/* -------------------------------------------------------------------------
* Preference questionnaire vars
*
*/

$w-preference-questionnaire__options--first-bp:                                           'sm' !default;
$w-preference-questionnaire__options--second-bp:                                          'md' !default;

$w-preference-questionnaire__toggle-element--duration:                                    $f-duration--normal !default;
$w-preference-questionnaire__toggle-element--timing:                                      $f-easing--in !default;

$w-preference-questionnaire__options--row-gap:                                            8px !default;
$w-preference-questionnaire__options--column-gap:                                         10px !default;

$w-preference-questionnaire__option--width-first-bp:                                      calc((100% / 2) - (#{$w-preference-questionnaire__options--row-gap} / 2)) !default;
$w-preference-questionnaire__option--width-second-bp:                                     calc((100% / 3) - ((#{$w-preference-questionnaire__options--row-gap} * 2) / 3)) !default;

$w-preference-questionnaire__option-illustrations--width:                                 calc((100% / 2) - (#{$w-preference-questionnaire__options--row-gap} / 2)) !default;
$w-preference-questionnaire__option-illustrations--width-second-bp:                       calc((100% / 4) - ((#{$w-preference-questionnaire__options--row-gap} * 3) / 4)) !default;

$w-preference-questionnaire__section--margin-bottom:                                      40px !default;

$w-preference-questionnaire__progress-bar--height:                                        4px !default;
$w-preference-questionnaire__progress-bar--border-radius:                                 $w-preference-questionnaire__progress-bar--height !default;
$w-preference-questionnaire__progress-bar--transition-duration:                           $f-duration--normal !default;

$w-preference-questionnaire__back-btn--width:                                             45.6px !default;

$w-preference-questionnaire__options-label--padding-right:                                $f-space--small !default;

$w-preference-questionnaire__options--flags-gap:                                          $f-space--small !default;

$w-preference-questionnaire__message--padding:                                            40px !default;

$w-preference-questionnaire__message-icon--color:                                         $f-color__neutral-grey !default;

$w-preference-questionnaire__message-text--max-width:                                     600px !default;
$w-preference-questionnaire__message-text--horizontal-padding:                            $f-space--medium !default;

$w-preference-questionnaire__message-title--max-width:                                    475px !default;

$w-preference-questionnaire__message-text-success--color:                                 $f-color__state-success !default;

$w-preference-questionnaire__message-items--margin-bottom:                                $f-space--medium !default;

$w-preference-questionnaire__question-icon--font-size-first-bp:                           $f-font-size--xxl !default;
$w-preference-questionnaire__question-icon--margin-bottom-first-bp:                       $f-space--small !default;

$w-preference-questionnaire__send-btn--padding:                                           16px $f-space--medium !default;
$w-preference-questionnaire__send-btn--font-size:                                         $f-font-size--m !default;

$w-preference-questionnaire__question-info-text--margin-top:                              -$f-space--medium !default;

$w-preference-questionnaire__start-loader--padding:                                       $f-space--large 0 !default;

$w-preference-questionnaire__questionnaire-container--max-width:                          920px !default;

// Summary

$w-preference-questionnaire__summary-question-title--bp:                                  $f-breakpoint--xs !default;

$w-preference-questionnaire__summary-question-answers--gap:                               8px !default;

$w-preference-questionnaire__summary-title--margin-top:                                   80px !default;

$w-preference-questionnaire__summary-question-title-container--margin-bottom:             16px !default;

$w-preference-questionnaire__summary-question-title--gap-until-bp:                        16px !default;

$w-preference-questionnaire__questionnaire-edit--margin-bottom-until-bp:                  $f-space--tiny !default;
