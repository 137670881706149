/* -------------------------------------------------------------------------
 * CATEGORY MIXINS
 */

// Content mixin

@mixin c-rating__content($val, $num) {
  $plus: $val;
  $content: $plus;

  @for $i from 1 to $num {
    $content: $content + $plus;
  }
  content: '#{$content}';
}

// Icons mixin

@mixin c-rating__icon($icon) {

  @each $_value in $c-rating__data {

    &[data-rating^="#{$_value}"] {
      &::before {
        @include c-rating__content($icon, $_value);
      }
    }
  }
}
