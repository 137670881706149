/* -------------------------------------------------------------------------
 * LOADER MIXINS
 *
 */

@mixin c-loader__set-color($color: $c-loader--border-color, $opacity: $c-loader--border-opacity) {
  &::#{$c-loader--pseudo-element} {
    border-color: rgba($color, $opacity);
    border-top-color: $color;
  }
}
