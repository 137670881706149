/* -------------------------------------------------------------------------
* Map Interactive
*
*/

$c-map-interactive__card-close--size:                             $f-space--medium + $f-space--small !default;
$c-map-interactive__card-close--font-size:                        18px !default;
$c-map-interactive__card-close--color:                            $f-color__state-info--semi-dark !default;
$c-map-interactive__card-title--padding-right:                    0 !default;

$c-map-interactive__cluster--hover-color:                          $f-color__brand-primary--semi-light !default;

$c-map-interactive__custom-marker--active-border-color:            $f-color__neutral-grey--semi-light !default;
$c-map-interactive__custom-marker--bg-color--hover:                $f-color__neutral-grey--ultra-light !default;
$c-map-interactive__custom-marker--font-size:                      $f-font-size--xs !default;
$c-map-interactive__custom-marker--border-radius:                  $f-radius--medium !default;
$c-map-interactive__custom-marker--bg-color-active:                $f-color__brand-primary !default;
$c-map-interactive__custom-marker--border-color--active:           $f-color__brand-primary !default;
$c-map-interactive__custom-marker--color-visited:                  $f-color__neutral-grey--dark !default;
$c-map-interactive__custom-marker--visited-border-color:           $f-color__neutral-grey !default;