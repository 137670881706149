.fr-inspiring-images-with-header{
    &>.c-promotion-image:first-child, &>.c-promotion-image__group:first-child {
        margin-bottom: $fr-inspiring-images-with-header--first-child__margin-bottom;

        @include t-mq($from: $f-breakpoint--md) {
            margin-bottom: $fr-inspiring-images-with-header--first-child__margin-bottom-bq;
        }
    }

    &>.c-promotion-image .c-img{
        @include t-mq($from: $f-breakpoint--sm) {
            height: $fr-inspiring-images-with-header--promotion-image__height;
        }
    }
}