// Trust
.c-btn--trust,
a.c-btn--trust {
  color: $c-btn-trust--color;
  background-color: $c-btn-trust--bg-color;
  border-color: $c-btn-trust--border-color;
  box-shadow: $c-btn-trust--shadow;
  
  font-size: $c-btn-trust--font-size;
  position: relative;
  @include sp-inset-v($c-btn-trust--sp-inset-v);
  
  .c-btn__loader {
    @include c-loader__set-color($c-btn-trust--color);
  }

  &:hover {
    color: $c-btn-trust--hover--color;
    background-color: $c-btn-trust--hover--bg-color;
    border-color: $c-btn-trust--hover--border-color;

    .c-btn__loader {
      @include c-loader__set-color($c-btn-trust--hover--color);
    }
  }

  &:active {
    color: $c-btn-trust--pressed--color;
    background-color: $c-btn-trust--pressed--bg-color;
    border-color: $c-btn-trust--pressed--border-color;
    box-shadow: none;

    .c-btn__loader {
      @include c-loader__set-color($c-btn-trust--pressed--color);
    }
  }

  &:focus {
    box-shadow: none;
    background-color: $c-btn-trust--focus--bg-color;
    border-color: $c-btn-trust--focus--border-color;
  }

}
