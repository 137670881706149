.fr-account-booking-content {
  @include o-layout();

  @include sp-stack-children($fr-account-booking-content--sp-stack-children);

  @include t-mq($from: "md") {
    @include reverse-layout();
  }
}

.fr-account-booking-content__main {
  @include o-layout__item();

  @include sp-stack-children($fr-account-booking-content--sp-stack-children * 2);

  @include t-mq($from: "md") {
    width: 75%;
  }
}

.fr-account-booking-content__main .c-accordion__item-content {
  padding-bottom: $fr-account-booking-content__accordion--padding-bottom;
}

.fr-account-booking-content__side {
  @include o-layout();
  @include o-layout__item();

  padding: 0;
  margin-top: 0;
  margin-left: 0;

  @include sp-stack-children($fr-account-booking-content--sp-stack-children);

  @include t-mq($from: "md") {
    width: 25%;
  }
}

.fr-account-booking-section-header {
  display: flex;
  align-items: baseline;
  margin-bottom: $fr-account-booking-section-header--margin-bottom;

  .m-heading {
    margin-left: $fr-account-booking-section-header--margin-left;
  }
}

.fr-account-booking-term {
  display: flex;

  &__content {
    width: 100%;
  }

  .m-icon {
    margin-right: $fr-account-booking-term--margin;
  }

  &__label {
    font-weight: $fr-account-booking-term__label--font-weight;
    font-size: $fr-account-booking-term__label--font-size;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  &__label-text:not(:only-child) {
    max-width: $fr-account-booking-term__label-text--max-width;
  }

  &__value {
    display: block;
    word-break: break-word;
    font-size: $fr-account-booking-term__value--font-size;
    margin-top: $fr-account-booking-term--margin;
  }

  &__service-edit-link.c-btn--icon-only {
    color: $fr-account-booking-term__service-edit-link--color;

    .c-btn__icon {
      height: $fr-account-booking-term__service-edit-link__icon--size;
      width: $fr-account-booking-term__service-edit-link__icon--size;
      @include sp-inset($fr-account-booking-term__service-edit-link--padding);

      &::before {
        font-size: $fr-account-booking-term__service-edit-link__icon--size;
      }
    }
  }
}

.fr-account-booking-term--small .fr-account-booking-term__label {
  font-size: $fr-account-booking-term__label-small--font-size;
}

.fr-account-booking-term--small .fr-account-booking-term__value {
  font-size: $fr-account-booking-term__value-small--font-size;
  margin-top: $fr-account-booking-term__value-small--margin-top;
}

.fr-account-booking-term__special-luggage {
  margin-bottom: $fr-account-booking-term__special-luggage__margin-bottom;
  display: flex;
  gap: $fr-account-booking-term__special-luggage__gap;
  align-items: center;
  flex-wrap: wrap;
}

.fr-account-booking-term__special-luggage .fr-account-booking-term__special-luggage-label {
  font-size: $f-font-size--xxs;
  flex-shrink: 0;
}
