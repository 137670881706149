/* -------------------------------------------------------------------------
* Textarea
*
*/

.c-textarea {
  color: $c-textarea--color;
  position: relative;
}

.c-textarea__wrapper {
  position: relative;
  display: block;
  width: 100%;
  background: $c-textarea__wrapper--bg-color;
  border-radius: $c-textarea--border-radius;
}

.c-textarea__element {
  appearance: none;
  outline: 0;
  box-shadow: none;
  background: $c-textarea__element--bg-color;
  margin: 0;
  border: $c-textarea__element--border-size $c-textarea__element--border-style $c-textarea__element--border-color;
  border-radius: $c-textarea--border-radius;
  width: 100%;
  height: $c-textarea__element--height;
  padding: $c-textarea--sp-inset-v $c-textarea--sp-inset-h;
  position: relative;
  cursor: text;
  font-family: $c-textarea__element--font-family;
  font-size: $c-textarea__element--font-size;
  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: $c-textarea--duration;
  transition-timing-function: $c-textarea--easing;

  &:hover {
    border-color: $c-textarea__element--border-color--hover;
  }

  &:focus,
  &.is-focused {
    box-shadow: $c-textarea__element--shadow--focus;
    border-color: $c-textarea__element--border--focus;
  }

  &::placeholder {
    color: $c-textarea__element--placeholder-color;
    opacity: 1;
  }
}

.c-textarea__messages {
  font-size: $c-textarea__messages--font-size;
  margin-top: $c-textarea__messages--margin-top;
  display: flex;
}


// States
// -------------------------------------------------

// Disabled
.c-textarea.is-disabled,
.is-disabled .c-textarea {
  .c-textarea__element {
    pointer-events: none;
  }
}

.is-disabled .c-textarea {
  .c-textarea__wrapper {
    opacity: $c-textbox__disabled--opacity;
  }
}

// Success
.c-textarea.has-success,
.has-success .c-textarea {
  .c-textarea__element {
    border-color: $c-textarea__element--success--border-color;

    &:focus,
    &.is-focused {
      box-shadow: $c-textarea__element--success--shadow--focus;
    }
  }
}

// Warning
.c-textarea.has-warning,
.has-warning .c-textarea {
  .c-textarea__element {
    border-color: $c-textarea__element--warning--border-color;

    &:focus,
    &.is-focused {
      box-shadow: $c-textarea__element--warning--shadow--focus;
    }
  }
}

// Error
.c-textarea.has-error,
.has-error .c-textarea {
  .c-textarea__element {
    border-color: $c-textarea__element--error--border-color;

    &:focus,
    &.is-focused {
      box-shadow: $c-textarea__element--error--shadow--focus;
    }
  }

  .c-textarea__message {
    color: $c-textarea__message--error--color
  }
}

// Modifiers
// -----------------------------------------

// Highlighted
.c-textarea--highlighted {
  .c-textarea__element {
    color: $c-textarea__element--highlighted--color;
  }
}
