/* -------------------------------------------------------------------------
 * PARTICIPANTS SELECTOR
 *
 */

.c-participants-selector {
  position: relative;

  datalist {
    display: none;
  }

  .c-number-stepper {
    display: flex;
    align-items: flex-start;
    .c-number-stepper__label {
      flex-grow: 2;
      margin-bottom: 0;
    }
  }

  .e-form-label__description {
    display: block;
    font-weight: 400;
    color: $c-participants-selector__label-description--color;
    font-size: $c-participants-selector__label-description--font-size;
    padding-top: $c-participants-selector__label-description--top-separation;
  }

  .c-date-selector__label {
    padding-left: 0;
  }

  .c-floating-box__body-content {
    padding-top: $c-participants-selector__floating-box-body-content--padding-top;
  }

  .c-textbox__element {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .c-textbox__element:focus,
  .c-textbox__element.is-focused {
    box-shadow: none !important;
    border-color: none;
  }

  .c-textbox__wrapper {
    display: -ms-flexbox;
  }
}

.c-participants-selector__room-title {
  @include m-heading--complete($c-participants-selector__room-title--material);
}

.c-participants-selector__floating-box {
  max-width: $c-participants-selector__floating-box--max-width;
  @include t-mq($from: $f-breakpoint--xs) {
    min-width:  $c-participants-selector__floating-box--min-width;
  }
}

.c-participants-selector__remove-room {
  position: absolute;
  right: $c-participants-selector--main-spacing * .5;
  top: $c-participants-selector--main-spacing * .5;
  color: $c-participants-selector__remove-room-btn--color;
  .c-btn__icon {
    vertical-align: top;
    padding-left: $c-participants-selector__remove-room-btn--size;
    height: $c-participants-selector__remove-room-btn--size;
    &::before {
      font-size: $c-participants-selector__remove-room-btn--size;
    }
  }
}
.c-participants-selector__multi-room {
  .c-participants-selector__add-room {
    background-color: $c-participants-selector__add-room--bg-color;
    padding: $f-space;
    margin-bottom: $c-participants-selector__add-room--margin-bottom;

    .c-number-stepper__input {
      background-color: $c-participants-selector__add-room--bg-color;
    }

    .c-number-stepper__label {
      font-weight: bold;
    }
    .c-number-stepper__body .c-btn {
      background:$c-participants-selector__add-room-btn--bg-color;

      &:hover {
        background:$c-participants-selector__add-room-btn--bg-color--hover;
      }
    }
  }

  .c-participants-selector__room-title-wrapper,
  .c-participants-selector__children-dates-required {
    position: relative;
    background-color: $c-participants-selector__wrapper--bg-color;
  }

  .c-participants-selector__room-title-wrapper {
    margin-bottom: $f-space / 2;
  }

  .c-participants-selector__room-wrapper {
    background-color: $c-participants-selector__wrapper--bg-color;
    padding: $f-space / 2;

    .c-number-stepper__input {
      background-color: $c-participants-selector__wrapper--bg-color;
    }
  }

  [data-c-participants-selector__profile-id] {
    border: 1px solid transparent;
    margin-bottom: $f-space / 2;
    padding-top: $f-space / 2;
  }

  .c-participants-selector__profile--focus {
    border: $c-participants-selector__profile--focus--border;
  }

  .c-participants-selector__children-dates-required {
    background-color: $c-participants-selector__children-dates-required--bg-color;
    &::before {
      border-bottom: ($c-participants-selector--main-spacing * .5) solid $c-participants-selector__children-dates-required--bg-color;
    }
  }
}

.c-participants-selector__group-link {
  margin-top: 0;
  margin-bottom: $c-participants-selector__group-link--margin-bottom;
}

.c-participants-selector__children-dates-required {
  background-color: $c-participants-selector__children-dates-required--bg-color;
  margin: $c-participants-selector--main-spacing (-$c-participants-selector--main-spacing * .5) 0;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -($c-participants-selector--main-spacing * .5);
    right: $c-participants-selector__children-dates-pointer--alignment;
    width: $c-participants-selector--main-spacing * .5;
    height: $c-participants-selector--main-spacing * .5;
    border-left: ($c-participants-selector--main-spacing * .5) solid transparent;
    border-right: ($c-participants-selector--main-spacing * .5) solid transparent;
    border-bottom: ($c-participants-selector--main-spacing * .5) solid $c-participants-selector__children-dates-required--bg-color;
  }

  &:empty {
    display: none;
  }

  .c-dropdown__element {
    padding-left: $c-participants-selector__children-dates-required--dropdown-space;
    padding-right: $f-space;

    & + .c-dropdown__arrow {
      right: $c-participants-selector__children-dates-required--dropdown-space;
    }
  }
}

@mixin c-participants-selector-title {
  color: $c-participants-selector__title--color;
  text-shadow: none;
}

.c-floating-box__header >
.c-background-img .c-title.c-participants-selector__title,
.c-background-img .m-heading.c-participants-selector__title {
  @include c-participants-selector-title;
}

.c-participants-selector__add-room,
.c-participants-selector__room-wrapper {
  @include sp-stack(16px);
}

.c-participants-selector__title,
.c-participants-selector__floating-box-header,
[data-c-participants-selector__profile-id] {
  @include sp-stack($c-participants-selector--main-spacing);
}

.c-participants-selector__children-dates-required {
  @include sp-stack-children($c-participants-selector--main-spacing);
}

.c-participants-selector__room-title-wrapper {
  @include sp-inset($c-participants-selector--main-spacing * .5);
}

.c-participants-selector__floating-box,
.c-participants-selector__children-dates-required {
  @include sp-inset-stretch($c-participants-selector--main-spacing);
}

.c-participants-selector__floating-box-header,
.c-participants-selector__floating-box-footer,
[data-c-participants-selector__profile-id]{
  @include sp-inset-h($c-participants-selector--main-spacing * .5);
}

.c-participants-selector__floating-box-footer {
  margin-top: $c-participants-selector__floating-box-footer--margin-top;
  & [data-c-participants-selector__action="cancel"] {
    float: left
  }
}

