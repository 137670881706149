.fr-faq-contact-cards {
  @include t-mq($until: $fr-faq-contact-cards--bp) {
    margin-left: $fr-faq-contact-cards--margin-h--until-bp;
    margin-right: $fr-faq-contact-cards--margin-h--until-bp;
    @include sp-inset($f-space--medium, $f-space--small)
  }
}

.fr-faq-contact-card__heading {
  padding-bottom: $fr-faq-contact-cards__title--sp-padding-bottom;
  border-bottom: $fr-faq-contact-cards__title--border-width $fr-faq-contact-cards__title--border-style $fr-faq-contact-cards__title--border-color;
  display: block;

  .m-heading {
    font-weight: bold;
  }
}
