// Dropdown modifier
// ------------------------------

.c-nav--dropdown {
  .c-nav__list {
    margin-left: 0;
    display: none;
    position: absolute;
    margin-top: 0;

    &[data-c-nav__level="2"] {
      margin-top: $c-nav__list--dropdown--level-2--margin-top;
    }
  }

  .c-nav__list.c-nav__list--sibling-children:not([data-c-nav__level="1"]) {
    position: relative;
    left: auto;
    top: auto;
    padding: 0;
    box-shadow: none;
    margin: 0;
    display: block;

    .c-nav__item {
      border: none;
      text-indent: 0;

      &:first-child {
        > .c-nav__link {

          &::before {
            display: initial;
          }
        }
      }
    }
    .c-nav__link, .c-nav__form-submit {
      line-height: $c-nav--line-height;
      padding-left: $c-nav__link--dropdown--4th--padding-left;
    }
  }

  .c-nav__item {
    margin-bottom: 0;
    position: relative;
    @include t-text-crop-disable();

    &.has-child {
      > .c-nav__link {
        @include m-icon("chevron-right", "after");
        padding-right: $c-nav--dropdown--padding-right;
        font-size: $c-nav--font-size;
        color: $c-nav__link--color;
        pointer-events: initial;

        &::after {
          position: absolute;
          padding-left: $c-nav--dropdown--padding-left;
          right: $c-nav--dropdown__sp-inset-h;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &.is-opened {
      > .c-nav__list {
        display: block;
      }

      > .c-nav__link {
        color: $c-nav__link--color;
        background-color: $c-nav__link--dropdown--hover--bg-color;
      }
    }
  }

  .c-nav__link {
    cursor: pointer;
    text-align: left;

    &:hover {
      color: $c-nav__link--color;
      background-color: $c-nav__link--dropdown--hover--bg-color;
    }

    &:active {
      background-color: $c-nav__link--dropdown--pressed--bg-color;
    }
  }
  
  .c-nav__list[data-c-nav__level="1"] {
    display: block;
    position: relative;

    > .c-nav__item {
      display: inline-block;
      @include sp-inline($c-nav--dropdown--sp-inline);
      @include sp-inset-v($c-nav--dropdown--sp-inset-v);

      &:last-child {
        margin-right: 0;
      }

      &.is-opened {
        > .c-nav__link {
          color: $c-nav__link--hover--color;
          background: none;
        }
      }

      > .c-nav__link {
        font-weight: $c-nav__link--dropdown--font-weight--level-1;

        &:hover {
          color: $c-nav__link--hover--color;
          background: none;
        }
      }

      .c-nav__link-icon + .c-nav__link-text {
        padding-left: $c-nav__link--dropdown--padding-left + $c-nav--dropdown--sp-inset-h;
      }

      &.has-child {
        > .c-nav__link {
          padding-right: 0;
          @include m-icon("chevron-down", "after");

          &::after {
            position: relative;
            right: auto;
            transform: none;
          }
        }
      }
    }
  }

  .c-nav__list:not([data-c-nav__level="1"]) {
    min-width: $c-nav__list--dropdown-min-width;
    max-width: $c-nav__list--dropdown-max-width;
    background-color: $c-nav__list--dropdown-bg-color;
    box-shadow: $c-nav__list--dropdown-shadow;
    z-index: $c-nav__list--dropdown-z-index;

    &.has-child {
      > .c-nav__link {
        padding-right: calc(#{$c-nav--dropdown__sp-inset-h} + #{$c-nav--dropdown--padding-right});
      }
    }
    
    .c-nav__link {
      @include sp-inset($c-nav--dropdown--sp-inset-v, $c-nav--dropdown--sp-inset-h);
      display: block;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: $c-nav--dropdown__border-color;
        height: $c-nav--dropdown__border-width;
      }
    }

    .c-nav__form-submit {
      @include sp-inset($c-nav--dropdown--sp-inset-v, $c-nav--dropdown--sp-inset-h);
    }

    .c-nav__link--form-wrapper {
      padding: 0;
    }

    .c-nav__item {
      &:first-child {
        > .c-nav__link {
          &::before {
            display: none;
          }
        }
      }

      &.is-active {
        > .c-nav__link {
          border-left: $c-nav__item--active--dropdown--border-width $c-nav__item--active--dropdown--border-style $c-nav__item--active--dropdown--border-color;
          text-indent: -1 * $c-nav__item--active--dropdown--border-width;
          background-color: $c-nav__link--dropdown--active--bg-color;
          font-weight: $c-nav__link--dropdown--active--font-weight;
        }
      }

      .c-nav__link-icon + .c-nav__link-text{
        padding-left: $c-nav__link--dropdown--padding-left;
      }
    }
  }

  .c-nav__list[data-c-nav__level="2"] {
    top: 100%;
    left: 0;
    border-top: $c-nav__list--dropdown-border-width $c-nav__list--dropdown-border-style $c-nav__list--dropdown-border-color;
    z-index: map_get($f-z-indexes--usage, "c-nav--dropdown__list");

    &.c-nav__list--reverse {
      left: auto;
      right: 0;
    }

  }

  .c-nav__list[data-c-nav__level="3"] {
    top: -1 * $c-nav--dropdown__sp-inset-v;
    left: 100%;

    &.c-nav__list--reverse {
      left: auto;
      right: 100%;
    }
  }

  .c-nav__link-icon {
    display: block;
    position: absolute;
    left: $c-nav--dropdown--sp-inset-h;
  }
}
