/* -------------------------------------------------------------------------
 * PACKAGE BLOCK
 *
 */

.c-package-block {
  @include sp-stack-children($f-space--tiny);
}

.c-package-block__included {
  white-space: nowrap;
  line-height: 1;
  font-size: $c-package-block__included--font-size;
  color: $c-package-block__included--color;
  display: inline-flex;

  .c-package-block__included-item {
    font-size: $c-package-block__included--font-size;
    display: flex;
    @include at-least(4) {
      font-size: $c-package-block__included--font-size * .75;
    }
  }
}

.c-package-block__included-item {
  display: inline-block;
  vertical-align: bottom;

  // Add concatenation icon
  &:not(:first-child) {
    @include m-icon($c-package-block__included-separator);
  }

  .m-icon {
    display: inline-block;
    position: relative;
    height: 1em;
    width: 1em;
    margin-top: 0;
    line-height: 1em;
    &::#{$m-icons__pseudo-element} {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.c-package-block__departure {
  font-weight: $c-package-block__departure--font-weight;
}

.c-package-block__filters {
  color: $c-package-block__filters--color;
}

.c-package-block__filters-item {
  display: inline-block;
  &:not(:first-child)::before {
    content: " ";
    white-space: pre;
  }
  &:not(:last-child)::after {
    content: $c-package-block__filters--separator;
  }
}

.c-package-block__arrival-date {
  display: none;
}

.c-package-block__outbound-departure-airport-name-container {
  font-size: 11px;
  font-weight: 500;
  color: #837868; 
  line-height: 1.5;
  .m-icon {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
}