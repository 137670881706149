/* -------------------------------------------------------------------------
* Dropdown Variables
*
*/

$c-dropdown-multiple--color:                                                                    $f-color__neutral-grey--ultra-dark !default;
$c-dropdown-multiple--padding-top:                                                              0 !default;
$c-dropdown-multiple--padding-bottom:                                                           0 !default;

$c-dropdown-multiple__options--bg-color:                                                        $f-color__neutral-white !default;
$c-dropdown-multiple__options--max-height:                                                      40em !default;
$c-dropdown-multiple__options-none--padding:                                                    $f-space--small !default;
$c-dropdown-multiple__placeholder--top:                                                         50% !default;
$c-dropdown-multiple__placeholder--color:                                                       $e-form-elements__placeholder--color !default;

$c-dropdown-multiple__options--border-width:                                                    0px 1px 1px !default;
$c-dropdown-multiple__options--border--style:                                                   solid !default;
$c-dropdown-multiple__options--border--color:                                                   $f-color__neutral-grey !default;
$c-dropdown-multiple__options--border:                                                          $c-dropdown-multiple__options--border-width $c-dropdown-multiple__options--border--style $c-dropdown-multiple__options--border--color !default;

$c-dropdown-multiple--options-footer--border--width:                                            1px !default;
$c-dropdown-multiple--options-footer--border--style:                                            solid !default;
$c-dropdown-multiple__options-footer--border--color:                                            $f-color__neutral-grey--semi-light !default;
$c-dropdown-multiple__options-footer--border:                                                   $c-dropdown-multiple--options-footer--border--width $c-dropdown-multiple--options-footer--border--style $c-dropdown-multiple__options-footer--border--color !default;

$c-dropdown-multiple__bottom--space:                                                            $f-space--medium !default;

$c-dropdown-multiple__content--padding-y:                                                       $c-dropdown--sp-inset-v !default;
$c-dropdown-multiple__content--padding-x:                                                       $c-dropdown--sp-inset-h !default;

$c-dropdown-multiple__durations:                                                                $f-duration--normal !default;

$c-dropdown-multiple__choice-list--min-height:                                                  7em !default;
$c-dropdown-multiple__chip--computed-height:                                                    27px !default;

// Swipe
// -----------------------------------------
$c-dropdown-multiple__swipe--min-height:                                                        $c-dropdown__element--min-height - ($c-dropdown__element--border-size * 2) !default;
$c-dropdown-multiple__swipe--top:                                                               ($c-dropdown-multiple__swipe--min-height - $c-dropdown-multiple__chip--computed-height) / 2 !default;
$c-dropdown-multiple__swipe--bottom:                                                            -1 * ($c-dropdown-multiple__swipe--min-height + $f-space) !default;

// Variants
// -----------------------------------------

// Compact
$c-dropdown__element--multiple--is-compact__no-value__min-height:                               $c-dropdown__element--is-compact--min-height - ($c-dropdown--is-compact--sp-inset-v * 2) - ($c-dropdown__element--border-size * 3);
$c-dropdown__selected-item-list__compact-has-value:                                             $f-space--tiny !default;
$c-dropdown__selected-item-list__compact-top:                                                   $f-space--tiny !default;
$c-dropdown__element--is-compact--color:                                                        $f-color__neutral-black !default;
$c-dropdown__element--is-compact--top:                                                          25% !default;
$o-swipe-btn--dropdown-is-compact--padding-top:                                                 $f-space--small !default;
$c-dropdown--is-compact--sp-inset-label--max-width:                                             90% !default;