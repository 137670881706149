/* -------------------------------------------------------------------------
* TABS FILTER
*
*/

.w-filter--tabs .c-tabs__nav--secondary {
  .o-swipe-btn--right {
    background: linear-gradient(270deg, $w-tabs-filer__c-tabs-nav--bg-color 40%, rgba($w-tabs-filer__c-tabs-nav--bg-color, 0));
  }

  .o-swipe-btn--left {
    background: linear-gradient(90deg, $w-tabs-filer__c-tabs-nav--bg-color 40%, rgba($w-tabs-filer__c-tabs-nav--bg-color, 0));
  }
}