/* -------------------------------------------------------------------------
 * PRODUCT ADS
 *
 */

 $w-product-ads__bq:                                                          $f-breakpoint--sml !default;
 
 $w-product-ads--padding:                                                     $f-space--medium $f-space--small !default;

 $w-product-ads--background--background-color:                                $f-color__neutral-grey !default;
 $w-product-ads--background--border-radius:                                   16px !default;

 $w-product-ads--modal--btn--color:                                           $f-color__neutral-grey !default;
 $w-product-ads--modal--btn--font-size:                                       $f-font-size--s !default;

 $w-product-ads__header:                                                      $f-space--medium !default;
 $w-product-ads--item--background-color:                                      $f-color__neutral-white !default;
 $w-product-ads--item-subtitle-icon--margin-right:                            $f-space--tiny / 2 !default;

 $w-product-ads--item-content--padding:                                       $f-space--small ($f-space--medium + $f-space--tiny) $f-space--small $f-space--small 
 !default;

 $w-product-ads--item--no-image--background-color:                            $f-color__neutral-grey !default;

 // Breakpoint -------------------------------------------------------------

 $w-product-ads--bq--padding:                                                $f-space--large !default;
 $w-product-ads--bq--header:                                                 $f-space--large !default;

 $w-product-ads--bq--item-content--padding:                                  $f-space--small !default;