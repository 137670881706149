/* -------------------------------------------------------------------------
* PRICE
*/

// Component output

.c-price {
  display: inline-flex;
  flex-direction: column;
  text-align: right;
  @include sp-stack-children($f-space--tiny);
}

.c-price__sale-info {
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.c-price__old-price {
  font-weight: $c-price_old-price--font-weight;
  font-family: $c-price_old-price--font-family;
  font-size: $c-price_old-price--font-size;
  margin-left: $c-price_old-price--margin-left;
  text-decoration: line-through;
  display: inline-block;
}

.c-price__info-icon {
  position: relative;
  z-index: $c-price__info-icon--z-index;
  .c-btn__icon {
    margin-left: $c-price__info-icon--margin-left;
    padding-left: 0;
    vertical-align: 0;
    height: auto;

    &::#{$m-icons__pseudo-element} {
      color: $c-price__info-icon--color;
      font-size: $c-price__info-icon--font-size;
      position: relative;
    }
  }
}

.c-price__label.c-price__label-grayed {
  color: $c-price__label-grayed--color;
  font-size: $c-price__label-grayed--font-size;
  font-style: $c-price__label-grayed--style;
}

.c-price__public-price {
  color: $c-price__public-price--color;
  margin-left: $c-price_public-price--margin-left;
  padding-top: $c-price_public-price--padding-top;
}

.c-price__public-price-detail {
  color: $c-price__public-price-detail--color;
}

//Modifier

.c-price--primary-color {
  .m-price__currency,
  .m-price__value {
    color: $c-price__value--primary-color--color;
  }

  .m-price__suffix {
    font-weight: $c-price__suffix--primary-color--font-weight;
    color: $c-price__suffix--primary-color--color;
    font-family: $c-price__suffix--primary-color--font-family;
  }
}

// Discount  
// AB Test temporary styles

.c-price__discount {
  display: none;
  width: 160px;
  flex-direction: row;
  background-color: rgba(33, 183, 136, 0.1);
  color: rgb(33, 183, 136);
  border-radius: 4px;
  padding: 2px;
  align-items: center;
  justify-content: center;
  size: 12px;
  font-weight: 700;
  padding-left: 10px;
  padding-right: 10px;

  @include t-mq($from: 960px, $until: 1080px) {
    width: 100%;
  }

  &-text {
    margin: 0 6px;
  }
  &-icon::before {
    font-weight: 700;
  }
} 

.c-price__extra-info-label {
  color: $c-price__label-grayed--color;
  font-size: $c-price__label-grayed--font-size;
  font-style: $c-price__label-grayed--style;
  text-transform: none;
  text-align: right;
  font-weight: 400;

  &:hover {
    color: $c-price__label-grayed--color;

    .c-price__extra-info-link {
      color: $c-price__extra-info-link-hover--color;
    }
  }
}

.c-price__extra-info-link {
  text-decoration: underline;
  font-weight: 700;
}

// TODO: remove this once the test is over!
.discount-ab-test {
  display: none;
}