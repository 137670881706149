//Vars
$w-payment__payment-method-container--sp-inset:         $f-space--medium !default;
$adyen-checkout__input--border-size:                    $c-textbox__element--border-size !default;
$adyen-checkout__input--border-style:                   $c-textbox__element--border-style !default;
$adyen-checkout__input--border-color:                   $c-textbox__element--border-color !default;
$adyen-checkout__input--border-radius:                  $c-choice-list--boxed--border-radius !default;
$adyen-checkout__input--placeholder:                    $c-textbox__element--placeholder-color !default;
$adyen-checkout__input--font-size:                      $c-textbox__element--font-size !default;

$adyen-checkout__input--padding:                        $c-textbox--sp-inset-v $c-textbox--sp-inset-h !default;


$adyen-checkout__input--shadow--focus:                  $c-textbox__element--shadow--focus !default;
$adyen-checkout__input--border--focus:                  $c-textbox__element--border--focus !default;

$adyen-checkout__input--border-color-error:             $c-textbox__element--error--border-color !default;
$adyen-checkout__input--error-color:                    $c-textbox__message--error--color !default;

$adyen-checkout__input--shadow-focus:                   $c-textbox__element--shadow--focus !default;
$adyen-checkout__input--border-focus:                   $c-textbox__element--border--focus !default;
$adyen-checkout__button--pay--min-width:                $c-btn--min-width !default;
$adyen-checkout__button--pay--border-radius:            $c-btn--border-radius !default;

$adyen-checkout__button--loading--background:           $c-btn-flow--bg-color !default;
$adyen-checkout__button--loading--box-shadow:           $c-btn-flow--shadow !default;