
/* -------------------------------------------------------------------------
 * SHADOWS
 *
 * Define box and text shadows
 *
 */


// Main shadow

$f-shadow:                            1px 1px 10px 0px !default;
$f-shadow__color:                     $f-color__neutral-black !default;
$f-shadow__opacity:                   0.1 !default;

// Shadow variants

$f-shadow--discrete:                  $f-shadow !default;
$f-shadow--elevated:                  1px 1px 5px 0px !default;
$f-shadow--sunken:                    inset 0px 0px 8px 0px !default;
$f-shadow--glow:                      0px 0px 10px 0px !default;
$f-shadow--submersed:                 inset 0px -10px 10px -9px !default;
$f-shadow--superimposed:              0px -3px 4px !default;
$f-shadow--border:                    0px 0px 1px 0px !default;
$f-shadow--elegant:                   0 8px 12px 0 !default;
$f-shadow--text:                      1px 1px 5px !default;

// Shadow opacity variants

$f-shadow__opacity--discrete:         $f-shadow__opacity !default;
$f-shadow__opacity--elevated:         0.14 !default;
$f-shadow__opacity--sunken:           $f-shadow__opacity--elevated !default;
$f-shadow__opacity--glow:             1 !default;
$f-shadow__opacity--submersed:        $f-shadow__opacity !default;
$f-shadow__opacity--superimposed:     .2 !default;
$f-shadow__opacity--border:           1 !default;
$f-shadow__opacity--elegant:          0.05 !default;
$f-shadow__opacity--text:             0.85 !default;

