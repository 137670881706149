/* -------------------------------------------------------------------------
 * INSET HORIZONTAL MIXINS
 *
 * To give space between container and content only in side spaces
 *
 * TIP:
 * You can modify the amount of generated spaces by overriding the $sp-inset-h__spaces variable.
 *
 */

// Space as a mixin
// --------------------------------------------------

@mixin sp-inset-h($_spacing-value) {
  padding-left: $_spacing-value;
  padding-right: $_spacing-value;
}
