.w-account-special-luggage__trigger-open-modal {
  cursor: pointer;
}

.w-account-special-luggage .c-modal {
  scrollbar-width: none;
}

.w-account-special-luggage .c-modal__footer,
.w-account-special-luggage .c-modal__header {
  position: sticky;
  z-index: $f-z-index--2;
}

.w-account-special-luggage .c-modal__footer {
  display: flex;
  justify-content: end;
  gap: $w-account-special-luggage__modal-footer-gap;
  bottom: 0;
  background-color: $f-color__neutral-white;
}

.w-account-special-luggage .c-modal__header {
  top: 0;
}

.w-account-special-luggage__measurement-title {
  @include m-body(map_get($m-heading__mod-sizes, "small"));
  margin-bottom: $w-account-special-luggage__measurement-title__margin-bottom;
}

.w-account-special-luggage__measurement-subtitle {
  @include m-body(map_get($m-heading__mod-sizes, "tiny"));
  margin-bottom: $w-account-special-luggage__measurement-subtitle__margin-bottom;
  margin-top: $w-account-special-luggage__row__margin;
}

.w-account-special-luggage__spacing-top {
  margin-top: $w-account-special-luggage__row__margin;
}

.w-account-special-luggage__spacing-bottom,
.w-account-special-luggage__medical-container p {
  margin-bottom: $w-account-special-luggage__row__margin;
}

.w-account-special-luggage__measurement-text p,
.w-account-special-luggage__medical-text,
.w-account-special-luggage__medical-container--no-flight {
  margin-bottom: 0;
}

.w-account-special-luggage__inputs-row {
  display: flex;
  gap: $w-account-special-luggage__input-row__gap;

  @include t-mq($until: $f-breakpoint--xs) {
    flex-direction: column;
    gap: $w-account-special-luggage__input-row__gap--small-screen;
  }
}

.w-account-special-luggage__inputs-row {
  margin-bottom: $w-account-special-luggage__row__margin;
}

.w-account-special-luggage__inputs-row:last-child {
  margin-bottom: 0;
}

.w-account-special-luggage__inputs-row {
  .c-textbox,
  .c-dropdown {
    flex: 1;
  }
}

.w-account-special-luggage__inputs-row--one-input {
  .c-textbox,
  .c-dropdown {
    @include t-mq($from: $f-breakpoint--xs) {
      flex-basis: calc(50% - ($w-account-special-luggage__input-row__gap / 2));
      flex-grow: 0;
    }
  }
}

.w-account-special-luggage__terms-and-conditions {
  margin-top: $w-account-special-luggage__terms-and-conditions__margin-top;
}

.w-account-special-luggage__hide {
  opacity: 0;

  @include t-mq($until: $f-breakpoint--xs) {
    display: none;
  }
}

.w-account-special-luggage__inputs-row--margin-top {
  margin-top: $w-account-special-luggage__row__margin;
}

.w-account-special-luggage__extra-medicine-kilos {
  margin-top: $w-account-special-luggage__row__margin--sm;
  margin-bottom: $w-account-special-luggage__row__margin--sm;
}

.w-account-special-luggage__wheelchair-container--bottom {
  margin-bottom: -$w-account-special-luggage__row__margin--sm;
}
