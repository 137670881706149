/* -------------------------------------------------------------------------
 * BOOKING STEPS
 *
 */
.w-booking-steps__main-top {
  @include sp-stack($w-booking-steps__main-top--sp-stack);
}

.w-booking-steps__main-right {
  .w-booking-steps__title {
    color: $w-booking-steps__title--main-right--color;
    text-transform: $w-booking-steps__title--main-right--text-transform;
    @include sp-stack($w-booking-steps__title--main-right--sp-stack);
  }
}

.w-booking-steps__contact-icon {
  font-size: $w-booking-steps__contact-icon--font-size;
}

.w-booking-steps__contact-phone {
  font-weight: $w-booking-steps__contact-phone--font-weight;
  @include sp-stack($w-booking-steps__contact-phone--sp-stack);
}

// To remove when AB testing for luggage has finished
.w-booking-item.is-hidden-for-google-optimize{
  display: none;
}