/* -------------------------------------------------------------------------
 * NUMBER STEPPER VARS
 *
 */

$c-number-stepper--height:                                  36px !default;
$c-number-stepper--width:                                   ($c-number-stepper--height * 3) !default;
$c-number-stepper--border-radius:                           $e-form-elements__field--border-radius !default;

$c-number-stepper__input--font-size:                        $e-form-elements__field--font-size !default;
$c-number-stepper__input--color:                            $e-form-elements__field--color !default;
$c-number-stepper__input--bg-color:                         $e-form-elements__field--bg-color !default;

$c-number-stepper__btn--color:                              $f-color__neutral-grey--dark !default;
$c-number-stepper__btn--bg-color:                           $f-color__neutral-grey--semi-light !default;
$c-number-stepper__btn--hover--bg-color:                    $f-color__neutral-grey !default;
$c-number-stepper__btn--hover--opacity:                     .7 !default;

$c-number-stepper__input-disabled--color:                   $c-number-stepper__btn--color !default;
$c-number-stepper__input-disabled--opacity:                 .5 !default;
