.w-booking-participants-form .c-form-section__title {
  font-size: $w-booking-participants-form--section-title;
}

.w-booking-participants-form .c-form__fieldset-title-container .c-title {
  font-family: $w-booking-participants-form__fieldset-title--font-family;
  font-size: $w-booking-participants-form__fieldset-title--font-size;
  font-weight: $w-booking-participants-form__fieldset-title--font-weight;
}



