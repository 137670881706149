/* -------------------------------------------------------------------------
 * NAV
 *
 */

// Component output
// ------------------------------
.c-nav {
  font-family: $c-nav--font-family;
  font-size: $c-nav--font-size;
}

.c-nav__list {
  list-style: none;
  margin-left: $c-nav--sp-inset-left;
  margin-bottom: 0;
  &[data-c-nav__level="1"] {
    margin-left: 0;
  }
  &[data-c-nav__level="2"], &[data-c-nav__level="3"], &[data-c-nav__level="4"] {
    margin-top: $c-nav__list--margin-top;
  }
}

.c-nav__item {
  @include t-text-crop-enable();
  @include t-text-crop($c-nav--font-family--crop, $c-nav--line-height);
  @include sp-stack($c-nav--sp-stack);

  &:last-child {
    margin-bottom: 0;
  }

  &.has-child {
    > .c-nav__link {
      font-size: $c-nav__item--has-child--font-size;
      color: $c-nav__item--has-child--color;
      pointer-events: none;
    }
  }

  &.is-active, &.is-current {
    > .c-nav__link {
      font-weight: $c-nav__item--active--font-weight;
    }
  }
}

.c-nav__link,
.cs-default .c-nav__link {
  font-weight: $c-nav__link--font-weight;
  color: $c-nav__link--color;
  transition: background-color $c-nav__link--duration $c-nav__link--easing, color $c-nav__link--duration $c-nav__link--easing;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:hover {
    color: $c-nav__link--hover--color;
  }
}

.c-nav__form-submit {
  @include t-text-crop-disable();
  line-height: $c-nav--line-height;
  padding:0;
  display: inline-block;
  width: 100%;
  text-align: left;
  border-width: 0;
  text-transform: none;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.c-nav__link-icon {
  display: none;
}


// Variants
// ------------------------------

@import "c-nav--block";
@import "c-nav--dropdown";
@import "c-nav--inline";
@import "c-nav--list";
@import "c-nav--collapsible";
