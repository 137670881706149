.fr-acco-carousel {
  .c-slider {
    @include sp-stack($fr-acco-carousel--sp-stack);

    @include t-mq($until: $t-accommodation--bp) {
      margin-left: $fr-acco-carousel--margin-h--until-bp;
      margin-right: $fr-acco-carousel--margin-h--until-bp;
    }

    @include t-mq($from: $t-accommodation--bp) {
      @include sp-stack($fr-acco-carousel--sp-stack);
    }
  }
}
