/* -------------------------------------------------------------------------
 * LABEL VARIABLES
 */

$c-label--border-radius:                    $f-radius--medium !default;
$c-label--space-inset:                      $f-space--small !default;
$c-label--material:                         map_get($m-heading__mod-sizes, "small") !default;


// Modifiers
// ------------------------------

$c-label--margin-right--icon:               $f-space--tiny !default;

// Variants
// ------------------------------
$c-label--badge--font-weight:               map_get($m-body__properties, "font-weight") !default;

// Sale
$c-label--bg-color--sale:                   $f-color__brand-primary--semi-light !default;
$c-label--text-color--sale:                 $f-color__neutral-white !default;
$c-label--font-size--sale:                  $f-font-size--xxs !default;
$c-label--font-family--sale:                $f-font-family__accent !default;
$c-label--sp-inset--sale:                   $f-space--tiny !default;

// Sizes
// ------------------------------
$c-label-sizes__map:                        $m-heading__mod-sizes !default;
