/* -------------------------------------------------------------------------
 * REVIEWS TEASER
 *
 */

.w-reviews-teaser{
    position: relative;

    .c-modal-v2,
    .c-modal {
        box-shadow:  $w-reviews-list__modal--box-shadow;
    }

    &.is-unresolved{
        .w-reviews-teaser--overview__number-score .c-number-score__body,
        .w-reviews-teaser--item .c-number-score__body,
        .w-reviews-teaser--overview__number-score .c-number-score__label .c-number-score__button-info{
            display: none
        }
        
        .w-reviews-teaser--overview__number-score .c-number-score .c-number-score__title-label,
        .w-reviews-teaser--item .c-number-score,
        .w-reviews-teaser--item .w-reviews-teaser--review-content,
        .w-reviews-teaser--item .w-reviews-teaser--review-translate--btn-wrapper,
        .w-reviews-teaser--btn{
            @include a-unresolved();
        }

        .w-reviews-teaser--item{
            overflow: hidden;
        }

        .w-reviews-teaser--btn{
            border-color:transparent;
        }
    }

    &:not(.is-unresolved){
        .w-reviews-teaser--overview__number-score .c-number-score__body:hover{
            cursor: pointer;

             & ~ .c-number-score__title-label{
                .c-number-score__label-text{
                    text-decoration: underline;
                }
             } 
        }
        .w-reviews-teaser--overview__number-score .c-number-score__title:hover{
            cursor: pointer;

            &~.c-number-score__label{
                .c-number-score__label-text{
                    text-decoration: underline;
                }
            } 
        }
        .w-reviews-teaser--overview__number-score .c-number-score__label-text:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.w-reviews-teaser--overview__number-score{
    display: flex;
}

.w-reviews-teaser--overview{
    @include  sp-stack($w-reviews-teaser__stack);

    .c-number-score__label-text{
        display: inline;
    }
}

.w-reviews-teaser--reviews-items{
    @include  sp-stack($w-reviews-teaser__stack);
    display: flex;
    gap: $w-reviews-teaser--reviews-items__gap;

    @include t-mq($until: $w-reviews-teaser__bq) {
        flex-direction: column;
    }

    .w-reviews-teaser--item{
        @include  sp-inset($w-reviews-teaser--item__inset-v, $w-reviews-teaser--item__inset-h);
        box-shadow: $w-reviews-teaser--item__shadow-box;
        width: $w-reviews-teaser--item__width;
        @include t-mq($until: $w-reviews-teaser__bq) {
            width: $w-reviews-teaser--item__width-bq;
        }

        .w-reviews-teaser--rating{
            @include  sp-stack($w-reviews-teaser--item-rating__stack);
        }

        .w-reviews-teaser__comment-translate-loader.is-hidden{
            display: none;
        }

        &.is-translated{
            .w-reviews-teaser--review-content{
                display: none;
            }
        }
        &:not(.is-translated){
            .w-reviews-teaser--review-translate{
                display: none;
            }
        }
    }
}

.w-reviews-teaser--review-content{
    .c-collapse__trigger{
        font-size: $w-reviews-teaser--review-content--collapse-btn__font-size;
    }
}

.w-reviews-teaser--review-translate--btn-wrapper{
    .w-reviews-teaser--review-translate--btn,
    .w-reviews-teaser--review-original--btn{
        color: $w-reviews-teaser--item--translate-btn__color;
        font-family: $f-font-family__secondary;
        font-size: $w-reviews-teaser--item--translate-btn__font-size;
        font-weight: 400;
        padding: 0;
        padding-top: $w-reviews-teaser--item--translate-btn__padding-top;
        text-transform: none;
        transition: color $w-reviews-teaser--item--translate-btn__transition-duration;

        &:hover{
            color: $w-reviews-teaser--item--translate-btn__color-hover;
        }

        &.is-hidden{
            display: none;
        }
    }
}

.w-reviews-teaser__loader,
.w-reviews-teaser--msg-area,
.w-reviews-teaser--btn,
.w-reviews-teaser--wrapper{
    &.is-hidden{
        display: none;
    }
}

.w-reviews-teaser__loader{
    margin-left: $w-reviews-list__loader__margin-left;
}

.w-reviews-teaser__modal--footer{
    text-align: end;
}

.w-reviews-teaser__modal{
    .c-modal-v2__body-content>*:not(:last-child){
        margin-bottom: $w-reviews-teaser__modal--body-content--item-gap;
    }
}

//Number Score Teaser
.w-reviews-teaser__number-score-teaser {
    .c-number-score__label-text{
        display: inline;

    }

    .w-reviews-teaser__number-score-teaser-loader{
        display: inline-block;
        margin-left:  $w-reviews-teaser__teaser-loader__margin-left;
    }

    &.is-loaded{
        .w-reviews-teaser__number-score-teaser-loader{
            display: none;
        }

        &.is-clicable {
            .c-number-score__body:hover{
                cursor: pointer;
        
                & ~ .c-number-score__title-label{
                    .c-number-score__label-text{
                        text-decoration: underline;
                    }
                } 
                
            }
            .c-number-score__title:hover{
                cursor: pointer;
        
                &~.c-number-score__label{
                    .c-number-score__label-text{
                        text-decoration: underline;
                    }
                } 
            }
            .c-number-score__label-text:hover{
                    cursor: pointer;
                    text-decoration: underline;
                }
            
        }
    }
}