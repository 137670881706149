/* -------------------------------------------------------------------------
* Price Filter
*
*/

// Fix for consistency of paddings between title and content of the filters.
.w-filter--range .c-range {
  padding-top: 0;
}

// States
// -------------------------------------------------

// Disabled
.w-filter--range.is-disabled,
.is-disabled .w-filter--range {
  .c-range__current-min,
  .c-range__current-max,
  .c-range__slider {
    opacity: $w-price-filter__disabled--opacity;
    pointer-events: none;
  }
}
