.booking-error--error-message{
    @include sp-stack($w-booking-error__error-message--stack);
}
.booking-error--internal-messages{
    @include sp-stack($w-booking-error__internal-messages--stack);
}
.booking-error--form{
    @include sp-stack($w-booking-error__form--stack);
}

.w-booking-error__form-fields-section {
    @include sp-stack($w-booking-error__form-fields-section--stack);
}
.w-booking-error__form-button-section {
    @include sp-stack($w-booking-error__form-button-section--stack);
}

.booking-error--message-item {
    @include sp-stack($w-booking-error__message-item--stack);
}

.booking-error--form .hidden {
    display: none;
}
.w-booking-error__form-title {
    font-weight: $w-booking-error__form-title--font-weight;
    font-size:  $w-booking-error__form-title--font-size;
}

.booking-error--form .c-form__item {
    padding-left: 0;
}

.booking-error--main-error {
    @include sp-stack($w-booking-error__main-error--stack);
}

.booking-error--error-message-has-mandatory-payment-case {
  display: none;
}

.booking-error--has-mandatory-payment {
  .booking-error--error-message-base-case {
    display: none;
  }

  .booking-error--error-message-has-mandatory-payment-case {
    display: block;
  }
}

.w-booking-error__modal .c-modal-v2__footer {
    text-align: right;
}