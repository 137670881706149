/* -------------------------------------------------------------------------
 * Account booking
 *
 */

@import 'fr-account-booking-top';
@import 'fr-account-booking-content';
@import '../shared/fragments/fr-account-booking-cancelled-content/main';
@import 'fr-account-booking-cancelled-content';
@import 'fr-account-booking-conditional-messages';
@import 'fr-account-booking-participants';
@import 'fr-account-booking-details';
@import 'fr-account-booking-payment-status';
@import 'fr-account-booking-acco-info';
@import 'fr-account-booking-modifications';
@import 'fr-account-booking-promotion-rebooking';
@import 'fr-account-booking-edit-link';
@import 'fr-account-booking-delete';

.l-site-main__content [class^="fr-"] > div.to-implement{
  background-color: lightgrey;
  min-height: 300px;
}

.t-account-booking {
  .l-site-main__content {
    padding-top: $t-account-booking__l-site-main__content--no-top--padding-top;
  }

  .l-site-main__top + .l-site-main__content {
    padding-top: 0;
  }
}

// A/B test reordering of sections:
//Hide sections to not show in ab test
.fr-account-booking-participants__main-booker-container--sm-screens,
.fr-account-booking-number--sm-screens,
.fr-account-booking-details-container--sm-screens,
.fr-account-booking-documents-container--sm-screens,
.fr-account-booking-acco-info-container--sm-screens {
  display: none;
}

