/* -------------------------------------------------------------------------
 * DESTINATION DESCRIPTION
 *
 */

.t-destination-page {
  .l-site-main__top {
    margin-bottom: $t-destination-page__l-site-main__top--margin-bottom;
    @include t-mq($t-destination-page__header-bottom-decoration--bp) {
      margin-bottom: $t-destination-page__l-site-main__top--margin-bottom-bp;
    }
    &::after {
      bottom: $t-destination-page__header-bottom-decoration--bottom;
    }
  }

  .t-destination-page__top .c-breadcrumbs {
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    top: $t-destination-page__c-breadcrumbs--top;
  }
}
