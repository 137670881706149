/* -------------------------------------------------------------------------
 * COLUMNS
 *
 * Distributes content in columns after mobile breakpoint.
 *
 */

// Object selector output
// --------------------------------------------------

@if ($o-columns--enabled) {
  .o-columns {
    @include o-columns();
  }
}

// Space modifiers
// --------------------------------------------------

@if ($o-columns--enabled and $o-columns__mod-spaces--enabled) {
  @each $_space-name, $_space-value in $o-columns-spaces {
    @include o-columns--space($_space-name, $_space-value);
  }
}

// No break modifier
// --------------------------------------------------

@if ($o-columns--enabled and $o-columns__mod-no-break--enabled) {
  .o-columns--no-break {
    @include o-columns--no-break();
  }
}

// Column number variants per breakpoint
// --------------------------------------------------

@each $_s-bp-name, $_s-bp-size in $o-wrapper__mod-bp {
  @include o-columns__numbers($_s-bp-name);
}
