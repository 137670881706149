.fr-destinations-overview__header-img-container {
  @extend .fr-destination-page__header-img-container;
}

.fr-destinations-overview__header-img-dropdown {
  width: 100%;
  @include t-mq($from: $fr-destinations-overview__header-img-dropdown--bp) {
    width: auto;
    min-width: $fr-destinations-overview__header-img-dropdown--width;
  }  
}

//I need to make an override of the default margin, so I need to target a more specific selector
.t-destinations-overview .fr-destinations-overview__header-img-title.fr-destinations-overview__header-img-title {
  @include t-shared-header-img__title();
  @include sp-stack($fr-destinations-overview__header-img-title--sp-stack);
}
