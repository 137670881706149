/* -------------------------------------------------------------------------
 * ROOM DROPDOWN
 *
 */

.w-room-dropdown {
  position: relative;

  .c-floating-box__footer {
    padding: $f-space--small 0 0 0;
    @include t-clearfix;
  }
  [data-c-dropdown__action="cancel"] {
    float: left;
  }
}

.w-room-dropdown__floating-box {
  height: 50vh;
  top: auto;
  border-top: $w-room-dropdown__floating-box--border-size $w-room-dropdown__floating-box--border-type $w-room-dropdown__floating-box--border-color;
  box-shadow: $w-room-dropdown__floating-box--shadow rgba($w-room-dropdown__floating-box--shadow-color, $w-room-dropdown__floating-box--shadow-opacity);
  padding-left: $f-space--tiny;
  padding-right: $f-space--tiny;
  padding-top: $f-space--tiny;
  @include t-mq($from: $f-breakpoint--xs) {
    &.c-floating-box--floats-from\@xs {
      height: auto;
    }
  }

  .c-floating-box__footer {
    padding-left: $f-space;
    padding-right: $f-space;
  }
}
