/* -------------------------------------------------------------------------
 * RANGE VARS
 *
 */

$c-range--disabled--opacity:                        .5 !default;

$c-range__label--stack:                             $f-space--none !default;

$c-range__handle--color:                            $f-color__state-info !default;
$c-range__handle--border-size:                      1px !default;
$c-range__handle--border-type:                      solid !default;
$c-range__handle--border-color:                     $f-color__neutral-grey--semi-dark !default;
$c-range__handle--active--border-color:             $f-color__neutral-grey--dark !default;
$c-range__handle--size:                             $f-space--tiny + $f-space--small !default;
$c-range__handle-dot--size:                         $f-space--small !default;
$c-range__handle--safe-size:                        $f-font-size--xl !default;
$c-range__handle--radius:                           $f-radius--rounded !default;
$c-range__handle--shadow:                           $f-shadow--elevated rgba($f-shadow__color, $f-shadow__opacity--elevated) !default;
$c-range__handle--cursor:                           pointer !default;

$c-range__current-values--stack:                    $f-space--medium !default;
$c-range__current-values--separator-content:        "-" !default;
$c-range__current-values--separator-inset:          $f-space--tiny !default;
$c-range__current-values--color:                    $f-color__neutral-grey--dark !default;
$c-range__current-values--font-size:                $f-font-size--s !default;

$c-range__line--color:                              $f-color__neutral-grey--light !default;
$c-range__line--active-color:                       $f-color__state-info !default;
$c-range__line--disabled--active-color:             $f-color__neutral-grey--semi-light !default;
$c-range__line--size:                               ($f-font-size--xs / 3) !default;
