
/* -------------------------------------------------------------------------
 * THEME romantic Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-romantic--enabled {
  .cs-theme-romantic {
    @include color-scheme($cs-theme-romantic)
  }
}
