/* -------------------------------------------------------------------------
 * COLUMNS
 *
 * Distributes content in columns after mobile breakpoint.
 *
 */

// Object as a mixin
// --------------------------------------------------

@mixin o-columns($_space-value: $o-columns__space) {
  column-count: 1;
  column-gap: $_space-value;
  overflow-wrap: break-word;
}

// Space modifiers
// --------------------------------------------------

@mixin o-columns--space($_space-name: "medium", $_space-value: 0) {
  .o-columns--space-#{$_space-name} {
    column-gap: s-core-px-to-rem($_space-value);
  }
}

// Columns per breakpoint mixin
// --------------------------------------------------

@mixin o-columns--numbers($_s-bp-name: null) {
  @each $_column-number in $o-columns__number {
    .o-column--1\/#{$_column-number}\@#{$_s-bp-name} {
      @include t-mq($from: $_s-bp-name) {
        column-count: $_column-number;
      }
    }
  }
}

// No break modifier
// --------------------------------------------------

@mixin o-columns--no-break() {
  & > * {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}

// Unset as mixin
// --------------------------------------------------

@mixin o-columns--unset() {
  column-count: inherit;
  column-gap: inherit;
  overflow-wrap: inherit;
}

@mixin o-columns--no-break--unset {
  & > * {
    break-inside: inherit;
    page-break-inside: inherit;
  }
}
