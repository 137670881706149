/* -------------------------------------------------------------------------
 * LOADER VARIABLES
 */

$c-loader--size:                            6rem !default;
$c-loader--border-width:                    .1em !default;
$c-loader--border-color:                    $f-color__brand-primary !default;
$c-loader--border-opacity:                  .2 !default;
$c-loader--duration:                        $f-duration--sluggish !default;
$c-loader--easing:                          $f-easing--loopable !default;
$c-loader--pseudo-element:                  $m-icons__pseudo-element !default;


// Size modifiers
// -----------------------------------------

// Tiny
$c-loader--tiny--size:                      1.25rem !default;
$c-loader--tiny--border-width:              .2em !default;

// Small
$c-loader--small--size:                     3rem !default;
$c-loader--small--border-width:             .15em !default;
