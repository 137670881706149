/* -------------------------------------------------------------------------
 * BOOKING MESSAGE
 *
 */

.w-booking-message {
    &.is-hidden {
        display: none;
    }
}

.w-booking-message__text {
    font-size: $w-booking-message__text--font-size;
    .w-booking-message__text{
        > :not(:last-child){
            @include sp-stack($w-booking-message__text-not-last-child--sp-stack);
        }
        > :last-child{
            @include sp-stack($w-booking-message__text--sp-stack);
        }
    }
}

.w-booking-message__title {      
    @include sp-stack($w-booking-message__title--sp-stack);
}

.w-booking-message__box {
    @include sp-inset($w-booking-message__box--sp-inset);
}