/* -------------------------------------------------------------------------
 * PROMOTION IMAGE VARS
 */

$c-promotion-image--bp:                                     "sm" !default;

$c-promotion-image--padding--desktop:                       $f-space--giant + $f-space--medium !default;
$c-promotion-image--padding--tablet:                        $f-space--large + $f-space--medium !default;
$c-promotion-image--padding--mobile:                        $f-space--large !default;

$c-promotion-image__bg-image--gradient--dark:               $f-color__neutral-black, $f-color__neutral-black--transparent !default;
$c-promotion-image__bg-image--gradient--light:              $f-color__neutral-white, $f-color__neutral-white--transparent !default;
$c-promotion-image__bg-image--gradient--opacity:            .2 !default;

$c-promotion-image__contents--sp-stack:                     $f-space--medium !default;
$c-promotion-image__contents--sp-inner-stack:               $f-space--small !default;
$c-promotion-image__contents--max-width--desktop:           80% !default;
$c-promotion-image__contents-center--max-width--desktop:    70% !default;
$c-promotion-image__contents--z-index:                      $f-z-index--2 !default;

$c-promotion-image__link--z-index:                          $f-z-index--1 !default;

$c-promotion-image__title--material:                        map_get($m-heading__mod-sizes, "large") !default;
$c-promotion-image__title--material--huge--desktop:         map_get($m-heading__mod-sizes, "huge") !default;
$c-promotion-image__body--material:                         map_get($m-heading__mod-sizes, "small") !default;
$c-promotion-image__body--material--huge--desktop:          map_get($m-heading__mod-sizes, "medium") !default;

$c-promotion-image__light-img--contents--color:             $f-color__neutral-black !default;
$c-promotion-image__light-img--text-shadow:                 $f-shadow--text rgba($f-color__neutral-white, $f-shadow__opacity--text) !default;

$c-promotion-image--position-vertical--top:                 25% !default;
$c-promotion-image--position-vertical--middle:              50% !default;
$c-promotion-image--position-vertical--bottom:              75% !default;

$c-promotion-image__grouped--column-gap:                    $f-space--small !default;
$c-promotion-image__grouped--row-gap:                       $f-space--small !default;
$c-promotion-image__grouped--row-gap--desktop:              $f-space--medium !default;
$c-promotion-image__grouped--image-size--default:           296px !default;
$c-promotion-image__grouped--image-size--small:             240px !default;
$c-promotion-image__grouped--image-size--big:               302px !default;
$c-promotion-image__grouped--image--secondary--padding:     $f-space--large !default;
$c-promotion-image__grouped__title--secondary--material:    map_get($m-heading__mod-sizes, "medium")  !default;
$c-promotion-image__grouped__title--secondary--font-size:   $f-font-size--xl !default;

/*video*/

$c-promotion-image__video__full-height:                     100% !default;

/*solid color*/

$c-promotion-image__solid-color__bc:                        $f-color__brand-primary !default;
$c-promotion-image__solid-color--max-width--desktop:        50% !default;
$c-promotion-image__solid-color--title-line-height--desktop:$f-space--large !default;
