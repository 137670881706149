/* -------------------------------------------------------------------------
 * CARD AVATAR VARS
 */

$c-card-avatar--breakpoint-tablet:                   $f-breakpoint--sm !default;

$c-card-avatar__headings--sp-inline:                 $f-space--small !default;

$c-card-avatar__text--mobile-font-size:              $f-font-size--s !default;

$c-card-avatar__avatar--size:                        60px !default;
$c-card-avatar__avatar--radius:                      50% !default;
$c-card-avatar__avatar--sp-inline:                   $f-space--small !default;
