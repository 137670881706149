.is-unresolved {
  .w-booking-participants-form{
    .c-form__item > *{
      @include a-unresolved();
      &::before, &::after{
        z-index: 1;
      }   
    }
  }
}

.w-booking-participants-form__modal-error{
  .c-modal__footer{
    text-align: center;
  }
}

/// Specific styles for ParticipantsForm Collapsed
.w-booking-participants-form{
  .e-form-label::after {
    display: inline;
  }
  .c-form-section__title{
    font-weight: $w-booking-participants-form__title--font-weight;
  }
  .w-booking-participants-form__header-selected{
    .c-form-section__title{
      color: $w-booking-participants-form--selected-color;
      font-weight: $w-booking-participants-form__title-selected--font-weight;
    }
    .c-form__section-heading-icon{
      color: $w-booking-participants-form--selected-color;
    }
  }

  // Specific styles for GRID layout
  .c-form__section--collapsed{
    .c-form__fieldset .c-form__fieldset-title-container {
      margin-bottom: 0;
    }
  
    .w-booking-participants-form__participant-fieldset {
      .c-title {
        font-size: 18px;
      }
    }
    .c-form__fieldset-container{
      margin-left: 0;
      margin-top: 0;
      column-gap: $w-booking-participants-form--column-grid-gap;
    }
    .c-form__item{
      margin-left: 0;
      padding-left: 0;
    }
  
    // added to be able to make a break line between items
    break{
      flex-basis: $w-booking-participants-form__item--full-width;
      width: 0px; 
      height: 0px; 
      overflow: hidden;
    }
    
    // 3 columns size
    .c-form__fieldset-container .c-form__item[data-w-booking-participants-form__field-type="Name"],
    .c-form__fieldset-container .c-form__item[data-w-booking-participants-form__field-type="Surname"],
    .c-form__fieldset-container .c-form__item[data-w-booking-participants-form__field-type="StreetName"],
    .c-form__fieldset-container .c-form__item[data-w-booking-participants-form__field-type="CityName"],
    .c-form__fieldset-container .c-form__item[data-w-booking-participants-form__field-type="Country"],
    .c-form__fieldset-container .c-form__item[data-w-booking-participants-form__field-type="Email"],
    .c-form__fieldset-container .c-form__item[data-w-booking-participants-form__field-type="MobilePhoneNumber"],
    .c-form__fieldset-container .c-form__item[data-w-booking-participants-form__field-type="EmergencyPhoneNumber"]{
      @include c-form__item--size($w-booking-participants-form__item--full-width, $w-booking-participants-form__item--full-width, $w-booking-participants-form__item--third-width);
    }

    .c-form__fieldset-container .c-form__item[data-w-booking-participants-form__field-type="Birthdate"]{
      @include c-form__item--size($w-booking-participants-form__item--full-width, $w-booking-participants-form__item--full-width, $w-booking-participants-form__item--twice-three-width);
    }
  
    // 6 columns size
    .c-form__fieldset-container .c-form__item[data-w-booking-participants-form__field-type="StreetNumber"],
    .c-form__fieldset-container .c-form__item[data-w-booking-participants-form__field-type="StreetNumberAdditional"],
    .c-form__fieldset-container .c-form__item[data-w-booking-participants-form__field-type="PostalCode"] {
      @include c-form__item--size($w-booking-participants-form__item--full-width, $w-booking-participants-form__item--full-width, $w-booking-participants-form__item--sixth-width);
    }
  }
}
