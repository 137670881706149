/* -------------------------------------------------------------------------
 * SUBSCRIBE FORM
 */

.w-subscribe-form {
    max-width: $w-subscribe-form--max-width;
    margin-left: auto;
    margin-right: auto;
}

.w-subscribe-form__content {
  text-align: center;
}

.w-subscribe-form__logo.w-subscribe-form__logo {
  @include sp-stack($w-subscribe-form__logo-img--margin-bottom);
}

.w-subscribe-form__logo-img {
  height: $w-subscribe-form__logo-img--height;
  width: auto;
}

.w-subscribe-form__title {
  @include sp-stack($w-subscribe-form__title--margin-bottom);
}

.w-subscribe-form__body {
  > *:last-child {
    margin-bottom: 0;
  }

  ul {
    text-align: left;
  }
}

.w-subscribe-form__form-validation-message {
  color: $f-color__state-danger;
  font-family: $w-subscribe-form__form-validation-message--font-family;
  font-size: $w-subscribe-form__form-validation-message--font-size;
  text-align: left;
}

@if($c-form--sp-stack > $w-subscribe-form__form-validation-message--margin-top) {
  .c-form__section + .w-subscribe-form__form-validation-message {
    margin-top: -($c-form--sp-stack - $w-subscribe-form__form-validation-message--margin-top);
  }
}

.w-subscribe-form {
  .c-form__fieldset-container {
    @include c-form__fieldset-container--sp-stack-children($w-subscribe-form__space-stack-children);
  }
}

.w-subscribe-form__email-success-text, .w-subscribe-form__email-error-text {
  padding: $w-subscribe-form__email-success-text--padding;
  display: none;
}

.w-subscribe-form__email-error-text {
  margin-top: $w-subscribe-form__email-error-text--margin-top;
}

.w-subscribe-form__is-success {
  .w-subscribe-form__email-success-text {
     display: block;
  }

  .w-subscribe-form__form {
    display: none;
  }
}

.w-subscribe-form__is-error {
  .w-subscribe-form__email-error-text {
     display: block;
  }
}
