$w-booking-cancellation-flow-success__icon--color:                      $f-color__state-success  !default;
$w-booking-cancellation-flow-error__icon--color:                        $f-color__neutral-grey  !default;

$w-booking-cancellation-flow__loader-container--margin:                 $f-space--small !default;
$w-booking-cancellation-flow__loader--padding-bottom:                   $f-space--medium !default;

$w-booking-cancellation-flow__prices-summary--background-color:         $f-color__state-info--ultra-light !default;
$w-booking-cancellation-flow__prices-summary--border-style:             solid !default;
$w-booking-cancellation-flow__prices-summary--border-width:             1px !default;
$w-booking-cancellation-flow__prices-summary--border-color:             $f-color__state-info--semi-light !default;
$w-booking-cancellation-flow__prices-wrapper--margin-bottom:            16px !default;
$w-booking-cancellation-flow__prices-summary--spacing:                  16px!default;
$w-booking-cancellation-flow__prices-wrapper-even-child--padding-left:  $f-space--small !default;
$w-booking-cancellation-flow__separator:                                2px solid $f-color__neutral-grey--semi-light !default;
$w-booking-cancellation-flow__percentage-amount-text--color:            $f-color__neutral-grey--semi-dark !default;
$w-booking-cancellation-flow__amount-to-pay--color:                     $f-color__state-info !default;
$w-booking-cancellation-flow__prices-summary__support-item--color:      $f-color__neutral-grey--semi-dark !default;
$w-booking-cancellation-flow__prices-summary-label--font-size:          $f-font-size--s !default;
