.w-dd-grid__navigation-title{
    text-transform: unset;
  }

.w-dd-grid__navigation-add-next-month-column .c-btn{
  border-color: $f-color__neutral-grey--light;
}

.w-room-list__room-title-text {
  text-transform: capitalize;
}
