/* -------------------------------------------------------------------------
 * FAQ
 *
 */
%fr-faq--pseudo-elements {
  height: 1px;
  right: 0;
  left: 0;
  content: "";
  display: block;
  position: absolute;
}

.t-faq .t-faq__background-img--withdrawn{
  height: auto;
}

.fr-faq-categories__grid-item__heading .c-title {
  transform: translateY($fr-faq-categories__grid-item__heading--transform);
}

.t-faq__question-answer,
.fr-faq-categories__grid-item__list-item.fr-faq-categories__grid-item__list-item,
.fr-faq-contact-cards .c-accordion__item {
  position: relative;
  &::before {
    @extend %fr-faq--pseudo-elements;
    top: 0;
  }
}

.fr-faq-categories__grid-item__list-item.fr-faq-categories__grid-item__list-item:last-child,
.fr-faq-contact-cards .c-accordion__item:last-child {
  border-bottom: 0;
  position: relative;

  &::after {
    @extend %fr-faq--pseudo-elements;
    bottom: 0;
  }
}

.t-faq__question-title {
  @include sp-stack($t-faq__question-title--margin-bottom);
}

.t-faq__question-answer {
  padding-top: $t-faq__question-answer--padding-top;
}

.w-faq-feedback__question {
  font-size: $w-faq-feedback__question--font-size;
}

.fr-faq-categories__grid-item__list-item {

  @include t-mq($from: $fr-faq-categories__breakpoint-mobile) {

    &:first-child {
      padding-top: $fr-faq-categories__grid-item__list-item--padding-top-from-bp;
    }

    &:not(:first-child)::before {
      display: none;
    }

    &.fr-faq-categories__grid-item__list-item:last-child::after {
      display: none;
    }
  }
}
