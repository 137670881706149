/* -------------------------------------------------------------------------
* PACKAGE ITEM
*
*/

.c-package-item {
  box-shadow: $c-package-item--shadow rgba($c-package-item--shadow-color, $c-package-item--shadow-opacity);
  position: relative;
  background-color: $c-package-item--bg-color;
  transition: box-shadow $f-duration--normal $f-easing--base;

  .c-rating {
    color: $c-package-item__rating--color;
  }

  .c-label {
    position: absolute;
    top: $c-package-item__label--top;
    left: $c-package-item__label--left;
    text-transform: capitalize;
  }

  .c-number-score__label {
    display: none;
  }

  .c-number-score {
    position: absolute;
    top: $c-package-item__score--top;
    right: $c-package-item__score--right;

    .c-number-score__body {
      margin-right: $c-package-item__score--margin-right;
    }
  }

  .c-package-block__included {
    margin-bottom: $c-package-item__package-block--margin-bottom;
    font-size: $c-package-item__package-block--font-size;
  }

  .c-price__info-icon {
    position: relative;
    z-index: $f-z-index--1;
  }

  .c-price__legend,
  .c-price__label {
    font-size: $c-package-item__price-info-text--overwrite--font-size;
  }

  &:hover {
    box-shadow: $c-package-item--shadow $c-package-item--shadow-color--hover;
  }
}

.c-package-item__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
  @include sp-inset($c-package-item__body--padding-v $c-package-item__body--padding-h);
}

.c-package-item__package-row,
.c-package-item__price {
  flex-grow: 1;
}

.c-package-item__price {
  color: $c-package-item__price-color;

  margin-bottom: -$f-space--small;
}

.c-package-item__body-heading {
  margin-top: $c-package-item--alignment-fix;
  @include sp-stack($c-package-item__body-heading--sp-stack);

  @include t-mq($until: $f-breakpoint--sm) {
    width: 75%;
  }

  .c-breadcrumbs {
    max-width: $c-package-item__title--max-width;
  }
}

.c-package-item__title {
  max-width: $c-package-item__title--max-width;
  @include m-heading--complete($c-package-item__title--material);
  @include t-text-crop-disable();
}

.c-package-item__body-footer {
  margin-top: $c-package-item--alignment-fix;
  width: 100%;
  display: flex;
  align-items: flex-end;

  > div {
    display: block;

    &:last-child {
      text-align: right;
    }
  }
}

.c-package-item__package-row .c-package-block {
  display: flex;
}

.c-package-item__cta-button {
  margin-top: $c-package-item__cta-button--margin-top;
  z-index: $c-package-item__cta-button--z-index;

  a[target='_blank'] {
    display: none !important;
  }
  @include t-mq($from: $c-package-item--mq) {
    a {
      display: none !important;
    }

    a[target='_blank'] {
      display: inline-block !important;
    }
  }
}

// Variants
// -------------------------------------

// Listed

.c-package-item--listed {
  @include package-item--listed();

  .c-package-item__img {
    @include t-mq($from: $c-package-item--mq) {
      width: $c-package-item__img--listed--from-mq--size;
      min-width: $c-package-item__img--listed--from-mq--size;
    }
  }

  .c-package-item__body {
    @include t-mq($from: $c-package-item--mq) {
      height: $c-package-item__img--listed--from-mq--size;
      @include sp-inset($c-package-item__body--listed--from-mq--padding);
    }
  }

  @include t-mq($until: $c-package-item--mq) {
    @include package-item--listed-condensed();
  }
  @include t-mq($until: $c-package-item-legend--mq) {
    .c-price__legend {
      display: none;
    }
  }
}

.c-package-item__link {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  &[target='_blank'] {
    display: none !important;
  }
  @include t-mq($from: $c-package-item--mq) {
    & {
      display: none !important;
    }

    &[target='_blank'] {
      display: inline-block !important;
    }
  }
}
