/* -------------------------------------------------------------------------
 * MEDIA
 *
 * Place any image-like and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 *
 */


// Object selector output
// --------------------------------------------------

@if ($o-media--enabled) {
  .o-media {
    @include o-media();
  }

  .o-media__img {
    @include o-media__img();
  }

  .o-media__body {
    @include o-media__body();
  }
}

// Reverse modifier
// --------------------------------------------------

@if ($o-media--enabled and $o-media__mod-reverse--enabled) {
  @include o-media__mod-reverse();
}
