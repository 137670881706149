/* -------------------------------------------------------------------------
* Product Map
*
*/

$w-product-map__modal-close--icon-color:                            $f-color__state-info--semi-dark !default;
$w-product-map__modal-close--size:                                  36px !default;

$w-product-map__card-title--font-family:                            $f-font-family__primary !default;
$w-product-map__card-title--font-size:                              $f-font-size--m !default;
$w-product-map__card-title--font-weight:                            700 !default;
