/* -------------------------------------------------------------------------
 * MEDIA MIXINS
 *
 */


// Object as a mixin
// --------------------------------------------------

@mixin o-media() {
  display: block;
  @include t-clearfix;
}

@mixin o-media__img() {
  float: left;

  > img {
    display: block;
  }
}

@mixin o-media__body() {
  overflow: hidden;
  display: block;

  &,
  > :last-child {
    margin-bottom: 0;
  }
}

// Reverse modifier
// --------------------------------------------------

@mixin o-media__img--reverse() {
  float: right;
}

@mixin o-media__mod-reverse() {
  .o-media--reverse {
    > .o-media__img {
      @include o-media__img--reverse;
    }
  }
}

// Unset as mixin
// --------------------------------------------------

@mixin o-media--unset() {
  display: inherit;
  @include t-clearfix--unset;
}

@mixin o-media__img--unset() {
  float: inherit;

  > img {
    display: inherit;
  }
}

@mixin o-media__body--unset() {
  overflow: inherit;
  display: inherit;
}
