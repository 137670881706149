// Icon only
.c-btn--icon-only,
a.c-btn--icon-only {
  padding: 0;
  border: none;
  border-radius: 0;
  min-width: 0;
  background-color: transparent;

  @include t-text-crop-disable();

  .c-btn__icon {
    margin: 0;
    height: $c-btn-icon-only--size;
    padding-left: $c-btn-icon-only--size;
    &::#{$m-icons__pseudo-element} {
      font-size: $c-btn-icon-only--size;
    }
  }

  &:hover,
  &:active,
  &:focus,
  &[disabled] {
    background-color: transparent;
    box-shadow: none;
  }

}
