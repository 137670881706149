/* -------------------------------------------------------------------------
 * FORM VARIABLES
 *
 */


$e-form-elements--line-height:                            $f-line-height--small !default;
$e-form-elements--font-size:                              $s-core__font-size !default;

$e-form-elements--height:                                 $e-form-elements--line-height * 5em !default;
$e-form-elements--sp-inset-h:                             $f-space--small !default;
$e-form-elements--sp-inset-v:                             $f-space--small !default;

$e-form-elements__field--color:                           $f-color__neutral-grey--ultra-dark !default;
$e-form-elements__field--bg-color:                        $f-color__neutral-white !default;
$e-form-elements__field--border--size:                    1px !default;
$e-form-elements__field--border--type:                    solid !default;
$e-form-elements__field--border-color:                    $f-color__neutral-grey !default;
$e-form-elements__field--border-radius:                   $f-radius--medium !default;
$e-form-elements__field--font-family:                     $f-font-family__accent !default;
$e-form-elements__field--font-size:                       $f-font-size--s !default;
$e-form-elements__field--line-height:                     $e-form-elements--line-height !default;


$e-form-elements--focus--color:                           $f-color__brand-primary !default;
$e-form-elements--focus--opacity:                         .8 !default;

$e-form-elements__label--display:                         block !default;
$e-form-elements__label--color:                           $f-color__neutral-grey--dark !default;
$e-form-elements__label--font-family:                     $f-font-family__primary !default;
$e-form-elements__label--font-family--crop:               $f-font-family__primary--crop !default;
$e-form-elements__label--font-size:                       $f-font-size--m !default;
$e-form-elements__label--font-size--strong:               $f-font-size--s !default;
$e-form-elements__label--font-weight:                     normal !default;
$e-form-elements__label--line-height:                     $f-line-height--tiny !default;
$e-form-elements__label--strong--font-weight:             700 !default;
$e-form-elements__label--text-transform:                  none !default;
$e-form-elements__label--strong--text-transform:          uppercase !default;
$e-form-elements__label--space-stack:                     $f-space--small !default;

$e-form-elements__icon--color:                            $f-color__neutral-grey !default;
$e-form-elements__placeholder--color:                     $f-color__neutral-grey !default;

$e-form-elements__clear--color:                           $f-color__neutral-grey !default;
$e-form-elements__clear--color--hover:                    $f-color__neutral-grey--semi-dark !default;

$e-form-elements__arrow--color:                           $f-color__neutral-grey--ultra-dark !default;
$e-form-elements__arrow--bg-color:                        $f-color__neutral-black--transparent !default;

$e-form-elements__inner-element--color:                   $f-color__state-info !default;

// Label with tooltip

$e-form-label__container--margin-bottom:                  $f-space--small !default;
$e-form-label__container--max-width:                      calc(100% - #{$f-space--medium}) !default;

$e-form-label__tooltip--margin-left:                      $f-space--tiny !default;
$e-form-label__tooltip--font-size:                        $f-font-size--l !default;
$e-form-label__tooltip--transform:                        translateX(calc(100% + #{$e-form-label__tooltip--margin-left})) !default;
$e-form-label__tooltip--z-index:                          $f-z-index--2 !default;

// States
// -----------------------------------------

// Disabled
$e-form-elements--disabled--opacity:                      .5 !default;
$e-form-elements--disabled--color:                        $f-color__neutral-grey--dark !default;
$e-form-elements--disabled--bg-color:                     $f-color__neutral-grey--light !default;
$e-form-elements__arrow--disabled--color:                 $f-color__neutral-grey--dark !default;
$e-form-elements__inner-element--disabled--color:         $f-color__neutral-grey !default;
$e-form-elements__with-inner-element--disabled--border-color:    $f-color__neutral-grey--semi-light !default;

// Hover
$e-form-elements--hover--bg-color:                        $f-color__neutral-grey--ultra-light !default;
$e-form-elements--hover--border-color:                    $f-color__neutral-grey--semi-dark !default;
$e-form-elements--hover--extra-border--color:             $f-color__neutral-grey--ultra-light !default;

// Focus / :active
$e-form-elements--focus--bg-color:                        $f-color__neutral-grey--ultra-light !default;
$e-form-elements--focus--border-color:                    $f-color__state-info !default;
$e-form-elements--focus--extra-border--color:             $f-color__neutral-grey--ultra-light !default;

// Pressed
$e-form-elements--pressed--bg-color:                      $f-color__neutral-grey--semi-light !default;
$e-form-elements--pressed--border-color:                  $f-color__neutral-white !default;
$e-form-elements--pressed--extra-border--color:           $f-color__neutral-grey--semi-light !default;

// Selected / Checked
$e-form-elements--selected--color:                        $f-color__neutral-grey--semi-light !default;
$e-form-elements--selected--bg-color:                     $f-color__neutral-white !default;
$e-form-elements--selected--border-color:                 $f-color__neutral-grey--semi-light !default;

// Success
$e-form-elements--success--border-color:                  $f-color__state-success !default;

// Warning
$e-form-elements--warning--border-color:                  $f-color__state-warning !default;

// Error
$e-form-elements--error--border-color:                    $f-color__state-danger !default;
