// Flat
.c-btn--flat,
a.c-btn--flat,
.c-btn--flat-neutral,
a.c-btn--flat-neutral {
  color: $c-btn-flat--color;
  background-color: $c-btn-flat--bg-color;
  border-color: $c-btn-flat--border-color;
  min-width: 0;
  border-radius: 0;
  @include sp-inset-h($f-space--tiny);

  .c-btn__loader {
    @include c-loader__set-color($c-btn-flat--color);
  }

  &:hover,
  &:active,
  &:focus,
  &[disabled] {
    background-color: transparent;
    box-shadow: none;
  }

  &:hover {
    color: $c-btn-flat--hover--color;
    background-color: $c-btn-flat--hover--bg-color;
    border-color: $c-btn-flat--hover--border-color;

    .c-btn__loader {
      @include c-loader__set-color($c-btn-flat--hover--color);
    }
  }

  &:active {
    color: $c-btn-flat--pressed--color;
    background-color: $c-btn-flat--pressed--bg-color;
    border-color: $c-btn-flat--pressed--border-color;

    .c-btn__loader {
      @include c-loader__set-color($c-btn-flat--pressed--color);
    }
  }
}

.c-btn--flat-neutral,
a.c-btn--flat-neutral {
  color: $c-btn-flat-neutral--color;
}
