/* -------------------------------------------------------------------------
 * CONTENT USPS
 *
 */
 
.w-content-usps__usp-block-item{
  .w-content-usps__usp-block-title{
    font-family: $w-content-usps__usp-block--font-family;
  }
  
  .w-content-usps__usp-block-subtitle {
    margin-bottom: 0;
    font-family: $w-content-usps__usp-block--font-family;
  }

  &:hover{
    .w-content-usps__usp-block-subtitle{
      text-decoration: $w-content-usps__usp-block-subtitle--hover--text-decoration;
    }
  }
}


.w-content-usps__usp-block-item {
  margin-top: $w-content-usps__usp-block-item--margin-top;
}