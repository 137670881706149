/* -------------------------------------------------------------------------
 * INSET VERTICAL MIXINS
 *
 * To give space between container and content only in top and bottom spaces
 *
 * TIP:
 * You can modify the amount of generated spaces by overriding the $sp-inset-v__spaces variable.
 *
 */

// Space as a mixin
// --------------------------------------------------

@mixin sp-inset-v($_spacing-value) {
  padding-top: $_spacing-value;
  padding-bottom: $_spacing-value;
}
