/* -------------------------------------------------------------------------
 * TRANSPORT SUMMARY SELECTOR
 *
 */

// Wrapper
.w-transport-summary__heading {
  @include sp-stack($w-transport-summary__spacing-main);
}

.w-transport-summary__heading-text,
.w-transport-summary__heading-date {
  display: inline-block;
}

.w-transport-summary__item:not(.w-transport-summary__item--empty-message) .w-transport-summary__heading-text:after {
  content: ':';
  display: inline;
  @include sp-inline($w-transport-summary__heading--separation);
}

.w-transport-summary__heading-date {
  font-family: $w-transport-summary__heading-dates--font-family;
  line-height: $w-transport-summary__heading-dates--line-height;

  .w-transport-summary__heading-date--old {
    text-decoration: line-through;
    margin-right: $w-transport-summary__heading-date--old--margin-right;
  }

  .w-transport-summary__heading-date--new {
    color: $w-transport-summary__heading-date--old--new--color;
  }
}

.w-transport-summary__selected {
  .c-transport-ticket {
    @include sp-stack($w-transport-summary__spacing-secondary);
  }
}

.w-transport-summary__items-container {
  display: flex;
  flex-direction: column;
  // Row version, 1 below another.
  // By default until defined breakpoint.
  .w-transport-summary__item {
    flex: 1;

    + .w-transport-summary__item {
      margin-top: $w-transport-summary__spacing-secondary;
    }
  }

  // Column version, 1 next to other.
  // By default after defined breakpoint, unless Row version is forced.
  &:not(.w-transport-summary--row) {
    .c-transport-ticket {
      width: 100%;
    }

    @include t-mq($from: $w-transport-summary__breakpoint) {
      flex-direction: row;

      .w-transport-summary__item {
        display: flex;
        flex-direction: column;
        + .w-transport-summary__item {
          margin-top: 0;
          margin-left: $w-transport-summary__spacing-secondary;
        }
      }

      .w-transport-summary__options {
        display: flex;
        flex-grow: 1;
      }
    }
  }
}

.w-transport-summary__flight-details {
  margin-top: $w-transport-summary__flight-details--margin-top;
  font-size: $w-transport-summary__flight-details--font-size;
  color: $w-transport-summary__flight-details--color;
}

// transport summary empty message

.w-transport-summary__item--empty-message {
  display: flex;
  flex-direction: column;

  .w-transport-summary__options {
    flex-grow: 1;
  }

  .c-transport-ticket__contents {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: $w-transport-summary__transport-ticket-contents-empty-message--min-height;
  }

  .w-transport-summary__transport-ticket-title {
    font-family: $w-transport-summary__transport-ticket-title-empty-message--font-family;
    font-weight: $w-transport-summary__transport-ticket-title-empty-message--font-weight;
    color: $w-transport-summary__transport-ticket-title-empty-message--color;
  }
}
