.fr-header-free-text-search {
  // Tune ups for live search (from medium devices)
  .w-live-search {
    @include t-mq($until: $fr-header-free-text-search--smaller-bp) {
        position: absolute;
        top: -100%;
        left: $f-space--medium;
        right: $f-space--medium;
    }

    @include t-mq($from: $fr-header-free-text-search--smaller-bp) {
      text-align: left;
      float: right;
      width: $fr-header-free-text-search--width-first-bp;

      .c-textbox__element {        
        @if($fr-header-free-text-search--element-sp-inset-squish == true) {
            @include sp-inset-squish($fr-header-free-text-search--element-sp-inset);
        }
        @else {
            @include sp-inset($fr-header-free-text-search--element-sp-inset);
        }
      }

      .c-floating-box {
        min-width: $fr-header-free-text-search__floating-box--min-width;
        left: auto;
        right: 0;
        z-index: $fr-header-free-text-search__floating-box--z-index;
      }
    }

    @include t-mq($from: $fr-header-free-text-search--small-bp) {
      width: $fr-header-free-text-search--width-second-bp;
    }

    .c-textbox__icon {
      background-color: transparent;
    }

    .c-textbox__element {
      @include t-mq($until: $fr-header-free-text-search--smaller-bp) {
        text-indent: 0
      }
    }
  }
}
