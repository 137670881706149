/* -------------------------------------------------------------------------
* Product Map
*
*/

$w-product-map--breakpoint-mobile:                     $f-breakpoint--sm !default;
$w-product-map--breakpoint-desktop:                    $f-breakpoint--md !default;

$w-product-map__modal-header--z-index:                 $f-z-index--1 !default;
$w-product-map__modal-body--padding:                   ($f-space--medium * 0.75) $f-space--medium !default;

$w-product-map__modal-close--offset:                   $f-space--small !default;
$w-product-map__modal-close--size:                     $f-space--medium !default;
$w-product-map__modal-close--background-color:         $f-color__neutral-white !default;
$w-product-map__modal-close--z-index:                  $f-z-index--1 !default;
$w-product-map__modal-close--icon-color:               $f-color__neutral-black !default;
$w-product-map__modal-close--icon-font-size:           20px !default;

$w-product-map__card--mobile-header-size:              102px !default;
$w-product-map__card--mobile-header-background:        $f-color__neutral-white;
$w-product-map__card--padding:                         $f-space--small !default;
$w-product-map__card--bottom:                          $f-space--medium !default;
$w-product-map__card--width:                           296px !default;
$w-product-map__card-body--padding-bottom:             40px !default;

$w-product-map__card-price--font-family:               $f-font-family__primary !default;
$w-product-map__card-price--font-size:                 $f-font-size--m !default;
$w-product-map__card-price--font-weight:               600 !default;

$w-product-map__card-rating--color:                    $f-color__state-warning--semi-light !default;
$w-product-map__card-rating--sp-stack:                 $f-space--tiny !default;

$w-product-map__card-score--right:                     $f-space--small !default;
$w-product-map__card-score--top:                       $f-space--small !default;
$w-product-map__card-score--font-family:               $f-font-family__secondary !default;
$w-product-map__card-score--font-size-mobile:          $f-font-size--s !default;
$w-product-map__card-score--font-size:                 $f-font-size--m !default;
$w-product-map__card-score--font-weight:               600 !default;

$w-product-map__card-score-arrow--offset:              -5px !default;
$w-product-map__card-score-arrow--height:              7px !default;
$w-product-map__card-score-arrow--width:               $f-space--small !default;

$w-product-map__card-title--font-size-mobile:          $f-font-size--s !default;
$w-product-map__card-title--font-size:                 $f-font-size--l !default;

$w-product-map__card-close--top:                       $f-space--tiny !default;
$w-product-map__card-close--right:                     38px !default;

$w-product-map__slider-buttons-arrow--left:            22px !default;
$w-product-map__slider-buttons-arrow--right:           22px !default;
$w-product-map__slider-buttons-arrow--top:             124px !default;
$w-product-map__slider--left:                          -16px !default;
$w-product-map__slider-mobile--bottom:                 -$f-space--medium !default;
$w-product-map__slider-mobile--margin:                 0 -16px !default;
$w-product-map__slider-item--padding-bottom:           $f-space--medium !default;
$w-product-map__slider-item--padding-right:            $f-space--medium !default;
$w-product-map__slider-tns-nav--bottom:                28px !default;
$w-product-map__slider-tns-inner--margin:              0px -16px 0px 16px !default;
