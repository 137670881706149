/* -------------------------------------------------------------------------
 * BOOKING BOX
 *
 */

.w-booking-box {
  @include sp-inset($w-booking-box--padding);
  @include t-mq($from: $w-booking-box--bp) {
    @include sp-inset($w-booking-box--padding-from-bp);
  }
}

.w-booking-box__description {
  font-size: $w-booking-box__description--font-size;  
}

.w-booking-box__heading {
  display: flex;
  align-items: baseline;
}

.w-booking-box__heading-icon {
  font-size: $w-booking-box__heading-icon--font-size;
  @include sp-inline($w-booking-box__heading-icon--sp-inline);
}

.w-booking-box__heading-title {
  align-self: center;
}

.w-booking-box__heading-title + .w-booking-box__heading-info {
  margin-left: $w-booking-box__heading-info--sp-inline;
}

.w-booking-box__heading-info {
  align-self: flex-start;
  color: $w-booking-box__heading-info--color;

  &:hover {
    color: $w-booking-box__heading-info--color--hover;
  }  
}

.w-booking-box__modal-button-label, .w-booking-box__heading-info{
  .c-btn__icon {
    height: $w-booking-box__heading-info--size;
    padding-left: $w-booking-box__heading-info--size;

    &::#{$m-icons__pseudo-element} {
      font-size: $w-booking-box__heading-info--font-size;
    }
  }
}
// States
// --------------------------------------

// is-loading

.is-loading .w-booking-box {
  opacity: $w-booking-box--opacity--is-loading;

  > * {
    pointer-events: none;
  }
}

//is-unresolved
.is-unresolved .w-booking-box,
.w-booking-box.is-unresolved {

  .w-booking-box__heading-info {
    display: none;
  }

  .w-booking-box__description {
    position: relative;
    font-size:  $w-booking-box__description--font-size;  

    &::before {
      content: '';
      position: absolute;
      top: $w-booking-box--unresolved-background--top;
      left: $w-booking-box--unresolved-background--left;
      height: $w-booking-box--unresolved-background--height;
      width: $w-booking-box--unresolved-background--width;
      display: block;
      background-color: $w-booking-box--unresolved-background;
      border-color: $w-booking-box--unresolved-background;
      border-radius: $w-booking-box__unresolved--border-radius;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: $w-booking-box__unresolved--width;
      display: block;
      animation: is-unresolved-animation $w-booking-box__unresolved--animation-duration infinite;
      background: linear-gradient(
          $w-booking-box__unresolved--animation-angle,
          $f-color__neutral-white--transparent,
          $w-booking-box__unresolved--animation-endcolor,
          $f-color__neutral-white--transparent
      );
    }
   
  }
}

// has-error

.has-error .w-booking-box {
  display: none;
}

// is-hidden

.is-hidden .w-booking-box {
  display: none;
}

// Collapsible
.w-booking-box__heading-label{
  &[data-c-modal-v2__id]{
    cursor: pointer;
  }
  color: $w-booking-box__heading-label--color;
  > * {
    margin-bottom: $w-booking-box__heading-label--margin-bottom;
  }
}
.w-booking-box__modal-button-label{
  color: $w-booking-box__heading-label--color;
}

.w-booking-box__collapse-trigger{
  cursor: pointer;
}

.w-booking-box__heading-icon--collapsed{
  color: $w-booking-box__heading-icon--collapsed-color;
}
.w-booking-box__heading-collapse-icon{
  margin-left: auto;
  .w-booking-box__trigger-icon-hided, .w-booking-box__trigger-icon-selected, .w-booking-box__trigger-icon-shown {
    display: flex;
    flex-direction: row;
  }
  .w-booking-box__trigger-text{
    white-space: nowrap;
  }
}

.w-booking-box__collapse-trigger{
  .w-booking-box__trigger-icon-hided{
    color: $w-booking-box__heading-info--color;
  }
}

.w-booking-box__collapse-trigger{
  .w-booking-box__trigger-icon-shown, .w-booking-box__trigger-icon-selected{
    display: none;
  }
  &[data-c-collapse__opened="true"]{
    .w-booking-box__trigger-icon-shown{
      display: flex;
    }
    .w-booking-box__trigger-icon-hided{
      display: none;
    }
  }

  .w-booking-box__title{
    font-weight: $w-booking-box__title--font-weight;
  }
}

.w-booking-box--selected, .w-booking-box--no-option-selected{
  .w-booking-box__collapse-trigger .w-booking-box__title{
    font-weight: $w-booking-box__title-selected--font-weight;
  }
}

.w-booking-box--selected{
  .w-booking-box__title, .w-booking-box__heading-icon--collapsed{
    color: $w-booking-box--selected-color;
  }
  .w-booking-box__collapse-trigger{
    .w-booking-box__trigger-icon-selected{
      display: flex;
    }
    .w-booking-box__trigger-icon-hided{
      display: none;
    }
    &[data-c-collapse__opened="true"]{
      .w-booking-box__trigger-icon-selected{
        display: none;
      }
    }
  }

}