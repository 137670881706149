/* -------------------------------------------------------------------------
 * SELECTED STATE
 *
 */

.w-selected-state {
  @include t-mq($until: $w-selected-state--breakpoint) {
    margin-top: $w-selected-state--main-space;
  }

  &:empty {
    display: none;
  }

  .c-breakdown-table__item-content{
    margin-left: 0px;
    margin-right: 0px;
  }
}

.w-selected-state__booking-overview {
  margin-bottom: $f-space;
}

.w-selected-state__img {
  margin-bottom: $f-space;
  @include t-mq($until: $w-selected-state--breakpoint) {
    display: none;
  }

  border-top-left-radius: $w-selected-state__summary--border-radius;
  border-top-right-radius: $w-selected-state__summary--border-radius;
}

.w-selected-state__conditional-message--wrapper {
  @include sp-inset($w-selected-state--secondary-space);
  border-width: $w-selected-state--main-border--size;
  border-style: $w-selected-state--main-border--type;
  @include sp-stack($w-selected-state--secondary-space)
}

.w-selected-state__conditional-message {
  margin-bottom: 0;
}

.w-selected-state__summary {
    .c-rating {
        margin-left: 0.5em;
    }
}

.w-selected-state__lists {
  margin-bottom: $f-space;
  padding: 0;
  position: relative;

  .c-bullet-list__icon {
    font-size: $w-selected-state__icon--font-size;
  }

  .c-bullet-list__item {
    display: flex;
    align-items: center;
    margin-top: -$f-space--tiny;
    &:not(.c-bullet-list__item--bold) + .c-bullet-list__item:not(.c-bullet-list__item--bold) {
      margin-top: -$f-space--small;
    }
  }

  .c-bullet-list__text {
    line-height: 1;
    font-size: 16px;
  }

  .c-bullet-list__item--bold {
    margin-top: $f-space--small;
    &:first-child {
      margin-top: 0;
    }
  }

  .c-bullet-list {
    @include sp-stack-children($f-space--none);
  }

  .w-selected-state__list--left {
    @include sp-stack-children($w-selected-state__list--left--sp-stack-children);
    margin-bottom: $w-selected-state__list--left--margin-bottom;
  }
}

.w-selected-state__usps-lists {
  .c-bullet-list__icon {
    font-size: $w-selected-state__icon--font-size;
  }

  .c-bullet-list__item {
    display: flex;
    align-items: center;
  }

  .c-bullet-list__text {
    line-height: 1;
    font-size: 16px;
  }

  .c-bullet-list {
    @include sp-stack-children($f-space--none);
  }
}

.w-selected-state__price-overview-container {
  border-spacing: 0;
}

.w-selected-state__price-overview {
  border: 1px solid $w-selected-state__price-overview--border-color;
  border-radius: $f-radius--medium;
  padding: $f-space--small;

  @include t-mq($from: $w-selected-state--breakpoint) {
    padding: $w-selected-state__price-overview--padding;
  }

  .c-breakdown-table__item.c-breakdown-table__item--total.c-table__item:last-child {
    border-top-width: 0;
  }
  
  .w-selected-state__price-overview-localcosts-table .c-table__item .c-breakdown-table__item-content--subitem .c-breakdown-table__item-left{
    color: $w-selected-state__price-overview-cost-item-description--color;
    font-size: $w-selected-state__price-overview-cost-item-description--font-size;
  }

  .w-selected-state__price-overview-localcosts-table {
    @include sp-stack($w-selected-state--secondary-space);
  }
}

.c-breakdown-table__item-right {
  white-space: nowrap;
}

.w-selected-state__price-overview--title,
.w-selected-state__booking-overview--title {
  color: $w-selected-state__price-overview-title--color;
  font-weight: $w-selected-state__price-overview-title--font-weight;
  font-size: $w-selected-state__price-overview-title--font-size;
  display: flex;
  align-items: center;

  @include t-mq($until: $w-selected-state--breakpoint) {
    margin-left: $w-selected-state--main-space;
  }
}

.w-selected-state__price-overview--title {
  @include t-mq($until: $w-selected-state--breakpoint) {
    margin-top: $w-selected-state--main-space;
  }
}

.w-selected-state__price-overview--title-text,
.w-selected-state__booking-overview--title-text {
  color: $f-color__neutral-grey--dark;
  text-transform: initial;
  padding-left: $f-space--tiny;
}

.w-selected-state__price-overview-details {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.w-selected-state__price-overview-details--extra {
  padding-left: $f-space--small;
  padding-right: $f-space--small;
  color: $f-color__neutral-grey;
}

.w-selected-state__price-overview-details--open-jaw-message {
  margin-bottom: $w-selected-state--main-space;

  .c-absolute-dialog {
    @include sp-inset($w-selected-state__open-jaw-message--sp-inset);
  }
}

.w-selected-state__price-overview-costs {
  background-color: $w-selected-state__price-overview-mandatory-costs--bg-color;
  @include sp-inset($w-selected-state--secondary-space);
  @include sp-stack($w-selected-state--secondary-space);
}

.w-selected-state__collapse-trigger-wrapper {
  color: $w-selected-state__collapse-trigger-wrapper--color;
  font-weight: $w-selected-state__collapse-trigger-wrapper--font-weight;
  margin-bottom: 0;
  display: flex;

  &[data-c-collapse__opened="true"] .m-icon--chevron-down {
    margin-bottom: $w-selected-state__collapse-trigger--chevron-alignment-fix;
  }
}

.w-selected-state__collapse-wrapper > :first-child {
  margin-top: $w-selected-state--main-space;
}

.w-selected-state__price-overview-costs-title {
  cursor: pointer;
  flex-grow: 2;
}

.w-selected-state__price-overview-cost-item {
  margin-bottom: $w-selected-state__price-overview-cost-item--margin-bottom;

  .w-selected-state__price-overview-cost-item-description {
    color: $w-selected-state__price-overview-cost-item-description--color;
    font-size: $w-selected-state__price-overview-cost-item-description--font-size;
  }
}

.w-selected-state__collapse-trigger {
  color: $w-selected-state__collapse-trigger--color;
  background-color: $w-selected-state__collapse-trigger--bg-color;
  border-radius: $w-selected-state__collapse-trigger--border-radius;
  font-size: $w-selected-state__collapse-trigger--font-size;
  margin: 0;
  height: $w-selected-state__collapse-trigger--size;
  width: $w-selected-state__collapse-trigger--size;
}

.w-selected-state__footer {
  padding: 0 0 0;
  text-align: $w-selected-state__footer--button-alignment;
}

@include t-mq($from: $w-selected-state--breakpoint) {
  .w-selected-state__body {
    display: flex;
  }

  .w-selected-state__booking-overview,
  .w-selected-state__price-overview,
  .w-selected-state__price-overview-container {
    flex: 1 1 0px;
  }

  .w-selected-state__booking-overview {
    padding-right: $w-selected-state__booking-overview--padding-right;
  }

  .w-selected-state__price-overview-details {
    margin-top: $w-selected-state__price-overview-details--padding;
  }
}

@include t-mq($until: $w-selected-state--breakpoint) {
  .w-selected-state__header,
  .w-selected-state__summary,
  .w-selected-state__price-overview {
    @include sp-stack($w-selected-state--main-space);
  }
}

.w-selected-state__footer-button {
  margin: $w-selected-state--main-space 0 $w-selected-state--main-space;
  width: 100%;
}

.w-selected-state__usps-content {
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: $f-space--small;
  @include t-mq($from: $f-breakpoint--sm) {
    flex-direction: row;
  }
}

.w-selected-state__usp {
  flex: 1 1 auto;

  @include t-mq($until: $w-selected-state--breakpoint) {
    display: flex;
    align-items: center;
  }

  @include t-mq($from: $w-selected-state__usp--bp) {
    width: $w-selected-state__usp--width;
  }

  &:not(:last-child) {
    @include sp-stack($w-selected-state__usp--sp-stack);

    @include t-mq($from: $w-selected-state__usp--bp) {
      margin-bottom: 0;
      @include sp-inline($w-selected-state__usp--sp-inline);
    }
  }
}
.w-selected-state__usp-text-wrapper {
  @include t-mq($until: $w-selected-state--breakpoint) {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    text-align:left;
    flex: 1;
  }
}

.w-selected-state__package-usp-icon {
  color: $w-selected-state__usp--color;
}

.w-selected-state__usp-title {
  display: block;
  color: $w-selected-state__usp--color;
  @include sp-stack($w-selected-state__usp-title--sp-stack);
}

// Price Overview Row
.w-selected-state__price-overview__row-main,
.w-selected-state__price-overview__row-extra-info {
  font-size: $w-selected-state__price-overview-row--font-size;
  display: flex;
}

.w-selected-state__price-overview-details--extra-info-message {
  .m-icon {
    margin-right: $w-selected-state__price-overview-details--extra-info-message-icon-margin;
  }
  p {
    display: inline;
  }
}
.w-selected-state__price-overview__row-title,
.w-selected-state__price-overview__row-services .w-selected-state__price-overview__row-main-text {
  font-weight: $w-selected-state__price-overview-row-bold--font-weight;
  @include sp-inline($w-selected-state--secondary-space);
}

.w-selected-state__price-overview__row-title,
.w-selected-state__price-overview__row-main {
  @include sp-stack($w-selected-state--secondary-space);
}
.w-selected-state__price-overview__row-title {
  margin-bottom: 6px;
}

.w-selected-state__price-overview 
.c-breakdown-table__item-content--subitem {
  .c-breakdown-table__item-left,.c-breakdown-table__item-right {
    color: $w-selected-state__price-overview__row-subitem--color;
    font-size: $w-selected-state__price-overview__row-title-subitem--font-size;
  }
}

.w-selected-state__price-overview-header-section {
  padding: $w-selected-state__price-overview-header--padding;
  border-bottom: $w-selected-state__price-overview-header--border-bottom;

  &.w-selected-state__price-overview-header-section-with-margin {
    margin-top: $w-selected-state__price-overview-header-localcosts--margin-top;
  }
}

.w-selected-state__price-overview-header {
  font-size: $w-selected-state__price-overview-header--font-size;
}

.w-selected-state__price-overview-localcosts-description {
  padding: $w-selected-state__price-overview-localcosts-description--padding;
}

.w-selected-state__price-overview__row-main-text {
  flex-grow: 2;
  align-self: center;
}

.w-selected-state__price-overview__row-main-value {

  .c-price__number {
    display: flex;
    align-items: baseline;
  }

  .c-price__value {
    font-size: $w-selected-state__price-overview-row-price-value--font-size;
  }

  .c-price__currency {
    font-size: $w-selected-state__price-overview-row-price-currency--font-size;
  }
}

.w-selected-state__price-overview__row-services {
    font-weight: 400;

  .w-selected-state__price-overview__row-main-value {
    display: flex;
    align-items: flex-end;
  }
}

.w-bullet-list__usps {
  padding: 0 $f-space--small 0;
  margin-left: 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @include t-mq($until: $w-selected-state--breakpoint) {
    display: flex;
    flex-direction: column;
  }

  .c-bullet-list__icon {
    color: $f-color__state-success;
  }

  .c-bullet-list__item {
    margin-left: 0;

    @include t-mq($until: $w-selected-state--breakpoint) {
      width: 100%;
    }
  }
}

.w-overview__optional-services:not(:empty) {
  padding-top: $f-space--medium;
  border-top: 2px solid $f-color__neutral-grey--ultra-light;

  @include t-mq($from: $f-breakpoint--sm) {
    padding-left: $f-space--small;
  }

  .c-bullet-list__text {
    line-height: 1.25;
  }
}

.w-overview__optional-services .c-bullet-list {
  padding-left: $f-space--none;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(15rem,1fr));
  gap: 1rem;

  @include t-mq($until: $w-selected-state--breakpoint) {
    margin-left: $w-selected-state--main-space;
  }
}

.w-overview__optional-service-promotion-label {
  background-color: $w-selected-state__price-overview-optional-service-label--color-bg;
  color: $w-selected-state__price-overview-optional-service-label--color-text;
  font-size: $w-selected-state__price-overview-optional-service-label--font-size;
  font-family: $w-selected-state__price-overview-optional-service-label--font-family;
  margin-bottom: $f-space--tiny;
  @include sp-inset-squish($w-selected-state__price-overview-optional-service-label--sp-inset);

  @include t-mq($until: $w-selected-state--breakpoint) {
    margin-left: 12px;
  }
}

.w-overview__optional-service-tag-line {
  color: $w-selected-state__price-overview-optional-service-tag-line--color-text;
  font-weight: $w-selected-state__price-overview-row-bold--font-weight;
  margin-bottom: $f-space--small;
  display: block;

  @include t-mq($until: $w-selected-state--breakpoint) {
    margin-left: 12px;
  }
}

.w-selected-state__basket-usps-content {
  border-top: 2px solid $f-color__neutral-grey--ultra-light;
  padding-top: $f-space;

  .usp {
    font-size: 1.25em;
    padding-left: 1.75em;

    &::before {
      font-size: 1.75em;
      line-height: .75em;
    }
  }
}

.c-table__item:first-child {
  border-top-width:0;
}

.w-selected-state__price-overview__row-services--inclusive .c-breakdown-table__item-right {
  font-style: italic;
}

.w-selected-state__price-overview-header {
  font-size: $w-selected-state__price-overview-header--font-size;
}

.w-selected-state__price-overview-total {
  font-size: $w-selected-state__price-overview-total--font-size;
  color: $w-selected-state__price-overview-total--color;
  display: flex;

  ~ .w-selected-state__price-overview__row {
    margin-bottom: $w-selected-state--main-space;
    font-weight: $w-selected-state__price-overview-row-bold--font-weight;

    .w-selected-state__price-overview__row-main {
      align-items: flex-end;
    }
  }
}

.w-selected-state__price-overview-total-text {
  flex-grow: 2;
  font-weight: $w-selected-state__price-overview-total--font-weight;
}

.w-selected-state__price-overview {
  .c-breakdown-table__item--total {

    .w-selected-state__price-overview__grossprice {
      .c-breakdown-table__item-left,
      .c-breakdown-table__item-right {
        padding-bottom: 0;
        text-decoration: line-through;
        color: $f-color__neutral-grey--dark;
        font-weight: $w-selected-state__list--right--font-weight;
      }
    }
    
    .w-selected-state__price-overview__item--highlighted {
      .c-breakdown-table__item-left,
      .c-breakdown-table__item-right {
        color: $f-color__state-info--semi-dark;
        font-size: $f-font-size--xl;
      }
    
      .c-breakdown-table__item-right {
        font-weight: 700;
        white-space: nowrap;
      }
    }
        
    .c-breakdown-table__item-content--subitem {
      .c-breakdown-table__item-left,
      .c-breakdown-table__item-right {
        color: $f-color__neutral-grey--dark;
        font-size: $w-selected-state__price-overview-row--font-size;
        font-weight: $w-selected-state__list--right--font-weight;
      }

      &.w-selected-state__price-overview__total-subitem {
        .c-breakdown-table__item-left,
        .c-breakdown-table__item-right {
          font-weight: $w-selected-state__price-overview-total-subitem--font-weight;
          color: $w-selected-state__price-overview-total-subitem--color;
          text-transform: initial;
          margin-top: $w-selected-state__price-overview-total-subitem--margin-top;
        }

        &.w-selected-state__price-overview__total-subitem-first {
          margin-top: $w-selected-state--secondary-space;
          padding-top: $w-selected-state--secondary-space / 2;
          border-top: $w-selected-state__price-overview-total-subitem-first--border;
        }
      }
    }
  }

  .w-selected-state__price-subtotal {
    color: $w-selected-state__price-overview-subtotal--color;
    font-weight: $w-selected-state__price-overview-subtotal--font-weight;
  }
}
