/* -------------------------------------------------------------------------
 * UN RESOLVED
 *
 */

// Animation as a mixin
// --------------------------------------------------

@mixin a-unresolved(
  $_top-before: $a-unresolved__background--top, 
  $_left-before: $a-unresolved__background--left,
  $_height-before: $a-unresolved__background--height,
  $_width-before: $a-unresolved__background--width,
  $_background-color: $a-unresolved__background,
  $_border-color: $a-unresolved__background,
  $_border-radius: $a-unresolved__border--radius,
  $_top-after: $a-unresolved__position--top,
  $_left-after: $a-unresolved__position--left,
  $_height-after: $a-unresolved__height,
  $_width-after: $a-unresolved__width,
  $_duration: $a-unresolved__duration,
  $_angle: $a-unresolved__angle,
  $_startcolor: $a-unresolved__startcolor,
  $_endcolor: $a-unresolved__endcolor,
  ) {
  position: relative;
        
  &::before {
    content: '';
    position: absolute;
    top: $_top-before;
    left: $_left-before;
    height: $_height-before;
    width: $_width-before;
    display: block;
    background-color: $_background-color;
    border-color: $_border-color;
    border-radius: $_border-radius;
  }

  &::after {
    content: '';
    position: absolute;
    top: $_top-after;
    left: $_left-after;
    height: $_height-after;
    width: $_width-after;
    display: block;
    animation: is-unresolved-animation $_duration infinite;
    background: linear-gradient($_angle, $_startcolor, $_endcolor, $_startcolor);
  } 
}

@keyframes is-unresolved-animation {
  from {
    left: $a-unresolved__startposition;
    opacity: 0;
  }
  10% {opacity: 1;}
  40% {opacity: 0;}
  50%, to {
    left: 100%;
    opacity: 0;
  }
}