
/* -------------------------------------------------------------------------
 * DURATIONS
 *
 * Define durations foundation
 * The values here are for sample proposes, consider add your owns
 *
 */


// Main duration

$f-duration:                  .3s !default;

// Duration variants

$f-duration--none:            $f-duration * 0 !default;
$f-duration--fast:            $f-duration / 2 !default;
$f-duration--normal:          $f-duration !default;
$f-duration--slow:            $f-duration * 2 !default;
$f-duration--sluggish:        $f-duration * 3 !default;


// Durations map

$f-durations: (
  "none":                     $f-duration--none,
  "fast":                     $f-duration--fast,
  "normal":                   $f-duration--normal,
  "slow":                     $f-duration--slow,
  "sluggish":                 $f-duration--sluggish,
) !default;
