

// Type room
// --------------------------------------------------

.c-actionable-list__item-room {
  [data-field="description"] {
    color: $c-actionable-list__item-room__description--color;
  }
}


// Type extended
// --------------------------------------------------

.c-actionable-list__item-extended .m-icon {
  color: $c-actionable-list__item-extended-icon--color;
}

.c-actionable-list__item-extended [data-field=description] {
  color: $c-actionable-list__item-extended-description--color;
}
