/* -------------------------------------------------------------------------
 * POSITIONS
 *
 * Special positions utilities
 *
 */

// Utilities output
// --------------------------------------------------

@if ($u-positions--enabled) {
  .u-positions--absolute-middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
