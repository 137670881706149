/* -------------------------------------------------------------------------
 * BOOKING confirmation SUMMARY VARS
 *
 */

$w-booking-confirmation-summary--sp-stack:                                 $f-space--medium !default;
$w-booking-confirmation-summary--sp-inline:                                $f-space--medium !default;

$w-booking-confirmation-summary__title--font-family:                       $f-font-family--secondary !default;
$w-booking-confirmation-summary__title--font-size:                         $f-font-size--xl !default;

$w-booking-confirmation-summary__subtitle--font-size:                      $f-font-size--m !default;
$w-booking-confirmation-summary__subtitle--font-family:                    $f-font-family--secondary !default;

$w-booking-confirmation-summary--text-shadow:                              $f-shadow--elevated !default;
$w-booking-confirmation-summary__text--color:                              $f-color__neutral-white !default;

$w-booking-confirmation-summary__header--sp-stack:                         $f-space--medium !default;
$w-booking-confirmation-summary__breakpoint-sml:                           $f-breakpoint--sml !default;

$w-booking-confirmation-summary__image--sp-inline:                         $f-space--medium !default;

$w-booking-confirmation-summary--left-column-width:                        100% !default;
$w-booking-confirmation-summary--right-column-width:                       60% !default;

$w-booking-confirmation-summary__links-info--flex-basis:                   calc(60% - 24px) !default;
$w-booking-confirmation-summary__links-info--sp-stack:                     $f-space--none !default;
$w-booking-confirmation-summary__links-info-margin-bottom:                 $f-space--small !default;

$w-booking-confirmation-summary__detail-info--sp-inset-h:                  $f-space--small !default;

$w-booking-confirmation-summary__details-title--font-size:                 $f-font-size--l !default;

$w-booking-confirmation-summary__details--border-bottom:                   1px !default;
$w-booking-confirmation-summary__details--border-bottom-style:             solid !default;
$w-booking-confirmation-summary__details--border-bottom-color:             $f-color__neutral-grey--light !default;

$w-booking-confirmation-summary__detail-name--font-size:                   $f-font-size--l !default;
$w-booking-confirmation-summary__detail-name--sp-stack:                    $f-space--small !default;

$w-booking-confirmation-summary__detail-info--font-size:                   $f-font-size--s !default;
$w-booking-confirmation-summary__detail-info--width:                       100% !default;

$w-booking-confirmation-summary--unresolved-border-radius:                 $f-radius--medium !default;

$w-booking-confirmation-summary__details-title__unresolved--width:         40% !default;
