/* -------------------------------------------------------------------------
 * SLIDER
 */

.c-slider {
  position: relative;
  > .tns-outer > :not(.tns-nav):not(.tns-ovh) {
    display: none
  }
  .tns-outer {
    overflow: hidden;
  }
  // Slider inner div
  .tns-inner {
    overflow: hidden;
  }
  .tns-ovh {
    pointer-events: initial;
  }

  .tns-nav[hidden], .tns-item[hidden] {
    display: none;
  }

  // Slider nav
  .tns-nav {
    position: absolute;
    left: $c-slider__nav-item--separation;
    right: 0;
    bottom: $c-slider__nav--margin-bottom;
    text-align: center;
    z-index: $f-z-index--1;
    display: block;
    width: auto;
    pointer-events: none;


    button[data-nav] {
      width: $c-slider__nav-item--width;
      height: $c-slider__nav-item--height;
      border-radius: $c-slider__nav-item--width;
      background-color: rgba($c-slider__nav-item--bg-color, $c-slider__nav-item--bg-color--opacity);
      @include sp-inline($c-slider__nav-item--separation);
      vertical-align: middle;
      cursor: pointer;
      pointer-events: initial;
      border: $c-slider__nav-item--border;
      padding: 0;
      outline: 0;
      display: none;

      &:not([tabindex="-1"]),
      &[data-relative-index="-2"],
      &[data-relative-index="-1"],
      &[data-relative-index="1"],
      &[data-relative-index="2"] {
        &:not([hidden]) {
          display: inline-block;
        }
      }

      &[data-relative-index="-2"],
      &[data-relative-index="2"] {
        transform: scale(.75);
        opacity: .5;
      }

      &.tns-nav-active {
        box-shadow: $c-slider__nav-item--shadow rgba($c-slider__nav-item--shadow--color, $c-slider__nav-item--shadow--opacity);
        background-color: $c-slider__nav-item--bg-color;
        transform: scale($c-slider__nav-item--scale);
        pointer-events: none;
      }
    }
  }
  // Avoiding the slider to show until is fully loaded
  > .c-slider__content {
    display: none;
    ~ * {
      display: none;
    }
  }
}

.c-slider__item {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}


// Slider Buttons
.c-slider__buttons {
  outline: 0;
  pointer-events: initial;
}
.c-slider__buttons--arrow {
  opacity: 1;
  transition: opacity $f-duration--normal ease;

  @include t-mq($until: $c-slider--bp) {
    display: none;
  }

  position: absolute;
  left: $c-slider__btn-arrow--margin;
  top: $c-slider__btn-arrow--position-v;
  transform: translateY(-$c-slider__btn-arrow--position-v);
  z-index: $f-z-index--1;

  .c-btn__icon {
    color: $c-slider__btn-arrow--color;
    background-color: rgba($c-slider__btn-arrow--bg-color, $c-slider__btn-arrow--bg-opacity);
    width: $c-slider__btn-arrow--size;
    height: $c-slider__btn-arrow--size;
    border-radius: $c-slider__btn-arrow--border-radius;
    transition: $c-slider__btn-arrow--transition-property $c-slider__btn-arrow--transition-duration $c-slider__btn-arrow--transition-easing;

    &::before {
      font-size: $c-slider__btn-arrow--font-size;
      left: $c-slider__btn-arrow_icon--left-fix;
    }
  }

  &.c-slider__buttons--arrow--next {
    left: initial;
    right: $c-slider__btn-arrow--margin;

    .c-btn__icon {
      &::before {
        left: initial;
        right: $c-slider__btn-arrow_icon--right-fix;
      }
    }
  }

  &[disabled] {
    opacity: 0;
    transition: opacity $f-duration--normal ease;
  }

  &:hover,
  &:active {
    .c-btn__icon {
      background-color: $c-slider__btn-arrow--bg-color;
    }
  }
}

// Modifiers
// ------------------------------------------------

// Dark luminosity

.c-slider--dark {
  .c-slider__buttons--arrow {
    .c-btn__icon {
      color: $c-slider__btn-arrow--bg-color;
      background-color: rgba($c-slider__btn-arrow--color, $c-slider__btn-arrow--bg-opacity);
    }
    &:hover,
    &:active {
      .c-btn__icon {
        background-color: $c-slider__btn-arrow--color;
      }
    }
  }
  .tns-nav {
    button[data-nav] {
      background-color: rgba($c-slider__nav-item--white--bg-color, $c-slider--dark__btn-arrow--bg-opacity);
      border: 1px solid $c-slider--dark__nav-item--border-color;

      &.tns-nav-active {
        background-color: $c-slider__nav-item--white--bg-color;
      }
    }
  }
}


// Outer navigation


.c-slider--outer-navigation,
.c-slider--outer-buttons {

  .c-slider__buttons--arrow--prev {
    left: 0;
  }
  .c-slider__buttons--arrow--next {
    right: 0;
  }

  // Side padding, only applies from certain breakpoint
  @include t-mq($from: $c-slider--outer-navigation--from-bp) {
    .tns-outer {
      padding-left: $c-slider--sp-inset-h--outer-navigation;
      padding-right: $c-slider--sp-inset-h--outer-navigation;
    }
  }
}

.c-slider--outer-navigation {
  .tns-nav {
    top: 100%;
    bottom: auto;
    button[data-nav] {
      background-color: $c-slider__nav--bg-color--outer-navigation;
      border: none;
      &.tns-nav-active {
        background-color: $c-slider__nav--bg-color--active--outer-navigation;
      }
    }
  }
}

// With gradient (outer navigation)
.c-slider--with-gradient {
  @include t-mq($from: $c-slider--outer-navigation--from-bp) {
    &.c-slider--outer-navigation {
      .tns-outer {
        padding-left: 0;
        padding-right: 0;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: auto;
          right: auto;
          width: $c-slider--sp-inset-h--outer-navigation;
          z-index: $f-z-index--1;
        }
        &::before {
          left: 0;
          background: linear-gradient(
              90deg,
              rgba($c-slider--with-gradient--bg-color, 1) 0%,
              rgba($c-slider--with-gradient--bg-color, .65) 50%,
              rgba($c-slider--with-gradient--bg-color, 0) 100%
          );
        }
        &::after {
          right: 0;
          background: linear-gradient(
              -90deg,
              rgba($c-slider--with-gradient--bg-color, 1) 0%,
              rgba($c-slider--with-gradient--bg-color, .65) 50%,
              rgba($c-slider--with-gradient--bg-color, 0) 100%
          );
        }
      }
      .tns-inner {
        padding-left: $c-slider--sp-inset-h--outer-navigation;
        padding-right: $c-slider--sp-inset-h--outer-navigation;
      }
    }
  }
}

// Bar navigation

.c-slider--bar-navigation {
  .tns-nav {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    border-radius: $c-slider__btn-arrow--border-radius;
    background-color: rgba($c-slider__nav-item--bg-color, $c-slider__nav-item--bg-color--opacity);
    height: $c-slider__nav--height--outer-navigation;
    width: calc(100% - #{$c-slider--sp-inset-h--outer-navigation});
    margin-left: $c-slider--sp-inset-h--outer-navigation / 2;
    margin-right: $c-slider--sp-inset-h--outer-navigation / 2;
    overflow: visible;
    left: 0;

    button[data-nav] {
      @include sp-inline($f-space--none);
      flex-grow: 1;
      background-color: transparent;

      &.tns-nav-active {
        transform: translateY($c-slider__nav-active--transform-y--outer-navigation);
        height: $c-slider__nav-active--height--outer-navigation;
        box-shadow: none;
      }

      &[aria-selected="false"] {
        border-radius: $f-radius--none;
      }
    }

    @include t-mq($from: $c-slider--bp) {
      width: calc(100% - #{2 * $c-slider--sp-inset-h--outer-navigation});
      margin-left: $c-slider--sp-inset-h--outer-navigation;
      margin-right: $c-slider--sp-inset-h--outer-navigation;
    }
  }
}

.c-slider--bar-navigation.c-slider--outer-navigation {
  .tns-nav {
    transform: translateY($c-slider__nav-item--width);
    background-color: $c-slider__nav--bg-color--outer-navigation;
  }
}

.c-slider--bar-navigation.c-slider--dark {
  .tns-nav {
    background-color: rgba($c-slider__nav-item--white--bg-color, $c-slider__nav-item--bg-color--opacity);
  }
}

// No buttons
//--------------------
.c-slider--no-buttons {
  .c-slider__buttons {
    display: none;
  }
}

.c-slider--no-buttons.c-slider--outer-navigation {
  // Side padding, only applies from certain breakpoint
  @include t-mq($from: $c-slider--outer-navigation--from-bp) {
    .tns-outer {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// Strecth
//--------------------
.c-slider--stretch {
  display: flex;

  .tns-outer,
  .tns-ovh,
  .tns-inner,
  .c-slider__item {
    display: flex;
    flex-grow: 1;
  }

  .tns-inner {
    flex-direction: column;

    .c-slider__content {
      // needs to be nested so it does not
      // override rule .c-slider>.c-slider__content
      // in which we want display:none;
      display: flex;
      flex-grow: 1;
    }
  }

  .c-slider__item {
    > * {
      flex-grow: 1;
    }
  }
}

.slider-video-thumbnail{
  cursor: pointer;
  position: relative;
  .c-img{
    transition-duration: $c-slider__transform-time-out;
    transition-property: all;
  }

  &:hover{
    .c-img{
      -webkit-filter: brightness($c-slider__thumbnail-brightness);
    }
    .c-slider-video-thumbnail-info-icon{
      background-color: $c-slider__thumbnail--info-icon-bg-color-hover;
      &:before{
        color: $c-video--thumbnail--info-icon-color-hover;
      }
    }
  }
}
.slider-individual-item{
  .c-modal-v2__header{
    .c-modal-v2__close{
      @include t-mq($from: $c-slider__breakpoint--tablet) {
        right: 0;
      }
    }
  }
}
.c-modal-video-slider {
  background-color: transparent;
  .c-modal-v2__header{
    background-color: transparent;
    .c-modal-v2__close{
      right: 0;
      top: $c-slider__video-modal__close-icon;
    }
  }
  .c-modal-v2__body{
    padding: 0;
  }
  .m-body{
    &:after{
      margin-top: 0;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.c-slider-video-thumbnail-info-icon{
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 50%;
  display: flex;
  background-color: $c-slider__thumbnail--info-icon-color;
  width: $c-slider__thumbnail--info-icon-content-size-tablet;
  height: $c-slider__thumbnail--info-icon-content-size-tablet;
  font-size: $c-slider__thumbnail--info-icon-size-tablet;
  margin-left: auto;
  margin-right: auto;
  transition-duration: $c-slider__transform-time-out;
  transition-property: all;

  @include t-mq($from: $c-slider__breakpoint--tablet) {
    width: $c-slider__thumbnail--info-icon-content-size;
    height: $c-slider__thumbnail--info-icon-content-size;
    font-size: $c-slider__thumbnail--info-icon-size;
  }

  &:before{
    position: absolute;
    display: block;
    color: $c-video--thumbnail--info-icon-content-color;
    right: $c-slider__thumbnail--info-icon-center-right-tablet;
    top: $c-slider__thumbnail--info-icon-center-top-tablet;
    transition-duration: $c-slider__transform-time-out;
    transition-property: all;

    @include t-mq($from: $c-video__breakpoint--tablet) {
      right: $c-slider__thumbnail--info-icon-center-right;
      top: $c-slider__thumbnail--info-icon-center-top;
    }
  }

}


.c-modal__inner-slider {
  background-color: transparent;
  overflow: visible;
  overflow-y: visible;
  pointer-events: none;
  transform: translate(-50%, calc(-50% - $c-modal__inner-slider--adjustment));
  
  @include t-mq($from: $c-slider--bp--md) {
    overflow: hidden;
    .tns-nav {
      bottom: $c-slider__nav--margin-bottom--adjustment--bp-md;
    }
  }

  @include t-mq($until: $c-slider--bp) {
    .c-modal-v2__close {
      right: $c-modal__inner-slider--adjustment;
      top: calc($c-slider__video-modal__close-icon + $c-modal__inner-slider--adjustment);
    }
  }
  

  .c-modal-v2 {
    background: transparent;
  }

  .c-modal-v2__close {
    right: 0;
    top: $c-slider__video-modal__close-icon;
    pointer-events: initial;

    @include t-mq($from: $c-slider__breakpoint--tablet) {
      right: $c-slider__modal-close-offset;
    }
  }

  .c-modal-v2__header {
    background: transparent;
    box-shadow: unset;
    padding: $c-slider__modal-header-vertical-padding $c-slider__modal-header-horizontal-padding;
  }

  .c-modal-v2__body {
    padding: 0;
    margin-top: auto;
    margin-bottom: auto;
    overflow: inherit;

    .c-slider__content {
      background: transparent;

      .c-slider__item {
        vertical-align: middle;
        height: 100%;
      }
    }

    &.m-body {
      @include t-text-crop-disable();
    }
  }

  .c-modal-v2__footer {
    @include sp-inset($c-slider__modal-description-sp-inset-v, $c-slider__modal-description-sp-inset);
    position: absolute;
    width: 100%;
    font-size: $c-slider__modal-description-size--mobile;
    text-align: center;
    color: $c-slider__modal-description-color;
    background-color: transparent;
    box-shadow: unset;
    bottom: $c-slider__modal-description-bottom;

    @include t-mq($from: $c-slider__breakpoint--tablet) {
      font-size: $c-slider__modal-description-size--tablet;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.c-slider__inner-item {
  width: 100%;

}

.c-photo-gallery__item-video {
  display: inline-block;
  position: relative;
  .c-img {
    -webkit-filter: brightness(75%);
  }
}

