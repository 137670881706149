/* -------------------------------------------------------------------------
 * BOOKING LUGGAGE
 *
 */
.w-booking-luggage{
  overflow: hidden;
}

.w-booking-luggage__message,
.w-booking-luggage__option-input {
  @include sp-stack($w-booking-luggage__default--sp-stack);
}

.w-booking-luggage__title {
  @include sp-stack($w-booking-luggage__title--sp-stack);
}

.w-booking-luggage__participant-name {
  @include sp-stack($w-booking-luggage__participant-name--sp-stack);

  @include t-mq($from: $w-booking-luggage__breakpoint--sm) {
    align-self: center;
    @include sp-inline(0);
  }
}

.w-booking-luggage__options,
.w-booking-luggage__options-header,
.w-booking-luggage__message-airline-info {
  display: flex;
  flex-wrap: nowrap;
  min-width: auto;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  flex-grow: 1;

  @include t-mq($from: $w-booking-luggage__breakpoint--sm) {
    flex-direction: row;
    align-items: flex-end;
  }

  .w-booking-luggage__option-input {
    @include t-mq($from: $w-booking-luggage__breakpoint--sm) {
      @include sp-inline($w-booking-luggage__default--sp-inline);
    }
  }

  .w-booking-luggage__message-wide {
    @include t-mq($from: $w-booking-luggage__breakpoint--sm) {
      flex-grow: 8;
    }
  }
}

.w-booking-luggage__message-direction-title {
  font-weight: $w-booking-luggage__font-weight;
}

.w-booking-luggage__message {
  font-size: $w-booking-luggage__message--font-size;
  padding: $w-booking-luggage__message-padding;
  padding-bottom: 0px;
  @include sp-stack($w-booking-luggage__message--sp-stack);

  .w-booking-luggage__message-line {
    border-color: $w-booking-luggage__message-line--color;
  }

  .w-booking-luggage__message-title,
  .w-booking-luggage__message-line,
  .w-booking-luggage__message-description {
    word-break: break-word;
    @include sp-stack($w-booking-luggage__message-items--sp-stack);
  }

  .w-booking-luggage__message-line:last-child {
    display: none;
  }
}

.w-booking-luggage__options-price {
  padding: $w-booking-luggage__options-price--padding;
  text-align: center;
  color: $w-booking-luggage__options-price--text-color;
  font-weight: $w-booking-luggage__font-weight;

  @include sp-stack($w-booking-luggage__default--sp-stack);

  @include t-mq($until: $w-booking-luggage__breakpoint--sm) {
    margin-top: $w-booking-luggage__options-price--margin-top;
  }

  @include t-mq($from: $w-booking-luggage__breakpoint--sm) {
    text-align: right;
    width: $w-booking-luggage__column-width;
    white-space: nowrap;
  }
}

.w-booking-luggage__message-dummy {
  @include t-mq($from: $w-booking-luggage__breakpoint--sm) {
    width: $w-booking-luggage__column-width;
  }
}

@include t-mq($from: $w-booking-luggage__breakpoint--sm) {
  .w-booking-luggage__one-click-options--slider{
    &.c-slider--with-gradient, &.c-slider--with-gradient .tns-inner{
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}

@include t-mq($until: $w-booking-luggage__breakpoint--md) {
  .w-booking-luggage__one-click-options--slider{
    margin-left: $w-booking-luggage__one-click-options-slider--margin;
    margin-right: $w-booking-luggage__one-click-options-slider--margin;
    &.c-slider--with-gradient, &.c-slider--with-gradient .tns-inner{
      padding-left: $w-booking-luggage__one-click-options-slider--padding;
      padding-right: $w-booking-luggage__one-click-options-slider--padding;
      overflow: visible;
    }
    &.c-slider--with-gradient .tns-outer{
      overflow: visible;
    }
  }
}

.w-booking-luggage__container{
  .is-hidden{
    display: none;
  }
}

/// Booking Luggage OneClick 
.w-booking-luggage__one-click{
  .w-booking-luggage__one-click-actions{
    @include sp-stack($w-booking-luggage__default--sp-stack);
  }
}
.w-booking-luggage__one-click-options--slider{
  .c-slider__content {
    display: flex;
    gap: $w-booking-luggage__one-click-options--gap;
    align-items: stretch;
    padding-bottom: $w-booking-luggage__one-click-options--pb;
  }

  &.c-slider--with-gradient.c-slider--with-gradient.c-slider--outer-navigation {
    .tns-outer{
      &:before, &:after {
        background: none;
        width: 0;
      }
    }
  }
  .tns-nav{
    display: none;
  }

  .c-slider__buttons--arrow {
    display: block;
    &[disabled]{
      opacity: .2;
      pointer-events: none;
    }
  }
}
.w-booking-luggage__one-click-option{
  display: flex;
  flex-direction: column;
  padding: $w-booking-luggage__one-click-option--padding;
  gap: $w-booking-luggage__one-click-option--gap;
  border-radius: $w-booking-luggage__one-click-option--border-radius;
  background:  $w-booking-luggage__one-click-option--bg;
  border: $w-booking-luggage__one-click-option--border solid $w-booking-luggage__one-click-option--border-color;
  align-items: stretch;
  flex: 1 0 0;
  box-shadow: $w-booking-luggage__one-click-options--shadow rgba($w-booking-luggage__one-click-options--shadow-color, $w-booking-luggage__one-click-options--shadow-opacity);
  position: relative;
  margin-top: $w-booking-luggage__one-click-options--mt;
	height: $w-booking-luggage__one-click-options--height;

  &:hover{
    cursor: pointer;
  }
} 
.w-booking-luggage__one-click-title{
  font-weight: $w-booking-luggage__one-click-title--font-weight;
  color: $w-booking-luggage__one-click--accent-color;
}

.w-booking-luggage__one-click-option-label{
  position: absolute;
	top: $w-booking-luggage__one-click-option-label-top;
  left: $w-booking-luggage__one-click-option-label-left;
  &.c-label--badge{
    font-weight: 700;
  }
}

.w-booking-luggage__one-click-option--selected{
  border: $w-booking-luggage__one-click-option--selected-border solid $w-booking-luggage__one-click-option--selected-border-color;
}

.w-booking-luggage__one-click-price-block{
  color: $w-booking-luggage__one-click--accent-color;
  letter-spacing: 0.33px;
  display: flex;
  align-items: center;
}
.w-booking-luggage__one-click-cta{
  color: $w-booking-luggage__one-click--accent-color;
  letter-spacing: 0.33px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.w-booking-luggage__one-click-price{
  margin-left: $w-booking-luggage__one-click-price-ml;
}
.w-booking-luggage__one-click-option--select-button, .w-booking-luggage__one-click-option--remove-button{
  text-transform: none;
}
.w-booking-luggage__one-click-option--remove-button{
  color: $w-booking-luggage__one-click-option--remove-button-color;
}
.w-booking-luggage__one-click-remove-button{
  color: $w-booking-luggage__one-click--remove-button-color ;
  text-transform: none;
}
.w-booking-luggage__special-luggage-extra-text-switch{
  margin-left: $w-booking-luggage__special-luggage-extra-text-switch-ml;
  margin-top: $w-booking-luggage__special-luggage-extra-text-switch-mt;
}
