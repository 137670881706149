/* -------------------------------------------------------------------------
 * RADIO BUTTON VARS
 */


// Enabled (or default)
// -----------------------------------------

$c-radio-button--font-size:                                $e-form-elements--font-size !default;

// Circle
$c-radio-button__circle--bg-color:                         $e-form-elements__field--bg-color !default;
$c-radio-button__circle--size:                             $f-space--tiny + $f-space--small !default;
$c-radio-button__circle--extra-border--size:               5px;
$c-radio-button__circle--extra-border--opacity:            $e-form-elements--focus--opacity !default;

$c-radio-button__circle--border--size:                     $e-form-elements__field--border--size !default;
$c-radio-button__circle--border--type:                     $e-form-elements__field--border--type !default;
$c-radio-button__circle--border--color:                    $e-form-elements__field--border-color !default;
$c-radio-button__circle--border:                           s-core-px-to-em($c-radio-button__circle--border--size) $c-radio-button__circle--border--type !default;
$c-radio-button__circle--border-radius:                    $f-radius--ellipsis !default;

// Bullet
$c-radio-button__bullet--color:                            $e-form-elements__inner-element--color !default;
$c-radio-button__bullet--size:                             12px !default;

// Container
$c-radio-button__element--size:                            $c-radio-button__circle--size * 2 !default;

// Label
$c-radio-button__label--font:                              map_get($m-body__mod-sizes, "medium") !default;

$c-radio-button__count--color:                             $cs-default--text--discrete !default;
$c-radio-button__count--font-size:                         map-deep-get($m-body__mod-sizes, "tiny", "font-size") !default;

$c-radio-button__text-wrapper--sp-inset-v:                 $f-space--tiny !default;

// Additional Text
$c-radio-button__additional-text--color:                   $cs-default--text--discrete !default;
$c-radio-button__additional-text--font-size:               map-deep-get($m-body__mod-sizes, "tiny", "font-size") !default;

// Transitions
$c-radio-button--transition-property:                      color, background-color, border-color, box-shadow, transform !default;
$c-radio-button--transition-duration:                      $f-duration--fast !default;
$c-radio-button--transition-easing:                        $f-easing--base !default;

// Higlighted Text
$c-radio-button__highlight-text--sp-stack:                 $f-space--tiny !default;

// States
// -----------------------------------------

// Disabled
$c-radio-button__disabled--opacity:                        $e-form-elements--disabled--opacity !default;

// Hocus / Active
$c-radio-button__circle--hover--bg-color:                  $e-form-elements--hover--bg-color !default;
$c-radio-button__circle--hover--border--color:             $e-form-elements--hover--border-color !default;
$c-radio-button__circle--hover--extra-border--color:       $e-form-elements--hover--extra-border--color !default;

// Pressed
$c-radio-button__circle--pressed--bg-color:                $e-form-elements--pressed--bg-color !default;
$c-radio-button__circle--pressed--border--color:           $e-form-elements--pressed--border-color !default;
$c-radio-button__circle--pressed--extra-border--color:     $e-form-elements--pressed--extra-border--color !default;

// Selected / Checked
$c-radio-button__circle--selected--bg-color:               $e-form-elements--selected--bg-color !default;
$c-radio-button__circle--selected--border--color:          $e-form-elements--selected--border-color !default;

// Validation [Success, Warning, Error]
$c-radio-button__circle--success--border--color:           $e-form-elements--success--border-color !default;
$c-radio-button__circle--warning--border--color:           $e-form-elements--warning--border-color !default;
$c-radio-button__circle--error--border--color:             $e-form-elements--error--border-color !default;

// Unresolved
$c-radio-button__unresolved--bg-color:                     $f-color__neutral-grey--semi-light !default;
$c-radio-button__unresolved--animation-bullet--width:      20px !default;

// Image
$c-radio-button__image-max-width:                          $f-space--medium + $f-space--tiny !default;
$c-radio-button__image-margin-right:                       $f-space--tiny !default;
$c-radio-button__image-margin-left:                        $f-space--tiny !default;
$c-radio-button__image-margin-bottom:                      $f-space--none !default;

// Illustration
$c-radio-butto__illustration-image:                        $f-space--medium !default;
