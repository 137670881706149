/* -------------------------------------------------------------------------
 * NUMBER SCORE
 *
 */

.c-number-score {
  display: inline-block;
  font-family: $c-number-score--font-family;
  font-size: $c-number-score--font-size;
  font-weight: $c-number-score--font-weight;
  line-height: $c-number-score--line-height;
}

.c-number-score__label {
  font-size: $c-number-score__label--font-size;
  font-weight: $c-number-score__label--font-weight;
  @include sp-inline($c-number-score__label--spacing);
}

.c-number-score__title {
  padding-bottom: $c-number-score__label--padding-bottom;
}

.c-number-score__title-label{
  display: inline-grid;
  vertical-align: baseline;
}

.c-number-score__body {
  white-space: nowrap;
  position: relative;
  display: inline-block;
}

.c-number-score__basis::before {
  content: "/";
  margin-left: -$c-number-score__label-separator--spacing;
  margin-right: $c-number-score__label-separator--spacing;
}

.c-number-score__button-info{
  color: $c-number-score__button-info--color;

  .c-btn__icon {
    height: $c-number-score__button-info--size;
    padding-left: $c-number-score__button-info--size;

    &::#{$m-icons__pseudo-element} {
      font-size: $c-number-score__button-info--font-size;
    }
  }

  &:hover {
    color: $c-number-score__button-info--color--hover;
  } 
}

// Variants
// -------------------------------------------

// Bubbled
.c-number-score--bubbled {
  .c-number-score__body {
    background-color: $c-number-score__bubble--color;
    color: $c-number-score--color;
    @include sp-inset-squish($c-number-score__bubble--padding);
    border-radius: $c-number-score__bubble--border-radius;
    min-width: $c-number-score__bubble--min-width;
    text-align: center;

    &::after {
      content: "";
      position: absolute;
      bottom: $c-number-score__bubble-tail--bottom;
      left: $c-number-score__bubble-tail--left;
      border-top: $c-number-score__arrow--height solid $c-number-score__bubble--color;
      border-right: $c-number-score__arrow--width solid transparent;
    }
  }
}

// Modifiers
// -------------------------------------------

// Small basis
.c-number-score--small-basis {
  .c-number-score__basis {
    font-size: $c-number-score--font-size--small-basis;
  }
}

// Right arrow
.c-number-score--arrow-right {
  .c-number-score__body {
    &::after {
      border-right: 0;
      border-left: $c-number-score__arrow--width solid transparent;
    }
  }
}

// Label right
.c-number-score--label-right {
  .c-number-score__label {
    @include sp-inline($f-space--none)
  }

  .c-number-score__body {
    @include sp-inline($c-number-score__label--spacing);
  }
}

// Squared

.c-number-score--squared {
  .c-number-score__title {
    font-size: $c-number-score--squared-title-font-size;
  }
  .c-number-score__label {
    font-size: $c-number-score--squared-label-font-size;
  }
  .c-number-score__body {
    padding: $c-number-score--squared-padding;
    background-color: $c-number-score--squared-color;
    color: $c-number-score--color;
    border-radius: $c-number-score--squared-border-radius;
    min-width: $c-number-score--squared-min-width;
    text-align: center;
    &::after {
      display: none;
    }
  }
}