/* -------------------------------------------------------------------------
 * RANGE
 *
 */

.c-range {
  @include sp-inset($c-range__handle--size / 2);
}

.c-range__label {
  @include sp-stack($c-range__label--stack);
}

.c-range__current-min,
.c-range__current-max {
  display: inline-block;
  color: $c-range__current-values--color;
  font-size: $c-range__current-values--font-size;
  @include sp-stack($c-range__current-values--stack);
}

.c-range__current-min + .c-range__current-max {
  &::before {
    content: $c-range__current-values--separator-content;
    @include sp-inset-h($c-range__current-values--separator-inset);
  }
}

.is-disabled.c-range,
.is-unresolved.c-range {
  opacity: $c-range--disabled--opacity;
  pointer-events: none;

  .noUi-handle {
    box-shadow: none;
  }

  .noUi-connect {
    background: $c-range__line--disabled--active-color;
  }
}

@import "nouislider";

// noUi Slider Overrides

.c-range {
  .noUi-handle {
    border: $c-range__handle--border-size $c-range__handle--border-type $c-range__handle--border-color;
    border-radius: $c-range__handle--radius;
    width: $c-range__handle--size;
    height: $c-range__handle--size;
    cursor: $c-range__handle--cursor;
    box-shadow: $c-range__handle--shadow;
  }

  .noUi-active {
    border-color: $c-range__handle--active--border-color;
  }

  .noUi-handle:before {
    width: $c-range__handle-dot--size;
    height: $c-range__handle-dot--size;
    border-radius: $c-range__handle--radius;
    background-color: $c-range__handle--color;
    left: calc(50% - #{$c-range__handle-dot--size / 2});
    top: calc(50% - #{$c-range__handle-dot--size / 2});
  }

  .noUi-handle:after {
    width: $c-range__handle--safe-size;
    height: $c-range__handle--safe-size;
    border-radius: 0;
    background-color: transparent;
    left: calc(50% - #{$c-range__handle--safe-size / 2});
    top: calc(50% - #{$c-range__handle--safe-size / 2});
  }

  .noUi-horizontal .noUi-handle {
    left: $c-range__handle--size * -.5;
    top: ($c-range__handle--size * -.5) + ($c-range__line--size * .5);
  }

  html:not([dir="rtl"]) & .noUi-horizontal .noUi-handle {
    right: $c-range__handle--size * -.5;
    left: auto;
  }

  .noUi-target {
    border-radius: $c-range__line--size / 2;
    border: none;
    background-color: $c-range__line--color;
    box-shadow: none;
  }
  .noUi-horizontal {
    height: $c-range__line--size;
  }
  .noUi-connect {
    background: $c-range__line--active-color;
  }
}
