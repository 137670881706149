/* -------------------------------------------------------------------------
* Items Grid Variables
*
*/

$c-items-grid--breakpoint-tablet:                  $f-breakpoint--sm !default;
$c-items-grid--breakpoint-desktop:                 $f-breakpoint--lg !default;
$c-items-grid--main-spacing:                       $f-space--medium !default;
$c-items-grid--secondary-spacing:                  $c-items-grid--main-spacing * .5 !default;

$c-items-grid__item--tablet-min-width:             45% !default;
$c-items-grid__item--desktop-min-width:            30% !default;

$c-items-grid__gradient--height:                   120px !default;
$c-items-grid__gradient--position-fix:             2px !default;
$c-items-grid__gradient--initial-color:            $f-color__neutral-white !default;
$c-items-grid__gradient--final-color:              $f-color__neutral-white--transparent !default;
$c-items-grid__gradient--z-index:                  $f-z-index--2 !default;
$c-items-grid__gradient--initial-change:           70% !default;
$c-items-grid__gradient--final-change:             100% !default;
$c-items-grid__gradient--opacity:                  .9 !default;

$c-items-grid__item-sp-stack:                      $f-space--medium !default;
$c-items-grid__item-sp-inline:                     $c-items-grid__item-sp-stack !default;
