/* -------------------------------------------------------------------------
 * SELECTED STATE VARS
 *
 */

$w-selected-state--breakpoint:                                            $f-breakpoint--sml !default;

$w-selected-state--dividing-space:                                        $f-space--large !default;
$w-selected-state--main-space:                                            $f-space--medium !default;
$w-selected-state--secondary-space:                                       $f-space--small !default;
$w-selected-state--shadow:                                                $f-shadow--elevated !default;
$w-selected-state--font-family:                                           $f-font-family__primary !default;
$w-selected-state--shadow-color:                                          $f-shadow__color !default;
$w-selected-state--shadow-opacity:                                        $f-shadow__opacity !default;
$w-selected-state--main-border--size:                                     1px !default;
$w-selected-state--main-border--type:                                     solid !default;

$w-selected-state__main-title--border-size:                               $w-selected-state--main-border--size * 2 !default;
$w-selected-state__main-title--border-color:                              $f-color__neutral-grey--ultra-light !default;

$w-selected-state__summary--border-color:                                 $f-color__neutral-grey--ultra-light !default;
$w-selected-state__summary--border-radius:                                $f-radius--medium !default;

$w-selected-state__list--padding:                                         $f-space--small !default;
$w-selected-state__list--right--font-weight:                              400 !default;

$w-selected-state__list--left--sp-stack-children:                         $f-space--small !default;
$w-selected-state__list--left--margin-bottom:                             $f-space--small !default;

$w-selected-state__price-overview--padding:                               $f-space--medium !default;
$w-selected-state__price-overview--border-color:                          $f-color__state-info !default;

$w-selected-state__price-overview-title--color:                           $f-color__neutral-black !default;
$w-selected-state__price-overview-title--font-size:                       $f-font-size--xl !default;
$w-selected-state__price-overview-title--font-weight:                     700 !default;

$w-selected-state__price-overview-details--padding:                       $f-space--small !default;
$w-selected-state__price-overview-details--border-color:                  $f-color__neutral-grey--semi-light !default;

$w-selected-state__price-overview-row--font-size:                         $f-font-size--s !default;
$w-selected-state__price-overview-row-bold--font-size:                    $f-font-size--m !default;
$w-selected-state__price-overview-row-bold--font-weight:                  700 !default;
$w-selected-state__price-overview-row-price-value--font-size:             $f-font-size--l !default;
$w-selected-state__price-overview-row-price-currency--font-size:          $f-font-size--s !default;
$w-selected-state__price-overview__row-main-value--alignment:             sub !default;
$w-selected-state__price-overview__row-main-value--font-weight:           700 !default;

$w-selected-state__price-overview-total--font-size:                       $f-font-size--xl !default;
$w-selected-state__price-overview-total--font-weight:                     900 !default;
$w-selected-state__price-overview-total--color:                           $f-color__state-info !default;

$w-selected-state__price-overview-total-subitem--color:                   $f-color__neutral-black !default;
$w-selected-state__price-overview-total-subitem--font-weight:             700 !default;
$w-selected-state__price-overview-total-subitem--margin-top:              $f-space--tiny !default;
$w-selected-state__price-overview-total-subitem-first--border:            1px solid $f-color__neutral-grey--semi-light !default;

$w-selected-state__price-overview-subtotal--color:                        $f-color__state-info--semi-dark !default;
$w-selected-state__price-overview-subtotal--font-weight:                  700 !default;

$w-selected-state__price-overview-mandatory-costs--bg-color:              $f-color__neutral-grey--ultra-light !default;

$w-selected-state__collapse-trigger-wrapper--color:                       $f-color__neutral-grey--dark !default;
$w-selected-state__collapse-trigger-wrapper--font-weight:                 700 !default;

$w-selected-state__collapse-trigger--font-size:                           $f-font-size--xs * .5 !default;
$w-selected-state__collapse-trigger--padding:                             $f-space--tiny * .5 !default;
$w-selected-state__collapse-trigger--border-radius:                       $f-radius--rounded !default;
$w-selected-state__collapse-trigger--bg-color:                            $f-color__neutral-grey--ultra-light !default;
$w-selected-state__collapse-trigger--color:                               $f-color__neutral-white !default;
$w-selected-state__collapse-trigger--chevron-alignment-fix:               2px !default;
$w-selected-state__collapse-trigger--size:                                $f-font-size--l !default;

$w-selected-state__footer--button-alignment:                              right !default;


$w-selected-state__usp--color:                                            $f-color__state-info !default;
$w-selected-state__usp--bp:                                               "sm" !default;
$w-selected-state__usp-title--sp-stack:                                   $f-space--tiny !default;
$w-selected-state__usp--sp-stack:                                         $f-space--small !default;
$w-selected-state__usp--width:                                            30% !default;
$w-selected-state__usp--sp-inline:                                        $f-space--tiny !default;


$w-selected-state__price-overview-optional-service--color:                $f-color__neutral-grey--semi-light !default;
$w-selected-state__price-overview-optional-service-label--color-text:     $f-color__neutral-white !default;
$w-selected-state__price-overview-optional-service-label--color-bg:       $f-color__neutral-grey--semi-dark !default;
$w-selected-state__price-overview-optional-service-tag-line--color-text:  $f-color__neutral-black !default;
$w-selected-state__price-overview-optional-service-label--font-size:      $f-font-size--xxs !default;
$w-selected-state__price-overview-optional-service-label--font-family:    $f-font-family__accent !default;
$w-selected-state__price-overview-optional-service-label--sp-inset:       $f-space--tiny !default;

$w-selected-state__icon--font-size:                                       map_get($m-icons__sizes, "medium") !default;

$w-selected-state__body--padding:                                         $f-space--medium !default;

$w-selected-state__booking-overview--padding-right:                       $f-space--medium !default;

$w-selected-state__open-jaw-message--sp-inset:                            $f-space--small !default;
$w-selected-state__price-overview-header--font-size:                      20px !default;
$w-selected-state__price-overview__row-title-subitem--font-size:          $f-font-size--m !default;
$w-selected-state__price-overview__row-subitem--color:                    $f-color__neutral-black !default;
$w-selected-state__price-overview-header--padding:                        $f-space--small 0 !default;
$w-selected-state__price-overview-header--font-size:                      20px !default;
$w-selected-state__price-overview-header--border-bottom:                  1px solid $f-color__neutral-grey--semi-light !default;

$w-selected-state__price-overview-header-localcosts--margin-top:          $f-space--medium !default;
$w-selected-state__price-overview-localcosts-description--padding:        0 !default;
$w-selected-state__price-overview-cost-item-description--color:           $f-color__neutral-grey !default;
$w-selected-state__price-overview-cost-item-description--font-size:       $f-font-size--s !default;
$w-selected-state__price-overview-cost-item--margin-bottom:               $f-space--small !default;
$w-selected-state__price-overview-details--extra-info-message-icon-margin:$f-space--tiny !default;