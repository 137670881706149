/* -------------------------------------------------------------------------
 * BACKGROUND IMG
 *
 */

 //This mixin is used in the c-background-img and c-promotion-img, the purpose is to have an only source of true for all the light titles inside of this 2 components
@mixin c-background-img--light-title {
  color: $c-background-img__dark-img--contents--color;
  text-shadow: $c-background-img__dark-img--text-shadow;
}

.c-background-img {
  position: relative;
}

//All titles inside the component will be light(white) by default, if there is need for a different color, apply it on the template or fragment and document, if the new style is pattern that repeats please consider to create a mixin and have only one source of truth like the one already implemented
.c-background-img .c-title,
.c-background-img .m-heading {
  @include c-background-img--light-title;
}

.c-background-img__img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;

  .c-img__placeholder, .c-img__resolved {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);
    object-fit: cover;
  }
}

.c-background-img__content {
  position: relative;

  > *:last-child {
    margin-bottom: 0;
  }
}
