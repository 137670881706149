
$w-booking-redeemer--bp-medium:                                         $f-breakpoint--sml !default;

$w-booking-redeemer__booking__card--border-radius:                      $f-radius--small !default;
$w-booking-redeemer__booking__card--border-width:                       1px !default;
$w-booking-redeemer__booking__card--border-style:                       solid !default;
$w-booking-redeemer__booking__card--border-color:                       $f-color__neutral-grey--light !default;
$w-booking-redeemer__booking__card--shadow:                             $f-shadow--elevated !default;
$w-booking-redeemer__booking__card--shadow-color:                       $f-shadow__color !default;
$w-booking-redeemer__booking__card--shadow-opacity:                     $f-shadow__opacity !default;
$w-booking-redeemer__booking__card--spacing-inset:                      $f-space--medium !default;
$w-booking-redeemer__booking__card--bg-color:                           $e-document__body--bgcolor !default;
$w-booking-redeemer__booking__card--selected--border-color:             $f-color__state-info !default;
$w-booking-redeemer__booking__card--selected--border-width:             2px !default;  
$w-booking-redeemer__booking__card--selected--background-color:         $f-color__state-info--ultra-light !default;

$w-booking-redeemer__booking__card-body--sp-top:                        $f-space--medium + $f-space--small !default;

$w-booking-redeemer__booking__card__title--font-size:                   $f-font-size--l !default;
$w-booking-redeemer__buttons--font-size:                                $f-font-size--xs !default;  
$w-booking-redeemer__button-revert--sp-inset-mobile:                    $f-space--none !default;
$w-booking-redeemer__button-revert--sp-inset-v:                         $f-space--tiny !default;
$w-booking-redeemer__button-revert--sp-inset-h:                         $f-space--none !default;
$w-booking-redeemer__button-revert--color:                              $f-color__state-info--dark !default;

$w-booking-redeemer__button-select--sp-inset-v:                         8px !default;

$w-booking-redeemer__usp--color:                                        $f-color__state-success !default;
$w-booking-redeemer__usp--font-size:                                    $f-font-size--s !default;
$w-booking-redeemer__usp--font-weight:                                  600 !default;
$w-booking-redeemer__usp--font-size-desktop:                            $f-font-size--m !default;
$w-booking-redeemer__usps--margin-bottom:                               $f-space--small !default;

$w-booking-redeemer__booking__body-heading--margin-bottom:              $f-space--tiny !default;

$w-booking-redeemer__messages--sp-inset-v:                              $f-space--small !default;
$w-booking-redeemer__messages--sp-inset-h:                              $f-space--medium !default;
$w-booking-redeemer__messages--font-weight:                             600 !default;

$w-booking-redeemer__messages--margin-top:                              $f-space--small !default;          
$w-booking-redeemer__global-messages--margin-top:                       $f-space--medium !default;          

$w-booking-redeemer__booking__info--font-size:                          $f-font-size--s !default; 

$w-booking-redeemer__booking__bullet-list--margin-bottom:               $f-space--tiny !default;
$w-booking-redeemer__booking__bullet-list--margin-right:                $f-space--small !default;
$w-booking-redeemer__booking__bullet-list--margin-left:                 $f-space--none !default;
$w-booking-redeemer__booking__bullet-list--margin-top:                  $f-space--none !default;

$w-booking-redeemer__breadcrums--color:                                 $f-color__neutral-grey--semi-dark !default;
$w-booking-redeemer__breadcrums--margin:                                $f-space--none !default;