/* -------------------------------------------------------------------------
 * REVEAL LIFT
 *
 */


// Animation as a mixin
// --------------------------------------------------

@mixin a-reveal-lift($_duration: $a-reveal-lift__duration) {
  transform: translateY(-25%);
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: $_duration;
  transition-timing-function: $a-reveal-lift__easing-out;
}

@mixin a-reveal-lift--in($_duration: $a-reveal-lift__duration) {
  transform: translateY(0);
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: $_duration;
  transition-timing-function: $a-reveal-lift__easing-in;
}

// Unset as mixin
// --------------------------------------------------

@mixin a-reveal-lift--unset() {
  transform: initial;
  opacity: initial;
  transition-property: initial;
  transition-duration: initial;
  transition-timing-function: initial;
}
