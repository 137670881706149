/* -------------------------------------------------------------------------
 * RATING
 *
 */

// Component selector output

.c-rating {
  @include m-icon__styles;
  font-size: $c-rating__font--size;

  &::before,
  &::after {
    display: inline-block;
    vertical-align: middle;
  }

  @each $_value in $c-rating__data {

    &[data-rating^="#{$_value}"] {
      &::before {
        @include c-rating__content($c-rating__icon, $_value);
      }
    }
  }

  &[data-rating$=".5"] {
    &::after {
      content: $c-rating__icon;
      overflow: hidden;
      width: $c-rating__font--size / 2;
    }
  }
}


// Modifiers
// --------------------------------

// Size modifier

@each $_size-name, $_size-value in $c-rating__font--sizes {

  .c-rating--#{$_size-name} {
    font-size: s-core-px-to-rem($_size-value);
    height: $_size-value;
    width: $c-rating--width;

    &[data-rating$=".5"] {
      &::after {
        width: $_size-value / 2;
      }
    }
  }
}

// Unresolved
.c-rating.is-unresolved,
.is-unresolved .c-rating {
  display: block;
  @include a-unresolved(
    $_top-before: $c-rating__unresolved--text-background--top,
    $_width-after: $c-rating__unresolved--animation-bullet--width
  );
}
