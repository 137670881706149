/* -------------------------------------------------------------------------
 * BOOKED SERVICE EDIT VARS
 *
 */

$w-booked-service-edit__breakpoint:                           $f-breakpoint--md !default;
$w-booked-service-edit__main--padding-left:                   $f-space--small !default;
$w-booked-service-edit__main--padding-right:                  $f-space--small !default;
$w-booked-service-edit__main-top--sp-stack:                   $f-space--none !default;
$w-booked-service-edit--reset-padding:                        $f-space--none !default;

$w-booked-service-edit__service-selected--bg--color:          $f-color__state-info--ultra-light !default;
$w-booked-service-edit__service--padding:                     $f-space--small !default;

$w-booked-service-edit__main-right--margin-bottom:            $f-space--large + $f-space--small !default;
$w-booked-service-edit__main-right--padding-h:                $f-space--small !default;

$w-booked-service-edit__buttons-action--padding-h:            $f-space--small !default;
$w-booked-service-edit__pay-button--margin-bottom:            $f-space--small !default;
$w-booked-service-edit__cancel-button--margin-bottom:         $f-space--medium !default;

$w-booked-service-edit__warning-messages--margin-bottom:      $f-space--medium !default;
$w-booked-service-edit__warning-messages--padding-h:          $f-space--small !default;

$w-booked-service-edit__sidebars--width:                      25% !default;
$w-booked-service-edit__main-content--width:                  50% !default;

$w-booked-service-edit__layer-3:                              $f-z-index--4 + 3 !default;
$w-booked-service-edit__modal-icon-cancel--color:             $f-color__neutral-grey !default;
$w-booked-service-edit__modal-icon-error--color:              $f-color__state-danger--semi-dark !default;

$w-booked-service-edit__backdrop-z-index:                     $f-z-index--3 !default; 