
// Inline Modifier
// ------------------------------

.c-nav--inline {
  height: $c-nav--inline--height;

  .c-nav__list {
    bottom: -1 * ($c-nav--inline__height + $f-space);
    width: 100%;
  }

  .c-nav__item {
    @include sp-inline($c-nav__item--inline__sp-inline);
    margin-bottom: 0;
    display: inline-block;

    &.has-child {
      > .c-nav__link {
        pointer-events: initial;
      }
    }
  }

  .o-swipe-btn {
    color: $c-nav__button--inline--color;

    &:hover,
    &:active {
      color: $c-nav__button--inline--color--hover;
    }
  }

  .c-nav__link {
    width: 100%;
  }
}
