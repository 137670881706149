/* -------------------------------------------------------------------------
 * PRICE
 */

$c-price__legend--font-family:                                            $f-font-family__secondary !default;
$c-price__legend--font-weight:                                            600 !default;
$c-price__legend--color:                                                  $f-color__neutral-grey--semi-dark !default;

$c-price--small__legend--font-size:                                       $f-font-size--xxs !default;
$c-price--small__legend--line-height:                                     $f-line-height--medium !default;

$c-price__info-icon--color:                                               $f-color__state-info !default;
$c-price__value--primary-color--color:                                    $f-color__brand-primary--dark !default;
$c-price__suffix--primary-color--color:                                   $f-color__brand-primary--dark !default;

$c-price_old-price--font-family:                                          $f-font-family__secondary !default;
$c-price_old-price--font-weight:                                          600 !default;
$c-price_old-price--font-size:                                            $f-font-size--s !default;

$c-price__label-grayed--margin-top:                                       $f-space--tiny !default;
$c-price__label-grayed--line-height:                                      1.2 !default;
$c-price__label-grayed--breakpoint:                                       $f-breakpoint--sm !default;
$c-price__label-grayed--breakpoint--font-size:                            10px !default;

