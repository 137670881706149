.c-actionable-list__item {
  position: relative;
  display: block;
  cursor: pointer;
  font-weight: $c-actionable-list__item--font-weight;
  color: $c-actionable-list__item--color;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: $c-actionable-list__item--border-color;
    height: $c-actionable-list__item--border-size;
  }

  &:first-child {
    &::before {
      display: none;
    }
  }
    
  &:hover:not([disabled]) {
    background-color: $c-actionable-list__item--selected--bg-color;
  }
  
  &[disabled] {
    pointer-events: none;
    cursor: default;
  }
}

.c-actionable-list__item-link, 
.c-actionable-list--room .c-actionable-list__item,
.c-actionable-list__item .c-actionable-list__item-extended  {
  @include sp-inset($c-actionable-list__item-link--sp-inset);
  text-decoration: none;
}

// Item Types
// --------------------------------------------------

@import "item-types/c-actionable-list__item--link";
@import "item-types/c-actionable-list__item--room";
@import "item-types/c-actionable-list__item--extended";
