/* -------------------------------------------------------------------------
 * PACKAGE BLOCK VARS
 */

$c-package-block__included--color:                        $f-color__brand-primary--dark !default;
$c-package-block__included-item__flight--color:           $f-color__brand-primary--dark  !default;
$c-package-block__included-item__accommodation--color:    $f-color__brand-primary--dark !default;
$c-package-block__included-item__car--color:              $f-color__brand-primary--dark !default;

$c-package-block__filters--color:                         $f-color__neutral-grey !default;
$c-package-block__departure--font-weight:                 600 !default;
$c-package-block__departure--font-family:                 $f-font-family__secondary !default;
