.fr-reviews-overview__main {
  @include t-mq($from: $fr-reviews-overview--bq) {
    display: flex;
    align-content: space-between;
    justify-items: stretch;
  }
}

.fr-reviews-overview__main-item {
  display: flex;
  flex-wrap: wrap;
  min-width: 50%;

  .c-progress-bar {
    @include sp-stack($fr-reviews-overview__c-progress-bar--sp-stack);
  }

  .c-progress-circle {
    @include t-mq($until: $fr-reviews-overview--bq) {
      width: 50%;
    }

    @include t-mq($from: $fr-reviews-overview--bq) {
      @include sp-inline($fr-reviews-overview__c-progress-circle--sp-inline * 2)
    }

    &:nth-child(2n) {
      @include t-mq($from: $fr-reviews-overview--bq) {
        @include sp-inline($fr-reviews-overview__c-progress-circle--sp-inline);
      }
    }

    &:nth-child(2n + 1) {
      @include t-mq($from: $fr-reviews-overview--bq) {
        margin-left: $fr-reviews-overview__c-progress-circle--sp-inline;
      }
    }

    @include sp-stack($fr-reviews-overview__c-progress-circle--sp-stack);

    @include t-mq($from: $fr-reviews-overview--bq) {
      text-align: left;
    }
  }

  .c-progress-circle__body {
    @include t-mq($from: $fr-reviews-overview--bq) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.fr-reviews-overview__top + .fr-reviews-overview__main,
.fr-reviews-overview__main-item + .fr-reviews-overview__main-item {
  margin-top: $fr-reviews-overview__main-item--vertical-rhythm
}

.fr-reviews-overview__main-item + .fr-reviews-overview__main-item {
  @include t-mq($from: $fr-reviews-overview--bq) {
    min-width: 0;
    margin-top: 0;
    padding-left: $fr-reviews-overview__main-item--gutter;
    margin-left: $fr-reviews-overview__main-item--gutter;
    border-left: $fr-reviews-overview__main-item--first-child--border-width $fr-reviews-overview__main-item--first-child--border-style $fr-reviews-overview__main-item--first-child--border-color;
    min-width: $fr-reviews-overview__main-item--last-child--width;
  }

  .c-progress-circle {
    width: calc(50% - #{$fr-reviews-overview__c-progress-circle--sp-inline});
    @include sp-inline($f-space--none);

    &:nth-child(2n) {
      @include t-mq($from: $fr-reviews-overview--bq) {
        @include sp-inline($fr-reviews-overview__c-progress-circle--sp-inline);
      }
    }

    &:nth-child(2n + 1) {
      @include t-mq($from: $fr-reviews-overview--bq) {
        margin-left: $fr-reviews-overview__c-progress-circle--sp-inline;
      }
    }
  }
}

.fr-reviews-overview__top + .fr-reviews-overview__main:empty {
  margin-top: 0;
}
