.fr-faq-categories__grid-item.fr-faq-categories__grid-item {
  @include sp-stack($fr-faq-categories__grid-item__sp-stack * 2);
}

.fr-faq-categories__grid-item__heading {
  display: flex;
  align-items: center;
  height: $fr-faq-categories__category__title--icon-size;
  @include sp-stack($fr-faq-categories__grid-item__sp-stack);

  .m-icon {
    color: $fr-faq-categories__category__title--icon-color;
    font-size: $fr-faq-categories__category__title--icon-size;
    @include sp-inline($fr-faq-categories__category__title--icon--sp-inline);
  }
}

.fr-faq-categories__grid-item__list {
  margin-top: 0;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
}

.fr-faq-categories__grid-item__list-item {
  border-top: $fr-faq-categories__grid-item__list-item--border-width $fr-faq-categories__grid-item__list-item--border-style $fr-faq-categories__grid-item__list-item--border-color;


  &:last-child {
    border-bottom: $fr-faq-categories__grid-item__list-item--border-width $fr-faq-categories__grid-item__list-item--border-style $fr-faq-categories__grid-item__list-item--border-color;
  }

  .fr-faq-categories__grid-item__list-item-link {
    display: block;
    color: $fr-faq-categories__grid-item__list-item--color;
    font-weight: normal;
    text-decoration: none;
    @include sp-inset($fr-faq-categories__grid-item__sp-stack);

    &:hover,
    &:focus {
      color: $fr-faq-categories__grid-item__list-item--hover--color;
      text-decoration: none;
      background-color: $fr-faq-categories__grid-item__list-item--hover--bg-color;
    }
  }

  @include t-mq($from: $fr-faq-categories__breakpoint-mobile) {
    @include sp-inset-v($fr-faq-categories__grid-item__sp-stack);
    padding-bottom: 0;

    &:not(:first-child) {
      border-top: none;
    }

    &:last-child {
      border-bottom: none;
    }

    .fr-faq-categories__grid-item__list-item-link {
      display: inline;
      padding-left: 0;
      padding-right: 0;

      &:hover,
      &:focus {
      background-color: initial;
    }
    }

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

.fr-faq-categories__grid-item__collapsible-trigger {
  margin-top: $fr-faq-categories__grid-item__sp-stack;
}
