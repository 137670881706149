
$w-booking-participants-form--selected-color:                                        $f-color__state-success !default;
$w-booking-participants-form__title--font-weight:                                    400 !default;
$w-booking-participants-form__title-selected--font-weight:                           700 !default;

$w-booking-participants-form--column-grid-gap:                                       8px !default;

$w-booking-participants-form__item--third-width:                                     calc(100%/3 - #{$w-booking-participants-form--column-grid-gap}) !default;
$w-booking-participants-form__item--sixth-width:                                     calc(100%/6 - #{$w-booking-participants-form--column-grid-gap}) !default;
$w-booking-participants-form__item--full-width:                                      100% !default;
$w-booking-participants-form__item--twice-three-width:                               calc((2 * #{$w-booking-participants-form__item--third-width}) + #{$w-booking-participants-form--column-grid-gap}) !default;
