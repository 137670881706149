/* -------------------------------------------------------------------------
* WELCOME USER VARS
*
*/

$w-welcome-user--bp:                                                'xs' !default;

$w-welcome-user--padding-top:                                       36px !default;
$w-welcome-user--padding-top-from-bp:                               24px !default;

$w-welcome-user__text--margin-bottom:                               4px !default;
$w-welcome-user__text--heading:                                     map_get($m-heading__mod-sizes, "large") !default;

$w-welcome-user__avatar-container--size:                               56px !default;
$w-welcome-user__avatar-container--bg-color:                           $f-color__neutral-black !default;
$w-welcome-user__avatar-container--margin-right:                       16px !default;
$w-welcome-user__avatar--color:                                        $f-color__neutral-white !default;
