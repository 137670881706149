.fr-destination-block {
  .c-promotion-image {
    height: 100%;

    img,
    .c-img {
      height: 100%;
    }

    .c-promotion-image__content {
      z-index: $fr-destination-block__content--z-index;
    }
  }

  .w-product-map {
    height: 100%;
  }

  .c-map {
    height: 100%;
    overflow: hidden;

    .c-img {
      height: 100%;
      aspect-ratio: 1 / 1;
    }
  }
}
