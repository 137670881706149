@import "airlines";

.m-airline-logo {
  height: $m-airline-logo--size;
  width: $m-airline-logo--size;
  background-size: $m-airline-logo--size;
}

@function str-split($string, $separator) {

  $split-arr: ();
  $index : str-index($string, $separator);

  @while $index != null {
      $item: str-slice($string, 1, $index - 1);
      $split-arr: append($split-arr, $item);
      $string: str-slice($string, $index + 1);
      $index : str-index($string, $separator);
  }
  $split-arr: append($split-arr, $string);

  @return $split-arr;
}

@each $imageName,$airlinecode in $airlines {
    $list: ();

    $split-values: str-split($airlinecode, ",");
    @each $p in $split-values {
      $list: append($list, '.m-airline-logo[data-airline-id="#{$p}"]', comma);
    }
    #{$list}{
      background-image: url("#{$m-airline-logos-url}/"+$imageName);
    } 
}