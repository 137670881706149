.fr-account-booking-cancelled-content {
  text-align: center;
}

.fr-account-booking-cancelled-content__section-text,
.fr-account-booking-cancelled-content__text-below,
.fr-account-booking-cancelled-content__legal-message {
  margin-left: auto;
  margin-right: auto;
  max-width: $fr-account-booking-cancelled-content__content--max-width;
}

.fr-account-booking-cancelled-content__icon.fr-account-booking-cancelled-content__icon {
  margin-bottom: $f-space--small;
}

.fr-account-booking-cancelled-content__legal-message {
  font-size: $fr-account-booking-cancelled-content__legal-message--font-size;
  color: $fr-account-booking-cancelled-content__legal-message--color;
  @include sp-inset($fr-account-booking-cancelled-content__legal-message--sp-inset-v, 0);
  @include sp-stack($fr-account-booking-cancelled-content__legal-message--sp-stack);
}

.fr-account-booking-cancelled-content__voucher-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 340px;
  text-align: left;
}
