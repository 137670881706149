/* -------------------------------------------------------------------------
 * LOGIN FORM VARIABLES
 */

$w-login-form--max-width:                                                   447px;
$w-login-form__space-stack-children:                                        $f-space--small !default;
$w-login-form__form-validation-message--font-family:                        $f-font-family__accent !default;
$w-login-form__form-validation-message--font-size:                          $f-font-size--xs !default;
$w-login-form__form-validation-message--margin-top:                         $w-login-form__space-stack-children !default;

$w-login-form__messages--margin-bottom:                                      $f-space--small !default;

$w-login-form__title--margin-bottom:                                        $f-space--small !default;

$w-login-form__logo-img--margin-bottom:                                     $f-space--small !default;
$w-login-form__logo--color:                                                 $f-color__brand-primary !default;

$w-login-form__switcher--top--margin-left:                                  -1 * $f-space--tiny  !default;

$w-login-form__privacy-statement--color:                                    $f-color__neutral-grey--semi-dark !default;
$w-login-form__privacy-statement--margin-top:                               $f-space--large !default;
$w-login-form__privacy-statement--font-size:                                $f-font-size--s !default;

$w-login-form__checkboxes-form-item--margin-top:                            8px !default;
$w-login-form__checkboxes-form-item--padding-left:                          40px !default;
$w-login-form__checkboxes-form-item--padding:                               12px !default;
