/* -------------------------------------------------------------------------
* Package Item
*
*/

.c-package-item {
  .c-package-item__body-heading{
    .c-breadcrumbs{
      color: $c-package-item__breadcrumbs-color;
    }
  }
}

.c-package-item__title {
  font-weight: $c-package-item__title--font-weight;
  @include m-body--complete($c-package-item__title--material);
  @include t-text-crop-disable();
}


// Variants
// -------------------------------------

// Listed

.c-package-item--listed {
  box-shadow: $c-package-item--shadow rgba($c-package-item--shadow-color, $c-package-item--shadow-opacity);
  background-color: $c-package-item--listed--bg-color;
  .c-package-item__body {
    @include sp-inset-squish($c-package-item__body--listed--padding);
  }

  .c-package-item__body-footer {
    @include t-mq($until: $c-package-item--mq) {
      height: $c-package-item__body-footer--height--until-bp;
    }
    margin-top: $c-package-item-listed__body-footer--margin-top-adjustment;
  }
}

