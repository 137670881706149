/* -------------------------------------------------------------------------
 * PAGINATION
 */

$c-pagination--font-family:                                         $f-font-family__primary !default;
$c-pagination--font-weight--normal:                                 400 !default;
$c-pagination--font-weight--bold:                                   700 !default;

$c-pagination__button--color:                                       $f-color__state-info !default;
$c-pagination__button--size:                                        34px !default;
$c-pagination__button--border-width:                                2px !default;
$c-pagination__button--border-style:                                solid !default;
$c-pagination__button--border-color:                                $f-color__neutral-grey--semi-light !default;
$c-pagination__button--border-radius:                               $c-btn--border-radius !default;
$c-pagination__button--icon--font-size:                             $f-font-size--m !default;
$c-pagination__button--icon--color:                                 $f-color__neutral-black !default;
$c-pagination__button--icon--padding--alignment-fix:                0 0 ($f-space--tiny * .5) !default;
$c-pagination__button--icon--top--alignment-fix:                    7px !default;
$c-pagination__button--icon--right--alignment-fix:                  0 !default;
$c-pagination__button-previous--icon--right--alignment-fix:         3px !default;

$c-pagination__button-truncation--margin-x:                         ($f-space--medium * 0.75) !default;
$c-pagination__button-truncation--top:                              -($f-space--small / 3) !default;
$c-pagination__button-truncation--space-left:                       17px !default;
$c-pagination__button-truncation--space-right:                      4px !default;

// States
// -----------------------------------------

// Disabled
$c-pagination--disabled--color:                                     $f-color__neutral-grey--light !default;

// Active state
$c-pagination__background-color--active:                            $f-color__state-info--ultra-light !default;
$c-pagination__color--active:                                       $f-color__state-info--semi-dark !default;

// Hover state
$c-pagination__background-color--hover:                             $f-color__neutral-grey--light !default;
$c-pagination__color--hover:                                        $f-color__neutral-black !default;

// Pressed state
$c-pagination__background-color--pressed:                           $f-color__state-info !default;
$c-pagination__color--pressed:                                      $f-color__neutral-white !default;
