/* -------------------------------------------------------------------------
 * BULLET MIXINS
 *
 */

// Object as a mixin
// --------------------------------------------------

@mixin o-bullet() {
  display: inline-block;
}

@mixin o-bullet__icon($_icon-size: $o-bullet__icon-size, $_line-height: $o-bullet__icon--line-height) {
  font-size: $_icon-size;
  line-height: $o-bullet__icon--line-height;
  display: inline-block;
  vertical-align: middle;
}

@mixin o-bullet__text() {
  display: inline-block;
  vertical-align: middle;
  line-height: $o-bullet__text--line-height;
  padding-left: $o-bullet__icon--padding-left;
}


// Block modifier
// --------------------------------------------------

@mixin o-bullet--block() {
  display: table;
  width: 100%;
}

@mixin o-bullet__icon--block($_icon-size: $o-bullet__icon-size) {
  display: table-cell;
  vertical-align: top;
  width: $o-bullet__icon-size;
  max-width: $o-bullet__icon-size;
}

@mixin o-bullet__text--block() {
  display: table-cell;
  vertical-align: top;
  width: auto;
  padding-left: $o-bullet__icon--padding-left * $o-bullet__icon--padding-multiplier;
}

@mixin o-bullet__mod-block() {
  .o-bullet--block {
    position: relative;
    @include o-bullet--block();

    > .o-bullet__icon {
      @include o-bullet__icon--block();
    }

    > .o-bullet__text {
      @include o-bullet__text--block();
    }
  }
}


// Middle modifier
// --------------------------------------------------

@mixin o-bullet--middle() {
  @include o-bullet--block();
}

@mixin o-bullet__icon--middle($_icon-size: $o-bullet__icon-size) {
  @include o-bullet__icon--block($_icon-size);

  vertical-align: middle;
}

@mixin o-bullet__text--middle() {
  @include o-bullet__text--block();

  vertical-align: middle;
}

@mixin o-bullet__mod-middle() {
  .o-bullet--middle {
    @include o-bullet--middle();

    > .o-bullet__icon {
      @include o-bullet__icon--middle();
    }

    > .o-bullet__text {
      @include o-bullet__text--middle();
    }
  }
}


// Reverse modifiers
// --------------------------------------------------

@mixin o-bullet__mod-reverse() {
  direction: rtl;
}

@mixin o-bullet-text__mod-reverse($_space-value: $o-bullet__icon--padding-left, $_space-multiplier: 1) {
  padding-left: 0;
  padding-right: $_space-value * $_space-multiplier;
}


// Icon Size modifiers
// --------------------------------------------------

@mixin o-bullet--iconsize($_iconsize-name: "medium", $_iconsize-value: 1em) {
  .o-bullet--iconsize-#{$_iconsize-name} {
    > .o-bullet__icon {
      font-size: $_iconsize-value;
      max-width: $_iconsize-value;
    }
  }
}


// Unset as mixin
// --------------------------------------------------

@mixin o-bullet--unset() {
  display: inherit;
}

@mixin o-bullet__icon--unset() {
  display: inherit;
  font-size: inherit;
  line-height: inherit;
  vertical-align: inherit;
}

@mixin o-bullet__text--unset() {
  display: inherit;
  vertical-align: inherit;
  line-height: inherit;
  padding-left: inherit;
  padding-right: inherit;
}
