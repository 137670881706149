/* -------------------------------------------------------------------------
 * LIST IN-LINE MIXINS
 *
 */


// Object as a mixin
// --------------------------------------------------

@mixin o-list-inline() {
  font-size: 0;

  > * {
    font-size: s-core-px-to-rem($s-core__font-size);
    display: inline-block;
    width: auto;
  }
}

// Unset as mixin
// --------------------------------------------------

@mixin o-list-inline--unset() {
  font-size: inherit;

  > * {
    font-size: inherit;
    display: inherit;
  }
}
