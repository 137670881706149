/* -------------------------------------------------------------------------
* Collection row Variables
*
*/

$c-collection-row__item-sp-stack:                                   $f-space--small !default;
$c-collection-row__item--max-width:                                 690px;

$c-collection-row--shadow:                                          $f-shadow--elevated !default;
$c-collection-row--shadow-color:                                    $f-color__neutral-grey--semi-light !default;
$c-collection-row--shadow-color--hover:                             $f-color__neutral-grey !default;

$c-collection-row__item-image--size:                                100px !default;
$c-collection-row__item-image--bg-color:                            $f-color__neutral-white !default;

$c-collection-row__item-label--material:                            map_get($m-body__mod-sizes, "tiny") !default;
$c-collection-row__item-label--font-size:                           map_get(map_get($m-body__mod-sizes, "tiny"), "font-size")  !default;
$c-collection-row__item-label--sp-inset:                            $f-space--tiny !default;

$c-collection-row__item-content--color:                             $f-color__neutral-grey--ultra-dark !default;
$c-collection-row__item-content--bg-color:                          $f-color__neutral-white !default;
$c-collection-row__item-content--bg-color-translucent:              rgba($c-collection-row__item-content--bg-color, $cs-default--translucence) !default;
$c-collection-row__item-content--sp-inset:                          $f-space--tiny + $f-space--small !default;
$c-collection-row__item-content--padding-right:                     $f-space--large !default;

$c-collection-row__item-title--material:                            map_get($m-heading__mod-sizes, "tiny") !default;
$c-collection-row__item-title--sp-stack:                            $f-space--tiny !default;

$c-collection-row__item-subtitle--material:                         map_get($m-body__mod-sizes, "small") !default;
$c-collection-row__item-subtitle--font-weight:                      400 !default;

$c-collection-row__item-icon--sp-inset:                             $f-space--small !default;

// Tiled
// --------------------------------------------------------

$c-collection-row--tiled-breakpoints:                               ("xxs", "sml", "md") !default;

// Expanded
// --------------------------------------------------------

$c-collection-row--expanded-breakpoints:                            ("xxs", "sml", "md") !default;
$c-collection-row--expanded-breakpoints-tablet:                     $f-breakpoint--sml !default;
$c-collection-row--expanded-breakpoints-desktop:                    $f-breakpoint--md !default;

$c-collection-row-expanded__item-sp-stack:                          $f-space--medium !default;
$c-collection-row-expanded__item-sp-inline:                         $c-collection-row-expanded__item-sp-stack / 2 !default;

$c-collection-row-expanded__item--min-width:                        250px !default;

$c-collection-row-expanded--border-bottom-width:                    4px !default;

$c-collection-row-expanded__item-label--position:                   $f-space--small !default;