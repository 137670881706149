/* -------------------------------------------------------------------------
 * SLIDER VARS
 */

$c-slider--bp:                                          "sm" !default;
$c-slider--bp--md:                                      $f-breakpoint--md !default;

// Slider arrows

$c-slider__btn-arrow--size:                             ($f-space--medium + $f-space--small) !default;
$c-slider__btn-arrow--font-size:                        $f-font-size--l !default;
$c-slider__btn-arrow--color:                            $f-color__neutral-white !default;
$c-slider__btn-arrow--bg-color:                         $f-color__neutral-black !default;
$c-slider__btn-arrow--bg-opacity:                       .6 !default;
$c-slider__btn-arrow--border-radius:                    $f-radius--rounded !default;
$c-slider__btn-arrow--margin:                           $f-space--small !default;
$c-slider__btn-arrow--position-v:                       50% !default;
$c-slider__btn-arrow--transition-property:              background-color !default;
$c-slider__btn-arrow--transition-duration:              $f-duration--fast !default;
$c-slider__btn-arrow--transition-easing:                $f-easing--in !default;

$c-slider__btn-arrow_icon--left-fix:                    8px !default;
$c-slider__btn-arrow_icon--right-fix:                   8px !default;

// Slider nav
$c-slider__nav--margin-bottom:                          $f-space--small !default;
$c-slider__nav--margin-bottom--adjustment--bp-md:       $f-space--medium * 3 !default;

$c-slider__nav-item--width:                             ($f-space--tiny + 2) !default;
$c-slider__nav-item--height:                            $c-slider__nav-item--width !default;
$c-slider__nav-item--bg-color:                          $f-color__neutral-black !default;
$c-slider__nav-item--bg-color--opacity:                 $c-slider__btn-arrow--bg-opacity !default;
$c-slider__nav-item--shadow:                            $f-shadow--elevated !default;
$c-slider__nav-item--shadow--color:                     $f-color__neutral-black !default;
$c-slider__nav-item--shadow--opacity:                   $f-shadow__opacity--elevated !default;
$c-slider__nav-item--scale:                             1.3 !default;
$c-slider__nav-item--separation:                        $f-space--small !default;
$c-slider__nav-item--border:                            none !default;

$c-slider__nav-item--white--bg-color:                   $f-color__neutral-white !default;


// Modifiers
// ------------------------------------------------

// Dark luminosity
$c-slider--dark__nav-item--border-color:                $f-color__neutral-white !default;
$c-slider--dark__btn-arrow--bg-opacity:                 .7 !default;

// Outer navigation
$c-slider--sp-inset-h--outer-navigation:                $f-space--large !default;
$c-slider__nav--bottom--outer-navigation:               -1 * ($f-space--medium + $f-space--small ) !default;
$c-slider__nav--bg-color--outer-navigation:             $f-color__neutral-grey--semi-light !default;
$c-slider__nav--bg-color--active--outer-navigation:     $f-color__neutral-grey !default;
$c-slider--outer-navigation--from-bp:                   "sm" !default;


// Bar navigation
$c-slider__nav--height--outer-navigation:               3px !default;
$c-slider__nav-active--height--outer-navigation:        7px !default;
$c-slider__nav-active--transform-y--outer-navigation:   -25% !default;


// With Gradient
$c-slider--with-gradient--bg-color:                     $e-document__body--bgcolor !default;

//video
$c-slider__breakpoint--tablet:                          $f-breakpoint--sm !default;
$c-slider__video-modal__close-icon:                     35% !default;
$c-slider__transform-time-out:                          .3s !default;
$c-slider__thumbnail-brightness:                        75% !default;
$c-slider__thumbnail--info-icon-color:                  $f-color__neutral-white !default;
$c-slider__thumbnail--info-icon-bg-color-hover:         $f-color__neutral-grey--light !default;
$c-slider__thumbnail--info-icon-content-color:          $f-color__brand-secondary--semi-dark !default;
$c-slider__thumbnail--info-icon-color-hover:            $f-color__brand-secondary--dark !default;
$c-slider__thumbnail--info-icon-size-tablet:            $f-space--medium !default;
$c-slider__thumbnail--info-icon-content-size:           57px !default;
$c-slider__thumbnail--info-icon-content-size-tablet:    60px !default;
$c-slider__thumbnail--info-icon-size:                   18px !default;
$c-slider__thumbnail--info-icon-center-right:           31% !default;
$c-slider__thumbnail--info-icon-center-right-tablet:    25% !default;
$c-slider__thumbnail--info-icon-center-top:             32% !default;
$c-slider__thumbnail--info-icon-center-top-tablet:      25% !default;

$c-slider__modal-close-offset:                        $f-space--large !default;
$c-slider__modal-header-vertical-padding:             $f-space--tiny + $f-space--small !default;
$c-slider__modal-header-horizontal-padding:           $f-space--large !default;
$c-slider__modal-description-sp-inset:                $f-space--medium !default;
$c-slider__modal-description-sp-inset-v:              ($f-space--medium - $f-space--tiny) !default;
$c-slider__modal-description-size--mobile:            $f-font-size--s !default;
$c-slider__modal-description-size--tablet:            $f-font-size--s !default;
$c-slider__modal-description-color:                   $f-color__neutral-white !default;
$c-slider__modal-description-bottom:                  -$f-space--large !default;

$c-modal__inner-slider--adjustment:                   $f-space--medium !default;                     
