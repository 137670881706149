/* -------------------------------------------------------------------------
 * ROOM TYPES
 *
 */

.w-room-types {
  .c-slider {
    .tns-item {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding-bottom: $w-room-types--slider__padding-bottom;
    }
    .tns-ovh {
      overflow: hidden;
    }
  }

  .c-card {
    height: 100%;
    margin-bottom: $w-room-types--card__margin-bottom;

    .c-card__body {
      display: flex;
      flex-direction: column;

      .w-room-types-item--body {
        display: grid;
        height: 100%;

        .w-room-types-item--body-buttons {
          display: flex;
          margin-top: $w-room-types-item--body-buttons__margin-top;
          gap: $w-room-types-item--body-buttons__gap;
          align-items: flex-end;

          @include t-mq($until: $w-room-types--bq) {
            flex-direction: column;
          }

          .c-btn {
            width: 100%;

            @include t-mq($until: $w-room-types--bq) {
              &:not(:first-child) {
                margin-top: $w-room-types-item--body-buttons__gap--bq;
              }
            }
          }
        }
      }
    }
  }
}
