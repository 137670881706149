/* -------------------------------------------------------------------------
* Package Item Variables
*
*/

$c-package-item--shadow:                                                        $f-shadow--discrete !default;

$c-package-item__title--material:                                               map_get($m-heading__mod-sizes, "tiny") !default;
$c-package-item__title--font-weight:                                            700 !default;
$c-package-item--alignment-fix:                                                 0 !default;

$c-package-item__rating--color:                                                 $f-color__brand-secondary !default;

$c-package-item__package-block--font-size:                                      .8em !default;

$c-package-item__breadcrumbs-color:                                             $f-color__neutral-grey !default;

// Package item listed adjustments
$c-package-item--listed--bg-color:                                              #fff;
$c-package-item-listed__body-footer--margin-top-adjustment:                     -50px;

// Variants
// --------------------------------

// Mobile Modifications

$c-package-item__body-heading--sp-stack--until-bp:                              0 !default;
$c-package-item__body-footer--height--until-bp:                                 20px !default;
$c-package-item__price-icon--listed--from-mq--font-size--until-mq:              $f-font-size--xxs !default;