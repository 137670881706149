/* -------------------------------------------------------------------------
 * BLOCK
 *
 * Stacked content object with or without header and footer.
 * A simple abstraction to cover a very commonly occurring design pattern.
 *
 * Params:
 * ALIGNMENT .......................... Horizontal text alignment
 *
 */


// Object selector output
// --------------------------------------------------

@if ($o-block--enabled) {
  .o-block {
    @include o-block();
  }

  .o-block__header {
    @include o-block__header();
  }

  .o-block__body {
    @include o-block__body();
  }

  .o-block__footer {
    @include o-block__footer();
  }
}

// Alignment modifiers
// --------------------------------------------------

@if ($o-block--enabled and $o-block__mod-alignments--enabled) {
  @each $_alignment-direction in $o-block__mod-alignments {
    @include o-block__mod-alignment($_alignment-direction);
  }
}


@if ($o-block--enabled and $o-block__mod-stretch--enabled) {
  @include o-block__mod-stretch();
}
