/* -------------------------------------------------------------------------
 * Voucher check balance
 */

.w-voucher-check-balance {
}

.w-voucher-check-balance__info {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.w-voucher-check-balance__expired-text {
  display: flex;
  flex-direction: column;
  font-size:$w-voucher-check-balance__expired-text--font-size;
  margin-top: $w-voucher-check-balance__expired-text--margin-top;
}

.w-voucher-check-balance__title {
  margin-bottom: $w-voucher-check-balance__title--margin-bottom;
  color: $w-voucher-check-balance__title--color;
  text-align: left;
}

.w-voucher-check-balance__refund-button,
.w-voucher-check-balance__refund-message {
  margin: $f-space--small;
  text-align: center;
}
