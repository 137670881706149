/* -------------------------------------------------------------------------
 * INSET SQUISH
 *
 * To give space between container and content emphasizing top and bottom spaces
 *
 * TIP:
 * You can modify the amount of generated spaces by overriding the $sp-inset-squish__spaces variable.
 *
 */

// Space selector output
// --------------------------------------------------

@if ($sp-inset-squish__enabled) {
  @each $_spacing-name, $_spacing-value in $sp-inset-squish__spaces {
    .sp-inset-squish--#{$_spacing-name} {
      @include sp-inset-squish($_spacing-value);
    }
  }
}
