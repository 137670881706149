/* -------------------------------------------------------------------------
 * REVIEWS LIST
 *
 */
 .w-reviews-list{
   &.no-reviews{
     .w-reviews-list__pagination,
     .w-reviews-list__items{
       display: none;
     }
   }
   &.no-reviews:not(.has-data){
    .w-reviews-list__filters-area,
    .w-reviews-list__title-area{
      display: none;
    }
  }
   &.is-hidden{
    display: none;
   }
   &.not-loaded{
     .w-reviews-list__title-area,
     .w-reviews-list__filters-area,
     .w-reviews-list__pagination{
       display: none;
     }
   }
 }

 .w-reviews-list__title-area{
  display: flex;
  justify-content: space-between;
  margin-bottom:$w-reviews-list__item-title-area--mb; 
  @include t-mq($until: $w-reviews-list__item--bq){
    flex-direction: column;
  }
 }
 .w-reviews-list__title {
  @include sp-stack($w-reviews-list__title--sp-stack);
  margin-top: auto;
  margin-bottom: auto;
}
.w-reviews-list__button-info{
  text-transform: none;
  @include sp-inset($w-reviews-list__button-info--pv,$w-reviews-list__button-info--ph);
  color:$w-reviews-list__button-info--color;

  &:hover{
    color:$w-reviews-list__button-info--hover-color;
  }

}

.w-reviews-list__sorting-area{
  display: flex;
  @include t-mq($from: $w-reviews-list__item--bq){
    align-items: center;
    justify-content: end;
  }
  align-items: end;
  justify-content: space-between;
}
.w-reviews-list__sorting-title{
  display:none;
  @include t-mq($from: $w-reviews-list__item--bq){
    display: block;
  }
}
.w-reviews-list__items{
  margin-bottom: $w-reviews-list__items--mb;
}
 .w-reviews-list__item{
    display: flex;
    width: 100%;
    flex-direction: column;
    transition: color $w-reviews-list__comment-translated-button--duration;
    @include sp-stack($w-reviews-list__item--mb);
    @include t-mq($from: $w-reviews-list__item--bq){
      flex-direction: row;
    }

    &.is-translated{
      .w-reviews-list__item-text,
      .c-collapse__trigger{
        display: none;
      }
      .w-reviews-list__comment-translate-text{
        max-height: $w-reviews-list__comment-translate-text--max-height;
      }
    }
}

.w-reviews-list__main-rating{
  @include t-mq($from: $w-reviews-list__item--bq) {
    width: 20%;
  }
}
.w-reviews-list__score{
  display: inline-block;

  .c-number-score__body{
    display: inline-flex;
    @include sp-stack($w-reviews-list__item-title--mb);
    @include t-mq($from: $w-reviews-list__item--bq){
        @include sp-inset-h($w-reviews-list__main-score-bubble--gutter);
        @include sp-inset-v($w-reviews-list__main-score-bubble--high);
        font-size: $w-reviews-list__main-score-bubble--font-size;
        border-radius: $w-reviews-list__main-score-bubble--radius;
    }
    @include sp-inset-h($w-reviews-list__main-score-bubble--gutter-mobile);
    @include sp-inset-v($w-reviews-list__main-score-bubble--high-mobile);
    font-size: $w-reviews-list__main-score-bubble--font-size-mobile;
    border-radius: $w-reviews-list__main-score-bubble--radius-mobile;
    margin-right: $w-reviews-list__main-score-bubble--margin-right;
  }

  .c-number-score__title-label{
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: $w-reviews-list__main-title-label--padding-bottom;
  }
}

.w-reviews-list__item-title{
  @include sp-stack($w-reviews-list__item-title--mb);
}

.w-reviews-list__item-date{
  color: $w-reviews-list__item-date--text-color;
  @include sp-stack($w-reviews-list__item-date--mb);
}

.w-reviews-list__comment{
  @include sp-stack($w-reviews-list__item-subtitle--sp-inline);

  @include t-mq($from: $w-reviews-list__item--bq) {
    @include sp-inset-h($w-reviews-list__main-comment--sp-inline);
    @include sp-stack(0);
    width: 45%;
  }
  .w-reviews-list__item-text{
    .m-body:before{
        margin-bottom : 0;
    }
  }
}

.w-reviews-list__item-subtitle{
    background: $w-reviews-list__item-subtitle-label-bg;
    color: $w-reviews-list__item-subtitle-label-color;
    font-size: $w-reviews-list__item-subtitle-label-font-size;
    display: inline-flex;

    @include t-mq($from: $w-reviews-list__item--bq) {
      @include sp-stack(0);
    }
}
.w-reviews-list__score-points{
  @include t-mq($from: $w-reviews-list__item--bq) {
    width: 35%;
  }
}
.w-reviews-list__item-score{
  display: inline-flex;
}
.w-reviews-list__item-scores{
  display: grid;
    grid-template-columns: repeat(2, minmax($w-reviews-list__item-scores--column-width, 1fr));
    row-gap: $w-reviews-list__item-scores--row-gap;
    column-gap: $w-reviews-list__item-scores--row-gap;

  .c-number-score__body{
    min-width: $w-reviews-list__number-score__body--min-width;
    border-radius: $w-reviews-list__number-score__body--br;
    @include sp-inset($w-reviews-list__number-score__body--pv, $w-reviews-list__number-score__body--ph);
    background-color: $w-reviews-list__number-score__body--background-color;
    color: $w-reviews-list__number-score__body--text-color;

    &::after{
      display: none;
    }

    .c-number-score__grade{
      display: flex;
      max-width: $w-reviews-list__number-score__grade--max-width;
      justify-content: center;
    }
  }
  .c-number-score__title{
    font-size: $w-reviews-list__item-scores--title-font-size;
    font-weight: $w-reviews-list__item-scores--title-font-weight;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 0;
  }
}
.w-reviews-list__no-items-area{
  &.is-hidden{
    display: none;
  }
}
.w-reviews-list__comment-translated-button{
  color: $w-reviews-list__comment-translated-button--color;
  margin-right: $w-reviews-list__comment-translated-button--margin-right;
  transition: color $w-reviews-list__comment-translated-button--duration;
  padding: 0;
  padding-top: $w-reviews-list__comment-translated-button--padding-top;
  text-transform: none;
  font-weight: 400;
  font-family: $f-font-family__secondary;
  font-size: $w-reviews-list__comment-translated-button--font-size;

  &:hover:not([disabled]) {
    color: $w-reviews-list__comment-translated-button--color--hover;
  }
  &[disabled] {
    color: $w-reviews-list__comment-translated-button--disabled;
    pointer-events: none;
  }
}

.w-reviews-list__comment-translated-button{
  &.is-hidden{
    display: none;
  }
}

.w-reviews-list__comment-translate-text{
  &:not(.empty){
    font-style: italic;
  }
  
  font-size: $w-reviews-list__comment-translate-text--font-size;
  max-height: 0;
  transition: max-height $w-reviews-list__comment-translated-button--duration;
}

.w-reviews-list__comment-translate-loader{
  display: inline-block;
  &.is-hidden{
    display: none;
  }
}

.w-reviews-list__item-flag-partycomp{
  display: flex;
  @include sp-stack($w-reviews-list__item-subtitle--sp-inline);
  .w-reviews-list__country-flag{
    margin-right: $w-reviews-list__country-flag--margin-right;
    align-self: center;
  }
}

.w-reviews-list__loader{
  display: flex;
  justify-content: center;
  @include sp-inset-v($w-reviews-list__loader--padding);

  &.is-hidden{
    display: none;
  }
}

.w-reviews-list__filters-area{
  display: flex;
  flex-direction: column;
  margin-bottom: $w-reviews-list__item-sorting-area--mb;

  @include t-mq($until: $w-reviews-list__item--bq){
    .w-reviews-list__filters-area--item:nth-child(2){
      margin-top:$w-reviews-list__item-sorting-item--ml;
    }
    .w-reviews-list__filters-area--item:nth-child(3){
      margin-top:$w-reviews-list__item-sorting-switch-item--ml;
    }
  }
  
  @include t-mq($from: $w-reviews-list__item--bq) {
    flex-direction: row;

    .w-reviews-list__filters-area--language{
      margin-bottom: auto;
      margin-top: auto;
      .w-reviews-list__filters-area--language-switch{
        justify-content: end;
      }
    }

    .w-reviews-list__filters-area--item:nth-child(1){
      width: 25%;
    }
    .w-reviews-list__filters-area--item:nth-child(2){
      margin-left:$w-reviews-list__item-sorting-item--ml;
      width: 25%;
    }
    .w-reviews-list__filters-area--item:nth-child(3){
      margin-left:$w-reviews-list__item-sorting-item--ml;
      width: 50%;
    }
  }
}