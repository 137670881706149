/* -------------------------------------------------------------------------
 * BULLET VARIABLES
 *
 */

// Object toggling

$o-bullet--enabled:                   true !default;

$o-bullet__icon-size:                 1em !default;
$o-bullet__icon--line-height:         $f-line-height--tiny !default;
$o-bullet__icon--padding-left:       .3em !default;
$o-bullet__icon--padding-multiplier:  2 !default;

$o-bullet__text--line-height:         $f-line-height--small !default;

// Modifier Block

$o-bullet__mod-block--enabled:        true !default;

// Modifier Middle

$o-bullet__mod-middle--enabled:        true !default;

// Modifier Reverse

$o-bullet__mod-reverse--enabled:      true !default;

// Icons Size Modifiers

$o-bullet__mod-iconsize--enabled:     true !default;

$o-bullet__mod-iconsizes:             $m-icons__sizes !default;
