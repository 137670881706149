/* -------------------------------------------------------------------------
 * BODY
 *
 * body known as a material not as a tag
 *
 */


// Material selector output
// --------------------------------------------------

@if $m-body--enabled {
  .m-body {
    @include m-body--complete($m-body__properties);
  }
}


// Material sizes modifiers output
// --------------------------------------------------

@if $m-body--enabled and $m-body__mod-sizes--enabled {
  @each $_mod-name, $_mod-properties in $m-body__mod-sizes {
    .m-body--#{$_mod-name} {
      @include m-body--update($_mod-properties, $m-body__properties);
    }
  }
}


// No-Crop modifier disables the text crop applied
// --------------------------------------------------

@if $m-body--enabled {
  .m-body--no-crop {
    @include t-text-crop-disable();
  }
}
