/* -------------------------------------------------------------------------
 * PROMOTION IMAGE
 *
 */

.c-promotion-image__content--body {
  @include m-heading--complete($c-promotion-image__content--body-heading, false);
}

.c-promotion-image__label {
  font-size: $c-promotion-image__label--font-size;
  @include t-mq($from: $c-promotion-image__label--breakpoint) {
    font-size: $c-promotion-image__label--font-size-from-bp;
  }
}
