/* -------------------------------------------------------------------------
 * SEARCHPAGE
 *
 */

.t-searchpage {
  .fr-acco-lister > .c-title {
    @include sp-stack($f-space--medium);
  }
}
