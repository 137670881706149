/* -------------------------------------------------------------------------
 * PADDINGS
 *
 */

// Utilities output
// --------------------------------------------------

@if ($u-padding--enabled) {
  @each $_space-name, $_space-value in $u-padding__spaces {
    @each $_class-name, $_class-properties in $u-padding__classes {
      .u-#{$_class-name}--#{$_space-name} {
        @each $_property-name in $_class-properties {
          #{$_property-name}: s-core-px-to-rem($_space-value) !important;
        }
      }
    }
  }
}
