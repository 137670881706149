/* -------------------------------------------------------------------------
* Dropdown Variables
*
*/

$c-dropdown__arrow--color:                                  $e-form-elements__arrow--color !default;


// Modifiers
// -----------------------------------------

// Highlighted
$c-dropdown__element--highlighted--value-color:              $e-form-elements__inner-element--color !default;

// Spacing to fix the issue with choice-list hover effect
$c-dropdown-multiple__floating-box-body-content--spacing:    9px !default;
