/* -------------------------------------------------------------------------
 * PROMOPAGE
 *
 */

.t-content-generic {
  [class^="fr-"] {
    > .c-title {
      text-align: center;
      @include sp-stack($t-content-generic__c-title--sp-stack);
    }
  }

  .fr-layout-container + .fr-content-usps {
    margin-top: $t-content-generic__content-usps--margin-top;
  }

  .fr-layout-container .fr-content-block:last-child .fr-destination-page__description-collapse-trigger {
    margin-bottom: 0;
  }

}

@import 'fr-right-to-object-form--promotion-page';
@import 'fr-text-block--promotion-page';
@import 'fr-highlighted-video--promotion-page';
