/* -------------------------------------------------------------------------
 * accommodation PAGE VARS
 *
 */

// Fragments
// -----------------------------------

$fr-acco-usps--icon-color:                                                $f-color__state-success !default;

$t-accommodation__tabs-container--box-shadows:                            $f-space--huge 0 0 0 $f-color__neutral-white, -$f-space--huge 0 0 0 $f-color__neutral-white !default;

$fr-acco-description__label--color:                                       $f-color__state-info--semi-dark !default;
$fr-acco-description__label--weight:                                      700 !default;
$fr-acco-description__collapse--line-height:                              $f-line-height--medium !default;

$fr-acco-carousel__breakpoint--mobile:                                    $f-breakpoint--sm !default;
$fr-acco-carousel__opacity-transition-duration:                           $f-duration !default;

$fr-acco-basic-info__rating--sp-stack:                                    $f-space--small !default;
$fr-acco-basic-info__heading--sp-stack:                                   16px !default;
$fr-acco-basic-info__name--material:                                      map_get($m-heading__mod-sizes, "large") !default;

$fr-social-share__icon--color:                                            $f-color__brand-primary--dark !default;
$fr-social-share__icon--hover-color:                                      $f-color__brand-primary !default;

$fr-acco-room-types__extra-info-title--color:                             $f-color__neutral-grey--dark !default;
$fr-acco-room-types__extra-info-title--sp-stack:                          $f-space--small !default;
$fr-acco-attention--margin-top:                                           -$f-space--medium !default;

$fr-reviews-overview--shadow:                                             $f-shadow--discrete rgba($f-shadow__color, $f-shadow__opacity) !default;
$fr-reviews-overview__progress-bar--background:                           $f-color__neutral-grey--light !default;
$fr-reviews-overview__progress-circle--background:                        $f-color__neutral-white !default;
$fr-reviews-collapse__after-fading-color--white:                          $f-color__neutral-white !default;

$fr-booking-gate__choice-list-option--font-size:                          $f-font-size--s !default;
$fr-booking-gate__table__item--border-color:                              $f-color__neutral-grey--light !default;

$fr-acco-facilities__collapse-content--bg-color:                          $f-color__neutral-grey--ultra-light !default;

$fr-acco-room-types__accordion-item-content--padding-top:                 $f-space--tiny !default;

$fr-reviews-leaver__c-box--border-width:                                  2px !default;

$t-accommodation__w-promoted-price--first-bp:                             'sm' !default;
$t-accommodation__w-promoted-price--second-bp:                            'sml' !default;
$t-accommodation__w-promoted-price--third-bp:                             'md' !default;
$t-accommodation__w-promoted-price--fourth-bp:                            'lg' !default;


$t-accommodation__w-promoted-price--top-first-bp:                         -70px !default;
$t-accommodation__w-promoted-price--top-second-bp:                        -40px !default;

$t-accommodation__button-back--margin-top:                                0 !default;
$t-accommodation__w-promoted-price--top-fourth-bp:                        $f-space--medium !default;
$t-accommodation__fr-acco-attention--title-color:                         $f-color__brand-primary !default;

$fr-acco-facilities__category-name--text-transform:                       unset !default;
$fr-acco-facilities__category-highlight-name--color:                      $f-color__brand-primary--dark  !default;
$fr-acco-facilities__category-highlight--border-color:                    $f-color__neutral-grey--semi-light !default;

// NEW REVIEWS OVERVIEW
// -----------------------------------
$t-accommodation__review-overview--main-score-title-font-size:            $f-font-size--xl !default;

$fr-reviews-overview__c-progress-bar-color-fantastic:                     $f-color__state-success--dark!default;
$fr-reviews-overview__c-progress-bar-color-good:                          $f-color__state-success--semi-light!default;
$fr-reviews-overview__c-progress-bar-color-normal:                        $f-color__state-info--semi-light!default;
$fr-reviews-overview__c-progress-bar-color-bad:                           $f-color__state-warning--semi-light!default;
$fr-reviews-overview__c-progress-bar-color-horror:                        $f-color__state-warning--semi-dark!default;

$t-accommodation__review-overview--dashed-border-divider-size:            10px 0px, 1px 10px !default;

$fr-reviews-overview__c-number-score--padding-top:                        4px !default;
$fr-reviews-overview__main-score-bubble--high:                            $f-space--small !default;
$fr-reviews-overview__main-score-bubble--high-mobile:                     $f-space--small !default;
$fr-reviews-overview__main-score-bubble--gutter-mobile:                   $f-space--small + $f-space--tiny !default;
$fr-reviews-overview__main-score-bubble--font-size-mobile:                $f-font-size--l !default;

$fr-destination-exceptional-info__table-row-header--font-weight:          600 !default;
$fr-destination-exceptional-info__table-row-header--font-family:          $f-font-family__secondary !default;
