/* -------------------------------------------------------------------------
 * bookings terms conditions
 *
 */

.w-booking-terms-conditions__documents-container {
  padding: $w-booking-terms-conditions__documents-container__padding;
  background-color: $w-booking-terms-conditions__documents-container__color;

  & .w-booking-terms-conditions__nav-link {
    margin-bottom: 0;
  }
}

.w-booking-terms-conditions__conditions-container {
  margin-top: $w-booking-terms-conditions-container__margin-bottom;
}

.w-booking-terms-conditions__collapse-trigger {
  float: right;
}

.w-booking-terms-conditions__collapse .c-bullet-list__item.c-bullet-list__item--link {

  &:first-child{
    margin-top:  $w-booking-terms-conditions__collapse--first-child;
  }

  &:last-child {
    margin-bottom: $w-booking-terms-conditions__collapse--last-child;
  }
}

.w-booking-terms-conditions__nav-link.c-nav__link:hover {
  cursor: pointer;
  color: $w-booking-terms-conditions__nav-link-color--hover;
}

.c-collapse__trigger-icon {
  color: $c-collapse__trigger-icon--color;
}

.w-booking-terms-conditions__conditions-choice-list{
  .c-choice-list__messages {
    display: none;
  }

  &.has-error{
    .c-choice-list__messages {
      display: inherit;
    }
  }
}