/* -------------------------------------------------------------------------
 * CATEGORY
 */

$c-rating__icons--enabled:                          true !default;


$c-rating__font--size:                              $f-font-size--m !default;
$c-rating__font--sizes:                             map_remove($f-font-sizes, "xxs", "m", "xxl", "xxxl") !default;
$c-rating__icon:                                    map-get($m-icon__glyphs, star-full-fill);
$c-rating__color--contrasted:                       $f-color__neutral-grey--semi-light;

$c-rating__data: (
  1,
  2,
  3,
  4,
  5,
) !default;

$c-rating--width:                                     5em !default;

// Unresolved
$c-rating__unresolved--text-background--top:          11px !default;
$c-rating__unresolved--animation-bullet--width:       20px !default;
