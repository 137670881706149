/* -------------------------------------------------------------------------
 * SITE HEADER
 */

$l-site-header--small-search-bp:                                    "sml" !default;
$l-site-header--normal-search-bp:                                   "lg" !default;

$l-site-header__top--sp-inset-v:                                    $f-space--tiny !default;
$fr-header-menu-top__nav-swipe-btn--margin-top:                     .15em !default;
$l-site-header__top-left--left-adjustment--from-bp:                 .15em !default;
$l-site-header__top--min-height--from-bp:                           32px !default;

$l-site-header__main--sp-inset-v:                                   $f-space--tiny !default;
$l-site-header__main--sp-inset-v--from-bp:                          $f-space--small !default;
$l-site-header__main--padding-bottom--until-bp:                     $f-space--tiny !default;
$l-site-header__main--min-height--from-bp:                          50px !default;

$l-site-header__main-menu--margin-right:                            $f-space--medium !default;

$l-site-header__main-form-submit-btn--color:                        $f-color__brand-primary--ultra-light !default;

$l-site-header__logo--margin-right:                                 $f-space--medium !default;
$l-site-header__logo-img--props: (
  "xxs": (
    "height":                                                       auto,
    "width":                                                        71px,
  ),
  "sml": (
    "height":                                                       auto,
    "width":                                                        89px,
  ),
) !default;

$l-site-header__favourites:                                         $f-space--small !default;

$l-site-header--link-hover--color:                                  $f-color__brand-primary!default;
$l-site-header--link--color:                                        $f-color__brand-primary--dark !default;
$l-site-header__icons--color:                                       $f-color__brand-primary--dark !default;
$fr-header-menu-side-drawer__menu-icon--font-family:                $f-font-family__primary !default;
$fr-header-menu-side-drawer__menu-icon--margin-left:                $f-space--large / 3 !default;
$fr-header-menu-side-drawer__menu-icon--vertical-align:             $f-space--tiny !default;

$fr-header-free-text-search--small-bp:                              $l-site-header--small-search-bp !default;
$fr-header-free-text-search--width:                                 175px !default;
$fr-header-free-text-search--element-sp-inset-squish:               false !default;
$fr-header-free-text-search--positioning:                           $f-space--medium !default;

$l-site-header__search-icon--vertical-align:                        -8px !default;

$fr-header-menu-top__nav-item---link-color:                         $f-color__brand-primary--dark !default;

$fr-header-menu-top__nav-item-not-lv-1---link-color:                $f-color__brand-primary--dark !default;
$fr-header-menu-top__nav-item-not-lv-1---link-color--hover:         $f-color__brand-primary--dark !default;
$fr-header-menu-top__nav-item-not-lv-1---before-bg:                 $f-color__brand-primary--dark !default;

$fr-header__top-left-text-color:                                    $f-color__brand-primary--dark !default;

$fr-header-menu-side-drawer__header--background-color:              $f-color__neutral-white !default;

$fr-header-contact-phone--font-family:                              $f-font-family__secondary !default;
