/* -------------------------------------------------------------------------
 * ACCO LISTER
 *
 */

$w-acco-lister__c-slider--package-item__item-listed--sp-stack:      $f-space--small       !default;
$w-acco-lister__c-slider--package-item__item-listed--sp-inset-h:    $f-space--tiny        !default;
$w-acco-lister__c-slider__item--mobile-offset:                      2px                   !default;
$w-acco-lister__c-slider-item--margin-top:                          0 !default;
$w-acco-lister__c-slider-item--margin-bottom:                       $f-space--tiny !default;
