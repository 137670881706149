.m-country-flag {
  pointer-events: none;
  width: $m-country-flag--width;
  min-width: $m-country-flag--width;
  height: $m-country-flag--height;
  display: inline-block;
  background-image: url("#{$s-config__path--img}/flags.png");
  background-color: $m-country-flag--bg-color;
  background-position: $m-country-flag--width 0;
  background-repeat: no-repeat;
  box-shadow: $m-country-flag--shadow $m-country-flag--shadow-color;

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2 / 1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    background-size: $m-country-flag--image-width $m-country-flag--height;
  }
}

// Countries
// -----------------------------------------

@import "country-flag";
