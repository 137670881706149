// Swipe Object
// --------------------------------------------------

[data-js-swipe] {
  position: relative;
  overflow: hidden;

  > [class*="list"]:first-child {
    position: absolute;
    white-space: nowrap;
    max-width: 100%;
    margin-bottom: 0;
    overflow-x: auto;
    overflow-y: hidden;
    top: 0;
  }
}

.o-swipe-btn {
  font-size: s-core-px-to-em($o-swipe__button-fontsize);
  position: absolute;
  padding: 0;
  top: 0;
  bottom: 0;
  border: none;
  outline: none;
  width: $o-swipe__button-width;
  cursor: pointer;
  display: block;
  color: $o-swipe__button-color;
  transition: opacity $o-swipe__animation-opacity, width $o-swipe__animation-width;
  transform: translateY($o-swipe__button-transform);

  &.is-disabled {
    width: 0;
    opacity: 0;
  }

  &:hover,
  &:active {
    color: $o-swipe__button-hover;
  }

  &--left {
    left: 0;
    text-align: left;
    background: linear-gradient(to right, $o-swipe__background-initial 40%, $o-swipe__background-final);
  }

  &--right {
    right: 0;
    text-align: right;
    background: linear-gradient(to left, $o-swipe__background-initial 40%, $o-swipe__background-final);
  }
}


// Color schemes overrides
// --------------------------------------------------
@import "color-schemes";
