
/* -------------------------------------------------------------------------
 * COLORS STATE
 *
 * Define all state colors
 * The values here are for sample proposes, consider add your owns
 *
 */


// Main state colors

$f-color__state-success:                  #2ab17d !default;
$f-color__state-info:                     #0074fc !default;
$f-color__state-warning:                  #fc870e !default;
$f-color__state-danger:                   #e73737 !default;

// Success shades

$f-color__state-success--ultra-light:     #effbf6 !default;
$f-color__state-success--light:           #bee9d8 !default;
$f-color__state-success--semi-light:      #75cdab !default;

$f-color__state-success--semi-dark:       #1a6f4e !default;
$f-color__state-success--dark:            #13533a !default;

// Info shades

$f-color__state-info--ultra-light:        #ebf4ff !default;
$f-color__state-info--light:              #c0dcff !default;
$f-color__state-info--semi-light:         #4b9eff !default;

$f-color__state-info--semi-dark:          #005fcc !default;
$f-color__state-info--dark:               #004ca3 !default;

// Warning shades

$f-color__state-warning--ultra-light:     #fff5eb !default;
$f-color__state-warning--light:           #ffdab4 !default;
$f-color__state-warning--semi-light:      #febe7d !default;

$f-color__state-warning--semi-dark:       #c56908 !default;
$f-color__state-warning--dark:            #8d4a01 !default;

// Danger shades

$f-color__state-danger--ultra-light:      #fdeded !default;
$f-color__state-danger--light:            #f8c0c0 !default;
$f-color__state-danger--semi-light:       #f07c7c !default;

$f-color__state-danger--semi-dark:        #bd2424 !default;
$f-color__state-danger--dark:             #921111 !default;
