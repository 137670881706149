/*
  Shared mixins for shared styles in different templates for the Header images.
  Header image fragments:
    - Are placed inside l-site-main__top container
    - Use c-background-img surrounding the content
    - Have a title, h1 with m-heading.
    - Have an optional subtitle, h2 with m-heading
*/


@mixin t-shared-header-img__title($_align: $t-shared-header-img__content--text-align) {
  @include m-heading--update($t-shared-header-img__title--heading, $t-shared-header-img__heading--base);

  @include t-mq($from: $t-shared-header-img__content--bp) {
    @include m-heading--update($t-shared-header-img__title--heading--from-mq, $t-shared-header-img__title--heading);
  }

  text-align: $_align;
}

@mixin t-shared-header-img__subtitle($_heading--from-mq: null, $display--from-mq: null, $_align: $t-shared-header-img__content--text-align) {
  @if not ($_heading--from-mq) {
    $_heading--from-mq: $t-shared-header-img__subtitle--heading--from-mq;
  }

  @if not ($display--from-mq) {
    $display--from-mq: $t-shared-header-img__content--bp;
  }

  @if ($display--from-mq != "xxs") {
    @include t-mq($until: $display--from-mq) {
      display: none;
    }
  }

  @include t-mq($from: $display--from-mq) {
    @include m-heading--update($_heading--from-mq, $t-shared-header-img__heading--base);
    margin-top: $t-shared-header-img__subtitle--margin-top;

    text-align: $_align;
  }
}
