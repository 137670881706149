/* -------------------------------------------------------------------------
 * SEARCH MAP VARS
 *
 */

$w-search-map__btn--border-color:                                                       $f-color__neutral-grey--semi-light !default;
$w-search-map__btn--border-color--hover:                                                $f-color__neutral-grey--semi-light !default;
$w-search-map__btn--bg-color--hover:                                                    $f-color__neutral-grey--ultra-light !default;

$w-search-map__modal-header-btn--border-radius:                                         $f-radius--medium !default;

$w-search-map__btn--bg-color--active:                                                   $f-color__neutral-grey--light !default;
$w-search-map__btn--bg-color--focus:                                                    $f-color__neutral-white !default;

$w-search-map__mobile-filters-btn--bg-color:                                            $f-color__brand-primary !default;
$w-search-map__mobile-filters-btn--bg-color-hover:                                      $f-color__brand-primary--semi-light !default;
$w-search-map__mobile-filters-btn--bg-color-active:                                     $f-color__brand-primary--semi-dark !default;
$w-search-map__mobile-filters-btn--color:                                               $f-color__neutral-white !default;
$w-search-map__search-result--border--hover:                                            1px solid transparent !default;
$w-search-map__search-result--bg-color--hover:                                          unset !default;
