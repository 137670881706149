/* -------------------------------------------------------------------------
 * CARD PRICE VARS
 */

$c-card-price--breakpoint-tablet:                           $f-breakpoint--sm !default;

$c-card-price--main-spacing:                                $f-space--medium !default;
$c-card-price--secondary-spacing:                           $c-card-price--main-spacing * .5 !default;

$c-card-price__icon-wrapper--bg-color:                      $f-color__neutral-grey--semi-light !default;
$c-card-price__icon-wrapper--color:                         $f-color__neutral-white !default;
$c-card-price__icon-font-size:                              $f-font-size--xxxl * 2 !default;

$c-card-price__headings--sp-inline:                         $f-space--small !default;

$c-card-price__text--mobile-font-size:                      $f-font-size--s !default;

$c-card-price__price--min-width:                            115px !default;
$c-card-price__price--legend-margin:                        9px !default;

$c-card-price__bg-color:                                   #fff !default;

//Modifier vars: compact

$c-card-price--compact--transition-duration:                $f-duration--fast !default;
$c-card-price--compact--transition-timing-function:         $f-easing--base !default;

$c-card-price--compact--box-shadow:                         $f-shadow--elevated rgba($f-shadow__color, $f-shadow__opacity--elevated) !default;

$c-card-price--compact__title--line-height:                 $f-line-height--small !default;

$c-card-price--compact__img--height:                        125px !default;
$c-card-price--compact__img--height-with-price:             150px !default;
$c-card-price--compact__img--width:                         $c-card-price--compact__img--height !default;
$c-card-price--compact__img--width-with-price:              $c-card-price--compact__img--height-with-price !default;

$c-card-price--compact__description--margin-bottom:         16px !default;
$c-card-price--compact__description-content--line-height:   $f-line-height--small !default;

$c-card-price--compact__body--margin-padding:               $f-space--medium 16px !default;
$c-card-price--compact__body--color:                        $f-color__neutral-black !default;

$c-card-price--compact__info--margin-bottom:                8px !default;

$c-card-price--compact__text-max-lines:                     2 !default;
$c-card-price--compact__title-height:                       20px !default;
$c-card-price--compact__text-height:                        17.5px !default;
