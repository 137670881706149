/* -------------------------------------------------------------------------
 * ICON-ROW
 *
 *
 */

// Component output
.c-icon-row {
}

.c-icon-row__item {
  @include sp-stack($c-icon-row__padding);
  @include sp-inline($c-icon-row__padding--inline);
  display: inline-block;
  line-height: $c-icon-row__line-height;

  &:last-child {
    @include sp-inline($f-space--none);
  }
}

.c-icon-row__link {
  font-weight: 400;
  width: 100%;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
    outline: 0;
    color: darken($c-icon-row__link-hover--color, 10%);
  }
}

.c-icon-row__item {
  &.is-active {
    .c-icon-row__link {
      font-weight: 700;
    }
  }
}

// Item Icon Modifier
.c-icon-row__item--icon {
  font-size: $c-icon-row__icon-size--icon;
}

// Variants
// --------------------------------------------------

// Justified
.c-icon-row--justified {
  .c-icon-row__list {
    display: flex;
    justify-content: space-evenly;
  }
}

// Color schemes overrides
// --------------------------------------------------
@import "color-schemes";
