/* -------------------------------------------------------------------------
 * BOOKING ACCOMMODATION SUMMARY VARS
 *
 */

 $w-booking-accommodation-summary__header--sp-stack:                        $f-space--medium !default;
$w-booking-accommodation-summary__breakpoint-sml:                           $f-breakpoint--sml !default;

$w-booking-accommodation-summary__rating--color:                            $f-color__state-warning--semi-light !default;
$w-booking-accommodation-summary__rating--sp-stack:                         $f-space--tiny !default;

$w-booking-accommodation-summary__breadcrumbs--sp-stack:                    $f-space--small !default;
$w-booking-accommodation-summary__breadcrumbs--color:                       $f-color__neutral-grey--semi-dark !default;

$w-booking-accommodation-summary__image--sp-stack:                          $f-space--medium !default;
$w-booking-accommodation-summary__image--sp-inline:                         $f-space--medium !default;

$w-booking-accommodation-summary--left-column-width:                        35% !default;
$w-booking-accommodation-summary--right-column-width:                       60% !default;
$w-booking-accommodation-summary__acco-name--sp-stack:                      $f-space--tiny !default;

$w-booking-accommodation-summary__booking-filters-item--sp-stack:           $f-space--small !default;
$w-booking-accommodation-summary__booking-filters-item--sp-inline:          $f-space--small !default;

$w-booking-accommodation-summary__rooms-title--sp-stack:                    $f-space--tiny + $f-space--small !default;

$w-booking-accommodation-summary__room-name--font-size:                     $f-font-size--s !default;
$w-booking-accommodation-summary__room-name--sp-stack:                      $f-space--small !default;

$w-booking-accommodation-summary__room-info--font-size:                     $f-font-size--s !default;
$w-booking-accommodation-summary__room-info--width:                         100% !default;

// is-unresolved
$w-booking-accommodation-summary--unresolved-border-radius:                 $f-radius--medium !default;

$w-booking-accommodation-summary__rooms-title__unresolved--width:           7em !default;