/* -------------------------------------------------------------------------
* Cookies consent
*
*/

.w-cookie-consent{
    .w-cookie-consent-modal-introduction__open-settings-modal-link{
        color: $w-cookie-consent_cookie-installen-link--color;

        &:hover{
            color: $w-cookie-consent_cookie-installen-link--color-hover;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &.settings-in{
        .w-cookie-consent-modal-introduction{
            display: none;
        }
    }
    .w-cookie-settings__options-item{
        display: flex;

        .c-checkbox{
            padding-top: $w-cookie-consent_checkbox-item;
        }
        .c-accordion__item:last-child {
            border-bottom: none;
        }
    }
    .w-cookie-settings__button-area{
        @include t-mq ($until: "sml") {
            flex-direction: column;
        }
        display: flex;
    }

    .w-cookie-consent-modal-settings__confirm-btn,
    .w-cookie-consent-modal-introduction__accept-btn{
        @include t-mq ($from: "sml") {
            margin-right: $w-cookie-consent_cookie-buttons-mr;
        }
    }

    .w-cookie-consent-modal-settings__refuse-btn,
    .w-cookie-consent-modal-introduction__refuse-btn{
        text-decoration: underline;
        color: $w-cookie-consent_cookie-installen-link--color;
        text-transform: none;

        &:hover{
            color: $w-cookie-consent_cookie-installen-link--color-hover;
        }
    }

    .w-cookie-consent-modal-settings{
        .c-modal-v2__body{
            padding-bottom: $w-cookie-consent_cookie-installen-body-pb;
        }
    }

    .w-cookie-consent-modal-settings__go-back-arrow{
        color: $w-cookie-consent_go-back-btn--color;

        &:hover{
            color:$w-cookie-consent_go-back-btn--color-hover;
        }

        i.c-btn__icon{
            height: $w-cookie-consent_go-back-btn--height;
            padding-left: $w-cookie-consent_go-back-btn--pl;

            &::before{
                font-size: $w-cookie-consent_go-back-btn--font-size;
            }
        }
    }
}