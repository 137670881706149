.t-destination-page__top {
  display: flex;
  flex-direction: column;
}

.t-destination-page__top .c-breadcrumbs {
  order: 1;
  margin-top: $t-destination-page__breadcrumbs--margin-top;
  @extend .o-wrapper;
  @extend .l-site__wrapper--medium;
  
  @include t-mq($until: $t-destination-page--bp) {
    margin-bottom: $t-destination-page__breadcrumbs--margin-bottom--until-bp;
  } 
}

.fr-destination-page__header-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include sp-inset($fr-destination-page__header-img-container--sp-inset-h, $fr-destination-page__header-img-container--sp-inset-v);

  @include t-mq($from: $fr-destination-page__header-img--bp) {
    @include sp-inset-stretch($fr-destination-page__header-img-container--sp-inset-from-bp);
  } 
}

//I need to make an override of the default margin, so I need to target a more specific selector
.t-destination-page .fr-destination-page__header-img-title.fr-destination-page__header-img-title {
  @include t-shared-header-img__title();
  @include sp-stack($fr-destination-page__header-img-title--sp-stack);
}

.fr-destination-page__header-img-dropdowns {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include t-mq($from: $fr-destination-page__header-img--bp) {
    flex-direction: row;
    flex: 1 0 auto;
    width: 100%;
    justify-content: center;
  } 
}

.fr-destination-page__header-img-dropdown {
  margin-right: 0;

  &:not(:last-child) {
    @include sp-stack($fr-destination-page__header-img-dropdown--margin-bottom);
  }

  @include t-mq($from: $fr-destination-page__header-img--bp) {
    flex: 1 100%;
    max-width: $fr-destination-page__header-img-dropdown--max-width;

    &:not(:last-child) {
      margin-right: $fr-destination-page__header-img-dropdown--margin-right;
      margin-bottom: 0;
    }
  }
}
