/* -------------------------------------------------------------------------
 * DESTINATION DESCRIPTION
 *
 */

.t-destination-page {
  @include t-mq($until: $fr-destination-page__description--bp) {
    padding-bottom: $fr-destination-page__cta--min-height;
  }

  [class^="fr-"] {
    > .c-title {
      @include sp-stack($t-destination-page__title--sp-stack);
    }
  }

  .fr-layout-container {
    margin-bottom: 0;

    .o-layout {
      padding-top: $fr-layout-container__padding;
    }

    .o-layout__item {
      padding-top: 0;
    }
  }

  .o-layout__item {
    & > [class^="fr-"] {
      margin-bottom: $t-destination-page__main--sp-stack;
    }
  }
}

.fr-destination-page__flight-info,
.fr-destination-page__practical-info,
.fr-destination-page__bus-info {
  @extend %fr-wide-text;
}

@import 'fr-destination-page__description';
@import 'fr-destination-page__cta';
@import 'fr-destination-page__description-with-slider';
@import 'fr-destination-page__discover';
@import 'fr-destination-page__header-img';
@import 'fr-destination-page__highlighted-video';
@import 'fr-destination-page__highlighted-video-centered';
@import 'fr-destination-page__practical-info';
@import 'fr-destination-page__party-group-statistics';
@import 'fr-destination-page__excursions';
@import 'fr-destination-page__expert-tips';

