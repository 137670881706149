// Swipe Object vars
// --------------------------------------------------

$o-swipe__button-width:                                     3em !default;
$o-swipe__background-initial:                               $f-color__neutral-white !default;
$o-swipe__background-final:                                 $f-color__neutral-white--transparent !default;
$o-swipe__button-fontsize:                                  $f-font-size--xs !default;
$o-swipe__button-color:                                     $e-links__color !default;
$o-swipe__button-hover:                                     $f-color__brand-secondary !default;
$o-swipe__button-transform:                                 -1px !default;
$o-swipe__animation-opacity:                                $f-duration--normal !default;
$o-swipe__animation-width:                                  $f-duration--slow !default;

$o-swipe__nav-border:                                       true !default;


// Color schemes overrides
// -----------------------

// Default Light
$o-swipe__button-color--cs-default--light:                  $cs-default--light__text !default;
$o-swipe__button-color--hover--cs-default--light:           $f-color__brand-primary--semi-dark !default;
$o-swipe__button-bg-initial--cs-default--light:             $f-color__neutral-white !default;

// Brand Primary
$o-swipe__button-color--cs-brand-primary:                   $cs-brand-primary--link !default;
$o-swipe__button-color--hover--cs-brand-primary:            $cs-brand-primary--link-hover !default;
$o-swipe__button-bg-initial--cs-brand-primary:              $cs-brand-primary--background !default;

// Brand Primary Dark
$o-swipe__button-color--cs-brand-primary--dark:             $cs-brand-primary--dark--link !default;
$o-swipe__button-color--hover--cs-brand-primary--dark:      $cs-brand-primary--dark--link-hover !default;
$o-swipe__button-bg-initial--cs-brand-primary--dark:        $cs-brand-primary--dark--background !default;
