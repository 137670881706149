@mixin c-img-box() {

  @include o-block--stretch();

  &.in, &.is-closing {
    z-index: $c-img-box--z-index;
  }

  &.is-closing {
    opacity: 1;
  }

}
