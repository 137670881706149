/* -------------------------------------------------------------------------
 * SITE FOOTER
 *
 */

// Selectors output
// -------------------------------------------------

.l-site-footer {
}

// Footer main
// -------------------------------------------------

.l-site-footer__main {
  @include sp-inset-v($l-site-footer__main--sp-inset-v);
}

.l-site-footer__main-content {
  .c-title {
    text-transform: $l-site-footer__main-title--text-transform;
    @include sp-stack($l-site-footer__main-title--sp-stack);
  }

  .c-nav__item {
    @include sp-stack($l-site-footer__c-nav--sp-stack);
  }
}

.l-site-footer__icon-row-image--boxed {
  display: inline-block;
  background-color: $l-site-footer__item--boxed--icon-row--bg-color;
  border-radius: $l-site-footer__item--boxed--icon-row--border-radius;
  @include sp-inset($l-site-footer__item--boxed--icon-row--sp-inset);
  padding-bottom: ($l-site-footer__item--boxed--icon-row--sp-inset - $l-site-footer__item--boxed--icon-row--item-sp-stack);
}

// Footer main extra
// -------------------------------------------------

.l-site-footer__main-extra {
  padding-top: $l-site-footer__main-extra--padding-top;
  padding-bottom: $l-site-footer__main-extra--padding-bottom;

  &::before{
    display: inline-block;
    content: "";
    border-top: $l-site-footer__main-extra--separator-border;
    width: 100%;
    transform: translateY($l-site-footer__main-extra--separator-translatey);
  }
}

// Footer bottom
// -------------------------------------------------

.l-site-footer__bottom {
  padding-top: $l-site-footer__bottom--padding-top;
  padding-bottom: $l-site-footer__bottom--padding-bottom;
}

.l-site-footer__bottom-content {
  text-align: center;
  font-size: $l-site-footer__bottom--font-size;
}
