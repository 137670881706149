/* -------------------------------------------------------------------------
 * BREAKDOWN TABLE
 */

.c-breakdown-table__item.c-breakdown-table__item--total {
  &.c-table__item:last-child {
    border-bottom: 0;
    border-top-width: $c-breakdown-table__item--color--total;
    font-weight: $c-breakdown-table__item--font-weight--bold;
    text-transform: $c-breakdown-table__item--text-transform--total;
  }
}

.c-breakdown-table__item-content {
  display: flex;
  margin: 0 $c-breakdown-table__item--margin 0;
}

.c-breakdown-table__item-content--subitem {
  color: $c-breakdown-table__item--color--subitem;
  @include m-heading--complete($c-breakdown-table__item--material--subitem);
}

.c-breakdown-table__item-content--subitem--info {
  color: $c-breakdown-table__item--color--subitem--info;
}
.c-breakdown-table__item-content--preitem {
  color: $c-breakdown-table__item--color--preitem;
  @include m-heading--complete($c-breakdown-table__item--material--preitem);
}

.c-breakdown-table__item-content--discount {
  font-weight: $c-breakdown-table__item--font-weight--bold;
  color: $c-breakdown-table__item-content--color--discount;
}


.c-breakdown-table__item-content--discount {
  .c-breakdown-table__item-content.c-breakdown-table__item-content--subitem {
    color: $c-breakdown-table__item-content--color--discount;
    font-weight: $c-breakdown-table__item--font-weight--normal;
  }
}

.c-breakdown-table__item-left {
  flex-grow: 1;
}

.c-breakdown-table__item-right {
  padding-left: $c-breakdown-table__item-right--sp-right;
}
