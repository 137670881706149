/* -------------------------------------------------------------------------
 * HEADINGS
 *
 * Headings known as a material not as a tag
 *
 */


// Material selector output
// --------------------------------------------------

@if $m-heading--enabled {
  .m-heading {
    @include m-heading--complete($m-heading__properties);
  }
}


// Material sizes modifiers output
// --------------------------------------------------

@if $m-heading--enabled and $m-heading__mod-sizes--enabled {
  @each $_mod-name, $_mod-properties in $m-heading__mod-sizes {
    @if $_mod-properties != null {
      .m-heading--#{$_mod-name} {
        @include m-heading--update($_mod-properties, $m-heading__properties);
      }
    }
  }
}


// No-Crop modifier disables the text crop applied
// --------------------------------------------------

@if $m-heading--enabled {
  .m-heading--no-crop {
    @include t-text-crop-disable();
  }
}
