/* -------------------------------------------------------------------------
 * GLOSSARY
 *
 */

.t-glossary {
  [class^="fr-"] {
    > .c-title {
      @include sp-stack($t-glossary__c-title--sp-stack);

      @include t-mq($until: $fr-glossary__tab-spacing--bp) {
        @include sp-stack($t-glossary__c-title--sp-stack--small);
      }
    }
  }
}

@import 'fr-glossary__top-img';
@import 'fr-glossary__tabs';
