/* -------------------------------------------------------------------------
 * BOOKING TRANSPORT SUMMARY SELECTOR
 *
 */

.w-booking-transport-summary{
  .w-transport-summary {
    @include t-mq($from: $w-transport-summary__breakpoint) {
      margin: $w-booking-transport-summary__spacing;
    }
  }
}
