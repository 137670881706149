/* -------------------------------------------------------------------------
 * This is the second implementation of the practical info fragment.
 * If there is a third one, it might be best to use a SASS placeholder.
 *
 */

.fr-destination-page__practical-info-text-container {
  margin-bottom: $fr-destination-page__practical-info-text-container--sp-stack;
}

.fr-destination-page__practical-info-text-container--preview {
  > * {
    display: none;
  }

  > *:nth-child(-n+4) {
    display: inherit;
  }

  > *:nth-child(4) {

    @include t-mq($from: $fr-destination-page__practical-info-text-container--preview--bp) {
      display: none;
    }

    @include t-mq($from: $fr-destination-page__practical-info-text-container--preview--bp-big) {
      display: inherit;
    }
  }
}
