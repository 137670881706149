$f-font-family__librefranklin:            'LibreFranklin' !default;
$f-font-family__librefranklin--fallback:  sans-serif !default;

$f-font-family__librefranklin--crop: (
  "font-size":      40,
  "line-height":    1,
  "top":            4,
  "bottom":         6
) !default;

@font-face {
  font-family: $f-font-family__librefranklin;
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local('LibreFranklin'),
  url('#{$s-config__path--fonts}/librefranklin/librefranklin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$s-config__path--fonts}/librefranklin/librefranklin-regular.woff') format('woff'); /* Modern Browsers */
}

@if variable-exists(web-fonts) {
  $web-fonts: map-merge($web-fonts, ("LibreFranklin": "#{$s-config__path--fonts}/librefranklin/librefranklin-regular.woff2"));
}


@font-face {
  font-family: $f-font-family__librefranklin;
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: local('librefranklin Bold'), local('librefranklin-Bold'),
  url('#{$s-config__path--fonts}/librefranklin/librefranklin-bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$s-config__path--fonts}/librefranklin/librefranklin-bold.woff') format('woff'); /* Modern Browsers */
}

@if variable-exists(web-fonts) {
  $web-fonts: map-merge($web-fonts, ("librefranklin-bold": "#{$s-config__path--fonts}/librefranklin/librefranklin-bold.woff2"));
}
