/* -------------------------------------------------------------------------
 * BACKDROP
 *
 */

// Component selector output
// --------------------------------------------------

.c-backdrop {
  position: fixed;
  top: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($c-backdrop--color, $c-backdrop--opacity);
  opacity: 0;
  transition-property: opacity;
  transition-duration: $c-backdrop__out--duration;
  transition-timing-function: $c-backdrop__out--easing;
  z-index: $c-backdrop--z-index;

  &.in {
    top: 0;
    opacity: 1;
    transition-timing-function: $c-backdrop__in--easing;
  }

  &.is-closing,
  &.is-opening {
    top: 0;
  }

  &.is-loading {
    &::#{$m-icons__pseudo-element} {
      position: fixed;
      top: 50%;
      left: 50%;
      content: "";
      display: block;
      font-size: $c-backdrop__loader--size;
      height: 1em;
      width: 1em;
      border: $c-backdrop__loader--border-width $c-backdrop__loader--border-type rgba($c-backdrop__loader--border-color, $c-backdrop__loader--border-opacity);
      border-left-color: $c-backdrop__loader--border-color;
      border-radius: $f-radius--rounded;
      transform: translate3d(-50%,-50%,0);
      animation: c-backdrop__loader--animation $c-backdrop__loader--animation-duration $c-backdrop__loader--animation-easing $c-backdrop__loader--animation-repeat;
    }

    @keyframes c-backdrop__loader--animation {
      0% {transform: rotate(0turn);}
      100% {transform: rotate(1turn);}
    }
  }
}
