/* -------------------------------------------------------------------------
 * SIMPLE FORM
 *
 */

 .t-simple-form {
  .l-site-main__top::after {
    content: none;
    @include t-mq($from: $t-simple-form__background-image--bp) {
      content: "";      
    }
  }
}
