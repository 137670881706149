/* -------------------------------------------------------------------------
 * LOADER
 *
 */

.c-loader {
  display: block;
  &::#{$c-loader--pseudo-element} {
    content: "";
    display: block;
    font-size: $c-loader--size;
    height: 1em;
    width: 1em;
    border: $c-loader--border-width solid rgba($c-loader--border-color, $c-loader--border-opacity);
    border-top-color: $c-loader--border-color;
    border-radius: $f-radius--rounded;
    will-change: transform;
    animation-name: c-loader;
    animation-duration: $c-loader--duration;
    animation-iteration-count: infinite;
    animation-timing-function: $c-loader--easing;
  }
}


@keyframes c-loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


// Size modifiers
// -----------------------------------------

.c-loader--tiny {
  &::#{$c-loader--pseudo-element} {
    font-size: $c-loader--tiny--size;
    border-width: $c-loader--tiny--border-width;
  }
}

.c-loader--small {
  &::#{$c-loader--pseudo-element} {
    font-size: $c-loader--small--size;
    border-width: $c-loader--small--border-width;
  }
}