.fr-header-menu-side-drawer {
    .c-side-drawer {
        text-align: left;
    }

    .fr-header-menu-side-drawer__menu-icon {
        position: relative;
    
        .c-btn {
          vertical-align: $fr-header-menu-side-drawer__menu-icon--vertical-align;
          padding-bottom: $fr-header-menu-side-drawer__menu-icon--padding-bottom;
        }
    
        &::after {
          content: attr(data-text);
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          text-align: center;
          text-transform: uppercase;
          font-size: $fr-header-menu-side-drawer__menu-icon--font-size;
          font-family: $fr-header-menu-side-drawer__menu-icon--font-family;
          margin-top: $fr-header-menu-side-drawer__menu-icon--margin-top;
          pointer-events: none;
        }
    }
}