/* -------------------------------------------------------------------------
 * DROPDOWN MULTIPLE FILTER
 *
 */

.c-dropdown--show-counter-below-caption .c-checkbox__text-content {
  flex-direction: column;
  align-items: flex-start;

  .c-checkbox__count {
    padding-left: 0;
  }
}

.c-dropdown--show-counter-below-caption .c-floating-box__body-content {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding-right: $c-dropdown-show-counter-below-caption__body-content--padding-left;
}
