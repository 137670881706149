/* -------------------------------------------------------------------------
 * PHOTO GALLERY
 */

.c-photo-gallery {
  display: flex;
  flex-wrap: wrap;

  .c-slider__content {
    background: $c-photo-gallery__content-background;
  }

  .c-modal {
    background: $c-photo-gallery__background;
    overflow: visible;
  }

  .c-modal__close {
    right: 0;
    @include t-mq($from: $c-photo-gallery__breakpoint--tablet) {
      right: $c-photo-gallery__close-offset;
    }
  }

  .c-modal__header {
    background: transparent;
    padding: $c-photo-gallery__header-vertical-padding $c-photo-gallery__header-horizontal-padding;
  }

  .c-modal__body {
    padding: 0;

    &.m-body {
      @include t-text-crop-disable();
    }
  }

  .c-modal__footer {
    @include sp-inset($c-photo-gallery__description-sp-inset-v, $c-photo-gallery__description-sp-inset);
    position: absolute;
    width: 100%;
    font-size: $c-photo-gallery__description-size--mobile;
    text-align: center;
    color: $c-photo-gallery__description-color;

    @include t-mq($from: $c-photo-gallery__breakpoint--tablet) {
      font-size: $c-photo-gallery__description-size--tablet;
    }
  }
}

.c-photo-gallery__item {
  width: percentage(1 / $c-photo-gallery__items-per-row--default);
  transition-duration: $c-photo-gallery--transform-time-out;
  transition-property: transform;
  cursor: pointer;
  padding: $c-photo-gallery__grid--gutter * .5;

  @include t-mq($c-photo-gallery__breakpoint--tablet) {
    width: percentage(1 / $c-photo-gallery__items-per-row--tablet);
  }

  @include t-mq($c-photo-gallery__breakpoint--desktop) {
    width: percentage(1 / $c-photo-gallery__items-per-row--desktop);
  }

  &:hover,
  &:focus {
    outline: none;
    .c-img {
      box-shadow: $c-photo-gallery--hover--shadow rgba($c-photo-gallery--hover--shadow--color, $c-photo-gallery--hover--shadow--opacity);
      transition-duration: $c-photo-gallery--transform-time-in;
    }
  }
}

.c-photo-gallery__item-video {
  display: inline-block;
  position: relative;
  .c-img {
    -webkit-filter: brightness(75%);
  }
  &:hover{
    .c-photo-gallery-video-thumbnail-info-icon {
      background-color: $c-photo-gallery__thumbnail--info-icon-bg-color-hover;
      &:before {
        color: $c-photo-gallery__thumbnail--info-icon-content-color-hover;
      }
    }
  }
}
.c-photo-gallery-video-thumbnail-info-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 50%;
  display: flex;
  background-color: $c-photo-gallery__thumbnail--info-icon-color;
  width: $c-photo-gallery__play-button-icon;
  height: $c-photo-gallery__play-button-icon;
  transition-duration: $c-photo-gallery__transform-time-out;
  transition-property: all;

  &:before {
    position: absolute;
    display: block;
    color: $c-photo-gallery__thumbnail--info-icon-content-color;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition-duration: $c-photo-gallery__transform-time-out;
    transition-property: all;
  }

  &:hover{
    background-color: $c-photo-gallery__thumbnail--info-icon-bg-color-hover;
    color: $c-photo-gallery__thumbnail--info-icon-content-color-hover;
  }
}
