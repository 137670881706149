/* -------------------------------------------------------------------------
 * BLOCK VARIABLES
 *
 */

// Object toggling

$o-block--enabled:                        true !default;

// Object Params

$o-block__alignment:                      left !default;

// Modifiers Alignments

$o-block__mod-alignments--enabled:        true !default;
$o-block__mod-alignments:                 (left, center, right) !default;

// Modifiers Stretch

$o-block__mod-stretch--enabled:           true !default;
