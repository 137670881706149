/* -------------------------------------------------------------------------
 * FLIGHT/BUS SELECTOR
 *
 */

// Wrapper
.w-flight-bus-selector__heading {
  @include sp-stack($w-flight-bus-selector__spacing-secondary);
}

.w-flight-bus-selector__heading-text,
.w-flight-bus-selector__heading-date {
  display: block;
  line-height: 1.25;
}

.w-flight-bus-selector__heading-date {
  color: $w-flight-bus-selector__heading-dates--color;
  display: inline-block;
}

.w-flight-bus-selector__heading-date-separator,
.w-flight-bus-selector__heading-date-arrival {
  margin-left: $w-flight-bus-selector__heading-date-arrival--separation;
}

.w-flight-bus-selector__tabs-nav {
  @include sp-stack($w-flight-bus-selector__spacing-secondary);
}

.w-flight-bus-selector__collapse {
  min-height: initial;

  &.c-collapse:not([data-c-collapse__max-items]) {
    @include t-mq($until: $w-flight-bus-selector__breakpoint) {
      max-height: initial;
    }
  }

  @include t-mq($from: $w-flight-bus-selector__breakpoint) {
    min-height: $w-flight-bus-selector__collapse--min-height;
  }
}

.w-flight-bus-selector__collapse-single-row {
  @include t-mq($from: $w-flight-bus-selector__breakpoint) {
    min-height: $w-flight-bus-selector__collapse--min-height-small;
  }
}

.w-flight-bus-selector__collapse-btn {
  width: fit-content;
  @include t-mq($until: $w-flight-bus-selector__breakpoint) {
    display: none;
  }
  &.is-hidden {
    display: none;
  }
}

.w-flight-bus-selector__heading--unrelated {
  margin-top: $w-flight-bus-selector__spacing-tertiary;
  margin-bottom: $w-flight-bus-selector__spacing-secondary;

  &.is-hidden {
    display: none;
  }
}

.w-flight-bus-selector__footer {
  @include t-mq($from: $w-flight-bus-selector__breakpoint) {
    display: none;
  }
}

// Selected items

.w-flight-bus-selector__selected {
  .c-transport-ticket {
    @include sp-stack($w-flight-bus-selector__spacing-secondary);
  }
}

// Collapse trigger

.w-flight-bus-selector__collapse-btn {
  width: fit-content;
  &[data-c-collapse__action="open"][data-c-collapse__opened="true"],
  &[data-c-collapse__action="close"]:not([data-c-collapse__opened="true"]){
    display: none;
  }

  &[data-c-collapse__action="open"]::before {
    content: "";
    position: absolute;
    width: 100vw;
    left: -50vw;
    height: $w-flight-bus-selector__gradient--height;
    top: -($w-flight-bus-selector__gradient--height + $w-flight-bus-selector__gradient--position-fix);
    background-image: linear-gradient(to top, $w-flight-bus-selector__gradient--initial-color $w-flight-bus-selector__gradient--initial-change, $w-flight-bus-selector__gradient--final-color $w-flight-bus-selector__gradient--final-change);
    z-index: $w-flight-bus-selector__gradient--z-index;
  }
}

// Inner components' overwrite

.w-flight-bus-selector {
  &:empty {
    display: none;
  }

  // Tabs
  .c-tabs__content-wrapper:not(.w-flight-bus-selector__flexible-regular-flights-tabs) {
    @include t-mq($from: $w-flight-bus-selector__breakpoint) {
      @include o-pack($w-flight-bus-selector__spacing-main);
    }

    .c-tabs__content {
      padding: 0;
      @include t-mq($from: $w-flight-bus-selector__breakpoint) {
        @include o-pack__item();
      }
    }
  }

  // Side Drawer

  .c-side-drawer {
    @include t-mq($until: $w-flight-bus-selector__breakpoint) {
      @include sp-inset-h($w-flight-bus-selector__side-drawer--padding);
    }
    @include t-mq($from: $w-flight-bus-selector__breakpoint) {
      &:not([class*="c-side-drawer--until"]) {
        display: block;
        position: relative;
        opacity: 1;
        width: 100%;
        transform: none;
        transition: none;
        .c-side-drawer__body-content {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }
    }
    &:not([class*="c-side-drawer--until"]) {
      .c-side-drawer__body-content {
        padding: 0;
      }
    }
  }
  .c-side-drawer__header {
    color: $w-flight-bus-selector__side-drawer-header--color;
    background-color: $w-flight-bus-selector__side-drawer-header--bg-color;
    margin-left: -$w-flight-bus-selector__side-drawer--padding;
    margin-right: -$w-flight-bus-selector__side-drawer--padding;
    font-weight: $w-flight-bus-selector__side-drawer-header--font-weight;
    @include sp-stack($w-flight-bus-selector__spacing-secondary);
    &.c-side-drawer__header {
      @include t-mq($from: $w-flight-bus-selector__breakpoint) {
        display: none;
      }
    }
    .c-side-drawer__header-title {
      text-transform: $w-flight-bus-selector__side-drawer-header--text-transform;
    }
    .c-side-drawer__close {
      color: $w-flight-bus-selector__side-drawer-header--color;
    }
  }
  .c-side-drawer__footer {
    border-top: $w-flight-bus-selector__side-drawer-footer--border-width $w-flight-bus-selector__side-drawer-footer--border-style $w-flight-bus-selector__side-drawer-footer--border-color;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    &.c-side-drawer__footer {
      display: flex;
      @include t-mq($from: $w-flight-bus-selector__breakpoint) {
        display: none;
      }
    }
  }
}

.w-flight-bus-selector__extra-info {
  margin-top: $w-flight-bus-selector_extra-info--spacing;
  margin-bottom: $w-flight-bus-selector_extra-info--spacing;
  font-size: $w-flight-bus-selector__extra-info--font-size;
  color: $w-flight-bus-selector__extra-info--color;
  margin-left: $w-flight-bus-selector__extra-info--margin;

  @include t-mq($until: $w-flight-bus-selector__breakpoint) {
    margin-left: 0;
  }
}

.w-flight-bus-selector__options {
  margin: 0;
}

.w-flight-bus-selector__options.is-disabled, .w-flight-bus-selector__options--unrelated {
  opacity: $w-flight-bus-selector__disabled--opacity;
  pointer-events: none;
}

.w-flight-bus-selector-error-message {
  padding: $w-flight-bus-selector__error-message--padding;
  border-width: $w-flight-bus-selector__error-message--border-width;
  border-style: $w-flight-bus-selector__error-message--border-style;

  & p {
    margin-bottom: 0;
  }
}

//Flexible/regular flights

.c-tabs__nav.w-flight-bus-selector__flexible-regular-flights-tabs {
  @include t-mq($until: $w-flight-bus-selector__breakpoint) {
    margin-top: $w-flight-bus-selector__flexible-regular-flights-tabs--margin-top;
  }
}

.c-tabs__content-wrapper.w-flight-bus-selector__flexible-regular-flights-tabs {
  .c-tabs__content {
    padding: $w-flight-bus-selector__flexible-regular-flights-tabs-content--padding;
    margin-bottom: $w-flight-bus-selector__flexible-regular-flights-tabs-content--margin-bottom;

    @include t-mq($until: $w-flight-bus-selector__breakpoint) {
      padding: $w-flight-bus-selector__flexible-regular-flights-tabs-content--padding--from-bp;
      margin-bottom: $w-flight-bus-selector__flexible-regular-flights-tabs-content--margin-bottom--from-bp;
    }
  }
}

.w-flight-bus-selector__flexible-regular-flights-tabs .is-hidden {
  display: none;
}

.w-flight-bus-selector__header-container {
  padding: $w-flight-bus-selector__header-container--padding;
  border-bottom: $w-flight-bus-selector__header-container--border-bottom;
  margin-bottom: $w-flight-bus-selector__header-container--margin-bottom;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  @include t-mq($from: $w-flight-bus-selector__open-jaw-switch--breakpoint) {
    align-items: center;
    flex-direction: row;
  }
}

.w-flight-bus-selector__open-jaw-switch {
  @include t-mq($until: $w-flight-bus-selector__open-jaw-switch--breakpoint) {
    margin-top: $w-flight-bus-selector__open-jaw-switch--margin-top;
  }
}

.w-flight-bus-selector__open-jaw-switch .c-switch__text {
  @include t-mq($from: $w-flight-bus-selector__open-jaw-switch--breakpoint) {
    order: -1;
    text-align: right;
    margin-right: $w-flight-bus-selector__open-jaw-switch-text--margin-right;
  }
}

.w-flight-bus-selector__open-jaw-switch .c-switch__slider  {
  @include t-mq($from: $w-flight-bus-selector__open-jaw-switch--breakpoint) {
    margin-right: 0;
  }
}

.w-flight-bus-selector__open-jaw-switch-subtitle {
  display: block;
  font-size: $w-flight-bus-selector__open-jaw-switch-subtitle--font-size;
  color: $w-flight-bus-selector__open-jaw-switch-subtitle--color;
  max-width: $w-flight-bus-selector__open-jaw-switch-subtitle--max-width;
  margin-top: $w-flight-bus-selector__open-jaw-switch-subtitle--margin-top;
}
