
/* -------------------------------------------------------------------------
 * FONT SIZES
 *
 * Values should be expressed in PX.
 * The values here are for sample proposes, consider add your owns
 *
 * If you need a relative unit based on that values, use the core functions
 * on context usage.
 *
 */


// Main font sizes

$f-font-size--xxs:                10px !default;
$f-font-size--xs:                 12px !default;
$f-font-size--s:                  14px !default;
$f-font-size--m:                  $s-core__font-size !default;
$f-font-size--l:                  18px !default;
$f-font-size--xl:                 24px !default;
$f-font-size--xxl:                30px !default;
$f-font-size--xxxl:               38px !default;
