.w-language-modal {
  text-align: center;
}

.w-language-modal .c-modal__footer {
  @include t-mq($from: $w-language-modal__breakpoint) {
    display: flex;
  }
}

.w-language-modal__logo.m-icon {
  @include sp-stack($w-language-modal__logo-img--margin-bottom);
}

.w-language-modal__language-select-btn {
  margin: $w-language-modal__button-spacing 0;
  display: block;
  width: 100%;
  

  @include t-mq($from: $w-language-modal__breakpoint) {
    flex: 1;
    margin: 0 $w-language-modal__button-spacing;
  }
}

.w-language-modal__language-select-btn:first-child {
  @include t-mq($from: $w-language-modal__breakpoint) {
    margin-left: 0;
  }
}

.w-language-modal__language-select-btn:last-child {
  @include t-mq($from: $w-language-modal__breakpoint) {
    margin-right: 0;
  }
}

.w-language-modal__title {
  @include sp-stack($w-language-modal__title--margin-bottom);
}
