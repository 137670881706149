$fr-quick-search__space:                                                    8px !default;

$fr-quick-search__tablet--bp:                                               $f-breakpoint--sm !default;
$fr-quick-search__desktop--bp:                                              $f-breakpoint--lg !default;

$fr-quick-search__background-color:                                         $f-color__neutral-white !default;
$fr-quick-search__border-radius:                                            $f-radius--medium !default;

$fr-quick-search__desktop--margin-top:                                      $f-space--medium !default;
$fr-quick-search__desktop--padding-from-tablet-bp:                          $fr-quick-search__space !default;
$fr-quick-search__desktop--padding-from-desktop-bp:                         $fr-quick-search__space !default;

$fr-quick-search__quick-search--margin-top--from-mq:                        $f-space--medium !default;

$fr-quick-search__content--padding-top-from-tablet-bp:                      $f-space--large !default;
$fr-quick-search__content--padding-top-from-desktop-bp:                     $f-space--huge !default;

$fr-quick-search__filters--margin-bottom:                                   $f-space--small !default;
$fr-quick-search__filters--padding:                                         $fr-quick-search__space !default;

$fr-quick-search__filter--margin-left:                                      $f-space--small !default;
