/* -------------------------------------------------------------------------
 * PROFILE DATA
 *
 */

$w-profile-data__absolute-dialog--padding:                                  $f-space--small $f-space--medium !default;
$w-profile-data__absolute-dialog--min-width:                                480px !default;
$w-profile-data__absolute-dialog--animation-delay:                          6s !default;

$w-profile-data__field--margin-bottom:                                      $f-space--medium !default;

$w-profile-data--border:                                                    1px solid $f-color__neutral-grey--light !default;
$w-profile-data--vertical-padding:                                          $f-space--medium !default;

$w-profile-data__form--vertical-margin:                                     16px !default;

$w-profile-data__field-value--color:                                        $f-color__neutral-grey--semi-dark !default;
$w-profile-data__field-value--font-size:                                    $f-font-size--s !default;
$w-profile-data__field-value--margin-top:                                   8px !default;
$w-profile-data__field-value--max-height:                                   50px !default;
$w-profile-data__field-value--animation:                                    max-height .3s !default;

$w-profile-data__loader--transform:                                         translate(-50%, calc(-50% - 35px)) !default;

$w-profile-data__collapse-btn--color:                                       $f-color__state-info !default;
$w-profile-data__collapse-btn--font-size:                                   $f-font-size--m !default;

$w-profile-data__form-input--max-width:                                     335px !default;

$w-profile-data__form-name-description--color:                              $f-color__neutral-grey--semi-dark !default;
$w-profile-data__form-name-description--font-size:                          $f-font-size--s !default;

$w-profile-data__messages-container--top:                                   -$f-space--small !default;
