
// Collapsible Modifier
// ------------------------------

.c-nav--collapsible {

  .c-nav__list {
    margin-left: $c-nav--collapsible--sp-inset-left;

    &[data-c-nav__level="1"] {
      margin-left: 0;
    }

    &[data-c-nav__level="2"], &[data-c-nav__level="3"], &[data-c-nav__level="4"] {
      margin-top: $c-nav__list--collapsible--margin-top;
    }
  }

  .c-nav__item {
    @include t-text-crop-disable();
    line-height: $c-nav--collapsible--line-height;
    @include sp-stack($c-nav--collapsible--sp-stack);

    &:last-child {
      margin-bottom: 0;
      // If we ever enable t-text-crop again,
      // we should set display:none of ::after of :last-child
      // to avoid issues with overflows of collapse.
    }

    &.has-child {
      > .c-nav__link {
        font-size: $c-nav__item--collapsible--has-child--font-size;
        color: $c-nav__item--collapsible--has-child--color;
        font-weight: $c-nav__item--collapsible--has-child--font-weight;
        pointer-events: initial;

        &:hover {
          color: $c-nav__item--collapsible--has-child--hover--color;
          font-weight: $c-nav__item--collapsible--has-child--hover--font-weight;
        }

        &[data-c-collapse__opened="true"] {
          color: $c-nav__item--collapsible--has-child--active--color;
          font-weight: $c-nav__item--collapsible--has-child--active--font-weight;
        }
      }
    }

    &.is-active {
      > .c-nav__link {
        color: $c-nav__item--active--collapsible--color;
        font-weight: $c-nav__item--active--collapsible--font-weight;
        position: relative;
        display: inline-block;

        &::before {
          content: ' ';
          position: absolute;
          top: (($c-nav--collapsible--line-height - 1) / 2) * 1em;
          bottom: (($c-nav--collapsible--line-height - 1) / 2) * 1em;
          left: -($c-nav__item--active--collapsible--sp-inline-left + $c-nav__item--active--collapsible--border-width);
          width: $c-nav__item--active--collapsible--border-width;
          background-color: $c-nav__item--active--collapsible--border-color;
        }
      }
    }
  }

  .c-nav__link {
    cursor: pointer;
    color: $c-nav__item--collapsible--color;

    &:hover {
      color: $c-nav__link--collapsible--hover--color;
    }
  }
}
