/* -------------------------------------------------------------------------
 * PROGRESS BAR
 */

@import "progress-polyfill";

.c-progress-bar {
	font-size: $c-progress-bar--font-size;
	text-align: $c-progress-bar--text-alignment;
	border-spacing: 0;

	progress,
	progress[role] {
		appearance: none;
		border: none;
		height: $c-progress-bar--height;
		vertical-align: sub;
		color: $c-progress-bar__value--bg-color;
		background: transparent;
		width: 100%;
  }

  progress::-moz-progress-bar {
    color: $c-progress-bar__value--bg-color;
    background-color: $c-progress-bar__value--bg-color;
  }

  progress::-webkit-progress-bar {
    background: $c-progress-bar--bg-color;
    border-radius: $c-progress-bar--radius;
  }

  progress::-webkit-progress-value {
    background: $c-progress-bar__value--bg-color;
    border-radius: $c-progress-bar--radius;
	}

  &.o-pack--reverse .c-progress-bar__value {
    text-align: left;
  }
}

.c-progress-bar__title {
  display: block;
  @include sp-stack($c-progress-bar--sp-stack);
}

.c-progress-bar__value {
	text-align: right;
	width: $c-progress-bar__value--width;
  padding-left: $c-progress-bar__value--padding-left;
  @include t-text-crop-disable();
}

.c-progress-bar__graphic {
  font-size: 0;
}

.c-progress-bar__value,
.c-progress-bar__graphic {
  vertical-align: middle;
}


// Variants
// --------------------

// Small

.c-progress-bar__title {
  @include sp-stack($c-progress-bar--sp-stack--small);
}
