
/* -------------------------------------------------------------------------
 * TABLES
 *
 * 1. Ensure tables fill up as much space as possible.
 * 2. If a table is too wide to fit on the screen, make side scrolling possible
 *
 */

@if ($e-tables__full-width) {
  table {
    width: 100%; /* [1] */
    overflow-x: auto; /* [2] */
  }
}
