/* -------------------------------------------------------------------------
 * Account personal details edit
 *
 */

.t-account-personal-details-edit {

  &__main-top {
    margin-top: $t-account-personal-details-edit__main-top--margin-top;
  }

}
