/* -------------------------------------------------------------------------
 * NUMBER STEPPER
 *
 */

.c-number-stepper__body {
  position: relative;
  display: inline-block;

  .c-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: $c-number-stepper--height;
    height: $c-number-stepper--height;
    color: $c-number-stepper__btn--color;
    background-color: $c-number-stepper__btn--bg-color;
    border-radius: $c-number-stepper--border-radius;
    &[data-c-number-stepper__increment] {
      left: auto;
      right: 0;
    }
    &:hover {
      background-color: rgba($c-number-stepper__btn--hover--bg-color, $c-number-stepper__btn--hover--opacity);
    }
  }

  .c-btn__icon {
    &::#{$m-icons__pseudo-element} {
      font-size: $c-number-stepper__input--font-size;
      left: calc(50% - .5em);
    }
  }
}

.c-number-stepper__input {
  width: $c-number-stepper--width;
  height: $c-number-stepper--height;
  padding: 0;
  border: none;
  color: $c-number-stepper__input--color;
  background-color: $c-number-stepper__input--bg-color;
  font-size: $c-number-stepper__input--font-size;
  text-align: center;
  vertical-align: middle;

  &:read-only {
    user-select: none;
    caret-color: transparent;
    cursor: default;
  }

  &.disabled {
    opacity: $c-number-stepper__input-disabled--opacity;
    color: $c-number-stepper__btn--color;
  }

  // Hide arrows
  -moz-appearance:textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
