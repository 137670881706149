/* -------------------------------------------------------------------------
 * PROGRESS BAR VARS
 */

$c-progress-bar--bg-color:                            $f-color__neutral-grey--light !default;
$c-progress-bar--font-size:                           $f-font-size--l !default;
$c-progress-bar--text-alignment:                      left !default;
$c-progress-bar--height:                              $f-space--small !default;
$c-progress-bar--sp-stack:                            $f-space--small !default;
$c-progress-bar--radius:                              $f-radius--small !default;

$c-progress-bar__value--bg-color:                     $f-color__state-info--semi-dark !default;
$c-progress-bar__value--width:                        10% !default;
$c-progress-bar__value--padding-left:                 $f-space--tiny !default;

$c-progress-bar__polyfill--width:                     10em !default;
$c-progress-bar__polyfill--height:                    1em !default;
$c-progress-bar__polyfill--vertical-align:            .2em !default;
$c-progress-bar__polyfill--bg-color-overwrite:        $f-color__neutral-grey--light !default;



// Variants
// --------------------

// Small

$c-progress-bar--sp-stack--small:                     $f-space--tiny !default;
