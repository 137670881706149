/* -------------------------------------------------------------------------
 * BUTTON
 */

// Default button
// -------------------------------
$c-btn--color:                                  $f-color__brand-primary !default;
$c-btn--bg-color:                               $f-color__neutral-white !default;
$c-btn--border-color:                           $f-color__brand-primary !default;
$c-btn--border-width:                           2px !default;
$c-btn--border-radius:                          $f-radius--rounded !default;
$c-btn--font-size:                              $f-font-size--m !default;
$c-btn--font-family:                            $f-font-family__secondary !default;
$c-btn--font-family--crop:                      $f-font-family__secondary--crop !default;
$c-btn--font-weight:                            700 !default;
$c-btn--line-height:                            $f-line-height--tiny !default;
$c-btn--text-transform:                         uppercase !default;
$c-btn--min-width:                              120px !default;
$c-btn--spacing-inset:                          $f-space--medium + $f-space--tiny !default;
$c-btn--spacing-inset-v:                        ($f-space--medium / 3) * 2 !default;
$c-btn--spacing-inset-h:                        $f-space--medium !default;
$c-btn--transition--property:                   color, background-color, border-color, box-shadow !default;
$c-btn--transition--in-duration:                $f-duration--fast !default;
$c-btn--transition--out-duration:               $f-duration--normal !default;

$c-btn__icon--size:                             1.25em !default;
$c-btn__icon--vertical-adjust:                  -.2em !default;
$c-btn__icon--space:                            .75em !default;

$c-btn__loader--size:                           $c-btn__icon--size !default;
$c-btn__loader--border-width:                   .2em !default;
$c-btn__loader--border-type:                    solid !default;
$c-btn__loader--border-color:                   $c-btn--color !default;
$c-btn__loader--border-opacity:                 .2 !default;
$c-btn__loader--animation-duration:             $f-duration--sluggish !default;
$c-btn__loader--animation-easing:               $f-easing--linear !default;
$c-btn__loader--animation-repeat:               infinite !default;
$c-btn__loader--space:                          2.25em !default;

// Hover
$c-btn--hover--color:                           $f-color__neutral-grey--dark !default;
$c-btn--hover--bg-color:                        $f-color__neutral-white !default;
$c-btn--hover--border-color:                    $f-color__neutral-grey--dark !default;

// Pressed
$c-btn--pressed--color:                         $f-color__neutral-black !default;
$c-btn--pressed--bg-color:                      $f-color__neutral-white !default;
$c-btn--pressed--border-color:                  $f-color__neutral-black !default;

// Focus
$c-btn--focus--shadow:                          $f-shadow--glow $f-color__neutral-grey !default;

// Disabled
$c-btn__disabled--opacity:                      .5 !default;

// Variants > Neutral
// -------------------------------
$c-btn-neutral--color:                          $f-color__neutral-grey--dark !default;
$c-btn-neutral--bg-color:                       $f-color__neutral-white !default;
$c-btn-neutral--border-color:                   $f-color__neutral-grey--semi-light !default;

// Hover
$c-btn-neutral--hover--color:                   $f-color__neutral-grey--dark !default;
$c-btn-neutral--hover--bg-color:                $c-btn--bg-color !default;
$c-btn-neutral--hover--border-color:            $f-color__neutral-grey--dark !default;

// Pressed
$c-btn-neutral--pressed--color:                 $f-color__neutral-black !default;
$c-btn-neutral--pressed--bg-color:              $f-color__neutral-white !default;
$c-btn-neutral--pressed--border-color:          $f-color__neutral-black !default;

// Focus
$c-btn-neutral--focus--shadow:                  $f-shadow--glow $f-color__neutral-grey--semi-dark !default;


// Variants > Secondary
// -------------------------------
$c-btn-secondary--color:                        $f-color__neutral-white !default;
$c-btn-secondary--bg-color:                     $f-color__neutral-black !default;
$c-btn-secondary--border-color:                 $f-color__neutral-black !default;
$c-btn-secondary--shadow:                       $f-shadow--elevated rgba($f-shadow__color, $f-shadow__opacity--elevated)!default;

// Hover
$c-btn-secondary--hover--color:                 $f-color__neutral-white !default;
$c-btn-secondary--hover--bg-color:              $f-color__brand-primary !default;
$c-btn-secondary--hover--border-color:          $f-color__brand-primary !default;

// Pressed
$c-btn-secondary--pressed--color:               $f-color__neutral-white !default;
$c-btn-secondary--pressed--bg-color:            $f-color__brand-primary--dark !default;
$c-btn-secondary--pressed--border-color:        $f-color__brand-primary--dark !default;
$c-btn-secondary--pressed--shadow:              $f-shadow--elevated $f-color__brand-primary  !default;

// Focus
$c-btn-secondary--focus--shadow:                $f-shadow--glow rgba($f-shadow__color, $f-shadow__opacity--elevated)!default;


// Variants > Flow
// -------------------------------
$c-btn-flow--color:                             map_get($cs-flow, "text") !default;
$c-btn-flow--bg-color:                          map_get($cs-flow, "background") !default;
$c-btn-flow--border-color:                      map_get($cs-flow, "background") !default;
$c-btn-flow--shadow:                            $f-shadow--elevated rgba($f-shadow__color, $f-shadow__opacity--elevated)!default;

// Hover
$c-btn-flow--hover--color:                      $f-color__neutral-white !default;
$c-btn-flow--hover--bg-color:                   $f-color__neutral-grey--dark !default;
$c-btn-flow--hover--border-color:               $f-color__neutral-grey--dark !default;

// Pressed
$c-btn-flow--pressed--color:                    $f-color__neutral-white !default;
$c-btn-flow--pressed--bg-color:                 $f-color__neutral-black !default;
$c-btn-flow--pressed--border-color:             $f-color__neutral-black !default;
$c-btn-flow--pressed--shadow:                   $f-shadow--glow $f-color__brand-primary  !default;

// Focus
$c-btn-flow--focus--shadow:                     $f-shadow--glow $f-color__neutral-grey !default;


// Variants > Flat
// -------------------------------
$c-btn-flat--color:                             $f-color__brand-primary !default;
$c-btn-flat--bg-color:                          $f-color__neutral-white--transparent !default;
$c-btn-flat--border-color:                      $f-color__neutral-white--transparent !default;
$c-btn-flat--shadow:                            none !default;

// Hover
$c-btn-flat--hover--color:                      $f-color__neutral-grey--dark !default;
$c-btn-flat--hover--bg-color:                   $f-color__neutral-white--transparent !default;
$c-btn-flat--hover--border-color:               $f-color__neutral-white--transparent !default;

// Pressed
$c-btn-flat--pressed--color:                    $f-color__neutral-grey--ultra-dark !default;
$c-btn-flat--pressed--bg-color:                 $f-color__neutral-white--transparent !default;
$c-btn-flat--pressed--border-color:             $f-color__neutral-white--transparent !default;


// Variants > Flat-neutral
// -------------------------------
$c-btn-flat-neutral--color:                     $f-color__neutral-grey !default;


//Variants > Delete
// -------------------------------
$c-btn-delete--color:                           $f-color__state-danger--dark !default;

// Variants > Icon Only
// -------------------------------
$c-btn-icon-only--size:                         $f-font-size--xxl !default;


// Variants > Trust
// -------------------------------
$c-btn-trust--font-size:                      $f-font-size--s !default;
$c-btn-trust--sp-inset-v:                     $f-space--small !default;

$c-btn-trust--color:                            $f-color__neutral-white !default;
$c-btn-trust--bg-color:                         $f-color__state-success !default;
$c-btn-trust--border-color:                     $f-color__state-success !default;
$c-btn-trust--shadow:                           $f-shadow--elevated rgba($f-shadow__color, $f-shadow__opacity--elevated)!default;

// Hover
$c-btn-trust--hover--color:                     $f-color__neutral-white !default;
$c-btn-trust--hover--bg-color:                  $f-color__state-success--semi-light !default;
$c-btn-trust--hover--border-color:              $f-color__state-success--semi-light !default;

// Pressed
$c-btn-trust--pressed--color:                   $f-color__neutral-white !default;
$c-btn-trust--pressed--bg-color:                $f-color__state-success--dark !default;
$c-btn-trust--pressed--border-color:            $f-color__state-success--dark !default;
$c-btn-trust--pressed--shadow:                  $f-shadow--elevated $f-color__state-success--dark  !default;

// Focus
$c-btn-trust--focus--shadow:                    $f-shadow--glow rgba($f-shadow__color, $f-shadow__opacity--elevated)!default;
$c-btn-trust--focus--bg-color:                  $c-btn-trust--pressed--bg-color !default;
$c-btn-trust--focus--border-color:              $c-btn-trust--pressed--border-color !default;

// Color schemes overrides
// -------------------------------
$c-btn-icon-only--color--cs-primary:            $f-color__neutral-white !default;
$c-btn-icon-only--color--cs-secondary:          $f-color__neutral-white !default;
