/* -------------------------------------------------------------------------
 * COLOR SCHEMES MIXINS
 *
 */

@mixin color-scheme($_colors) {

  @if (map_get($_colors, "background") != null) {
    background-color: map_get($_colors, "background");
  }

  @if (map_get($_colors, "text") != null) {
    color: map_get($_colors, "text");
  }

  @if (map_get($_colors, "text") != null or map_get($_colors, "link-discrete") != null) {
    .m-link--discrete {
      &,
      &:hover,
      &:focus {
        @if (map_get($_colors, "link-discrete") != null) {
          color: map_get($_colors, "link-discrete");
        }
        @else {
          color: map_get($_colors, "text");
        }
      }
    }
  }

  @if (map_get($_colors, "border") != null) {
    border-color: map_get($_colors, "border");
  }

  @if (map_get($_colors, "icon") != null) {
    [class^= "m-icon"],
    [class*= " m-icon"] {
      &::#{$m-icons__pseudo-element} {
        color: map_get($_colors, "icon");
      }
    }
  }

  @if ((map_get($_colors, "selection-background") != null) or (map_get($_colors, "selection-text") != null)) {
    &::selection,
    &::-moz-selection,
    & *::selection,
    & *::-moz-selection {
      @if (map_get($_colors, "selection-background") != null) {
        background-color: map_get($_colors, "selection-background");
      }

      @if (map_get($_colors, "selection-text") != null) {
        color: map_get($_colors, "selection-text");
      }
    }
  }

  @if ((map_get($_colors, "link") != null) or (map_get($_colors, "link-hover") != null)) {
    a {
      @if (map_get($_colors, "link") != null) {
        color: map_get($_colors, "link");
      }

      @if (map_get($_colors, "link-hover") != null) {
        &:hover,
        &:focus {
          color: map_get($_colors, "link-hover");
        }
      }
    }
  }

  @if (map_get($_colors, "line") != null) {
    hr {
      border-color: map_get($_colors, "line");
    }
  }

  @if (map_get($_colors, "heading") != null) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: map_get($_colors, "heading");
    }
  }

  @if ((map_get($_colors, "translucence") != null) and map_get($_colors, "translucence") < 1) {
    &.cs-translucent {
      background-color: rgba(map_get($_colors, "background"), map_get($_colors, "translucence"));
    }
  }
}
