/* -------------------------------------------------------------------------
* Floating Box Variables
*
*/

$c-floating-box--color:                       $e-form-elements__field--color !default;
$c-floating-box--bg-color:                    $e-form-elements__field--bg-color !default;
$c-floating-box--border-radius:               $f-radius--none !default;
$c-floating-box--border-size:                 $e-form-elements__field--border--size !default;
$c-floating-box--border-type:                 solid !default;
$c-floating-box--border-color:                $f-color__neutral-grey--semi-light !default;
$c-floating-box--bottom-distance:             $f-space--tiny !default;
$c-floating-box--z-index:                     $f-z-index--4 !default;
$c-floating-box--sp-inset:                    $f-space--medium !default;
$c-floating-box--shadow:                      $f-shadow--elevated !default;
$c-floating-box--shadow-color:                $f-shadow__color !default;
$c-floating-box--shadow-opacity:              $f-shadow__opacity !default;
$c-floating-box--min-width:                   280px !default;
$c-floating-box--translate-y:                 -$f-space--tiny !default;

$c-floating-box--breakpoints:                 'xs', 'sm', 'sml' !default;
