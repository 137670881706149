/* -------------------------------------------------------------------------
 * SITE HEADER
 *
 */
// Main top
// -------------------------------------------------

.l-site-main__top {
  position: relative;

  &.l-main-top--form-container::after {
    display: none;
  }
}
