/* -------------------------------------------------------------------------
 * INSET MIXINS
 *
 */

// Space as a mixin
// --------------------------------------------------

@mixin sp-inset($_spacing-value, $_spacing-value--h: null) {
  @if not ($_spacing-value--h) {
    $_spacing-value--h: $_spacing-value;
  }
  padding: $_spacing-value $_spacing-value--h;
}
