.fr-account-booking-payment-status {
  @include o-layout__item();
  margin-bottom: 0;
}

.fr-account-booking-payment-status__container {
  @include m-body(map_get($m-body__mod-sizes, "medium"));
  padding: 16px;
  border-style: $fr-account-booking-payment-status__container--border-style;
  border-color: $fr-account-booking-payment-status__container--border-color;
  border-width: $fr-account-booking-payment-status__container--border-width;
  background-color: $fr-account-booking-payment-status__container--bg-color;
}

.fr-account-booking-payment-status__additional-info {
  margin-top: 8px;
  text-align: center;
}

.fr-account-booking-payment-status__support-item {
  color: $fr-account-booking-payment-status__support-item--color;
  @include m-body(map_get($m-body__mod-sizes, "small"));
}

.fr-account-booking-payment-status__row-item.c-label {
  font-size: $fr-account-booking-payment-status__row-item-label--font-size;
}

.fr-account-booking-payment-status__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: $fr-account-booking-payment-status--spacing;
  gap: $fr-account-booking-payment-status--spacing;
  align-items: start;
}

.fr-account-booking-payment-status__row--separator {
  padding-top: $fr-account-booking-payment-status--spacing;
  border-top: $fr-account-booking-payment-status--separator;
}

.fr-account-booking-payment-status__row--bold {
  @include m-body(map_get($m-body__mod-sizes, "large"));
}

.fr-account-booking-payment-status__btn {
  margin-top: $fr-account-booking-payment-status--spacing;
}

.fr-account-booking-payment-status__row-item+.fr-account-booking-payment-status__row-item {
  flex-shrink: 0;
}

.fr-account-booking-payment-status__icon {
  color: $fr-account-booking-payment-status__icon--color;
}

.fr-account-booking-payment-status__success-title {
  @include m-heading(map_get($m-heading__mod-sizes, "small"));
  margin-bottom: $fr-account-booking-payment-status__success-title--margin-bottom;
}
