/* -------------------------------------------------------------------------
 * PAGE LOADER
 *
 */

.w-page-loader.is-hidden {
  display: none;
}

.w-page-loader:not(.w-page-loader--enhanced) .w-page-loader__loader,
.w-page-loader__container {
  position: fixed;
  left: $w-page-loader__loader--left;
  top: $w-page-loader__loader--top;
  z-index: $w-page-loader__loader--z-index;
  transform: $w-page-loader__loader--transform;
}

.w-page-loader__loader-background {
  position: fixed;
  left: 0px;
  top: 0px;
  width: $w-page-loader__loader-bkg--width;
  height: $w-page-loader__loader-bkg--height;
  z-index: $w-page-loader__loader-bkg--z-index;
  background-color: $w-page-loader__loader-bkg--color;
}

.w-page-loader--enhanced {
  .w-page-loader__loader-background {
    background-color: $w-page-loader__loader--enhanced-bkg--color;
  }

  .w-page-loader__container {
    text-align: center;
    width: $w-page-loader__container--until-xs-width;
    padding: $w-page-loader__container--padding;
    background-color: $w-page-loader__container--bg-color;
    @include sp-stack-children($w-page-loader__container--sp-stack);

    @include t-mq($from: $f-breakpoint--xs) {
      width: $w-page-loader__container--width;
    }

    .w-page-loader__icon {
      color: $w-page-loader__icon--color;
      margin-bottom: $w-page-loader__icon--mb;
    }

    .w-page-loader__loader {
      display: inline-block;
    }
  }
}