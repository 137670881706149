/* -------------------------------------------------------------------------
 * DATES FILTER
 *
 */

.w-dates-filter {
  position: relative;

  input[type="text"] {
    caret-color: transparent;
  }

  .flatpickr-calendar {
    margin: 0 auto;
  }

  // A small bottom padding is needed to help focused dropdown shadow to be visible
  .c-floating-box__body-content {
    padding-bottom: $f-space--tiny;
  }
}

.w-dates-filter__actions {
  display: flex;
  justify-content: space-between;
}

