
/* -------------------------------------------------------------------------
 * SECONDARY Color Scheme
 *
 */


// Color Scheme Modifiers
// -------------------------


// Ultra light modifier

@if $cs-brand-secondary--enabled and $cs-brand-secondary--ultra-light--enabled {
  .cs-brand-secondary--ultra-light {
    @include color-scheme(s-core-map-diff($cs-brand-secondary, $cs-brand-secondary--ultra-light))
  }
}

@if $cs-brand-secondary--enabled and $cs-brand-secondary--darker--enabled {
  .cs-brand-secondary--darker {
    @include color-scheme(s-core-map-diff($cs-brand-secondary, $cs-brand-secondary--darker))
  }
}

