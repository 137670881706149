/* -------------------------------------------------------------------------
* PRICE
*/

@mixin c-price-table__cells--visible($_initial-element: 1, $_final-element: 1, $_bp: "xxs", $_previous-breakpoint: "xxs", $_isCompactMode: false) {

  @if $_isCompactMode {
    @include t-mq($from: $_bp) {
      .c-price-table[data-compact-mode="true"] {
        @include c-price-table__cells--visible-core($_initial-element, $_final-element)
      }
    }
  }
  @else{
    @include t-mq($from: $_bp) {
      .c-price-table[data-compact-mode="false"] {
        @include c-price-table__cells--visible-core($_initial-element, $_final-element)
      }
    }

  }
}

@mixin c-price-table__cells--visible-core($_initial-element: 1, $_final-element: 1) {
  &::before {
    content: '#{$_final-element + 1}';
  }

  @for $_current-element from $_initial-element through $_final-element {
    .c-price-table__cell--date[data-position="#{$_current-element}"],
    .c-price-table__cell--price[data-position="#{$_current-element}"] {
      display: table-cell;
    }
  }
}