/* -------------------------------------------------------------------------
 * BOOKING CHOICE LIST
 *
 */

.w-booking-choice-list {
  .c-radio-button__highlight-text {
    background-color: $f-color__neutral-white;
    color: $f-color__state-success;
    border-color: $f-color__state-success;
    border-width: 1px;
    border-style: solid;
  }
  .w-booking-choice-list__heading-room-title {
    @include sp-stack($w-booking-choice-list__additional-text--sp-inline);
    &.is-hidden{
      display: none;
    }
  }

  .c-radio-button__additional-text {
    float: right;
    text-align: right;
    width: 100%;
    align-self: auto;
    color: $w-booking-choice-list__additional-text--font-color;
    font-size: $w-booking-choice-list__additional-text--font-size;
    display: flex;
    flex-direction: column;
  }
  .w-booking-choice-list__additional-text--age-category{
    white-space: nowrap;
  }
  .w-booking-choice-list__additional-text-legend{
    font-weight: $w-booking-choice-list__additional-text-legend--font-weight;
    font-size: $w-booking-choice-list__additional-text-legend--font-size;
  }
  .w-booking-choice-list__additional-text-price{
    font-size: $w-booking-choice-list__additional-text-legend--font-size;
  }
  .c-radio-button__additional-text, .c-checkbox__additional-text{
    @include sp-inline($w-booking-choice-list__additional-text--sp-inline);
  }

  input:checked + label .c-radio-button__additional-text {
    color: $w-booking-choice-list__additional-text--font-color--selected;
    font-weight: $w-booking-choice-list__additional-text--font-weight--selected;
  }

  input:checked + label .c-radio-button__highlight-text {
    background-color: $f-color__state-success;
    color: $f-color__neutral-white;
    border-style: none;
    border-color: $f-color__state-success;
    border-width: 1px;
    border-style: solid;
  }
}

.w-booking-choice-list__item {
  .c-radio-button__text, .c-checkbox__text {
    font-size: $w-booking-choice-list__item-text;
    text-transform: inherit;
    @include sp-inline($w-booking-choice-list__text--sp-inline);
  }
  &.is-hidden{
    display: none;
  }
  &.label-is-hidden{
    .c-choice-list__label{
      display: none;
    }
  }
}
.w-booking-choice-list__switch--applies-to-all-participants{
  margin-left: $w-booking-choice-list__applies-to-all-participants--margin-h;
  margin-right: $w-booking-choice-list__applies-to-all-participants--margin-h;
}
.w-booking-choice-list__messages-top{
  @include sp-stack($w-booking-choice-list__messages-top--margin-bottom)
}