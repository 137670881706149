/* -------------------------------------------------------------------------
 * Weather table vars
 */

$c-weather-table--bp-small:                                     "sm" !default;
$c-weather-table--bp:                                           "md" !default;
$c-weather-table--bp-large:                                     "lg" !default;

$c-weather-table--max-width--bp-small:                          375px !default;
$c-weather-table--font-size:                                    $f-font-size--m !default;
$c-weather-table--font-size--bp-medium:                         $f-font-size--s !default;

$c-weather-table--border-width:                                 3px !default;
$c-weather-table--border-style:                                 solid !default;
$c-weather-table--border-color:                                 $f-color__neutral-grey--ultra-light !default;
$c-weather-table--background-even:                              $f-color__neutral-white !default;

$c-weather-table__cell--sp-inset:                               $f-space--small !default;
$c-weather-table__cell--sp-inset--bp-medium:                    $f-space--tiny !default;
$c-weather-table__cell--line-height:                            $f-line-height--tiny !default;
$c-weather-table__cell--height:                                 $f-font-size--m + ($f-space--small * 2) !default;
$c-weather-table__cell--width--from-md:                         7.5% !default;

$c-weather-table__month-name--font-weight:                      700 !default;
$c-weather-table__month-name--short--text-transform:            uppercase !default;

$c-weather-table__legend--font-weight:                          700 !default;
$c-weather-table__legend--width--from-md:                       10% !default;
$c-weather-table__legend--min-height:                           $f-space--tiny * 10 !default;

$c-weather-table__value--font-family:                           $f-font-family__secondary !default;
