/* -------------------------------------------------------------------------
 * SITE FOOTER
 */

$l-site-footer__main--sp-inset-v:                       $f-space--large !default;
$l-site-footer__main--sp-stack:                         $f-space--medium !default;
$l-site-footer__bottom--padding-top:                    $f-space--small !default;
$l-site-footer__bottom--padding-bottom:                 $f-space--tiny !default;
$l-site-footer__bottom--font-size:                      $f-font-size--s !default;

$l-site-footer--swipe-btn__margin-bottom:               .5em !default;

$l-site-footer__main-title--sp-stack:                   $f-space--small !default;
$l-site-footer__main-title--text-transform:             uppercase !default;
$l-site-footer__c-nav--sp-stack:                        $f-space--tiny !default;

$l-site-footer__item--boxed--icon-row--bg-color:        $f-color__neutral-white !default;
$l-site-footer__item--boxed--icon-row--border-radius:   $f-radius--medium !default;
$l-site-footer__item--boxed--icon-row--sp-inset:        $f-space--medium !default;
$l-site-footer__item--boxed--icon-row--item-sp-stack:   $c-icon-row__padding !default;

$l-site-footer__main-extra--separator-border:           1px solid $f-color__brand-secondary--light !default;
$l-site-footer__main-extra--separator-translatey:       -($f-space--tiny + $f-space--small) !default;
$l-site-footer__main-extra--padding-top:                $f-space--medium !default;
$l-site-footer__main-extra--padding-bottom:             $f-space--medium !default;
