/* -------------------------------------------------------------------------
 * SITE HEADER
 *
 */

// Header top
// -------------------------------------------------

.l-site-header {
  box-shadow: unset;

  a{
    color: $l-site-header--link--color;

    &:hover,
    &:focus,
    &:active{
      color: $l-site-header--link-hover--color;
    }
  }
}

.l-site-header__top {
  @include t-mq($from: $l-site-header--bp) {
    min-height: $l-site-header__top--min-height--from-bp;
  }
}

.l-site-header__top-left {
  .fr-header-text {
    color: $fr-header__top-left-text-color;
  }

  .fr-header-contact-phone {
    color: $fr-header__top-left-text-color;
    font-family: $fr-header-contact-phone--font-family;
  }
}

// Header main
// -------------------------------------------------

.l-site-header__main {
  @include sp-inset-v($l-site-header__main--sp-inset-v);

  @include t-mq($from: $l-site-header--bp) {
    @include sp-inset-v($l-site-header__main--sp-inset-v--from-bp);
    min-height: $l-site-header__main--min-height--from-bp + ($l-site-header__main--sp-inset-v--from-bp * 2);
  }
}

.l-site-header__main .c-nav__form-submit:hover {
  color: $l-site-header__main-form-submit-btn--color;
}

.l-site-header__main-left {
  width: 0%;
  @include t-mq($from: $l-site-header--bp) {
    padding: 0;
  }
}

.l-site-header__main-right {
  @include t-mq($until: $l-site-header--bp) {
    padding-bottom: $l-site-header__main--padding-bottom--until-bp;
  }

  @include t-mq($from: $l-site-header--bp) {
    display: flex;
    align-items: center;
  }

  .l-site-header__mobile-icon {
    .c-btn__icon {
      vertical-align: $l-site-header__search-icon--vertical-align;
    }
  }
}

.l-site-header__main-menu--last-to-right {
  margin-right: $l-site-header__main-menu--margin-right;
}

.l-site-header__logo {
  @include t-mq($from: $l-site-main--bp) {
    margin-right: $l-site-header__logo--margin-right;
  }
}

.l-site-header__favourites {
  @include t-mq($from: $l-site-main--bp) {
    @include sp-inline($l-site-header__favourites);
  }
}

// Fragments
// -------------------------------------------------

.fr-header-menu-main {
  flex-grow: 1;
}

.fr-header-menu-side-drawer {
  .fr-header-menu-side-drawer__menu-icon {
      margin-left: $fr-header-menu-side-drawer__menu-icon--margin-left;
      vertical-align: $fr-header-menu-side-drawer__menu-icon--vertical-align;
  }

  .c-side-drawer__header{
    background-color: $fr-header-menu-side-drawer__header--background-color;
  }
}

.fr-header-free-text-search .w-live-search{
  @include t-mq($until: $fr-header-free-text-search--small-bp) {
    position: absolute;
    top: -100%;
    left: $fr-header-free-text-search--positioning;
    right: $fr-header-free-text-search--positioning;
  }
}

.fr-header-menu-top .c-nav--dropdown .c-nav__list:not([data-c-nav__level="1"]) {
  .c-nav__link {
    color: $fr-header-menu-top__nav-item-not-lv-1---link-color;
  }

  .c-nav__link:hover,
  .c-btn--flat:hover {
    color: $fr-header-menu-top__nav-item-not-lv-1---link-color--hover;
  }

  .c-nav__link:before {
    background-color: $fr-header-menu-top__nav-item-not-lv-1---before-bg;
  }
}
