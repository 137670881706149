
/* -------------------------------------------------------------------------
 * PROPS
 *
 * Mixin to set output properties from a map
 *
 * Params:
 * MAP ................................ Main map to generate output
 *
 * Output:
 * MAP props & values
 *
 * Example
 *
 * map: ("font-weight": 400, "text-decoration": underline)
 * .selector { @include t-props(map); }
 *
 * .selector-output {
 *  font-weight: 400;
 *  text-decoration: underline;
 * }
 *
 */


@mixin t-props($_map: (), $_px-to-rem: false) {

  @each $_property, $_value in $_map {

    @if ($_px-to-rem) and (type_of($_value) == "number") and (unit($_value) == "px") {
      $_value: s-core-px-to-rem($_value);
    }

    @if (type_of($_value) != "map") and (type_of($_value) != "function") {
      #{$_property}: $_value;
    }

  }

}
