/* -------------------------------------------------------------------------
 * BOOKING BUNDLE
 *
 */

 .w-booking-bundle{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: $w-booking-bundle--border-radius;
  border: $w-booking-bundle--border-width $w-booking-bundle--border-style $w-booking-bundle--border-color;
  box-shadow: $w-booking-bundle--shadow rgba($w-booking-bundle--shadow-color, $w-booking-bundle--shadow-opacity);
  background-color: $w-booking-bundle--bg-color;

  @include t-mq( $w-booking-bundle--bp-medium) {
    display: grid;
    grid-template-columns: minmax(auto, $w-booking-bundle__img-width-desktop) auto;
  }
}

.w-booking-bundle__img{
  height: 100%;
  width: 100%;
}

.w-booking-bundle__content{
  @include sp-inset($w-booking-bundle__content--sp-inset);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.w-booking-bundle__content-information{
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: $w-booking-bundle__content-information--sp-top;
  @include t-mq( $w-booking-bundle--bp-medium) {
    flex-direction: row;
    align-items: center;
  }
}
.w-booking-bundle__usps{
  width: 100%;
  margin-left: $w-booking-bundle__usps--margin;
  margin-bottom: $w-booking-bundle__usps--margin;
  @include t-mq( $w-booking-bundle--bp-medium) {
    margin-bottom: $w-booking-bundle__usps--margin-bottom-desktop;
  }
}
.w-booking-bundle__content-action{
  display: flex;
	flex-direction: column;
  width: 100%;

  @include t-mq( $w-booking-bundle--bp-medium) {
    width: auto;
  }

  > * {
    float: right;
  }
}
.w-booking-bundle__btn .c-btn__text{
  white-space: nowrap;
}
.w-booking-bundle__remove-btn{
  padding: 0;
  white-space: nowrap;
  @include t-mq( $w-booking-bundle--bp-medium) {
    text-align: right;
  }
}

.w-booking-bundle__title-selected{
  color: $w-booking-bundle__title-selected--color;
  display: flex;
  gap: $w-booking-bundle__title-selected--gap;
  align-items: center;
  flex-direction: row-reverse;
  @include t-mq( $w-booking-bundle--bp-medium) {
    flex-direction: row;
  }
}