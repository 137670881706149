/* -------------------------------------------------------------------------
 * NUMBER SCORE
 *
 */

  .c-number-score__label {
    color: $c-number-score__label--color;
  }

  .c-number-score__body {
    font-family: $c-number-score__body--font-family;
  }