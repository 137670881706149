/* -------------------------------------------------------------------------
 * SEARCH VARS
 *
 */

$w-search--mq:                                                          "md" !default;
$w-search--mq-small:                                                    "sml" !default;
$w-search--mq-tiny:                                                     "sm" !default;
$w-search--mq-xs:                                                       "xs" !default;
$w-search--mq-tablet:                                                   1111px !default;

$w-search__collection-teaser--sp-stack:                                 $f-space--large !default;
$w-search__collection-teaser--margin-top:                               $f-space--medium + $f-space--small !default;

// Left and its content
$w-search__box--sp-stack:                                               $f-space--small + $f-space--tiny !default;
$w-search__box--sp-inset-v:                                             $f-space--small + $f-space--tiny !default;
$w-search__box--sp-inset-h:                                             $c-box--spacing-inset !default;

$w-search__c-checkbox--margin-left:                                     -.55em !default;
$w-search__c-checkbox-text--font-size:                                  $f-font-size--s !default;

$w-search__filter-description--margin-bottom:                           $f-space--medium !default;

$w-search__box-title--font-weight:                                      700 !default;

$w-search__box-content--padding-top:                                    $f-space--medium !default;
$w-search__box-content-label--line-height:                              $f-line-height--medium !default;

$w-search__filter-collapse-trigger--top:                                -$f-space--medium !default;
$w-search__filter-collapse--mb:                                          $f-space--medium !default;

$w-search__collapse-trigger--color:                                     $cs-default--light--text !default;

$w-search__c-collapse-trigger--color--hover:                            $f-color__neutral-grey--semi-dark !default;
$w-search__c-collapse-trigger--duration:                                $f-duration--normal !default;
$w-search__c-collapse-trigger--mb:                                      $f-space--medium !default;

$w-search__c-side-drawer__close--right:                                 $f-space--small !default;

// Main Header and its content
$w-search__header--sp-stack:                                            $f-space--large !default;

$w-search__results-number__sp-stack:                                    8px !default;
$w-search__results-number__font-weight:                                 700 !default;

$w-search__available-results__font-size:                                $f-font-size--m !default;
$w-search__available-results__margin-left:                              8px !default;

$w-search__filters-action__sp-stack:                                    $f-space--medium !default;

$w-search__clear__color:                                                $e-links__color !default;
$w-search__clear__color--hover:                                         $e-links__hover-color !default;
$w-search__clear--font-size:                                            $f-font-size--s !default;
$w-search__clear--margin:                                               4px !default;

$w-search__sort--max-width--from-mq:                                    $c-search-result_-footer--max-width--from-mq !default;
$w-search__sort--dropdown-element-padding:                              13px !default;

$w-search__selected-texts--sp-stack:                                    $f-space--small !default;

$w-search__selected-chips--margin-top:                                  4px !default;
$w-search__selected-chips--margin-right:                                4px !default;

$w-search__chip--sp-stack:                                              $f-space--tiny !default;

$w-search__main-layout--padding-top:                                    16px !default;
$w-search__right-layout--padding-top:                                   $f-space--medium !default;

$w-search__selected-filters--margin-right:                              $f-space--medium !default;
$w-search__selected-filters--margin-top:                                8px !default;

$w-search__filters-action--gap:                                         8px !default;
$w-search__header-results-title--font-size:                             36px;
// Main Body and its content

$w-search__results-loader--bgcolor:                                     $e-document__body--bgcolor !default;
$w-search__results-loader--opacity:                                     $c-backdrop--opacity !default;
$w-search__results-loader--z-index:                                     $f-z-index--3 - 3 !default;
$w-search__results-loader-text--font-size:                              $f-font-size--xl !default;
$w-search__results-loader-text--line-height:                            $f-line-height--small !default;

$w-search__result--w-search__main-body--only-results--min-height:       10em !default;


$w-search__result--sp-stack:                                            $f-space--medium !default;
$w-search__result--sp-stack--from-mq-small:                             $f-space--large !default;
$w-search__result--border-width--from-mq-small:                         1px !default;
$w-search__result--border-style--from-mq-small:                         solid !default;
$w-search__result--border-color--from-mq-small:                         $f-color__neutral-grey--semi-light !default;
$w-search__result--border-bottom--from-mq-small:                        -1 * $w-search__result--sp-stack !default;

$w-search__no-favorites-title--sp-stack:                                $f-space--small !default;
$w-search__no-favorites-title--font-weight:                             700 !default;
$w-search__no-favorites-text--sp-stack:                                 $f-space--medium !default;

$w-search__results-message__title--sp-stack:                            $f-space--small !default;
$w-search__results-message__title--font-weight:                         700 !default;
$w-search__results-message__subtitle--sp-stack:                         $f-space--medium !default;

//Acco tips

$w-search__results-acco_tips-title--font-size:                          $f-font-size--s !default;
$w-search__results-acco_tips-title--line-height:                        $f-line-height--tiny !default;
$w-search__results-acco_tips-title--color:                              $f-color__neutral-grey !default;
$w-search__results-acco_tips-title--font-weight:                        $e-headings__font-weight;
$w-search__results-acco_tips-title--margin-bottom:                      $f-space--small !default;
$w-search__results-acco_tips--sp-stack:                                 $f-space * 1.5 !default;
$w-search__results-acco_tips--sp-stack--from-mq-small:                  $f-space--large * 1.5 !default;
$w-search__results-acco_tips--border-bottom--from-mq-small:             $f-space * -1.5 !default;

$w-search__results-acco_tips__c-breadcrumbs__link--color:               $f-color__neutral-grey--semi-dark !default;
$w-search__top-filters-color:                                           $f-color__neutral-grey--ultra-light !default;
$w-search__top-filters-border-color:                                    $f-color__neutral-grey--light !default;
$w-search__top-filters-text-color:                                      $f-color__neutral-grey--dark !default;
$w-search__top-filter--max-width:                                       23rem;
// usps

$w-search__map-action-btn--margin-bottom:                               $f-space--medium !default;
$w-search__map-action-btn--margin-top:                                  10px !default;

// search map

$w-search__map-action-btn--width:                                       100% !default;
$w-search__map-action-btn--margin-bottom:                               $f-space--small !default;

$w-search__top-filters-summary--padding:                                 0 12px 0;
$w-search__top-filters-summary--margin-small:                            8px;
$w-search__top-filters-summary--icon-font-size:                          16px;
$w-search__top-filters-summary--text-font-size:                          15px;

$w-search__selected-filters-container--margin-top:                      $f-space--small !default;
$w-search__selected-filters-container-clear-button--margin-top:         5px 0 !default;

//popularity tooltip
$w-search__popularity-tooltip--bottom:                                   4px;
$w-search__popularity-tooltip__icon--size:                               18px;
$w-search__popularity-tooltip__label--size:                              14px;
$w-search__popularity-tooltip__label--weight:                            400;
$w-search__popularity-tooltip__line-height:                              18px;
$w-search__popularity-tooltip__until-md--top:                            -12px;
$w-search__popularity-tooltip__before--z-index:                          $c-search-result__image-wrapper--z-index +1;
$c-radio-button__label-after--left:                                      0.196em;

$w-search__acco-tips-slider--padding-top:                                8px !default;
