/* -------------------------------------------------------------------------
 * DELETE ACCOUNT
 *
 */

.w-delete-account.is-hidden {
  display: none;
}

.w-delete-account .c-modal__body {
  @include t-text-crop-disable();
}
