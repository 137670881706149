/* -------------------------------------------------------------------------
 * UN RESOLVED VARIABLES
 *
 */

// Animation toggling

$a-unresolved--enabled:                     true !default;

// Animation variables
// -------------------------------------------------

$a-unresolved__background:                   $f-color__neutral-grey--light !default;
$a-unresolved__background--width:            100% !default;
$a-unresolved__background--height:           100% !default;
$a-unresolved__background--top:              0 !default;
$a-unresolved__background--left:             0 !default;
$a-unresolved__border--radius:               $f-radius--medium !default;

// Animation Params
$a-unresolved__position--top:                0 !default;
$a-unresolved__position--left:               0 !default;
$a-unresolved__duration:                     2s !default;
$a-unresolved__angle:                        220deg !default;
$a-unresolved__startposition:                -10px !default;
$a-unresolved__startcolor:                   $f-color__neutral-white--transparent !default;
$a-unresolved__endcolor:                     rgba($f-color__neutral-grey--ultra-light, .7) !default;
$a-unresolved__width:                        5em !default;
$a-unresolved__height:                       100% !default;
