/* -------------------------------------------------------------------------
 * SEARCH RESULT VARS
 */

// General
$c-search-result--bp-extra-small:                                                             "xs" !default;
$c-search-result--bp-small:                                                                   "sm" !default;
$c-search-result--bp:                                                                         "sml" !default;
$c-search-result--bp-big:                                                                     "lg" !default;
$c-search-result--border-width:                                                               1px !default;
$c-search-result--border-style:                                                               solid !default;
$c-search-result--border-color:                                                               $f-color__neutral-grey--semi-light !default;
$c-search-result--border-radius:                                                              $f-radius--medium !default;

// Header and its content
$c-search-result__favourites--position-bottom:                                                $f-space--small * 1.5 !default;
$c-search-result__favourites--position-right:                                                 $f-space--small !default;
$c-search-result__favourites--z-index:                                                        $f-z-index--1 !default;

$c-search-result__collection--left:                                                           $f-space--small !default;
$c-search-result__collection--top:                                                            $f-space--tiny !default;
$c-search-result__collection--z-index:                                                        $f-z-index--1 !default;
$c-search-result__collection--top--from-mq:                                                   $f-space--small !default;
$c-search-result__collection--top--from-mq:                                                   $f-space--small !default;
$c-search-result__collection--font-size--until-mq:                                            $f-font-size--s !default;

$c-search-result__image-wrapper--width--from-mq:                                              280px !default;
$c-search-result__image-wrapper--width--from-mq-big:                                          320px !default;
$c-search-result__image-wrapper--sp-stack--from-mq:                                           $f-space--tiny !default;
$c-search-result__image-wrapper--z-index:                                                     $f-z-index--2;

$c-search-result__action-wrapper--margin:                                                     $f-space--small $f-space--small 0 $f-space--small;

$c-search-result__action--border-width:                                                       1px !default;
$c-search-result__action--border-style:                                                       solid !default;
$c-search-result__action--font-size:                                                          $f-font-size--s !default;
$c-search-result__action--font-weight:                                                        700 !default;
$c-search-result__action--sp-inset:                                                           $f-space--tiny !default;
$c-search-result__action--padding-right:                                                      $f-space--medium !default;
$c-search-result__action--padding-left:                                                       $f-space--small !default;
$c-search-result__action--duration:                                                           $f-duration--normal !default;
$c-search-result__action--index:                                                              $f-z-index--1 !default;
$c-search-result__action--color:                                                              $f-color__state-info !default;
$c-search-result__action-font-family:                                                         $f-font-family__primary !default;

$c-search-result__action-icon--font-size:                                                     $f-font-size--m !default;


// Body and its content
$c-search-result__body--sp-inset:                                                             $f-space--small !default;
$c-search-result__body--sp-inset--from-mq:                                                    $f-space--medium !default;

$c-search-result__score--top:                                                                 $f-space--small !default;
$c-search-result__score--right:                                                               $f-space--small !default;
$c-search-result__score--top--from-mq:                                                        $f-space--none !default;
$c-search-result__score--right--from-mq:                                                      $f-space--small !default;

$c-search-result__rating--color:                                                              $f-color__state-warning--semi-light !default;
$c-search-result__rating--sp-stack:                                                           $f-space--tiny !default;

$c-search-result__name--sp-inline:                                                            60px !default;
$c-search-result__name--sp-inline--from-mq:                                                   $f-space--none !default;

$c-search-result__breadcrumbs--color:                                                         $f-color__neutral-grey--semi-dark !default;
$c-search-result__breadcrumbs--sp-stack:                                                      $f-space--small !default;

$c-search-result__mostbooked--font-size:                                                      $f-font-size--s !default;
$c-search-result__mostbooked--color:                                                          $f-color__state-success--semi-dark !default;
$c-search-result__mostbooked--sp-stack:                                                       $f-space--tiny * 1.5 !default;
$c-search-result__mostbooked-icon--sp-inline:                                                 $f-space--tiny !default;

$c-search-result__usps--font-size:                                                            $f-font-size--xs !default;
$c-search-result__usps--sp-stack:                                                             $f-space--tiny !default;
$c-search-result__usps--font-size--from-mq:                                                   $f-font-size--s !default;
$c-search-result__usp-item--sp-stack:                                                         $f-space--tiny !default;
$c-search-result__usp-item--line-height--from-mq:                                             $f-line-height--medium !default;

$c-search-result__urgency-messages--font-size:                                                $f-font-size--xs !default;
$c-search-result__urgency-message--sp-stack:                                                  $f-space--tiny !default;
$c-search-result__urgency-message-item--sp-stack:                                             $f-space--tiny !default;
$c-search-result__urgency-message-icon--color:                                                $f-color__brand-primary !default;
$c-search-result__urgency-messages--font-size--from-mq:                                       $f-font-size--s !default;
$c-search-result__urgency-messages--line-height--from-mq:                                     $f-line-height--medium !default;

// Footer and its content
$c-search-result__footer--sp-inset-squish:                                                    $f-space--small !default;
$c-search-result__footer--bg-color:                                                           $f-color__neutral-grey--ultra-light !default;
$c-search-result__footer--shadow-size--from-mq:                                               0 2px 8px 0 !default;
$c-search-result__footer--shadow-color--from-mq:                                              rgba($f-shadow__color, $f-shadow__opacity--elevated) !default;
$c-search-result__footer--shadow--from-mq:                                                    $c-search-result__footer--shadow-size--from-mq $c-search-result__footer--shadow-color--from-mq !default;
$c-search-result__footer--max-width--from-mq:                                                 210px !default;
$c-search-result__footer--sp-inset--from-mq:                                                  $f-space--medium !default;
$c-search-result__footer--sp-inset-v--from-mq--unavailable:                                   $f-space--small !default;

$c-search-result__footer-top--sp-stack:                                                       $f-space--medium !default;

$c-search-result__yield--height:                                                              32px !default;
$c-search-result__yield--sp-stack:                                                            $f-space--tiny !default;

$c-search-result__price--sp-stack:                                                            $f-space--small  !default;

$c-search-result__cta--font-size:                                                             $f-font-size--s !default;
$c-search-result__cta--sp-inset-v:                                                            $f-space--small !default;
$c-search-result__cta--sp-inline:                                                             -1 * $f-space--tiny !default;
$c-search-result__cta--z-index:                                                               $f-z-index--1 !default;

$c-search-result__warning-message--font-size:                                                 $f-font-size--xs !default;
$c-search-result__warning-message--color:                                                     $f-color__state-warning--dark !default;
$c-search-result__warning-message--margin-top:                                                $f-space--tiny !default;

$c-search-result__unavailable-info--sp-stack:                                                 $f-space--small !default;
$c-search-result__unavailable-info--sp-stack--from-mq:                                        $f-space--medium !default;
$c-search-result__unavailable-text--font-weight:                                              700 !default;
$c-search-result__unavailable-text--sp-stack:                                                 $f-space--small !default;


// Variants
// ------------------------------------------

// Condensed

$c-search-result__image--size--condensed:                                                     115px !default;

$c-search-result__collection--font-size--condensed:                                           $f-font-size--xs !default;

// Compact
$c-search-result__width--compact:                                                             600px !default;
$c-search-result__price-stack:                                                                $f-space--tiny !default;

// Condensed Tiny

$c-search-result__width--condensed-tiny:                                                      340px !default;
$c-search-result__image-wrapper-width--condensed-tiny:                                        120px !important;
$c-search-result__image-wrapper-sml-width--condensed-tiny:                                    100px !important;
$c-search-result__body-padding--condensed-tiny:                                               6px !important;
$c-search-result__body-padding-sml--condensed-tiny:                                           4px !default;
$c-search-result__font-size--condensed-tiny:                                                  14px !default;
$c-search-result__font-size-sml--condensed-tiny:                                              12px !default;

$c-search-result--condensed-tiny-rating--font-size--from-bp:                                  $f-font-size--xxs !default;
$c-search-result--condensed-tiny-name--font-size--from-bp:                                    $f-font-size--s !default;
$c-search-result--condensed-tiny-collection-label--font-size:                                 $f-font-size--xs !default;
$c-search-result--condensed-tiny-collection-label--padding:                                   5px !default;
$c-search-result--condensed-tiny-collection-package-block-departure--font-size:               $f-font-size--s !default;
$c-search-result--condensed-tiny-collection-package-block-included-item--font-size:           20px !default;
