/* -------------------------------------------------------------------------
 * MODAL
 *
 */

// Component selector output
// --------------------------------------------------

.c-modal__header.cs-brand-primary {
  .c-modal__header-text {
    color: $c-modal__header-text--color--cs-brand-primary;
  }
}

.c-modal__header.cs-default {
  .c-modal__close {
    color: $c-modal__close--color--cs-default;
    &:hover {
      color: $c-modal__close--hover-color--cs-default;
    }
  }
}