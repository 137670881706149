
/* -------------------------------------------------------------------------
 * Z-INDEXES
 *
 * Define general and components z-index
 *
 */


// Main z-indexes

$f-z-index--7:              2000 !default;
$f-z-index--6:              1500 !default;
$f-z-index--5:              1000 !default;
$f-z-index--4:              500 !default;
$f-z-index--3:              100 !default;
$f-z-index--2:              50 !default;
$f-z-index--1:              1 !default;
$f-z-index--0:              0 !default;
$f-z-index--negative:       -1 !default;


// z-indexes map

$f-z-indexes: (
  "negative":               $f-z-index--negative,
  "0":                      $f-z-index--0,
  "1":                      $f-z-index--1,
  "2":                      $f-z-index--2,
  "3":                      $f-z-index--3,
  "4":                      $f-z-index--4,
  "5":                      $f-z-index--5,
  "6":                      $f-z-index--6,
  "7":                      $f-z-index--7,
) !default;



// z-indexes usage map

$f-z-indexes--usage: (
  "c-backdrop":                       $f-z-index--3,
  "c-modal":                          $f-z-index--5,
  "c-modal-v2":                       $f-z-index--6,
  "c-side-drawer":                    $f-z-index--4,
  "c-img-box":                        $f-z-index--4,
  "c-nav--dropdown__list":            $f-z-index--3,
  "w-page-loader":                    $f-z-index--7,
  "w-booking-basket":                 $f-z-index--5,
) !default;


////////////////////////////////////////////////////////////////////////////////
//                               W A R N I N G                                //
//                  DO NOT MODIFY ANYTHING BEYOND THIS POINT                  //
////////////////////////////////////////////////////////////////////////////////

/* SAFE CHECKING
 * Check that the chosen z-indexes are numbers.
 */

@each $_name, $_value in $f-z-indexes {

  @if (type-of($_value) != number) {
    @error "`$f-z-indexes: #{$_name}` needs to be a number.";
  }
}
