/* -------------------------------------------------------------------------
 * TRANSFER SUMMARY
 *
 */

.w-transfer-summary,
.w-transfer-summary__items-container,
.w-transfer-summary .w-transfer-summary__ticket,
.w-transfer-summary .w-transfer-summary__item-container {
  display: flex;
}

.w-transfer-summary {
  flex-direction: column;
}

.w-transfer-summary__items-container {
  margin: -$w-transfer-summary__items-container--gap;

  @include t-mq($until: $f-breakpoint--md) {
    flex-direction: column;
  }
}

.w-transfer-summary .w-transfer-summary__item-container {
  flex: 1;
  flex-direction: column;
  margin: $w-transfer-summary__items-container--gap;
}

.w-transfer-summary .w-transfer-summary__ticket {
  flex-grow: 1;

  @include t-mq($until: $f-breakpoint--md) {
    display: block;
  }
}

.w-transfer-summary .w-transfer-ticket {
  flex-grow: 1;
}

.w-transfer-summary__heading-container {
  margin-bottom: $w-transfer-summary__heading-container--margin-bottom;
}

.w-transfer-summary__heading {
  font-family: $w-transfer-summary__heading--font-family;

  &+&::before {
    content: ':';
    display: inline-block;
    margin-right: $w-transfer-summary__heading-before--margin-right;
    font-weight: 700;
  }
}

.w-transfer-summary__heading--bold {
  font-weight: 700;
}

.w-transfer-summary__info-text {
  color: $w-transfer-summary__info-text--color;
  margin-top: $w-transfer-summary__info-text--margin-top;
  margin: $w-transfer-summary__items-container--gap;
}

[data-w-transfer-summary__error-message],
.w-transfer-summary__items-container {
  display: none;
}

.w-transfer-summary__loader {
  align-self: center;
}

.w-transfer-summary__info-text:first-child {
  opacity: 0;
  @include t-mq($until: $f-breakpoint--md) {
    display: none;
  }
}

.w-transfer-summary__items-container--info {
  margin-top: 0;
  @include t-mq($until: $f-breakpoint--md) {
    gap: 0;
  }
}

