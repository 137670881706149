/* -------------------------------------------------------------------------
 * FLIGHT/BUS SELECTOR
 *
 */

.w-flight-bus-selector__heading-text,
.w-flight-bus-selector__heading-date {
  font-family: $w-flight-bus-selector__heading--font-family;
  font-size: $w-flight-bus-selector__heading--font-size;
  @include t-text-crop-disable;
}
