/* -------------------------------------------------------------------------
 * SIDE DRAWER MIXINS
 *
 */

@mixin c-steps__item() {
  .c-steps__item {
    flex: 1 1 auto;
    list-style-type: none;
    .c-steps__link {
      display: block;
      background-color: $c-steps__link--bg-color;
      color: $c-steps__link--color;
      font-weight: $c-steps__link--font-weight;
      font-size:$c-steps__link--font-size;
      text-align: $c-steps__link--text-align;
      padding:$c-steps__link--spacing;
      border-radius: $c-steps__link--radius;
      text-decoration: none;
  
      @include sp-inline($c-steps__link--spacing);
  
      @include t-mq($from: $c-steps__link--from-bp) {
        font-size:$c-steps__link--font-size--from-bp;
      }
      &:hover {
        background-color: $c-steps__link--bg-hover;
        text-decoration: none;
      }
    }
  
    &:last-of-type .c-steps__link {
      @include sp-inline($f-space--none)
    }
  
    &.is-active .c-steps__link {
       background-color: $c-steps__item---bg-color--active;
       box-shadow: $c-steps__item--box-shadow--active;
       color: $c-steps__item---color--active;
       position: relative;
       pointer-events: none;
      @include t-mq($from: $c-steps__link--from-bp) {
        padding: $c-steps__item---spacing--active;
      }
      &:after {
        position: absolute;
        display: block;
        content: "";
        left: 50%;
        bottom: $c-steps__item---bottom--active;
        width: $c-steps__item---size--active;
        height: $c-steps__item---size--active;
        transform-origin: $c-steps__item---transform-origin--active;
        transform: $c-steps__item---transform--active;
        background-color: $c-steps__item---bg-color--active;
        border-bottom-right-radius: $c-steps__link--radius;
        z-index: $c-steps__item---z-index--active;
      }
    }
  
    &.is-disabled .c-steps__link {
      color: $c-steps__item---bg--locked;
      opacity: $c-steps__item---opacity--locked;
      pointer-events: none;
    }
  }
}
