/* -------------------------------------------------------------------------
 * CHIP VARIABLES
 */

$c-chip--font-family:                                 inherit !default;
$c-chip--font-family--crop:                           null !default;
$c-chip--bg-color:                                    $f-color__state-info--ultra-light !default;
$c-chip--hover--bg-color:                             $f-color__state-info--light !default;
$c-chip--radius:                                      $f-radius--medium !default;

$c-chip__text--color--highlighted:                    $f-color__state-info--dark !default;

$c-chip__icon-color:                                  $f-color__state-info--dark !default;
$c-chip__text--color--dark:                           $f-color__neutral-white !default;
