/* -------------------------------------------------------------------------
* Checkbox Variables
*
*/

$c-checkbox__element--space-inline:                     $f-space--tiny !default;
$c-checkbox__element--border-radius:                    $f-radius--small !default;
$c-checkbox__element--container--border-radius:         $f-radius--ellipsis !default;
$c-checkbox__element--bg-color:                         $e-form-elements__field--bg-color !default;
$c-checkbox__element--border-color:                     $e-form-elements__field--border-color !default;
$c-checkbox__element--border-style:                     $e-form-elements__field--border--type !default;
$c-checkbox__element--border-width:                     $e-form-elements__field--border--size !default;
$c-checkbox__element--extra-border-width:               3px !default;
$c-checkbox__element--extra-border--opacity:            $e-form-elements--focus--opacity !default;
$c-checkbox__element--size:                             $f-space--tiny + $f-space--small !default;
$c-checkbox__element--container--size:                  $c-checkbox__element--size * 2 !default;
$c-checkbox__element--box-sizing:                       $g-box-sizing !default;

$c-checkbox__label--font:                               map_get($m-body__mod-sizes, "medium") !default;
$c-checkbox__label--vertical-align:                     -.25em !default;

$c-checkbox__checkmark--width:                          6px !default;
$c-checkbox__checkmark--height:                         12px !default;
$c-checkbox__checkmark--left:                           15px !default;
$c-checkbox__checkmark--thickness:                      2px !default;
$c-checkbox__checkmark--color:                          $e-form-elements--selected--bg-color !default;
$c-checkbox__checkmark--color--disabled:                $f-color__neutral-grey--semi-light !default;

$c-checkbox__half-checkmark--top:                       7px !default;

$c-checkbox__count--color:                              $cs-default--text--discrete !default;
$c-checkbox__count--font-size:                          map-deep-get($m-body__mod-sizes, "tiny", "font-size") !default;

$c-checkbox__text-wrapper--sp-inset-v:                  $f-space--tiny !default;

//Additional Text
$c-checkbox__additional-text--color:                    $cs-default--text--discrete !default;
$c-checkbox__additional-text--font-size:                map-deep-get($m-body__mod-sizes, "tiny", "font-size") !default;

// Transitions
$c-checkbox--transition-property:                       background-color, border-color, transform, opacity !default;
$c-checkbox--transition-duration:                       $f-duration--fast !default;
$c-checkbox--transition-easing:                         $f-easing--base !default;

// Sizes
$c-checkbox--small__label--font:                        map_get($m-body__mod-sizes, "small") !default;

// States
// -----------------------------------------

// Disabled
$c-checkbox__disabled--opacity:                         $e-form-elements--disabled--opacity !default;

// Hocus / Active
$c-checkbox__element--hover--bg-color:                  $e-form-elements--hover--bg-color !default;
$c-checkbox__element--hover--border-color:              $e-form-elements--hover--border-color !default;
$c-checkbox__element--hover--extra-border-color:        $e-form-elements--hover--extra-border--color !default;

// Pressed
$c-checkbox__element--pressed--bg-color:                $e-form-elements--pressed--bg-color !default;
$c-checkbox__element--pressed--border-color:            $e-form-elements--pressed--border-color !default;
$c-checkbox__element--pressed--extra-border-color:      $e-form-elements--pressed--extra-border--color !default;

// Selected / Checked
$c-checkbox__element--selected--bg-color:               $e-form-elements__inner-element--color !default;
$c-checkbox__element--selected--border--color:          $e-form-elements__inner-element--color !default;

// Validation [Success, Warning, Error]
$c-checkbox__element--success--border-color:            $e-form-elements--success--border-color !default;
$c-checkbox__element--warning--border-color:            $e-form-elements--warning--border-color !default;
$c-checkbox__element--error--border-color:              $e-form-elements--error--border-color !default;

// Unresolved
$c-checkbox__unresolved--text-background:               $f-color__neutral-grey--semi-light !default;
$c-checkbox__unresolved--text-background--top:          9px !default;
$c-checkbox__unresolved--text-background--height:       calc(100% - #{$c-checkbox__unresolved--text-background--top}) !default;
$c-checkbox__unresolved--animation-bullet--width:       20px !default;

// Image
$c-checkbox__image-max-width:                           $f-space--medium + $f-space--tiny !default;
$c-checkbox__image-margin-right:                        $f-space--tiny !default;
$c-checkbox__image-margin-left:                         $f-space--tiny !default;
$c-checkbox__image-margin-bottom:                       $f-space--none !default;

// Illustration
$c-checkbox__illustration-image:                        $f-space--medium !default;
