/* -------------------------------------------------------------------------
 * LAYOUT VARIABLES
 *
 */


// Object toggling

$o-layout--enabled:                           true !default;

// Object Params

$o-layout__space:                             $f-space !default;

// Modifiers spaces

$o-layout__mod-spaces--enabled:               true !default;
$o-layout__mod-spaces:                        map_remove($f-spaces, "huge", "giant") !default;

// Modifiers Alignments

$o-layout__mod-alignments-v--enabled:         true !default;
$o-layout__mod-alignments-v:                  (top, middle, bottom) !default;

$o-layout__mod-alignments-h--enabled:         true !default;
$o-layout__mod-alignments-h:                  (left, center, right) !default;

// Modifiers Reverse

$o-layout__mod-reverse--enabled:              true !default;

// Modifiers Stretch

$o-layout__mod-stretch--enabled:              true !default;

// Smart layout sizes
$o-layout__smart--enabled:                    true !default;
$o-layout__smart-columns: (
  "small": (
    "xxs":      1,
    "sm":       2,
    "md":       4,
  ),
  "medium": (
    "xxs":      1,
    "sm":       2,
    "md":       3,
  ),
  "large": (
    "xxs":      1,
    "sm":       2,
  ),
) !default;


// Item Modifiers Reverse

$o-layout__item-mod-reverse--enabled:         true !default;
