@mixin package-item--listed() {
  display: flex;
  width: 100%;
  background-color: $c-package-item__body--listed--bg-color;
  box-shadow: none;
  
  .c-package-item__img {
    width: $c-package-item__img--listed--size;
    min-width: $c-package-item__img--listed--size;
    position: relative;
  }
  
  .c-package-item__body {
    height: $c-package-item__img--listed--size;
    overflow: hidden;
    @include sp-inset($c-package-item__body--listed--padding);

    .c-package-item__body-heading {
      min-height: $c-package-item__body-heading--listed--height;
      margin-bottom:0;
      @include t-mq($from: $c-package-item--mq) {
        margin-bottom: $f-space--small;
      }
    }

    .c-package-item__body-footer {
      min-height: $c-package-item__body-footer--listed--height;
      padding-bottom: $f-space--small;

      @include t-mq($from: $c-package-item--mq) {
        padding-bottom: 0;
      }

      .c-price__info {
        line-height: 2;
      }

      .c-price__value, .c-price__currency, .c-package-block__included-item {
        font-size: $c-package-item__price-size;
      }
    }
  }
  
  .c-label {
    left: 0;
    bottom: 0;
    top: auto;
    width: 100%;
    border-radius: 0;
  }
  
  .c-package-item__title {
    white-space: unset;
    text-overflow: unset;

    @include t-mq($from: $c-package-item--mq) {
      max-width: $c-package-item__title--max-width;
      width: $c-package-item__title--max-width;
    }

    max-width: $c-package-item__body-title--listed--max-width;
    width: $c-package-item__body-title--listed--max-width;
  }

  .c-breadcrumbs__item {
    min-width: 0;
  }

  .c-breadcrumbs {
    max-width: 100%;
  }

  .c-number-score {
    top: $c-package-item__score--listed--top;
    right: $c-package-item__score--listed--right;
  }
  
  .c-number-score__label {
    display: inline-block;
  }
  
  &:hover {
    box-shadow: $c-package-item--shadow $c-package-item--shadow-color--hover;
  }
}

@mixin package-item--listed-condensed() {
  .c-package-item__body-heading {
    @include sp-stack($c-package-item__body-heading--sp-stack--until-bp)
  }
  
  .c-rating {
    font-size: $c-package-item__rating--listed--until-mq--font-size;
  }
  
  .c-label {
    font-size: $c-package-item__label--listed--until-mq--font-size;
  }
  
  .c-package-item__title {
    font-size: $c-package-item__body-title--listed--until-mq--font-size;
  }
  
  .c-breadcrumbs__item,
  .c-breadcrumbs__link {
    font-size: $c-package-item__breadcrumbs--listed--until-mq--font-size;
  }
  
  .c-breadcrumbs__item:first-child {
    display: none;
  }
  
  .c-breadcrumbs__item:nth-child(2) {
    padding-left: 0;
    
    &::before {
      display: none;
    }
  }
  
  .c-price__info {
    margin-bottom: 0;
  }
  
  .c-price__info-icon .c-btn__icon::before {
    font-size: $c-package-item__price-icon--listed--from-mq--font-size--until-mq;
  }
  
  .c-price__legend {
    display: none;
  }
  
  .c-price__number .m-price__currency,
  .c-price__number .m-price__value {
    margin-left: 0;
    font-size: $c-package-item__price--listed--from-mq--font-size--until-mq;
  }
  
  .c-number-score {
    display: none;
  }
  
  .c-package-block__included {
    .c-package-block__included-item {
      font-size: $c-package-item__package-block--listed--until-mq--font-size;
      
      &:not(:first-child)::before {
        @include sp-inset-h($c-package-item__package-block--listed--until-mq--separator--space);
      }
    }
  }
}