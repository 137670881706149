/* -------------------------------------------------------------------------
 * Fragment Account Booking Cancelled Content VARS
 *
 */

// Cancelled content
$fr-account-booking-cancelled-content__content--max-width:                   600px;

$fr-account-booking-cancelled-content__legal-message--sp-inset-v:            $f-space--small + $f-space--tiny !default;
$fr-account-booking-cancelled-content__legal-message--font-size:             $f-font-size--xs !default;
$fr-account-booking-cancelled-content__legal-message--color:                 $f-color__neutral-grey--semi-dark !default;
$fr-account-booking-cancelled-content__legal-message--sp-stack:              $f-space--large !default;      
