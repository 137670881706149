
/* -------------------------------------------------------------------------
 * PRIMARY Color Scheme
 *
 */

// Color Scheme output

@if $cs-brand-primary--enabled {
  .cs-brand-primary {
    @include color-scheme($cs-brand-primary)
  }
}


// Color Scheme Modifiers
// -------------------------

// White modifier

@if $cs-brand-primary--enabled and $cs-brand-primary--white--enabled {
  .cs-brand-primary--white {
    @include color-scheme(s-core-map-diff($cs-brand-primary, $cs-brand-primary--white))
  }
}

// Light modifier

@if $cs-brand-primary--enabled and $cs-brand-primary--light--enabled {
  .cs-brand-primary--light {
    @include color-scheme(s-core-map-diff($cs-brand-primary, $cs-brand-primary--light))
  }
}

// Dark modifier

@if $cs-brand-primary--enabled and $cs-brand-primary--dark--enabled {
  .cs-brand-primary--dark {
    @include color-scheme(s-core-map-diff($cs-brand-primary, $cs-brand-primary--dark))
  }
}
