
/* -------------------------------------------------------------------------
 * RADIUS
 *
 * Border radius sizes
 * The values here are for sample proposes, consider add your owns
 *
 */


// Main radius

$f-radius:                    5px !default;

// Radius variants

$f-radius--none:              0 !default;
$f-radius--small:             2px !default;
$f-radius--medium:            $f-radius !default;
$f-radius--large:             15px !default;
