
/* -------------------------------------------------------------------------
 * ICONS
 *
 * Material icons
 *
 */


// Font importer
// --------------------------------------------------

@at-root {
  @font-face {
    font-family: $m-icons__font-family;
    font-style: normal;
    font-weight: 400;
    src: url("#{$s-config__path--fonts}/m-icons/m-icons.woff2") format("woff2"),
    url("#{$s-config__path--fonts}/m-icons/m-icons.woff") format("woff");
  }
}

@if variable-exists(web-fonts) {
  $web-fonts: map-merge($web-fonts, ("icons": "#{$s-config__path--fonts}/m-icons/m-icons.woff2"));
}

// Material output
// --------------------------------------------------

[class^= "m-icon"],
[class*= " m-icon"] {
  &::#{$m-icons__pseudo-element} {
    @include m-icon__styles;
  }
}


// Glyph modifiers
// --------------------------------------------------

@function unicode($value) {
  @return unquote('"#{$value}"');
}

@each $_icon-name, $_icon-glyph in $m-icon__glyphs {
  .m-icon--#{$_icon-name} {
    &::#{$m-icons__pseudo-element} { content: unicode($_icon-glyph); }
  }
}


// Size modifiers
// --------------------------------------------------

@each $_size-name, $_size-value in $m-icons__sizes {
  .m-icon--size-#{$_size-name} {
    &::#{$m-icons__pseudo-element} { font-size: $_size-value; }
  }
}


// is-unresolved state
// --------------------------------------------------

.m-icon.is-unresolved,
.is-unresolved .m-icon {
  position: relative;
  display: block;
  
  &::#{$m-icons__pseudo-element} {
    background-color: $m-icon--unresolved--text-background;
    color: $m-icon--unresolved--text-background;
    border-color: $m-icon--unresolved--text-background;
    content: inherit;
    top: $m-icon--unresolved--text-background--top;
    left: $m-icon--unresolved--text-background--left;
    height: $m-icon--unresolved--text-background--height;
    width: $m-icon--unresolved--text-background--width;
    display: inline-block;
    border-radius: $m-icon--unresolved--border-radius;
  }

  &::#{$m-icon--unresolved--pseudo-element} {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: $m-icon--unresolved--animation-bullet--width;
    display: block;
    animation: is-unresolved-animation $m-icon--unresolved--animation-duration infinite;
    background: linear-gradient(
        $m-icon--unresolved--animation-angle,
        $f-color__neutral-white--transparent,
        $m-icon--unresolved--animation-endcolor,
        $f-color__neutral-white--transparent
    );
  }
}

@keyframes is-unresolved-animation {
 from {
   left: $m-icon--unresolved--animation-startposition;
   opacity: 0;
 }
 10% {opacity: 1;}
 40% {opacity: 0;}
 50%, to {
   left: 100%;
   opacity: 0;
 }
}
