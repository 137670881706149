/* -------------------------------------------------------------------------
 * BOOKING STEPS NAVIGATION
 *
 */

.w-booked-service-navigation {
  display: flex;
  justify-content: center;
  &.is-unresolved {
    @include a-unresolved();
    &::before, &::after{
      z-index: 1;
    }
  }
}

.w-booked-service-navigation.is-loading {
  opacity: $w-booked-service-navigation--opacity--is-loading;
  pointer-events: none;
}

.w-booked-service-navigation.has-error {
  display: none;
}