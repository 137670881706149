.fr-acco-address {
  text-align: center;

  .fr-acco-address__content {
    display: flex;
    justify-content: center;
    text-align: left;
  }

  .fr-acco-address__icon {
    @include sp-inline($fr-acco-address__icon--sp-inline);
  }
}

