
/* -------------------------------------------------------------------------
 * BOX
 *
 * The box object simply boxes off content.
 * Extend with cosmetic styles in the components layer.
 *
 */


// Object selector output
// --------------------------------------------------

@if ($o-box--enabled) {
  .o-box {
    @include o-box();
  }
}
