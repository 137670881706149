/* -------------------------------------------------------------------------
 * UNTRUSTED HTML
 *
 * Scope to have a consistent styles in rich text fields 
 *
 */

.s-untrusted-html {
  @include m-body($s-untrusted-html--body-font);
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include m-heading($s-untrusted-html--heading-font);
    @include sp-stack($s-untrusted-html--heading--sp-stack);
  }

  p:empty {
    display: none !important;
  }

  table {
    display: inline-block;

    thead, 
    th {
      font-weight: $s-untrusted-html--table--head-font-weight;
    }

    td,
    th {
      @include sp-inset($s-untrusted-html--table--sp-inset);
    }

    tr {
      &:nth-child(odd) {
        background-color: $s-untrusted-html--table--bg-color;
      }
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  img {
    width: auto;
  }
}

