/* -------------------------------------------------------------------------
 * BOOKING BOX VARS
 *
 */

$w-booking-box--bp:                                                    $f-breakpoint--sm !default;
$w-booking-box--padding:                                               $f-space--small + $f-space--tiny !default;
$w-booking-box--padding-from-bp:                                       $f-space--medium !default;
$w-booking-box__heading-icon--sp-inline:                               $f-space--small !default;
$w-booking-box__description--font-size:                                $f-font-size--m !default;

$w-booking-box__heading-icon--font-size:                               map_get($m-icons__sizes, "medium") !default;

$w-booking-box__heading-info--sp-inline:                               $f-space--tiny !default;
$w-booking-box__heading-info--color:                                   $f-color__state-info !default;
$w-booking-box__heading-info--color--hover:                            $f-color__state-info--dark !default;
$w-booking-box__heading-info--font-size:                               $f-font-size--l !default;
$w-booking-box__heading-info--size:                                    16px !default;

// States
// --------------------------------------

// is-loading
$w-booking-box--opacity--is-loading:                                   .4 !default;

// is-unresolved
$w-booking-box--unresolved-background:                                 $f-color__neutral-grey--light !default;
$w-booking-box--unresolved-background--width:                          100% !default;
$w-booking-box--unresolved-background--height:                         100% !default;
$w-booking-box--unresolved-background--top:                            0 !default;
$w-booking-box--unresolved-background--left:                           0 !default;
$w-booking-box__unresolved--width:                                     5em !default;
$w-booking-box__unresolved--border-radius:                             $f-radius--medium !default;

$w-booking-box__unresolved--animation-duration:                        2s !default;
$w-booking-box__unresolved--animation-angle:                           220deg !default;
$w-booking-box__unresolved--animation-startposition:                   -10px !default;
$w-booking-box__unresolved--animation-startcolor:                      $f-color__neutral-white--transparent !default;
$w-booking-box__unresolved--animation-endcolor:                        rgba($f-color__neutral-grey--ultra-light, .7) !default;

$w-booking-box__heading-label--margin-bottom:                          $f-space__tiny !default;
$w-booking-box__heading-label--color:                                  $f-color__neutral-grey--semi-dark !default;
$w-booking-box__heading-icon--collapsed-color:                         $f-color__neutral-grey--semi-dark !default;

$w-booking-box--selected-color:                                        $f-color__state-success !default;
$w-booking-box__title--font-weight:                                    400 !default;
$w-booking-box__title-selected--font-weight:                           700 !default;