/* -------------------------------------------------------------------------
 * ROOM OCCUPANCY VARS
 *
 */

$w-room-occupancy--breakpoint:                                                                    $f-breakpoint--sm !default;
$w-room-occupancy--main-spacing:                                                                  $f-space--small !default;
$w-room-occupancy--wrapper-spacing:                                                               $f-space--medium !default;
$w-room-occupancy--stack-children:                                                                $f-space--medium !default;
$w-room-occupancy--icon-font-size:                                                                $f-font-size--m !default;
$w-room-occupancy--icon-desktop-font-size:                                                        $f-font-size--xl !default;
$w-room-occupancy--icon-color:                                                                    $f-color__neutral-black !default;
$w-room-occupancy--icon-hover-color:                                                              $f-color__neutral-grey--dark !default;

$w-room-occupancy--bg-color:                                                                      $f-color__state-info--ultra-light !default;
$w-room-occupancy-header--font-size:                                                              $f-font-size--s !default;

$w-room-occupancy__room-title--color:                                                             $f-color__state-info--semi-dark !default;
$w-room-occupancy__room-title--font-weight:                                                       700 !default;
$w-room-occupancy__room-title--font-size:                                                         $f-font-size--l !default;
$w-room-occupancy__room-title-row--padding-left:                                                  $f-space--small !default;
$w-room-occupancy__room-title-row--margin-bottom:                                                 $f-space--small !default;
$w-room-occupancy__room-title-icon--font-size:                                                    $f-font-size--xl !default;
$w-room-occupancy__room-title-icon--margin-right:                                                 6px !default;
$w-room-occupancy__room-title--text-transform:                                                    uppercase !default;

$w-room-occupancy__description--font-size:                                                        $f-font-size--xs !default;

$w-room-occupancy__info-message--color:                                                           $f-color__neutral-grey--semi-dark !default;
$w-room-occupancy__info-message--font-size:                                                       $f-font-size--s !default;

$w-room-occupancy__details--mobile-stack:                                                         $f-space--tiny !default;

$w-room-occupancy__onrequest--font-family:                                                        $f-font-family__secondary !default;
$w-room-occupancy__onrequest--font-size:                                                          $f-font-size--xxs !default;
$w-room-occupancy__onrequest--font-weight:                                                        400 !default;
$w-room-occupancy__onrequest-color:                                                               $f-color__neutral-grey--semi-dark !default;
$w-room-occupancy__onrequest--margin-bottom:                                                      $f-space--tiny !default;
$w-room-occupancy__onrequest--border-color:                                                       $f-color__neutral-grey--semi-dark !default;
$w-room-occupancy__onrequest--border-type:                                                        solid !default;
$w-room-occupancy__onrequest--border-size:                                                        1px !default;
$w-room-occupancy__onrequest--bg-color:                                                           $f-color__neutral-white !default;

$w-room-occupancy__availability--border-color:                                                    $f-color__state-warning--dark !default;
$w-room-occupancy__availability--border-type:                                                     solid !default;
$w-room-occupancy__availability--border-size:                                                     1px !default;
$w-room-occupancy__availability--color:                                                           $f-color__state-warning--dark !default;
$w-room-occupancy__availability--bg-color:                                                        $f-color__neutral-white !default;
$w-room-occupancy__availability--margin-bottom:                                                   $f-space--tiny !default;

$w-room-occupancy__price--color:                                                                  $f-color__state-info--semi-dark !default;
$w-room-occupancy__price--font-size:                                                              $f-font-size--l !default;
$w-room-occupancy__price--font-weight:                                                            700 !default;
$w-room-occupancy__price_info--padding-bottom:                                                    $f-space--tiny !default;
$w-room-occupancy__price_info--font-size:                                                         $f-font-size--xs !default;
$w-room-occupancy__price_info--font-weight:                                                       700 !default;
$w-room-occupancy__price--max-width:                                                              40% !default;
$w-room-occupancy__price-number--line-height:                                                     0.75 !default;

$w-room-occupancy__remove-button--right:                                                          -20px !default;
$w-room-occupancy__remove-button--desktop--right:                                                 -30px !default;

$w-room-occupancy_status-invalid--border-color:                                                   $f-color__neutral-grey--semi-light !default;
$w-room-occupancy--border-color:                                                                  $f-color__state-info--semi-light !default;
$w-room-occupancy--border-radius:                                                                 $f-radius--medium !default;
$w-room-occupancy--border-size:                                                                   1px !default;
$w-room-occupancy--border-type:                                                                   solid !default;

$w-room-occupancy__occupancy-selector--breakpoint:                                                $f-breakpoint--md !default;
$w-room-occupancy__occupancy-selector--margin-top:                                                $f-space--small !default;
$w-room-occupancy__occupancy-selector__number-stepper--max-width:                                 240px !default;
$w-room-occupancy__occupancy-selector__number-stepper--margin-right:                              $f-space--small !default;
$w-room-occupancy__occupancy-selector__number-stepper--padding:                                   $f-space--small !default;
$w-room-occupancy__occupancy-selector__number-stepper--margin-right:                              $f-space--medium !default;
$w-room-occupancy__occupancy-selector__number-stepper-label--padding-top:                         4px !default;
$w-room-occupancy__occupancy-selector__number-stepper-label--margin-right:                        $f-space--small !default;
$w-room-occupancy__occupancy-selector__number-stepper-label--font-size:                           $f-font-size--s !default;
$w-room-occupancy__occupancy-selector__number-stepper-label-description--color:                   $f-color__neutral-grey !default;
$w-room-occupancy__occupancy-selector__number-stepper-label-description--font-size:               $f-font-size--xs !default;
$w-room-occupancy__occupancy-selector__number-stepper-label-description--top-separation:          2px !default;
$w-room-occupancy__occupancy-selector__number-stepper--border-color:                              $f-color__neutral-grey--semi-light;
$w-room-occupancy__occupancy-selector__number-stepper--border-size:                               1px !default;
$w-room-occupancy__occupancy-selector__number-stepper--border-type:                               solid !default;

$w-room-occupancy__error-message--font-color:                                                     $f-color__state-danger !default;
$w-room-occupancy__error-message--font-size:                                                      $f-font-size--s !default;
$w-room-occupancy__error-message--font-weight:                                                    600 !default;
$w-room-occupancy__error-message--margin:                                                         $f-space--small !default;

$w-room-occupancy__characteristics-trigger--margin-top:                                           $f-space--small !default;
$w-room-occupancy__characteristics-trigger-btn-text--margin-right:                                5px !default;
$w-room-occupancy__characteristics-content--margin-top:                                           14px !default;
$w-room-occupancy__characteristics-content--margin-bottom:                                        $f-space--tiny !default;
