/* -------------------------------------------------------------------------
 * CROP
 *
 * A cropping wrapper with preserved ratio
 *
 * Params:
 * ALIGNMENT .......................... Horizontal text alignment
 *
 */

// Object selector output
// --------------------------------------------------

@if ($o-crop--enabled) {
  .o-crop {
    @include o-crop();
  }

  .o-crop__content {
    @include o-crop__content();
  }
}


// Alignment modifiers
// --------------------------------------------------

@if ($o-crop--enabled and $o-crop__mod-alignments--enabled) {
  .o-crop__content--left {
    top: 0;
    left: 0;
  }

  .o-crop__content--right {
    right: 0;
    left: auto;
  }

  .o-crop__content--bottom {
    top: auto;
    bottom: 0;
  }

  .o-crop__content--center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


// Crop ratios
// -------------------------------------------

@if ($o-crop--enabled and $o-crop__ratios--enabled) {
  @each $_name, $_ratio in $o-crop__ratios {
    .o-crop--#{$_name} {
      padding-bottom: ($_ratio) * 100%;
    }
  }
}
