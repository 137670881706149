.t-glossary .fr-tabs-container .c-tabs__nav-button {
  min-width: 0;
  padding: $fr-glossary__tab-button--padding;
}

.t-glossary .fr-tabs-container .c-tabs__content {
  padding: $fr-glossary__tab-content--padding-v $fr-glossary__tab-content--padding-h;

  @include t-mq($until: $fr-glossary__tab-spacing--bp) {
    padding: $fr-glossary__tab-content--padding-v-mobile $fr-glossary__tab-content--padding-h-mobile;
  }
}

.fr-glossary__tab-nav {
  .c-nav__item {
    display: inline-block;
    width: 100%;
  }
}

.fr-glossary__tab-content {
  .c-nav__item:before {
    margin-bottom: 0;
  }

  @include t-mq($until: $fr-glossary__tab-spacing--bp) {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
