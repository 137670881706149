/* -------------------------------------------------------------------------
 * PROMOTED PRICE
 *
 */

.w-promoted-price {
  display: none;
  @include a-reveal-lift();
  transform: translateY(-30%);
  z-index: $w-promoted-price--z-index;

  &.in {
    display: block;
    @include a-reveal-lift--in();
    transform: translateY(0%);
  }

  &.is-opening,
  &.is-closing {
    display: block;
  }
}

.w-promoted-price__main {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.w-promoted-price__fallback {
    max-width: 416px;
  }
}

.w-promoted-price__price {
  display: block;
}

.w-promoted-price__btn {
  display: none;
}

.w-promoted-price__yield {
  height: $w-promoted-price__yield--height;
  width: auto;
  @include sp-stack($w-promoted-price__yield--sp-stack-from-bp);
}

.w-promoted-price__sticky {
  position: sticky;
  bottom: 0;
  width: 100%;
  gap: $w-promoted-price__sticky--gap;

  background-color: $w-promoted-price__sticky--background-color;
  box-shadow: map_get($w-promoted-price__sticky--box-shadow, 'shadow')
    rgba(
      map_get($w-promoted-price__sticky--box-shadow, 'color'),
      map_get($w-promoted-price__sticky--box-shadow, 'opacity')
    );
  z-index: $w-promoted-price__sticky--z-index;
  padding: $w-promoted-price__sticky--padding;

  align-items: center;
  display: flex;
  justify-content: space-between;

  @include t-mq($from: $w-promoted-price__sticky--breakpoint) {
    display: none;
  }

  &.is-hidden {
    display: none;
  }

  .c-price {
    .c-price__value {
      font-size: $w-promoted-price__sticky-price-value--font-size;
    }

    .c-price__currency {
      font-size: $w-promoted-price__sticky-price-currency--font-size;
    }

    .c-price__label {
      font-size: $w-promoted-price__sticky-price-label--font-size;
    }
  }

  .w-promoted-price__btn {
    display: block;
    max-width: 50%;
  }

  .c-price,
  .c-price__extra-info-label {
    text-align: left;
  }
}

.w-promoted-price__fallback-title {
  font-size: $w-promoted-price__fallback-title--font-size;
  font-weight: $w-promoted-price__fallback-title--font-weight;
}

// Variants
// ------------------------------

// Floating

@mixin w-promoted-price--floating() {
  .w-promoted-price__main {
    display: inline-block;
    background-color: $w-promoted-price--floating--bg-color;
    box-shadow: map_get($w-promoted-price--floating--shadow, 'shadow')
      rgba(
        map_get($w-promoted-price--floating--shadow, 'color'),
        map_get($w-promoted-price--floating--shadow, 'opacity')
      );
    @include sp-inset($w-promoted-price--floating--sp-inset);
    @include sp-stack-children($w-promoted-price--floating--sp-stack);
    text-align: center;
  }

  .w-promoted-price__price {
    text-align: center;
    .c-price__label.c-price__label-grayed:last-child {
      display: block;
      color: $c-price__label-grayed--color;
      font-size: $c-price__label-grayed--font-size;
      max-width: $w-promoted-price__label-grayed--max-width;
      font-style: $w-promoted-price__label-grayed--style;
    }
  }

  .w-promoted-price__btn {
    display: block;
  }

  .w-promoted-price__yield {
    @include sp-stack($w-promoted-price__yield--sp-stack);
  }
}

.w-promoted-price--floating {
  @include w-promoted-price--floating();
}

@each $_breakpoint in $w-promoted-price--floating--breakpoints {
  @include t-mq($from: $_breakpoint) {
    .w-promoted-price--floating#{s-core-string-breakpoint($_breakpoint)} {
      @include w-promoted-price--floating();
    }
  }
}

.discount-banner {
  display: flex;
  justify-content: center;
  background: $w-promoted-price__discount-banner--background-color;
  border-radius: 0px 0px 10px 10px;
  padding: $w-promoted-price__discount-banner--padding;
  margin-top: $w-promoted-price__discount-banner--margin-top;
  margin-bottom: $w-promoted-price__discount-banner--margin-bottom;
  color: $w-promoted-price__discount-banner--color;
  position: relative;
  @include t-mq($until: $f-breakpoint--sm) {
    flex-direction: column;
  }
  @include t-mq($until: $f-breakpoint--sml) {
    margin-left: $w-promoted-price__discount-banner-until-sml--margin;
    margin-right: $w-promoted-price__discount-banner-until-sml--margin;
    margin-bottom: $w-promoted-price__discount-banner-until-sml--margin-bottom;
  }

  strong {
    margin-right: 4px;
  }

  &.discount-banner--react-compatible {
    margin-top: $w-promoted-price__discount-banner--margin-top--react-compatible;
    border-radius: 10px;

    @include t-mq($until: $f-breakpoint--sml) {
      border-radius: unset;
    }

    @include t-mq($until: $f-breakpoint--sm) {
      margin-top: $w-promoted-price__discount-banner--margin-top--react-compatible--sm;
    }
  }
}

.w-promoted-price__info-wrapper {
  .c-package-block {
    flex-direction: column;
    .c-package-block__flight-routes-container {
      justify-content: center;
    }
  }
}
