/* -------------------------------------------------------------------------
 * PRICE
 */


$c-price_old-price--font-size:                  $f-font-size--xs !default;
$c-price_old-price--font-family:                $f-font-family__accent !default;
$c-price_old-price--font-weight:                700 !default;
$c-price_old-price--margin-left:                $f-space--tiny !default;

$c-price__info-icon--margin-left:               $f-space--tiny / 2 !default;
$c-price__info-icon--font-size:                 $f-font-size--m !default;
$c-price__info-icon--color:                     $e-document__body--color !default;
$c-price__info-icon--z-index:                   $f-z-index--1 !default;

$c-price__public-price--color:                  $f-color__neutral-grey--semi-dark !default;
$c-price_public-price--margin-left:             $f-space--tiny !default;
$c-price_public-price--padding-top:             3px;

$c-price__public-price-detail--color:           $f-color__state-info--semi-dark !default;

//Modifier vars: primary color

$c-price__value--primary-color--color:          $f-color__brand-primary !default;

$c-price__suffix--primary-color--font-weight:   500 !default;
$c-price__suffix--primary-color--color:         $f-color__neutral-grey !default;
$c-price__suffix--primary-color--font-family:   $f-font-family__primary !default;

$c-price__label-grayed--color:                  $f-color__neutral-grey !default;
$c-price__label-grayed--font-size:              $f-font-size--xs !default;
$c-price__label-grayed--style:                  italic !default;


$c-price__extra-info-link-hover--color:         $f-color__state-info--semi-dark !default;
