/* -------------------------------------------------------------------------
 * BOOKING PARTICIPANTS BADGE
 *
 */

.w-booking-participants-badge__email-section {
  @include sp-stack(
    $w-booking-participants-badge__email-section--marging-bottom
  );

  .c-form__fieldset-container {
    margin-left: 0;
  }
}

.w-booking-participants-badge__email-field-row {
  @include sp-stack(
    $w-booking-participants-badge__email-field-row--margin-bottom
  );
  min-width: 60%;

  @include t-mq($until: $w-booking-participants-badge__email-field-row--mq) {
    display: flex;
    flex-direction: column;
  }
}

.w-booking-participants-badge .c-form__fieldset-container {
  @include c-form__fieldset-container--sp-stack-children(0);
}

.w-booking-participants-badge__email-input {
  @include sp-inline($w-booking-participants-badge__email-input--margin-right);
  @include sp-stack($w-booking-participants-badge__email-input--sp-stack);
  padding-left: 0;
  padding-right: $w-booking-participants-badge__email-input--margin-right;

  .c-textbox__label {
    @include t-text-crop-disable();
    @include t-mq(
      $from: $w-booking-participants-badge__email-input--label-overflow--mq
    ) {
      width: $w-booking-participants-badge__email-input--width;
      overflow: hidden;
    }
  }

  @include t-mq($until: $w-booking-participants-badge__email-input--mq) {
    padding-right: 0;
  }
}

.w-booking-participants-badge__email-success-text {
  padding: 0;
  @include sp-inline(0);
  max-height: 0;
  visibility: hidden;
}

.w-booking-participants-badge__email-information-text {
  color: $w-booking-participants-badge__email-information-text--color;
}

.w-booking-participants-badge__participants-room-section {
  @include sp-stack(
    $w-booking-participants-badge__participants-room-section--margin-bottom
  );

  + .c-form-section__title {
    font-weight: $w-booking-participants-badge__participants-room-section-title--font-weight;
  }
}

.w-booking-participants-badge__participant-row {
  display: flex;
  justify-content: space-between;
  @include t-mq($until: $w-booking-participants-badge__participant-row--mq) {
    flex-direction: column;
    .w-booking-participants-badge__participant-field.c-form__item {
      padding-left: 0px;
    }
  }

  @include t-mq($from: $w-booking-participants-badge__participant-row--mq) {
    .w-booking-participants-badge__participant-field.c-textbox.c-form__item {
      padding-left: 0px;
    }
  }
}

.w-booking-participants-badge__participant-field {
  flex: 1;
  .e-form-label{
    @include t-text-crop-disable();
  }
}

.w-booking-participants-badge--email-save-button {
  vertical-align: top;
  padding-top: $w-booking-participants-badge--email-save-button--padding-top;
}

.w-booking-participants-badge--collapse-btn {
  pointer-events: none;
  height: $w-booking-participants-badge--collapse-btn--height;
  margin-top: $w-booking-participants-badge--collapse-btn--margin-top;

  .c-btn__icon {
    color: $w-booking-participants-badge--collapse-btn__icon--color;
    padding-left: $w-booking-participants-badge--collapse-btn__icon--padding-left;
  }
  .c-btn__icon::before {
    font-size: $w-booking-participants-badge--collapse-btn__icon--font-size;
    height: $w-booking-participants-badge--collapse-btn__icon--height;
    color: $w-booking-participants-badge--collapse-btn__icon--color;
  }
}

.w-booking-participants-badge--participants-header {
  display: flex;
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-width: $w-booking-participants-badge--participants-header--bottom-width;
  border-bottom-color: $f-color__neutral-grey--semi-light;
  padding-bottom: $w-booking-participants-badge--participants-header--padding-bottom;

  &[data-c-collapse__opened] {
    .c-btn__icon::before {
      transform: rotate(180deg);
    }
  }
}
.w-booking-participants-badge--collapse-section {
  &.in,
  &.is-opening,
  &.is-closing {
    margin-top: $w-booking-participants-badge--collapse-section--margin-top;
  }
}

.w-booking-participants-badge__participants-room-section {
  .c-form__section-title {
    border-bottom: 0;
    margin-bottom: 0;
  }
}

.w-booking-participants-badge__is-success {
  .w-booking-participants-badge__email-success-text {
    padding: $w-booking-participants-badge__email-success-text--padding;
    @include sp-inline(
      $w-booking-participants-badge__email-input--margin-right
    );
    @include t-mq(
      $until: $w-booking-participants-badge__email-success-text--mq
    ) {
      @include sp-inline(0);
    }
    max-height: inherit;
    visibility: inherit;
  }

  .w-booking-participants-badge__email-save {
    visibility: hidden;
  }

  .w-booking-participants-badge__email-information-text {
    visibility: hidden;
    height: 0;
  }
}