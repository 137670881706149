/* -------------------------------------------------------------------------
 * ACTION MODAL
 */
.w-action-modal {

  .w-action-modal__icon {
    color: $w-action-modal__icon--color;
    margin-bottom: $w-action-modal--sp-stack;
    text-align: center;
  }

  .w-action-modal--failed .w-action-modal__icon {
    color: $w-action-modal--failed__icon--color;
  }

  .w-action-modal--succeeded .w-action-modal__icon {
    color: $w-action-modal--succeeded__icon--color;
  }

  .w-action-modal--content-notification {
    text-align: center;
  }

  .w-action-modal .c-modal__body {
    @include t-text-crop-disable();
  }
}

[data-w-action-modal__has-response-msg] .c-modal__footer {
  display: none;
}
