/* -------------------------------------------------------------------------
 * BOOKING ITEM
 *
 */

 .w-booking-item {
    margin-bottom: $w-booking-item-margin;
    &.is-hidden {
        display: none;
    }
 }
