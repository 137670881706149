$w-account-menu__z-index:                                                           $f-z-index--1 !default;

$w-account-menu__dropdown__width:                                                   300px !default;
$w-account-menu__dropdown__box-shadow:                                              0px -2px 8px 0px rgba(0, 0, 0, 0.16) !default;
$w-account-menu__dropdown__bg-color:                                                $f-color__neutral-white !default;
$w-account-menu__dropdown__border-radius:                                           $f-radius--large !default;
$w-account-menu__dropdown__margin-bottom:                                           16px !default;
$w-account-menu__dropdown__top:                                                     calc(100% + 5px) !default;

$w-account-menu__link__font-size:                                                   $f-font-size--s !default;
$w-account-menu__link__font-weight:                                                 500 !default;
$w-account-menu__link__line-height:                                                 $f-line-height--large !default;

$w-account-menu__login-container__padding:                                          16px !default;
$w-account-menu__login-container__margin:                                           $f-space--medium !default;

$w-account-menu__logo__color:                                                       $f-color__brand-primary !default;
$w-account-menu__logo__font-size:                                                   $f-font-size--xxxl !default;
$w-account-menu__logo__margin-bottom:                                               $f-space--tiny !default;

$w-account-menu__create-account-link__font-weight:                                  600 !default;

$w-account-menu__login-btn__margin-bottom:                                          $f-space--small !default;
$w-account-menu__login-btn__color:                                                  $f-color__neutral-white;
$w-account-menu__login-btn__font-weight:                                            600;

$w-account-menu__login-title__margin-bottom:                                        16px !default;

$w-account-menu__button__font-weight:                                               500 !default;
$w-account-menu__button__border-radius:                                             $f-radius--rounded !default;

$w-account-menu__button__hover-color:                                               $f-color__neutral-black !default;

$w-account-menu__nav-link__padding:                                                 $f-space--small $f-space--medium !default;
$w-account-menu__nav-link__color-hover:                                             $f-color__neutral-black !default;

$w-account-menu__first-nav__margin-top:                                             16px !default;

$w-account-menu__nav-link-icon__font-size:                                          20px !default;

$w-account-menu__nav-item--with-icon__gap:                                          4px !default;

$w-account-menu__login-btn__hover-color:                                            $f-color__neutral-white !default;

$w-account-menu__button__hover-color:                                               $f-color__neutral-black !default;

$w-account-menu__second-nav__padding:                                               16px 0 !default;
$w-account-menu__second-nav__margin:                                                16px auto !default;
$w-account-menu__second-nav__pseudo-element-left:                                   24px !default;
$w-account-menu__second-nav__pseudo-element-right:                                  24px !default;

$w-account-menu__nav-link-favorite__color:                                          $f-color__neutral-black !default;
$w-account-menu__nav-link-favorite__font-size:                                      $f-font-size--s !default;
$w-account-menu__nav-link-favorite__line-height:                                    $f-line-height--large !default;
$w-account-menu__nav-link-favorite__font-weight:                                    600 !default;

$w-account-menu__form-submit__width:                                                100%;
$w-account-menu__form-submit__color:                                                $f-color__neutral-black !default;
$w-account-menu__form-submit__font-size:                                            $f-font-size--s !default;
$w-account-menu__form-submit__font-weight:                                          500 !default;
$w-account-menu__form-submit__line-height:                                          $f-line-height--large !default;
$w-account-menu__form-submit__font-family:                                          $f-font-family__primary !default;
$w-account-menu__form-submit__background-color:                                     transparent !default;

$w-account-menu__button-icon__font-size:                                            19px !default;
$w-account-menu__button-icon__offset:                                               -3px !default;
$w-account-menu__button__padding:                                                   6px 12px !default;
$w-account-menu__nav-item-active--background-color:                                 $f-color__neutral-grey !default;
$w-account-menu__border-color:                                                      $f-color__neutral-grey--light !default;
$w-account-menu__border-width:                                                      2px !default;

$w-account-menu__avatar__bg-color:                                                  $f-color__neutral-black;
$w-account-menu__avatar__color:                                                     $f-color__neutral-white;
$w-account-menu__avatar__height:                                                    24px;
$w-account-menu__avatar__width:                                                     24px;
$w-account-menu__avatar__left:                                                      12px;
$w-account-menu__avatar__z-index:                                                   1;
$w-account-menu__avatar__border-radius:                                             100%;
