/* -------------------------------------------------------------------------
* Form Variables
*
*/

$c-form--sp-stack:                                    $f-space--medium !default;

$c-form__fieldset--sp-stack:                          $c-form--sp-stack !default;
$c-form__fieldset-title-container--sp-stack:          $c-form--sp-stack !default;
$c-form__fieldset-title--sp-stack:                    $f-space--tiny !default;
$c-form__fieldset-description--sp-stack:              $f-space--tiny !default;
$c-form__fieldset-description--font-size:             $f-font-size--s !default;
$c-form__fieldset-description--color:                 $f-color__neutral-grey--dark !default;
$c-form__fieldset-container--margin-left:             -1 * $f-space--medium !default;

$c-form__fieldset--choice-list-margin-bottom:         -1 * $f-space--small !default;

$c-form__item--padding-left:                          $f-space--medium !default;
$c-form__item--sp-stack:                              $c-form--sp-stack !default;
$c-form__item--half-width:                            percentage(1/2) !default;
$c-form__item--third-width:                           percentage(1/3) !default;
$c-form__item--fourth-width:                          percentage(1/4) !default;
$c-form__item--sixth-width:                           percentage(1/6) !default;
$c-form__item--full-width:                            100% !default;

$c-form--breakpoint-tablet:                           $f-breakpoint--sm !default;
$c-form--breakpoint-tablet-desktop:                   $f-breakpoint--md !default;
$c-form--breakpoint-desktop:                          $f-breakpoint--lg !default;

$c-form__section-title--sp-stack:                     $c-form--sp-stack !default;
$c-form__section-title-border-color:                  $f-color__neutral-grey--semi-light !default;
$c-form__section-title-border-size:                   1px !default;
$c-form__section-title-border-style:                  solid !default;

$c-form__section-title-border-margin:                 $f-space--small !default;
$c-form__section-heading-icon--color:                 $f-color__neutral-grey--semi-dark !default;
$c-form__section-heading-icon--margin-right:          $f-space--small !default;
$c-form__section--collapse-fieldset-sp-top:           $f-space--medium !default;