/* -------------------------------------------------------------------------
* Video
*
*/

.c-video-title{
    text-align: $c-video--title-aligment;
    padding-top: $c-video--title-padding-top;
    padding-bottom: $c-video--title-padding-bottom;
}

.c-video-subtitle{
    &.c-video-subtitle--isup{
        padding-bottom: $c-video--subtitle-up-padding-bottom;
    }
    &.c-video-subtitle--isdown{
        padding-top: $c-video--subtitle-down-padding-top;
        padding-bottom: $c-video--subtitle-down-padding-bottom;
    }
}

.c-video-thumbnail{

    &.hidden{
        display: none;
    }
    &:hover{
        cursor: pointer;
        .c-video-thumbnail-image{
            -webkit-filter: brightness(75%);
        }
        .c-video-thumbnail-info{
            .c-video-thumbnail-info-icon-wrapper{
                .c-video-thumbnail-info-icon{
                    background-color: $c-video--thumbnail--info-icon-color-hover;
                }
            }
        }
    }
}

.c-video-thumbnail-info{
    display: inline-flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $c-video--thumbnail-information-background-color;
}

.c-video-thumbnail-info-icon-wrapper{
    padding-left: $c-video--thumbnail--info-icon-wrapper-padding-left;
    padding-right: $c-video--thumbnail--info-icon-wrapper-padding-right-tablet;
    margin-top: $c-video--thumbnail--info-icon-margin-top-tablet;
    margin-bottom: $c-video--thumbnail--info-icon-margin-bottom-tablet;
    display: grid;

    @include t-mq($from: $c-video__breakpoint--tablet) {
        padding-right: $c-video--thumbnail--info-icon-wrapper-padding-right;
        margin-top: $c-video--thumbnail--info-icon-margin-top;
    }
}

.c-video-thumbnail-info-icon{
    font-size: $c-video--thumbnail--info-icon-size-tablet;
    margin-bottom: $c-video--thumbnail--info-icon-margin-bottom;
    position: relative;
    width: $c-video--thumbnail--info-icon-content-size-tablet;
    height: $c-video--thumbnail--info-icon-content-size-tablet;
    display: flex;
    background-color: $c-video--thumbnail--info-icon-content-color;
    border-radius: 50%;
    transition-duration: $c-video--transform-time-out;
    transition-property: all;

    @include t-mq($from: $c-video__breakpoint--tablet) {
        width: $c-video--thumbnail--info-icon-content-size;
        height: $c-video--thumbnail--info-icon-content-size;
        font-size: $c-video--thumbnail--info-icon-size;
    }

    &:before{
        position: absolute;
        display: block;
        color: $c-video--thumbnail--info-icon-color;
        left: $c-video--thumbnail--info-icon-center-left;
        top: $c-video--thumbnail--info-icon-center-top;
        transform: translate(-50%, -50%);
    }
}

.c-video-thumbnail-info-duration{
    text-align: center;
}

.c-video-thumbnail-info-name{
    padding-right: $c-video--thumbnail-info-name-padding-right-tablet;
    display: flex;
    align-items: center;

    @include t-mq($from: $c-video__breakpoint--tablet) {
        padding-right: $c-video--thumbnail-info-name-padding-right;
    }
}

.c-video-youtube{
    border: none;
}

.c-video-thumbnail-image{
    -webkit-filter: brightness(100%);
    transition-duration: $c-video--transform-time-out;
    transition-property: all;
}
