/* -------------------------------------------------------------------------
 * CONTENT BLOCK VARIABLES
 */

$c-content-block--spacing-stack:                 $f-space--medium !default;
$c-content-block__body--spacing-stack:           $f-space--medium + $f-space--small !default;


$c-content-block__subtitle--color:               $f-color__neutral-grey--semi-dark !default;
$c-content-block__subtitle--spacing:             $f-space--tiny !default;

// Size Modifiers
// -------------------------------

// Small modifier
$c-content-block--small--spacing-stack:          $f-space--medium !default;
$c-content-block__body--small--spacing-stack:    $f-space--medium + $f-space--small !default;

// Large modifier
$c-content-block--large--spacing-stack:          $f-space--large !default;
$c-content-block__body--large--spacing-stack:    $f-space--large + $f-space--small !default;
