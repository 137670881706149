
/* -------------------------------------------------------------------------
 * HIDDEN
 *
 * Force hide elements on certain breakpoints range
 *
 */


// Utility Selector output
// --------------------------------------------------

@if ($u-hidden--enabled) {
  .u-hidden {
    display: none !important;
  }
}

// Utility breakpoints FROM output
// --------------------------------------------------

@if ($u-hidden--enabled and $u-hidden__bp-from--enabled) {
  @each $_bp-name, $_bp-value in $u-hidden__bp-from {
    @include u-hidden--bp("from", $_bp-name);
  }
}


// Utility breakpoints UNTIL output
// --------------------------------------------------

@if ($u-hidden--enabled and $u-hidden__bp-until--enabled) {
  @each $_bp-name, $_bp-value in $u-hidden__bp-until {
    @include u-hidden--bp("until", $_bp-name);
  }
}


// Save utility class names to $brand__class-names
// --------------------------------------------------

@if variable-exists(class-names) and $u-hidden--enabled {

  @if $u-hidden__bp-from--enabled {

    @each $_bp-name, $_bp-value in $u-hidden__bp-from {

      $u-hidden__class-names: append($u-hidden__class-names, (
        'name': 'hidden-from-#{$_bp-name}',
        'class': 'u-hidden--from#{s-core-string-breakpoint($_bp-name)}',
        'description': 'visible < #{$_bp-value}, hidden >= #{$_bp-value}'
      ));
    }

  }

  @if $u-hidden__bp-until--enabled {

    @each $_bp-name, $_bp-value in $u-hidden__bp-until {

      $u-hidden__class-names: append($u-hidden__class-names, (
        'name': 'hidden-until-#{$_bp-name}',
        'class': 'u-hidden--until#{s-core-string-breakpoint($_bp-name)}',
        'description': 'hidden < #{$_bp-value}, visible >= #{$_bp-value}'
      ));
    }

  }

  $class-names: map-merge($class-names, (
    'u-hidden': $u-hidden__class-names
  ));

}
