
/* -------------------------------------------------------------------------
 * LIST BARE
 *
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 *
 */

// Object selector output
// --------------------------------------------------

@if ($o-list-bare--enabled) {
  .o-list-bare {
    @include o-list-bare();
  }
}
