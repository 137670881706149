/* -------------------------------------------------------------------------
 * ELEMENT mixins
 *
 */

@mixin e-form-label() {
  display: $e-form-elements__label--display;
  color: $e-form-elements__label--color;
  font-family: $e-form-elements__label--font-family;
  font-size: $e-form-elements__label--font-size;
  font-weight: $e-form-elements__label--font-weight;
  text-transform: $e-form-elements__label--text-transform;
  line-height: $e-form-elements__label--line-height;
  @include sp-stack($e-form-elements__label--space-stack);
  @include t-text-crop-enable();
  @include t-text-crop($e-form-elements__label--font-family--crop, $e-form-elements__label--line-height);
  
  &:empty {
    display: none;
  }
}

@mixin e-form-label--strong() {
  display: $e-form-elements__label--display;
  color: $e-form-elements__label--color;
  font-family: $e-form-elements__label--font-family;
  font-size: $e-form-elements__label--font-size--strong;
  font-weight: $e-form-elements__label--strong--font-weight;
  text-transform: $e-form-elements__label--strong--text-transform;
  line-height: $e-form-elements__label--line-height;
  @include sp-stack($e-form-elements__label--space-stack);
  @include t-text-crop-enable();
  @include t-text-crop($e-form-elements__label--font-family--crop, $e-form-elements__label--line-height);
  
  &:empty {
    display: none;
  }
}
