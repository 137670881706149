
$w-destination-multiple--column-wrapper-width:                                        260px !default;
$w-destination-multiple--column-wrapper-margin:                                       32px !default;
$w-destination-multiple--last-column-wrapper-margin:                                  $f-space--tiny !default;

$w-destination-multiple--checkbox-separator-height:                                   0.5em !default;
$w-destination-multiple--checkbox-separator-border-size:                              1px  !default;
$w-destination-multiple--checkbox-separator-border-type:                              solid  !default;
$w-destination-multiple--checkbox-separator-border-color:                             $f-color__neutral-grey--semi-light !default;
$w-destination-multiple--checkbox-separator-margin-left:                              9px !default;

$w-destination-multiple--checkbox-value-font-size:                                    12px !default;
$w-destination-multiple--checkbox-value-color:                                        $f-color__neutral-grey !default;

$w-destination-multiple--floating-box__min-width:                                     calc(#{2*$w-destination-multiple--column-wrapper-width} + #{$w-destination-multiple--column-wrapper-margin} + #{$w-destination-multiple--last-column-wrapper-margin} + #{2*$f-space});

$w-destination-multiple-filter__clear-btn__color:                                     $e-links__color !default;
$w-destination-multiple-filter__clear-btn__color--hover:                              $e-links__hover-color !default;
$w-destination-multiple-filter__clear-btn--font-size:                                 $f-font-size--m !default;
$w-destination-multiple-filter__clear-btn--margin:                                    4px !default;