/**
    Reviews List for Eliza
*/

.w-reviews-list__item{
    box-shadow: none;

    .w-reviews-list__main-rating{
        .c-number-score__title {
            font-size: $w-reviews-list__item--main-score-title-font-size;
        }
        .w-reviews-list-__item-title{
            margin-top: $w-reviews-list__item-title--marging-top;
        }
    }

    .w-reviews-list__item-text{
        &:not([class*=c-collapse--from]).after-fading-light:not(.in):not(.is-idle):after {
            background-image: linear-gradient(180deg,hsla(0,0%,100%,0),$w-reviews-list__item--bg);
        }
    }
    
    .w-reviews-list__score-points{
        .w-reviews-list__item-scores{
            .c-number-score{
                .c-number-score__body{
                    background-color: $w-reviews-list__score-points--score-bg;
                    color: $w-reviews-list__score-points--score-color;
                }
                .c-number-score__title {
                    font-family: $w-reviews-list__score-points--main-score-title-font;
                    font-size: $w-reviews-list__score-points--main-score-title-font-size;
                }
            }
        }
    }
}
