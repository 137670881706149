/* -------------------------------------------------------------------------
 * DATES FILTER
 *
 */

.c-floating-box {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: $c-floating-box--min-width;
  z-index: $c-floating-box--z-index;
  background-color: $c-floating-box--bg-color;
  overflow-y: auto;
  overflow-x: visible;
  @include sp-inset($c-floating-box--sp-inset);
  @include a-reveal-lift();
  transform: translateY(30%);

  &.is-opening,
  &.is-closing {
    display: flex;
    flex-direction: column;
  }
  &.in {
    display: flex;
    flex-direction: column;
    @include a-reveal-lift--in();
    transform: translateY(0%);
  }
}


.c-floating-box__body {
  @include o-block__body--stretch();
  position: relative;
}

.c-floating-box__body-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: visible;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-behavior: smooth;
}

.c-floating-box__footer {
  text-align: right;
}


// With gradient modifier
.c-floating-box--with-gradient {
  .c-floating-box__header {
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      height: $f-space--small;
      background: linear-gradient($f-color__neutral-white, $f-color__neutral-white--transparent);
      z-index: 1;
    }
  }
  .c-floating-box__body-content {
    padding-top: $f-space--small;
  }
  .c-floating-box__footer {
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: $f-space--small * -1;
      height: $f-space--small;
      background: linear-gradient($f-color__neutral-white--transparent, $f-color__neutral-white);
      pointer-events: none;
    }
  }
}


// Enable floating box float only from certain breakpoints
// --------------------------------------------------

@each $_breakpoint in $c-floating-box--breakpoints {
  @include t-mq($from: $_breakpoint) {
    .c-floating-box--floats-from#{s-core-string-breakpoint($_breakpoint)} {
      position: absolute;
      top: calc(100% + #{$c-floating-box--bottom-distance});
      right: auto;
      bottom: auto;
      left: 0;
      border-radius: $c-floating-box--border-radius;
      border: $c-floating-box--border-size $c-floating-box--border-type $c-floating-box--border-color;
      transform: translateY($c-floating-box--translate-y);

      .c-floating-box__body-content {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        overscroll-behavior-y: auto;
        max-height: 100%;
      }
    }
  }
}
