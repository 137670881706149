/* -------------------------------------------------------------------------
 * PACK
 *
 * The pack object simply causes any number of elements pack up horizontally to
 * automatically fill an equal, fluid width of their parent.
 *
 * Params:
 * SPACE .............................. Horizontal separation between image and body
 * ALIGNMENT .......................... Vertical items alignment
 *
 */


// Object selector output
// --------------------------------------------------

@if ($o-pack--enabled) {
  .o-pack {
    @include o-pack();
  }

  .o-pack__item {
    @include o-pack__item();
  }

}


// Space modifiers
// --------------------------------------------------

@if ($o-pack--enabled and $o-pack__mod-spaces--enabled) {
  @each $_space-name, $_space-value in $o-pack-spaces {
    @include o-pack--space($_space-name, $_space-value);
  }
}


// Alignment modifiers
// --------------------------------------------------

@if ($o-pack--enabled and $o-pack__mod-alignments--enabled) {
  @each $_vertical-alignment in $o-pack__mod-alignments {
    .o-pack--#{$_vertical-alignment} > .o-pack__item {
      @include o-pack__mod-alignment($_vertical-alignment);
    }
  }

}


// Reverse modifier
// --------------------------------------------------

@if ($o-pack--enabled and $o-pack__mod-reverse--enabled) {
  .o-pack--reverse {
    @include o-pack--reverse();

    > .o-pack__item {
      @include o-pack__item--reverse();
    }

  }

}


// Auto-Width modifier
// --------------------------------------------------

@if ($o-pack--enabled and $o-pack__mod-auto--enabled) {
  .o-pack--auto {
    @include o-pack--auto();
    > .o-pack__item--wide {
      @include o-pack--auto__item--wide();
    }
  }
}



// Item reverse modifiers
// --------------------------------------------------

@if ($o-pack--enabled and $o-pack__item-mod-reverse--enabled) {
  .o-pack__item--reverse {
    @include o-pack__item-mod-reverse();
  }
}
