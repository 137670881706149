/* -------------------------------------------------------------------------
* Dropdown
*
*/

.c-dropdown {
  color: $f-color__neutral-grey--ultra-dark;

  &.has-value {
    .c-dropdown__clear {
      display: block;
    }

    .c-dropdown__element {
      color: $c-dropdown__element--color--has-element;
    }
  }

  &.is-required:not(.c-dropdown--without-required-symbol) {
    .c-dropdown__label:after {
      content: ' *';
      display: inline;
    }
  }
}

.c-dropdown__wrapper {
  position: relative;
  display: block;
  width: 100%;
  border-radius: $c-dropdown--border-radius;
}

.c-dropdown__icon,
.c-dropdown__clear,
.c-dropdown__arrow {
  position: absolute;
  z-index: $c-dropdown__icons--z-index;
  top: 50%;
  transform: translateY(-50%);
}

.c-dropdown__icon {
  pointer-events: none;
  left: $c-dropdown__icon--left;
  font-size: $c-dropdown__icon--font-size;
  color: $c-dropdown__icon--color;
  background-color: $c-dropdown__element--bg-color;
  border-radius: $c-dropdown--border-radius;
}

.c-dropdown__clear {
  display: none;
  right: $c-dropdown__clear--right;
  color: $c-dropdown__clear--color;
  background-color: $c-dropdown__element--bg-color;
  border-radius: $c-dropdown--border-radius;
  @include sp-inline($c-dropdown__clear--space-inline);

  &:hover {
    color: $c-dropdown__clear--color--hover;
    background-color: $c-dropdown__element--bg-color;
  }

  .c-btn__icon {
    &::#{$m-icons__pseudo-element} {
      font-size: $c-dropdown__clear--font-size;
      left: auto;
      right: 50%;
      transform: translateX(50%);
    }
  }
}

.c-dropdown__arrow {
  right: $f-space--small;
  pointer-events: none;
  color: $c-dropdown__arrow--color;
  background-color: $c-dropdown__arrow--bg-color;
}

.c-dropdown__element {
  appearance: none;
  outline: 0;
  box-shadow: none;
  background: $c-dropdown__element--bg-color;
  margin: 0;
  border: $c-dropdown__element--border-size $c-dropdown__element--border-style $c-dropdown__element--border-color;
  border-radius: $c-dropdown--border-radius;
  width: 100%;
  @include sp-inset($c-dropdown--sp-inset-v $c-dropdown--sp-inset-h);
  padding-right: $c-dropdown--sp-inset-right;
  position: relative;
  cursor: pointer;
  color: $c-dropdown__element--color;
  font-family: $c-dropdown__element--font-family;
  font-size: $c-dropdown__element--font-size;
  transition: color $c-dropdown--duration $c-dropdown--easing, border $c-dropdown--duration $c-dropdown--easing, box-shadow $c-dropdown--duration $c-dropdown--easing;
  min-height: $c-dropdown__element--min-height;

  .c-dropdown__option:not(:disabled) {
    color: $c-dropdown__option--color;
  }

  .c-dropdown__option-default:not(:disabled) {
    color: $c-dropdown__option-default--color;
  }

  &:hover {
    border-color: $c-dropdown__element--border-color--hover;
  }

  &:focus,
  &.is-focused {
    box-shadow: $c-dropdown__element--shadow--focus;
    border-color: $c-dropdown__element--border--focus;
  }

  &::-ms-expand {
    display: none;
  }
}

.c-dropdown__icon + .c-dropdown__element {
  padding-left: $c-dropdown__element--text-indent--with-icon;
}

.c-dropdown__messages {
  font-size: $c-dropdown__messages--font-size;
}

.c-dropdown__message:first-child {
  margin-top: $c-dropdown__messages--margin-top;
}

.c-dropdown__label:empty {
  display: none;
}

// States
// -------------------------------------------------

// Disabled
.c-dropdown.is-disabled,
.is-disabled .c-dropdown {

  .c-dropdown__element {
    pointer-events: none;
  }
}

.c-dropdown.is-disabled {
  .c-dropdown__icon,
  .c-dropdown__clear {
    background-color: $c-dropdown__disabled--bg-color;
  }

  .c-dropdown__arrow {
    color: $c-dropdown__arrow--disabled--color;
  }

  .c-dropdown__element {
    color: $c-dropdown__element--disabled--color;
  }

  &.has-value {
    .c-dropdown__element {
      color: $c-dropdown__element--disabled--color--has-element;
    }
  }
}

.is-disabled .c-dropdown {
  .c-dropdown__wrapper {
    opacity: $c-dropdown__disabled--opacity;
  }
}

// Success
.c-dropdown.has-success,
.has-success .c-dropdown {
  .c-dropdown__element {
    border-color: $c-dropdown__element--success--border-color;

    &:focus {
      box-shadow: $c-dropdown__element--success--shadow--focus;
    }
  }
}

// Warning
.c-dropdown.has-warning,
.has-warning .c-dropdown {
  .c-dropdown__element {
    border-color: $c-dropdown__element--warning--border-color;

    &:focus {
      box-shadow: $c-dropdown__element--warning--shadow--focus;
    }
  }
}

// Error
.c-dropdown.has-error,
.has-error .c-dropdown {
  .c-dropdown__element {
    border-color: $c-dropdown__element--error--border-color;

    &:focus {
      box-shadow: $c-dropdown__element--error--shadow--focus;
    }
  }
  .c-dropdown__message {
    color: $c-dropdown__message--error--color
  }
}

// Modifiers
// -----------------------------------------

// Highlighted
.c-dropdown--highlighted {
  &.has-value {
    .c-dropdown__element {
      color: $c-dropdown__element--highlighted--value-color;

      .c-dropdown__option {
        color: $c-dropdown__option--color;
      }

      .c-dropdown__option-default {
        color: $c-dropdown__option-default--color;
      }
    }
  }
}

.c-dropdown__option {
  text-align: left;
}

.c-dropdown__option-default {
  text-align: left;
}

// Variants
// -----------------------------------------

.c-dropdown,
.c-dropdown-multiple{
  &.is-compact{
    .c-dropdown__label {
      font-family: $c-dropdown__element--font-family;
      position: absolute;
      z-index: $c-dropdown__label--z-index;
      @include sp-inset($c-dropdown--is-compact--sp-inset-v $c-dropdown--sp-inset-h);
      padding-right: $c-dropdown--sp-inset-right;
      color: $c-dropdown--sp-inset-label--font-color;
      pointer-events: none;
      transition: font-size $c-dropdown--sp-inset-label-duration $c-dropdown--easing, padding 0s $c-dropdown--easing;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: $c-dropdown--is-compact--sp-inset-label--max-width;
    }
    .c-dropdown__element {
      min-height: $c-dropdown__element--is-compact--min-height;
      padding-top: $c-dropdown--is-compact--sp-inset-v;
      padding-bottom: $c-dropdown--is-compact--sp-inset-v;
    }
    .c-dropdown__placeholder{
      display: none;
    }
    .c-dropdown__swipe{
      min-height: $c-dropdown__element--multiple--is-compact__no-value__min-height;
    }
    [data-js-swipe].c-dropdown__swipe{
      .c-dropdown__selected-item-list{
        position: relative;
        display: block;
        padding-top: $c-dropdown__selected-item-list__compact-has-value;
        overflow-x: hidden;
        top: $c-dropdown__selected-item-list__compact-top;

        .c-dropdown__multiple-item:not(:last-child):after{
          content: ', ';
        }
      }
    }
  }
  &.is-compact:not(.has-value){
    .c-dropdown__label{
      font-size: $c-dropdown__element--font-size;
      min-height: $c-dropdown__element--is-compact--min-height;
      top: $c-dropdown__element--is-compact--top;
      margin-bottom: 0;
    }
    .c-dropdown__element{
      color: transparent;
      transition: color $c-dropdown--is-compact-duration $c-dropdown--easing;
    }
  }

  &.is-compact.has-value{
    .c-dropdown__element{
      padding-top: $c-dropdown--sp-inset-v-compact;
    }
  }

  &.is-compact.has-values{
    .c-dropdown__label {
      min-height: 0;
      top: 0px;
    }
    .c-dropdown__element{
      color: $c-dropdown__element--is-compact--color;
    }
    .o-swipe-btn{
      padding-top: $o-swipe-btn--dropdown-is-compact--padding-top;
    }
  }

  &.is-compact.has-value,
  &.is-compact.has-values {
    .c-dropdown__label {
      font-size: $c-dropdown--sp-inset-label--font-size;
      padding-top: $c-dropdown--sp-inset-label-has-value--padding-top;
    }
  }

  &.has-icon.is-compact {
    .c-dropdown__label {
      padding-left: $c-dropdown__element--text-indent--with-icon;
    }
  }
}
