
/* -------------------------------------------------------------------------
 * THEME PEARLS Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-pearls--enabled {
  .cs-theme-pearls {
    @include color-scheme($cs-theme-pearls)
  }
}
