/* -------------------------------------------------------------------------
 * CAR ITEM
 *
 */

.c-car-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include t-mq($until: $c-car-item--bp-medium) {
    width: 100%;
    padding: $c-car-item--padding--bp-medium;
  }
  @include t-mq($c-car-item--bp-medium) {
    align-items: center;
    flex-direction: row;
  }
}

.c-car-item__image {
  mix-blend-mode: multiply;
  width: auto;
  @include t-mq($until: $c-car-item--bp-medium) {
    margin: 0 auto;
  }
  @include t-mq($c-car-item--bp-medium) {
    max-width: $c-car-item__image--max-width;
    min-width: $c-car-item__image--min-width;
  }
}


.c-car-item__details{
  max-width: 100%;
  @include t-mq($c-car-item--bp-medium) {
    max-width: $c-car-item__details--max-width;
  }
}

.c-car-item__pre-heading{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.c-car-item__type {
  color: $c-car-item__type--color;
  font-weight: $c-car-item__type--font-weight;
  @include sp-stack($c-car-item__type--sp-stack);
  @include sp-inline($c-car-item__type--sp-inline);
}

.c-car-item__name {
  font-size: $c-car-item__name--font-size;
  font-weight: $c-car-item__name--font-weight;
  line-height: inherit;
  
  @include t-mq($c-car-item--bp-medium) {
    font-size: $c-car-item__name--font-size-desktop;
  }
}

.c-car-item__features{
  margin-left: 0;
  margin-top: $c-car-item__features-item--margin-top;
  @include t-mq($c-car-item--bp-large) {
    float: left;
  }
  .c-bullet-list__item {
    margin-top: 0;
    margin-left: 0;
    @include sp-inline($c-car-item__features-item--sp-inline--bp-small);
    font-size: $c-car-item__features--font-size;
    @include t-mq($c-car-item--bp-large) {
      font-size: $c-car-item__features--font-size-desktop;
    }
  }

  .c-bullet-list__icon {
    color: $c-car-item__features--icon-color;
  }
}

.c-label.c-car-item__label{
  @include sp-stack($c-car-item__label--sp-stack);
  margin-left: $c-car-item__label--margin-left;
  background-color: $c-car-item__label--background-color;
  color: $c-car-item__label--color;
  width: fit-content;
  height: fit-content;
  @include sp-inset-v($c-car-item__label--sp-inset-v);
}