
/* -------------------------------------------------------------------------
 * RADIUS
 *
 * Border radius sizes
 * The values here are for sample proposes, consider add your owns
 *
 */


// Main radius

$f-radius:                    6px !default;

// Radius variants

$f-radius--none:              0 !default;
$f-radius--small:             round($f-radius * .5) !default;
$f-radius--medium:            round($f-radius * 1) !default;
$f-radius--large:             round($f-radius * 2) !default;
$f-radius--ellipsis:          50% !default;
$f-radius--rounded:           1000px !default;


// Radiuses  map

$f-radiuses: (
  "none":                     $f-radius--none,
  "small":                    $f-radius--small,
  "medium":                   $f-radius--medium,
  "large":                    $f-radius--large,
  "ellipsis":                 $f-radius--ellipsis,
  "rounded":                  $f-radius--rounded,
) !default;
