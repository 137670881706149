/* -------------------------------------------------------------------------
 * Booking preferences selector -list VARS
 *
 */

$w-booking-preferences-selector__collapse--margin-top:                                             $f-space--small !default;
$w-booking-preferences-selector__collapse--font-size:                                              $f-font-size--m !default;

$w-booking-preferences-selector--reset-margin:                                                     $f-space--none !default;

$w-booking-preferences-selector__accordion-title--font-weight:                                     600 !default;
$w-booking-preferences-selector__accordion-item--border-color:                                     $f-color__neutral-grey--semi-light !default;
$w-booking-preferences-selector__warning-messages--margin-bottom:                                  $f-space--medium !default;
$w-booking-preferences-selector__warning-messages--font-family:                                    $f-font-family__primary !default;

$w-booking-preferences-selector__option__textbox--margin-left:                                     $c-checkbox__element--container--size  !default;
$w-booking-preferences-selector__option__textbox--margin-bottom:                                   1px !default; 