/* -------------------------------------------------------------------------
* Permission settings vars
*
*/

$w-permission-settings--bp:                                                 $f-breakpoint--sm !default;

$w-permission-settings__vertical-space:                                     18px !default;

$w-permission-settings__title--margin-bottom:                               $w-permission-settings__vertical-space !default;

$w-permission-settings__title-icon--margin-bottom:                          $f-space--tiny !default;
$w-permission-settings__title-icon--margin-right:                           $f-space--tiny !default;

$w-permission-settings__heading--line-height:                               $f-line-height--small !default;


$w-permission-settings__intro--margin-bottom:                               $f-space--medium !default;

$w-permission-settings__form-fieldset-title--margin-bottom:                 10px !default;

$w-permission-settings__form-item--padding-bottom:                          $w-permission-settings__vertical-space !default;
$w-permission-settings__form-item--border-bottom:                           1px solid $f-color__neutral-grey--light !default;

$w-permission-settings__form-field-items--margin-left:                      $f-space--medium !default;
$w-permission-settings__form-field-items--v-margin:                         $w-permission-settings__vertical-space !default;
$w-permission-settings__form-field-checkbox--margin-left:                   14px !default;
$w-permission-settings__form-field-choice-list-checkbox--margin-left:       -10px !default;

$w-permission-settings__form-fieldset--margin-bottom:                       20px !default;

$w-permission-settings__extra-info--margin-bottom:                          16px !default;
$w-permission-settings__extra-info--margin-top:                             -12px !default;
$w-permission-settings__extra-info--color:                                  $f-color__neutral-grey--semi-dark !default;
$w-permission-settings__extra-info--font-size:                              $f-font-size--s !default;

$w-permission-settings__form-field-extra-info--margin-bottom:               $f-space--small !default;
$w-permission-settings__form-field-extra-info--margin-top:                  $f-space--medium !default;
