/* -------------------------------------------------------------------------
 * PROGRESS CIRCLE
 */

.c-progress-circle {
  text-align: center;
}

.c-progress-circle__body {
  margin: 0 auto;
  width: $c-progress-circle--size;
  height: $c-progress-circle--size;
  position: relative;
  background-color: $c-progress-circle--bg-color;
  box-sizing: border-box;
  border-radius: $c-progress-circle--border-radius;
  overflow: hidden;

  &:before {
    top: $c-progress-circle__border--offset;
    left: $c-progress-circle__border--offset;
    bottom: $c-progress-circle__border--offset;
    right: $c-progress-circle__border--offset;
  }

  .c-progress-circle__progress:after {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &:before,
  .c-progress-circle__progress:after {
    content: "";
    box-sizing: inherit;
    display: block;
    border-radius: $c-progress-circle--border-radius;
    border-width: $c-progress-circle__border--width;
    border-style: solid;
    border-color: $c-progress-circle__border--color;
    position: absolute;
    clip: rect(0, $c-progress-circle--size * .5, $c-progress-circle--size, 0);
    transform: rotate(.5turn) scale(1.01);
  }

  .c-progress-circle__progress {
    clip: rect(0, $c-progress-circle--size * .5, $c-progress-circle--size, 0);
    height: $c-progress-circle--size;
    width: $c-progress-circle--size;
    &:after {
      border-color: $c-progress-circle--bg-color;
    }
  }
  &[data-c-progressvar-value^="5"],
  &[data-c-progressvar-value^="6"],
  &[data-c-progressvar-value^="7"],
  &[data-c-progressvar-value^="8"],
  &[data-c-progressvar-value^="9"],
  &[data-c-progressvar-value^="100"] {
    .c-progress-circle__progress {
      &:after {
        transform: rotate(0turn) scale(1.01);
        border-color: $c-progress-circle__border--color;
      }
    }
  }
}

.c-progress-circle__indicator {
  position: absolute;
  top: $c-progress-circle__border--width;
  left: $c-progress-circle__border--width;
  background-color: $c-progress-circle__indicator--bg-color;
  width: $c-progress-circle__indicator--size;
  height: $c-progress-circle__indicator--size;
  font-weight: 700;
  border-radius: 100%;
  text-align: center;
  line-height: $c-progress-circle__indicator--size;
  transform: scale(1.05);
  z-index: $f-z-index--1;
  @include t-text-crop-disable();
}
.c-progress-circle__title {
  display: inline-block;
  @include sp-stack($c-progress-circle__title--stack);
}
