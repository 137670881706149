/* -------------------------------------------------------------------------
 * COLLECTIONS
 *
 */

.t-collections {
  .l-site-main__content {
    > [class^="fr-"] {
      @include sp-stack($t-collections__children--sp-stack);

      @include t-mq($from: $t-collections__children--bp) {
        @include sp-stack($t-collections__children--sp-stack);
      }
    }
  }
}
