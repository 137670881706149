
/* -------------------------------------------------------------------------
 * COLORS BRAND
 *
 * Define brand colors and its priorities
 */


// Main brand colors
$f-color__brand-primary:                  #ff0059 !default;
$f-color__brand-secondary:                #eabf73 !default;

// Primary shades
$f-color__brand-primary--ultra-light:     #ffe5ee !default;
$f-color__brand-primary--light:           #ffacc9 !default;
$f-color__brand-primary--semi-light:      #ff81ad !default;
$f-color__brand-primary--semi-dark:       #b10144 !default;
$f-color__brand-primary--dark:            #2d0221 !default;

// Secondary shades
$f-color__brand-secondary--ultra-light:   #f9f6f0 !default;
$f-color__brand-secondary--light:         #eee5d3 !default;
$f-color__brand-secondary--semi-light:    #e0d1af !default;
$f-color__brand-secondary--semi-dark:     #f4ad36 !default;
$f-color__brand-secondary--dark:          #e9980c !default;

// BRAND COLOR MAP

$f-colors-brand: (
  "primary":                   $f-color__brand-primary,
  "secondary":                 $f-color__brand-secondary,
  "primary--ultra-light":      $f-color__brand-primary--ultra-light,
  "primary--light":            $f-color__brand-primary--light,
  "primary--semi-light":       $f-color__brand-primary--semi-light,
  "primary--semi-dark":        $f-color__brand-primary--semi-dark,
  "primary--dark":             $f-color__brand-primary--dark,
  "secondary--ultra-light":    $f-color__brand-secondary--ultra-light,
  "secondary--light":          $f-color__brand-secondary--light,
  "secondary--semi-light":     $f-color__brand-secondary--semi-light,
  "secondary--semi-dark":      $f-color__brand-secondary--semi-dark,
  "secondary--dark":           $f-color__brand-secondary--dark,
) !default;
