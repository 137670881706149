/* -------------------------------------------------------------------------
 * Price variables
 *
 */

$m-price--font-family:                    $f-font-family__secondary !default;
$m-price__currency--font-family:          $f-font-family__secondary !default;

$m-price__currency--font-weight:          600 !default;
$m-price__currency--font-size:            $f-font-size--m !default;
$c-price__currency-sign--font-weight:     600 !default;

// Modifiers
// -----------------------------------------------

// Large modifier
$m-price__currency--large--font-weight:   600 !default;
$m-price__currency--large--line-height:   600 !default;
$m-price__currency--large--font-family:   $f-font-family__secondary !default;
$m-price__currency--large--font-size:     $f-font-size--xl !default;
$m-price--large--font-size:               $f-font-size--xxl !default;
