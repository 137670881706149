/* -------------------------------------------------------------------------
* Promoted Price Variables
*
*/

$w-promoted-price--z-index:                                     $f-z-index--2 !default;
$w-promoted-price--floating--bg-color:                          rgba($e-document__body--bgcolor, .9) !default;
$w-promoted-price--floating--shadow:                            map_get($f-shadows, "elevated") !default;
$w-promoted-price--floating--breakpoints:                       ("sm", "sml", "md") !default;
$w-promoted-price--floating--sp-inset:                          $f-space--medium !default;
$w-promoted-price--floating--sp-stack:                          $f-space--medium !default;
$w-promoted-price__yield--height:                               32px !default;
$w-promoted-price__yield--sp-stack:                             $f-space--small !default;
$w-promoted-price__yield--sp-stack-from-bp:                     $f-space--tiny !default;
$w-promoted-price__label-grayed--max-width:                     230px !default;
$w-promoted-price__label-grayed--style:                         italic !default;
$w-promoted-price__fallback-title--font-size:                   18px !default;
$w-promoted-price__fallback-title--font-weight:                 700 !default;

$w-promoted-price__sticky--gap:                                 $f-space--tiny !default;
$w-promoted-price__sticky--background-color:                    white !default;
$w-promoted-price__sticky--box-shadow:                          map_get($f-shadows, "top") !default;
$w-promoted-price__sticky--z-index:                             $f-z-index--3 !default;
$w-promoted-price__sticky--breakpoint:                          $f-breakpoint--sm !default;
$w-promoted-price__sticky--padding:                             $f-space--small !default;
$w-promoted-price__sticky-price-value--font-size:               24px !default;
$w-promoted-price__sticky-price-currency--font-size:            18px !default;
$w-promoted-price__sticky-price-label--font-size:               10px !default;

$w-promoted-price__discount-banner--background-color:           #f3efeb;
$w-promoted-price__discount-banner--color:                      #837868;
$w-promoted-price__discount-banner--padding:                    16px;
$w-promoted-price__discount-banner--margin-top:                 -28px;
$w-promoted-price__discount-banner--margin-top--react-compatible: 12px;
$w-promoted-price__discount-banner--margin-top--react-compatible--sm: -26px;
$w-promoted-price__discount-banner--margin-bottom:              24px;

$w-promoted-price__discount-banner-until-sml--margin:           -12px;
$w-promoted-price__discount-banner-until-sml--margin-bottom:    36px;