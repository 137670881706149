/* -------------------------------------------------------------------------
* Autocomplete
*
*/

.c-autocomplete {
  .c-floating-box {
    padding: 0;
    width: 100%;
  }
  .c-floating-box__header {
    height: $f-space--huge - $f-space--small;
  }
  @each $_breakpoint in $c-floating-box--breakpoints {
    @include t-mq($from: $_breakpoint) {
      .c-floating-box--floats-from#{s-core-string-breakpoint($_breakpoint)} {
        .c-floating-box__header {
          height: 1px;
        }
      }
    }
  }

  .c-floating-box__footer {
    padding-bottom: $f-space--small;
    @include sp-inset-h($f-space);
  }

  & .c-textbox__icon + .c-textbox__element {
    padding-left: $c-autocomplete--textbox-padding !important;
  }
  .c-textbox__element {
    text-indent: none;
  }
}

.c-autocomplete__results {
  @include o-list-bare();
}

.c-autocomplete__result {
  position: relative;
  cursor: pointer;
  @include sp-inset-v($c-autocomplete__result--sp-inset-v);
  @include sp-inset-h($c-autocomplete--sp-inset);

  // Border as pseudo element because do no cover full element width
  margin-top: $c-autocomplete--border-size * -1;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: $c-autocomplete--sp-inset;
    right: $c-autocomplete--sp-inset;
    background-color: $c-autocomplete--border-color;
    height: $c-autocomplete--border-size;
  }
  &:first-child {
    margin-top: 0;
    &::before {
      display: none;
    }
  }

  mark {
    color: $c-autocomplete__mark--color;
    background-color: $c-autocomplete__mark--bg-color;
  }

  &.is-selected {
    background-color: $c-autocomplete--selected--bg-color;
    [data-attribute]:first-child {
      color: $c-autocomplete--selected--color;
    }
    mark {
      color: $c-autocomplete--selected__mark--color;
      background-color: $c-autocomplete--selected__mark--bg-color;
    }
  }

  &:not(:hover) {
    background-color: inherit;
    [data-attribute]:first-child {
      color: inherit;
    }
    mark {
      color: $c-autocomplete__mark--color;
      background-color: $c-autocomplete__mark--bg-color;
    }
  }

  > *[data-attribute] {
    display: block;
    &::after {
      content: ', ';
      display: inline-block;
    }
    &:first-child,
    &:last-child {
      &::after {
        content: '';
      }
    }
    &:not(:first-child) {
      display: inline-block;
      color: $c-autocomplete--atributes--color;
      font-size: $c-autocomplete--atributes--font-size;
    }
  }

  .c-autocomplete__result-loader {
    float: right;
    margin-left: $f-space--tiny;
    @include c-loader__set-color($c-autocomplete--result-loader--color);
  }

  .live-search__category-result {
    pointer-events: none;
  }
  .c-autocomplete__result--no-result {
    pointer-events: none;
    cursor: default;
  }
}

// DataTypes
// --------------------------------------------------

@import 'c-autocomplete__destinations';
