/* -------------------------------------------------------------------------
 * BOOKING ACCOMMODATION SUMMARY
 *
 */


.w-booking-accommodation-summary__header {
  display: flex;
  flex-wrap: wrap;
  @include sp-stack($w-booking-accommodation-summary__header--sp-stack);

  @include t-mq($from: $w-booking-accommodation-summary__breakpoint-sml) {
    flex-wrap: nowrap;
  }
}

.w-booking-accommodation-summary__room-info:nth-child(odd),
.w-booking-accommodation-summary__image {
  width: $w-booking-accommodation-summary__room-info--width;
  @include sp-stack($w-booking-accommodation-summary__image--sp-stack);

  @include t-mq($from: $w-booking-accommodation-summary__breakpoint-sml) {
    width: $w-booking-accommodation-summary--left-column-width;
    align-self: flex-start;
    @include sp-inline($w-booking-accommodation-summary__image--sp-inline);
  }
}

.w-booking-accommodation-summary__room-info:nth-child(even){
  width: $w-booking-accommodation-summary__room-info--width;
  @include sp-stack($w-booking-accommodation-summary__image--sp-stack);

  @include t-mq($from: $w-booking-accommodation-summary__breakpoint-sml) {
    width: $w-booking-accommodation-summary--right-column-width;
    align-self: flex-start;
  }
}

.w-booking-accommodation-summary__rating {
  display: block;
  color: $w-booking-accommodation-summary__rating--color;
  @include sp-stack($w-booking-accommodation-summary__rating--sp-stack);
}
.w-booking-accommodation-summary__acco-name {
  text-transform: capitalize;

  @include sp-stack($w-booking-accommodation-summary__acco-name--sp-stack);
}

.w-booking-accommodation-summary__breadcrumbs {
  @include sp-stack($w-booking-accommodation-summary__breadcrumbs--sp-stack);

  .c-breadcrumbs__item {
    min-width: initial;
  }
}

.w-booking-accommodation-summary__breadcrumbs,
.w-booking-accommodation-summary__breadcrumbs .c-breadcrumbs__link {
  color: $w-booking-accommodation-summary__breadcrumbs--color;
  pointer-events: none;
}

.w-booking-accommodation-summary__booking-filters-group {
  display: flex;
  align-items: baseline;
}

.w-booking-accommodation-summary__booking-filters-item {
  display: block;

  @include sp-stack($w-booking-accommodation-summary__booking-filters-item--sp-stack);

  &::before {
    @include sp-inline($w-booking-accommodation-summary__booking-filters-item--sp-inline);
  }
}

.w-booking-accommodation-summary__rooms-list {
  display: block;

  @include t-mq($from: $w-booking-accommodation-summary__breakpoint-sml) {
    display: flex;
    flex-wrap: wrap;
  }
}

.w-booking-accommodation-summary__rooms-title {
  @include sp-stack($w-booking-accommodation-summary__rooms-title--sp-stack);
}

.w-booking-accommodation-summary__room-name {
  font-size: $w-booking-accommodation-summary__room-name--font-size;
  @include sp-stack($w-booking-accommodation-summary__room-name--sp-stack);
}

.w-booking-accommodation-summary__room-info {
  font-size: $w-booking-accommodation-summary__room-info--font-size;
}

.is-unresolved {
  .w-booking-accommodation-summary__rooms-title {
    width: $w-booking-accommodation-summary__rooms-title__unresolved--width;
  }
  
  .w-booking-accommodation-summary-text {
    @include a-unresolved($_border-radius: $w-booking-accommodation-summary--unresolved-border-radius);
  }
}
