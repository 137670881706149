
/* -------------------------------------------------------------------------
 * PRIMARY Color Scheme
 *
 */


// Color Scheme Modifiers
// -------------------------


// Lighten modifier

@if $cs-brand-primary--enabled and $cs-brand-primary--lighten--enabled {
  .cs-brand-primary--lighten {
    @include color-scheme(s-core-map-diff($cs-brand-primary, $cs-brand-primary--lighten))
  }
}

@if $cs-brand-primary--enabled and $cs-brand-primary--darker--enabled {
  .cs-brand-primary--darker {
    @include color-scheme(s-core-map-diff($cs-brand-primary, $cs-brand-primary--darker))
  }
}
