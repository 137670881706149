@mixin collection-row-condensed() {
  flex-direction: column;

  .c-collection-row__item {
    display: flex;
  }

  .c-collection-row__item-image {
    width: $c-collection-row__item-image--size;
    flex-shrink: 0;
    background-color: $c-collection-row__item-image--bg-color;
  }

  .c-collection-row__item-label.c-collection-row__item-label {
    border-radius: 0;
    width: 100%;
    position: relative;
    @include m-body($c-collection-row__item-label--material);
    //override of the font-size in order to change the font-size in other brands via variable
    font-size: $c-collection-row__item-label--font-size;
    @include sp-inset-v($c-collection-row__item-label--sp-inset);
  }

  .c-collection-row__item-content {
    color: $c-collection-row__item-content--color;
    flex-grow: 1;
    flex-basis: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $c-collection-row__item-content--bg-color;
    padding-right: $c-collection-row__item-content--padding-right;
  }
}

@mixin collection-row-neutral() {

  // Neutral styles
  .c-collection-row__item--collection {
    .c-collection-row__item-content {
      color: $c-collection-row__item-content--color;
      background-color: $c-collection-row__item-content--bg-color-translucent;
      border-bottom-style: solid;
      border-bottom-width: $c-collection-row-expanded--border-bottom-width;
    }
  }
}

@mixin collection-row-tiled() {
  flex-wrap: wrap;
  margin-left: -.5 * $c-collection-row-expanded__item-sp-inline;
  margin-right: -.5 * $c-collection-row-expanded__item-sp-inline;
  justify-content: flex-start;

  > * {
    flex-grow: 0;
    margin-left: .5 * $c-collection-row-expanded__item-sp-inline;
    margin-right: .5 * $c-collection-row-expanded__item-sp-inline;

    @include sp-stack($c-collection-row-expanded__item-sp-stack);
    width: 100%;

    @include t-mq($from: $c-collection-row--expanded-breakpoints-tablet) {
      width: calc(50% - #{$c-collection-row-expanded__item-sp-inline});
    }

    @include t-mq($from: $c-collection-row--expanded-breakpoints-desktop) {
      width: calc(33% - #{$c-collection-row-expanded__item-sp-inline});
    }
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax($c-collection-row-expanded__item--min-width, 1fr));
    grid-gap: $c-collection-row-expanded__item-sp-stack $c-collection-row-expanded__item-sp-inline;
    margin-left: 0;
    margin-right: 0;
    justify-items: center;

    > * {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
      min-width: initial;
      max-width: initial;

      &.c-collection-row__item {
        width: 100%;
      }
    }
  }
}

@mixin collection-row-expanded() {
  .c-collection-row__item {
    overflow: hidden;
    max-width: $c-collection-row__item--max-width;

    &:hover {
      .c-collection-row__item-image .c-img {
        transition: transform $f-duration--fast $f-easing--base;
        transform: scale(1.025);
      }
    }

    &:not(.c-collection-row__item--collection) {
      .c-collection-row__item-content {
        color: $c-collection-row__item-content--color;
        background-color: $c-collection-row__item-content--bg-color-translucent;
      }
    }
  }

  .c-collection-row__item-image {
    width: 100%;

    .c-img {
      transform: scale(1);
      transition: transform $f-duration--normal $f-easing--base;
    }
  }

  .c-collection-row__item-label {
    left: $c-collection-row-expanded__item-label--position;
    top: $c-collection-row-expanded__item-label--position;
    bottom: auto;
    right: auto;
  }

  .c-collection-row__item-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .c-collection-row__item-icon {
    display: none;
  }

  // Neutral styles
  &.c-collection-row--neutral {
    @include collection-row-neutral();
  }

  // Hide labels
  &.c-collection-row--label-hidden {
    .c-collection-row__item-label {
      display: none
    }
  }
}

// Reset Styles to avoid excessive output on breakpoints
// -----------------------------------------------------
@mixin collection-row-expanded-to-condensed() {
  margin-left: 0;
  margin-right: 0;

  > * {
    flex-basis: auto !important;
    max-width: none !important;
  }

  @supports (display: grid) {
    display: flex;
    grid-gap: 0 0;
  }

  .c-collection-row__item {
    margin-left: 0;
    margin-right: 0;

    &:hover {
      .c-collection-row__item-image .c-img {
        transition: none;
        transform: none;
      }
    }
  }

  .c-collection-row__item-label {
    left: 0;
    top: 0;
  }

  .c-collection-row__item-content {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
  }

  .c-collection-row__item-icon {
    display: block;
  }

  &.c-collection-row--neutral {
    .c-collection-row__item--collection {
      .c-collection-row__item-content {
        background-color: $c-collection-row__item-content--bg-color;
        border-bottom-width: 0;
      }
    }
  }
}
