.fr-content-usps {
  margin-top: $fr-content-usps--margin-top;
  margin-bottom: $fr-content-usps--margin-bottom;
  @include sp-inset-h($fr-content-usps--sp-inset-h);

  @include t-mq($from: $fr-content-usps--bp) {
    margin-bottom: $fr-content-usps--margin-bottom--from-mq;
    
    .c-slider__content {
      text-align: center;
      margin-top: -$fr-content-usps--item--margin-top;
      margin-left: -$fr-content-usps--item--margin-left;
    }
  
    .c-slider__item {
      width: auto;
      margin-top: $fr-content-usps--item--margin-top;
      margin-left: $fr-content-usps--item--margin-left;
    }
  }

  .tns-nav{
    display: none;
  }

  .c-bullet-list__item {
    justify-content: center;
  }

  .c-bullet-list__icon {
    color: $fr-content-usps--icon-color;
  }
}