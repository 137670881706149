.c-button-group {
  display: flex;
  width: $c-button-group--width;

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  a.c-btn,
  .c-btn {
    flex: $c-button-group--flex;
    min-width: $c-button-group__btn--min-width;
    border-color: $c-button-group--border-color;
    color: $c-button-group__text--color;
  }

  &:not(.c-button-group--chips) {
    a.c-btn,
    .c-btn {
      &:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        padding-left: $c-button-group__btn--reduced-padding;
      }

      &:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: none;
        padding-right: $c-button-group__btn--reduced-padding;

        &:active + .c-btn,
        &.is-active + .c-btn {
          border-left-color: $c-button-group--selected--border-color;
        }
      }

      &:hover {
        background-color: $c-button-group--hover--bg-color;
      }

      &:active,
      &.is-active {
        background-color: $c-button-group--selected--bg-color;
        border-color: $c-button-group--selected--border-color;
        color: $c-button-group__text--selected--color;
        box-shadow: $c-button-group--selected--shadow;
      }

      &:focus {
        box-shadow: $c-button-group--selected--shadow;
      }
    }
  }
  .c-btn.c-btn--flat {
    min-width: $c-button-group__btn--min-width;
    border: none;
    color: $c-button-group__text--color;

    &:not(:first-child) {
      padding-left: $c-button-group__btn--reduced-padding;
    }

    &:not(:last-child) {
      padding-right: $c-button-group__btn--reduced-padding;
    }

    &:hover {
      background-color: $c-button-group--hover--bg-color;
      color: $c-button-group__text--selected--color;
    }

    &:active,
    &.is-active {
      background-color: $c-button-group--selected--bg-color;
      border-bottom: $c-button-group__c-btn--flat__border-bottom--width solid $c-button-group--selected--border-color;
      color: $c-button-group__text--selected--color;
      box-shadow: $c-button-group--selected--shadow;
    }
  }
}

// Proportional variant
.c-button-group--proportional {
  a.c-btn,
  .c-btn {
    flex-basis: auto;
  }
}

// Variants
// Chips
.c-button-group--chips {
  display: flex;
  gap: $c-button-group--chips--gap;
  width: $c-button-group--width;

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .c-btn {
    flex: $c-button-group--flex;
    min-width: $c-button-group__btn--min-width;
    border-color: $c-button-group--border-color;
    color: $c-button-group__text--color;

    &:hover {
      background-color: $c-button-group--hover--bg-color;
    }

    &:active,
    &.is-active {
      background-color: $c-button-group--selected--bg-color;
      border-color: $c-button-group--selected--border-color;
      color: $c-button-group__text--selected--color;
      box-shadow: $c-button-group--selected--shadow;
    }

    &:focus {
      box-shadow: $c-button-group--selected--shadow;
    }
  }

  .c-btn.c-btn--flat {
    min-width: $c-button-group__btn--min-width;
    border: none;
    color: $c-button-group__text--color;

    &:hover {
      background-color: $c-button-group--hover--bg-color;
      color: $c-button-group__text--selected--color;
    }

    &:active,
    &.is-active {
      background-color: $c-button-group--selected--bg-color;
      border-bottom: $c-button-group__c-btn--flat__border-bottom--width solid $c-button-group--selected--border-color;
      color: $c-button-group__text--selected--color;
      box-shadow: $c-button-group--selected--shadow;
    }
  }
}
