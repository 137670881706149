/* -------------------------------------------------------------------------
* TOOLTIP VARS
*
*/

$c-tooltip--first-breakpoint:                                 "xs" !default;

$c-tooltip--direction-breakpoints:                            ($c-tooltip--first-breakpoint, "sml", "md") !default;

$c-tooltip--bg-color:                                         $f-color__state-info--dark !default;
$c-tooltip--max-width:                                        240px !default;
$c-tooltip--max-width--until-first-bp:                        200px !default;
$c-tooltip--inset:                                            $f-space--small !default;
$c-tooltip--color:                                            $f-color__neutral-white !default;
$c-tooltip--border-radius:                                    4px !default;
$c-tooltip--font-size:                                        $f-font-size--s !default;
$c-tooltip--transition-duration:                              $f-duration--fast !default;
$c-tooltip--font-size:                                        $f-font-size--s !default;
$c-tooltip--z-index:                                          $f-z-index--2 !default;
$c-tooltip--line-height:                                      $f-line-height--tiny !default;

$c-tooltip__arrow--width:                                     $f-space--tiny !default;
$c-tooltip__arrow--offset:                                    ($c-tooltip__arrow--width * 2) - 1 !default;
$c-tooltip__arrow--transition-duration:                       $c-tooltip--transition-duration / 3 !default;
$c-tooltip__arrow--transition-delay:                          $c-tooltip--transition-duration !default;

$c-tooltip--transition-delay:                                 $c-tooltip__arrow--transition-duration !default;

$c-tooltip__icon--color:                                      $f-color__state-info !default;
$c-tooltip__icon--color-hover:                                $f-color__state-info--semi-dark !default;
$c-tooltip__icon--transition-duration:                        $f-duration--fast !default;

//IE version vars

$c-tooltip__icon--ie11-before--left:                          calc(100% + 10px) !default;
$c-tooltip__icon--ie11-before--min-width:                     240px !default;

$c-tooltip--directions: (
  "top":(
    "horizontal-position-property": right,
    "vertical-position-property": top,
    "horizontal-position-value": 50%,
    "vertical-position-value": 0,
    "transform-origin": bottom,
    "translate-before": translate(50%, calc(-100% - #{$c-tooltip__arrow--offset})),
    "translate-after": translate(50%, -100%),
    "border-color": $c-tooltip--bg-color transparent transparent transparent,
  ),
  "right":(
    "horizontal-position-property": right,
    "vertical-position-property": top,
    "horizontal-position-value": 0,
    "vertical-position-value": 50%,
    "transform-origin": left,
    "translate-before": translate(calc(100% + #{$c-tooltip__arrow--offset}), -50%),
    "translate-after": translate(100%, -50%),
    "border-color": transparent $c-tooltip--bg-color transparent transparent,
  ),
  "bottom":(
    "horizontal-position-property": right,
    "vertical-position-property": bottom,
    "horizontal-position-value": 50%,
    "vertical-position-value": 0,
    "transform-origin": top,
    "translate-before": translate(50%, calc(100% + #{$c-tooltip__arrow--offset})),
    "translate-after": translate(50%, 100%),
    "border-color": transparent transparent $c-tooltip--bg-color transparent,
  ),
  "left":(
    "horizontal-position-property": left,
    "vertical-position-property": top,
    "horizontal-position-value": 0,
    "vertical-position-value": 50%,
    "transform-origin": right,
    "translate-before": translate(calc(-100% - #{$c-tooltip__arrow--offset}), -50%),
    "translate-after": translate(-100%, -50%),
    "border-color": transparent transparent transparent $c-tooltip--bg-color,
  )
) !default;
