
.c-autocomplete--destinations {

  .c-autocomplete__results {

    // Add Levels indentation (not on results from search)
    &:not(.c-autocomplete__results--from-search) {
      [data-destination-type="region"] {
        padding-left: $c-autocomplete--sp-inset + $f-space--tiny;
      }
    }

  }
  // We only want to apply these styles when getting the actual results but no in the "no results" or "searching" mode of the autocomplete
  .c-autocomplete__result:not(.c-autocomplete__result--no-result):not(.c-autocomplete__result--searching) {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }
  .c-autocomplete__result {
    // Increase padding right to reserve icon space
    &[data-destination-type="country"],
    &[data-destination-type="region"],
    &[data-destination-type="city"],
    &[data-destination-type="accommodation"] {
     padding-right: calc(#{($c-autocomplete--sp-inset + ($f-space--tiny * 2))} + 2em);
    }

    // Countries better border
    &[data-destination-type="country"] {
      @include sp-inset-v($c-autocomplete__result--sp-inset-v * 1.5);
    }
    &[data-destination-type="region"] + [data-destination-type="country"] {
      &::before {
        background-color: $c-autocomplete--color;
      }
    }

    // Remove trailing commas from default auto-complete
    > *[data-attribute] {
      &::after {
        content: "";
      }
    }

    // Caption inline (overrides inline block on first attribute from default auto-complete)
    > *[data-attribute="caption"] {
      display: block;
      font-size: $c-autocomplete__caption--font-size--destinations;
      color: $c-autocomplete__caption--color--destinations;
      padding-left: $c-autocomplete__caption--padding-left--destinations;
      @include sp-stack($c-autocomplete__caption--space);
      @include sp-inline($c-autocomplete__caption--space);
    }

    // Count inline, and between parenthesis
    [data-attribute="count"] {
      color: $c-autocomplete--selected--color;
      display: inline-block;

      &::before { content: "("; }
      &::after { content: ")"; }
      // Clear-fix trick if next Sibling is another attribute, forces to break the line
      + [data-attribute] {
        &::before {
          content: "";
          display: block;
          margin-top: -.6em;
        }
      }
    }

    // Discrete size for count and parent
    [data-attribute="count"],
    [data-attribute="parent"] {
      font-size: $f-font-size--xs;
    }


    // Parents
    .c-autocomplete__result-parents {
      color: $cs-default--text--discrete;
      display: block;
      width: 100%;
      padding-left: $c-autocomplete__caption--padding-left--destinations;

      // Concat parents with trailing commas
      [data-attribute="parent"] + [data-attribute="parent"] {
        margin-left: -.25em;
        &::before { content: ", "; }
      }
    }

    // Icon
    .m-icon {
      position: absolute;
      top: 50%;
      left: $c-autocomplete--sp-inset;
      transform: translateY(-50%);
      font-size: $c-autocomplete__icon--font-size--destinations;
      color: $c-autocomplete__icon--color--destinations;
    }
  }

}
