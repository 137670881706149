/* -------------------------------------------------------------------------
* Calendar Variables
*
*/

$c-calendar--today:                                     false !default;

$c-calendar--bg-color:                                  $f-color__neutral-white !default;
$c-calendar--days-week:                                 7 !default;
$c-calendar--security-gap:                              $f-space--small !default;
$c-calendar--day-size:                                  35px !default;
$c-calendar--day-margin:                                2px !default;
$c-calendar--width:                                     ((($c-calendar--day-size + ($c-calendar--day-margin * 2)) * $c-calendar--days-week) + $c-calendar--security-gap) !default;
$c-calendar--height:                                    28px !default;
$c-calendar--height-time:                               40px !default;

$c-calendar--easing:                                    $f-easing--excite !default;
$c-calendar--duration:                                  $f-duration--normal !default;

$c-calendar--font-size:                                 $f-font-size--s !default;

$c-calendar__weekdays--color:                           $f-color__neutral-grey--semi-dark !default;
$c-calendar__weekdays--font-weight:                     400 !default;

$c-calendar__month--color:                              $f-color__neutral-grey--ultra-dark !default;
$c-calendar__month--color--hover:                       $f-color__neutral-grey--semi-dark !default;
$c-calendar__month--font-family:                        $f-font-family__secondary !default;
$c-calendar__month--font-weight:                        700 !default;
$c-calendar__month--font-size:                          $f-font-size--m !default;
$c-calendar__month--text-transform:                     uppercase !default;

$c-calendar__day--color:                                $f-color__neutral-grey--ultra-dark !default;
$c-calendar__day--font-weight:                          700 !default;
$c-calendar__day--hover--bg-color:                      $f-color__brand-primary--ultra-light !default;
$c-calendar__day--hover--font-weight:                   700 !default;
$c-calendar__day--disabled--color:                      $f-color__neutral-grey--semi-dark !default;
$c-calendar__day--disabled--font-weight:                400 !default;
$c-calendar__day--selected--color:                      $f-color__neutral-white !default;
$c-calendar__day--selected--bg-color:                   $f-color__brand-primary !default;
$c-calendar__day--selected--font-size:                  $f-font-size--m !default;
$c-calendar__day--selected--font-weight:                700 !default;
$c-calendar__day--selected--hover--color:               $f-color__brand-primary--light !default;
$c-calendar__day--selected--pressed--bg-color:          $f-color__brand-primary--dark !default;
$c-calendar__day--disabled--selected--color:            $f-color__neutral-white !default;
$c-calendar__day--disabled--selected--bg-color:         $f-color__neutral-grey--semi-light !default;
$c-calendar__day--disabled--selected--font-size:        $c-calendar--font-size !default;
$c-calendar__day--disabled--selected--font-weight:      400 !default;

$c-calendar__next-prev--color:                          $f-color__neutral-grey--semi-light !default;
$c-calendar__next-prev--font-weight:                    400 !default;

$c-calendar__week-number--color:                        $f-color__brand-primary--semi-light !default;
$c-calendar__week-number--font-size:                    $f-font-size--xxs !default;
$c-calendar__week-number--font-weight:                  400 !default;
$c-calendar__week-number--visible-from:                 $f-breakpoint--xs !default;
$c-calendar__week-number--width:                        ((($c-calendar--day-size + ($c-calendar--day-margin * 2)) * ($c-calendar--days-week + 1)) + $c-calendar--security-gap) !default;

