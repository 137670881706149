/* -------------------------------------------------------------------------
 * INLINE
 *
 * To separate inline items.
 *
 * TIP:
 * You can modify the amount of generated spaces by overriding the $sp-inline__spaces variable.
 *
 */

// Space selector output
// --------------------------------------------------

@if ($sp-inline__enabled) {
  @each $_spacing-name, $_spacing-value in $sp-inline__spaces {
    .sp-inline--#{$_spacing-name} {
      @include sp-inline($_spacing-value);
    }
  }
}
