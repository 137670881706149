@mixin fr-quick-search__background($padding) {
  background-color: $fr-quick-search__background-color;
  border-radius: $fr-quick-search__border-radius;
  padding: $padding;
}

.fr-quick-search {
  .w-quick-search__publications {
    display: none;
  }

  .fr-quick-search__content {
    @include t-mq($until: $fr-quick-search__tablet--bp) {
      margin-bottom: 0;
    }

    @include t-mq($from: $fr-quick-search__tablet--bp) {
      padding-top: $fr-quick-search__content--padding-top-from-tablet-bp;
    }

    @include t-mq($from: $fr-quick-search__desktop--bp) {
      padding-top: $fr-quick-search__content--padding-top-from-desktop-bp;
    }
  }

  .w-quick-search {
    @include fr-quick-search__background($fr-quick-search__desktop--padding-from-tablet-bp);

    @include t-mq($from: $fr-quick-search__tablet--bp) {
      padding: 0;
      background-color: initial;
    }

    @include t-mq($from: $fr-quick-search__desktop--bp) {
      @include fr-quick-search__background($fr-quick-search__desktop--padding-from-desktop-bp);
      margin-top: $fr-quick-search__desktop--margin-top;
    }
  }

  .w-quick-search__submit {
    @include t-mq($until: $fr-quick-search__tablet--bp) {
      margin-bottom: 0;
    }
  }

  .w-quick-search__filters {
    @include t-mq($from: $fr-quick-search__tablet--bp) {
      @include fr-quick-search__background($fr-quick-search__filters--padding);
      margin-bottom: $fr-quick-search__filters--margin-bottom;
    }

    @include t-mq($from: $fr-quick-search__desktop--bp) {
      padding: 0;
      margin-bottom: 0;
      background-color: initial;
    }
  }

  .w-filter:first-child {
    @include t-mq($from: $fr-quick-search__tablet--bp) {
      margin-left: 0;
    }

    @include t-mq($from: $fr-quick-search__desktop--bp) {
      margin-left: $fr-quick-search__filter--margin-left;
    }
  }
}

