/* -------------------------------------------------------------------------
 * CARD COLLECTION
 */

.c-card-collection__usp  {
  flex-grow: 2;
  @include sp-stack($c-card-collection__usp--sp-stack);
  .c-bullet-list__icon {
    color: $c-card-collection__usp--color;
  }
}

.c-card-collection__btn:first-of-type {
  @include sp-stack($c-card-collection__btn--sp-stack);
}

.c-card-collection .c-card__body {
  display: flex;
  flex-direction: column;
}
