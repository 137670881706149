.fr-destination-page__party-group-statistics-list {
  display: flex;
  flex-wrap: wrap;
}

.fr-destination-page__party-group-statistics-item {
  margin-right: $fr-destination-page__party-group-statistics--margin-right;
  margin-bottom: $fr-destination-page__party-group-statistics--margin-bottom;
  font-weight: normal;
}
