/* -------------------------------------------------------------------------
* Favorite Variables
*
*/

$c-favorite-collector--bp:                            "sml" !default;
$c-favorite-collector__bubble--color:                 $f-color__neutral-white !default;
$c-favorite-collector__bubble--bg-color:              $f-color__brand-primary !default;
$c-favorite-collector__bubble--font-weight:           normal !default;
$c-favorite-collector__bubble--top:                   -.25em !default;
$c-favorite-collector__bubble--left:                  1.4em !default;
$c-favorite-collector__bubble--font-size:             0.875em !default;

$c-favorite-btn--color:                               $f-color__state-danger !default;
$c-favorite-btn--bg-color:                            $f-color__neutral-white !default;
$c-favorite-btn--bg-color--hover:                     $f-color__neutral-grey--light !default;
$c-favorite-btn--duration:                            $f-duration--normal !default;
$c-favorite-btn--size:                                2em !default;
$c-favorite-btn--border-radius:                       10em !default;
$c-favorite-btn--shadow:                              $f-shadow--elevated rgba($f-shadow__color, $f-shadow__opacity--elevated) !default;

$c-favorite-btn__heart--font-size:                    1rem !default;

$c-modal__favorites-signup-btn--color:                $f-color__state-info !default;
$c-modal__favorites-footer__before--margin:           $f-space !default;
$c-modal__favorites-footer__before--margin-bottom:    $f-space--tiny !default;
$c-modal__favorites-footer__before--border:           1px solid $f-color__neutral-grey--semi-light !default;

$w-search__favorites--max-width:                      30rem !default;
$w-search__favorites__header--margin-bottom:          round($f-space * 0.75) !default;
$w-search__favorites__body--margin-bottom:            round($f-space * 0.75) !default;
$w-search__favorites-login-btn--width:                60% !default;