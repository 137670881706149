/* -------------------------------------------------------------------------
 * Error 500
 */

.t-error__container {
@include sp-stack-children($t-error__container--sp-stack);
@include t-mq($from: $t-error__mobile-bp) {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  flex-direction: row-reverse;
  margin-top: $t-error__container--margin-top;
}
}

.t-error__item {
@include t-mq($from: $t-error__mobile-bp) {
  flex-basis: 50%;
}
}

.t-error__item--illustration {
  @include sp-inset-h($t-error__item--illustration--inset-h);
  @include t-mq($from: $t-error__mobile-bp) {
    @include sp-inset-h(0);
    padding-left: $t-error__items--separation
  }

  .t-error__illustration {
  @include t-mq($from: $t-error__mobile-bp) {
    max-width: 350px;
  }
    display: block;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
  }
}

.t-error__item--content {
  text-align: center;
  @include sp-stack-children($t-error__container--sp-stack);
  .t-error__title {
  @include sp-stack( $t-error__title--sp-stack);
  }
  .t-error__box {
    @include sp-inset($t-error__items--inset);
  }
  .t-error__options {
    font-size: $t-error__options--font-size;
    text-align: left;

    .c-bullet-list__icon {
      font-size: $t-error__options--icon-size;
      margin-top: $t-error__options--icon-margin-top;
    }

    .c-bullet-list__text {
      padding-left: $t-error__list-item--padding-left;
      text-transform: none;
      line-height: $t-error__container--line-height;
    }
  }
}