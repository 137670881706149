/* -------------------------------------------------------------------------
 * booking usps
 *
 */

.w-booking-usps__shown-as-block{
  .w-content-usps__icon, .w-content-usps__usp-block-title{
    color: $w-booking-usps__shown-as-block--color;
  }
  .w-content-usps__usp-block-subtitle{
    color: $w-booking-usps__shown-as-block--subtitle-color;
  }
  &.w-content-usps.w-content-usps--usp-block{
    justify-content: center;
  }
}