/* -------------------------------------------------------------------------
* RATIO MIXINS
*
*/


// Object as a mixin
// -------------------------------------------

@mixin o-ratio($_ratio: 1) {
  position: relative;
  overflow: hidden;

  &::before {
    display: block;
    width: 100%;
    padding-top: $_ratio * 100%;
    content: '';
  }
}

@mixin o-ratio__content() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

// Ratio modifiers mixin
// -------------------------------------------

@mixin o-ratio--override($ratio) {
  &::before {
    padding-top: $ratio * 100%;
  }
}

// Unset as mixin
// --------------------------------------------------

@mixin o-ratio--unset() {
  position: inherit;

  &::before {
    display: inherit;
    width: auto;
    padding-top: inherit;
    content: inherit;
  }
}

@mixin o-ratio__content--unset() {
  position: inherit;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  height: auto;
  width: auto;
}
