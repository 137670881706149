/* -------------------------------------------------------------------------
 * HOMEPAGE VARS
 *
 */

$t-homepage--bp:                                                    $l-site-main--bp !default;
$t-homepage__c-title--sp-stack:                                     $f-space--medium !default;

// HOMEPAGE USP VARS

$fr-content-usps--margin-top:                                        $f-space--medium !default;
$fr-content-usps--margin-bottom:                                     $f-space--medium !default;
$fr-content-usps--margin-bottom--from-mq:                            $f-space--large !default;
$fr-content-usps--sp-inset-h:                                        $f-space--small !default;
$fr-content-usps--bp:                                                $f-breakpoint--sml !default;
$fr-content-usps--icon-color:                                        $f-color__state-success !default;
$fr-content-usps--item--margin-left:                                 $f-space--medium !default;
$fr-content-usps--item--margin-top:                                  $f-space--small !default;

//Highlighted video

$fr-homepage__video--breakpoint--tablet:                             $f-breakpoint--sm !default;
$fr-homepage__video--max-width:                                      75% !default;

//HOMEPAGE PRODUCT ADS

$fr-product-ads--margin-bottom:                                      $f-space--large !default;