/* -------------------------------------------------------------------------
 * TABS
 *
 */

 .c-tabs__nav{
  .c-tabs__nav-item.is-open .c-btn__text{
    font-weight: $c-tabs__nav-button--is-open--font-weight;
  }
 }
.c-tabs__nav-item--featured .c-tabs__nav-button:active {
  color: $c-tabs__nav-featured-button--active-color ;
}

.c-tabs__nav-button:active {
  color: $c-tabs__nav-button--active-color;
}

.c-tabs__nav--secondary {
  .c-tabs__nav-item.is-open .c-tabs__nav-button,
  .c-tabs__nav-list {
    background-color: transparent;
  }
}

