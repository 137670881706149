// collection row has different style and font.

.c-collection-row__item {
  border-radius: $c-collection-row__item--rounded-corners;
  overflow: hidden;
  transform: translateZ(0);
}


@each $_breakpoint in $c-collection-row--expanded-breakpoints {
  @include t-mq($from: $_breakpoint) {
    .c-collection-row--expanded-from#{s-core-string-breakpoint($_breakpoint)} {
      @include collection-row-expanded--eliza();
    }
  }
}

.c-collection-row .c-collection-row__item-title {
  font-weight: $c-collection-row__item-title--font-weight;
}
