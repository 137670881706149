
/* -------------------------------------------------------------------------
 * FONTS
 *
 * Font families & fallback
 * The values here are for sample proposes, consider add your owns
 *
 */

// Web fonts variable. Used to preload load them
$web-fonts: ();

// Font imports

@import "foundations__font-families--tahoma";
@import "foundations__font-families--verdana";
@import "foundations__font-families--arial";

// Main font families

$f-font-family__primary:          $f-font-family__tahoma, $f-font-family__tahoma--fallback !default;
$f-font-family__secondary:        $f-font-family__verdana, $f-font-family__verdana--fallback !default;
$f-font-family__accent:           $f-font-family__arial, $f-font-family__arial--fallback !default;

$f-font-family__primary--crop:    $f-font-family__tahoma--crop !default;
$f-font-family__secondary--crop:  $f-font-family__verdana--crop !default;
$f-font-family__accent--crop:     $f-font-family__arial--crop !default;

// Font families map

$f-font-families: (
  "primary":    $f-font-family__primary,
  "secondary":  $f-font-family__secondary,
  "accent":     $f-font-family__accent,
) !default;

$f-font-families--crops: (
  "primary":    $f-font-family__primary--crop,
  "secondary":  $f-font-family__secondary--crop,
  "accent":     $f-font-family__accent--crop,
) !default;
