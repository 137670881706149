/* -------------------------------------------------------------------------
 * PROMOPAGE VARS
 *
 */

$t-content-generic--bp:                                                         $l-site-main--bp !default;
$t-content-generic__c-title--sp-stack:                                          $f-space--medium !default;
$t-content-generic__content-usps--margin-top:                                   -$f-space--medium !default;

$fr-right-to-object--promotion-page__c-box--disabled--until-bp:                 "xs" !default;
$fr-right-to-object--promotion-page__c-box--inset--until-bp:                    $l-site__wrapper-medium--inset !default;

//Highlighted video

$fr-content-generic__video--breakpoint--tablet:                                 $f-breakpoint--sm !default;
$fr-content-generic__video--max-width:                                          75% !default;
