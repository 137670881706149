/* -------------------------------------------------------------------------
* Textbox Variables
*
*/

$c-textbox--color:                                                       $e-form-elements__field--color !default;
$c-textbox--sp-inset-h:                                                  $e-form-elements--sp-inset-h !default;
$c-textbox--sp-inset-v:                                                  $e-form-elements--sp-inset-v !default;
$c-textbox--padding-right--icon-right:                                   $f-space--large !default;
$c-textbox--border-radius:                                               $e-form-elements__field--border-radius !default;
$c-textbox--duration:                                                    $f-duration--normal !default;
$c-textbox--easing:                                                      $f-easing--linear !default;

$c-textbox__wrapper--bg-color:                                           $e-form-elements__field--bg-color !default;

$c-textbox__element--bg-color:                                           $e-form-elements__field--bg-color !default;
$c-textbox__element--placeholder-color:                                  $e-form-elements__placeholder--color !default;
$c-textbox__element--font-family:                                        $e-form-elements__field--font-family !default;
$c-textbox__element--font-size:                                          $e-form-elements__field--font-size !default;
$c-textbox__element--text-indent--with-icon:                             $f-space--medium !default;
$c-textbox__element--border-size:                                        $e-form-elements__field--border--size !default;
$c-textbox__element--border-style:                                       $e-form-elements__field--border--type !default;
$c-textbox__element--border-color:                                       $e-form-elements__field--border-color !default;
$c-textbox__element--border-color--hover:                                $e-form-elements--hover--border-color !default;
$c-textbox__element--shadow--focus:                                      $f-shadow--glow $e-form-elements--focus--border-color !default;
$c-textbox__element--border--focus:                                      $e-form-elements--focus--border-color !default;

$c-textbox__icons--z-index:                                              $f-z-index--1 !default;

$c-textbox__icon--position:                                              $f-space--small !default;
$c-textbox__icon--font-size:                                             $f-font-size--l !default;
$c-textbox__icon--color:                                                 $e-form-elements__icon--color !default;

$c-textbox__clear--right:                                                0 !default;
$c-textbox__clear--color:                                                $e-form-elements__clear--color !default;
$c-textbox__clear--color--hover:                                         $e-form-elements__clear--color--hover !default;
$c-textbox__clear--space-inline:                                         $f-space--tiny !default;
$c-textbox__clear--font-size:                                            $f-font-size--xs !default;

// States
// -----------------------------------------

// Disabled
$c-textbox__disabled--opacity:                                           $e-form-elements--disabled--opacity !default;
$c-textbox__disabled--bg-color:                                          $e-form-elements--disabled--bg-color !default;

// Success
$c-textbox__element--success--border-color:                              $e-form-elements--success--border-color !default;
$c-textbox__element--success--shadow--focus:                             $f-shadow--glow $e-form-elements--success--border-color !default;


// Warning
$c-textbox__element--warning--border-color:                              $e-form-elements--warning--border-color !default;
$c-textbox__element--warning--shadow--focus:                             $f-shadow--glow $e-form-elements--warning--border-color !default;

// Error
$c-textbox__element--error--border-color:                                $e-form-elements--error--border-color !default;
$c-textbox__element--error--shadow--focus:                               $f-shadow--glow $e-form-elements--error--border-color !default;


// Modifiers
// -----------------------------------------

// Highlighted
$c-textbox__element--highlighted--color:                                 $e-form-elements__inner-element--color !default;

// Messages
$c-textbox__messages--font-size:                                         $f-font-size--s !default;
$c-textbox__messages--margin-top:                                        $f-space--tiny !default;
$c-textbox__message--error--color:                                       $e-form-elements--error--border-color !default;

// Password icon
// -----------------------------------------

$c-textbox__icon-password--font-size:                                    22px !default;
$c-textbox__icon-password--color:                                        currentColor!default;

// Password requirements
// -----------------------------------------

$c-textbox__password-requirements-container--bp:                         $f-breakpoint--xs !default;
$c-textbox__password-requirements-container--column-gap:                 $f-space--medium !default;
$c-textbox__password-requirements-container--row-gap:                    2px !default;
$c-textbox__password-requirements-container--margin-top:                 $f-space--small !default;
$c-textbox__password-requirements-container--right-margin:               $f-space--medium !default;

$c-textbox__password-requirement--font-size:                             $f-font-size--s !default;
$c-textbox__password-requirement--margin-bottom:                         4px !default;
$c-textbox__password-requirement--color:                                 $f-color__neutral-grey--semi-dark !default;
$c-textbox__password-requirement--flex-basis:                            calc(50% - #{$f-space--small}) !default;

$c-textbox__password-requirement-checked--color:                         $f-color__state-success !default;

$c-textbox__password-requirement-icon--left:                             -20px !default;

