/* -------------------------------------------------------------------------
* HOMEPAGE VARS
*
*/

$t-homepage__l-site-main__top--margin-bottom:                        $f-space--medium !default;

$fr-acco-lister__c-title--margin-bottom:                             $f-space--medium + $f-space--small !default;
$fr-acco-lister--padding-bottom:                                     16px !default;

$fr-content-usps--icon-color:                                        $f-color__state-success !default;

$fr-link-list__c-card__title--font-family:                           $f-font-family__primary !default;
$fr-link-list__c-card__title--font-weight:                           700 !default;

$t-homepage__content-usps--margin-top:                               $f-space--large + $f-space--small !default;
$fr-content-usps__content-usps--margin-top:                          -$f-space--large !default;