/* -------------------------------------------------------------------------
* COLLECTION TEASER VARS
*
*/

$c-collection-teaser--tablet-breakpoint:                            'sml' !default;
$c-collection-teaser--desktop-breakpoint:                           'lg' !default;

$c-collection-teaser--padding-top:                                   18px !default;
$c-collection-teaser--border-radius:                                 5px !default;

$c-collection-teaser__label--z-index:                                10 !default;

$c-collection-teaser__description--vertical-padding:                 $f-space--medium + $f-space--small !default;
$c-collection-teaser__description--horizontal-padding:               $f-space--medium !default;

$c-collection-teaser__title----sp-stack:                             $f-space--small !default;

$c-collection-teaser__usps--padding:                                 $f-space--medium !default;
$c-collection-teaser__usps--background-color:                        $f-color__neutral-grey--ultra-light !default;
$c-collection-teaser__usps-icon--color:                              $f-color__state-success !default;

// LARGE VARIANT

$c-collection-teaser--large__img--width:                             320px !default;

$c-collection-teaser--large__label--left-position:                   $c-collection-teaser--large__img--width / 2 !default;

$c-collection-teaser--large__usps--tablet-width:                     50% !default; 
$c-collection-teaser--large__usps--desktop-width:                    25% !default; 
$c-collection-teaser--large__usps--padding:                          $f-space--small !default; 

$c-collection-teaser--large__title--line-clamp:                      1 !default;

$c-collection-teaser--large__container-main--line-clamp: 4 !default;
