/* -------------------------------------------------------------------------
 * BOOKED SERVICES LIST VARS
 *
 */

$w-booked-services-list__breakpoint:                                     $f-breakpoint--md !default;

$w-booked-services-list__side-drawer--width:                             100% !default;
$w-booked-services-list__side-drawer__close-button--sp-v:                $f-space--small !default;
$w-booked-services-list__side-drawer__close-button--sp-h:                $f-space--medium !default;

$w-booked-services-list__content--border-size:                           1px !default;
$w-booked-services-list__content--border-type:                           solid !default;
$w-booked-services-list__content--border-color:                          $f-color__neutral-grey--semi-light !default; 

$w-booked-services-list__side-drawer--border-radius:                     $f-radius--large !default;

$w-booked-services-list__item-selected__after--width:                    $f-space--small !default;
$w-booked-services-list__item-selected__after--right:                    calc(-1 * #{$w-booked-services-list__item-selected__after--width}) !default;
$w-booked-services-list__item--sp-v:                                     $f-space--none !default;
$w-booked-services-list__item-button--sp-h:                              $f-space--small !default;

$w-booked-services-list__no-service-selected--bg-color:                  $f-color__state-info--dark !default;
$w-booked-services-list__no-service-selected--padding:                   $f-space--medium !default;
$w-booked-services-list__no-service-selected--color:                     $f-color__neutral-white !default;
$w-booked-services-list__no-service-selected--border-radius:             $f-radius--medium !default;
$w-booked-services-list__no-service-selected--width:                     300px !default;
$w-booked-services-list__no-service-selected--arrow-size:                20px !default;
$w-booked-services-list__no-service-selected--arrow-top:                 calc(50% - (#{$w-booked-services-list__no-service-selected--arrow-size}/2)) !default;
$w-booked-services-list__no-service-selected--arrow-left:                calc(-1 * (#{$w-booked-services-list__no-service-selected--arrow-size} / 2)) !default; 
$w-booked-services-list__no-service-selected--shadow:                    $f-shadow--elevated !default; 
$w-booked-services-list__no-service-selected--shadow-color:              $f-shadow__color !default;
$w-booked-services-list__no-service-selected--shadow-opacity:            $f-shadow__opacity !default;

// Colors
$w-booked-services-list--background-color:                               $f-color__neutral-white !default;
$w-booked-services-list__item-selected--bg-color:                        $f-color__state-info--ultra-light !default;
$w-booked-services-list-service-not-booked--bg-color:                    $f-color__neutral-grey--ultra-light !default;
$w-booked-services-list-service-booked__icon--color:                     $f-color__state-info !default;

$w-booked-services-list__side-drawer-trigger--border-size:                1px !default;
$w-booked-services-list__side-drawer-trigger--border-type:                solid !default;
$w-booked-services-list__side-drawer-trigger--border-color:               $f-color__neutral-grey--semi-light !default; 
$w-booked-services-list__side-drawer-trigger--sp-v:                       $f-space--small !default;
$w-booked-services-list__side-drawer-trigger--sp-h:                       $f-space--medium !default;
$w-booked-services-list--font-weight:                                     600 !default;
$w-booked-services-list--font-size:                                       $f-font-size--m !default;
$w-booked-services-list__side-drawer-trigger--shadow:                     $f-shadow--elevated !default; 
$w-booked-services-list__side-drawer-trigger--shadow-color:               $f-shadow__color !default;
$w-booked-services-list__side-drawer-trigger--shadow-opacity:             $f-shadow__opacity !default;
$w-booked-services-list__side-drawer-trigger--color-selected:             $f-color__state-info !default;
$w-booked-services-list__side-drawer-trigger--min-height:                 $f-space--large + $f-space--small !default;

$w-booked-services-list__trigger-button--margin-top:                      calc(-1 * #{$f-space--small}) !default; 
$w-booked-services-list__trigger-button__icon--color:                     $f-color__state-info !default;
$w-booked-services-list__trigger-button__icon--padding:                   $f-space--small !default;
$w-booked-services-list__trigger-button__icon--font-size:                 $f-font-size--l !default;
$w-booked-services-list__trigger-button--height:                          $f-space--medium !default;

$w-booked-services-list__backdrop-z-index:                                $f-z-index--3 - 1 !default; 