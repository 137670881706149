.w-refund__modal{
  .w-refund__buttons{
    padding-top: $f-space--small;
    text-align: right;
  }
}

.w-refund__response{
  margin-bottom: $f-space--medium;
  text-align: center;
  .w-refund__buttons{
    text-align: center;
  }
  .w-refund__confirmed__icon, .w-refund__confirmed__title{
    color: $w-refund-successful__icon--color;
    margin-bottom: $f-space--small;
  }

  .w-refund__error__icon, .w-refund__error__title{
    margin-bottom: $f-space--small;
  }
  .w-refund__error__icon{
    color: $w-refund-error__icon--color;
  }
}

.w-refund__result-error, .w-refund__result-success{
  &.is-hidden{
    display: none;
  }
}

.w-refund__filled-data-box {
  text-align: left;
  padding: $f-space--small;
  margin-bottom: $f-space--medium;
  margin-top: $f-space--small;
  & ul {
    list-style-type: none;
    margin-left: 0;
  }

  & li {
    margin-bottom: $f-space--tiny;
  }
}

.w-refund__terms-conditions {
  padding-left: $f-space--medium;
  padding-top: $f-space--small;
}

.w-refund__back-button {
  padding-left: 0;
  padding-top: 0;
}

.w-refund__form {
  margin-bottom: $f-space--medium;
}

.w-refund__form-fieldset {
  padding-top: $f-space--small;
}

.w-refund__form-fieldset{
  .c-textbox__icon.is-hidden{
    display: none;
  }
}