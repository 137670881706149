/* -------------------------------------------------------------------------
 * TABS
 */

$c-tabs__nav--bg-color:                                               $f-color__neutral-grey--ultra-light !default;

$c-tabs__nav-button--font-weight:                                     400 !default;
$c-tabs__nav-button--color:                                           $f-color__brand-primary--dark !default;
$c-tabs__nav-button--color--open:                                     $f-color__brand-primary !default;
$c-tabs__nav-button--color--hover:                                    $f-color__brand-primary--dark !default;
$c-tabs__nav-button--bg-color--hover:                                 $f-color__neutral-grey--semi-light !default;
$c-tabs__nav-button--bg-color--open:                                  $f-color__neutral-grey--light !default;
$c-tabs__nav-button--active-color:                                    $f-color__neutral-grey--dark !default;
$c-tabs__nav-featured-button--active-color:                           $f-color__neutral-white !default;

$c-tabs__nav-item--bg-color:                                          $f-color__neutral-grey--ultra-light !default;
$c-tabs__nav-item--bg-color--open:                                    $f-color__neutral-white !default;

$c-tabs__swipe-button--icon-color:                                    $f-color__neutral-grey--dark !default;
$c-tabs__swipe-button--icon-color--hover:                             $f-color__neutral-grey !default;

$c-tabs__content--bg-color:                                           $f-color__neutral-white !default;

$c-tabs__nav-button--is-open--font-weight:                            600 !default;

// Variants
// ----------------------------------------

// Secondary
$c-tabs__nav-button--color--hover--secondary:                         $f-color__brand-primary !default;
$c-tabs__nav-button--color--open--secondary:                          $f-color__brand-primary !default;

$c-tabs__nav--secondary__swipe-btn--color:                            $f-color__neutral-white !default;
