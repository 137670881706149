/* -------------------------------------------------------------------------
 * PROMOTION IMAGE VARS
 */

$c-promotion-image__content--body-heading:                          map_get($m-heading__mod-sizes, "tiny") !default;

$c-promotion-image__grouped--image--secondary--material:            map_get($m-heading__mod-sizes, "large") !default;

$c-promotion-image__grouped__title--secondary--material:            map_get($m-heading__mod-sizes, "large") !default;
$c-promotion-image__grouped__title--secondary--font-size:           $f-font-size--xxl !default;

$c-promotion-image__contents--sp-inner-stack:                       $f-space--small + $f-space--tiny !default;

$c-promotion-image--huge__content-body--margin-top:                 $f-space--large !default;

$c-promotion-image__label--breakpoint:                              $f-breakpoint--sm !default;
$c-promotion-image__label--font-size:                               $f-font-size--xl !default;
$c-promotion-image__label--font-size-from-bp:                       $f-font-size--m !default;

$c-promotion-image__title--material--huge--desktop:                 map_get($m-heading__mod-sizes, "large") !default;
