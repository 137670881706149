// ACCORDION
// ---------------------------------

.c-accordion__item {
  border-top: $c-accordion__item--border-width $c-accordion__item--border-style $c-accordion__item--border-color;

  &:last-child {
    border-bottom: $c-accordion__item--border-width $c-accordion__item--border-style $c-accordion__item--border-color;
  }
}

.c-accordion__item-heading {
  cursor: pointer;
  position: relative;
  transition: color $c-accordion__item-heading--duration;

  @include sp-inset-stretch($c-accordion--sp-inset);

  @include t-mq($from: $c-accordion--bp) {
    @include sp-inset($c-accordion--sp-inset);
  }

  &:hover {
    color: $c-accordion__item-heading--color--hover;

    .c-accordion__item-trigger {
      background-color: $c-accordion__item-trigger--bg-color--hover;
    }
  }
  &.in-active {
    pointer-events: none;
    .c-accordion__item-title {
      pointer-events: none;
    }
  }
}

.c-accordion__item-title,
.c-accordion__item-subtitle {
  padding-right: $c-accordion__item-title--padding-right;
}

.c-accordion__item-title {
  text-transform: $c-accordion__item-title--text-transform;
  font-weight: $c-accordion__item-title--font-weight;
}

// The class is duplicated in order to increase the specificity.
// This is needed to avoid the overwrite of the variable in the c-collapse__trigger
// Which is compiled later because of the alphabetical order
.c-accordion__item-trigger.c-accordion__item-trigger {
  position: absolute;
  right: $c-accordion--sp-inset--until-bp;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 0;
  line-height: normal;
  width: $c-accordion__item-trigger--size;
  height: $c-accordion__item-trigger--size;
  background-color: $c-accordion__item-trigger--bg-color;
  border-radius: $c-accordion__item-trigger--border-radius;
  overflow: hidden;
  @include sp-inset($c-accordion__item-trigger--sp-inset);

  @include t-mq($from: $c-accordion--bp) {
    right: $c-accordion--sp-inset;
  }

  .m-icon {
    color: $c-accordion__item-trigger--color;
  }

  .m-icon::before {
    font-size: $c-accordion__item-trigger--icon-size;
  }
}

.c-accordion__item-collapse.in {
  overflow: visible;
}

.c-accordion__item-content {
  padding-bottom: $c-accordion--sp-inset;
  padding-top: $c-accordion__item-content--padding-top;
  @include sp-inset-h($c-accordion--sp-inset *.5);

  @include t-mq($from: $c-accordion--bp) {
    @include sp-inset-h($c-accordion--sp-inset);
  }

  > *:last-child {
    margin-bottom: 0;
  }

  &:empty {
    display: none;
  }

}

.c-accordion__item-title-icon {
  position: absolute;
  left: $c-accordion--sp-inset--until-bp;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 0;
  line-height: normal;
  width: $c-accordion__item-title-icon--size;
  height: $c-accordion__item-title-icon--size;
  overflow: hidden;

  @include t-mq($from: $c-accordion--bp) {
    left: $c-accordion--sp-inset;
  }

  &.m-icon::before {
    font-size: $c-accordion__item-title-icon--size;
  }

  & + .c-accordion__item-title,
  & + .c-accordion__item-title + .c-accordion__item-subtitle {
    padding-left: $c-accordion__item-title-icon--size + $c-accordion--sp-inset--until-bp;

    @include t-mq($from: $c-accordion--bp) {
      padding-left: $c-accordion__item-title-icon--size + $c-accordion--sp-inset;
    }
  }
}

// With subtitle
// ------------------
.c-accordion__item-subtitle {
  margin-top: $c-accordion__item-subtitle--margin-top;
  font-weight: $c-accordion__item-subtitle--font-weight;
}

// Size
// ------------------
@import "c-accordion--small";
