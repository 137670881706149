/* -------------------------------------------------------------------------
 * REVEAL OPACITY MIXINS
 *
 */


// Animation as a mixin
// --------------------------------------------------

@mixin a-reveal-opacity($_duration: $a-reveal-opacity__duration) {
  opacity: 0;
  transition-property: opacity;
  transition-duration: $_duration;
  transition-timing-function: $a-reveal-opacity__easing-out;
}

@mixin a-reveal-opacity--in() {
  opacity: 1;
  transition-timing-function: $a-reveal-opacity__easing-in;
}


// Duration modifiers output
// --------------------------------------------------

@mixin a-reveal-opacity__mod-duration($_duration-name: "normal", $_duration-value: $f-duration--normal) {
  .a-reveal-opacity--duration-#{$_duration-name} {
    transition-duration: $_duration-value;
  }
}

// Unset as mixin
// --------------------------------------------------

@mixin a-reveal-opacity--unset() {
  opacity: initial;
  transition-property: initial;
  transition-duration: initial;
  transition-timing-function: initial;
}
