/* -------------------------------------------------------------------------
 * Weather table
 */

.c-weather-table {
}

.c-weather-table__wrapper {
  display: flex;
  border: $c-weather-table--border-width $c-weather-table--border-style $c-weather-table--border-color;
  background: $c-weather-table--border-color;
  align-items: flex-end;
  overflow: hidden;

  @include t-mq($from: $c-weather-table--bp-small) {
    max-width: $c-weather-table--max-width--bp-small;
  }

  @include t-mq($from: $c-weather-table--bp) {
    font-size: $c-weather-table--font-size--bp-medium;
    flex-direction: column;
    max-width: none;
    align-items: inherit;
  }

  @include t-mq($from: $c-weather-table--bp-large) {
    font-size: $c-weather-table--font-size;
  }
}

.c-weather-table__months,
.c-weather-table__values {
  flex-direction: column;

  @include t-mq($from: $c-weather-table--bp) {
    display: flex;
    flex-direction: row;
  }

  > *:nth-child(even) {
    background-color: $c-weather-table--background-even;
  }
  > *:nth-child(odd) {
    background-color: transparent;
  }
}

.c-weather-table__month,
.c-weather-table__legend,
.c-weather-table__value {
  text-align: center;
  height: $c-weather-table__cell--height;
  line-height: $c-weather-table__cell--line-height;
  @include sp-inset($c-weather-table__cell--sp-inset);

  @include t-mq($from: $c-weather-table--bp) {
    @include sp-inset($c-weather-table__cell--sp-inset--bp-medium);
    height: auto;
  }

  @include t-mq($from: $c-weather-table--bp-large) {
    @include sp-inset($c-weather-table__cell--sp-inset);
  }
}

.c-weather-table__legend {
  height: auto;
}

.c-weather-table__month {
  text-align: left;

  @include t-mq($from: $c-weather-table--bp) {
    text-align: center;
    width: $c-weather-table__cell--width--from-md;
  }
}

.c-weather-table__month-name {
  font-weight: $c-weather-table__month-name--font-weight;
}

.c-weather-table__month-name--short {
  display: none;
  text-transform: $c-weather-table__month-name--short--text-transform;

  @include t-mq($from: $c-weather-table--bp) {
    display: inherit;
  }
}

.c-weather-table__month-name--long {
  @include t-mq($from: $c-weather-table--bp) {
    display: none;
  }
}

.c-weather-table__legend {
  font-weight: $c-weather-table__legend--font-weight;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;

  @include t-mq($from: $c-weather-table--bp) {
    width: $c-weather-table__legend--width--from-md;
    text-align: left;
  }
}

.c-weather-table__values {
  flex-grow: 1;
}

.c-weather-table__value {
  font-family: $c-weather-table__value--font-family;

  @include t-mq($from: $c-weather-table--bp) {
    width: $c-weather-table__cell--width--from-md;
  }
}

// Variants
// ---------------------------------------------------

// Vertical
.c-weather-table--vertical {
  .c-weather-table__wrapper {
    display: flex;
    align-items: flex-end;

    @include t-mq($from: $c-weather-table--bp-small) {
      max-width: $c-weather-table--max-width--bp-small;
    }

    @include t-mq($from: $c-weather-table--bp) {
      font-size: $c-weather-table--font-size;
      flex-direction: row;
    }
  }

  .c-weather-table__months,
  .c-weather-table__values {
    @include t-mq($from: $c-weather-table--bp) {
      display: block;
      flex-direction: column;
    }
  }

  .c-weather-table__month,
  .c-weather-table__legend,
  .c-weather-table__value {
    @include t-mq($from: $c-weather-table--bp) {
      @include sp-inset($c-weather-table__cell--sp-inset);
      height: $c-weather-table__cell--height;
    }
  }
  .c-weather-table__legend {
    height: auto;
    @include t-mq($from: $c-weather-table--bp) {
      height: auto;
    }
  }

  .c-weather-table__month {
    @include t-mq($from: $c-weather-table--bp) {
      text-align: left;
      width: auto;
    }
  }

  .c-weather-table__month-name--short {
    @include t-mq($from: $c-weather-table--bp) {
      display: none;
    }
  }

  .c-weather-table__month-name--long {
    @include t-mq($from: $c-weather-table--bp) {
      display: inherit;
    }
  }

  .c-weather-table__legend {
    @include t-mq($from: $c-weather-table--bp) {
      width: auto;
      text-align: center;
    }
  }

  .c-weather-table__value {
    @include t-mq($from: $c-weather-table--bp) {
      width: auto;
    }
  }
}

