/* -------------------------------------------------------------------------
 * PRICE TABLE
 */

.c-price-table {
  @include t-mq($from: $c-price-table--bp) {
    padding-right: $c-price-table--padding-right--from-bp;
  }

  &::before {
    display: none;
    content: '0';
  }
}

.c-price-table__navigation-wrapper {
  position: relative;  /* added to support older browsers */
  position: sticky;
  top: 0;
  background-color: $f-color__neutral-white;
  z-index: 10;
  transition: top $f-easing--in $f-duration;

  &::after {
    content: '';
    position: absolute;
    right: $c-price-table__cell--border-width--hover;
    top: 0;
    bottom: 0;
    width: $c-price-table__cell--border-width;
    display: block;
    background-color: $c-price-table__navigation-wrapper--after-color;
  }

  &.is-hidden {
    @supports (position: sticky) {
      transition: top $f-easing--out $f-duration;
      top: -80px;
    }
  }
}

.c-price-table__navigation {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  color: $c-price-table__navigation--color;
  z-index: $c-price-table__navigation--z-index;
  transition: background-color $c-price-table__navigation--duration;

  @include t-mq($from: $c-price-table--bp) {
    width: $c-price-table__navigation--size;
    height: $c-price-table__navigation--size;
    border: $c-price-table__navigation--border-width $c-price-table__navigation--border-style $c-price-table__navigation--border-color;
    border-radius: $c-price-table__navigation--border-radius;
    overflow: hidden;
  }

  &[disabled] {
    pointer-events: none;
  }

  &:hover {
    @include t-mq($from: $c-price-table--bp) {
      background-color: $c-price-table__navigation--bg-color--hover;
    }
  }

  .c-btn__icon {
    margin-top: $c-price-table__navigation--vertical-adjustment;

    @include t-mq($from: $c-price-table--bp) {
      height: $c-price-table__navigation--size;
      padding-left: $c-price-table__navigation--size;
    }

    &::before {
      font-size: $c-price-table__navigation--font-size;
    }
  }
}

.c-price-table__navigation--left {
  transform: translate(100%, -50%);

  @include t-mq($from: $c-price-table--bp) {
    transform: translate(-50%, -50%);
  }

  .c-btn__icon {
    margin-left: $c-price-table__navigation--horizontal-adjustment--left;

    &::before {
      @include t-mq($from: $c-price-table--bp) {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
      }
    }
  }
}

.c-price-table__navigation--right {
  @include t-mq($from: $c-price-table--bp) {
    transform: translate(150%, -50%);
  }

  .c-btn__icon {
    &::before {
      right: 0;
      left: auto;

      @include t-mq($from: $c-price-table--bp) {
        right: 50%;
        transform: translateX(50%);
      }
    }
  }
}

.c-price-table__row {
  @include sp-inset-h($c-price-table__row--inset-h);
}

.c-price-table__row:not(:last-child), .c-price-table__room:last-child .c-price-table__row:last-child {
  border-bottom: $c-price-table__row--border-width $c-price-table__row--border-style $c-price-table__row--border-color;
}

.c-price-table__row--heading {
  border-bottom: 0;
  position: relative;
}

.c-price-table__cell {
  position: relative;
  width: $c-price-table__cell--width;
  text-align: $c-price-table__cell--text-align;
  border-right: $c-price-table__cell--border-width $c-price-table__cell--border-style $c-price-table__cell--border-color;
  /*@include sp-inset-squish($c-price-table__cell--inset-squish);*/
  @include sp-inset(1.5 * $c-price-table__cell--inset-squish, $c-price-table__cell--inset-squish);

  &:before {
    @include t-mq($from: $c-price-table--bp) {
      content: '';
      position: absolute;
      top: -1 * ($c-price-table__row--inset-v + $c-price-table__cell--border-width--hover);
      left: -1 * $c-price-table__cell--border-width--hover;
      right: -1 * $c-price-table__cell--border-width--hover;
      bottom: -1 * ($c-price-table__row--inset-v + $c-price-table__cell--border-width--hover);
      display: block;
      border: $c-price-table__cell--border-width--hover $c-price-table__cell--border-style--hover $c-price-table__cell--border-color--hover;
      pointer-events: none;
      opacity: 0;
    }
  }

  .c-price {
    vertical-align: middle;
  }

  .c-price__number {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }

  .c-price__value {
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
  }
}

.c-price-table__cell:hover {
  cursor: default;
}

.c-price-table__cell--price:hover {
  cursor: pointer;
}

.c-price-table__room-info:hover {
  cursor: pointer;
}

.c-price-table__cell--date {
  &.is-highlighted {
    position: relative;
    z-index: $c-price-table__cell--z-index--hover;

    &:before {
      opacity: 1;
      top: 0;
      bottom: 0;
      border-bottom: none;
    }
  }

  &[data-position] {
    display: none;
  }
}

.c-price-table__cell--price {
  color: $c-price-table__cell--price--color;

  * {
    color: $e-document__body--color;
  }

  letter-spacing: 0;
  font-size: $c-price-table__cell--price--font-size;
  font-weight: $c-price-table__cell--price--font-weight;

  &:not([data-id]) {
    pointer-events: none;
    color: $c-price-table__cell--disabled-color;
  }

  &[data-position] {
    display: none;
  }

  &:active,
  &.is-active {
    cursor: pointer;
    background-color: $c-price-table__cell--bg-color--active;
    position: relative;
    cursor: auto;
    opacity: 1;

    &::after {
      position: absolute;
      top: 100%;
      left: 50%;
      z-index: 1;
      width: 0;
      height: 0;
      pointer-events: none;
      content: " ";
      border: solid transparent;
    }

    &::after {
      margin-left: -1 * $c-price-table__cell-selected;
      border-width: $c-price-table__cell-selected;
      border-top-color: $c-price-table__cell--bg-color--active;
    }
  }

  &:hover {
    &:before {
      background-color: $c-price-table__cell--bg-color--hover;
      opacity: 1;
    }
  }

  &:hover, &.is-active {
    .c-price-table__onrequest {
      text-transform: uppercase;
      font-size: $c-price-table__cell--onrequest-font-size;
      font-weight: 400;
      white-space: nowrap;
      position: absolute;
      left: -50%;
      right: -50%;
      text-align: center;
    }
  }

  &:active, &.is-active {
    .c-price-table__onrequest {
      color: $c-price-table__cell--onrequest-color--active;
    }
    &:before {
      background-color: $c-price-table__cell--bg-color--active;
    }
  }

  &.is-highlighted:not(:hover) {
    &:before {
      opacity: 1;
      border-top: none;
      border-bottom: none;
    }
  }
}

.c-price-table__cell--participants {
  width: auto;
  text-align: $c-price-table__cell--participants--text-align;

  @include t-mq($until: $c-price-table--bp) {
    @include sp-inset-h($c-price-table__cell--participants--inset-h--until-bp);
  }

  @include t-mq($from: $c-price-table--bp) {
    width: auto;
  }
}

.c-price-table__room:not(:last-child) {
  .c-price-table__row:last-child {
    .c-price-table__cell.is-highlighted {
      &:before {
        bottom: -1 * ($c-price-table__row--inset-v + $c-price-table__room-info--margin-top);
      }
    }
  }
}

.c-price-table__row.is-highlighted {
  .c-price-table__cell--participants,
  .c-price-table__cell--price:not(:hover) {
    &:before {
      opacity: 1;
      border: $c-price-table__cell--border-width--hover $c-price-table__cell--border-style--hover $c-price-table__cell--border-color--hover;
    }
  }

  .c-price-table__cell--participants {
    &:before {
      border-right: none;
    }
  }

  .c-price-table__cell--price {
    &:hover ~ .c-price-table__cell--price:before {
      border: 0;
    }
    &:not(:hover) {
      &:before {
        border-left: none;
        border-right: none;
      }
    }
  }
}

.c-price-table__row.is-highlighted {
  .c-price-table__cell--participants,
  .c-price-table__cell--price {
    &:before {
      top: 0;
      bottom: 0;
    }
  }
}

.c-price-table__cell--participants-fine-detail {
  display: inline-block;
  @include t-mq($from: $c-price-table--bp) {
    display: none;
  }
}

.c-price-table__cell--participants-coarse-detail {
  display: none;
  @include t-mq($from: $c-price-table--bp) {
    display: inline-block;
  }
}

.c-price-table__onrequest {
  text-transform: uppercase;
  color: $c-price-table__cell--onrequest-color;
  font-size: $c-price-table__cell--onrequest-font-size;
  font-weight: 400;
  white-space: nowrap;
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  text-align: center;
}

.c-price-table__legend_wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.c-price-table__legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $f-space--medium;
}

.c-price-table__discount_bullet {
  width: $c-price-table__discount-bullet-size;
  height: $c-price-table__discount-bullet-size;
  border-radius: 50%;
}

.c-price-table__legend_item_bullet {
  margin-left: $f-space--small;
  margin-right: $f-space--small;
  flex-shrink: 0;
}

.c-price-table__legend_item_description {
  font-size: $f-font-size--xs;
  margin-right: $f-space--medium;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1;
}

.c-price-table__discount_bullet_placement {
  position: absolute;
  bottom: 45px;
  right: 5px;
}

.c-price-table__day-name {
  position: relative;
  color: $c-price-table__day-name--color;
  text-transform: $c-price-table__day-name--text-transform;
  letter-spacing: $c-price-table__day-name--letter-spacing;
  z-index: $c-price-table__cell--z-index--hover;
  @include sp-stack($c-price-table__day-name--sp-stack);
}

.c-price-table__day-month {
  position: relative;
  color: $c-price-table__day-month--color;
  font-weight: $c-price-table__day-month--font-weight;
  letter-spacing: $c-price-table__day-month--letter-spacing;
  z-index: $c-price-table__cell--z-index--hover;
  font-size: $c-price-table__day-month--font-size;
}

.c-price-table__cell--title {
  position: relative;
  text-align: $c-price-table__cell--title--text-align;
  color: $c-price-table__cell--title--color;
  font-weight: $c-price-table__cell--title--font-weight;
  border-right: 0;
  width: auto;
  padding-left: $c-price-table__cell--title--padding-left;
  padding-right: $c-price-table__cell--title--padding-right;
  display: flex;
  align-items: center;

  @include t-mq($until: $c-price-table--bp) {
    @include sp-inset-h($c-price-table__cell--title--inset-h--until-bp);
  }

  @include t-mq($from: $c-price-table--bp) {
    width: auto;
  }
}

.c-price-table__title-icon {
  font-size: $c-price-table__title-icon--font-size;
}

.c-price-table__title-text {
  font-size: $c-price-table__title-text--font-size;
  margin-left: $c-price-table__title-text--margin-left;
}

.c-price-table__title-period,
.c-price-table__title-period-extra-info {
  display: block;
  @include t-mq($from: $f-breakpoint--sm) {
    display: inline-block;
  }
}

.c-price-table__title-period-extra-info {
  font-size: $c-price-table__title-period-extra-info--font-size;
}

.c-price-table__participant {
  position: relative;
  z-index: $c-price-table__cell--z-index--hover;
  @include sp-inline($c-price-table__participant--sp-inline);

  &[data-participants="0"] {
    display: none;
  }

  .m-icon {
    @include sp-inline($c-price-table__participant--sp-inline / 3);
  }
}

.c-price-table__room-info {
  position: relative;
  background-color: $c-price-table__room-info--bg-color;
  @include sp-inset($c-price-table__room-info--sp-inset);

  @include t-mq($from: $c-price-table--bp) {
    @include sp-inset-squish($c-price-table__room-info--sp-inset);
  }
}

.c-price-table__room-title {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: baseline;
  padding-right: $c-price-table__room-title--padding-right;
  @include sp-inline($c-price-table__room-title--sp-inset);

  @include t-mq($from: $c-price-table--bp) {
    padding-right: $c-price-table__room-title--padding-right--from-bp;
  }

  &:hover{
    color: $c-price-table__room-title--color--hover;
  }
}

.c-price-table__room-icon {
  font-size: $c-price-table__room-icon--font-size;
  @include sp-inline($c-price-table__room-title--sp-inline);

  &::before {
    vertical-align: $c-price-table__room-icon--v-align;
  }

  @include t-mq($until: $c-price-table--bp) {
    display: none;
  }
}

.c-price-table__room-name {
  @include sp-inline($c-price-table__room-title--sp-inline);
  @include t-text-crop-disable();
}

.c-price-table__room-description {
  @include t-text-crop-disable();
}

.c-price-table__room-trigger {
  display: block;
  margin-right: 0;
  text-align: right;
  color: $c-price-table__room-trigger--color;
  position: absolute;
  right: -1 * $c-price-table__room-title--sp-inset;
  top: -1 * $c-price-table__room-title--sp-inset;
  bottom: -1 * $c-price-table__room-title--sp-inset;
  left: -1 * $c-price-table__room-title--sp-inset;
  width: calc(100% + #{2 * $c-price-table__room-title--sp-inset});

  &.c-collapse__trigger {
    height: auto;
  }

  &:hover {
    color: $c-price-table__room-trigger--color;
  }

  .c-collapse__trigger-text {
    display: inline-block;

    @include t-mq($until: $c-price-table--bp) {
      display: none;
    }

    @include t-mq($from: $c-price-table--bp) {
      max-width: $c-price-table__room-title--padding-right--from-bp - $c-price-table__room-title--padding-right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .c-collapse__trigger-icon {
    display: inline-block;
    font-size: $c-price-table__room-trigger-icon-font-size;

    @include t-mq($until: $c-price-table--bp) {
      position: absolute;
      right: 0;
      top: $c-price-table__room-title--sp-inset;
    }
  }
}

.c-price-table__room-content {
  @include sp-inset-v($c-price-table__room-content--sp-inset-v);
}

.c-price-table__room-characteristic {
  @include sp-stack($c-price-table__room-characteristic--sp-stack);
  font-size: $c-price-table__room-characteristic--font-size;
}

.c-price-table__room-characteristic-single {
  @include sp-inset-h($c-price-table__room-content--sp-inset-v);
}

.c-price-table[data-compact-mode="true"] {
  .c-price-table__cell--price, .c-price-table__cell--date {
    width: $c-price-table__cell--compact-width;
    @include sp-inset(1.5 * $c-price-table__cell--inset-squish, $c-price-table__cell--inset-squish * 0.5);
  }
}

%c-price-table__footer-wrapper {
  margin-top: $w-dd-grid__footer-wrapper--margin-top;

  display: flex;
  flex-direction: column;
  @include t-mq($until: $w-dd-grid--bp) {
    flex-direction: column-reverse;
  }
}

%c-price-table__footer-caption  {
  max-width: $w-dd-grid__footer-caption--max-width;

  @include t-mq($from: $w-dd-grid--bp) {
    margin-left: auto;
    text-align: right;
  }
}

%c-price-table__footer-caption-title {
  font-weight: $w-dd-grid__footer-caption-title--font-weight;
  color: $w-dd-grid__footer-caption-title--color;
  margin-bottom: $w-dd-grid__footer-caption-title--margin-bottom;
}

%c-price-table__footer-caption-description {
  color: $w-dd-grid__footer-caption-description--color;
}

%c-price-table__footer-extra-info {
  color: $c-price-table__footer-extra-info--color;
  font-style: $c-price-table__footer-extra-info--font-style;
  font-weight: $c-price-table__footer-extra-info--font-weight;
  margin-top: $c-price-table__footer-extra-info--margin-top;
}

.c-price-table__footer-wrapper {
  @extend %c-price-table__footer-wrapper;
}

.c-price-table__footer-caption  {
  @extend %c-price-table__footer-caption;
}

.c-price-table__footer-caption-title {
  @extend %c-price-table__footer-caption-title;
}

.c-price-table__footer-caption-description {
  @extend %c-price-table__footer-caption-description;
}

.c-price-table__footer-extra-info {
  @extend %c-price-table__footer-extra-info;
}

.c-price-table__cell-threshold-standard .c-price__value {
  color: $w-dd-grid__cell-threshold-standard--color;
}

// Cell display mixin
@include c-price-table__cells--visible(0, 1, $_isCompactMode: true);
@include c-price-table__cells--visible(2, 2, "xs", $_isCompactMode: true);
@include c-price-table__cells--visible(3, 3, "sm", $_isCompactMode: true);
@include c-price-table__cells--visible(4, 6, "sml", $_isCompactMode: true);
@include c-price-table__cells--visible(7, 8, "md", $_isCompactMode: true);
@include c-price-table__cells--visible(9, 11, "lg", $_isCompactMode: true);
@include c-price-table__cells--visible(12, 13, "xl", $_isCompactMode: true);

@include c-price-table__cells--visible(0, 1);
@include c-price-table__cells--visible(2, 2, "xs");
@include c-price-table__cells--visible(3, 3, "sm");
@include c-price-table__cells--visible(4, 4, "sml");
@include c-price-table__cells--visible(5, 5, "md");
@include c-price-table__cells--visible(6, 8, "lg");
@include c-price-table__cells--visible(9, 9, "xl");
