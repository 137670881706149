/* -------------------------------------------------------------------------
 * bookings comparison table VARS
 *
 */
$w-booking-comparison-table--bp-medium:                                    $f-breakpoint--sm !default;
$w-booking-comparison-table--bp-large:                                     $f-breakpoint--md !default;

$w-booking-comparison-table--font-family:                                  $f-font-family__primary !default;
$w-booking-comparison-table--font-style:                                   normal !default;
$w-booking-comparison-table--font-weight:                                  600 !default;

$w-booking-comparison-table-features--width-mobile:                        140px !default;
$w-booking-comparison-table-features--width-tablet:                        200px !default;

$w-booking-comparison-table-features--bg-color:                            $f-color__neutral-white !default;
$w-booking-comparison-table-features--pd-bottom:                           102px !default;

$w-booking-comparison-table__top-info--height:                             80px !default;
$w-booking-comparison-table__top-info--font-size:                          $f-font-size--s !default;
$w-booking-comparison-table__top-info--line-height:                        150% !default;
$w-booking-comparison-table__top-info--letter-spacing:                     0.3px !default;

$w-booking-comparison-table-columns--width:                                350px !default;
$w-booking-comparison-table-columns--mg-bottom:                            10px !default;

$w-booking-comparison-table-product--width-mobile:                         50px !default;
$w-booking-comparison-table-product--width-tablet:                         150px !default;
$w-booking-comparison-table-product--margin-left:                          $f-space--tiny !default;
$w-booking-comparison-table-product--margin-right:                         $f-space--tiny !default;

$w-booking-comparison-table-features-list-item--min-height:                70px !default;
$w-booking-comparison-table-features-list-item--font-size:                 $f-font-size--xs !default;
$w-booking-comparison-table-features-list-item--font-weight:               500 !default;
$w-booking-comparison-table-features-list-item--line-height:               120% !default;
$w-booking-comparison-table-features-list-item--letter-spacing:            0.26px !default;
$w-booking-comparison-table-features-list-item--gap:                       8px !default;
$w-booking-comparison-table-features-list-item--sp-inset:                  $f-space--tiny !default;
$w-booking-comparison-table-features-list-item--padding-top:               $f-space--small !default;

$w-booking-comparison-table__button-group--height:                         90px !default;
$w-booking-comparison-table__button-group--sp-inset-mobile:                2px !default;
$w-booking-comparison-table__button-group--sp-inset-tablet:                10px !default;
$w-booking-comparison-table__button-group--color:                          #837868 !default;
$w-booking-comparison-table__more-info-button--font-size:                  $f-font-size--xxs !default;

$w-booking-comparison-table__label--border-radius:                         6px !default;
$w-booking-comparison-table__label--padding:                               8px !default;

$w-booking-comparison-table--color:                                        #362f2f !default;
$w-booking-comparison-table-features--event-bg-color:                      #fbf9f6 !default;
$w-booking-comparison-table-product--border:                               2px !default;
$w-booking-comparison-table-product--border-color:                         #d9d6d1  !default;
$w-booking-comparison-table-product--border-radius:                        8px !default;
$w-booking-comparison-table-product--shadow:                               0px 2px 6px 0px !default;
$w-booking-comparison-table-product--shadow-color:                         $f-color__neutral-black !default;
$w-booking-comparison-table-product--shadow-opacity:                       0.08 !default;
$w-booking-comparison-table-product-price--color:                          #b5b0ab !default;
