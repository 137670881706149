/* -------------------------------------------------------------------------
 * Account booking
 *
 */

.fr-account-booking-top {
  .fr-account-booking-top__title,
  .fr-account-booking-top__subtitle {
    @include t-text-crop-disable();
  }

  .fr-account-booking-top__title {
    @include m-heading(map_get($m-heading__mod-sizes, "medium"));
    text-align: center;
    margin-top: $f-space--medium;

    @include t-mq($from: "sml") {
      @include m-heading--update(map_get($m-heading__mod-sizes, "huge"), map_get($m-heading__mod-sizes, "medium"));
    }
  }

  .fr-account-booking-top__subtitle {
    @include m-body(map_get($m-body__mod-sizes, 'medium'));
    margin-bottom: 0;
    text-align: center;
    color: $f-color__neutral-white;
    text-shadow: $f-shadow--text rgba($f-shadow__color, $f-shadow__opacity--text);
  }

  .fr-account-booking-top__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 $fr-account-booking-top__content--sp-inset-h $fr-account-booking-top__content--sp-inset-v;

    @include t-mq($until: "sml") {
      padding-left: $fr-account-booking-top__content--sp-inset--from-bp;
      padding-right: $fr-account-booking-top__content--sp-inset--from-bp;
    }
  }

  .m-heading:first-child {
    margin-top: $fr-account-booking-top__content--sp-inset-v;
  }
}

.fr-account-booking-top--faint-header {
  .c-img {
    opacity: $fr-account-booking-top--faint-header__img-opacity;
  }
}

.fr-account-booking-top__labels {
  display: flex;
  gap: $fr-account-booking-top__labels--gap;
  margin-top: $fr-account-booking-top__labels--margin-top;
  flex-wrap: wrap;
  justify-content: center;
}

.fr-account-booking-top__content .fr-account-booking-top__content--with-bg {
  background-color: $f-color__neutral-white;
  padding: $f-space--medium $fr-account-booking-top__content--with-bg--padding-h;
  text-shadow: none;
  max-width: $fr-account-booking-top__content--with-bg--max-width;
  border-radius: $f-radius--large;
  margin-top: $f-space--large;

  @include t-mq($until: "sml") {
    padding: 0;
  }

  .fr-account-booking-top__content-top {
    @include t-mq($until: "sml") {
      padding: $fr-account-booking-top__content-top--padding;
    }
  }

  .fr-account-booking-top__title {
    margin-top: 0;
  }

  .fr-account-booking-top__labels {
    margin-top: $f-space--tiny;
  }

  .fr-account-booking-top__title,
  .fr-account-booking-top__subtitle {
    color: $f-color__neutral-black;
    text-shadow: none;
  }
}

.fr-account-booking-top__group-link-container {
  display: flex;
  margin: 0 auto;
  margin-top: $fr-account-booking-top__group-link-container--margin-top;
  background-color: $f-color__neutral-grey--ultra-light;
  border-radius: $f-radius--rounded;
  align-items: center;
  gap: $fr-account-booking-top__group-link-container--gap;
  padding: $fr-account-booking-top__group-link-container--padding;
  border: 2px solid $f-color__neutral-grey--semi-light;
  width: fit-content;

  @include t-mq($until: "sml") {
    margin-top: 0;
    border-radius: 0 0 $f-radius--large $f-radius--large;
    width: 100%;
    border-width: 0px;
    border-top-width: 2px;
  }
}

.fr-account-booking-top__group-link {
  text-decoration: none;
  padding: $fr-account-booking-top__group-link--padding;
  cursor: pointer;
  border-radius: $f-radius--rounded;
  display: flex;
  align-items: center;
  gap: $fr-account-booking-top__group-link--gap;
  @include m-body(map_get($m-body__mod-sizes, 'medium'));

  @include t-mq($until: "sml") {
    @include m-body(map_get($m-body__mod-sizes, 'tiny'));
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    flex-basis: 0;
    flex-grow: 1;
    gap: 0;
    border-radius: $f-radius--large;
    padding-left: $fr-account-booking-top__group-link--padding-h-until-bp;
    padding-right: $fr-account-booking-top__group-link--padding-h-until-bp;
  }

  &:hover {
    background-color: $f-color__neutral-grey--light;
    color: currentColor;
  }
}

.fr-account-booking-top__group-link-icon {
  font-size: $f-font-size--m;
  line-height: 1;
  @include t-mq($until: "sml") {
    font-size: $f-font-size--xl;
  }
}

.fr-account-booking-top__group-link--selected {
  color: $f-color__neutral-white;
  background-color: $f-color__neutral-black;

  &:hover {
    background-color: $f-color__neutral-grey--ultra-dark;
    color: $f-color__neutral-white;
  }
}
