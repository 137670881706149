.w-search-map__modal .c-modal__header .c-btn:hover {
  background-color: $w-search-map__btn--bg-color--hover;
}

.w-search-map__modal .w-search-map__refresh-btn__showing-destinations {
  border-color: $w-search-map__btn--border-color;
}

.w-search-map__modal .w-search-map__mobile-filters-btn {
  color: $w-search-map__mobile-filters-btn--color;

  &:hover {
    border-color: $w-search-map__mobile-filters-btn--bg-color-hover;
  }

  &:active {
    border-color: $w-search-map__mobile-filters-btn--bg-color-active;
  }
}
