/* -------------------------------------------------------------------------
 * SIDE DRAWER MIXINS
 *
 */

@mixin c-side-drawer() {

  @include o-block--stretch();

  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  width: $c-side-drawer--width--xs;
  height: $c-side-drawer--height;
  opacity: 0;

  @include t-mq($until: "xs") {
    width: $c-side-drawer--width;
  }

  &.in, &.is-closing {
    z-index: $c-side-drawer--z-index;
  }

  &.is-closing {
    opacity: 1;
  }

  .c-side-drawer__header,
  .c-side-drawer__footer,
  .c-side-drawer__close {
    display: block;
  }

  .c-side-drawer__body {
    @include o-block__body--stretch();
    position: relative;
  }

  .c-side-drawer__body-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include sp-inset-h($c-side-drawer--sp-inset);
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
  }

  .c-side-drawer__close + .c-side-drawer__body {
    margin-top: $c-side-drawer--sp-inset * 2;
  }

  // Origin modifiers
  &.c-side-drawer--origin-left {
    @include a-reveal-slide($_direction: "right");
    left: 0;
    right: auto;
    &.in {
      opacity: 1;
      @include a-reveal-slide--in();
    }
  }
  &.c-side-drawer--origin-right {
    @include a-reveal-slide($_direction: "left");
    left: auto;
    right: 0;
    &.in {
      opacity: 1;
      @include a-reveal-slide--in();
    }
  }
  &.c-side-drawer--origin-bottom {
    @include a-reveal-slide($_direction: "bottom");
    left: auto;
    right: 0;
    &.in {
      opacity: 1;
      @include a-reveal-slide--in();
    }
  }
  &.c-side-drawer--origin-top {
    @include a-reveal-slide($_direction: "top");
    left: 0;
    right: auto;
    &.in {
      opacity: 1;
      @include a-reveal-slide--in();
    }
  }
}
