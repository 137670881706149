/* -------------------------------------------------------------------------
 * HEADING mixins
 *
 */


@mixin m-heading($_properties: $m-heading__properties) {

  // Try to resolve properties from a given string

  @if (type-of($_properties) == "string") {

    @if (map_has_key($_properties, $m-heading__mod-sizes)) {

      $_properties: map_get($m-heading__mod-sizes, $_properties);
    } @else {
      @error "m-heading: `#{$_properties}` needs to be a map or a valid key for $m-heading__mod-sizes";
    }

  }

  // Return css output iterating over given properties
  @include t-props($_properties);

}

@mixin m-heading--complete($_properties: $m-heading__properties, $_sp-stack-remove: true) {

  @include m-heading($_properties);
  @include t-text-crop-enable();
  @include t-text-crop(map-get($_properties, "font-family-crop"), map-get($_properties, "line-height"));

  @if $_sp-stack-remove {
    margin-bottom: 0;
  }
}

@mixin m-heading--update($_new-properties: (), $_properties: $m-heading__properties) {

  // Include only the CSS differences between m-heading props and the given modifier props
  @include t-props-diff($_properties, $_new-properties);

  // Include a different text crop only if there are differences between font-family or line-height
  @if (map_get($_properties, "font-family") != map_get($_new-properties, "font-family")) or (map_get($_properties, "line-height") != map_get($_new-properties, "line-height")) {
    @include t-text-crop(map-get($_new-properties, "font-family-crop"), map-get($_new-properties, "line-height"));
  }

}



