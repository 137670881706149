/* -------------------------------------------------------------------------
 * MAIN MENU
 *
 */

.w-main-menu {
  border-bottom-style: $w-main-menu--border-style;
  border-bottom-width: $w-main-menu--border-width;
  border-bottom-color: $w-main-menu--border-color;
  transition-duration: $w-main-menu--transition-duration;
  position: relative;
  z-index: $w-main-menu--z-index - 1;
  background-color: $w-main-menu--background-color;

  @include t-mq($until: $w-main-menu--bq) {
    box-shadow: $w-main-menu--mobile--box-shadow;
    border: unset;
    position: inherit;

    &:has(.c-side-drawer.in) {
      height: $w-main-menu__desktop--height--bq;
      .w-main-menu__desktop{
        position: fixed;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .c-btn {
    font-family: $w-main-menu__items-wrapper--font-family;
    font-size: $w-main-menu__font-size;

    &:hover,
    &:focus {
      color: $f-color__brand-primary--semi-dark;
    }
  }

  ul {
    margin-left: unset;
  }

  &:has(.c-side-drawer.in) {
    @include t-mq($from: $w-main-menu--bq) {
      height: $w-main-menu__desktop--height--bq;
      .w-main-menu__desktop{
        position: fixed;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.c-backdrop__main-menu {
  z-index: $w-main-menu--z-index - 2;
}

.w-main-menu__desktop {
  @extend .l-site__wrapper--medium !optional;
  background-color: $w-main-menu--background-color;
  z-index: $f-z-index--5;

  @include t-mq($from: $w-main-menu--bq) {
    height: $w-main-menu__desktop--height--bq;
  }
}

.w-main-menu__logo-wrapper {
  display: inline-block;
  @include sp-inset-h(unset);
  transform: translateY(50%);

  .w-main-menu__logo-img {
    width: $w-main-menu__logo-mobile--width;
  }

  @include t-mq($from: $w-main-menu--bq) {
    @include sp-inset-v(
      $w-main-menu__logo-desktop--inset-v
    );
    transform: unset;

    .w-main-menu__logo-img {
      width: $w-main-menu__logo-desktop--width;
    }
  }
}

.w-main-menu--action-items-wrapper {
  text-align: right;

  ul {
    margin: 0 auto;
  }
}

.w-main-menu__items-wrapper {
  padding-left: $w-main-menu__items-wrapper--padding-left;
  display: none;

  @include t-mq($from: $w-main-menu--bq) {
    display: inline-block;
  }

  ul {
    margin: 0 auto;
  }

  li {
    list-style: none;
    display: inline-block;
  }

  .w-main-menu__item--has-child {
    border-radius: $w-main-menu__item--border-radius;

    &.is-active,
    &:hover,
    &:focus-visible,
    &:focus {
      background-color: $w-main-menu__item--is-active--background-color;
      color: $f-color__neutral-grey--dark;
    }

    &.is-active {
      .c-btn__icon {
        transform: rotate(180deg);

        &::before {
          padding-right: $w-main-menu__item--has-child--icon--padding-right;
        }
      }
    }

    .c-btn__icon {
      padding-left: $w-main-menu__item--has-child--chevron-adjustment;

      &::before {
        font-size: $w-main-menu__item--has-child--chevron-font-size;
        font-weight: $w-main-menu__font-weight--bold;
      }
    }
  }

  .c-btn {
    @include sp-inset($w-main-menu__items-wrapper--padding-side-h,$w-main-menu__items-wrapper--padding-side-v);
    font-size: $w-main-menu__font-size;
    font-weight: $w-main-menu__item--link--font-weight;
  }
}

.w-main-menu__action-item {
  list-style: none;
  display: inline-block;

  .c-btn--icon-only {
    color: $w-main-menu--icon-color;
  }

  .c-favorite-collector {
    text-decoration: none;
    font-weight: $w-main-menu__font-weight--bold;
    margin-bottom: $w-main-menu__item--margin-height;
  }
}

.w-main-menu__mobile-icon--toggler {
  margin-left: $w-main-menu__item-toggler--spacing;
  display: none;

  &.is-active {
    display: inline-block;
  }
}

.w-main-menu__action-item--side-drawer-toggle {
  @include t-mq($from: $w-main-menu--bq) {
    display: none;
  }
}

.w-main-menu__mobile {
  display: block;

  .c-nav__link,
  .c-btn__text{
    font-size: $w-main-menu__font-size;
  }

  @include t-mq($from: $w-main-menu--bq) {
    display: none;
  }

  .c-side-drawer {
    height: calc(100% - $w-main-menu__desktop--height--bq);
    top: unset;
    background-color: $w-main-menu__side-drawer-background-color;
    box-shadow: $w-main-menu__side-drawer-box-shadow;

    .c-side-drawer__header {
      display: none;
    }

    .c-side-drawer__body-content {
      @include sp-inset-h(unset);

      .c-nav__link,
      .w-main-menu__mobile--extra-items .c-btn__text {
        @include sp-inset-h($w-main-menu__side-drawer--inset-h);
      }
    }

    .c-nav__item:not(.w-main-menu__mobile--main-items--section-title) {
      & > .c-nav__link {
        &:hover,
        &:active {
          background-color: unset;

          .c-nav__link-text {
            color: $w-main-menu__mobile-item--is-active--color;
          }
        }
      }

      &.has-child > .c-nav__link {
        &:hover,
        &:active {
          background-color: $w-main-menu__mobile-item--is-active--background-color;

          .c-nav__link-text {
            color: $w-main-menu__mobile-item-has-child--is-active--color;
          }
        }
      }
    }
  }

  li {
    list-style: none;

    .c-btn {
      text-align: left;
      font-family: $w-main-menu__items-wrapper--font-family;
      @include sp-inset-v($w-main-menu__item-mobile--inset-v);
      display: block;
      width: 100%;
      padding-left: unset;
      line-height: $w-main-menu__item-mobile--line-height;
    }
  }

  .w-main-menu__mobile--extra-items::before {
    border-top-style: $w-main-menu--border-style;
    border-top-width: $w-main-menu__mobile--extra-items--border-width;
    border-top-color: $w-main-menu__mobile--extra-items--border-color;
    margin-top: calc($w-main-menu__mobile-list--padding-top / -2);
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - calc($w-main-menu__side-drawer--inset-h * 2));
  }

  .w-main-menu__mobile--main-items {
    margin-top: $w-main-menu__mobile-list--padding-top;
    margin-bottom: $w-main-menu__mobile-list--margin-bottom;

    &:not([data-c-nav__shown-level="1"]) {
      & ~ .w-main-menu__mobile--extra-items {
        display: none;
      }
    }

    .c-nav__item {
      border-top: unset;

      &.c-nav__item--back {
        .c-nav__link {
          &::before {
            padding-left: $w-main-menu__mobile--go-back--padding-left;
          }

          &:hover {
            &::before {
              color: $w-main-menu__mobile-item--is-active--color;
            }
          }
        }

        .c-nav__link-text {
          padding-left: $w-main-menu__mobile--go-back--padding-left;
          text-transform: none;
          line-height: $w-main-menu__mobile--go-back--line-height;
        }
      }

      &.w-main-menu__mobile--main-items--section-title {
        pointer-events: none;

        .c-nav__link {
          font-weight: $w-main-menu__font-weight--bold;
        }
      }

      .c-nav__link {
        @include sp-inset-v($w-main-menu__item-mobile--inset-v);
        line-height: $w-main-menu__item-mobile--line-height;
        font-family: $w-main-menu__item-mobile--font-family;
        font-weight: $w-main-menu__item--link--font-weight;
        border: $w-main-menu__item-mobile--border;
        font-size: $w-main-menu__font-size;
      }
    }
  }
}

.w-main-menu__action-item--favourites a {
  text-decoration: none;
  vertical-align: middle;
  margin-top: -$w-main-menu__favourites-icon--adjustment;

  &:hover {
    &::after {
      color: $w-main-menu__action-item--favourites--color-hover;
    }
  }

  &::after {
    background-color: unset;
    color: $w-main-menu__action-item--favourites--color;
    font-weight: $w-main-menu__font-weight--bold;
    top: $w-main-menu__action-item--favourites--top;
    font-size: $w-main-menu__action-item--favourites--font-size;
    padding-left: unset;
  }

  i {
    padding-left: $w-main-menu__favourites-icon--size;
  }
}

// wrapper for content
.w-main-menu__desktop--inside {
  display: none;
  position: absolute;
  width: 100%;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition-duration: $w-main-menu--transition-duration;
  transition-property: max-height;
  transition-timing-function: $w-main-menu__desktop--inside--easing;
  will-change: max-height;
  background-color: $w-main-menu--background-color;
  transform: translateY($w-main-menu--border-width);

  &::before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: $w-main-menu__desktop--inside--background-color;
    z-index: $w-main-menu__desktop--inside--background-z-index;
    right: calc(50% + $w-main-menu__desktop--inside--background-adjustment);
  }

  &:focus-visible{
    outline: none;
  }

  @include t-mq($from: $w-main-menu--bq) {
    display: block;
  }

  ul {
    list-style: none;
  }

  &.is-open {
    max-height: $w-main-menu__desktop--inside--max-height;
    transition-duration: $w-main-menu--transition-duration;

    @include t-mq($until: $w-main-menu--bq--xl) {
      max-height: $w-main-menu__desktop--inside--max-height--bq;
    }
  }

  .w-main-menu__desktop--inside-wrapper {
    @extend .l-site__wrapper--medium !optional;
    margin-left: auto;
    margin-right: auto;
  }

  .w-main-menu__desktop--inside--left-area {
    border-right-style: $w-main-menu--border-style;
    border-right-width: $w-main-menu--border-width;
    border-right-color: $w-main-menu--border-color;
    background-color: $w-main-menu__desktop--inside--background-color;

    .w-main-menu__desktop--inside--left-area--content {
      @include sp-inset-v($w-main-menu__desktop--inside--left-area--inset-v);
      width: 100%;
      list-style: none;

      .c-btn {
        width: 100%;
        text-align: left;
        padding-left: unset;
        font-weight: $w-main-menu__item--link--font-weight;

        &:not(:first-child) {
          margin-top: $w-main-menu__desktop--inside--left-area--margin-top;
        }

        .c-btn__text {
          @include sp-inset($w-main-menu__desktop--inside--left-area--item--inset-v $w-main-menu__desktop--inside--left-area--item--inset-h);
          border-radius: $w-main-menu__desktop--inside--left-area--item--border-radius;
        }

        &:hover,
        &:focus-visible,
        &:active,
        &.is-active {
          color: $w-main-menu__item--is-active--color;

          .c-btn__text {
            background-color: $w-main-menu__desktop--inside--item--background-color;
          }
        }
      }
    }
  }

  .w-main-menu__desktop--inside--middle-column{
    border-right-style: $w-main-menu--border-style;
    border-right-width: $w-main-menu--border-width;
    border-right-color: $w-main-menu--border-color;
    background-color: $w-main-menu__desktop--inside--background-color;

    &:not(:has(.w-main-menu__desktop--inside--middle-column.is-active)){
      display: none;
    }

    .w-main-menu__desktop--inside--middle-column {
      display: none;
      border-width: 0;
      @include sp-inset-v($w-main-menu__desktop--inside--left-area--inset-v);

      &.is-active {
        display: block;
      }

      &:focus-visible{
        outline: none;
      }

      .w-main-menu__desktop--middle-column--title {
        font-weight: $w-main-menu__font-weight--bold;
        font-size: $w-main-menu__font-size;
        @include sp-inset-v($w-main-menu__desktop--inside--title--inset-v);
      }

      .w-main-menu__desktop--middle-column--item{
        .c-btn{
          width: 100%;
          text-align: start;
          display: flex;
          justify-content: space-between;
          padding-left: unset;
          font-weight: $w-main-menu__item--link--font-weight;

          .c-btn__icon{
            align-self: center;
            top: $w-main-menu__desktop--middle-column--item--icon--adjustment;
          }

        }

        &.is-active{
          background-color: $w-main-menu__desktop--inside--item--background-color;
        }

        &:hover,
        &:focus-visible,
        &:has(.c-btn:focus-visible){
          background-color: $w-main-menu__desktop--inside--item--background-color;

          .c-btn{
            color: $e-document__body--color;

            &:hover{
              color: $e-document__body--color;
            }
          }
        }

        &:focus{
          .c-btn{
            color: $e-document__body--color;
          }
        }
      }
    }
  }

  .w-main-menu__desktop--inside--right-column {
    @include sp-inset-v($w-main-menu__desktop--inside--left-area--inset-v);

    .w-main-menu__desktop--inside--right-column--content {
      display: none;

      &.is-active {
        display: block;
      }

      &:focus-visible{
        outline: none;
      }

      .w-main-menu__desktop--inside--right-column--content--title{
        font-weight: $w-main-menu__font-weight--bold;
        font-size: $w-main-menu__font-size;
        @include sp-inset-v($w-main-menu__desktop--inside--title--inset-v);
      }

      .w-main-menu__desktop--inside--right-column--item{
        .c-btn{
          padding-left: unset;
          font-weight: $w-main-menu__item--link--font-weight;
          text-align: left;
        }

        &:focus{
          .c-btn{
            color: $e-document__body--color;
          }
        }
      }
    }
  }
}

// 12 columns-grid
.w-main-menu {
  .w-main-menu__desktop--inside {
    .w-main-menu__desktop--inside-wrapper {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      grid-column-gap: $w-main-menu__12-column-grid--column-gap;

      .w-main-menu__desktop--inside--left-area {
        grid-column-end: 3;
        grid-column-start: 1;
        margin-left: -$w-main-menu__12-column-grid--column-gap;
        padding-left: $w-main-menu__12-column-grid--column-gap;

        @include t-mq($until: $w-main-menu--bq--lg) {
          grid-column-end: 4;
        }
      }

      .w-main-menu__desktop--inside--right-area {
        grid-column-end: -1;
        grid-column-start: 3;
        display: grid;
        grid-template-columns: repeat(10, minmax(0, 1fr));
        grid-column-gap: $w-main-menu__12-column-grid--column-gap;

        @include t-mq($until: $w-main-menu--bq--lg) {
          grid-column-start: 4;
          grid-template-columns: repeat(9, minmax(0, 1fr));
        }

        .w-main-menu__desktop--inside--middle-column {
          grid-column-end: 3;
          grid-column-start: 1;
          margin-left: -$w-main-menu__12-column-grid--column-gap;
          padding-left: $w-main-menu__12-column-grid--column-gap;

          @include t-mq($until: $w-main-menu--bq--lg) {
            grid-column-end: 4;
          }

          .w-main-menu__desktop--middle-column--item {
            margin-left: -$w-main-menu__12-column-grid--column-gap;
            padding-left: $w-main-menu__12-column-grid--column-gap;
          }
        }

        .w-main-menu__desktop--inside--right-column{
          grid-column-end: -1;
          grid-column-start: 3;

          @include t-mq($until: $w-main-menu--bq--lg) {
            grid-column-start: 4;
          }

          .w-main-menu__desktop--inside--right-column--content--items{
            display: grid;
            grid-template-columns: repeat(8, minmax(0, 1fr));
            grid-column-gap: $w-main-menu__12-column-grid--column-gap;
            grid-template-rows: repeat(6, auto);
            grid-auto-flow: column;

            @include t-mq($until: $w-main-menu--bq--lg) {
              grid-template-columns: repeat(4, minmax(0, 1fr));
              grid-template-rows: repeat(12, auto);
            }

            .w-main-menu__desktop--inside--right-column--item{
              grid-column: span 2;
            }
          }

        }
      }
    }
  }
}
