/* -------------------------------------------------------------------------
 * STEP INDICATOR
 */

.c-steps {
  display: flex;
  margin-left: 0;
  align-items: center;
  padding-bottom: $c-steps---padding-bottom;
  &:not([class*="c-steps--progress"]):not([class*="c-steps--vertical"]) {
    @include c-steps__item();
  }
}

.c-steps__text--mobile {
  + .c-steps__text {
    display: none;
  }
  @include t-mq($from: $c-steps__text--from-bp) {
    display: none;
    + .c-steps__text {
      display: block;
    }
  }
}

.c-steps{
  &.c-steps--progress{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  	counter-reset: stepsnumber;
    @include sp-inset-v($c-steps--progress-sp-v);
    .c-steps__item{
      align-items: center;
      counter-increment: stepsnumber;
      display: flex;
      flex-basis: 100%;
      flex-direction: column;
      flex-grow: 1;
      position: relative;
      .c-steps__link{
        align-items: center;
        color: $c-steps--progress__link-color;
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        flex-grow: 1;
        text-decoration: none;
        width: 100%;
        font-size: $c-steps--progress__link-font-size;
        font-weight: $c-steps--progress__link-weight;
        text-align: center;
        word-break: break-word;
        min-height: $c-steps--progress__link-min-height;
        height: $c-steps--progress__link-height;
        @include t-mq($from: $c-steps__progress--bp) {
          font-size: $c-steps--progress__link-font-size--from-bp;
        }
        &:hover{
          cursor: pointer;
          .c-steps__text--mobile, .c-steps__text{
            color: $c-steps--progress__link-color--active;
            text-decoration: underline;
          }
        }
      }
      .c-steps--progress__bullet{
        background-color: $c-steps--progress__bullet-bg-color--active;
        border-radius: 100%;
        color: $c-steps--progress__bullet-color;
        display: block;
        height: $c-steps--progress__bullet-size;
        line-height: $c-steps--progress__bullet-size;
        margin-bottom: $c-steps--progress__bullet-margin-bottom;
        font-weight: $c-steps--progress__bullet-weight;
        text-align: center;
        width: $c-steps--progress__bullet-size;
        .c-steps--progress__bullet{
          &::before{
            content: '';
          }
        }
      }
      &:not(:first-child){
        &::before{
          content: "";
          border-top: $c-steps--progress__bullet-line-border-width solid $c-steps--progress__bullet-bg-color--active;
          left: $c-steps--progress__bullet-line-left;
          position: absolute;
          top: $c-steps--progress__bullet-line-top;
          width: $c-steps--progress__bullet-line-width;
        }
      }
      
      &.is-disabled{
        .c-steps--progress__bullet{
          background-color: $c-steps--progress__bullet-bg-color--disabled;
        }
        .c-steps__link{
          pointer-events: none;
          &:hover{
            cursor: none;
          }
        }
        &::before{
          border-top: $c-steps--progress__bullet-line-border-width dashed $c-steps--progress__bullet-bg-color--disabled;
        }
      }
      &.is-active{
        .c-steps__link{
          color: $c-steps--progress__link-color--active;
          font-weight: $c-steps--progress__link-weight--active;
          pointer-events: none;
          &:hover{
            cursor: none;
          }
        }
        .c-steps--progress__bullet{
          background-color: $c-steps--progress__bullet-bg-color--active;
          box-shadow: 0px 0px 0px 5px rgba($c-steps--progress__bullet-shadow--active, $c-steps--progress__bullet-shadow-opacity);
        }
      }
      &.is-active, &.is-disabled{
        .c-steps--progress__bullet-icon{
          display: none;
        }
        .c-steps--progress__bullet{
          &::before{
            content: counter(stepsnumber);
            display: block;
            padding-top: $c-steps--progress__bullet-before--reset-padding;
          }
        }
      }
      &:not(.is-active):not(.is-disabled){
        .c-steps--progress__bullet-icon{
          display: block;
        }
      }
    }

    &.c-steps--vertical{
      flex-direction: column;
      .c-steps__item{
        @include sp-inset($c-steps--vertical__item--sp-inset-v, $c-steps--vertical__item--sp-inset-h);
        .c-steps__link{
          flex-direction: row;
          min-height: auto;
          .c-steps--progress__bullet{
            margin-bottom: $c-steps--vertical__bullet--sp-stack;
            margin-right: $c-steps--vertical__bullet--margin-right;
            height: $c-steps--vertical__bullet--size;
            width: $c-steps--vertical__bullet--size;
            z-index: $c-steps--progress__bullet--z-index;
            .c-steps--progress__bullet-icon{
              display: none;
            }
            .c-steps--progress__bullet{
              &::before{
                content: '';
                display: none;
              }
            }
          }
        }  
        &:not(:first-child){
          &::before{
            content: "";
            border-top: none;
            border-left: $c-steps--progress__bullet-line-border-width solid $c-steps--progress__bullet-bg-color--active;
            left: 5px;
            position: absolute;
            top: $c-steps--vertical__border--position-top;
            width: auto;
            height: $c-steps--vertical__border--size;
          }
        }
        &.is-disabled{
          &::before{
            border-top: none;
            border-left: $c-steps--progress__bullet-line-border-width dashed $c-steps--progress__bullet-bg-color--disabled;
          }
        }
        &.is-active, &.is-disabled{
          .c-steps--progress__bullet{
            &::before{
              content: '';
              display: none;
            }
          }
        }
      }
    }
  }
}
