/* -------------------------------------------------------------------------
 * TRANSPORT TICKET VARS
 *
 */

$c-transport-ticket__spacing-main:                                    $f-space--small !default;
$c-transport-ticket__spacing-secondary:                               $f-space--small !default;
$c-transport-ticket__spacing-secondary--from-bp:                      $f-space--medium !default;

$c-transport-ticket__breakpoint:                                      $f-breakpoint--sml !default;

$c-transport-ticket__bg-color:                                        $f-color__neutral-grey--ultra-light !default;
$c-transport-ticket__checked-bg-color:                                $f-color__state-info--ultra-light !default;
$c-transport-ticket__hover-bg-color:                                  $f-color__neutral-grey--light !default;
$c-transport-ticket__border-radius:                                   $f-radius--large !default;

$c-transport-ticket__detailed-info--font-weight:                      700 !default;

$c-transport-ticket__detailed-info-top--margin-bottom:                $f-space--tiny !default;
$c-transport-ticket__detailed-info-top--index-fix:                    $f-z-index--1 !default;
$c-transport-ticket__detailed-info-top--color:                        $f-color__neutral-grey--dark !default;
$c-transport-ticket__detailed-info-top--font-size:                    $f-font-size--s !default;

$c-transport-ticket__detailed-info-unknownTime--color:                $f-color__neutral-grey--semi-dark !default;
$c-transport-ticket__detailed-info-unknownTime--font-size:            $f-font-size--xs !default;

$c-transport-ticket__detailed-info-bottom--margin-bottom:             $f-space--small !default;

$c-transport-ticket__airline-info--margin-bottom:                     $f-space--small !default;
$c-transport-ticket__airline-info--gap:                               4px !default;

$c-transport-ticket__detailed-info-additional--color:                 $f-color__neutral-grey--ultra-dark !default;
$c-transport-ticket__detailed-info-additional--font-size:             $f-font-size--xs !default;
$c-transport-ticket__detailed-info-additional-breakpoint:             1100px !default;

$c-transport-ticket__detailed-info-remark--color:                     $f-color__state-info--semi-dark !default;
$c-transport-ticket__detailed-info-remark--font-size:                 $f-font-size--xs !default;
$c-transport-ticket__detailed-info-remark--margin-top:                4px !default;

$c-transport-ticket__detailed-info-icon--font-size:                   24px !default;
$c-transport-ticket__detailed-info-icon--left-alignment:              50% !default;
$c-transport-ticket__detailed-info-icon--top-alignment:               50% !default;
$c-transport-ticket__detailed-info-icon--color:                       $f-color__neutral-grey !default;
$c-transport-ticket__detailed-info-icon-line--width:                  95% !default;
$c-transport-ticket__detailed-info-icon-line--color:                  $f-color__neutral-grey !default;
$c-transport-ticket__detailed-info-icon-line--style:                  dashed !default;
$c-transport-ticket__detailed-info-icon-line--height:                 1px !default;
$c-transport-ticket__detailed-info-icon-line--top-alignment:          5px !default;

$c-transport-ticket__radio--checked-bg-color:                         $f-color__state-info--light !default;
$c-transport-ticket__radio--hover-bg-color:                           $f-color__neutral-grey--semi-light !default;
$c-transport-ticket__radio--min-width:                                38px !default;
$c-transport-ticket__radio--margin-top:                               $f-space--small !default;
$c-transport-ticket__radio--bg-color:                                 $f-color__neutral-grey--light !default;

$c-transport-ticket__contents--border-right-color:                    $c-transport-ticket__radio--bg-color !default;
$c-transport-ticket__contents--border-right--checked-color:           $c-transport-ticket__radio--checked-bg-color !default;
$c-transport-ticket__contents--border-right--hover-color:             $c-transport-ticket__radio--hover-bg-color !default;
$c-transport-ticket__contents--border-right-thickness:                1px !default;
$c-transport-ticket__contents--border-right-alignment:                -1px !default;
$c-transport-ticket__contents--border-right-offset:                   $f-space--medium !default;

$c-transport-ticket__price--color:                                    $f-color__neutral-grey--semi-dark !default;
$c-transport-ticket__price--checked-color:                            $f-color__state-info--semi-dark !default;
$c-transport-ticket__price--font-size:                                $f-font-size--l !default;
$c-transport-ticket__price--max-width:                                11ch !default;
$c-transport-ticket__price--min-width:                                140px !default;

$c-transport-ticket__disabled--opacity:                               .5 !default;

$c-transport-ticket__airline-logo--size:                              24px !default;

$c-transport-ticket__type-ticket--margin-top-from-bp:                 2px !default;

$c-transport-ticket__luggage-info--margin-right:                      $f-space--small !default;

$c-transport-ticket__detailed-info-additional-icon--margin-right:     4px !default;

$c-transport-ticket__detailed-info-unknownTime--margin-top:           10px !default;
$c-transport-ticket__detailed-info-unknownTime--horizontal-margin:    8px !default;

// Booking flight changed
$c-transport-ticket__data--old--margin-right:                         $f-space--tiny !default;
$c-transport-ticket__data--new--color:                                $f-color__brand-primary !default;

//Bus

$c-transport-ticket-bus__airline-info--color:                         $f-color__neutral-grey--dark !default;
$c-transport-ticket-bus__contents--vertical-padding:                  16px !default;
$c-transport-ticket-bus__detailed-info-bottom--margin-bottom:         $f-space--small !default;
$c-transport-ticket-bus__detailed-info-departure--font-family:        $f-font-family__secondary !default;
$c-transport-ticket-bus__detailed-info-departure--font-size:          $f-font-size--l !default;
$c-transport-ticket-bus__detailed-info-departure--font-weight:        700 !default;
$c-transport-ticket-bus__detailed-info-top--margin-bottom:            8px !default;

$c-transport-ticket__pnr-number-tooltip--font-size:                   $f-font-size--xs !default;
$c-transport-ticket__pnr-number-tooltip--line-height:                 $f-line-height--small !default;

$c-transport-ticket__type-ticket-text-container--gap:                 2px !default;
