/* -------------------------------------------------------------------------
 * Account booking VARS
 *
 */

// Layout
$t-account-booking__l-site-main__content--no-top--padding-top:                     $f-space--medium !default;

// Fragment FR-ACCOUNT-BOOKING-TOP
// ---------------------------------------------------
$fr-account-booking-top__content--sp-inset-v:                                      $f-space--large !default;
$fr-account-booking-top__content--sp-inset-h:                                      $f-space--medium !default;
$fr-account-booking-top__content--sp-inset--from-bp:                               16px !default;

$fr-account-booking-top--faint-header__img-opacity:                                .6 !default;

$fr-account-booking-content__accordion--padding-bottom:                            32px !default;

// Labels
$fr-account-booking-top__labels--margin-top:                                       8px !default;
$fr-account-booking-top__labels--gap:                                              4px !default;

$fr-account-booking-content--sp-stack-children:                                    $f-space--medium !default;

// Section header
$fr-account-booking-section-header--margin-bottom:                                 $f-space--medium !default;
$fr-account-booking-section-header--margin-left:                                   $f-space--tiny !default;

// Booking term
$fr-account-booking-term--margin:                                                  $f-space--tiny !default;
$fr-account-booking-term__label--font-weight:                                      700 !default;
$fr-account-booking-term__service-edit-link__icon--size:                           $f-font-size--l !default;
$fr-account-booking-term__label-text--max-width:                                   calc(100% - #{$fr-account-booking-term__service-edit-link__icon--size} - #{$f-space--tiny}) !default;
$fr-account-booking-term__value--font-size:                                        $f-font-size--s !default;
$fr-account-booking-term__label--font-size:                                        $f-font-size--m !default;
$fr-account-booking-term__service-edit-link--color:                                $f-color__state-info !default;
$fr-account-booking-term__service-edit-link--padding:                              $f-space--none !default;

// Booking term small variant
$fr-account-booking-term__label-small--font-size:                                  $f-font-size--s !default;
$fr-account-booking-term__value-small--font-size:                                  $f-font-size--xs !default;
$fr-account-booking-term__value-small--margin-top:                                 2px !default;

//Participants
$fr-account-booking-participants__term--padding:                                   $f-space--small !default;
$fr-account-booking-participants__term--margin:                                    $f-space--medium !default;
$fr-account-booking-participants__content--inset-h:                                $f-space--small !default;
$fr-account-booking-participants__section--margin-top:                             $f-space--large !default;
$fr-account-booking-participants__section-title--padding:                          $f-space--small !default;
$fr-account-booking-participants__section-title--border-color:                     $f-color__neutral-grey--semi-light !default;

// Details
$fr-account-booking-details__content--inset-h:                                     $f-space--small !default;
$fr-account-booking-details__term--padding:                                        $f-space--small !default;
$fr-account-booking-details__term--margin:                                         $f-space--medium !default;
$fr-account-booking-details__extras-add--margin:                                   $f-space--medium !default;
$fr-account-booking-details__extras-section--border:                               1px solid $f-color__neutral-grey--semi-light !default;
$fr-account-booking-details__extras-title--hover-color:                            $f-color__neutral-grey--semi-dark !default;
$fr-account-booking-details__extras-opener--icon-size:                             map_get($m-icons__sizes, "small") !default;
$fr-account-booking-details__preferences-remark--color:                            $f-color__neutral-grey--semi-dark !default;
$fr-account-booking-details__preferences-remark--font-size:                        $f-font-size--xs !default;
$fr-account-booking-details__preferences-list--spacing:                            $f-space--tiny !default;
$fr-account-booking-details__preferences-list--margin-left:                        1rem !default;
$fr-account-booking-details__preferences-list--stack-spacing:                      $f-space--small !default;

// Details:contact
$fr-account-booking-details-contact__term--margin-top:                             $f-space--small !default;
$fr-account-booking-details-contact__small-term--margin-top:                       8px !default;
$fr-account-booking-details-contact__empty-message--font-size:                     $f-font-size--s !default;
$fr-account-booking-details-contact__empty-message--margin-top:                    $f-space--medium !default;

// Payment status
$fr-account-booking-payment-status--spacing:                                       16px !default;
$fr-account-booking-payment-status__container--bg-color:                           $f-color__state-info--ultra-light !default;
$fr-account-booking-payment-status__container--border-style:                       solid !default;
$fr-account-booking-payment-status__container--border-width:                       1px !default;
$fr-account-booking-payment-status__container--border-color:                       $f-color__state-info--light !default;

$fr-account-booking-payment-status__support-item--color:                           $f-color__neutral-grey--semi-dark !default;

$fr-account-booking-payment-status--separator:                                    2px solid $f-color__neutral-grey--semi-light !default;

$fr-account-booking-payment-status__row-item-label--font-size:                    $f-font-size--s !default;

$fr-account-booking-payment-status__icon--color:                                  $f-color__state-success !default;

$fr-account-booking-payment-status__success-title--margin-bottom:                 4px !default;

// Acco info
$fr-account-booking-acco-info__content-details-icon--color:                        $f-color__state-success !default;
$fr-account-booking-acco-info__content-details--font-size:                         $f-font-size--s !default;
$fr-account-booking-acco-info__content-details--line-height:                       $f-line-height--medium !default;
$fr-account-booking-acco-info__content-details-text--margin-bottom:                $f-space--small !default;

$fr-account-booking-acco-info__content-details-usps--margin-bottom:                $f-space--small !default;
$fr-account-booking-acco-info__content-details-usps--padding-left:                 4px !default;
$fr-account-booking-acco-info__content-details-usps-icon--font-size:               16px !default;
$fr-account-booking-acco-info__content-details-usps-icon--padding-top:             2px !default;

// Delete Booking
$fr-account-booking-delete-btn--border:                                            2px solid $f-color__neutral-grey--ultra-light !default;

// Booking cancelled voucher overrides
$t-account-booking__fr-account-booking-cancelled-content__icon--color:             $f-color__neutral-grey !default;

// Booking promotion rebooking
$fr-account-booking-promotion-rebooking--max-width:                                320px !default;

// Conditional message
$fr-account-booking-conditional-messages--spacing:                                 $f-space--small !default;

$fr-account-booking-term__special-luggage__margin-bottom:                          8px !default;
$fr-account-booking-term__special-luggage__gap:                                    4px !default;

$fr-account-booking-top__content--with-bg--padding-h:                              61px !default;
$fr-account-booking-top__content--with-bg--max-width:                              720px !default;

$fr-account-booking-top__content-top--padding:                                     16px !default;

$fr-account-booking-top__group-link-container--margin-top:                         28px !default;
$fr-account-booking-top__group-link-container--gap:                                4px !default;
$fr-account-booking-top__group-link-container--padding:                            4px !default;

$fr-account-booking-top__group-link--padding:                                      8px 16px !default;
$fr-account-booking-top__group-link--gap:                                          4px !default;
$fr-account-booking-top__group-link--padding-h-until-bp:                           2px;
