
/* -------------------------------------------------------------------------
 * HORIZONTAL RULES
 *
 * Ensure to use border bottom as width source
 *
 */


hr {
  border: 0;
  border-top-width: $e-hr__width;
  border-top-style: $e-hr__style;
  border-top-color: $e-document__body--color;
  height: 0;
  padding: 0;
}
