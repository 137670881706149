/* -------------------------------------------------------------------------
 * SMART APP BANNER
 */

@import 'imported-styles';

// overrides

.smartbanner {
  background: $w-smart-app-banner--background;
  box-shadow: $w-smart-app-banner--shadow;
  font-family: $w-smart-app-banner--font-family;
  color: $w-smart-app-banner--color;

  .smartbanner-info :not(.smartbanner-title) {
    font-weight: $w-smart-app-banner__info--font-weight;
  }
}

.smartbanner-ios .smartbanner-button {
  box-shadow: none;
  background: none;
  color: $w-smart-app-banner__btn--color;
}
