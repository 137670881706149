
/* -------------------------------------------------------------------------
 * DEFAULT Color Scheme
 *
 */

// Color Scheme output

@if $cs-default--enabled {
  .cs-default,
  .cs-default--white {
    @include color-scheme($cs-default)
  }
}


// Color Scheme Modifiers
// -------------------------

// Light modifier

@if $cs-default--enabled and $cs-default--light--enabled {
  .cs-default--light {
    @include color-scheme(s-core-map-diff($cs-default, $cs-default--light))
  }
}

// Dark modifier

@if $cs-default--enabled and $cs-default--dark--enabled {
  .cs-default--dark {
    @include color-scheme(s-core-map-diff($cs-default, $cs-default--dark))
  }
}
