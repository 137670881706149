/* -------------------------------------------------------------------------
* Pagination Variables
*
*/


$c-pagination__button--color:                                       $f-color__brand-primary--dark !default;

$c-pagination__button-truncation--space-left:                       18px !default;

$c-pagination__button--border-color:                                $f-color__neutral-black !default;
$c-pagination__button--icon--padding--alignment-fix:                0 !default;
$c-pagination__button--icon--right--alignment-fix:                  3px !default;
$c-pagination__button-previous--icon--right--alignment-fix:         7px !default;

$c-pagination__button--border-radius:                               $f-radius--ellipsis !default;



// States
// -----------------------------------------

// Disabled
$c-pagination--disabled--color:                                     $f-color__neutral-grey--semi-light !default;

// Active state
$c-pagination__background-color--active:                            $f-color__state-info !default;
$c-pagination__color--active:                                       $f-color__neutral-white !default;

// Hover state
$c-pagination__background-color--hover:                             $f-color__state-info--light !default;
$c-pagination__color--hover:                                        $f-color__state-info--dark !default;

// Pressed state
$c-pagination__background-color--pressed:                           $f-color__state-info !default;
$c-pagination__color--pressed:                                      $f-color__state-info--dark !default;
