/* -------------------------------------------------------------------------
* Phone input Variables
*
*/

$c-phone-input__country-selector--sp-inset:                     $f-space--small !default;
$c-phone-input__country-selector--sp-inline:                    $f-space--small !default;
$c-phone-input__country-selector--z-index:                      $f-z-index--1 !default;

$c-phone-input__chevron--duration:                              $f-duration--fast !default;
$c-phone-input__chevron--easing:                                $f-easing--base !default;

$c-phone-input__country-flag--width:                            20px !default;

$c-phone-input__country-flag--sp-inline:                        $f-space--tiny !default;
$c-phone-input__country-flag--sp-inline--country-item:          $f-space--small !default;
$c-phone-input__country-flag--sp-inline--country-item-ch:       17px !default;

$c-phone-input__prefix--width:                                  6ch !default;
$c-phone-input__prefix--z-index:                                $f-z-index--1 !default;
$c-phone-input__prefix--top:                                    1px !default;
$c-phone-input__prefix--left:                                   $c-phone-input__country-selector--sp-inline + (2 * $c-phone-input__country-selector--sp-inset) + $c-phone-input__country-flag--width + $c-phone-input__country-selector--sp-inline !default;
$c-phone-input__prefix--color--disabled:                        $f-color__neutral-grey !default;

$c-phone-input__number--text-indent:                            4em !default;
$c-phone-input__number--reset-text-indent:                      0em !default;

$c-phone-input__country-list--sp-inset:                         $f-space--tiny !default;
$c-phone-input__country-list--bg-color:                         $f-color__neutral-white !default;
$c-phone-input__country-list--shadow:                           $f-shadow--elevated !default;
$c-phone-input__country-list--shadow-color:                     $f-shadow__color !default;
$c-phone-input__country-list--shadow-opacity:                   $f-shadow__opacity !default;
$c-phone-input__country-list--border-radius:                    $f-radius--none !default;
$c-phone-input__country-list--border-size:                      $e-form-elements__field--border--size !default;
$c-phone-input__country-list--border-type:                      solid !default;
$c-phone-input__country-list--border-color:                     $f-color__neutral-grey--semi-light !default;
$c-phone-input__country-list--z-index:                          $f-z-index--1 !default;

$c-phone-input__country-item--bg-color--hover:                  $f-color__neutral-grey--ultra-light !default;
$c-phone-input__country-item--sp-squish:                        $f-space--small !default;

$c-phone-input__country-name--sp-inline:                        $f-space--small !default;

$c-phone-input__country-dial--color:                            $f-color__neutral-grey !default;

// States
// -----------------------------------------

// Disabled
$c-phone-input__disabled--opacity:                              $e-form-elements--disabled--opacity !default;
$c-phone-input__chevron--disabled--color:                       $e-form-elements__arrow--disabled--color !default;

// Modifiers
// -----------------------------------------

// Highlighted
$c-phone-input__element--highlighted--color:                    $f-color__state-info--light !default;

// Messages
$c-phone-input__messages--font-size:                            $f-font-size--s !default;
$c-phone-input__messages--margin-top:                           $f-space--tiny !default;
$c-phone-input__message--error--color:                          $e-form-elements--error--border-color !default;
