/* -------------------------------------------------------------------------
 * FLAG
 *
 * A media object based on tables
 *
 * The flag object is a design pattern similar to the media object, however it
 * utilises `display: table[-cell];` to give us control over the vertical
 * alignments of the text and image.
 *
 * Params:
 * ALIGNMENT .......................... Vertical image and body alignment
 *
 */

// Object selector output
// --------------------------------------------------

@if ($o-flag--enabled) {
  .o-flag {
    @include o-flag();
  }

  .o-flag__img {
    @include o-flag__img();
  }

  .o-flag__body {
    @include o-flag__body();
  }
}


// Alignment modifiers
// --------------------------------------------------

@if ($o-flag--enabled and $o-flag__mod-alignments--enabled) {
  @each $_vertical-alignment in $o-flag__mod-alignments {
    @include o-flag__mod-alignment($_vertical-alignment);
  }
}


// Reverse modifier
// --------------------------------------------------

@if ($o-flag--enabled and $o-flag__mod-reverse--enabled) {
  @include o-flag__mod-reverse();
}
