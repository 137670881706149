/* -------------------------------------------------------------------------
 * CAR RENTAL
 *
 */

.w-car-rental {
}

.w-car-rental__item {
  border: $w-car-rental__item--border-width $w-car-rental__item--border-style $w-car-rental__item--border-color;
  border-radius: $w-car-rental__item--border-radius;
  box-shadow: $w-car-rental__item--shadow--focus;

  @include t-mq($from: $w-car-rental--bp) {
    @include sp-inset($w-car-rental__item--sp-inset--from-bp);
    display: flex;
    flex-wrap: wrap;
    box-shadow: none;
  }

  @include t-mq($from: $w-car-rental--bp-big) {
    flex-wrap: nowrap;
  }
}

.w-car-rental__item-content {
  position: relative;
  @include sp-inset($w-car-rental__item-content--sp-inset);

  @include t-mq($from: $w-car-rental--bp) {
    display: flex;
    align-items: center;
    @include sp-inset($f-space--none);
    @include sp-stack($w-car-rental__item-content--sp-stack--from-bp);
  }

  @include t-mq($from: $w-car-rental--bp-big) {
    align-items: initial;
    flex-basis: 2 * $w-car-rental__item--col-width--from-bp;
    @include sp-inline($w-car-rental__item-content--sp-inline--from-bp-big);
    @include sp-stack($f-space--none);
    max-width: $w-car-rental__item-content--max-width--from-bp-big;
  }
}

.w-car-rental__item-header {
  display: flex;
  align-items: center;
  @include sp-stack($w-car-rental__item-header--sp-stack);

  @include t-mq($from: $w-car-rental--bp) {
    flex: 0 0 $w-car-rental__item-header--width--from-bp;
    align-items: flex-start;
    flex-direction: column;
    @include sp-inline($w-car-rental__item-header--sp-inline--from-bp);
    @include sp-stack($f-space--none);
  }

  @include t-mq($from: $w-car-rental--bp-big) {
    flex-basis: $w-car-rental__item-header--width--from-bp-big;
    @include sp-inline($w-car-rental__item-header--sp-inline--from-bp-big);
  }
}

.w-car-rental__item-image {
  height: auto;
  max-width: $w-car-rental__item-image--max-width;
  mix-blend-mode: multiply;
  @include sp-inline($w-car-rental__item-image--sp-inset);

  @include t-mq($from: $w-car-rental--bp) {
    max-width: initial;
    @include sp-inline($f-space--none);
  }

  @include t-mq($from: $w-car-rental--bp-big) {
    width: $w-car-rental__item-image--width--from-bp-big;
  }
}

.w-car-rental__item-title {
  @include t-mq($from: $w-car-rental--bp) {
    order: -1;
    margin-bottom: $w-car-rental__item-title--sp-stack--from-bp;
  }
}

.w-car-rental__item-name {
  @include m-heading--complete($w-car-rental__item-name--material);
  @include sp-stack($w-car-rental__item-name--sp-stack);

  @include t-mq($from: $w-car-rental--bp) {
    @include m-heading--update($w-car-rental__item-name--material--from-bp, $w-car-rental__item-name--material);
  }
}

.w-car-rental__item-category {
  color: $w-car-rental__item-category--color;
}

.w-car-rental__item-characteristics {
  @include sp-stack($w-car-rental__item-characteristics--sp-stack);

  @include t-mq($from: $w-car-rental--bp) {
    @include sp-stack($f-space--none);
  }

  @include t-mq($from: $w-car-rental--bp-big) {
    padding-top: $w-car-rental__item-characteristics--sp-inset-top;
  }
}

.w-car-rental__item-characteristic {
  display: inline-block;
  text-transform: capitalize;
  @include sp-inline($w-car-rental__item-characteristic--sp-inline);
  @include sp-stack($w-car-rental__item-characteristic--sp-stack);

  &:last-child {
    @include sp-inline($f-space--none);

    @include t-mq($from: $w-car-rental--bp) {
      @include sp-stack($f-space--none);
    }
  }

  @include t-mq($from: $w-car-rental--bp) {
    display: block;
    @include sp-inline($f-space--none);
  }
}

.w-car-rental__item-icon {
  color: $w-car-rental__item-icon--color;
  font-size: $w-car-rental__item-icon--font-size;

  @include t-mq($from: $w-car-rental--bp) {
    @include sp-inline($w-car-rental__item-icon--sp-inline--from-bp);
  }
}

.w-car-rental__item-trigger {
  color: $w-car-rental__item-trigger--color;
  text-align: right;

  @include t-mq($from: $w-car-rental--bp) {
    display: none;
  }
}

.w-car-rental__item-trigger-text--less {
  display: none;
}

.w-car-rental__item-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;

  &[data-c-collapse__opened] ~ .w-car-rental__item-trigger {
    .w-car-rental__item-trigger-text--more {
      display: none;
    }
  
    .w-car-rental__item-trigger-text--less {
      display: initial;
    }
  }

  @include t-mq($from: $w-car-rental--bp) {
    display: none;
  }
}

.w-car-rental__item-collapse.w-car-rental__item-collapse.w-car-rental__item-collapse {
  @include t-mq($from: $w-car-rental--bp) {
    overflow: visible;
    max-height: initial;
    flex-basis: $w-car-rental__item--col-width--from-bp;
  }
}

.w-car-rental__item-table {
  display: table;
  background-color: $w-car-rental__item-table--bg-color;
  width: 100%;
  @include sp-inset-squish($w-car-rental__item-table--sp-inset);

  @include t-mq($from: $w-car-rental--bp) {
    background-color: transparent;
    @include sp-inset($f-space--none);
  }
}

.w-car-rental__item-row {
  display: table-row;
  @include sp-inset($f-space--none);

  &.w-car-rental__item-row:nth-child(odd) {
    @include t-mq($from: $w-car-rental--bp) {
      background-color: $w-car-rental__item-row--even--bg-color;
    }
  }

  &:nth-child(even) {
    background-color: $w-car-rental__item-row--even--bg-color;

    @include t-mq($from: $w-car-rental--bp) {
      background-color: $w-car-rental__item-table--bg-color;
    }
  }

  &:first-child {
    .w-car-rental__item-cell {
      @include t-mq($from: $w-car-rental--bp) {
        padding-bottom: $w-car-rental__item-cell--sp-inset-down--from-bp;
      }
    }
  }
}

.w-car-rental__item-cell {
  font-weight: 700;
  display: table-cell;
  text-transform: capitalize;
  @include sp-inset($w-car-rental__item-cell--sp-inset);

  &:first-child {
    padding-left: $w-car-rental__item-cell--sp-inset-h;

    @include t-mq($from: $w-car-rental--bp) {
      @include sp-inset-h($w-car-rental__item-cell--sp-inset-h--from-bp);
    }
  }

  &:last-child {
    padding-right: $w-car-rental__item-cell--sp-inset-h;

    @include t-mq($from: $w-car-rental--bp) {
      @include sp-inset-h($w-car-rental__item-cell--sp-inset-h--from-bp);
    }
  }

  &.w-car-rental__item-cell--body:first-child {
    font-weight: 400;
  }

  &.w-car-rental__item-cell--body:not(:first-child) {
    white-space: nowrap;
  }
}

.w-car-rental__notification-box {
  display: flex;
  align-items: center;
  color: $w-car-rental__notification--color;
}

.w-car-rental__notification-icon {
  @include sp-inline($w-car-rental__notification-icon--sp-inline);
}


