/* -------------------------------------------------------------------------
 * BODY variables
 *
 */

// Material default properties

$m-body__properties: (
  "font-family"         : $f-font-family__primary,
  "font-family-crop"    : $f-font-family__primary--crop,
  "font-size"           : $f-font-size--m,
  "line-height"         : $f-line-height--medium,
  "font-weight"         : 400,
) !default;


// Material Size Modifiers

$m-body__mod-sizes--enabled :   true !default;
$m-body__mod-sizes          : (
  "tiny" : (
    "font-family"           : $f-font-family__primary,
    "font-family-crop"      : $f-font-family__primary--crop,
    "font-size"             : $f-font-size--xs,
    "line-height"           : $f-line-height--medium
  ),
  "small" : (
    "font-family"           : $f-font-family__primary,
    "font-family-crop"      : $f-font-family__primary--crop,
    "font-size"             : $f-font-size--s,
    "line-height"           : $f-line-height--medium
  ),
  "medium"                  : $m-body__properties,
  "large" : (
    "font-family"           : $f-font-family__primary,
    "font-family-crop"      : $f-font-family__primary--crop,
    "font-size"             : $f-font-size--l,
    "line-height"           : $f-line-height--medium
  ),
  "huge": (
    "font-family"           : $f-font-family__primary,
    "font-family-crop"      : $f-font-family__primary--crop,
    "font-size"             : $f-font-size--xl,
    "line-height"           : $f-line-height--medium
  )
) !default;
