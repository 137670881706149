/* -------------------------------------------------------------------------
 * RADIUSES
 *
 * Foundation radiuses as border-radius
 *
 */

// Utilities output
// --------------------------------------------------


@if ($u-radiuses--enabled) {
  @each $_radius__name, $_radius__value in $u-radiuses__map {
    .u-radius--#{$_radius__name} {
      border-radius: $_radius__value !important;
    }
  }
}
