/* -------------------------------------------------------------------------
 * SEARCH RESULT
 */

.c-search-result {
  background-color: $c-search-result--bg-color;
  transition: $c-search-result--shadow-transition;

  @include t-mq($from: $c-search-result--bp) {
    box-shadow: $c-search-result--shadow-size--from-mq $c-search-result--shadow-color--from-mq;

    &:hover {
      box-shadow: $c-search-result--shadow-size--from-mq $c-search-result--shadow-color--hover;
    }
  }

  // Footer and its content
  .c-search-result__footer {
    @include sp-inset($c-search-result__footer--sp-inset-squish);

    @include t-mq($from: $c-search-result--bp) {
      justify-content: space-between;
      border-left: $c-search-result__footer--border-left;

      .c-search-result__footer-top,
      .c-search-result__footer-bottom {
        margin: 0;
      }
    }
  }  
}

.c-search-result__usps {
  margin-top: $c-search-result__usps--sp-stack;

  .c-bullet-list__icon {
    color: $c-search-result__bullet-list-icon--color;
  }

  @include t-mq($from: $c-search-result--bp-small) {
    margin-bottom: $c-search-result__usps--sp-stack--small;
    margin-top: $c-search-result__usps--sp-stack--small;
  }

  @include t-mq($from: $c-search-result--bp) {
    margin-bottom: $c-search-result__usps--sp-stack--small;
    margin-top: $c-search-result__usps--sp-stack--small;
  }
}

.c-search-result__name {
  font-weight: $c-search-result__name--font-weight;
}

.c-search-result__header {
  @include t-mq($from: $c-search-result--bp-small) {
    display: flex;
    flex-direction: column;
  }
}

.c-search-result:not(.c-search-result--condensed-tiny):not(.c-search-result--compact) {
  .c-search-result__header {
    @include t-mq($from: $c-search-result--bp-small) {
      padding: $c-search-result__header--padding;
    }
  }

  .c-search-result__body {
    @include t-mq($from: $c-search-result--bp-small) {
      padding-bottom: $c-search-result__body--sp-stack--small;
    }

    @include t-mq($from: $c-search-result--bp) {
      padding-bottom: $c-search-result__body--sp-stack;
    }
  }

  .c-search-result__image-wrapper {
    @include t-mq($from: $c-search-result--bp-small) {
      width: $c-search-result__image-wrapper--width--from-mq-big;
    }
  }
}

.c-search-result__image-wrapper {
  margin-bottom: 0;
}
