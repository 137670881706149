
/* -------------------------------------------------------------------------
 * DEFAULT Color Scheme variables
 *
 */

// Color Scheme default properties

$cs-default--background:                    $e-document__body--bgcolor !default;
$cs-default--text:                          $e-document__body--color !default;
$cs-default--text--discrete:                $f-color__neutral-grey !default;
$cs-default--selection-background:          $f-color__brand-primary--semi-light !default;
$cs-default--selection-text:                $f-color__neutral-white !default;
$cs-default--border:                        $f-color__neutral-grey--light !default;
$cs-default--line:                          $f-color__neutral-grey--light !default;
$cs-default--link:                          $e-links__color !default;
$cs-default--link-hover:                    $e-links__hover-color !default;
$cs-default--heading:                       $e-document__body--color !default;
$cs-default--translucence:                  .9 !default;


// Color Scheme Modifiers
// -------------------------

// Light modifier

$cs-default--light--background:             $f-color__neutral-grey--ultra-light !default;
$cs-default--light--text:                   $e-document__body--color !default;
$cs-default--light--text--discrete:         $f-color__neutral-grey !default;
$cs-default--light--selection-background:   $f-color__brand-primary--semi-light !default;
$cs-default--light--selection-text:         $f-color__neutral-white !default;
$cs-default--light--border:                 $f-color__neutral-grey--semi-light !default;
$cs-default--light--line:                   $f-color__neutral-grey--semi-light !default;
$cs-default--light--link:                   $e-links__color !default;
$cs-default--light--link-hover:             $e-links__hover-color !default;
$cs-default--light--heading:                $e-document__body--color !default;
$cs-default--light--translucence:           .9 !default;


// Dark modifier

$cs-default--dark--background:              $f-color__neutral-grey--semi-dark !default;
$cs-default--dark--text:                    $f-color__neutral-grey--ultra-light !default;
$cs-default--dark--text--discrete:          $f-color__neutral-grey--light !default;
$cs-default--dark--selection-background:    $f-color__neutral-grey--light !default;
$cs-default--dark--selection-text:          $f-color__neutral-grey--dark !default;
$cs-default--dark--border:                  $f-color__neutral-white !default;
$cs-default--dark--line:                    $f-color__neutral-white !default;
$cs-default--dark--link:                    $f-color__neutral-white !default;
$cs-default--dark--link-hover:              $f-color__neutral-white !default;
$cs-default--dark--heading:                 $f-color__neutral-white !default;

// Darker modifier

$cs-default--darker--enabled :              true !default;

$cs-default--darker--background:            $f-color__neutral-grey--dark !default;
$cs-default--darker--text:                  $f-color__neutral-grey--ultra-light !default;
$cs-default--darker--text--discrete:        $f-color__neutral-grey !default;
$cs-default--darker--selection-background:  $f-color__brand-primary--semi-light !default;
$cs-default--darker--selection-text:        $f-color__neutral-white !default;
$cs-default--darker--border:                $f-color__neutral-grey--dark !default;
$cs-default--darker--line:                  $f-color__neutral-white !default;
$cs-default--darker--link:                  $e-links__color !default;
$cs-default--darker--link-hover:            $e-links__hover-color !default;
$cs-default--darker--heading:               $f-color__neutral-white !default;

$cs-default--darker: (
  "background":                             $cs-default--darker--background,
  "text":                                   $cs-default--darker--text,
  "text-discrete":                          $cs-default--darker--text--discrete,
  "selection-background":                   $cs-default--darker--selection-background,
  "selection-text":                         $cs-default--darker--selection-text,
  "border":                                 $cs-default--darker--border,
  "line":                                   $cs-default--darker--line,
  "link":                                   $cs-default--darker--link,
  "link-hover":                             $cs-default--darker--link-hover,
  "heading":                                $cs-default--darker--heading
) !default;

// White modifier

$cs-default--white--enabled :               true !default;

$cs-default--white--background:             $f-color__neutral-white !default;
$cs-default--white--text:                   $e-document__body--color !default;
$cs-default--white--text--discrete:         $f-color__neutral-grey !default;
$cs-default--white--selection-background:   $f-color__brand-primary--semi-light !default;
$cs-default--white--selection-text:         $f-color__neutral-white !default;
$cs-default--white--border:                 $f-color__neutral-grey--light !default;
$cs-default--white--line:                   $f-color__neutral-grey--light !default;
$cs-default--white--link:                   $e-links__color !default;
$cs-default--white--link-hover:             $e-links__hover-color !default;
$cs-default--white--heading:                $e-document__body--color !default;

$cs-default--white: (
  "background":                             $cs-default--white--background,
  "text":                                   $cs-default--white--text,
  "text-discrete":                          $cs-default--white--text--discrete,
  "selection-background":                   $cs-default--white--selection-background,
  "selection-text":                         $cs-default--white--selection-text,
  "border":                                 $cs-default--white--border,
  "line":                                   $cs-default--white--line,
  "link":                                   $cs-default--white--link,
  "link-hover":                             $cs-default--white--link-hover,
  "heading":                                $cs-default--white--heading
) !default;

// custom dashed border

$c-absolute-dialog--dark-bg-opacity:                            .75 !default;
$c-absolute-dialog--dark-bg-color:                              rgba($f-color__neutral-black, $c-absolute-dialog--dark-bg-opacity) !default;
