/* -------------------------------------------------------------------------
 * TRANSPORT TICKET
 */


.c-transport-ticket {
  cursor: pointer;
  &:not(:last-child) {
    @include sp-stack($c-transport-ticket__spacing-main);
  }
  .c-radio-button__label {
    display: block;
  }
  @include t-mq($from: $c-transport-ticket__breakpoint) {
    display: flex;
  }
}

.c-transport-ticket__no-radio {
  cursor: auto;
}

.c-transport-ticket__no-radio .c-transport-ticket__contents {
  border-top-left-radius: $c-transport-ticket__border-radius;
  border-bottom-left-radius: $c-transport-ticket__border-radius;
}

.c-transport-ticket__contents,
.c-transport-ticket__price {
  background-color: $c-transport-ticket__bg-color;
}

.c-transport-ticket__radio {
  display: none;
  background-color: $c-transport-ticket__radio--bg-color;
  border-top-left-radius: $c-transport-ticket__border-radius;
  border-bottom-left-radius: $c-transport-ticket__border-radius;
  min-width: $c-transport-ticket__radio--min-width;

  .c-radio-button__element {
    @include t-mq($from: $c-transport-ticket__breakpoint) {
      margin-top: $c-transport-ticket__radio--margin-top;
    }
  }
}

.c-transport-ticket__radio,
.c-transport-ticket__price {
  text-align: center;
  @include t-mq($from: $c-transport-ticket__breakpoint) {
    display: flex;
    align-items: center;
  }
}

.c-transport-ticket__price {
  border-radius: $c-transport-ticket__border-radius;
  @include sp-inset($c-transport-ticket__spacing-main);
  @include t-mq($from: $c-transport-ticket__breakpoint) {
    justify-content: center;
    min-width: $c-transport-ticket__price--min-width;
    @include sp-inset-v($c-transport-ticket__spacing-secondary);
  }

  .c-price {
    color: $c-transport-ticket__price--color;
    font-size: $c-transport-ticket__price--font-size;
  }

  .c-price__public-price {
    max-width: $c-transport-ticket__price--max-width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: flex-end;
    text-align: right;
  }
}

.c-transport-ticket__contents {
  flex-grow: 2;
  border-radius: $c-transport-ticket__border-radius;
  position: relative;
  @include sp-inset($c-transport-ticket__spacing-secondary);
  &::after {
    content: "";
    height: $c-transport-ticket__contents--border-right-thickness;
    width: calc(100% - #{$c-transport-ticket__contents--border-right-offset});
    background-color: $c-transport-ticket__contents--border-right-color;
    display: block;
    position: absolute;
    left: 50%;
    bottom: $c-transport-ticket__contents--border-right-alignment;
    transform: translate(-50% 0);
    @include t-mq($from: $c-transport-ticket__breakpoint) {
      right: $c-transport-ticket__contents--border-right-alignment;
      left: auto;
      bottom: auto;
      top: 50%;
      transform: translate(0 -50%);
      height: calc(100% - #{$c-transport-ticket__contents--border-right-offset});
      width: $c-transport-ticket__contents--border-right-thickness;
    }
  }
  @include t-mq($from: $c-transport-ticket__breakpoint) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    @include sp-inset-squish($c-transport-ticket__spacing-secondary--from-bp);
  }

  &:last-child::after {
    content: unset;
  }
}

.c-transport-ticket__date {
  font-weight: $c-transport-ticket__detailed-info--font-weight;
}

.c-transport-ticket__detailed-info-top,
.c-transport-ticket__detailed-info-bottom {
  display: flex;
}

.c-transport-ticket__detailed-info-top {
  justify-content: space-between;
  @include sp-stack($c-transport-ticket__detailed-info-top--margin-bottom);
}

.c-transport-ticket__detailed-info-bottom {
  @include sp-stack($c-transport-ticket__detailed-info-bottom--margin-bottom);
}

.c-transport-ticket__detailed-info-departure {
  text-align: left;
  line-height: 1;
}

.c-transport-ticket__detailed-info-arrival {
  text-align: right;
  line-height: 1;
}

.c-transport-ticket__detailed-info-departure .c-transport-ticket__detailed-info-unknownTime{
  margin-right: $c-transport-ticket__detailed-info-unknownTime--horizontal-margin;
}

.c-transport-ticket__detailed-info-arrival .c-transport-ticket__detailed-info-unknownTime {
  margin-left: $c-transport-ticket__detailed-info-unknownTime--horizontal-margin;
}

.c-transport-ticket__detailed-info-unknownTime {
  color: $c-transport-ticket__detailed-info-unknownTime--color;
  font-size: $c-transport-ticket__detailed-info-unknownTime--font-size;
  margin-top: $c-transport-ticket__detailed-info-unknownTime--margin-top;
  display: inline-block;
}

.c-transport-ticket__detailed-info-icon {
  flex-grow: 2;
  text-align: center;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: $c-transport-ticket__detailed-info-icon-line--width;
    border-bottom: $c-transport-ticket__detailed-info-icon-line--height $c-transport-ticket__detailed-info-icon-line--style $c-transport-ticket__detailed-info-icon-line--color;
    top: $c-transport-ticket__detailed-info-icon-line--top-alignment;
    left: 50%;
    transform: translateX(-50%);
  }

  .m-icon {
    font-size: $c-transport-ticket__detailed-info-icon--font-size;
    position: absolute;
    left: $c-transport-ticket__detailed-info-icon--left-alignment;
    top: $c-transport-ticket__detailed-info-icon--top-alignment;
    transform: translateY(-50%);
    background-color: $c-transport-ticket__bg-color;
  }
}

.c-transport-ticket__detailed-info-top {
  color: $c-transport-ticket__detailed-info-top--color;
  font-size: $c-transport-ticket__detailed-info-top--font-size;
  z-index: $c-transport-ticket__detailed-info-top--index-fix;
}

.c-transport-ticket__airline-info {
  @include t-text-crop-disable();
  margin-bottom: $c-transport-ticket__airline-info--margin-bottom;
  display: flex;
  align-items: center;
  gap: $c-transport-ticket__airline-info--gap;
}

.c-transport-ticket__detailed-info-additional {
  display: flex;
  justify-content: space-between;
  color: $c-transport-ticket__detailed-info-additional--color;
  font-size: $c-transport-ticket__detailed-info-additional--font-size;
  margin-bottom: 0;

  @include t-mq($from: $c-transport-ticket__detailed-info-additional-breakpoint) {
    flex-direction: row;
  }

  @include t-mq($from: $c-transport-ticket__breakpoint) {
    flex-direction: column;
  }
}

.c-transport-ticket__luggage-info,
.c-transport-ticket__type-ticket,
.c-transport-ticket__luggage-info-container {
  display: flex;
  align-items: center;
}

.c-transport-ticket__type-ticket {
  margin-left: auto;

  @include t-mq($from: $c-transport-ticket__detailed-info-additional-breakpoint) {
    margin-left: auto;
    margin-top: 0;
  }

  @include t-mq($from: $c-transport-ticket__breakpoint) {
    margin-left: 0;
    margin-top: $c-transport-ticket__type-ticket--margin-top-from-bp;
  }
}

.c-transport-ticket__detailed-info-additional .m-icon {
  margin-right: $c-transport-ticket__detailed-info-additional-icon--margin-right;
}

.c-transport-ticket__luggage-info:not(:last-child) {
  margin-right: $c-transport-ticket__luggage-info--margin-right;
}

.c-transport-ticket__detailed-info-remark {
  color: $c-transport-ticket__detailed-info-remark--color;
  font-size: $c-transport-ticket__detailed-info-remark--font-size;
  margin-bottom: 0;
}

.c-transport-ticket__detailed-info-additional + .c-transport-ticket__detailed-info-remark {
  margin-top: $c-transport-ticket__detailed-info-remark--margin-top;
}

//States
.c-transport-ticket {
  // hover
  &:hover:not(.c-transport-ticket__no-radio) {
    @include c-transport-ticket__set-bg-color($c-transport-ticket__hover-bg-color,
      $c-transport-ticket__radio--hover-bg-color,
      $c-transport-ticket__contents--border-right--hover-color);
    }

  // is-checked
  &.is-checked.is-checked {
    @include c-transport-ticket__set-bg-color($c-transport-ticket__checked-bg-color,
      $c-transport-ticket__radio--checked-bg-color,
      $c-transport-ticket__contents--border-right--checked-color,
      $c-transport-ticket__price--checked-color);
  }

  .c-transport-ticket__detailed-info-icon .m-icon {
    color: $c-transport-ticket__detailed-info-icon--color;
  }

  // is-disabled
  &.is-disabled {
    opacity: $c-transport-ticket__disabled--opacity;
    pointer-events: none;
  }
}

/* -------------------------------------------------------------------------
 * AIRLINE LOGOS
 */

.c-transport-ticket__airline-logo {
  display: inline-block;
  vertical-align: middle;
  height: $c-transport-ticket__airline-logo--size;
  width: $c-transport-ticket__airline-logo--size;
  background-size: $c-transport-ticket__airline-logo--size;
  background-repeat: no-repeat;
  background-position: center;
}

/* -------------------------------------------------------------------------
 * Booking flight changed
 */

.c-transport-ticket__data--old {
  text-decoration: line-through;
  margin-right: $c-transport-ticket__data--old--margin-right;
}
.c-transport-ticket__data--new {
  color: $c-transport-ticket__data--new--color;
}

/* -------------------------------------------------------------------------
 * Bus
 */

.c-transport-ticket--bus {

  .c-transport-ticket__airline-info {
    margin-bottom: 0;
    color: $c-transport-ticket-bus__airline-info--color;
  }

  .c-transport-ticket__contents {
    padding-top: $c-transport-ticket-bus__contents--vertical-padding;
    padding-bottom: $c-transport-ticket-bus__contents--vertical-padding;
  }

  .c-transport-ticket__detailed-info-bottom {
    margin-bottom: $c-transport-ticket-bus__detailed-info-bottom--margin-bottom;
  }
  .c-transport-ticket__detailed-info-departure {
    font-family: $c-transport-ticket-bus__detailed-info-departure--font-family;
    font-size: $c-transport-ticket-bus__detailed-info-departure--font-size;
    font-weight: $c-transport-ticket-bus__detailed-info-departure--font-weight;
  }

  .c-transport-ticket__detailed-info-top {
    margin-bottom: $c-transport-ticket-bus__detailed-info-top--margin-bottom;
  }
}

.c-transport-ticket__pnr-number-container .c-tooltip:before {
  font-size: $c-transport-ticket__pnr-number-tooltip--font-size;
  line-height: $c-transport-ticket__pnr-number-tooltip--line-height;
}

.c-transport-ticket__type-ticket-text-container {
  display: flex;
  flex-direction: column;
  gap: $c-transport-ticket__type-ticket-text-container--gap;
}
