// Flow
.c-btn--secondary,
a.c-btn--secondary {
  color: $c-btn-secondary--color;
  background-color: $c-btn-secondary--bg-color;
  border-color: $c-btn-secondary--border-color;
  box-shadow: $c-btn-secondary--shadow;

  .c-btn__loader {
    @include c-loader__set-color($c-btn-secondary--color);
  }

  &:hover {
    color: $c-btn-secondary--hover--color;
    background-color: $c-btn-secondary--hover--bg-color;
    border-color: $c-btn-secondary--hover--border-color;

    .c-btn__loader {
      @include c-loader__set-color($c-btn-secondary--hover--color);
    }
  }

  &:active {
    color: $c-btn-secondary--pressed--color;
    background-color: $c-btn-secondary--pressed--bg-color;
    border-color: $c-btn-secondary--pressed--border-color;
    box-shadow: $c-btn-secondary--pressed--shadow;

    .c-btn__loader {
      @include c-loader__set-color($c-btn-secondary--pressed--color);
    }
  }

  &:focus {
    box-shadow: $c-btn-secondary--focus--shadow;
  }
}
