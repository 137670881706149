// Item Type vars
// --------------------------------------------------

$c-actionable-list__item-link__mark--color:            $c-actionable-list__item--selected--color !default;
$c-actionable-list__item-link__mark--bg-color:         transparent !default;

$c-actionable-list__item-link--selected__mark--color:  $f-color__state-info--semi-dark !default;


// Item Type styles
// --------------------------------------------------

.c-actionable-list__item-link {
  display: block;
  color: $c-actionable-list__item--color;
  font-weight: $c-actionable-list__item--font-weight;

  mark {
    color: $c-actionable-list__item-link__mark--color;
    background-color: $c-actionable-list__item-link__mark--bg-color;
  }

  &:hover {
    text-decoration: none;
    [data-field]:first-child {
      color: $c-actionable-list__item--selected--color;
    }
    mark {
      color: $c-actionable-list__item-link--selected__mark--color;
      background-color: $c-actionable-list__item-link__mark--bg-color;
    }
  }

  > *[data-field] {
    display: block;
    &::after {
      content: ",\00a0";
      display: inline-block;
    }
    &:first-child,
    &:last-child {
      &::after {content: "";}
    }
    &:not(:first-child) {
      display: inline-block;
      color: $c-actionable-list__item--secondary-color;
      font-size: $c-actionable-list__item--secondary-font-size;
    }
  }

}
