.fr-acco-room-types {
  .fr-acco-room-types__description {
    @include sp-stack($fr-acco-room-types__description--sp-stack);
  }

  .fr-acco-room-types__accordion {
    @include sp-stack($fr-acco-room-types--sp-stack);

    .fr-acco-room-types__photo-gallery-room-types{
      @include sp-stack($fr-acco-room-types__photo-gallery--sp-stack);
    }
  }

  .fr-acco-room-types__accordion-description {
    font-weight: $fr-acco-room-types__description--font-weight;
    @include sp-stack($fr-acco-room-types__description--sp-stack);
  }

  .fr-acco-room-types__list {
    @include sp-stack($f-space--none);
  }

  .fr-acco-room-types__list-item {
    @include sp-stack($fr-acco-room-types__list-item--sp-stack);

    @include t-mq($until: $c-accordion--bp) {
      font-size: $fr-acco-room-types__list-item--font-size--until-bp;
    }

    &:last-child {
      @include sp-stack($f-space--none);
    }
  }

  .fr-acco-room-types__extra-info {
    margin-top: $fr-acco-room-types__extra-info--margin-top;
    @include sp-inset-h($fr-acco-room-types__extra-info--sp-inset);

    @include t-mq($from: $c-accordion--bp) {
      @include sp-inset-h($fr-acco-room-types__extra-info--sp-inset--from-bp);
    }

    .fr-acco-room-types__extra-info-title {
      @include sp-stack($fr-acco-room-types__extra-info-title--sp-stack);
    }
  }
}
