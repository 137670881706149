.fr-destination-page__expert-tips-slider.c-slider--no-buttons.c-slider--outer-navigation {
   .tns-outer {
    margin: 0 $fr-destination-page__expert-tips--margin-offset;
    padding: 0 $fr-destination-page__expert-tips--padding-offset;
  }

  .tns-inner {
    overflow: visible;
  }
}

.fr-destination-page__expert-tips-slider .c-slider__item {
  margin-bottom: $fr-destination-page__expert-tips-c-slider-item--margin-bottom;
}
