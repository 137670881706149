/* -------------------------------------------------------------------------
 * COLLECTION
 *
 */

$t-collection__c-slider--package-item__item-listed--sp-stack:         $f-space--small !default;
$t-collection__c-slider--package-item__item-listed--sp-inset-h:       $f-space--small / 2 !default;
$t-collection__other-collections--bullet-list--margin-bottom:         $f-space !default;
$t-collection__other-collections--button--margin-bottom:              $f-space--tiny !default;

$fr-other-collections__c-slider-item--margin-top:                     0 !default; 
$fr-other-collections__c-slider-item--margin-bottom:                  $f-space--tiny !default;
