/* -------------------------------------------------------------------------
 * IMAGE BOX
 */

$c-img-box--z-index:                                    map_get($f-z-indexes--usage, "c-img-box") !default;
$c-img-box--z-index:                                    map_get($f-z-indexes--usage, "c-img-box") !default;
$c-img-box--z-index:                                    map_get($f-z-indexes--usage, "c-img-box") !default;

// Zoom buttons

$c-img-box__image--zoom2x:                              200% !default;
$c-img-box__image--zoom4x:                              400% !default;
$c-img-box__image--zoom8x:                              800% !default;
$c-img-box__buttons--margin:                            $f-space !default;
$c-img-box__buttons--margin-right--close-button:        $f-space--tiny !default;
$c-img-box__buttons--margin-top--close-button:          -1 * ($f-space--medium + $f-space--small) !default;
$c-img-box__buttons--zoom--in--margin-bottom:           $f-space--tiny !default;
$c-img-box__btn-zoom--color:                            $f-color__neutral-white !default;
$c-img-box__btn-zoom--hover--color:                     $f-color__neutral-white !default;
$c-img-box__btn-zoom--bg-color:                         $f-color__neutral-black !default;
$c-img-box__btn-zoom--active-bg-color:                  $f-color__neutral-grey--semi-dark !default;
$c-img-box__btn-zoom--disabled-color:                   $f-color__neutral-grey--ultra-light !default;
$c-img-box__btn-zoom--disabled-bg-color:                $f-color__neutral-grey--semi-light !default;
$c-img-box__btn-zoom--size:                             ($f-space--medium + $f-space--small) !default;
$c-img-box__btn-zoom--border-radius:                    $f-radius--rounded !default;
$c-img-box__btn-zoom--transition-property:              background-color !default;
$c-img-box__btn-zoom--transition-duration:              $f-duration--fast !default;
$c-img-box__btn-zoom--transition-easing:                $f-easing--in !default;
$c-img-box__btn-zoom--font-size:                        20px !default;
$c-img-box__btn-zoom_icon--left-fix:                    8px !default;
$c-img-box__btn-zoom_icon--color:                       $f-color__neutral-grey--semi-light !default;
$c-img-box__btn-zoom_icon--hover--color:                $f-color__neutral-white !default;
$c-img-box__btn-zoom_icon--active--color:               $f-color__neutral-white !default;
$c-img-box__btn-zoom_icon--disabled--color:             $f-color__neutral-grey--ultra-light !default;
