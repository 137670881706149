/* -------------------------------------------------------------------------
* Textbox
*
*/

.c-textbox {
  color: $c-textbox--color;
  position: relative;

  &.has-value {
    .c-textbox__clear {
      display: block;
    }
  }

  &.is-required:not(.c-textbox--without-required-symbol) {
    .c-textbox__label:after {
      content: ' *';
      display: inline;
    }
  }
}

.c-textbox__wrapper {
  position: relative;
  display: block;
  width: 100%;
  border-radius: $c-textbox--border-radius;
}

.c-textbox__text,
.c-textbox__icon,
.c-textbox__clear {
  position: absolute;
  z-index: $c-textbox__icons--z-index;
  top: 50%;
  transform: translateY(-50%);
}

.c-textbox__text,
.c-textbox__icon {
  pointer-events: none;
  left: $c-textbox__icon--position;
  background-color: $c-textbox__wrapper--bg-color;
  font-size: $c-textbox__icon--font-size;
  color: $c-textbox__icon--color;
  border-radius: $c-textbox--border-radius;
}

.c-textbox__text--right,
.c-textbox__icon--right {
  left: auto;
  right: $c-textbox__icon--position;
}

.c-textbox__clear {
  display: none;
  right: $c-textbox__clear--right;
  color: $c-textbox__clear--color;
  background-color: $c-textbox__wrapper--bg-color;
  border-radius: $c-textbox--border-radius;
  @include sp-inline($c-textbox__clear--space-inline);

  &:hover {
    color: $c-textbox__clear--color--hover;
    background-color: $c-textbox__element--bg-color;
  }

  .c-btn__icon {
    &::#{$m-icons__pseudo-element} {
      font-size: $c-textbox__clear--font-size;
      left: auto;
      right: 50%;
      transform: translateX(50%);
    }
  }
}

.c-textbox__element {
  appearance: none;
  outline: 0;
  box-shadow: none;
  background: $c-textbox__element--bg-color;
  margin: 0;
  border: $c-textbox__element--border-size $c-textbox__element--border-style $c-textbox__element--border-color;
  border-radius: $c-textbox--border-radius;
  width: 100%;
  padding: $c-textbox--sp-inset-v $c-textbox--sp-inset-h;
  position: relative;
  cursor: text;
  font-family: $c-textbox__element--font-family;
  font-size: $c-textbox__element--font-size;
  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: $c-textbox--duration;
  transition-timing-function: $c-textbox--easing;

  &:hover {
    border-color: $c-textbox__element--border-color--hover;
  }

  &:focus,
  &.is-focused {
    box-shadow: $c-textbox__element--shadow--focus;
    border-color: $c-textbox__element--border--focus;
  }

  &::placeholder {
    color: $c-textbox__element--placeholder-color;
    opacity: 1;
  }
}

.c-textbox__text--left + .c-textbox__element,
.c-textbox__icon--left + .c-textbox__element,
.c-textbox__icon--left + .c-textbox__icon--right.c-textbox__icon--password + .c-textbox__element {
  text-indent: $c-textbox__element--text-indent--with-icon;
}

.c-textbox__icon--right {
  & + .c-textbox__element {
    padding-right: $c-textbox--padding-right--icon-right;
  }
  & ~ .c-textbox__clear {
    right: calc(#{$c-textbox__icon--position} + #{$c-textbox__icon--font-size});
  }
}

.c-textbox__icon--right + .c-textbox__element {
  padding-right: $c-textbox--padding-right--icon-right;
}

.c-textbox__messages {
  font-size: $c-textbox__messages--font-size;
  display: flex;
}

.c-textbox__message:first-child {
  margin-top: $c-textbox__messages--margin-top;
}

// States
// -------------------------------------------------

// Disabled
.c-textbox.is-disabled,
.is-disabled .c-textbox {

  .c-textbox__element {
    pointer-events: none;
  }
}

.c-textbox.is-disabled {
  .c-textbox__icon {
    background-color: $c-textbox__disabled--bg-color;
  }
}

.is-disabled .c-textbox {
  .c-textbox__wrapper {
    opacity: $c-textbox__disabled--opacity;
  }
}

// Success
.c-textbox.has-success,
.has-success .c-textbox {
  .c-textbox__element {
    border-color: $c-textbox__element--success--border-color;

    &:focus,
    &.is-focused {
      box-shadow: $c-textbox__element--success--shadow--focus;
    }
  }
}

// Warning
.c-textbox.has-warning,
.has-warning .c-textbox {
  .c-textbox__element {
    border-color: $c-textbox__element--warning--border-color;

    &:focus,
    &.is-focused {
      box-shadow: $c-textbox__element--warning--shadow--focus;
    }
  }
}

// Error
.c-textbox.has-error,
.has-error .c-textbox {
  .c-textbox__element {
    border-color: $c-textbox__element--error--border-color;

    &:focus,
    &.is-focused {
      box-shadow: $c-textbox__element--error--shadow--focus;
    }
  }
  .c-textbox__message {
    color: $c-textbox__message--error--color
  }
}

// Modifiers
// -----------------------------------------

// Highlighted
.c-textbox--highlighted {
  .c-textbox__element {
    color: $c-textbox__element--highlighted--color;
  }
}

//Fake dropdown

.c-textbox__fake-dropdown {
  border: none;
  width: auto !important;
  padding: 0;
  margin: $f-space--medium $f-space--tiny $f-space--medium $f-space--tiny;
  color: $c-textbox__element--placeholder-color;
}

.c-textbox__arrow {
  top: 50%;
  transform: translateY(-50%);
  color: $c-textbox__element--placeholder-color;

  &:hover{
    cursor: pointer;
  }
}

// Dynamic width
.c-textbox.c-textbox--dynamic-width {

  margin-bottom: $f-space--medium * .5;
  &:hover {
    cursor: pointer !important;
  }
  .c-textbox__element.c-textbox__element__dynamic-width  {
    background: none;
    border: none;
    width: auto;
    padding: 0;
    margin-right: $f-space--tiny;
    margin-left: $f-space--tiny;
    margin-bottom: 0px;
    margin-top: 0px;
    color: $c-textbox__element--placeholder-color;
    min-height: 5px;
    &:hover {
      cursor: pointer !important;
    }
  }
  .c-textbox__icon,
  .c-textbox__arrow {
    position: relative;
    transform: none;
    top: none;
  }
}

//textbox password input type

.c-textbox__icon.c-textbox__icon--password {
  pointer-events: initial;
  cursor: pointer;
  font-size: $c-textbox__icon-password--font-size;
  color: $c-textbox__icon-password--color;
}

.c-textbox__icon--password.is-hidden {
  display: none;
}

//textbox password requirements

.c-textbox__password-requirements-container {
  width: calc(100% - #{$c-textbox__password-requirements-container--right-margin});
  display: flex;
  flex-wrap: wrap;
  column-gap: $c-textbox__password-requirements-container--column-gap;
  row-gap: $c-textbox__password-requirements-container--row-gap;
  margin-bottom: 0;
  margin-top: $c-textbox__password-requirements-container--margin-top;
  @include t-mq($until: $c-textbox__password-requirements-container--bp) {
    column-gap: unset;
  }
}

.c-textbox__password-requirements-container:empty {
  margin-bottom: 0;
  margin-top: 0;
}

.c-textbox__password-requirement {
  font-size: $c-textbox__messages--font-size;
  margin-bottom: $c-textbox__password-requirement--margin-bottom;
  color: $c-textbox__password-requirement--color;
  flex-basis: $c-textbox__password-requirement--flex-basis;
  position: relative;
  @include t-mq($until: $c-textbox__password-requirements-container--bp) {
    flex-basis: 100%;
  }
}

.c-textbox__password-requirement--checked {
  color: $c-textbox__password-requirement-checked--color;
  list-style: none;
}

.c-textbox__password-requirement-icon {
  display: none;
  position: absolute;
  left: $c-textbox__password-requirement-icon--left;
}

.c-textbox__password-requirement--checked .c-textbox__password-requirement-icon {
  display: initial;
}

.c-textbox__element[type="password"] {
  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
}
