/* -------------------------------------------------------------------------
 * BOOKING BACK BUTTON VARS
 *
 */

.w-booking-back-button {
  @include sp-stack($w-booking-back-button--sp-stack);

  .w-booking-back-button__btn,
  a.w-booking-back-button__btn  {
    display:inline-flex;
    align-items:center;
    text-transform: none;
    
    &:hover {
      color: $w-booking-back-button__btn--hover-color;
      text-decoration: underline;
    }
    .c-btn__icon{
      margin-top: $w-booking-back-button__btn-icon--margin-top;
    }
  }

  &.is-unresolved {
    .c-btn__text {
      @include a-unresolved($_border-radius: $w-booking-back-button--unresolved-border-radius);
    }
  }
}
