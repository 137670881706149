.fr-quick-search {
  .fr-quick-search__title.u-align-left {
    @include t-shared-header-img__title($_align: left);
  }

  .fr-quick-search__title {
    @include t-shared-header-img__title();
  }

  .fr-quick-search__subtitle.u-align-left {
    @include t-shared-header-img__subtitle($_align: left);
  }

  .fr-quick-search__subtitle {
    @include t-shared-header-img__subtitle();
  }

  .m-heading + .w-quick-search {
    margin-top: $fr-quick-search__quick-search--margin-top;
    @include t-mq($from: $fr-quick-search__content--bp) {
      margin-top: $fr-quick-search__quick-search--margin-top--from-mq;
    }
  }

  .fr-quick-search__content {
    @include sp-inset($fr-quick-search__content--sp-inset-h, $fr-quick-search__content--sp-inset-w);
    @include t-mq($from: $fr-quick-search__content--bp) {
      @include sp-inset($fr-quick-search__content--sp-inset-h--from-mq, $fr-quick-search__content--sp-inset-w--from-mq);
    }
  }

  &.feature-toggle-new-styles {

    .c-background-img{
      position:relative;
    }
    .c-background-img__img {
      border-radius: 0 0 16px 16px;
      height: 300px;
      @include t-mq($from: $f-breakpoint--md) {
        border-radius: 0;
        height: 100%;
      }
    }
    .fr-quick-search__title-wrapper {
      margin-top: 36px;
      height: 220px; 
      overflow: hidden;
      @include t-mq($from: $f-breakpoint--sm) {
        margin-top: 12px;
      }
      @include t-mq($from: $f-breakpoint--md) {
        margin-top: 32px;
      }
      .fr-quick-search__title {
        padding-top: .25em;
        @include t-mq($from: $f-breakpoint--md) {
          font-size: 56px !important;
        }
      }
    }

    &.has-publication-buttons {
      .fr-quick-search__title-wrapper {
        height: 250px; 
      }
      .c-background-img__img {
        height: 408px;
        @include t-mq($from: $f-breakpoint--md) {
          height: 100%;
        }
      }
    }
  }
}
