
/* -------------------------------------------------------------------------
 * LIST BARE MIXINS
 *
 */


// Object as a mixin
// --------------------------------------------------

@mixin o-list-bare() {
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
}

// Unset as mixin
// --------------------------------------------------

@mixin o-list-bare--unset() {
  list-style: inherit;
  margin-left: inherit;
}
