/* -------------------------------------------------------------------------
 * SEARCH MAP VARS
 *
 */

$w-search-map--first-bp:                                                                'sm' !default;
$w-search-map--second-bp:                                                               'md' !default;
$w-search-map--third-bp:                                                                'lg' !default;

$w-search-map__modal--width:                                                            calc(100% - #{$f-space--large}) !default;
$w-search-map__modal--height:                                                           calc(100% - #{$f-space--large}) !default;
$w-search-map__modal--max-width:                                                        1440px !default;
$w-search-map__modal--min-height--from-bp:                                              100% !default;
$w-search-map__modal--min-width--from-bp:                                               100% !default;

$w-search-map__btn--color:                                                              $f-color__neutral-grey--dark !default;

$w-search-map__search-results--width:                                                   350px !default;

$w-search-map__search-result-component--max-width--from-bp:                             420px !default;

$w-search-map__search-results-container--sp:                                            $f-space--small !default;

$w-search-map__search-result--margin-bottom:                                            $w-search-map__search-results-container--sp !default;

$w-search-map__search-results-container--offset:                                        -35px !default;

$w-search-map__results-loader--bottom:                                                  50% !default;
$w-search-map__results-loader--right:                                                   50% !default;
$w-search-map__results-loader--transform:                                               translate(50%, calc(50% - #{$w-search-map__search-results-container--offset})) !default;

$w-search-map__modal-header--z-index:                                                   $f-z-index--1 !default;
$w-search-map__modal-header--right:                                                     $f-space--small !default;
$w-search-map__modal-header--top:                                                       $f-space--small !default;

$w-search-map__mobile-filters-btn--bg-color:                                            $f-color__neutral-black !default;
$w-search-map__mobile-filters-btn--bg-color-hover:                                      $f-color__brand-primary !default;
$w-search-map__mobile-filters-btn--bg-color-active:                                     $f-color__brand-primary--dark !default;

$w-search-map__modal-header-btn--bg-color:                                              $f-color__neutral-white !default;
$w-search-map__modal-header-btn--color:                                                 $f-color__neutral-grey--dark !default;
$w-search-map__header--sp-inset:                                                        18px $f-space--small !default;
$w-search-map__btn--border-color:                                                       $f-color__neutral-grey--semi-light !default;
$w-search-map__modal-header-btn--border:                                                2px solid $w-search-map__btn--border-color !default;
$w-search-map--modal-header-btn--measure:                                               46px !default;
$w-search-map--modal-header-btn--measure--until-first-bp:                               35px !default;
$w-search-map__modal-header-btn--border-radius:                                         $w-search-map__modal-header-btn--measure / 2 !default;

$w-search-map__modal-header-btn-icon--font-size:                                        $f-font-size--l !default;
$w-search-map__modal-header-btn-icon--font-size--until-first-bp:                        $f-font-size--m !default;

$w-search-map__info-window-container--color:                                            $f-color__neutral-white !default;
$w-search-map__info-window-container--bottom:                                           10px !default;
$w-search-map__info-window-container--left:                                             $w-search-map__info-window-container--bottom !default;
$w-search-map__info-window-container--right:                                            $w-search-map__info-window-container--bottom !default;
$w-search-map__info-window-container--width--from-bp:                                   530px !default;
$w-search-map__info-window-container--left--from-bp:                                    50% !default;

$w-search-map__search-result-condensed-tiny--box-shadow:                                2px 2px 10px rgba(0, 0 ,0 , 0.20) !default;

$w-search-map__search-result--bg-color--hover:                                          $f-color__state-info--ultra-light !default;
$w-search-map__search-result--border--hover:                                            1px solid $f-color__state-info !default;

$w-search-map__refresh-btn--margin-top:                                                 $f-space--small !default;
$w-search-map__refresh-btn--margin-top--from-bp:                                        $f-space--small !default;
$w-search-map__refresh-btn--font-size--until-first-bp:                                  $f-font-size--xs !default;
$w-search-map__refresh-btn--padding--until-first-bp:                                    $f-space--small 16px !default;
$w-search-map__refresh-btn--min-width--until-first-bp:                                  100px !default;

$w-search-map__search-results-container-empty--padding:                                 0  $f-space--medium !default;
$w-search-map__search-results-container-no-results-title--font-family:                  $f-font-family__secondary !default;
$w-search-map__search-results-container-no-results-title--font-size:                    $f-font-size--xl !default;
$w-search-map__search-results-container-no-results-title--font-weight:                  700 !default;
$w-search-map__search-results-container-no-results-title--margin-bottom:                8px !default;

$w-search-map__btn--border-color--hover:                                                $f-color__neutral-grey--dark !default;

$w-search-map__loader-translate:                                                        translate(calc(-50% + 175px),-50%) !default;
$w-search-map__loader-translate--until-bp:                                              translate(-50%,-50%) !default;

$w-search-map__side-drawer-footer-header--padding:                                      18px 12px !default;

$w-search-map__filters-btn--font-size:                                                  26px !default;
$w-search-map__filters-btn--font-size--until-first-bp:                                  $f-font-size--l !default;
