// Block modifier

.c-nav--block {
  overflow: hidden;

  .c-nav__list {
    margin-top: 0;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
  }

  .c-nav__item {
    @include t-text-crop-disable();
    border-top: $c-nav__item--block--border-style $c-nav__item--block--border-width $c-nav__item--block--border-color;
    margin-bottom: 0;

    &.has-child {
      > .c-nav__link {
        font-size: $c-nav--font-size;
        color: $c-nav__link--color;
        pointer-events: initial;
        padding-right: $c-nav--list--sp-inset-h * 2;
      }
    }

    &.is-active {
      > .c-nav__link {
        border-left: $c-nav__item--active--block--border-width $c-nav__item--active--block--border-style $c-nav__item--active--block--border-color;
        text-indent: -1 * $c-nav__item--active--block--border-width;
        background-color: $c-nav__link--block--active--bg-color;
        font-weight: $c-nav__link--block--active--font-weight;
      }
    }

    &:first-child {
      border-top: none;
    }

  }

  .c-nav__link {
    cursor: pointer;
    display: block;
    position: relative;
    @include sp-inset-h($c-nav--block--sp-inset-h);
    @include sp-inset-v($c-nav--block--sp-inset-v);
    &:not(.c-favorite-collector) {
      @include m-icon("chevron-right", "after");
    }

    &::after {
      position: absolute;
      right: $c-nav__icon--block--right;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      background-color: $c-nav__link--block--hover--bg-color;
      color: $c-nav__link--block--color;
    }

    &:active {
      background-color: $c-nav__link--block--pressed--color;
    }
  }

  .c-nav__form-submit {
    @include sp-inset-h($c-nav--block--sp-inset-h);
    @include sp-inset-v($c-nav--block--sp-inset-v);
  }

  .c-nav__link--form-wrapper {
    padding: 0;
  }

  .c-nav__link-icon {
    display: block;
    position: absolute;
    left: $c-nav--dropdown--sp-inset-h;

    + .c-nav__link-text{
      padding-left: 24px;
    }
  }
}

// With multilevel
.c-nav--block.c-nav--multilevel {
  .c-nav__list {
    display: none;
    margin-left: 100%;
    position: absolute;

    &[data-c-nav__level="1"] {
      margin-left: 0;
      display: block;
      position: relative;
      transition: margin-left $c-nav__list--block--border-width;
    }
  }

  &[data-c-nav__shown-level="1"] > .c-nav__list {
    margin-left: 0;
  }
  &[data-c-nav__shown-level="2"] > .c-nav__list {
    margin-left: -100%;
  }
  &[data-c-nav__shown-level="3"] > .c-nav__list {
    margin-left: -200%;
  }
  &[data-c-nav__shown-level="4"] > .c-nav__list {
    margin-left: -300%;
  }

  .c-nav__list.c-nav__list--sibling-children {
    position: relative;
    left: auto;
    top: auto;
    margin-top: 0;
    margin-left: 0;
    display: block;

    .c-nav__item {
      border: none;
      text-indent: 0;
    }

    .c-nav__link, .c-nav__form-submit {
      line-height: $c-nav--line-height;
      padding-left: $c-nav__link--block--children--padding-left;
    }
  }

  .c-nav__item:not(.c-nav__item--favorite-collector) {
    &.is-opened > .c-nav__list {
      display: block;
    }

    > .c-nav__link {
      &::after {
        content: '';
      }
    }
  }

  .c-nav__item.has-child:not(.c-nav__item--favorite-collector) {
    > .c-nav__link {
      @include m-icon("chevron-right", "after");

      &::after {
        position: absolute;
        right: $c-nav__icon--block--right;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .c-nav__item--back {
    @include sp-inset-h($c-nav__back--sp-inset-h);

    .c-nav__link-text {
      min-height: $c-nav__item--back--height;
      display: block;
    }

    > .c-nav__link {
      text-transform: $c-nav__back--block--text-transform;
      font-size: $c-nav__back--block--font-size;
      color: $c-nav__back--block--color;
      cursor: pointer;
      @include m-icon("chevron-left", "before");

      &:before {
        position: absolute;
        left: 0;
        transform: translateY($c-nav__back--block--vertical-align);
      }
    }
  }
}
