@mixin progress-color($color, $bg-color) {
  progress[role],
  progress[aria-valuenow]:before {
    background-color: $color;
  }
  progress[aria-valuenow] {
    background: $bg-color;
  }

  progress,
  progress[role] {
    color: $color;
  }
  progress::-moz-progress-bar {
    color: $color;
    background-color: $color;
  }
  progress::-webkit-progress-bar {
    background: $bg-color;
  }
  progress::-webkit-progress-value {
    background: $color;
  }
}
