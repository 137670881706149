
/* -------------------------------------------------------------------------
 * STATE WARNING Color Scheme
 *
 */

// Color Scheme output

@if $cs-state-warning--enabled {
  .cs-state-warning {
    @include color-scheme($cs-state-warning)
  }
}


// Color Scheme Modifiers
// -------------------------

// White modifier

@if $cs-state-warning--enabled and $cs-state-warning--white--enabled {
  .cs-state-warning--white {
    @include color-scheme(s-core-map-diff($cs-state-warning, $cs-state-warning--white))
  }
}

// Light modifier

@if $cs-state-warning--enabled and $cs-state-warning--light--enabled {
  .cs-state-warning--light {
    @include color-scheme(s-core-map-diff($cs-state-warning, $cs-state-warning--light))
  }
}
