/* -------------------------------------------------------------------------
 * SMART APP BANNER VARS
 */

$w-smart-app-banner--background:                        $f-color__neutral-white !default;
$w-smart-app-banner--color:                             $cs-default--text !default;
$w-smart-app-banner--font-family:                       map_get($m-body__properties, "font-family") !default;
$w-smart-app-banner--shadow:                            0 1px 2px rgba($f-color__neutral-black, 0.2) !default;
$w-smart-app-banner__info--font-weight:                 normal !default;
$w-smart-app-banner__btn--color:                        $e-links__color !default;
