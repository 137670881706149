/* -------------------------------------------------------------------------
 * PACKAGE BLOCK
 *
 */

.c-package-block__included-item {
  .m-icon--airplane {color: $c-package-block__included-item__flight--color;}
  .m-icon--home {color: $c-package-block__included-item__accommodation--color;}
  .m-icon--car {color: $c-package-block__included-item__car--color;}
}
.c-package-block__departure{
  font-family: $c-package-block__departure--font-family;
}
