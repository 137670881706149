/* -------------------------------------------------------------------------
 * COLLAPSE
 */

$c-collapse__duration:                              $f-duration !default;
$c-collapse__easing:                                $f-easing--base !default;

$c-collapse__trigger--color:                        $e-links__color !default;
$c-collapse__trigger--color--hover:                 $e-links__hover-color !default;
$c-collapse__trigger--duration:                     $f-duration--normal !default;
$c-collapse__trigger--text-props:                   map_get($m-body__mod-sizes, "tiny") !default;
$c-collapse__trigger--line-height:                  map_get($c-collapse__trigger--text-props, "line-height") !default;
$c-collapse__trigger--font-size:                    map_get($c-collapse__trigger--text-props, "font-size") !default;
$c-collapse__trigger--disabled:                     $f-color__neutral-grey--semi-light;

$c-collapse--after-fading-padding-top:              $f-space--large !default;
$c-collapse--after-fading-color-default:            map_get($cs-default, "background") !default;
$c-collapse--after-fading-color-light:              map_get($cs-default--light, "background") !default;
$c-collapse--after-fading-transparent:            rgba(255, 255, 255, 0) !default; // Safari on MacOS shows a grey gradient when using 'transparent'

$c-collapse--breakpoints:                           ("sm", "sml", "md") !default;

// Data attributes
// --------------------------------------------------
$c-collapse__attr__component-api:                   'c-collapse' !default;
$c-collapse__attr__max-items:                       'data-#{$c-collapse__attr__component-api}__max-items' !default;
$c-collapse__attr__action:                          'data-#{$c-collapse__attr__component-api}__action' !default;
$c-collapse__attr__opened:                          'data-#{$c-collapse__attr__component-api}__opened' !default;
$c-collapse__trigger-icon--origin:                  center;