
/* -------------------------------------------------------------------------
 * STATE SUCCESS Color Scheme
 *
 */

// Color Scheme output

@if $cs-state-success--enabled {
  .cs-state-success {
    @include color-scheme($cs-state-success)
  }
}


// Color Scheme Modifiers
// -------------------------

// White modifier

@if $cs-state-success--enabled and $cs-state-success--white--enabled {
  .cs-state-success--white {
    @include color-scheme(s-core-map-diff($cs-state-success, $cs-state-success--white))
  }
}

// Light modifier

@if $cs-state-success--enabled and $cs-state-success--light--enabled {
  .cs-state-success--light {
    @include color-scheme(s-core-map-diff($cs-state-success, $cs-state-success--light))
  }
}
