// Item Type vars
// --------------------------------------------------

$c-actionable-list__item-extended__title--font-weight:               600 !default;
$c-actionable-list__item-extended-description--font-size:            $f-font-size--s !default;
$c-actionable-list__item-extended__details--padding-left:            $f-space--small !default;
$c-actionable-list__item-extended__icon--color:                      $f-color__neutral-grey--semi-dark !default;

// Item Type styles
// --------------------------------------------------

.c-actionable-list__item-extended {
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;

  [data-field="title"] {
    font-weight: $c-actionable-list__item-extended__title--font-weight;
  }

  [data-field="description"] {
    font-size: $c-actionable-list__item-extended-description--font-size;
  }

  &:hover {
    [data-field="title"] {
      color: $c-actionable-list__item--selected--color;
    }
  }
}

.c-actionable-list__item-extended__details {
  display: flex;
  flex-direction: column;
  padding-left: $c-actionable-list__item-extended__details--padding-left;
}

.c-actionable-list__item-extended__icon{
  color: $c-actionable-list__item-extended__icon--color;
}
