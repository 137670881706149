/* -------------------------------------------------------------------------
* Date time selector Variables
*
*/

$c-calendar-field--bp:                          "md" !default;
$c-calendar-field__floating-box--sp-bottom:     $f-space--small !default;
$c-calendar-field__label--margin-bottom:        $f-space--small !default;
$c-calendar-field__label--font-size:            $f-font-size--s !default;
$c-calendar-field__label--font-weight:          700 !default;
$c-calendar-field__label--font-family:          $f-font-family__primary !default;