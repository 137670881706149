/* -------------------------------------------------------------------------
 * ROOM LIST VARS
 *
 */

$w-room-list--first-bp:                                                                           $f-breakpoint--xs !default;
$w-room-list--second-bp:                                                                          $f-breakpoint--md !default;

$w-room-list--main-spacing:                                                                       $f-space--tiny !default;
$w-room-list__header--margin-bottom:                                                              $f-space--small !default;

$w-room-list__room-title--color:                                                                  $f-color__state-info--semi-dark !default;
$w-room-list__room-title--font-weight:                                                            700 !default;
$w-room-list__room-title--font-size:                                                              $f-font-size--l !default;
$w-room-list__room-title-occupancy--font-weight:                                                  400 !default;
$w-room-list__room-title-occupancy--font-size:                                                    $f-font-size--s !default;
$w-room-list__room-title-occupancy--margin-left:                                                  $f-space--small !default;
$w-room-list__room-title-icon--font-size:                                                         $f-font-size--xl !default;

$w-room-list__container--border-radius:                                                           $f-radius--medium !default;
$w-room-list__container--border:                                                                  solid 1px $f-color__neutral-grey--semi-light !default;
$w-room-list__container--padding:                                                                 $f-space--small !default;
$w-room-list__container--background-color:                                                        $f-color__neutral-grey--ultra-light !default;

$w-room-list__occupancy-selector--margin-top:                                                     8px !default;
$w-room-list__occupancy-selector--padding-top:                                                    $f-space--small !default;

$w-room-list__number-stepper--sp-v:                                                               $f-space--small !default;
$w-room-list__number-stepper--border-bottom:                                                      1px solid $f-color__neutral-grey--semi-light !default;
$w-room-list__number-stepper--max-width:                                                          240px !default;
$w-room-list__number-stepper--margin-right:                                                       $f-space--small !default;
$w-room-list__occupancy-selector--border-top:                                                     $w-room-list__number-stepper--border-bottom !default;

$w-room-list-item--main-spacing:                                                                  $f-space--small !default;
$w-room-list-item--border-radius:                                                                 $f-radius--small !default;
$w-room-list-item--border:                                                                        solid 1px $f-color__neutral-grey--semi-light !default;
$w-room-list-item--padding:                                                                       $f-space--small !default;
$w-room-list-item--background-color:                                                              $f-color__neutral-white !default;
$w-room-list-item--margin-bottom:                                                                 $f-space--small !default;

$w-room-list-item__name-text--font-size--mobile:                                                  $f-font-size--s !default;

$w-room-list-item__onrequest--font-family:                                                        $f-font-family__secondary !default;
$w-room-list-item__onrequest--font-size:                                                          $f-font-size--xxs !default;
$w-room-list-item__onrequest--font-weight:                                                        400 !default;
$w-room-list-item__onrequest-color:                                                               $f-color__neutral-grey--semi-dark !default;
$w-room-list-item__onrequest--margin-bottom:                                                      $f-space--tiny !default;
$w-room-list-item__onrequest--border-color:                                                       $f-color__neutral-grey--semi-dark !default;
$w-room-list-item__onrequest--border-type:                                                        solid !default;
$w-room-list-item__onrequest--border-size:                                                        1px !default;
$w-room-list-item__onrequest--bg-color:                                                           $f-color__neutral-white !default;

$w-room-list-item__availability--border-color:                                                    $f-color__state-warning--dark !default;
$w-room-list-item__availability--border-type:                                                     solid !default;
$w-room-list-item__availability--border-size:                                                     1px !default;
$w-room-list-item__availability--color:                                                           $f-color__state-warning--dark !default;
$w-room-list-item__availability--bg-color:                                                        $f-color__neutral-white !default;
$w-room-list-item__availability--margin-bottom:                                                   $f-space--tiny !default;

$w-room-list-item__description--font-size:                                                        $f-font-size--xs !default;

$w-room-list__empty-filtered-rooms-message--color:                                                $f-color__state-danger !default;
$w-room-list__empty-filtered-rooms-message--margin-bottom:                                        $f-space--small !default;

$w-room-list-item__price--font-size:                                                              $f-font-size--l !default;
$w-room-list-item__price--padding-left:                                                           $f-space--small !default;
$w-room-list-item__price--font-weight:                                                            700 !default;
$w-room-list-item__price-info--font-size:                                                         $f-font-size--xs !default;
$w-room-list-item__price-info--font-weight:                                                       700 !default;
$w-room-list-item__price--max-width:                                                              40% !default;
$w-room-list-item__price-info--margin-right:                                                      $f-space--tiny !default;
$w-room-list-item__price-info--margin-bottom--until-bp:                                           8px !default;


$w-room-list-item__characteristics-trigger-btn-text--margin-right:                                5px !default;
$w-room-list-item__characteristics-content--margin-top:                                           14px !default;
$w-room-list-item__characteristics-content--margin-bottom:                                        $f-space--tiny !default;

$w-room-list-item__number-stepper-label--margin-right:                                            $f-space--small !default;
$w-room-list-item__number-stepper-label--font-size:                                               $f-font-size--s !default;
$w-room-list-item__number-stepper-label--font-weight:                                             700 !default;

$w-room-list-item__number-stepper-label-description--color:                                       $f-color__neutral-grey !default;
$w-room-list-item__number-stepper-label-description--font-size:                                   $f-font-size--xs !default;
$w-room-list-item__number-stepper-label-description--font-weight:                                 400 !default;
$w-room-list-item__number-stepper-label-description--margin-top:                                  2px !default;

$w-room-list__filtered-out-header--background-color:                                              $f-color__neutral-grey--semi-dark !default;
$w-room-list__filtered-out-header--margin-bottom:                                                 $f-space--small !default;

$w-room-list-item__filtered-out--opacity:                                                         0.5 !default;
$w-room-list-item__filtered-out--padding-top:                                                     ($w-room-list-item--padding / 2) !default;
$w-room-list-item__filtered-out--padding-bottom:                                                  ($w-room-list-item--padding / 2) !default;
$w-room-list-item__filtered-out--border-size:                                                     $f-color__neutral-grey--semi-light;
$w-room-list-item__filtered-out--border-type:                                                     1px !default;
$w-room-list-item__filtered-out--border-color:                                                    solid !default;

$w-room-list-item__room-selection--margin-bottom--until-bp:                                       8px !default;

$w-room-list-item__room-selection--button--sp-inset--mobile:                                      0.5em !default;

$w-room-list--selected-room-type-title--font-weight:                                              500 !default;
$w-room-list--selected-room-type-title--font-size:                                                14px !default;
$w-room-list--selected-room-type-title--margin-bottom:                                            8px !default;
$w-room-list--selected-room-type-title--line-height:                                              24px !default;
$w-room-list-separator--margin:                                                                   18px !default;
$w-room-list-separator--border-top:                                                               1px solid $f-color__neutral-grey--semi-light !default;
 