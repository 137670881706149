/* -------------------------------------------------------------------------
 * BOX
 */

$c-box--bg-color:                                       $e-document__body--bgcolor !default;
$c-box--border-width:                                   1px !default;
$c-box--border-style:                                   solid !default;
$c-box--border-color:                                   $f-color__neutral-grey--light !default;
$c-box--border-radius:                                  $f-radius--small !default;

$c-box--spacing-inset:                                  $f-space--medium !default;

$c-box--shadow:                                         $f-shadow--elevated !default;
$c-box--shadow-color:                                   $f-shadow__color !default;
$c-box--shadow-opacity:                                 $f-shadow__opacity !default;


// Variants
// --------------------------------------

// Contrasted

$c-box--flat--shadow:                                   none !default;

// Arrow

$c-box--arrow--shadow:                                  none !default;
$c-box--arrow--arrow-size:                              12px !default;
$c-box--arrow--arrow-left-position:                     $f-space--medium !default;
$c-box--arrow-title--margin-bottom:                     $f-space--small !default;


// Color schemes
// --------------------------------------------

// Default

$c-box--bg-color--cs-default:                           $cs-default--background !default;
$c-box--border-color--cs-default:                       $cs-default--border !default;

$c-box--bg-color--cs-default--light:                    $cs-default--light--background !default;
$c-box--border-color--cs-default--light:                $cs-default--light--border !default;

$c-box--bg-color--cs-default--dark:                     $cs-default--dark--background !default;
$c-box--border-color--cs-default--dark:                 $cs-default--dark--border !default;

// State

$c-box--bg-color--cs-state-info--light:                 $cs-state-info--light--background !default;
$c-box--border-color--cs-state-info--light:             $cs-state-info--light--border !default;

$c-box--bg-color--cs-state-warning--light:              $cs-state-warning--light--background !default;
$c-box--border-color--cs-state-warning--light:          $cs-state-warning--light--border !default;

$c-box--bg-color--cs-state-error--light:                $cs-state-error--light--background !default;
$c-box--border-color--cs-state-error--light:            $cs-state-error--light--border !default;

$c-box--bg-color--cs-state-success--light:              $cs-state-success--light--background !default;
$c-box--border-color--cs-state-success--light:          $cs-state-success--light--border !default;
