$fr-quick-search__content--bp:                       "sm" !default;
$fr-quick-search__content--text-align:               center !default;

$fr-quick-search__content--sp-inset-h:               $f-space--medium !default;
$fr-quick-search__content--sp-inset-w:               $f-space--small !default;

$fr-quick-search__content--sp-inset-h--from-mq:      $f-space--large !default;
$fr-quick-search__content--sp-inset-w--from-mq:      $f-space--medium !default;
$fr-quick-search__quick-search--margin-top:          10px !default;
$fr-quick-search__quick-search--margin-top--from-mq: 10px !default;
