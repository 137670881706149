$t-shared-header-img__content--bp:               "sm" !default;
$t-shared-header-img__content--text-align:        center !default;

$t-shared-header-img__heading--base:                $m-heading__properties;

$t-shared-header-img__title--heading:               map_get($m-heading__mod-sizes, "large") !default;
$t-shared-header-img__title--heading--from-mq:      map_get($m-heading__mod-sizes, "huge") !default;

$t-shared-header-img__subtitle--margin-top:         16px !default;
$t-shared-header-img__subtitle--heading--from-mq:   map_get($m-heading__mod-sizes, "medium") !default;
