// --------------------------------------------------
// Color schemes overrides
// --------------------------------------------------

.cs-brand-primary {
  .c-icon-row__link {
    color: $c-icon-row__link--color--cs-brand-primary;

    &:hover {
      color: $c-icon-row__link--color---hover--cs-brand-primary;
    }
  }
}

.cs-brand-primary--white {
  .c-icon-row__link {
    color: $c-icon-row__link--color--cs-brand-primary--white;

    &:hover {
      color: $c-icon-row__link--color---hover--cs-brand-primary--white;
    }
  }
}

.cs-brand-primary--light {
  .c-icon-row__link {
    color: $c-icon-row__link--color--cs-brand-primary--light;

    &:hover {
      color: $c-icon-row__link--color---hover--cs-brand-primary--white;
    }
  }
}

.cs-brand-primary--dark {
  .c-icon-row__link {
    color: $c-icon-row__link--color--cs-brand-primary--dark;

    &:hover {
      color: $c-icon-row__link--color---hover--cs-brand-primary--white;
    }
  }
}

.cs-brand-primary--lighten {
  .c-icon-row__link {
    color: $c-icon-row__link--color--cs-brand-primary--lighten;

    &:hover {
      color: $c-icon-row__link--color---hover--cs-brand-primary--white;
    }
  }
}
