/* -------------------------------------------------------------------------
 * COLORS
 *
 * Foundation colors as color
 *
 */

// Utilities output
// --------------------------------------------------

@if ($u-colors--enabled) {

  @each $_color-group__name, $_color-group__map in $u-colors__maps {

    @each $_color-name, $_color-value in $_color-group__map {

      @if ($_color-group__name != "others") {
        $_color-name: unquote("#{$_color-group__name}-#{$_color-name}");
      }

      .u-color--#{$_color-name} {
        color: $_color-value !important;
      }

    }

  }

}
