/* -------------------------------------------------------------------------
 * bookings comparison table
 *
 */

// Reset styles List
.w-booking-comparison-table-features-list,
.w-booking-comparison-table-features-list li,
.w-booking-comparison-table-columns,
.w-booking-comparison-table-columns li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.w-booking-comparison-table-features-list, .w-booking-comparison-table-columns {
  list-style: none;
}
// Reset styles List

.w-booking-comparison-table-table {
  position: relative;
  overflow: hidden;
}

.w-booking-comparison-table-table .w-booking-comparison-table-features {
  position: absolute;
  z-index: 1;
  padding-bottom: $w-booking-comparison-table-features--pd-bottom;
  bottom: 0;
  left: 0;
  width: $w-booking-comparison-table-features--width-mobile;
  background-color: $w-booking-comparison-table-features--bg-color;

  @include t-mq($w-booking-comparison-table--bp-medium) {
    width: $w-booking-comparison-table-features--width-tablet;
  }

  .w-booking-comparison-table__top-info {
    position: relative;
    height: $w-booking-comparison-table__top-info--height;
    color: $w-booking-comparison-table--color;
    font-family: $w-booking-comparison-table--font-family;
    font-size: $w-booking-comparison-table__top-info--font-size;
    font-style: $w-booking-comparison-table--font-style;
    font-weight: $w-booking-comparison-table--font-weight;
    line-height: $w-booking-comparison-table__top-info--line-height;
    letter-spacing: $w-booking-comparison-table__top-info--letter-spacing;
    display: flex;
    align-items: flex-end;
  }
}
.w-booking-comparison-table-table .w-booking-comparison-table-features .w-booking-comparison-table-features-list li:nth-child(even),
.w-booking-comparison-table-table .w-booking-comparison-table-product .w-booking-comparison-table-features-list li:nth-child(even) {
  background-color: $w-booking-comparison-table-features--event-bg-color;
}

.w-booking-comparison-table-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.w-booking-comparison-table-columns {
  margin-left: $w-booking-comparison-table-features--width-mobile;
  margin-bottom: $w-booking-comparison-table-columns--mg-bottom;
  display: flex;
  align-items: flex-end;
  width: $w-booking-comparison-table-columns--width;

  @include t-mq($w-booking-comparison-table--bp-medium) {
    margin-left: $w-booking-comparison-table-features--width-tablet;
    width: calc(100% - $w-booking-comparison-table-features--width-tablet);
  }

  &::after {
    clear: both;
    content: '';
    display: table;
  }

  .w-booking-comparison-table-product {
    position: relative;
    float: left;
    width: $w-booking-comparison-table-product--width-mobile;
    @include t-mq($w-booking-comparison-table--bp-medium) {
      width: $w-booking-comparison-table-product--width-tablet;
    }
    text-align: center;
    -webkit-transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
    -moz-transition: opacity 0.3s, visibility 0.3s, -moz-transform 0.3s;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
    border: $w-booking-comparison-table-product--border solid $w-booking-comparison-table-product--border-color;
    border-radius: $w-booking-comparison-table-product--border-radius;
    box-shadow: $w-booking-comparison-table-product--shadow rgba($w-booking-comparison-table-product--shadow-color, $w-booking-comparison-table-product--shadow-opacity);
    margin-left: $w-booking-comparison-table-product--margin-left;
    margin-right: $w-booking-comparison-table-product--margin-right;
    flex-grow: 1;
    flex-basis: 0;
    @include t-mq($w-booking-comparison-table--bp-medium) {
      width: calc((100% - $w-booking-comparison-table-features--width-tablet)/ 3);
    }
  }
  .w-booking-comparison-table-product--selected {
    border-color: #21b788;
  }
}

.w-booking-comparison-table-features-list li {
  min-height: $w-booking-comparison-table-features-list-item--min-height;
  color: $w-booking-comparison-table--color;
  font-family: $w-booking-comparison-table--font-family;
  font-size: $w-booking-comparison-table-features-list-item--font-size;
  font-style: $w-booking-comparison-table--font-style;
  font-weight: $w-booking-comparison-table-features-list-item--font-weight;
  line-height: $w-booking-comparison-table-features-list-item--line-height;
  letter-spacing: $w-booking-comparison-table-features-list-item--letter-spacing;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $w-booking-comparison-table-product--border;
}

.w-booking-comparison-table-features .w-booking-comparison-table-features-list li{
  .m-icon {
    display: none;
  }

  @include t-mq($w-booking-comparison-table--bp-large) {
    flex-direction: row;
    .m-icon {
      display: block;
      padding: 12px;
    }
  }
}

.w-booking-comparison-table-features-list .w-booking-comparison-table__more-info-button {
  color: $w-booking-comparison-table--color;
  font-family: $w-booking-comparison-table--font-family;
  font-size: $w-booking-comparison-table-features-list-item--font-size;
  font-style: $w-booking-comparison-table--font-style;
  font-weight: $w-booking-comparison-table-features-list-item--font-weight;
  line-height: $w-booking-comparison-table-features-list-item--line-height;
  letter-spacing: $w-booking-comparison-table-features-list-item--letter-spacing;
  text-decoration-line: underline;
  cursor: pointer;
}

.w-booking-comparison-table-features .w-booking-comparison-table-features-list li,
.w-booking-comparison-table-table .w-booking-comparison-table-features .w-booking-comparison-table__top-info {
  text-align: left;
  gap: $w-booking-comparison-table-features-list-item--gap;
  @include sp-inset-h($w-booking-comparison-table-features-list-item--sp-inset);
  word-break: break-word;
  @include t-mq($w-booking-comparison-table--bp-large) {
    justify-content: start;
  }
}

.w-booking-comparison-table-columns .w-booking-comparison-table-product .w-booking-comparison-table__top-info {
  color: $w-booking-comparison-table--color;
  text-align: center;
  font-family: $w-booking-comparison-table--font-family;
  font-size: $w-booking-comparison-table__top-info--font-size;
  font-style: $w-booking-comparison-table--font-style;
  font-weight: $w-booking-comparison-table--font-weight;
  line-height: $w-booking-comparison-table__top-info--line-height;
  letter-spacing: $w-booking-comparison-table__top-info--letter-spacing;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: $w-booking-comparison-table-features-list-item--padding-top;
  @include sp-inset-h($w-booking-comparison-table-features-list-item--sp-inset);

  .w-booking-comparison-table-product-price {
    color: $w-booking-comparison-table-product-price--color;
    font-family: $w-booking-comparison-table--font-family;
    font-size: $w-booking-comparison-table-features-list-item--font-size;
    font-style: $w-booking-comparison-table--font-style;
    font-weight: $w-booking-comparison-table-features-list-item--font-weight;
    line-height: $w-booking-comparison-table-features-list-item--line-height;
    letter-spacing: $w-booking-comparison-table-features-list-item--letter-spacing;

    strong {
      color: $w-booking-comparison-table-product-price--color;
      font-size: $w-booking-comparison-table__top-info--font-size;
      font-weight: $w-booking-comparison-table--font-weight;
      line-height: $w-booking-comparison-table__top-info--line-height;
    }
  }
}

.w-booking-comparison-table__button-group {
  display: flex;
  flex-direction: column;
  @include sp-inset-h($w-booking-comparison-table__button-group--sp-inset-mobile);
  @include t-mq($w-booking-comparison-table--bp-medium) {
    @include sp-inset-h($w-booking-comparison-table__button-group--sp-inset-tablet);
  }
  height: $w-booking-comparison-table__button-group--height;
  .c-btn--block {
    min-width: 100%;
    @include sp-inset-h(0);
  }
  .w-booking-comparison-table__more-info-button {
    color: $w-booking-comparison-table__button-group--color;
    text-align: center;
    font-family: $w-booking-comparison-table--font-family;
    font-size: $w-booking-comparison-table__more-info-button--font-size;
    font-style: $w-booking-comparison-table--font-style;
    font-weight: $w-booking-comparison-table-features-list-item--font-weight;
    line-height: $w-booking-comparison-table-features-list-item--line-height;
    letter-spacing: 0.33px;
    text-decoration-line: underline;
  }
}

.w-booking-comparison-table-product .w-booking-comparison-table__label {
  border-radius: $w-booking-comparison-table__label--border-radius;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100%;
  text-align: center;
  padding: $w-booking-comparison-table__label--padding;
}
