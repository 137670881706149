/* -------------------------------------------------------------------------
 * Cookie / Emergency dialog
 *
 */
#master-cookie {
  overflow: auto;
  max-height: $w-cookie-emergency-dialog--max-height;

  ~ .c-absolute-dialog {
    display: none;
  }

  .c-absolute-dialog__content {
    @extend .l-site__wrapper--medium !optional;
    margin: auto;

    @include t-mq ($from: "sml") {
      display: flex;
    }
  }

  .c-absolute-dialog__content-body {
    flex: 1;
  }

  .c-absolute-dialog__content-footer {
    text-align: center;

    @include t-mq ($from: "sml") {
      margin-left: $w-cookie-emergency-dialog__content-footer--margin-left;
    }
  }

  .c-absolute-dialog__close, .cookie-settings__submit {
    display: block;
    width: 100%;
    top: 0;
    right: 0;

    @include t-mq ($from: "sml") {
      display: inline-block;
      width: auto;
      top: $w-cookie-emergency-dialog__close--position-y;
      right: $w-cookie-emergency-dialog__close--position-x;
    }
  }

  .cookie-settings__submit {
    margin-top: $w-cookie-emergency-dialog__cookie-settings--margin-top;
  }

  .cookie-settings__post-form {
    font-size: $w-cookie-emergency-dialog__post-form--font-size;
  }
}

.w-cookie-emergency-dialog {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $w-cookie-emergency-dialog--z-index;
}

// No stack
.w-cookie-emergency-dialog--no-stack {
  .c-absolute-dialog:not(:first-child) {
    display: none;
  }
}

.w-cookie-emergency-dialog--not-full-width {
  left: unset;
}
