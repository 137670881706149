/* -------------------------------------------------------------------------
 * SELECTED STATE
 *
 */

.w-selected-state__conditional-message--wrapper {
  margin-bottom: $w-selected-state--margin-bottom;
  @include sp-inset($w-selected-state--sp-inset);
}

.w-selected-state__conditional-message {
  font-size: $w-selected-state__conditional-message--font-size;
}

.w-selected-state__lists .c-rating {
  color: $w-selected-state__rating--color;
}
