/* -------------------------------------------------------------------------
 * TABLE MIXINS
 *
 */


// Object as a mixin
// --------------------------------------------------

@mixin o-table() {
  width: 100%;
}

@mixin o-table__cell($_space-value: $o-table__space) {
  padding: s-core-px-to-rem($_space-value);
}

// Space modifiers
// --------------------------------------------------

@mixin o-table__mod-space($_space-name: "none", $_space-value: 0) {
  .o-table--space-#{$_space-name} {
    th,
    td {
      padding: s-core-px-to-rem($_space-value);
    }
  }
}

// Fixed modifier
// --------------------------------------------------

@mixin o-table--fixed() {
  table-layout: fixed;
}

// Unset as mixin
// --------------------------------------------------

@mixin o-table--unset() {
  width: auto;
}

@mixin o-table__cell--unset() {
  padding: inherit;
}
