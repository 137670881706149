// Item Type vars
// --------------------------------------------------

$c-actionable-list__item-room__title--font-weight:     600 !default;


// Item Type styles
// --------------------------------------------------

.c-actionable-list__item-room {
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: space-between;

  [data-field="title"] {
    font-weight: $c-actionable-list__item-room__title--font-weight;
  }

  [data-field="description"] {
    font-size: $c-actionable-list__item__room-description--font-size;
  }
}

.c-actionable-list__item:hover .c-actionable-list__item-room [data-field="title"] {
  color: $c-actionable-list__item--selected--color;
}

.c-actionable-list__item-room__details {
  display: flex;
  flex-direction: column;
}

.c-actionable-list__item-room__onrequest {
  align-self: flex-start;
  border: $c-actionable-list__item__room-stock--border-size $c-actionable-list__item__room-stock--border-type $c-actionable-list__item__room-onrequest--border-color;
  border-radius: $c-actionable-list__item__room-stock--border-radius;
  font-family: $c-actionable-list__item__room-onrequest--font-family;
  font-size: $c-actionable-list__item__room-onrequest--font-size;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $c-actionable-list__item__room-onrequest--color;
  padding: 0 $c-actionable-list__item__room-stock--padding-sides;
}

.c-actionable-list__item-room__stock {
  align-self: flex-start;
  border:$c-actionable-list__item__room-stock--border-size $c-actionable-list__item__room-stock--border-type $c-actionable-list__item__room-availability--border-color;
  border-radius: $c-actionable-list__item__room-stock--border-radius;
  color: $c-actionable-list__item__room-availability--color;
  font-size: $c-actionable-list__item__room-availability--font-size;
  font-weight: $c-actionable-list__item__room-availability--font-weight;
  padding: 0 $c-actionable-list__item__room-stock--padding-sides;
}

.c-actionable-list__item-room__price {
  flex-shrink: 0;
  margin-left: $f-space--tiny;
  .c-price__legend {
    color: $c-actionable-list__item__room-price-legend--color;
  }
  .c-price__currency {
    font-size: $c-actionable-list__item__room-price-currency--font-size;
  }
  .c-price__value {
    font-size: $c-actionable-list__item__room-price-value--font-size;
  }

  @if $m-body--enabled {
    .c-price__info {
      @include t-text-crop-disable();
    }
  }
}

