.c-download-button {
  text-decoration: none;
  display: flex;
  text-align: left;
  border: $c-download-button--border-width $c-download-button--border-style $c-download-button--border-color;
  border-radius: $c-download-button--border-radius;
  background-color: $c-download-button--background-color;
  color: $c-download-button--color;
  @include sp-inset($c-download-button--inset);

  & > * + * {
    margin-left: $c-download-button--sibilings--margin-left;
  }

  .m-icon:not(.c-download-button__download-icon) {
    color: $c-download-button__icon--color;
  }

  .m-icon {
    line-height: 1;
  }

  &:not(.is-unresolved):hover {
    color: $c-download-button--hover--color;
    text-decoration: none;
    box-shadow: $c-download-button--hover--shadow rgba($c-download-button--hover--shadow-color, $c-download-button--hover--shadow-opacity);
    background-color: $c-download-button--hover--background-color;

    .c-download-button__download-icon {
      color: $c-download-button__download-icon--hover--color;
    }
  }
}

.c-download-button__titles {
  flex-grow: 1;
  flex-basis: 100%;
}

.c-download-button__title {
  display: block;
  font-family: $c-download-button__title--font-family;
  font-weight: $c-download-button__title--font-weight;
  font-size: $c-download-button__title--font-size;
}

.c-download-button__subtitle {
  display: block;
  margin-top: $c-download-button__subtitle--margin-top;
  font-family: $c-download-button__subtitle--font-family;
  font-size: $c-download-button__subtitle--font-size;
  color: $c-download-button__subtitle--color;
  word-break: break-word;
  word-wrap: break-word;
  font-weight: $c-download-button__subtitle--font-weight;
}

.c-download-button__download-icon {
  display: flex;
  align-items: center;
  color: $c-download-button__download-icon--color;
}

.c-download-button.is-unresolved {
  @include a-unresolved();
}
