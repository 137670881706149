/* -------------------------------------------------------------------------
 * Account bookings VARS
 *
 */

$t-account-bookings--bp:                                                       "xs" !default;

$t-account-bookings__title-heading:                                             map_get($m-heading__mod-sizes, "large") !default;
$t-account-bookings__slider-button--offset:                                     10px !default;

// Bookings list
// ---------------------
$t-account-bookings__booking-list--sp-inset-top:                                $f-space--medium !default;
$t-account-bookings__booking-list--sp-inset-v:                                  48px !default;
$t-account-bookings__booking-list__title:                                       map_get($m-heading__mod-sizes, "tiny") !default;
$t-account-bookings__booking-list__title--from-bp:                              map_get($m-heading__mod-sizes, "small") !default;
$t-account-bookings__booking-list__card-title--font-size:                       $f-font-size--l !default;
$t-account-bookings__booking-list__card-subtitle--font-size:                    $f-font-size--xs !default;
$t-account-bookings__booking-list__card-heading--margin-bottom:                 $f-space--small !default;
$t-account-bookings__booking-list__card-content--font-size:                     $f-font-size--s !default;
$t-account-bookings__booking-list__card-content-icon--margin-right:             $f-space--tiny !default;
$t-account-bookings__booking-list__card--shadow--transition-duration:           $f-duration--normal !default;
$t-account-bookings__booking-list__card--shadow--transition-timing-function:    $f-easing--base !default;
$t-account-bookings__booking-list__card--hover--shadow:                         $f-shadow--elevated !default;
$t-account-bookings__booking-list__card--hover--shadow-color:                   $f-color__neutral-grey !default;
$t-account-bookings__booking-list__card-img--opacity:                           .6 !default;
$t-account-bookings__booking-list-card-label--left:                             16px !default;
$t-account-bookings__booking-list-card-two-labels--top:                         44px !default;
$t-account-bookings__booking-list-card-secondary-label--top:                    16px !default;
$t-account-bookings__booking-list-card-tertiary-label--top:                     72px !default;

// Fragments
$fr-account-upcoming-bookings__c-slider-item--margin-bottom:                    $f-space--tiny !default;
$t-account-bookings__fr-account-booking-message--sp-inset-v:                    $f-space--medium !default;

// Vouchers

$fr-account-vouchers-overview__voucher--margin:                                 $f-space--small !default;
$fr-account-vouchers-overview__header-title--text-transform:                    uppercase !default;

// Customer discount
$w-customer-discount--margin-bottom:                                            $f-space--medium !default;

//Info message
$t-account-bookings__info-message--margin-bottom:                               $f-space--medium !default;

// Add booking
$fr-account-upcoming-bookings-add-booking--margin-top:                          36px !default;
$fr-account-upcoming-bookings-add-booking--margin-bottom:                       -16px !default;
$fr-account-booking-message-add-booking--margin-top:                            -8px !default;
$fr-account-booking-message-add-booking--padding-bottom:                        $f-space--medium !default;
