/* -------------------------------------------------------------------------
 * ROOM SELECTOR
 *
 */

.w-room-selector {
  &:empty {
    display: none;
  }
}

.w-room-selector__message,
.w-room-selector__dropdown-section {
  margin-bottom: $f-space;
  padding-right: $w-room-selector__room--padding-right;

  @include t-mq($from:$w-room-selector--breakpoint) {
    padding-right: $w-room-selector__room--padding-right--desktop;
  }

  &:last-child {
    margin-bottom: 0
  }
}

.w-room-selector__item-wrapper {
  padding-right: $w-room-selector__item-wrapper--padding-right;
  margin-bottom: $f-space;
  @include t-mq($from:$w-room-selector--breakpoint) {
    padding-right: $w-room-selector__item-wrapper--padding-right--desktop;
  }
}

.w-room-selector__dropdown-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  .w-room-selector__dropdown-row{
    @include t-mq($from: $f-breakpoint--md) {
      display: flex;
    }
  }
}

.w-room-selector__dropdown-item {
  flex-basis: $w-room-selector__items-short-width;
}

.w-room-selector__message-short-width {
  @include t-mq($from: $f-breakpoint--md) {
    display: flex;
  }
}

.w-room-selector__message-box {
  flex-basis: $w-room-selector__items-short-width;
  border-radius: $w-room-selector__message-box--border-radius;
}

.w-room-selector__message-container {
  p {
    margin-bottom: $w-room-selector__message-lines--space;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.w-room-selector__warning-container.wrong-allocation-message > p{
  color: $cs-state-error--light--heading;
}

.w-room-selector__message,
.w-room-selector__dropdown-section,
.w-room-selector__age-profile-message {
  margin-bottom: $f-space;
  padding-right: $w-room-selector__room--padding-right;

  @include t-mq($from:$w-room-selector--breakpoint) {
    padding-right: $w-room-selector__room--padding-right--desktop;
  }

  &:last-child {
    margin-bottom: 0
  }
}
.w-room-selector__message-box {
  flex-basis: $w-room-selector__items-short-width;
  border-radius: $w-room-selector__message-box--border-radius;
}

.w-room-selector__message-container {
  p {
    margin-bottom: $w-room-selector__message-lines--space;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.wrong-allocation-message {
  .w-room-selector__message-container {
    p{
      color: $cs-state-error--light--heading;
    }
  }
}

// age profile message styles

.w-room-selector__age-profile-message-trigger-btn { 
  @extend %w-room-selector__trigger-btn-styles;
  margin-right: 0;
  margin-left: $w-room-selector__age-profile-message-trigger-btn--margin-left;
  flex-shrink: 0;
}

.w-room-selector__age-profile-message-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.w-room-selector__age-profile-message-title-text,
.w-room-selector__age-profile-message-profile-title {
  font-weight: 700;
}

.w-room-selector__age-profile-message-content-collapse,
.w-room-selector__age-profile-message-profiles {
  margin-top: $w-room-selector__age-profile-message-content--margin-top;
}

.w-room-selector__age-profile-message-profiles {
  margin-bottom: 0;
}

.w-room-selector__age-profile-message-profile-title {
  width: 1%;
  white-space: nowrap;
  padding-right: $w-room-selector__age-profile-message-profile-title--padding-right;
}

.w-room-selector__age-profile-message-profile-cell:not(:last-child) td {
  padding-bottom: $w-room-selector__age-profile-message-profile-cell--padding-bottom;
}
