/* -------------------------------------------------------------------------
 * BOOKED SERVICE BASKET
 *
 */
.w-booked-service-basket{
  &.has-error{
    display: none;
  }

  .w-booked-service-basket{
    @include sp-stack($w-booked-service-basket__sp-inline);
  }

  @include t-mq($from: $w-booked-service-basket__breakpoint-sml){
    .w-booked-service-basket .c-table__item{
      @include sp-inset-h($w-booked-service-basket__sp-inset-h);
    }
  }
}
.w-booked-service-basket__side-drawer-trigger{
  position: fixed;
  left: 0;
  bottom: 0;
  width: $w-booked-service-basket__side-drawer-trigger--width;
  background-color: $w-booked-service-basket__side-drawer-trigger--background-color;
  z-index: $w-booked-service-basket__side-drawer-trigger--z-index;
  @include sp-inset(
    $w-booked-service-basket__side-drawer-trigger--sp-inset-v,
    $w-booked-service-basket__side-drawer-trigger--sp-inset-h
  );
  @include t-mq($w-booked-service-basket__breakpoint){
    display: none;
  }
}
@include t-mq($w-booked-service-basket__breakpoint){
  .is-unresolved{
    .w-booked-service-basket__without-side-drawer{
      @include a-unresolved();
      &::before, &::after{
        z-index: 1;
      }
    }
  }
}
.w-booked-service-basket__side-drawer-trigger__right{
  display: flex;
  justify-content: center;
}
.w-booked-service-basket__without-side-drawer{
  background-color: $w-booked-service-basket--background-color;
  border: $w-booked-service-basket--border-style $w-booked-service-basket--border-width $w-booked-service-basket--border-color;
  @include sp-inset($w-booked-service-basket__side-drawer--header--padding);
}

.w-booked-service-basket__side-drawer{
  &.c-side-drawer--origin-bottom{
    top: $w-booked-service-basket__side-drawer--top;
    width: $w-booked-service-basket__side-drawer--width;
    height: $w-booked-service-basket__side-drawer--height;
    background-color: $w-booked-service-basket--background-color;
    border: $w-booked-service-basket--border-style $w-booked-service-basket--border-width $w-booked-service-basket--border-color;
    margin-bottom: 0;
    @include t-mq($w-booked-service-basket__breakpoint){
      @include sp-inset($w-booked-service-basket__side-drawer--header--padding);
    }
    .c-side-drawer__close{
      top: 0;
      right: 0;
      transform: translate(0);
    }
    .c-btn__icon{
      color: $w-booked-service-basket__side-drawer--header__btn-icon--color;
    }
    .w-booked-service-basket__side-drawer--header{
      @include sp-inset($w-booked-service-basket__side-drawer--header--padding);
    }
  }
}

.w-booked-service-basket__total-services{
  @include t-mq($until: $w-booked-service-basket__breakpoint){
    .w-booked-service-basket__return-amount,
    .w-booked-service-basket__due-amount{
      display: none;
    }
  }
}

.w-booked-service-basket__due-amount--title,
.w-booked-service-basket__due-amount-value,
.w-booked-service-basket__return-amount--title,
.w-booked-service-basket__return-amount-value{
  color: $w-booked-service-basket__total-services--color;
}

.w-booked-service-basket__due-amount,
.w-booked-service-basket__due-date,
.w-booked-service-basket__down-amount,
.w-booked-service-basket__return-amount{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: $w-booked-service-basket__amount-information--margin-bottom;
  color: $w-booked-service-basket__due-amount--color;
  &.is-hidden{
    display: none;
  }
}
.w-booked-service-basket__service-info{
  display: flex;
  justify-content: space-between;
  @include sp-stack($w-booked-service-basket__service-info--margin-bottom);
}

.w-booked-service-basket__service-info--text{
  font-size: $w-booked-service-basket__service-info--text--font-size;
}

.w-booked-service-basket__service-info--price{
  font-size: $w-booked-service-basket__service-info--price--font-size;
  font-weight: bold;
}

.w-booked-service-basket__service-info--detial{
  display: block;
  justify-content: space-between;
  @include sp-stack($w-booked-service-basket__service-info--margin-bottom);
}

.w-booked-service-basket__booking-info{
  border-bottom: $w-booked-service-basket--border-style $w-booked-service-basket--border-width
    $w-booked-service-basket__booking-info--border-color;
  padding-bottom: $w-booked-service-basket__booking-info--padding-bottom;
  @include sp-stack($w-booked-service-basket__booking-info--margin-bottom);
}

.w-booked-service-basket__services > *:last-child{
  border-bottom: $w-booked-service-basket--border-style $w-booked-service-basket--border-width
    $w-booked-service-basket__booking-info--border-color;
  padding-bottom: $w-booked-service-basket__booking-info--padding-bottom;
  @include sp-stack($w-booked-service-basket__booking-info--margin-bottom);
}

.w-booked-service-basket__booking-total-amount, .w-booked-service-basket__booking-paid-amount{
  display: flex;
  justify-content: space-between;
  @include sp-inset-v($w-booked-service-basket__booking-total-amount--padding-v);
}
.w-booked-service-basket__booking-paid-amount{
  color: $w-booked-service-basket__booking-paid-amount--color;
}
.w-booked-service-basket__side-drawer-trigger__total--text, .w-booked-service-basket__side-drawer-trigger__total--amount{
  color: $w-booked-service-basket__total-services--color;
  display: flex;
  justify-content: space-between;
}

.w-booked-service-basket__side-drawer-btn{
  pointer-events: none;
  height: $w-booked-service-basket__side-drawer-btn--height;
  margin-top: $w-booked-service-basket__side-drawer-btn--margin-top;
  margin-left: $w-booked-service-basket__side-drawer-btn--margin-left;

  .c-btn__icon{
    color: $w-booked-service-basket__side-drawer-btn__icon--color;
    padding-left: $w-booked-service-basket__side-drawer-btn__icon--padding;
    height: $w-booked-service-basket__side-drawer-btn--height;
  }
  .c-btn__icon::before{
    height: $w-booked-service-basket__side-drawer-btn--height;
    color: $w-booked-service-basket__side-drawer-btn__icon--color;
    font-size: $w-booked-service-basket__side-drawer-btn__icon--font-size;
  }
}

.w-booked-service-basket__side-drawer-trigger.in{
  .w-booked-service-basket__side-drawer-btn{
    margin-top: $w-booked-service-basket__side-drawer-btn--margin-top;
    .c-btn__icon::before{
      transform: rotate(180deg);
    }
  }
}

.w-booked-service-basket__backdrop.w-booked-service-basket__backdrop{
  z-index: $w-booked-service-basket__backdrop-z-index;
}

.w-booked-service-basket{
  .w-booked-service-basket__table__item-content--subitem:not(.w-booked-service-basket__subitem--child){
    margin-top: $w-booked-service-basket__subitem--inset-top;
  }
  @include t-mq($until: $w-booked-service-basket__breakpoint-sml){
    .w-booked-service-basket__table__item-content,
    .w-booked-service-basket__table__item-content--subitem{
      margin-left: 0;
      margin-right: 0;
    }
  }
}
.w-booked-service-basket__subitem--indented{
  > .w-booked-service-basket__table__item-content.w-booked-service-basket__table__item-content--subitem.w-booked-service-basket__subitem--child:last-child{
    border-left: $w-booked-service-basket__subitem--child--border-size solid transparent;
  }
  .w-booked-service-basket__table__item-content--subitem.w-booked-service-basket__subitem--child{
    margin-top: 0px;
    border-left: $w-booked-service-basket__subitem--child--border-size solid $w-booked-service-basket__subitem--child--color;
    margin-left: $w-booked-service-basket__subitem--child--inset-left;
    @include t-mq($from: $w-booked-service-basket__breakpoint-sml){
      margin-left: 2 * $w-booked-service-basket__subitem--child--inset-left;
    }
    .w-booked-service-basket__table__item-left{
      padding-left: $w-booked-service-basket__subitem--child--inset-left;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: $w-booked-service-basket__subitem--child--border-size * -1;
        bottom: 50%;
        width: 10px;
        border: $w-booked-service-basket__subitem--child--border-size solid $w-booked-service-basket__subitem--child--color;
        border-top: 0 none transparent;
        border-right: 0 none transparent;
      }
    }
  }
}

.w-booked-service-basket__table__item.w-booked-service-basket__table__item--total{
  &.c-table__item:last-child{
    border-bottom: 0;
    border-top-width: $w-booked-service-basket__table__item--color--total;
    font-weight: $w-booked-service-basket__table__item--font-weight--bold;
    text-transform: $w-booked-service-basket__table__item--text-transform--total;
  }
}

.w-booked-service-basket__table__item-content--subitem{
  display: flex;
  color: $w-booked-service-basket__table__item--color--subitem;
  @include m-heading--complete($w-booked-service-basket__table__item--material--subitem);
}

.w-booked-service-basket__table__item-left{
  flex-grow: 1;
}

.w-booked-service-basket__table__item-right{
  padding-left: $w-booked-service-basket__table__item-right--sp-right;
}
