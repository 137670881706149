/* -------------------------------------------------------------------------
 * ABSOLUTE DIALOG
 *
 */

.c-absolute-dialog {
  min-height: $c-absolute-dialog--min-height;
  border-width: $c-absolute-dialog--outside-border-width;
  border-style: $c-absolute-dialog--outside-border-style;
  @include sp-inset($c-absolute-dialog--sp-inset);
  transition: opacity $c-absolute-dialog--transition-duration;
  position: relative;

  .c-absolute-dialog__close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    padding: $c-absolute-dialog__close--padding-v $c-absolute-dialog__close--padding-h;
  }
}

.c-absolute-dialog.is-hidden {
  opacity: 0;
  overflow: hidden;
}

.c-absolute-dialog[class*="--light"],
.c-absolute-dialog[class*="--white"] {
  .c-absolute-dialog__header .c-absolute-dialog__close {
    color: $f-color__neutral-black;
  }
}

.c-absolute-dialog__header {
  margin-bottom: $c-absolute-dialog__header--margin-bottom;
}

.c-absolute-dialog__title {
  padding-bottom: $c-absolute-dialog__title--padding-bottom;
  border-style: $c-absolute-dialog--title-border-style;
  border-width: 0;
  border-bottom-width: $c-absolute-dialog--title-border-width;

}

.c-absolute-dialog__icon {
  font-size: $c-absolute-dialog__title-icon--font-size;
  margin-right: $c-absolute-dialog__title-icon--margin-right;
  position: absolute;
  top: $c-absolute-dialog__title-icon--position-top;
  left: 0;
}

.c-absolute-dialog__content-footer {
  .c-absolute-dialog__close {
    position: relative;
  }
}

// Modifiers
// -------------------------------------


// Dark
.c-absolute-dialog--dark {
  background-color: $c-absolute-dialog--dark-bg-color;
}


// Toast
.c-absolute-dialog--toast {
  margin-right: $c-absolute-dialog--toast-margin;
  margin-bottom: $c-absolute-dialog--toast-margin;
  width: $c-absolute-dialog--toast-width;
  max-width: $c-absolute-dialog--toast-max-width;
  float: right;
  clear: right;

  @include t-mq($until: $c-absolute-dialog--bp) {
    width: $c-absolute-dialog--toast-width--from-bg;
  }

  .c-absolute-dialog__content {
    max-height: $c-absolute-dialog__content--max-height;
  }
}

// Closable
.c-absolute-dialog--closable {
  padding-right: $c-absolute-dialog--sp-inset-right--closable;
}
