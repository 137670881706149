@import url('~@adyen/adyen-web/dist/adyen.css');
@import './vars-adyen';

.w-payment__payment-method-container{
  display: flex;
  flex-direction: column;
  & > *{ 
    @include sp-inset-v($w-payment__payment-method-container--sp-inset)
  }
}

// These css classes are not following our custom BEM structure because they are overwritting the ones coming from Adyen
img.adyen-checkout__button__icon, img.adyen-checkout__card__cardNumber__brandIcon{
  width: auto;
}

.adyen-checkout__spinner__wrapper{ 
  display: none;
}

.adyen-checkout__button .adyen-checkout__spinner__wrapper {
  display: flex;
}

.adyen-checkout__loading-input__form[class*="form--loading"]{
  display: none;
}

.adyen-checkout__label__text {
  @include e-form-label();
}

.adyen-checkout__input {
  border: $adyen-checkout__input--border-size $adyen-checkout__input--border-style $adyen-checkout__input--border-color;
  border-radius: $adyen-checkout__input--border-radius;
  font-size: $adyen-checkout__input--font-size;
  padding: $adyen-checkout__input--padding;
  height: 50px;
  &::placeholder {
    color: $adyen-checkout__input--placeholder;
    opacity: 1;
  }
  &:active, &:active:hover, &:focus, &:focus:hover{
    box-shadow: $adyen-checkout__input--shadow--focus;
    border-color: $adyen-checkout__input--border--focus;
  }
}

.adyen-checkout__input--focus,.adyen-checkout__input--focus:hover {
  box-shadow: $adyen-checkout__input--shadow--focus;
  border-color: $adyen-checkout__input--border--focus;
}

.adyen-checkout__input--error {
  border-color: $adyen-checkout__input--border-color-error;
}

.adyen-checkout__error-text {
  color: $adyen-checkout__input--error-color;
}

.adyen-checkout-input__inline-validation{
  line-height: 1;
}

.adyen-checkout__dropdown__button {
  border: $adyen-checkout__input--border-size $adyen-checkout__input--border-style $adyen-checkout__input--border-color;
  border-radius: $adyen-checkout__input--border-radius;
}

.adyen-checkout__dropdown__button--active, .adyen-checkout__dropdown__button--active:hover, .adyen-checkout__dropdown__button:active, .adyen-checkout__dropdown__button:focus {
  box-shadow: $adyen-checkout__input--shadow-focus;
  border-color: $c-textbox__element--border--focus;
}

.adyen-checkout__filter-input{
  border: none;
  padding: 0;
}

.adyen-checkout__dropdown__list {
  margin-left: 0;
  border-radius: 0;
}
.adyen-checkout__card-input{
  display: flex;
  flex-direction: column;
}
.adyen-checkout__button--pay{
  @include c-btn-flow();
  display: inline-block;
  width: auto;
  min-width: $adyen-checkout__button--pay--min-width;
  border-radius: $adyen-checkout__button--pay--border-radius;
  align-self: end;

  &.adyen-checkout__button--loading {
    background:  $adyen-checkout__button--loading--background;
    box-shadow: $adyen-checkout__button--loading--box-shadow;
  }
}
