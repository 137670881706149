/* -------------------------------------------------------------------------
 * SEARCH RESULT
 */

// General
.c-search-result {
  border: $c-search-result--border-width $c-search-result--border-style $c-search-result--border-color;
  border-radius: $c-search-result--border-radius;
  position: relative;

}

.c-search-result__link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &[target="_blank"] {
    display: none !important;
  }

  @include t-mq($from: $c-search-result--bp-small) {
    & {
      display: none !important;
    }

    &[target="_blank"] {
      display: inline-block !important;
    }
  }
}

.c-search-result__image-wrapper {
  position: relative;
  z-index: $c-search-result__image-wrapper--z-index;
  @include sp-stack($c-search-result__image-wrapper--sp-stack--from-mq);
}

.c-search-result__favourite {
  position: absolute;
  right: $c-search-result__favourites--position-right;
  bottom: $c-search-result__favourites--position-bottom;
  z-index: $c-search-result__favourites--z-index;
}

.c-search-result__collection {
  pointer-events: none;
  position: absolute;
  left: $c-search-result__collection--left;
  top: $c-search-result__collection--top;
  z-index: $c-search-result__collection--z-index;

  @include t-mq($until: $c-search-result--bp) {
    font-size: $c-search-result__collection--font-size--until-mq;
  }

}

.c-search-result__action-wrapper {
  margin: $c-search-result__action-wrapper--margin;

}

.c-search-result__action {
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: block;
  border-width: $c-search-result__action--border-width;
  border-style: $c-search-result__action--border-style;
  position: relative;
  z-index: $c-search-result__action--index;
  font-size: $c-search-result__action--font-size;
  font-weight: $c-search-result__action--font-weight;
  transition: color $c-search-result__action--duration;
  @include sp-inset($c-search-result__action--sp-inset);
  color: $c-search-result__action--color;
  font-family: $c-search-result__action-font-family;
  padding-right: $c-search-result__action--padding-right;
  padding-left: $c-search-result__action--padding-left;

  &:hover {
    text-decoration: none;
  }

}

.c-search-result__action-icon {
  position: absolute;
  right: $c-search-result__action--sp-inset;
  top: 50%;
  font-size: $c-search-result__action-icon--font-size;
  transform: translateY(-50%);
}


// Body and its content
.c-search-result__body {
  position: relative;
  @include sp-inset($c-search-result__body--sp-inset);
}

.c-search-result__score {
  position: absolute;
  right: $c-search-result__score--right;
  top: $c-search-result__score--top;

  .c-number-score__label {
    @include t-mq($until: $c-search-result--bp) {
      display: none;
    }
  }
}

.c-search-result__rating.c-search-result__rating {
  display: block;
  color: $c-search-result__rating--color;
  @include sp-stack($c-search-result__rating--sp-stack);
}

.c-search-result__name {
  @include sp-inline($c-search-result__name--sp-inline);
}

.c-search-result__breadcrumbs {
  @include sp-stack($c-search-result__breadcrumbs--sp-stack);

  .c-breadcrumbs__item {
    min-width: initial;
  }
}

.c-search-result__breadcrumbs,
.c-search-result__breadcrumbs .c-breadcrumbs__link {
  color: $c-search-result__breadcrumbs--color;
}

.c-search-result__mostbooked {
  display: none;

  .c-search-result__mostbooked-icon {
    @include sp-inline($c-search-result__mostbooked-icon--sp-inline);
  }
}

.c-search-result__usps {
  font-size: $c-search-result__usps--font-size;
  @include sp-stack($c-search-result__usps--sp-stack);

  .c-bullet-list__item {
    @include sp-stack($c-search-result__usp-item--sp-stack);
  }
}

.c-search-result__campaign_btn {
  all: unset;
  padding: 6px 24px 6px 0px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  .c-search-result__campaign_icon {
    margin-right: 2px;
  }

  b {
    color: $f-color__brand-primary;
  }
}

.c-search-result__urgency-messages {
  font-size: $c-search-result__urgency-messages--font-size;
  @include sp-stack($c-search-result__urgency-message--sp-stack);

  .c-bullet-list__item {
    @include sp-stack($c-search-result__urgency-message-item--sp-stack);
  }

  .c-bullet-list__icon {
    color: $c-search-result__urgency-message-icon--color;
  }
}

// Footer and its content
.c-search-result__footer {
  background-color: $c-search-result__footer--bg-color;
  @include sp-inset-squish($c-search-result__footer--sp-inset-squish);

  @include t-mq($until: $c-search-result--bp) {
    @include o-pack();
  }
}

.c-search-result__footer-top,
.c-search-result__footer-bottom {
  @include o-pack__item();
}

.c-search-result__footer-bottom {
  text-align: right;
}

.c-search-result__yield {
  height: $c-search-result__yield--height;
  width: auto;
  display: initial;
  @include sp-stack($c-search-result__yield--sp-stack);
}

.c-search-result__cta.c-search-result__cta {
  display: none;
  font-size: $c-search-result__cta--font-size;
  position: relative;
  z-index: $c-search-result__cta--z-index;
  @include sp-inset-v($c-search-result__cta--sp-inset-v);

  &[target="_blank"] {
    display: none !important;
  }

  @include t-mq($from: $c-search-result--bp-small) {
    & {
      display: none !important;
    }

    &[target="_blank"] {
      display: inline-block !important;
      @include t-mq($until: "md") {
        display: none !important;
      }
    }
  }
}

.c-search-result__warning-message {
  display: none;
}

// not compact variant

.c-search-result:not(.c-search-result--compact) {
  @include search-result-extended();
}

.c-search-result:not(.c-search-result--condensed-tiny):not(.c-search-result--compact) {
  @include t-mq($from: $c-search-result--bp) {
    display: flex;
    border: none;
  }

  .c-search-result__footer-top {
    @include t-mq($from: $c-search-result--bp) {
      display: block;
      @include sp-stack($c-search-result__footer-top--sp-stack);
    }
  }

  .c-search-result__footer-bottom {
    @include t-mq($from: $c-search-result--bp) {
      display: block;
      margin-top: auto;
    }
  }

  .c-search-result__footer {
    @include t-mq($from: $c-search-result--bp) {
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      max-width: $c-search-result__footer--max-width--from-mq;
      box-shadow: $c-search-result__footer--shadow--from-mq;
      @include sp-inset($c-search-result__footer--sp-inset--from-mq);
    }
  }

  .c-search-result__cta.c-search-result__cta {
    @include t-mq($from: $c-search-result--bp) {
      display: inline-block;
      @include sp-inline($c-search-result__cta--sp-inline);
    }
  }
}

.c-search-result.c-search-result--condensed-tiny {
  @include search-result-extended(true);
}

// Is unavailable
// --------------

.c-search-result.c-search-result--is-unavailable {
  .c-search-result__footer {
    background-color: transparent;
    box-shadow: none;
    border-top: $c-search-result--border-width $c-search-result--border-style $c-search-result--border-color;

    @include t-mq($until: $c-search-result--bp) {
      text-align: right;
      @include sp-inset-v($c-search-result__footer--sp-inset-v--from-mq--unavailable);
    }
  }
}

.c-search-result__unavailable-info {
  @include sp-stack($c-search-result__unavailable-info--sp-stack);

  @include t-mq($until: $c-search-result--bp) {
    text-align: left;
  }
  align-self: stretch;
}

.c-search-result__unavailable-title {
  font-weight: $c-search-result__unavailable-text--font-weight;
  @include sp-stack($c-search-result__unavailable-text--sp-stack);
}

.c-search-result__cta.c-search-result__unavailable-button {
  display: inline-block;
}

.c-search-result.c-search-result--is-unavailable:not(.c-search-result--compact) {
  .c-search-result__footer {
    @include t-mq($from: $c-search-result--bp) {
      border-top: 0;
      border-left: $c-search-result--border-width $c-search-result--border-style $c-search-result--border-color;
    }
  }

  .c-search-result__unavailable-info {
    @include t-mq($from: $c-search-result--bp) {
      @include sp-stack($c-search-result__unavailable-info--sp-stack--from-mq);
    }
  }
}

// Is flight only
// --------------

.c-search-result--is-flight-only {
  .c-search-result__usps {
    display: none;
  }

  .c-package-block__included {
    display: none;
  }

  .c-package-block__filters {
    display: none;
  }

  .c-package-block__arrival-date {
    display: initial;
  }
}

// Is flight only
// --------------

.c-search-result--is-highlighted {
  order: -1;
}
.c-search-result__custom-usp {
  display: flex;
  align-items: center;
  height: auto;
  .m-icon {
    margin-right: .53em;
  }
}
// Variants
// ------------------------------------------

// Condensed

.c-search-result--condensed {
  @include t-mq($until: $c-search-result--bp) {
    .c-search-result__collection {
      font-size: $c-search-result__collection--font-size--condensed;
    }

    .c-search-result__image {
      width: $c-search-result__image--size--condensed;
    }

    .c-search-result__content {
      @include o-pack();
      @include o-pack--auto();
    }

    .c-search-result__header,
    .c-search-result__body {
      @include o-pack__item();
      @include o-pack__mod-alignment(middle)
    }

    .c-search-result__usps,
    .c-search-result__urgency-messages {
      display: none;
    }
  }
}

// Compact

.c-search-result--compact,
.c-search-result--condensed-tiny {
  max-width: $c-search-result__width--compact;

  .c-search-result__collection {
    font-size: $c-search-result__collection--font-size--until-mq;
  }

  .c-number-score__label {
    display: none;
  }

  .c-search-result__footer {
    @include o-pack();
  }

  .c-search-result__price {
    @include sp-stack($c-search-result__price-stack);
  }
}

.c-search-result--compact .c-search-result__footer {
  border-radius: 0px 0px $c-search-result--border-radius $c-search-result--border-radius;
}

.c-search-result--compact.c-search-result--is-unavailable,
.c-search-result--condensed-tiny.c-search-result--is-unavailable {
  .c-search-result__footer {
    text-align: right;
    @include sp-inset-v($c-search-result__footer--sp-inset-v--from-mq--unavailable);
  }

  .c-search-result__unavailable-info {
    text-align: left;
  }
}

// Condensed tiny card
.c-search-result--condensed-tiny {
  .c-search-result__image {
    width: $c-search-result__image--size--condensed;
  }

  .c-search-result__content {
    @include o-pack();
    @include o-pack--auto();
  }

  .c-search-result__header,
  .c-search-result__body {
    @include o-pack__item();
    @include o-pack__mod-alignment(middle)
  }

  .c-search-result__usps,
  .c-search-result__urgency-messages,
  .c-search-result__mostbooked,
  .c-search-result__yield,
  .c-search-result__warning-message,
  .c-search-result__mostbooked,
  .c-search-result__score,
  .c-search-result__favourite {
    display: none !important;
  }

  .c-search-result__image-wrapper {
    width: $c-search-result__image-wrapper-width--condensed-tiny;
  }

  .c-search-result__body {
    padding: $c-search-result__body-padding--condensed-tiny;
  }

  @include t-mq($until: $c-search-result--bp-extra-small) {
    width: 100%;
    .c-search-result__image-wrapper {
      width: $c-search-result__image-wrapper-sml-width--condensed-tiny;
    }
    .c-search-result__body {
      padding: $c-search-result__body-padding-sml--condensed-tiny;
    }
    .c-search-result__breadcrumbs,
    .c-search-result__footer {
      font-size: $c-search-result__font-size-sml--condensed-tiny;
    }
    .c-search-result__name {
      font-size: $c-search-result__font-size--condensed-tiny;
    }
  }

  .c-rating {
    @include t-mq($until: $c-search-result--bp) {
      font-size: $c-search-result--condensed-tiny-rating--font-size--from-bp;
      height: unset;
    }
  }

  .c-search-result__name {
    @include t-mq($until: $c-search-result--bp) {
      font-size: $c-search-result--condensed-tiny-name--font-size--from-bp;
    }
  }

  .c-search-result__collection.c-label--collection.c-label--collection {
    left: 0;
    top: 0;
    right: 0;
    border-radius: 0;
    font-size: $c-search-result--condensed-tiny-collection-label--font-size;
    padding: $c-search-result--condensed-tiny-collection-label--padding;
  }

  .c-search-result__image {
    width: 100%;
  }

  .c-package-block__departure {
    font-size: $c-search-result--condensed-tiny-collection-package-block-departure--font-size;
  }

  .c-package-block__included-item {
    font-size: $c-search-result--condensed-tiny-collection-package-block-included-item--font-size;
  }

  .c-search-result__action {
    display: none;
  }

  .c-search-result__image-wrapper {
    margin-bottom: 0;
  }
}

.c-search-result--condensed-tiny.c-search-result--is-unavailable {
  .c-search-result__footer,
  .c-search-result__unavailable-title {
    text-align: center;
  }

  .c-search-result__footer {
    border-top: none;
  }

  .c-search-result__unavailable-button {
    width: 100%;
  }
}

.c-search-result__image-link {
  & {
    display: inline;
  }

  &[target="_blank"] {
    display: none;
  }

  @include t-mq($from: $c-search-result--bp-small) {
    & {
      display: none;
    }
    &[target="_blank"] {
      display: inline;
    }
  }
}

.c-search-result__footer-top {
  .c-package-block {
    flex-direction: column;
    @include t-mq($until: "md") {
      .c-package-block__flight-routes-container {
        flex-direction: row;

        .c-package-block__flight-routes {
          &:not(:last-child) {
            padding-right: 12px;
          }
        }
      }
    }

    .c-package-block__flight-routes-container {
      flex-direction: column;

      .c-package-block__flight-routes {
        padding-right: 0px;
      }
    }
  }
}
