/* -------------------------------------------------------------------------
 * TRANSPORT TICKET MIXINS
 *
 */

 @mixin c-transport-ticket__set-bg-color($bg-color, $radio--bg-color: $c-transport-ticket__radio--bg-color, $contents--border-right: $radio--bg-color, $price--checked-color: null) {
    .c-transport-ticket__contents,
    .c-transport-ticket__price {
      background-color: $bg-color;

      @if ($price--checked-color) {
        .c-price {
          color: $price--checked-color;
        }
      }

      &::after {
        background-color: $contents--border-right;
      }
    }

    .c-transport-ticket__radio {
      background-color: $radio--bg-color;
    }

    .c-transport-ticket__detailed-info-icon .m-icon {
      background-color: $bg-color;
    }
}