/* -------------------------------------------------------------------------
 * BULLET LIST COLOR SCHEMES
 *
 */

.c-state-info.cs-state-info--light {
  .c-bullet-list__icon {
    color: $c-bullet-list__icon--color--state-info--light;
  }
}
