/* -------------------------------------------------------------------------
 * ACCO NAV
 *
 */

.w-acco-nav {
  margin-bottom: $w-acco-nav__mb;
  position: sticky;
  top: 0;
  z-index: $f-z-index--2;

  .w-acco-nav__wrapper {
    min-height: $w-acco-nav--wrapper__min-height;

    .w-acco-nav__menu {
      width: 100%;
      min-height: $w-acco-nav--menu__min-height;
      background-color: $w-acco-nav--menu__bc;
      border-bottom: $w-acco-nav--menu__border-bottom-height solid
        $w-acco-nav--menu__border-bottom-color;
      height: $w-acco-nav--nav-list__height;
      overflow: unset;

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      .c-nav__list {
        height: $w-acco-nav--nav-list__height;

        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      .c-nav__item {
        .c-nav__link {
          @include sp-inset-h($w-acco-nav--item__inset-h);
          padding-top: $w-acco-nav--item-link__padding-top;
          padding-bottom: $w-acco-nav--item-link__padding-bottom;

          border-bottom: $w-acco-nav--item__border-bottom-height solid
            $w-acco-nav--item__border-bottom-color;
          transition: border-color $w-acco-nav--item__border-bottom-duration
            $w-acco-nav--item__border-bottom-easing;
          display: inline-block;

          &:hover,
          &.active {
            border-color: $w-acco-nav--item__hover-color;
            color: $w-acco-nav--item__hover-color;
          }

          .c-nav__link-text {
            line-height: 1;
          }
        }

        &:first-child {
          .c-nav__link {
            padding-left: unset;
          }
        }
      }
    }
  }

  .o-swipe-btn {
    top: $w-acco-nav--swipe-btn__top;
  }
}

.anchor-item-tool {
  scroll-margin-top: $anchor-item-tool__scroll-margin-top;
}
