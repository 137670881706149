/* -------------------------------------------------------------------------
 * COLUMNS VARIABLES
 *
 */

// Object toggling

$o-columns--enabled:                          true !default;

// Object Params

$o-columns__space:                            $f-space--medium !default;

// Modifiers Spaces

$o-columns__mod-spaces--enabled:              true !default;
$o-columns-spaces:                            map_remove($f-spaces, "large", "huge") !default;

// Modifier No Break

$o-columns__mod-no-break--enabled:            true !default;

// Columns number variants per breakpoint

$o-columns__number:                           1 2 3 !default;
