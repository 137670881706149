/* -------------------------------------------------------------------------
 * Search page
 *
 */

.fr-searchpage-top {
  
  .fr-searchpage-top__title {
    @include t-shared-header-img__title();
  }

  .fr-searchpage-top__subtitle {
    @include t-shared-header-img__subtitle($fr-searchpage-top__subtitle--heading--from-mq, $fr-searchpage-top__subtitle--display--from-mq);
  }

  .fr-searchpage-top__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: $fr-searchpage-top__content--sp-inset-v $fr-searchpage-top__content--sp-inset-h;
    @include t-mq($until: $f-breakpoint--xs) {
      padding: 24px;
    }
  }

  .c-label {
    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .c-label + .m-heading {
    margin-top: $fr-searchpage-top__label--margin-top;
  }

  .m-heading + .c-label {
    margin-top: $fr-searchpage-top__label--margin-top;
  } 
}

@include t-mq($until: $f-breakpoint--xs) {
  
  .fr-searchpage-top .fr-searchpage-top__title {
    font-size: 32px;
  }
  
  .fr-searchpage-top__subtitle {
    display: none;
  }
}