/* -------------------------------------------------------------------------
 * LAYOUT
 *
 * Grid-like layout system.
 *
 * The layout object provides us with a column-style layout system. This file
 * contains the basic structural elements, but classes should be complemented
 * with width utilities, for example:
 *
 *   <div class="o-layout">
 *     <div class="o-layout__item  u-1/2">
 *     </div>
 *     <div class="o-layout__item  u-1/2">
 *     </div>
 *   </div>
 *
 * The above will create a two-column structure in which each column will
 * fluidly fill half of the width of the parent. We can have more complex
 * systems:
 *
 *   <div class="o-layout">
 *     <div class="o-layout__item  u-1/1  u-1/3@md">
 *     </div>
 *     <div class="o-layout__item  u-1/2  u-1/3@md">
 *     </div>
 *     <div class="o-layout__item  u-1/2  u-1/3@md">
 *     </div>
 *   </div>
 *
 * The above will create a system in which the first item will be 100% width
 * until we enter our medium breakpoint, when it will become 33.333% width. The
 * second and third items will be 50% of their parent, until they also become
 * 33.333% width at the medium breakpoint.
 *
 * We can also manipulate entire layout systems by adding a series of modifiers
 * to the `.o-layout` block. For example:
 *
 *   <div class="o-layout  o-layout--reverse">
 *
 * This will reverse the displayed order of the system so that it runs in the
 * opposite order to our source, effectively flipping the system over.
 *
 *   <div class="o-layout  o-layout--[right|center]">
 *
 * This will cause the system to fill up from either the centre or the right
 * hand side. Default behaviour is to fill up the layout system from the left.
 *
 * There are plenty more options available to us: explore them below.
 *
 *
 * By default we use the `font-size: 0;` trick to remove whitespace between
 * items. Set this to true in order to use a markup-based strategy like
 * commenting out whitespace or minifying HTML.
 *
 *
 * Params:
 * SPACE .............................. Horizontal separation between layout__items
 *
 */

// Object selector output
// --------------------------------------------------

@if ($o-layout--enabled) {
  .o-layout {
    @include o-layout();
  }

  .o-layout__item {
    @include o-layout__item();
  }
}

// Space modifiers
// --------------------------------------------------

@if ($o-layout--enabled and $o-layout__mod-spaces--enabled) {
  @each $_space-name, $_space-value in $o-layout__mod-spaces {
    .o-layout--space-#{$_space-name} {
      @include o-layout__mod-space($_space-name, $_space-value);

      > .o-layout__item {
        @include o-layout__item-mod-space($_space-name, $_space-value);
      }
    }
  }
}

// Vertical alignment modifiers
// --------------------------------------------------

@if ($o-layout--enabled and $o-layout__mod-alignments-v--enabled) {
  @each $_vertical-alignment in $o-layout__mod-alignments-v {
    .o-layout--#{$_vertical-alignment} > .o-layout__item {
      @include o-layout__mod-alignment-v($_vertical-alignment);
    }
  }
}

// Horizontal alignment modifiers
// --------------------------------------------------

@if ($o-layout--enabled and $o-layout__mod-alignments-h--enabled) {
  @each $_alignment in $o-layout__mod-alignments-h {
    @include o-layout__mod-alignment-h($_alignment);
  }
}

// Reverse modifier
// --------------------------------------------------

@if ($o-layout--enabled and $o-layout__mod-reverse--enabled) {
  .o-layout--reverse {
    @include o-layout--reverse();

    > .o-layout__item {
      @include o-layout__item--reverse();
    }
  }

}

// Item reverse  modifier
// --------------------------------------------------

@if ($o-layout--enabled and $o-layout__mod-reverse--enabled) {
  .o-layout__item--reverse {
    @include o-layout__item-mod--reverse();
  }
}

// Stretch modifier
// --------------------------------------------------

@if ($o-layout--enabled and $o-layout__mod-stretch--enabled) {
  .o-layout--stretch {
    @include o-layout--stretch();

    > .o-layout__item {
      @include o-layout__item--stretch();

      > * {
        flex-grow: 1;
      }

      @if ($o-layout__mod-alignments-h--enabled) {
        &.o-layout--center {
          @include o-layout__item--stretch-center();
        }

        &.o-layout--right {
          @include o-layout__item--stretch-right();
        }
      }
    }
  }

}

// Smart layout columns
// --------------------------------------------------

@import "smart";
