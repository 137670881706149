/* -------------------------------------------------------------------------
 * Price
 *
 * Price as a material
 *
 */

// Material selector output
// --------------------------------------------------

@if $m-price--enabled {
  .m-price {
    font-size: $m-price--font-size;
    font-family: $m-price--font-family;
    font-weight: $m-price--font-weight;
    letter-spacing: $m-price--letter-spacing;
    @include t-text-crop-enable();
    @include t-text-crop($m-price--font-family--crop, 1);
  }

  .m-price__currency, .m-price__suffix {
    @if ($m-price--font-size != $m-price__currency--font-size) {
      font-size: $m-price__currency--font-size;
    }

    @if ($m-price--font-family != $m-price__currency--font-family) {
      font-family: $m-price__currency--font-family;
    }

    @if ($m-price--font-weight != $m-price__currency--font-weight) {
      font-weight: $m-price__currency--font-weight;
    }
  }

  .m-price__value + .m-price__currency,
  .m-price__currency + .m-price__value,
  .m-price__currency + .m-price__suffix,
  .m-price__value + .m-price__suffix {
    margin-left: $m-price--spacing-inline;
  }
}

// Modifiers
// -----------------------------------------------

// Small modifier
.m-price--small {
  &,
  .m-price__currency,
  .m-price__suffix,
  .m-price__prefix  {
    font-size: $m-price--small--font-size;
  }

  @if ($m-price--font-family != $m-price--small--font-family) {
    font-family: $m-price--small--font-family;
  }

  @if ($m-price--letter-spacing != $m-price--small--letter-spacing) {
    letter-spacing: $m-price--small--letter-spacing;
  }
}

// Large modifier
.m-price--large {
  font-size: $m-price--large--font-size;

  @if ($m-price--font-family != $m-price--large--font-family) {
    font-family: $m-price--large--font-family;
  }

  .m-price__currency {
    @if ($m-price--large--font-size != $m-price__currency--large--font-size) {
      font-size: $m-price__currency--large--font-size;
    }
    font-weight: $m-price__currency--large--font-weight;
  }
}
