.w-product-ads {
  position: relative;
  padding: $w-product-ads--padding;
  display: flex;
  flex-direction: column;

  @include t-mq($from: $w-product-ads__bq) {
    padding: $w-product-ads--bq--padding;
  }

  .w-product-ads--modal--btn {
    padding-left: unset;
    padding-top: unset;
    color: $w-product-ads--modal--btn--color;
    font-size: $w-product-ads--modal--btn--font-size;
  }

  .w-product-ads--container[class*='c-collection-row--expanded'] {
    .w-product-ads--item {
      .c-collection-row__item-content {
        .c-collection-row__item-title,
        .c-collection-row__item-subtitle {
          display: block;
          text-overflow: unset;
        }
      }
    }
  }
}

.w-product-ads--background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $w-product-ads--background--background-color;
  border-radius: $w-product-ads--background--border-radius;
  z-index: -1;

  @include t-mq($from: $w-product-ads__bq) {
    width: 65%;
  }
}

.w-product-ads__header {
  margin-bottom: $w-product-ads--header;

  @include t-mq($from: $w-product-ads__bq) {
    margin-bottom: $w-product-ads--bq--header;
  }
}

.w-product-ads--container {
  @include t-mq($from: $w-product-ads__bq) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.w-product-ads--item {
  &.no-image .c-img {
    background-color: $w-product-ads--item--no-image--background-color;
  }

  .c-collection-row__item-image {
    .c-img {
      height: 100%;

      img {
        object-fit: cover;
      }
    }
  }

  .c-collection-row__item-content {
    padding: $w-product-ads--item-content--padding;

    @include t-mq($from: $w-product-ads__bq) {
      padding: $w-product-ads--bq--item-content--padding;
    }
  }

  @include t-mq($from: $w-product-ads__bq) {
    background-color: $w-product-ads--item--background-color;
  }
}

.w-product-ads--item-subtitle-icon {
  margin-right: $w-product-ads--item-subtitle-icon--margin-right;
}
