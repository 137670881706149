/* -------------------------------------------------------------------------
* Preference questionnaire
*
*/

%toggle-element {
  display: none;
  opacity: 0;
  animation: show $f-duration--normal $f-easing--in forwards;
  &.in {
    display: block;
  }
}

.w-preference-questionnaire__progress-bar progress {
  height: $w-preference-questionnaire__progress-bar--height;
  &::-webkit-progress-bar,
  &::-webkit-progress-value,
  &::-moz-progress-bar {
    border-radius: $w-preference-questionnaire__progress-bar--border-radius;
  }

  &::-webkit-progress-value {
    transition: width $w-preference-questionnaire__progress-bar--transition-duration;
  }
}

.w-preference-questionnaire__question-title-container,
.w-preference-questionnaire__options .c-choice-list__options,
.w-preference-questionnaire__options .c-choice-list__option,
.w-preference-questionnaire__question-title {
  display: flex;
}

.w-preference-questionnaire__progress-bar,
.w-preference-questionnaire__question-title-container,
.w-preference-questionnaire__question-info-text,
.w-preference-questionnaire__question-options {
  margin-bottom: $w-preference-questionnaire__section--margin-bottom;
}

.w-preference-questionnaire__question-info-text {
  margin-top: $w-preference-questionnaire__question-info-text--margin-top;
}

.w-preference-questionnaire__question-title-container {
  @include t-mq($until: $w-preference-questionnaire__options--first-bp) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .o-bullet__icon {
      font-size: $w-preference-questionnaire__question-icon--font-size-first-bp;
      display: block;
      margin-bottom: $w-preference-questionnaire__question-icon--margin-bottom-first-bp;
    }

    .w-preference-questionnaire__question-title {
      text-align: center;
      padding-left: 0;
    }
  }
}

.w-preference-questionnaire__options .c-choice-list__options {
  gap: $w-preference-questionnaire__options--column-gap $w-preference-questionnaire__options--row-gap;
  flex-wrap: wrap;
}

.w-preference-questionnaire__options.w-preference-questionnaire__options .c-choice-list__option.c-choice-list__option {
  margin-bottom: 0;
}

.w-preference-questionnaire__options .c-choice-list__option {
  flex-basis: 100%;
  flex-shrink: 0;

  @include t-mq($from: $w-preference-questionnaire__options--first-bp) {
    flex-basis: $w-preference-questionnaire__option--width-first-bp;
  }

  @include t-mq($from: $w-preference-questionnaire__options--second-bp) {
    flex-basis: $w-preference-questionnaire__option--width-second-bp;
  }
}
.w-preference-questionnaire__options--illustrations .c-choice-list__option {
  flex-basis: $w-preference-questionnaire__option-illustrations--width;

  @include t-mq($from: $w-preference-questionnaire__options--second-bp) {
    flex-basis: $w-preference-questionnaire__option-illustrations--width-second-bp;
  }
}

.w-preference-questionnaire__footer {
  display: flex;
  justify-content: space-between;
}

.w-preference-questionnaire__back-btn {
  @extend %toggle-element;
  min-width: $w-preference-questionnaire__back-btn--width;
  width: $w-preference-questionnaire__back-btn--width;
  padding-left: 0;
}

.w-preference-questionnaire__next-btn {
  @extend %toggle-element;
  margin-left: auto;
}

.w-preference-questionnaire__options {
  .c-checkbox__label,
  .c-radio-button__label {
    width: 100%;
    align-items: start;
  }
}

.w-preference-questionnaire__options:not(.w-preference-questionnaire__options--illustrations) .c-checkbox__label,
.w-preference-questionnaire__options .c-checkbox__element-container,
.w-preference-questionnaire__options .c-radio-button__element-container {
  padding-right: $w-preference-questionnaire__options-label--padding-right;
}

.w-preference-questionnaire__options--flags .c-checkbox__text-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: $w-preference-questionnaire__options--flags-gap;
}

.w-preference-questionnaire__message {
  @extend %toggle-element;
  text-align: center;
  padding: $w-preference-questionnaire__message--padding 0;
}

.w-preference-questionnaire__message-icon {
  display: block;
  color: $w-preference-questionnaire__message-icon--color;
}

.w-preference-questionnaire__message-text,
.w-preference-questionnaire__message-title {
  margin-left: auto;
  margin-right: auto;
  padding: 0 $w-preference-questionnaire__message-text--horizontal-padding;
}

.w-preference-questionnaire__message-title {
  max-width: $w-preference-questionnaire__message-title--max-width;
}

.w-preference-questionnaire__message-text {
  max-width: $w-preference-questionnaire__message-text--max-width;

}

.w-preference-questionnaire__message-icon,
.w-preference-questionnaire__message-title,
.w-preference-questionnaire__message-text {
  margin-bottom: $w-preference-questionnaire__message-items--margin-bottom;
}

.w-preference-questionnaire__message--success {
  .w-preference-questionnaire__message-icon,
  .w-preference-questionnaire__message-title {
    color: $w-preference-questionnaire__message-text-success--color;
  }
}

.w-preference-questionnaire__footer-right-container {
  margin-left: auto;
}

.w-preference-questionnaire__question,
.w-preference-questionnaire__progress-bar,
.w-preference-questionnaire__summary-container,
.w-preference-questionnaire__start-loader-container {
  @extend %toggle-element;
}

.w-preference-questionnaire__send-btn:disabled,
.w-preference-questionnaire__back-btn:disabled,
.w-preference-questionnaire__next-btn:disabled {
  animation: unset;
}

.w-preference-questionnaire__send-btn {
  @extend %toggle-element;
  padding: $w-preference-questionnaire__send-btn--padding;
  font-size: $w-preference-questionnaire__send-btn--font-size;
}

.w-preference-questionnaire__start-loader-inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $w-preference-questionnaire__start-loader--padding;
}

.w-preference-questionnaire__questionnaire-container {
  @extend %toggle-element;
  max-width: $w-preference-questionnaire__questionnaire-container--max-width;
  margin: 0 auto;
}

//Summary

.w-preference-questionnaire__summary-title {
  margin-top: $w-preference-questionnaire__summary-title--margin-top;
}

.w-preference-questionnaire__summary-question-answers {
  display: flex;
  flex-direction: column;
  gap: $w-preference-questionnaire__summary-question-answers--gap;
}

.w-preference-questionnaire__summary-question-title-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: $w-preference-questionnaire__summary-question-title-container--margin-bottom;

  @include t-mq($until: $w-preference-questionnaire__summary-question-title--bp) {
    flex-direction: column;
  }
}

.w-preference-questionnaire__summary-question-title {
  display: flex;

  @include t-mq($until: $w-preference-questionnaire__summary-question-title--bp) {
    flex-direction: column;
    gap: $w-preference-questionnaire__summary-question-title--gap-until-bp;
  }

  .o-bullet__text {
    @include t-mq($until: $w-preference-questionnaire__summary-question-title--bp) {
      padding-left: 0;
    }
  }
}

.w-preference-questionnaire__summary-question-title .o-bullet__text {
  align-self: center;
}

.w-preference-questionnaire__questionnaire-edit {
  flex-shrink: 0;
  cursor: pointer;

  @include t-mq($until: $w-preference-questionnaire__summary-question-title--bp) {
    order: -1;
    align-self: flex-end;
    margin-bottom: $w-preference-questionnaire__questionnaire-edit--margin-bottom-until-bp;
  }
}

.w-preference-questionnaire__questionnaire-edit:hover {
  text-decoration: none;

  .w-preference-questionnaire__questionnaire-edit-text {
    text-decoration: underline;
  }
}

.w-preference-questionnaire__questionnaire-edit-text {
  display: inline;
}

//Animations

@keyframes show {
  100% {
    opacity: 1;
    transform: none;
  }
}
