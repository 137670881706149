/* -------------------------------------------------------------------------
* Dropdown Variables
*
*/

$c-dropdown--color:                                   $e-form-elements__field--color !default;
$c-dropdown--sp-inset-h:                              $e-form-elements--sp-inset-h !default;
$c-dropdown--sp-inset-v:                              $e-form-elements--sp-inset-v !default;
$c-dropdown--sp-inset-right:                          $f-space--medium + $c-dropdown--sp-inset-h !default;
$c-dropdown--border-radius:                           $e-form-elements__field--border-radius !default;
$c-dropdown--duration:                                $f-duration--normal !default;
$c-dropdown--easing:                                  $f-easing--linear !default;

$c-dropdown__element--bg-color:                       $e-form-elements__field--bg-color !default;
$c-dropdown__element--color:                          $e-form-elements__icon--color !default;
$c-dropdown__element--color--has-element:             $e-form-elements__field--color !default;
$c-dropdown__element--font-family:                    $e-form-elements__field--font-family !default;
$c-dropdown__element--font-size:                      $e-form-elements__field--font-size !default;
$c-dropdown__element--text-indent--with-icon:         $c-dropdown--sp-inset-h + ($f-space--tiny + $f-space--medium) !default;
$c-dropdown__element--border-size:                    $e-form-elements__field--border--size !default;
$c-dropdown__element--border-style:                   $e-form-elements__field--border--type !default;
$c-dropdown__element--border-color:                   $e-form-elements__field--border-color !default;
$c-dropdown__element--border-color--hover:            $e-form-elements--hover--border-color !default;
$c-dropdown__element--shadow--focus:                  $f-shadow--glow $e-form-elements--focus--border-color !default;
$c-dropdown__element--border--focus:                  $e-form-elements--focus--border-color !default;
$c-dropdown__element--min-height:                     floor(($e-form-elements__field--font-size * $e-form-elements__field--line-height) + ($e-form-elements--sp-inset-v * 2) + ($c-dropdown__element--border-size * 2)) !default;

$c-dropdown__option--color:                           $e-form-elements__field--color !default;
$c-dropdown__option-default--color:                   $e-form-elements__icon--color !default;

$c-dropdown__icons--z-index:                          $f-z-index--1 !default;
$c-dropdown__label--z-index:                          $f-z-index--1 !default;

$c-dropdown__icon--left:                              $f-space--small !default;
$c-dropdown__icon--font-size:                         20px !default;
$c-dropdown__icon--color:                             $e-form-elements__icon--color !default;

$c-dropdown__clear--right:                            $f-space--medium !default;
$c-dropdown__clear--color:                            $e-form-elements__clear--color !default;
$c-dropdown__clear--color--hover:                     $e-form-elements__clear--color--hover !default;
$c-dropdown__clear--space-inline:                     $f-space--tiny !default;
$c-dropdown__clear--font-size:                        $f-font-size--xs !default;

$c-dropdown__arrow--right:                            $f-space--small !default;
$c-dropdown__arrow--bg-color:                         $e-form-elements__arrow--bg-color !default;
$c-dropdown__arrow--color:                            $e-form-elements__arrow--color !default;


// States
// -----------------------------------------

// Disabled
$c-dropdown__disabled--opacity:                       $e-form-elements--disabled--opacity !default;
$c-dropdown__disabled--bg-color:                      $e-form-elements--disabled--bg-color !default;
$c-dropdown__element--disabled--color:                $c-dropdown__element--color !default;
$c-dropdown__element--disabled--color--has-element:   $e-form-elements__disabled--color !default;
$c-dropdown__arrow--disabled--color:                  $e-form-elements__arrow--disabled--color !default;

// Success
$c-dropdown__element--success--border-color:          $e-form-elements--success--border-color !default;
$c-dropdown__element--success--shadow--focus:         $f-shadow--glow $e-form-elements--success--border-color !default;

// Warning
$c-dropdown__element--warning--border-color:          $e-form-elements--warning--border-color !default;
$c-dropdown__element--warning--shadow--focus:         $f-shadow--glow $e-form-elements--warning--border-color !default;

// Error
$c-dropdown__element--error--border-color:            $e-form-elements--error--border-color !default;
$c-dropdown__element--error--shadow--focus:           $f-shadow--glow $e-form-elements--error--border-color !default;



// Modifiers
// -----------------------------------------

// Highlighted
$c-dropdown__element--highlighted--value-color:       $e-form-elements__inner-element--color !default;

// Messages
$c-dropdown__messages--font-size:                     $f-font-size--s !default;
$c-dropdown__messages--margin-top:                    $f-space--tiny !default;
$c-dropdown__message--error--color:                   $e-form-elements--error--border-color !default;


// Variants
// -----------------------------------------

// Compact
$c-dropdown--sp-inset-label-v:                        $f-space--tiny !default;
$c-dropdown--sp-inset-label--font-size:               $f-font-size--xxs !default;
$c-dropdown--sp-inset-v-compact:                      $f-space--tiny + $f-space--small !default;
$c-dropdown--sp-inset-label--font-color:              $f-color__neutral-grey !default;
$c-dropdown--sp-inset-element--font-color:            $f-color__neutral-black !default;
$c-dropdown--sp-inset-label-duration:                 $f-duration--fast !default;
$c-dropdown--is-compact-duration:                     $f-duration--none !default;
$c-dropdown--is-compact--sp-inset-v:                  $f-space--tiny !default;
$c-dropdown__element--is-compact--min-height:         floor(($e-form-elements__field--font-size * $e-form-elements__field--line-height) + ($c-dropdown--is-compact--sp-inset-v) + ($c-dropdown__element--border-size * 2) + ($c-dropdown--sp-inset-v-compact)) !default;
$c-dropdown--sp-inset-label-has-value--padding-top:   floor($f-space--tiny + ($f-space--tiny / 2) ) !default;
