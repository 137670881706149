
/* -------------------------------------------------------------------------
 * SECONDARY Color Scheme
 *
 */

// Color Scheme output

@if $cs-brand-secondary--enabled {
  .cs-brand-secondary {
    @include color-scheme($cs-brand-secondary)
  }
}


// Color Scheme Modifiers
// -------------------------

// White modifier

@if $cs-brand-secondary--enabled and $cs-brand-secondary--white--enabled {
  .cs-brand-secondary--white {
    @include color-scheme(s-core-map-diff($cs-brand-secondary, $cs-brand-secondary--white))
  }
}

// Light modifier

@if $cs-brand-secondary--enabled and $cs-brand-secondary--light--enabled {
  .cs-brand-secondary--light {
    @include color-scheme(s-core-map-diff($cs-brand-secondary, $cs-brand-secondary--light))
  }
}

// Dark modifier

@if $cs-brand-secondary--enabled and $cs-brand-secondary--dark--enabled {
  .cs-brand-secondary--dark {
    @include color-scheme(s-core-map-diff($cs-brand-secondary, $cs-brand-secondary--dark))
  }
}
