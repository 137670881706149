/* -------------------------------------------------------------------------
 * DESTINATION AREA FACTS VARS
 *
 */
$w-destination-area-facts--bp-large:                                            $f-breakpoint--lg !default;
$w-destination-area-facts--bp-medium:                                           $f-breakpoint--md !default;

$w-destination-area-facts-columns__item__padding-bottom:                        $f-space--medium !default;
$w-destination-area-facts-columns__item__padding-bottom--from-mq:               $f-space--small !default;
$w-destination-area-facts-columns__item__min-height:                            $f-space--giant !default;
$w-destination-area-facts-columns__item__border-color:                          $f-color__neutral-grey--light !default;

$w-destination-area-facts__item-icon-disabled__color:                           $f-color__neutral-grey--semi-light !default;
$w-destination-area-facts__item-icon__size-large:                               2 * $f-font-size--xxxl !default;
$w-destination-area-facts__item-icon__size-small:                               $f-font-size--xxl !default;
$w-destination-area-facts__item-icon__padding-bottom:                           $f-space--small !default;
$w-destination-area-facts_ski-slope_padding-bottom:                             $f-space--medium !default;
$w-destination-area-facts_ski-slope_total-slope__padding-top:                   $f-space--small !default;

$c-tabs__content__padding-top:                                                  $f-space--medium !default;

$c-progress-bar__padding-bottom:                                                $f-space--small !default;
$c-progress-bar_overwrite-font-size:                                            $f-font-size--m !default;
$c-progress-bar_overwrite-font-family:                                          $f-font-family--primary !default;

$w-destination-area-facts__ski-fact__padding-bottom:                            $f-space--small !default;
$w-destination-area-facts__ski-fact-title__padding-bottom:                      $f-space--medium !default;
$w-destination-area-facts__ski-fact__min-height:                                10 * $f-space--medium !default;

$w-destination-area-facts_ski-slope-black__color:                               $f-color__neutral-black !default;
$w-destination-area-facts_ski-slope-red__color:                                 $f-color__state-danger !default;
$w-destination-area-facts_ski-slope-blue__color:                                $f-color__state-info !default;
$w-destination-area-facts_ski-slope-green__color:                               $f-color__state-success !default;

$w-destination-area-facts__map__overlay__z-index--1:                            $f-z-index--1 !default;
$w-destination-area-facts__map__overlay__color:                                 $f-color__neutral-grey--dark !default;
$w-destination-area-facts__map__overlay__opacity:                               .6 !default;
$w-destination-area-facts__map__overlay__icon-color:                            $f-color__neutral-white !default;
$w-destination-area-facts__map__overlay__icon-font-size:                        48px !default;

