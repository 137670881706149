/* -------------------------------------------------------------------------
 * PHOTO GALLERY VARS
 *
 */

$c-photo-gallery__items-per-row--default:              3 !default;
$c-photo-gallery__items-per-row--tablet:               5 !default;
$c-photo-gallery__items-per-row--desktop:              7 !default;

$c-photo-gallery__item--padding:                       $f-space--tiny !default;
$c-photo-gallery__item--padding-bottom:                $f-space--medium + $f-space--small !default;
$c-photo-gallery__item--margin:                        $f-space--tiny !default;
$c-photo-gallery__item--background-color:              $f-color__neutral-white !default;
$c-photo-gallery__item--border:                        0.5px solid $f-color__neutral-grey--light !default;
$c-photo-gallery__item--border-radius:                 $f-radius--small !default;
$c-photo-gallery__item--box-shadow:                    $f-shadow rgba($f-shadow__color, $f-shadow__opacity) !default;
$c-photo-gallery__item--transition:                    transform $f-duration--fast $f-easing--base !default;
$c-photo-gallery__item--transform-rotate-deg:          3deg !default;