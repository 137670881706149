
// remove when the ab test is over
// Specific code for the creditCard checkbox implementation
.w-booking-car-rental__required-credit-card{
  margin-left: -$f-space--tiny;
  margin-bottom: $f-space--small;
  margin-top: -$f-space--small;
  .c-choice-list__message{
    margin-left: $f-space--tiny
  }
  .c-checkbox {
    width: fit-content;
  }
  &.is-hidden{
    display: none;
  }
}