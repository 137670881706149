/* -------------------------------------------------------------------------
 * FORMS
 *
 * Define some basic forms styles
 *
 */

input,
textarea,
select {
  align-items: center;
  border-style: solid;
  cursor: text;
  border-width: 1px;
  border-color: transparent;
  background-color: $e-form-elements__field--bg-color;
  display: inline-flex;
  justify-content: flex-start;
  position: relative;
  padding: $e-form-elements--sp-inset-v $e-form-elements--sp-inset-h;
  color: $e-form-elements__field--color;
  font-size: $e-form-elements--font-size;
  line-height: $e-form-elements--line-height;
  vertical-align: top;
  max-width: 100%;
}

input[type="text"],
textarea  {
  border-color: $e-form-elements__field--border-color;
  width:100%;
}

input::placeholder{
  color: $e-form-elements__field--color;
}


textarea::placeholder{
  color:$e-form-elements__field--color;
}
textarea {
  resize:vertical;
  min-height:$e-form-elements--height;
}

select {
  cursor: pointer;
  border-color: $e-form-elements__field--border-color;
  outline: none;
}

input:focus,
input:active,
textarea:focus,
textarea:active {
  outline-color:$e-form-elements--focus--color;
}

input[type="checkbox"],
input[type="radio"] {
  vertical-align: middle;
  cursor: pointer;
}


input[disabled],
textarea[disabled],
select[disabled] {
  color: $e-form-elements--disabled--color;
  background-color: $e-form-elements--disabled--bg-color;
  box-shadow: none;
  pointer-events: none;
  opacity: 1;
}

label {
  font-size: $e-form-elements--font-size;
  display: block;
}


.e-form-label {
  @include e-form-label();
}

.e-form-label--strong {
  @include e-form-label--strong();
}

// form label with tooltip

.e-form-label__container {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-bottom: $e-form-label__container--margin-bottom;
  max-width: $e-form-label__container--max-width;

  .e-form-label {
    margin-bottom: 0;
  }

  .c-tooltip {
    position: absolute;
    right: 0;
    transform: $e-form-label__tooltip--transform;
    font-size: $e-form-label__tooltip--font-size;
  }

  .c-tooltip.in {
    z-index: $e-form-label__tooltip--z-index;
  }
}
