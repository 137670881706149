.w-booking-luggage__message-title,
.w-booking-luggage__title,
.w-booking-luggage__title .c-title,
.w-booking-luggage__participant-name {
  font-family: $w-booking-luggage__secondary-titles--font-family;
}

.w-booking-luggage__title,
.w-booking-luggage__title .c-title,
.w-booking-luggage__participant-name {
  font-weight: $w-booking-luggage__secondary-titles--font-weight;
}

.w-booking-luggage__participant-name {
  font-size: $w-booking-luggage__participant-name--font-size;
}

.w-booking-luggage__options-price {
  background-color: $w-booking-luggage__options-price--background-color;
  color: $w-booking-luggage__options-price--color;
}

.w-booking-luggage__message {
  padding-bottom: $w-booking-luggage__message-padding;

  .w-booking-luggage__message-description {
    margin-bottom: 0;
  }
  .w-booking-luggage__list {
    margin-bottom: 0;
  }
}

.w-booking-luggage__message-line {
  margin-top: $w-booking-luggage__message-line--margin-top;
}