.w-booking-cancellation-flow__cancel-modal{
    .w-booking-cancellation-flow__buttons{
        margin-top: $f-space--small;
        text-align: right;
    }
    .w-booking-cancellation-flow__cancellation-response{
        margin-bottom: $f-space--medium;
    }
}

.w-booking-cancellation-flow__cancellation-response{
    text-align: center;
    .w-booking-cancellation-flow__buttons{
        text-align: center;
    }

    .w-booking-cancellation-flow__confirmed__icon, .w-booking-cancellation-flow__confirmed__title {
        margin-bottom: $f-space--small;
    }

    .w-booking-cancellation-flow__confirmed__icon {
        color: $w-booking-cancellation-flow-success__icon--color;
    }

    .w-booking-cancellation-flow__error__icon, .w-booking-cancellation-flow__error__title{
        margin-bottom: $f-space--small;
    }
    .w-booking-cancellation-flow__error__icon{
        color: $w-booking-cancellation-flow-error__icon--color;
    }
}

.w-booking-cancellation-flow__button-next, .w-booking-cancellation-flow__step{
    &.is-hidden{
        display: none;
    }
}

.w-booking-cancellation-flow__result-error, .w-booking-cancellation-flow__result-success{
    &.is-hidden{
        display: none;
    }
}

.w-booking-cancellation-flow__loader-container {
    display: flex;
    align-items: center;
    margin: $w-booking-cancellation-flow__loader-container--margin 0;
    flex-direction: column;
}

.w-booking-cancellation-flow__loader {
    padding-bottom: $w-booking-cancellation-flow__loader--padding-bottom;
}

.w-booking-cancellation-flow__prices-summary {
    @include m-body(map_get($m-body__mod-sizes, "medium"));
    flex: 1 0 auto;
    margin-top: $w-booking-cancellation-flow__prices-summary--spacing;
    margin-bottom: $w-booking-cancellation-flow__prices-summary--spacing;
    padding: $w-booking-cancellation-flow__prices-summary--spacing;
    background-color: $w-booking-cancellation-flow__prices-summary--background-color;
    border-style: $w-booking-cancellation-flow__prices-summary--border-style;
    border-width: $w-booking-cancellation-flow__prices-summary--border-width;
    border-color: $w-booking-cancellation-flow__prices-summary--border-color;
}

.w-booking-cancellation-flow__prices-summary .c-price__info,
.w-booking-cancellation-flow__prices-summary__support-item {
  color: $w-booking-cancellation-flow__prices-summary__support-item--color;
  @include m-body(map_get($m-body__mod-sizes, "small"));
}

.w-booking-cancellation-flow__prices-summary .c-price__value,
.w-booking-cancellation-flow__prices-summary .c-price__currency {
  @include m-body(map_get($m-body__mod-sizes, "medium"));
}

.w-booking-cancellation-flow__prices-wrapper--bold,
.w-booking-cancellation-flow__prices-wrapper--bold .c-price__value,
.w-booking-cancellation-flow__prices-wrapper--bold .c-price__currency {
  @include m-body(map_get($m-body__mod-sizes, "large"));
}

.w-booking-cancellation-flow__prices-summary .c-label {
  font-size: $w-booking-cancellation-flow__prices-summary-label--font-size;
}

.w-booking-cancellation-flow__prices-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > div {
        flex: 0 50%;
        margin-bottom: $w-booking-cancellation-flow__prices-wrapper--margin-bottom;

        &:nth-child(odd) {
            word-break: break-word;
        }
        &:nth-child(even) {
            text-align: right;
            padding-left: $w-booking-cancellation-flow__prices-wrapper-even-child--padding-left;
        }
    }
}

.w-booking-cancellation-flow__separator{
    border-top: $w-booking-cancellation-flow__separator;
    margin-bottom: $w-booking-cancellation-flow__prices-summary--spacing;
}

.w-booking-cancellation-flow__participants-box {
    text-align: left;
    padding: 12px;
    margin-bottom: 24px;
    margin-top: 12px;
    & ul {
        list-style-type: none;
        margin-left: 0;
    }

    & li {
        margin-bottom: 6px;
    }
}

.w-booking-cancellation-flow__terms-conditions {
    padding-left: 24px;
    padding-top: 12px;
}

.w-booking-cancellation-flow__back-button {
    padding-left: 0;
    padding-top: 0;
}

.w-booking-cancellation-flow__form {
    margin-bottom: 24px;
}

.w-booking-cancellation-flow__cancel-options .c-radio-button__text {
  text-transform: none;
}

.w-booking-cancellation-flow__conditions .c-checkbox__text {
  font-size: $f-font-size--xs;
  line-height: $f-line-height--medium;
}
