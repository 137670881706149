.c-car-item__type {
  font-family: $c-car-item__type--font-family;

  @include t-mq($c-car-item--bp-medium) {
    font-size: $c-car-item__type--font-size-desktop;
  }
}

.c-car-item__features{
  @include sp-stack($c-car-item__features--sp-stack);
}