/* -------------------------------------------------------------------------
 * INSET HORIZONTAL
 *
 * To give space between container and content only in side spaces
 *
 * TIP:
 * You can modify the amount of generated spaces by overriding the $sp-inset-h__spaces variable.
 *
 */

// Space selector output
// --------------------------------------------------

@if ($sp-inset-h__enabled) {
  @each $_spacing-name, $_spacing-value in $sp-inset-h__spaces {
    .sp-inset-h--#{$_spacing-name} {
      @include sp-inset-h($_spacing-value);
    }
  }
}
