/* -------------------------------------------------------------------------
 * Map
 */

$c-map--max-width:                          640px;

$c-map__overlay--full-cover:                100% !default;
$c-map__overlay--bg-color:                  $f-color__neutral-black !default;
$c-map__overlay--opacity:                   .6 !default;
$c-map__icon--color:                        $f-color__neutral-white !default;
