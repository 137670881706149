.fr-destination-page__description-collapse {
  @include sp-stack($fr-destination-page__description-content--sp-stack);
}

.fr-destination-page__description .c-title {
  @include t-mq($from: $fr-destination-page__description--bp) {
    text-align: center;
  }
}

.o-layout__item > .fr-destination-page__description .c-title {
  text-align: left;
}
