/* -------------------------------------------------------------------------
 * FAQ FEEDBACK
 */

.w-faq-feedback__question,
.w-faq-feedback__message {
  display: none;

  &.is-active {
    display: block;
  }
}

.w-faq-feedback__button {
  color: $w-faq-feedback__button--color;
  font-weight: $w-faq-feedback__button--font-weight;
  cursor: pointer;
}

.w-faq-feedback__message {
  color: $w-faq-feedback__message--color;

  > *:last-child {
    margin-bottom: 0;
  }
}
