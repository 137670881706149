
/* -------------------------------------------------------------------------
 * HEADINGS VARIABLES
 *
 */

$e-headings__font-weight:         400 !default;

$e-headings__font-size--h1:       $f-font-size--xxxl !default;
$e-headings__font-size--h2:       $f-font-size--xxl !default;
$e-headings__font-size--h3:       $f-font-size--xl !default;
$e-headings__font-size--h4:       $f-font-size--l !default;
$e-headings__font-size--h5:       $f-font-size--m !default;
$e-headings__font-size--h6:       $f-font-size--s !default;

$e-headings__line-height:         $f-line-height--tiny !default;

$e-headings__margin-bottom:       $g-reset__spacing--vertical !default;
