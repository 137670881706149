/* -------------------------------------------------------------------------
 * TRANSFER TICKET
 */

.w-transfer-ticket {
  padding: $w-transfer-ticket--padding;
  background-color: $w-transfer-ticket--bg-color;
  border-radius: $w-transfer-ticket--border-radius;
  display: flex;
  justify-content: space-between;
}

.w-transfer-ticket__text {
  margin-bottom: 0;
}

.w-transfer-ticket__label {
  line-height: $w-transfer-ticket__label--line-height;
}

.w-transfer-ticket__right-container,
.w-transfer-ticket__left-container {
  >:not(:last-child) {
    margin-bottom: $w-transfer-ticket__container--margin-bottom;
  }
}

.w-transfer-ticket__right-container {
  flex-shrink: 0;
  margin-left: $w-transfer-ticket__right-container--margin-left;
}

.w-transfer-ticket__small-label {
  color: $w-transfer-ticket__small-label--color;
}

.w-transfer-ticket__text-warning {
  text-transform: $w-transfer-ticket__text-warning--text-transform;
  color: $w-transfer-ticket__text-warning--color;
}

.w-transfer-ticket__text-empty {
  align-self: center;
  flex-grow: 1;
  text-align: center;
  color: $w-transfer-ticket__text-empty;
}
