/* -------------------------------------------------------------------------
 * TABLE
 */

.c-table {
  .c-table__item:last-child {
    border-bottom-width: $c-table__item--border-width;
    border-bottom-style: $c-table__item--border-style;
    border-bottom-color: $c-table__item--border-color;
  }
}

.c-table__item {
  border-top-width: $c-table__item--border-width;
  border-top-style: $c-table__item--border-style;
  border-top-color: $c-table__item--border-color;
  @include sp-inset-v($c-table__item--sp-inset-v);
}

// Modifiers
// ------------------------------------------------

// Stripped

.c-table--stripped {
  .c-table__item {
    border-top-color: $c-table__item--border-color--stripped;
    @include sp-inset($c-table__item--sp-inset--stripped);

    &:last-child {
      border-bottom-color: $c-table__item--border-color--stripped;
    }
  }

  .c-table__item:nth-child(odd) {
    background-color: $c-table__item--bg-color--stripped;
  }
}
