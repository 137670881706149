$w-voucher--bg-color:                                                   $f-color__brand-primary !default;
$w-voucher--bg-color-hover:                                             $f-color__brand-primary--dark !default;
$w-voucher--color:                                                      $f-color__neutral-white !default;

$w-voucher__logo--bg-image:                                             url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMzciIGhlaWdodD0iMTUyIj48cGF0aCBmaWxsPSIjRkFGQUZBIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDBoMTA5Ljk0MmMzNi4wNzcgNDkuODM2IDM2LjA3NyAxMTAuMTM4IDAgMTUySDBWMHoiLz48L3N2Zz4=") !default;

$w-voucher__icon--color:                                                $f-color__brand-primary !default;
$w-voucher__icon--font-size:                                            70px !default;
$w-voucher__icon--margin-bottom:                                        0 !default;
$w-voucher__icon--margin-left:                                          $f-space--tiny !default;

$w-voucher__tooltip--color:                                             $f-color__neutral-white !default;
$w-voucher__tooltip--bg-color:                                          $f-color__neutral-grey--dark !default;

$w-voucher--compact__icon--font-size:                                   40px !default;
$w-voucher--compact__icon--margin-bottom:                               $w-voucher__icon--margin-bottom / 2 !default;

$w-voucher__old-balance-amount--font-size:                              $f-font-size--xxs !default;
$w-voucher__old-balance-amount--font-family:                            $f-font-family__accent !default;
$w-voucher__old-balance-amount--font-weight:                            600 !default;
$w-voucher__old-balance-amount--margin-right:                           $f-space--tiny !default;

$w-voucher__balance_bonus_info_label--bg-color:                         $f-color__state-info--dark !default;
$w-voucher__balance_bonus_info_label--color:                            $f-color__neutral-white !default;
$w-voucher__balance_bonus_info_label--font-size:                        $f-font-size--xxs !default;
$w-voucher__balance_bonus_info_label--font-family:                      $f-font-family__primary !default;
$w-voucher__balance_bonus_info_label--sp-inset:                         $f-space--tiny !default;
$w-voucher__balance_bonus_info_label--font-weight:                      600 !default;

$w-voucher__refund-message-and-button--margin:                          $f-space--small $f-space--none !default;
