/* -------------------------------------------------------------------------
 * SIMPLE FORM VARS
 *
 */

$t-simple-form__form-container--inset--until-bp:              $l-site__wrapper-medium--inset !default;
$t-simple-form__form-container--sp-inset-top:                 $f-space--medium !default;
$t-simple-form__form-container--sp-inset-top--from-bp:        $f-space--large !default;
$t-simple-form__form-container--max-width:                    447px !default;
$t-simple-form__form-container--sp-stack-children:            $f-space--medium !default;
$t-simple-form__form-container--z-index:                      $f-z-index--1 !default;
$t-simple-form__background-image--min-height:                 320px !default;
$t-simple-form__margin-top--small:                            -$t-simple-form__background-image--min-height !default;
$t-simple-form__margin-top--medium:                           $t-simple-form__margin-top--small - $f-space--small !default;
$t-simple-form__margin-top--large:                            $t-simple-form__margin-top--small - $f-space--large !default;
$t-simple-form__background-image-content--padding:            160px !default;

$fr-simple-form__margin-bottom:                               $f-space--large !default;
$fr-simple-form__margin-bottom--from-bp-sml:                  $f-space--huge !default;

$t-simple-form__background-image--bp:                         "sm" !default;
$t-simple-form__background-image--bp-sml:                     "sml" !default;

$t-simple-form__icon--sp-inset-bottom:                        $f-space--small !default;
$t-simple-form__title--margin-bottom:                         $f-space--small !default;
$t-simple-form__default-icon--color:                          $f-color__neutral-grey !default;
$t-simple-form__failed-icon--color:                           $f-color__neutral-grey !default;
$t-simple-form__succeeded-icon--color:                        $f-color__state-success !default;
$t-simple-form__body-intro-sp-inset-bottom:                   $f-space--small !default;
$t-simple-form__body-intro-sp-stack:                          $f-space--medium !default;
$t-simple-form__email--font-weight:                           700 !default;

$t-simple-form__form-validation-message--font-family:         $f-font-family__accent !default;
$t-simple-form__form-validation-message--font-size:           $f-font-size--xs !default;
$t-simple-form__form-validation-message--margin-top:          $w-login-form__space-stack-children !default;

