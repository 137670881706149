/* -------------------------------------------------------------------------
 * SUBSCRIBE FORM VARIABLES
 */

 $w-subscribe-form--max-width:                               447px;
 $w-subscribe-form__space-stack-children:                    $f-space--small !default;
 $w-subscribe-form__form-validation-message--font-family:    $f-font-family__accent;
 $w-subscribe-form__form-validation-message--font-size:      $f-font-size--xs;
 $w-subscribe-form__form-validation-message--margin-top:     $w-subscribe-form__space-stack-children !default;

 $w-subscribe-form__title--margin-bottom:                    $f-space--small !default;
 $w-subscribe-form__logo-img--height:                        $f-font-size--m * 2.5 !default;
 $w-subscribe-form__logo-img--margin-bottom:                 $f-space--small !default;

 $w-subscribe-form__email-success-text--padding:             $f-space--small !default;
 $w-subscribe-form__email-error-text--margin-top:            $f-space--small !default;
