/* -------------------------------------------------------------------------
* Checkbox
*
*/

.c-checkbox {
  position: relative;

  &.has-child {
    .c-checkbox__label {
      font-weight: 700;
    }
  }
}

.c-checkbox__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.c-checkbox__label {
  position: relative;
  cursor: pointer;
  user-select: none;
  display: flex;
  @include m-body($c-checkbox__label--font);
}

.c-checkbox__element {
  position: relative;
  width: s-core-px-to-em($c-checkbox__element--container--size);
  height: s-core-px-to-em($c-checkbox__element--container--size);
  flex: 0 0 s-core-px-to-em($c-checkbox__element--container--size);
  border-radius: $c-checkbox__element--container--border-radius;
  box-sizing: $c-checkbox__element--box-sizing;

  &::before {
    content: "";
    position: absolute;
    left: $c-checkbox__element--size / 2;
    top: calc(50% - #{$c-checkbox__element--size / 2});
    width: s-core-px-to-em($c-checkbox__element--size);
    height: s-core-px-to-em($c-checkbox__element--size);
    display: inline-block;
    background-color: $c-checkbox__element--bg-color;
    border: s-core-px-to-em($c-checkbox__element--border-width) $c-checkbox__element--border-style $c-checkbox__element--border-color;
    border-radius: $c-checkbox__element--border-radius;
    transition-property: $c-checkbox--transition-property;
    transition-duration: $c-checkbox--transition-duration;
    transition-timing-function: $c-checkbox--transition-easing;
  }

  &::after {
    content: '';
    position: absolute;
    left: s-core-px-to-em($c-checkbox__checkmark--left);
    top: calc(50% - #{($c-checkbox__checkmark--height / 2) + $c-checkbox__checkmark--thickness});
    display: block;
    width: $c-checkbox__checkmark--width;
    height: s-core-px-to-em($c-checkbox__checkmark--height);
    border-style: solid;
    border-color: $c-checkbox__checkmark--color;
    border-width: 0 s-core-px-to-em($c-checkbox__checkmark--thickness) s-core-px-to-em($c-checkbox__checkmark--thickness) 0;
    transform: rotate(45deg) scale(0);
    opacity: 0;
    transition-property: $c-checkbox--transition-property;
    transition-duration: $c-checkbox--transition-duration;
    transition-timing-function: $c-checkbox--transition-easing;
  }
}

.c-checkbox__text-wrapper{
  @include sp-inset-v($c-checkbox__text-wrapper--sp-inset-v);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  .c-checkbox__text-content{
    display: flex;
    align-items: center;
    > :not(.c-checkbox__count){
      word-break: break-word;
    }
  }
}

.c-checkbox__text {
  display: inline-block;
}

.c-checkbox__image {
  max-width: $c-checkbox__image-max-width;
  margin-right: $c-checkbox__image-margin-right;
  margin-bottom: $c-checkbox__image-margin-bottom;
  margin-left: $c-checkbox__image-margin-left;
}

.c-checkbox--with-illustration {
  .c-checkbox__label {
    display: flex;
    flex-direction: column;
  }
  .c-checkbox__illustration-image {
    width: fit-content;
    align-self: center;
    margin-bottom: $c-checkbox__illustration-image;
  }

  .c-checkbox__element-container {
    display: flex;
  }

  .c-checkbox__text-wrapper {
    align-self: start;
  }
}

.c-checkbox__count {
  color: $c-checkbox__count--color;
  font-size: $c-checkbox__count--font-size;
  padding-left: $f-space--tiny;
  &::before{content: "("}
  &::after{content: ")"}
}

.c-checkbox__additional-text {
  color: $c-checkbox__additional-text--color;
  font-size: $c-checkbox__additional-text--font-size;
  display: block;
}

.c-checkbox__children  {
  padding-left: s-core-px-to-em($c-checkbox__element--container--size - ($c-checkbox__element--size * .5));
  margin-bottom: $f-space--small;
}


// User Interaction States
// -------------------------------------------------

// Hover / Focus State
.c-checkbox__label:hover,
.c-checkbox__input:focus ~ .c-checkbox__label {
  .c-checkbox__element {
    background-color: rgba($c-checkbox__element--hover--extra-border-color, $c-checkbox__element--extra-border--opacity);
    &::before {
      background-color: rgba($c-checkbox__element--hover--bg-color, $c-checkbox__element--extra-border--opacity);
      border: s-core-px-to-em($c-checkbox__element--border-width) $c-checkbox__element--border-style $c-checkbox__element--hover--border-color;
    }
  }
}

// Pressed / Active
.c-checkbox__label:active .c-checkbox__element {
  background-color: rgba($c-checkbox__element--pressed--extra-border-color, $c-checkbox__element--extra-border--opacity);
  &::before {
    background-color: rgba($c-checkbox__element--pressed--bg-color, $c-checkbox__element--extra-border--opacity);
    border: s-core-px-to-em($c-checkbox__element--border-width) $c-checkbox__element--border-style $c-checkbox__element--pressed--border-color;
  }
}

// Selected / Checked State
.c-checkbox__input:checked ~ .c-checkbox__label {
  .c-checkbox__element::before {
    background-color: $c-checkbox__element--selected--bg-color;
    border-color: $c-checkbox__element--selected--border--color;
    opacity: 1;
  }
  .c-checkbox__element::after {
    opacity: 1;
    transform: rotate(45deg) scale(1);
  }
}

// Sizes
// -------------------------------------------------
.c-checkbox--small {
  .c-checkbox__label {
    @include m-body--update($c-checkbox--small__label--font, $c-checkbox__label--font);
  }
}

// General States
// -------------------------------------------------

// Half Checked
.c-checkbox.is-half-checked {
  .c-checkbox__input:not(:checked) ~ .c-checkbox__label {
    .c-checkbox__element::before {
      background-color: $c-checkbox__element--selected--bg-color;
      border-color: $c-checkbox__element--selected--border--color;
    }
    .c-checkbox__element::after {
      opacity: 1;
      transform: rotate(0deg) scale(1);
      top: s-core-px-to-em($c-checkbox__half-checkmark--top);
      border-width: 0 0 s-core-px-to-em($c-checkbox__checkmark--thickness) 0;
    }
  }
}

// Disabled / Unresolved
.c-checkbox.is-disabled,
.c-checkbox.is-unresolved {
  opacity: $c-checkbox__disabled--opacity;
  pointer-events: none;
}

// Unresolved
.c-checkbox.is-unresolved,
.is-unresolved .c-checkbox {

  .c-checkbox__element::before {
    background-color: $c-checkbox__unresolved--text-background;
    border-color: $c-checkbox__unresolved--text-background;
  }

  .c-checkbox__text-wrapper {
    @include a-unresolved(
      $_top-before: $c-checkbox__unresolved--text-background--top,
      $_height-before: $c-checkbox__unresolved--text-background--height,
      $_width-after: $c-checkbox__unresolved--animation-bullet--width
    );
  }
}

// Validation States
// -------------------------------------------------

.c-checkbox {
  &.has-success {
    .c-checkbox__label .c-checkbox__element::before {
        border: s-core-px-to-em($c-checkbox__element--border-width) $c-checkbox__element--border-style $c-checkbox__element--success--border-color;
      }
  }
  &.has-warning {
    .c-checkbox__label .c-checkbox__element::before {
        border: s-core-px-to-em($c-checkbox__element--border-width) $c-checkbox__element--border-style $c-checkbox__element--warning--border-color;
      }
  }
  &.has-error {
    .c-checkbox__label .c-checkbox__element::before {
        border: s-core-px-to-em($c-checkbox__element--border-width) $c-checkbox__element--border-style $c-checkbox__element--error--border-color;
      }
  }
}
