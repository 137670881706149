.w-main-menu__action-item--search {
  position: relative;

  .c-autocomplete--live-search_new .c-floating-box {
    top: 100%;
    right: 0;
    left: auto;
    min-width: 332px;
    padding: 16px;

    @include t-mq($until: 'sm') {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      overflow: hidden;
    }

    .c-floating-box__body-content {
      padding: 0;
      overflow: hidden;
      height: 100%;

      .c-autocomplete__results-wrapper {
        border: 2px solid $f-color__neutral-grey--semi-light;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        max-height: 355px;
        margin-top: $f-space--small;
        margin-bottom: $f-space--tiny;
        position: relative;
        overflow-y: auto;

        @include t-mq($until: 'sm') {
          max-height: calc(100vh - 150px);
        }

        .c-autocomplete__results {
          padding-top: $f-space--small;

          .w-live-search__category {
            text-transform: initial;

            &::before {
              content: '';
              left: 16px;
              margin-right: 32px;
            }

            .w-live-search__result {
              padding: 12px 16px;
            }
          }
        }

        .c_autocomplete__results-footer {
          display: none;
          position: sticky;
          bottom: -1px;
          background: $cs-brand-primary--white--background;
          width: 100%;
          @include t-mq($from: 'sm') {
            display: block;
          }
        }
      }
    }
    .c-floating-box__footer {
      .c_autocomplete__results-footer {
        @include t-mq($from: 'sm') {
          display: none;
        }
      }
    }
  }
}
