.c-calendar-field-block{
  position: relative;
  .c-floating-box__body-content{
    padding-bottom: $c-calendar-field__floating-box--sp-bottom;
  }
  .c-calendar-field-label {
    margin-bottom: $c-calendar-field__label--margin-bottom;
    font-family: $c-calendar-field__label--font-family;
    font-weight: $c-calendar-field__label--font-weight;
    font-size: $c-calendar-field__label--font-size;
    display: inline-block;
  }
}

.c-calendar-field__body-footer{
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  @include t-mq($c-calendar-field--bp) {
    flex-direction: row;
  }
}