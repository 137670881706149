/* -------------------------------------------------------------------------
 * BOX mixins
 */

@mixin c-box--disable() {
  @include c-box--disable-border();
  background-color: inherit;
  @include sp-inset(0, 0);
}

@mixin c-box--disable-border() {
  border-radius: 0;
  border: inherit;
  box-shadow: inherit;
}
