/* -------------------------------------------------------------------------
 * FAQ VARS
 *
 */

$t-faq__main--bp:                                          "sml" !default;
$t-faq__children--sp-stack:                                $f-space--medium !default;
$t-faq__children--sp-stack--from-bp:                       $f-space--large !default;
$t-faq__children-last--sp-stack:                           $f-space--small !default;
$t-faq__children-last--sp-stack--from-bp:                  $f-space--medium !default;

// Base vars
// ---------------------
$t-faq__main-item--max-width:                               664px !default;
$t-faq__borders--border-width:                              1px !default;
$t-faq__borders--border-style:                              solid !default;
$t-faq__borders--border-color:                              $f-color__neutral-grey--semi-light !default;

// Top
// ---------------------
$t-faq__background-img--withdrawn--height:                  $e-form-elements--height !default;
$fr-faq__main-top--content--padding:                        $f-space--large !default;
$fr-faq__main-top__background-img--withdrawn--content--padding: $t-faq__background-img--withdrawn--height/3 !default;

// Image title
// ---------------------
$t-faq__main-top--title--margin-bottom:                     $f-space--medium !default;

// Top five
// ---------------------

$t-faq__top-five--margin-bottom:                             $f-space--large !default;

// Categories-grid-item
// ---------------------
$fr-faq-categories__category__title--icon-color:              $f-color__brand-primary !default;
$fr-faq-categories__category__title--icon-size:               map_get($m-icons__sizes, "large") !default;
$fr-faq-categories__category__title--icon--sp-inline:         $f-space--tiny !default;

$fr-faq-categories__grid-item__sp-stack:                     $f-space--small !default;
$fr-faq-categories__grid-item__list-item--border-width:      $t-faq__borders--border-width !default;
$fr-faq-categories__grid-item__list-item--border-style:      $t-faq__borders--border-style !default;
$fr-faq-categories__grid-item__list-item--border-color:      $t-faq__borders--border-color !default;
$fr-faq-categories__grid-item__list-item--color:             $e-document__body--color !default;
$fr-faq-categories__grid-item__list-item--hover--color:      $f-color__state-info !default;
$fr-faq-categories__grid-item__list-item--hover--bg-color:   $f-color__neutral-grey--ultra-light !default;
$fr-faq-categories__breakpoint-desktop:                      $f-breakpoint--lg !default;
$fr-faq-categories__breakpoint-mobile:                       $f-breakpoint--sm !default;

// Actionable-list
// ---------------------
$t-faq__actionable-list--border-width:                      $c-actionable-list__item--border-size !default;
$t-faq__actionable-list--border-color:                      $c-actionable-list__item--border-color !default;
$t-faq__actionable-list--margin:                            $c-actionable-list__item-link--sp-inset !default;
$t-faq__actionable-list--breakpoint-tablet:                 $c-actionable-list--breakpoint-tablet !default;
$t-faq__actionable-list-title--sp-inset-h:                  $t-faq__actionable-list--margin !default;

// Others
// ---------------------
$t-faq__search-results-title--color:                        $f-color__neutral-grey !default;

$t-faq__question-answer__sp-inset-top:                      $f-space--small !default;
$t-faq__question-answer--sp-stack:                          $f-space--small + $f-space--medium !default;


// Contact card
// ---------------------
$fr-faq-contact-cards__title--border-width:                  $t-faq__borders--border-width !default;
$fr-faq-contact-cards__title--border-style:                  $t-faq__borders--border-style !default;
$fr-faq-contact-cards__title--border-color:                  $t-faq__borders--border-color !default;
$fr-faq-contact-cards__title--sp-padding-bottom:             $f-space--small !default;

$fr-faq-contact-cards--margin-h--until-bp:                  - 1 * $l-site__wrapper-medium--inset !default;
$fr-faq-contact-cards--bp:                                  'sm' !default;
