/* -------------------------------------------------------------------------
 * Fragment Account Booking Message
 *  To inform bookings about error (not found, exception)
 *  or information using full width
 *
 */

$fr-account-booking-message--sp-inset-h:        $f-space--small !default;
$fr-account-booking-message__content--max-width:   600px;
$fr-account-booking-message__icon--font-size:   map_get($m-icons__sizes, "huge") !default;
$fr-account-booking-message--error__icon--font-size:   4em;
