$f-font-family__outfit:            'Outfit' !default;
$f-font-family__outfit--fallback:  sans-serif !default;

$f-font-family__outfit--crop: (
  "font-size":      40,
  "line-height":    1,
  "top":            5,
  "bottom":         6
) !default;

@font-face {
  font-family: $f-font-family__outfit;
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local('Outfit'),
  url('#{$s-config__path--fonts}/outfit/outfit-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$s-config__path--fonts}/outfit/outfit-regular.woff') format('woff'); /* Modern Browsers */
}

@if variable-exists(web-fonts) {
  $web-fonts: map-merge($web-fonts, ("Outfit": "#{$s-config__path--fonts}/outfit/outfit-regular.woff2"));
}

@font-face {
  font-family: $f-font-family__outfit;
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: local('Outfit SemiBold'), local('Outfit-SemiBold'),
  url('#{$s-config__path--fonts}/outfit/outfit-semibold.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$s-config__path--fonts}/outfit/outfit-semibold.woff') format('woff'); /* Modern Browsers */
}

@if variable-exists(web-fonts) {
  $web-fonts: map-merge($web-fonts, ("outfit-semibold": "#{$s-config__path--fonts}/outfit/outfit-semibold.woff2"));
}

@font-face {
  font-family: $f-font-family__outfit;
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: local('Outfit Bold'), local('Outfit-Bold'),
  url('#{$s-config__path--fonts}/outfit/outfit-bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$s-config__path--fonts}/outfit/outfit-bold.woff') format('woff'); /* Modern Browsers */
}

@if variable-exists(web-fonts) {
  $web-fonts: map-merge($web-fonts, ("outfit-bold": "#{$s-config__path--fonts}/outfit/outfit-bold.woff2"));
}
