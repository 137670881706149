/* -------------------------------------------------------------------------
 * PROMOTION IMAGE
 *
 */

.c-promotion-image {
  position: relative;
}

.c-promotion-image__link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $c-promotion-image__link--z-index;
}

.c-promotion-image--with-gradient::after {
  content:"";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: $c-promotion-image__bg-image--gradient--opacity;
  @include promotion-image__gradient(right, $c-promotion-image__bg-image--gradient--dark);
}

.c-promotion-image__content {
  pointer-events: none;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  width: 100%;
  text-align: center;
  z-index: $c-promotion-image__contents--z-index;
  @include sp-inset-h($c-promotion-image--padding--mobile);

  @include promotion-image__content-left($c-promotion-image--bp, true);
}

.c-promotion-image__label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include t-mq($from: $f-breakpoint--sm) {
    transform: translate(-50%, -50%) scale(2);
  }
}

.c-promotion-image__content--title,
.c-promotion-image__content--body {
  @include c-background-img--light-title;
  @include sp-stack(0);
}

.c-promotion-image__content--title {
  @include m-heading--complete($c-promotion-image__title--material, false);
}

.c-promotion-image__content--body {
  margin-top: $c-promotion-image__contents--sp-inner-stack;
  @include m-heading--complete($c-promotion-image__body--material, false);
}

.c-promotion-image__content--link {
  margin-top: $c-promotion-image__contents--sp-stack;
  pointer-events: auto;
}

.c-promotion-image--huge {
  .c-promotion-image__content--title {
    @include t-mq($from: $c-promotion-image--bp) {
      @include m-heading--update($c-promotion-image__title--material--huge--desktop, $c-promotion-image__title--material);
    }
  }

  .c-promotion-image__content--body {
    @include t-mq($from: $c-promotion-image--bp) {
      @include m-heading--update($c-promotion-image__body--material--huge--desktop, $c-promotion-image__body--material);
    }
  }
}

.c-promotion-image--light {
  .c-promotion-image__content--title, .c-promotion-image__content--body {
    color: $c-promotion-image__light-img--contents--color;
    text-shadow: $c-promotion-image__light-img--text-shadow;
  }

  &.c-promotion-image--with-gradient::after {
    @include promotion-image__gradient(right, $c-promotion-image__bg-image--gradient--light);
  }

  &.c-promotion-image--right.c-promotion-image--with-gradient {
    &::after {
      @include promotion-image__gradient(left, $c-promotion-image__bg-image--gradient--light);
    }
  }
}

.c-promotion-image--right {
  .c-promotion-image__content {
    @include promotion-image__content-right($c-promotion-image--bp, true);
  }

  &.c-promotion-image--with-gradient::after {
    @include promotion-image__gradient(left, $c-promotion-image__bg-image--gradient--dark);
  }
}

.c-promotion-image--center {
  .c-promotion-image__content {
    @include promotion-image__content-center($c-promotion-image--bp);
  }
}

/*
  Promotion-image Group
  ------------------------------------------------------
*/

.c-promotion-image__group {
  > .c-promotion-image {
    @include sp-stack($c-promotion-image__grouped--row-gap);

    &:last-child {
      @include sp-stack($f-space--none);
    }

    @include t-mq($from: $f-breakpoint--sm) {
      @include sp-stack($f-space--none);
    }

    .c-img {
      min-height: $c-promotion-image__grouped--image-size--default;

      @include t-mq($from: $f-breakpoint--sm) {
        min-height: $c-promotion-image__grouped--image-size--big;
      }
    }

    .c-promotion-image__content--body {
      display: none;

      @include t-mq($from: $f-breakpoint--sm) {
        display: block;
      }
    }
  }

  &[data-items-number="2"] {
    .c-promotion-image__content {
      @include t-mq($from: $f-breakpoint--sm) {
        text-align: center;
        max-width: 100%;
        @include sp-inset-h($c-promotion-image--padding--mobile);
      }

      @include promotion-image__content-right($f-breakpoint--md, false);
    }

    .c-promotion-image--with-gradient::after {
      @include promotion-image__gradient(left, $c-promotion-image__bg-image--gradient--dark);
    }
  }

  &[data-items-number="3"] {
    @include t-mq($from: $f-breakpoint--sm) {
      [data-c-promotion-image__index="2"],
      [data-c-promotion-image__index="3"] {
        .c-promotion-image__content--title {
          @include m-heading--complete($c-promotion-image__grouped__title--secondary--material, false);
          font-size: $c-promotion-image__grouped__title--secondary--font-size;
        }

        .c-promotion-image__content {
          @include sp-inset-h($c-promotion-image__grouped--image--secondary--padding)
        }

        .c-promotion-image__content--body {
          display: none;
        }
      }
    }
  }

  &[data-items-number="3"].is-row {
    [data-c-promotion-image__index="1"],
    [data-c-promotion-image__index="2"],
    [data-c-promotion-image__index="3"] {
      .c-promotion-image__content--body {
        display: block;
      }
    }
    @include t-mq($from: $f-breakpoint--sm) {
      [data-c-promotion-image__index="1"],
      [data-c-promotion-image__index="2"],
      [data-c-promotion-image__index="3"] {
        .c-promotion-image__content--title {
          @include m-heading--complete($c-promotion-image__grouped__title--secondary--material, false);
          font-size: $c-promotion-image__grouped__title--secondary--font-size;
        }

        .c-promotion-image__content {
          padding: unset;
        }

        .c-promotion-image__content--body {
          display: block;
        }
        .c-img {
          min-height: $c-promotion-image__grouped--image-size--big;
        }
      }
    }
  }

  &[data-items-number="2"],
  &[data-items-number="3"] {
    @include t-mq($from: $f-breakpoint--sm) {
      display: flex;
      flex-wrap: wrap;
      margin-left: -.5 * $c-promotion-image__grouped--row-gap;
      margin-right: -.5 * $c-promotion-image__grouped--row-gap;
    }

    @include t-mq($from: $f-breakpoint--md) {
      margin-left: -.5 * $c-promotion-image__grouped--row-gap--desktop;
      margin-right: -.5 * $c-promotion-image__grouped--row-gap--desktop;
    }

    > .c-promotion-image {
      @include t-mq($from: $f-breakpoint--sm) {
        flex-grow: 0;
        width: calc(50% - #{$c-promotion-image__grouped--row-gap});
        margin-left: .5 * $c-promotion-image__grouped--row-gap;
        margin-right: .5 * $c-promotion-image__grouped--row-gap;
      }

      @include t-mq($from: $f-breakpoint--md) {
        width: calc(50% - #{$c-promotion-image__grouped--row-gap--desktop});
        margin-left: .5 * $c-promotion-image__grouped--row-gap--desktop;
        margin-right: .5 * $c-promotion-image__grouped--row-gap--desktop;
      }
    }
  }

  &[data-items-number="3"] {
    @include t-mq($from: $f-breakpoint--sm) {
      > .c-promotion-image {
        &[data-c-promotion-image__index="1"] {
          width: 100%;
          margin-bottom: $c-promotion-image__grouped--row-gap;
        }

        &[data-c-promotion-image__index="2"],
        &[data-c-promotion-image__index="3"] {
          .c-img {
            min-height: $c-promotion-image__grouped--image-size--small;
          }
        }
      }
    }

    @include t-mq($from: $f-breakpoint--md) {
      > .c-promotion-image {
        &[data-c-promotion-image__index="1"] {
          margin-bottom: $c-promotion-image__grouped--row-gap--desktop;
        }
      }

      @supports (display: grid) {
        display: grid;
        margin-left: 0;
        margin-right: 0;
        grid-column-gap: $c-promotion-image__grouped--column-gap;
        grid-row-gap: $c-promotion-image__grouped--row-gap;

        > .c-promotion-image {
          &[data-c-promotion-image__index="1"],
          &[data-c-promotion-image__index="2"],
          &[data-c-promotion-image__index="3"] {
            margin: 0;
            width: auto;
          }

          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 3;

          .c-img {
            min-height: initial;
            height: 100%;
            @include o-ratio--unset();
          }

          &[data-c-promotion-image__index="2"] {
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 2;
          }

          &[data-c-promotion-image__index="3"] {
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 2;
            grid-row-end: 3;
          }
        }
      }
    }
  }
}

/*
  Stretch
  ------------------------------------------------------
*/
.c-promotion-image--stretch {
  display: flex;

  .c-img {
    flex-grow: 1;
  }
}

.c-promotion-image--position-vertical {
  .c-promotion-image__content {
    &.c-promotion-image__content--align-top {
      top: $c-promotion-image--position-vertical--top;
    }
    &.c-promotion-image__content--align-middle {
      top: $c-promotion-image--position-vertical--middle;
    }
    &.c-promotion-image__content--align-bottom {
      top: $c-promotion-image--position-vertical--bottom;
    }

    &:not([class*="c-promotion-image__content--align-"]) {
      height: 100%;

      .c-promotion-image__content-item {
        width: 100%;

        &.c-promotion-image__content-item--align-top,
        &.c-promotion-image__content-item--align-middle,
        &.c-promotion-image__content-item--align-bottom {
          position: absolute;
          transform: translateY(-50%);
          margin: 0;
        }

        &.c-promotion-image__content-item--align-top {
          top: $c-promotion-image--position-vertical--top;
        }
        &.c-promotion-image__content-item--align-middle {
          top: $c-promotion-image--position-vertical--middle;
        }
        &.c-promotion-image__content-item--align-bottom {
          top: $c-promotion-image--position-vertical--bottom;
        }
      }
    }

  }

  .c-promotion-image__content-inner {
    height: 100%;
    position: relative;
  }
}

/*
  3 items in a row
  ------------------------------------------------------
*/

.c-promotion-image__group[data-items-number="3"].is-row{
  @include t-mq($from: $f-breakpoint--sm) {
    .c-promotion-image {
      &[data-c-promotion-image__index="1"] {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
      }
      &[data-c-promotion-image__index="2"] {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
      }
      &[data-c-promotion-image__index="3"] {
        grid-column-start: 3;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
      }
    }
  }
}

/*
  Video
  ------------------------------------------------------
*/

.c-promotion-image__video{
  @include t-mq($from: $f-breakpoint--md) {
    height: $c-promotion-image__video__full-height;
    }
  .c-video{
    @include t-mq($from: $f-breakpoint--md) {
    height: $c-promotion-image__video__full-height;
    }
    .c-video-wrapper{
      @include t-mq($from: $f-breakpoint--md) {
      height: $c-promotion-image__video__full-height;
      }
    }
  }
}

/**
Solid Color
*/
.c-promotion-image--solid-color{
    background-color: $c-promotion-image__solid-color__bc;
    min-height: $c-promotion-image__grouped--image-size--default;

    .c-promotion-image__content {

      @include t-mq($from: $f-breakpoint--sm) {
        max-width: $c-promotion-image__solid-color--max-width--desktop;
      }
    }

    @include t-mq($from: $f-breakpoint--sm) {
      min-height: $c-promotion-image__grouped--image-size--big;
    }
}
