/* -------------------------------------------------------------------------
 * BOOKING GATE
 *
 */

.w-booking-gate__item-wrapper {
  padding: $w-booking-gate--spacing-y 0;
  background-color: $w-booking-gate__item-wrapper--background-color;
  box-shadow: $w-booking-gate__item-wrapper--box-shadow-length 0 0 0 $w-booking-gate__item-wrapper--box-shadow-color, -$w-booking-gate__item-wrapper--box-shadow-length 0 0 0 $w-booking-gate__item-wrapper--box-shadow-color;
  position: relative;
}

.w-booking-gate__item-close {
  position: absolute;
  top: $w-booking-gate--spacing-y * 1.5;
  right: $w-booking-gate--spacing-y * .5;
  color: $f-color__neutral-grey--dark;
  &:hover {
    color: $f-color__neutral-black;
  }
  .c-btn__icon::#{$m-icons__pseudo-element} {
    font-size: $f-font-size--xl;
  }
}

.w-booking-gate__item-wrapper-main {
  box-shadow: $w-booking-gate__item-wrapper-main--shadow rgba($w-booking-gate__item-wrapper-main--shadow-color, $w-booking-gate__item-wrapper-main--shadow-opacity);
  background-color: $f-color__neutral-white;
  border-radius: $f-radius--medium;
  margin-bottom: $f-space;
  margin-left: -$f-space--small;
  margin-right: -$f-space--small;

  @include t-mq($from: $f-breakpoint--xs) {
    margin-left: 0;
    margin-right: 0;
  }

  @include sp-inset($f-space--small);
  @include sp-stack-children($f-space--small);
  @include t-mq($from:$f-breakpoint--sm) {
    @include sp-stack-children($f-space--medium);
    @include sp-inset($f-space--medium);
  }

  > * {
    &:empty {
      display: none;
    }
  }
}

.w-booking-gate__item-title {
  padding: $f-space--small 0;
  border-bottom: 2px solid $f-color__neutral-grey--ultra-light;
  margin-bottom: $f-space;
}

.w-booking-gate__filters-box {
  > .c-box__content {
    margin-bottom: -$f-space--medium;
  }
}

.w-booking-gate__filters {
  display: grid;
  column-gap: $f-space;
  grid-template-columns: repeat(auto-fit, minmax($w-booking-gate__filter--width, 1fr));

  &.is-disabled {
    pointer-events: none;
  }
}

.w-booking-gate__main {
  position: relative;
  min-height: $w-booking-gate__price-table--min-height;
}

.w-booking-gate__price-table-loader {
  // These lines are for IE11 which doesn't support position:sticky,
  // and overridden with @supports rule for modern browsers.
  position: fixed;
  width: 100%;
  left: 0;
  @supports (position: sticky) {
    position: sticky;
    width: auto;
    left: auto;
  }
  z-index: $f-z-index--2;
  top: 25vh;
  display: none;
}
// Price table messages
.w-booking-gate__price-table-messages {
  .c-absolute-dialog {
    margin-bottom: $f-space--medium;
  }
}

// Loading state
.w-booking-gate.is-loading {
  .w-booking-gate__price-table-loader {
    display: flex;
    justify-content: center;
    .w-booking-gate__loader{
      position: absolute;
    }
  }
  .w-filter--tabs,
  .w-booking-gate__price-table-container,
  .w-booking-gate__no-results,
  .w-booking-gate__price-table-messages {
    opacity: .25;
    pointer-events: none;
  }
}

// No results
.w-booking-gate__no-results-text {
  margin-top: $f-space--large;
}

.w-booking-gate__no-results-alternatives {
  margin-left: $f-space--medium;
}

.w-booking-gate__alternatives-item {
  margin-bottom: $w-booking-gate__alternatives-item--margin-bottom;
}

.w-booking-gate__alternatives-item .c-title {
    margin-bottom: $f-space--small;
}

.w-booking-gate__alternatives-chip-wrapper .c-chip {
  text-transform: none;
  margin-bottom: $w-booking-gate__alternatives-chips--margin-bottom;
}

//Error
.w-booking-gate__error {
  display: flex;
  justify-content: center;
}

// Error Modal
.w-booking-gate__error-modal {
  position: absolute;
  top: 25%;
  box-shadow: $f-shadow--elevated rgba($f-color__neutral-black, .5);
}

// Error Modal
.w-booking-gate__start-booking-error-modal {
  box-shadow: $f-shadow--elevated rgba($f-color__neutral-black, .5);
}

.w-booking-gate__out-of-season-modal-button{
  white-space: nowrap;
}

// Hide subscbribe-form inside booking-get price tab
// [MM-styleGuideLegacy]
.w-booking-gate ~ .w-subscribe-form {
  display: none;
}

// Force max-width dd-grid and month filters inside booking gate.
.w-booking-gate[data-w-booking-gate__price-table-type="DepartureDateAndDuration"] {
  .w-filter--tabs[data-w-filters__name="Month"], .w-dd-grid {
    max-width: $w-booking-gate__price-table-dd-grid--max-width;
    margin-left: auto;
    margin-right: auto;
  }
}

// A CSS rule that capitalized all the tabs was removed (see MI-4361), so a custom rule for the months tabs
// is created as it is desired to have the months names capitalized (but excluding the subscribe to next season
// button text, which is next to the months tabs and has similar CSS classes).
.w-booking-gate .w-filter--tabs[data-w-filters__name="Month"] .c-tabs__nav-button:not(.w-booking-gate__out-of-season-modal-button) {
  text-transform: capitalize;
}

.w-filter[data-w-filters__name="RoomType"].is-hidden {
  display: none;
}

.w-booking-gate__open-jaw-validation-modal {
  .c-modal__footer {
    display: flex;
    align-content: start;
    .c-btn{
      width: auto;
    }
  }
}