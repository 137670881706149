/* -------------------------------------------------------------------------
 * BUTTON
 *
 */

// Component selector output
.c-btn,
a.c-btn {
  display: inline-block;
  min-width: $c-btn--min-width;
  border-radius: $c-btn--border-radius;

  border: $c-btn--border-width solid $c-btn--border-color;
  color: $c-btn--color;
  background-color: $c-btn--bg-color;

  font-family: $c-btn--font-family;
  font-size: $c-btn--font-size;
  font-weight: $c-btn--font-weight;
  text-align: center;
  text-decoration: none;
  text-transform: $c-btn--text-transform;

  cursor: pointer;
  transition-property: $c-btn--transition--property;
  transition-duration: $c-btn--transition--out-duration;

  @include sp-inset($c-btn--spacing-inset-v,$c-btn--spacing-inset-h);
  @include t-text-crop-enable();
  @include t-text-crop($c-btn--font-family--crop, $c-btn--line-height);

  &:hover,
  &:active,
  &:focus,
  &[disabled] {
    transition-duration: $c-btn--transition--in-duration;
  }

  &:hover {
    border-color: $c-btn--hover--border-color;
    color: $c-btn--hover--color;
    background-color: $c-btn--hover--bg-color;

    .c-btn__loader {
      @include c-loader__set-color($c-btn--hover--color);
    }
  }

  &:active {
    border-color: $c-btn--pressed--border-color;
    color: $c-btn--pressed--color;
    background-color: $c-btn--pressed--bg-color;

    .c-btn__loader {
      @include c-loader__set-color($c-btn--pressed--color);
    }
  }

  &:focus {
    box-shadow: $c-btn--focus--shadow;
    outline: 0;
  }

  &[disabled] {
    pointer-events: none;
    opacity: $c-btn__disabled--opacity;
  }

  .c-btn__loader {
    @include c-loader__set-color($c-btn__loader--border-color);
  }

}

.c-btn__icon {
  position: relative;
  display: inline-block;
  height: .5em;
  vertical-align: middle;
  padding-left: calc((#{$c-btn__icon--size} + #{$c-btn__icon--space}) - 4px);
  margin-top: $c-btn__icon--vertical-adjust;

  // DON'T remove this line, is really useful for debugging the icon vertical adjust
  // background-color: rgba($f-color__brand-primary, .5);

  &::#{$m-icons__pseudo-element} {
    font-size: $c-btn__icon--size;
    position: absolute;
    top: calc(50% - .5em);
    right: 0;
  }
}

.c-btn__icon--left::#{$m-icons__pseudo-element} {
  left: 0;
  right: auto;
}

.c-btn__loader {
  position: relative;
  height: .5em;
  vertical-align: middle;
  padding-left: calc(#{$c-btn__loader--space} - 4px);
  margin-top: $c-btn__icon--vertical-adjust;

  // DON'T remove this line, is really useful for debugging the icon vertical adjust
  // background-color: rgba($f-color__brand-primary, .5);

  &.c-loader {
    display: inline-block; // Needs more specificity
  }

  &::#{$c-loader--pseudo-element} {
    font-size: $c-btn__icon--size;
    position: absolute;
    top: calc(50% - .5em);
    right: 0;
  }
}


// Block modifier
// --------------------------------------------------
.c-btn--block {
  display: block;
  width: 100%;
  position: relative;
}

// Variants
// --------------------------------------------------

@import "c-btn__secondary";
@import "c-btn__flow";
@import "c-btn__flat";
@import "c-btn__icon-only";
@import "c-btn__neutral";
@import "c-btn__trust";
@import "c-btn__delete";

// Color schemes overrides
// --------------------------------------------------
@import "color-schemes";
