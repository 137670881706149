/* -------------------------------------------------------------------------
 * PACKAGE BLOCK VARS
 */

$c-package-block__included--color:                  $f-color__neutral-grey--semi-dark !default;
$c-package-block__included--font-size:              1.6rem !default;
$c-package-block__included-separator:               "plus-small" !default;

$c-package-block__filters--separator:               '\002C' !default;

$c-package-block__filters--color:                   $c-package-block__included--color !default;
$c-package-block__departure--font-weight:           700 !default;

