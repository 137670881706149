/* -------------------------------------------------------------------------
 * PAGINATION
 *
 */

.c-pagination__list {
  display: inline-block;

  .c-pagination__button {
    float: left
  }
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.c-pagination__list-pages {
  display: inline;
}

.c-pagination__list-pages .c-pagination__button {
  display: none;
  @include c-pagination__set-visible-pages(1);
}

a.c-pagination__button {
  line-height: $c-pagination__button--size;
}

.c-btn.c-pagination__button {
  font-family: $c-pagination--font-family;
  color: $c-pagination__button--color;
  width: $c-pagination__button--size;
  height: $c-pagination__button--size;
  font-weight: $c-pagination--font-weight--normal;
  padding: $c-pagination__button--icon--padding--alignment-fix;
  border-radius: $c-pagination__button--border-radius;
  transition: none;

  &::before {
    color: $c-pagination__button--color;
    font-weight: $c-pagination--font-weight--normal;
  }

  &:hover {
    background-color: $c-pagination__background-color--hover;
    color: $c-pagination__color--hover;
    text-decoration: none;
  }

  &:active {
    &, &:hover {
      background-color: $c-pagination__background-color--pressed;
      color: $c-pagination__color--pressed;
      font-weight: $c-pagination--font-weight--bold;
      .c-btn__icon::before {
        color: $c-pagination__color--pressed;
      }
    }
  }

  &.is-active {
    background-color: $c-pagination__background-color--active;
    color: $c-pagination__color--active;
    font-weight: $c-pagination--font-weight--bold;
    pointer-events: none;
  }

  &.is-disabled {
    opacity: 0;
    pointer-events: none;
  }

  &.c-btn--icon-only {
    border: $c-pagination__button--border-width $c-pagination__button--border-style $c-pagination__button--border-color;
    border-radius: $c-pagination__button--border-radius;
    vertical-align: top;
    line-height: 1;

    .c-btn__icon::before {
      font-size: $c-pagination__button--icon--font-size;
      color: $c-pagination__button--icon--color;
      right: $c-pagination__button--icon--right--alignment-fix;
      top: $c-pagination__button--icon--top--alignment-fix;

      &:active {
        color: $c-pagination__color--active;
      }
    }
    .m-icon--chevron-left::before {
      right: $c-pagination__button-previous--icon--right--alignment-fix;
    }
  }
}

// States
// -------------------------------------------------

// Disabled
.c-pagination.is-disabled,
.is-disabled .c-pagination {
  .c-pagination__button {
    &, &.is-active {
      pointer-events: none;
      color: $c-pagination--disabled--color;
      border-color: $c-pagination--disabled--color;
      font-weight: $c-pagination--font-weight--normal;
      background-color: transparent;
    }
    &, .c-btn__icon {
      &::before {
        color: $c-pagination--disabled--color;
      }
    }
    &[disabled] {
      opacity: 1;
    }
  }
}
