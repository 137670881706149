/* -------------------------------------------------------------------------
 * Voucher redeemer
 *
 */

.w-voucher-redeemer__form-body-section {
  margin-bottom: $w-voucher-redeemer__form-body-section--stack;
}

.w-voucher-redeemer__form-button-section {
  display: flex;
  justify-content: center;

  & > * + * {
    margin-left: 24px;
  }
}

.w-voucher-redeemer__error-message {
  color: $w-voucher-redeemer--error--color;
  font-size: $f-font-size--s;
  text-align: center;
  display: none;
}
