/* -------------------------------------------------------------------------
 * REVEAL SLIDE
 *
 * Reveal from translated to untranslated position
 *
 * Params:
 * DURATION ........................... Animation duration
 * DIRECTION .......................... In-Movement direction (top, bottom, left, right)
 *
 */

// Animation selector output
// --------------------------------------------------

@if ($a-reveal-slide--enabled) {
  .a-reveal-slide {
    @include a-reveal-slide();

    &.in {
      @include a-reveal-slide--in();
    }
  }
}


// Duration modifiers output
// --------------------------------------------------

@if ($a-reveal-slide--enabled and $a-reveal-slide__mod-durations--enabled) {
  @each $_duration-name, $_duration-value in $a-reveal-slide__mod-durations {
    @include a-reveal-slide__mod-duration($_duration-name, $_duration-value);
  }
}


// Direction modifiers output
// --------------------------------------------------

@if ($a-reveal-slide--enabled and $a-reveal-slide__mod-directions--enabled) {
  @each $_direction-name in $a-reveal-slide__mod-directions {
    @include a-reveal-slide__mod-direction($_direction-name);
  }
}
