
/* -------------------------------------------------------------------------
 * FONT-SIZES
 *
 * Foundation font-sizes
 *
 */

// Utilities output
// --------------------------------------------------

@if ($u-font-sizes--enabled) {

  @each $_font-size__name, $_font-size__value in $u-font-sizes__map {
    .u-font-size--#{$_font-size__name} {
      font-size: $_font-size__value !important;
    }
  }
}
