.w-booking-car-rental__list .c-choice-list__option-rich-item{
  align-self: flex-start;
	margin-top: $w-booking-car-rental__list-option-rich-item--margin-top;
}
.w-booking-car-rental__items {
  .c-choice-list--rich-options.c-choice-list--boxed{
    .c-choice-list__option-rich{
      &.is-checked{
        .c-choice-list-rich__label{
          cursor: inherit;
        }
      }
      .w-booking-car-rental__option_radio_hide label{
        visibility: hidden;
      }
    }
  }
} 

.w-booking-car-rental__car-item{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include t-mq($w-booking-car-rental--bp-medium) {
    display: grid;
    grid-template-columns: minmax(auto, calc((100% - 190px))) 190px;
  }

  &.w-booking-car-rental__car-item--vertical-card{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-right: 12px;
    max-width: 100%;
    min-height: 290px;
    height:100%;
    justify-content: space-between;
    .w-booking-car-rental__car-information{
      width: 100%;
    }
    .w-booking-car-rental__item-price{
      margin-bottom:12px;
      width: 100%;
    }
  }
}
.w-booking-car-rental__list--one-row{
  .tns-nav{
    display: none;
  }
}

@include t-mq($until: $w-booking-car-rental--bp-medium) {
  .w-booking-car-rental__car-information{
      width: 100%;
  }
}

.w-booking-car-rental__car-information-item.w-booking-car-rental__car-information-item{
  padding: 0;
  padding-bottom: $w-booking-car-rental__car-information--padding-bottom;
  
  &.w-booking-car-rental__car-information-item--vertical-card{
    flex-direction: column;
  
    .c-car-item__details{
      max-width: 100%;
      margin-bottom: 12px;
    }
  }
}

.w-booking-car-rental__item-price{
  @include t-mq($w-booking-car-rental--bp-medium) {
    align-self: center;
    margin-right: $w-booking-car-rental__item-price--margin-right;
  }
  @include t-mq($until: $w-booking-car-rental--bp-medium) {
    align-self: flex-end;
    width: $w-booking-car-rental__item-price--width;
    margin-bottom: $w-booking-car-rental__item-price--margin-bottom;
  }
}

.w-booking-car-rental__trigger{
  margin: 0 auto;
  display: block;
  
  &[data-c-collapse__action="open"][data-c-collapse__opened="true"]{
   display: none;
  }
  &[data-c-collapse__action="close"]{
    display: none;
    &[data-c-collapse__opened="true"]{
      display: block;
     }
  }
}

.c-label.c-car-item__label{
  @include sp-stack($w-booking-car-rental__item--label--sp-stack);

  &.w-booking-car-rental__item--label-on-request{
    margin-left: 0;
    background-color: $w-booking-car-rental__item--label-on-request--background-color;
    color: $w-booking-car-rental__item--label-on-request--color;
    @include sp-inset-v($w-booking-car-rental__item--label-on-request--sp-inset-v);
    width: fit-content;
    height: fit-content;
  }
}

@include t-mq($until: $w-booking-car-rental--bp-medium) {
  .w-booking-car-rental__checkout-form {
    @include sp-stack($w-booking-car-rental__checkout-form--sp-stack);
  }
}

.w-booking-car-rental__edit-details{
  margin-top: $w-booking-car-rental__edit-details--sm-top;
}
.w-booking-car-rental__edit-details__box{
  background-color: $w-booking-car-rental__edit-details__box--background-color;
  border-color: $w-booking-car-rental__edit-details__box--background-color;
  @include c-box-arrow-background($w-booking-car-rental__edit-details__box--background-color);
  @include sp-stack($w-booking-car-rental__edit-details--sp-stack);
}

.w-booking-car-rental__item-ageWarning {
  display: inherit;
  @include sp-stack($w-booking-car-rental__item-age-warning--sp-stack--in);
  @include sp-inset($w-booking-car-rental__item-age-warning--sp-inset);

  &[hidden] {
    display: none;
  }
}

.w-booking-car-rental__checkout-form-footer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include t-mq($w-booking-car-rental--bp-medium) {
    flex-direction: row;
    justify-content: flex-end;
  }
}
.w-booking-car-rental__checkout-buttons{
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  @include t-mq($w-booking-car-rental--bp-medium) {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.w-booking-car-rental__optional-equipments.w-booking-car-rental__optional-equipments{
  white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
  @include t-text-crop-disable();
  clear: both;
}
.w-booking-car-rental__checkout-advice.w-booking-car-rental__checkout-advice{
  @include t-text-crop-disable();
}
.w-booking-car-rental__checkout-field.w-booking-car-rental__equipments{
  .c-choice-list__options{
    display: flex;
    flex-direction: column;
    @include t-mq($w-booking-car-rental__selected-breakpoint--md) {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-left: $w-booking-car-rental__equipments--margin;
      margin-right: $w-booking-car-rental__equipments--margin;
      .c-choice-list__option{
        flex: 1 1 30%;
        @include sp-inset-h($w-booking-car-rental__equipment--sp-inset);
      }
    }
    
    .c-choice-list__option{
      .c-checkbox__label{
        .c-checkbox__element{
          height: $w-booking-car-rental__equipments--height;
        }
      } 
    }
  }  
  .c-checkbox__label{
    background-color: $w-booking-car-rental__equipments--background-color;
  } 
}

.w-booking-car-rental__selected-info-section{
  @include t-mq($w-booking-car-rental__selected-breakpoint--md) {
    @include sp-inset($w-booking-car-rental__selected-info-section--sp-inset);
  }
  display: flex;
	flex-direction: column;
  .w-booking-car-rental__driver-info{
    white-space: nowrap;
    margin-right: $w-booking-car-rental__driver-info--margin-right;
  }
  .w-booking-car-rental__driver-info, .w-booking-car-rental__optional-equipments{
    color: $w-booking-car-rental__driver-info--color;
    font-size: $w-booking-car-rental__selected-font-size--bp-small;
  
    @include t-mq($w-booking-car-rental__selected-breakpoint--md) {
      font-size: $w-booking-car-rental__selected-font-size--bp-medium;
    }
  }
  .w-booking-car-rental__selected-info-details{
    display: flex;
    flex-direction: column;
	  @include t-mq($w-booking-car-rental__selected-breakpoint--md) {
      flex-direction: row;
    }
  }
  .w-booking-car-rental__selected-button-actions{
    display: flex;
    flex-direction: column;
    margin-top: $w-booking-car-rental__selected-button-actions--margin-top;
    @include t-mq($w-booking-car-rental__selected-breakpoint--md) {
      justify-content: end;
      flex-direction: row;
    }
  }  
  .w-booking-car-rental__selected-button{
    color: $w-booking-car-rental__driver-info--color;
    text-transform: inherit;
    padding: 0;
    margin-bottom: $w-booking-car-rental__selected-button--margin-bottom;
    @include t-mq($w-booking-car-rental__selected-breakpoint--md) {
      margin-bottom: 0;
      margin-left: $w-booking-car-rental__selected-button--margin-left;
    } 
  }
}

.w-booking-car-rental__usps {
  .c-bullet-list__item {
    align-items: center;
    break-inside: avoid;
    page-break-inside: avoid;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .c-bullet-list__icon {
    color: $w-booking-car-rental__usp-icon--color;
    font-size: $w-booking-car-rental__usp-icon--font-size;
  }
}

.w-booking-car-rental-edit__conditions-btn.w-booking-car-rental-edit__conditions-btn{
  text-transform: unset;
  margin-right: auto;
  @include sp-inset-h(0);
  @include t-mq($until: $w-booking-car-rental__selected-breakpoint--md) {
    text-align: left;
  }
  .c-btn__text{
    color: $w-booking-car-rental-edit__conditions-btn--color;
  }
  &:hover{
    .c-btn__text{
      text-decoration: underline;
    }
  }
}

.is-unresolved {
  .w-booking-car-rental{
    .w-booking-car-rental__items{
      .c-choice-list__option-rich{
        @include a-unresolved();
        &::before, &::after{
          z-index: 2;
        }  
      }
    }
  }
}

.w-booking-car-rental__separator{
  border-top: 1px solid $w-booking-car-rental_separator--color;
  padding-top: $w-booking-car-rental_separator--sp-top;
}

.w-booking-car-rental__add-extra-cars-title {
	margin-bottom: $w-booking-car-rental__add-extra-cars-title--sm-bottom;
}

.w-booking-car-rental__cars-list {
  .c-choice-list--with-slider{
    .c-slider__buttons--arrow {
      display: block;
      &[disabled]{
        opacity: .2;
        pointer-events: none;
      }
    }

    @include t-mq($from: $w-booking-car-rental--with-slider--bp-small) {
      .c-slider--with-gradient.c-slider--with-gradient.c-slider--outer-navigation {
        .tns-outer{
          &:before, &:after {
            background: none;
            width: 0;
          }
        }
      }
    }
    
  }
}

// Import credit card checkbox styles
@import "credit-card/credit-card";
