/* -------------------------------------------------------------------------
* QuickSearch Variables
*
*/

$w-quick-search--bp:                          "sm" !default;

$w-quick-search__publications--margin-bottom: ($f-space--medium + $f-space--tiny) !default;

$w-quick-search--sp-stack:                    $f-space--medium !default;
$w-quick-search--sp-inline:                   $f-space--medium !default;
$w-quick-search__filter--shadow--focus:       inset 0px 0px 4px 0px $e-form-elements--focus--border-color !default;
$w-quick-search__filters--sp-inline:          $f-space--small !default;
$w-quick-search__filter--mb:                  $f-space--small !default;
$w-quick-search__filter--min-height:          ($e-form-elements__field--font-size * $e-form-elements__field--line-height) + ($e-form-elements--sp-inset-v * 2) + ($c-dropdown__element--border-size * 2) !default;
$w-quick-search__filter-duration--p:          10px  !default;

$w-filter-on-top--font-weight:                600 !default;
$w-filter-on-top--padding-left:               $f-space--medium !default;
$w-filter-on-top--padding-right:              round($f-space * .75) !default;

$w-filter-on-top-c-background-img__dark-img--contents--color: $f-color__neutral-white !default;
$w-filter-on-top-c-background-img__dark-img--text-shadow:     $f-shadow--text rgba($f-shadow__color, $f-shadow__opacity--text) !default;

$w-quick-search--border-radius:               $f-radius--medium !default;
$c-button-group__c-btn--flat__border-bottom--width: 2px;
