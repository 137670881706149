/* -------------------------------------------------------------------------
 * COLLAPSE MIXINS
 *
 */

@mixin c-collapse() {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    will-change: max-height;
    transition-property: max-height;
    transition-duration: $c-collapse__duration;
    transition-timing-function: $c-collapse__easing;
  
    &:not([#{$c-collapse__attr__max-items}]) {
      max-height: 0;
    }
  
    &.is-checking  > * {
      opacity: 0;
    }
  
    &.in {
      max-height: none;
      transition-duration: $c-collapse__duration;
      &:not([#{$c-collapse__attr__max-items}]){
        overflow: visible;
      }
    }
  
    &.after-fading:not(.in):not(.is-idle):after, &.after-fading-light:not(.in):not(.is-idle):after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-top: $c-collapse--after-fading-padding-top;
      background-image: linear-gradient(to bottom, $c-collapse--after-fading-transparent, $c-collapse--after-fading-color-default);
    }
  
    &.after-fading-light:not(.in):not(.is-idle):after {
      background-image: linear-gradient(to bottom, $c-collapse--after-fading-transparent, $c-collapse--after-fading-color-light);
    }
}
  