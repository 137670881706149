/* -------------------------------------------------------------------------
 * CONTENT USPS
 *
 */

.w-content-usps {
  
  .c-bullet-list--inline > * {
    display: inline-flex;
  }

  &.w-content-usps--horizontal {
    text-align: center;
    .c-bullet-list__text,
    .c-bullet-list__text p {
      display: inline;
    }
  }

  &.w-content-usps--secondary-color-checkmark {
    .c-bullet-list__item {
      .c-bullet-list__icon::#{$m-icons__pseudo-element} {
        color: $w-content-usps--bullet-icon-secondary-color;
      }
    }
  }

  &.is-unresolved {
    .c-bullet-list__icon {
      display: none;
    }

    .c-bullet-list__item {
      @include a-unresolved();
    }
  }

  &.w-content-usps--usp-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; //fallback for IE11 since space-evenly is not supported
    justify-content: space-evenly;
  }

  // Slider variant
  .w-content-usps__usp-block-slider{
    width: 100%;

    .tns-nav{
      display: none;
    }
    
    .w-content-usps__usp-block-item{
      margin-left: auto;
      margin-right: auto;
    }

    @include t-mq($from: $w-content-usps--bp) {
      .c-slider__content{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .c-slider__item{
          width: auto;
          max-width: $w-content-usps__usp-block-item--max-width;
          flex-basis: $w-content-usps__usp-block-item--max-width;
        }
      }
    }   
  }

  .w-content-usps__usp-list-slider {
    .tns-nav{
      display: none;
    }
    
    .c-slider__item{
      margin-bottom: $w-content-usps__usp-list-slider--item-mb;
    }
  }

  &.w-content-usps--horizontal {
    .w-content-usps__usp-list-slider{
      .c-bullet-list__item{
        justify-content: center;
      }

      @include t-mq($from: $w-content-usps--bp) {
        .c-slider__item{
          margin-bottom: $w-content-usps__usp-list-slider--item-mb__sml;;
          margin-left:  $w-content-usps__usp-list-slider--item-ml__sml;
          margin-top: $w-content-usps__usp-list-slider--item-mt__sml;
          width: auto;
        }
      }
    }
  }
}

// Usp Block
// -----------------------------------------
.w-content-usps__usp-block-item {
  text-align: center;
  max-width: $w-content-usps__usp-block-item--max-width;
  flex-basis: $w-content-usps__usp-block-item--max-width;
}

.w-content-usps__usp-block-link {
  display: inline;
  &:hover {
    text-decoration: none;
  }
}

.w-content-usps__usp-block-image {
  max-width: 100%;
  width: auto;
  height: auto;
  margin-bottom: $w-content-usps__usp-block-item--spacing;
}

.w-content-usps__usp-block-title {
  @include sp-inset(0 $w-content-usps__usp-block-item--spacing);
  margin-bottom: $w-content-usps__usp-block-item--spacing;
  color: $w-content-usps__usp-block-title--color;
  font-weight: $w-content-usps__usp-block-title--fontweight;
  font-size: $w-content-usps__usp-block-title--fontsize;
}

.w-content-usps__usp-block-subtitle {
  @include sp-inset(0 $w-content-usps__usp-block-item--spacing);
  color: $w-content-usps__usp-block-subtitle--color;
  font-weight: $w-content-usps__usp-block-subtitle--fontweight;
}
