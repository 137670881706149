.l-site-main__content:not(.l-site-main__content--accommodation-no-tabs){
  .fr-promoted-price {
    .w-promoted-price {
      padding: 0;
      @include t-mq($from: 'xs') {
        @include sp-inset-h($f-space--small);
      }
      @include t-mq($from: 'sm') {
        position: absolute;
        top: 0;
        right: 50px;
        @include sp-inset-h($f-space--none);
      }
      @include t-mq($from: 'sml') {
        right: 60px;
      }
      @include t-mq($from: 'md') {
        top: $f-space--medium;
      }
    }
  } 
}


&.t-accommodation-no-tabs{
  .fr-tabs-container .c-tabs__content-wrapper .c-tabs__content:first-child {
    .fr-promoted-price {
      @include t-mq($from: $f-breakpoint--md) {
        position: relative;
        top: 0;
        padding-top: $fr-promoted-price__sticky--padding-top;
        height: 100%;
      }

      .w-promoted-price{
        position: sticky;
        transform: unset;
        top: 0;
        right: 0;
        width: 100%;

        .w-promoted-price__main{
          width: 100%;
        }
      }
    }
  }
}
