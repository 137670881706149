/* -------------------------------------------------------------------------
 * BUTTON
 */

// Default button
// -------------------------------
$c-btn--text-transform:                         unset !default;
$c-btn--border-radius:                          $f-radius--medium !default;
$c-btn--font-weight:                            600 !default;
$c-btn--spacing-inset:                          $f-space--medium + $f-space--tiny !default;

$c-btn__icon--vertical-adjust:                  -.2em !default;

// Hover
$c-btn--hover--color:                           $f-color__brand-primary !default;
$c-btn--hover--bg-color:                        $f-color__brand-primary--ultra-light !default;
$c-btn--hover--border-color:                    $f-color__brand-primary !default;

// Pressed
$c-btn--pressed--color:                         $f-color__brand-primary !default;
$c-btn--pressed--bg-color:                      $f-color__brand-primary--light !default;
$c-btn--pressed--border-color:                  $f-color__brand-primary !default;


// Variants > Secondary
// -------------------------------
$c-btn-secondary--color:                        $f-color__brand-primary--dark !default;
$c-btn-secondary--bg-color:                     $f-color__brand-secondary--light !default;
$c-btn-secondary--border-color:                 $f-color__brand-secondary--light !default;
$c-btn-secondary--shadow:                       none!default;

// Hover
$c-btn-secondary--hover--color:                 $f-color__brand-primary--dark !default;
$c-btn-secondary--hover--bg-color:              $f-color__brand-secondary--semi-light !default;
$c-btn-secondary--hover--border-color:          $f-color__brand-secondary--semi-light !default;

// Pressed
$c-btn-secondary--pressed--color:               $f-color__brand-primary--dark !default;
$c-btn-secondary--pressed--bg-color:            $f-color__brand-secondary !default;
$c-btn-secondary--pressed--border-color:        $f-color__brand-secondary !default;
$c-btn-secondary--pressed--shadow:              $f-shadow--glow $f-color__brand-secondary--light !default;

// Focus
$c-btn-secondary--focus--shadow:                $f-shadow--glow $f-color__brand-secondary--light !default;


// Variants > Neutral
// -------------------------------

$c-btn-neutral--color:                          $f-color__brand-primary--dark !default;
$c-btn-neutral--border-color:                   $f-color__brand-primary--dark !default;

// Hover
$c-btn-neutral--hover--color:                   $f-color__brand-primary--dark !default;
$c-btn-neutral--hover--border-color:            $f-color__brand-primary--dark !default;
$c-btn-neutral--hover--bg-color:                $f-color__neutral-grey--ultra-light !default;
$c-btn-neutral--hover--border-color:            $f-color__neutral-grey--semi-light !default;

// Focus
$c-btn-neutral--focus--color:                   $f-color__brand-primary--dark !default;
$c-btn-neutral--focus--border-color:            $f-color__brand-primary--dark !default;
$c-btn-neutral--focus--shadow:                  $f-shadow--glow $f-color__neutral-grey--semi-light !default;

// Pressed
$c-btn-neutral--pressed--color:                 $f-color__brand-primary--dark !default;
$c-btn-neutral--pressed--border-color:          $f-color__brand-primary--dark !default;
$c-btn-neutral--pressed--color:                 $f-color__neutral-grey--dark !default;
$c-btn-neutral--pressed--bg-color:              $f-color__neutral-grey--light !default;
$c-btn-neutral--pressed--border-color:          $f-color__neutral-grey--semi-light !default;


// Variants > Flow
// -------------------------------
$c-btn-flow--shadow:                            none !default;
$c-btn-flow--color:                             $f-color__neutral-white !default;

// Hover
$c-btn-flow--hover--bg-color:                   $f-color__brand-primary--semi-dark !default;
$c-btn-flow--hover--border-color:               $f-color__brand-primary--semi-dark !default;

// Pressed
$c-btn-flow--pressed--color:                    $f-color__brand-primary--ultra-light !default;
$c-btn-flow--pressed--bg-color:                 $f-color__brand-primary--semi-dark !default;
$c-btn-flow--pressed--border-color:             $f-color__brand-primary--semi-dark !default;
$c-btn-flow--pressed--shadow:                   none !default;

// Disabled
$c-btn-flow--disabled--opacity:                 .25 !default;


// Variants > Flat
// -------------------------------
// Hover
$c-btn-flat--hover--color:                      $f-color__neutral-grey--dark !default;

// Pressed
$c-btn-flat--pressed--color:                    $f-color__neutral-grey--dark !default;

// Variants > Trust
// -------------------------------

$c-btn-trust--font-size:                        $f-font-size--m !default;
$c-btn-trust--sp-inset-v:                       16px !default;
$c-btn-trust--bg-color:                         $f-color__state-success !default;
$c-btn-trust--border-color:                     $f-color__state-success !default;

// Hover
$c-btn-trust--hover--bg-color:                  $f-color__state-success--semi-dark !default;
$c-btn-trust--hover--border-color:              $f-color__state-success--semi-dark !default;

// Pressed
$c-btn-trust--pressed--bg-color:                $f-color__state-success--semi-dark !default;
$c-btn-trust--pressed--border-color:            $f-color__state-success--semi-dark !default;
$c-btn-trust--pressed--shadow:                  $f-shadow--glow $f-color__state-info--semi-dark  !default;

//Variants > Delete
// -------------------------------
$c-btn-delete--color:                           $f-color__state-danger !default;
$c-btn-delete--hover--color:                    $f-color__state-danger--dark !default;

// Variants > Icon Only
// -------------------------------

$c-btn-icon-only--size:                         36px !default;
