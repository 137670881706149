
/* -------------------------------------------------------------------------
 * THEME CULINARY Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-culinary--enabled {
  .cs-theme-culinary {
    @include color-scheme($cs-theme-culinary)
  }
}
