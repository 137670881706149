
/* -------------------------------------------------------------------------
 * BOX MIXINS
 *
 */


// Object as a mixin
// --------------------------------------------------

@mixin o-box() {
  display: block;
  @include t-clearfix();
}

// Unset as mixin
// --------------------------------------------------

@mixin o-box--unset() {
  display: inherit;
  @include t-clearfix--unset();
}
