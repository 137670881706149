/* -------------------------------------------------------------------------
 * ROOM TYPES
 *
 */

$w-room-types--bq:                                                              "xs" !default;

$w-room-types--card__margin-bottom:                                             $f-space--tiny !default;
$w-room-types-item--body-buttons__gap:                                          3% !default;
$w-room-types-item--body-buttons__gap--bq:                                      $f-space--small !default;
$w-room-types-item--body-buttons__margin-top:                                   $f-space--medium !default;

$w-room-types--slider__padding-bottom:                                          $f-space--tiny !default;