/* -------------------------------------------------------------------------
 * DESTINATIONS OVERVIEW
 *
 */

.t-destinations-overview {
  [class^="fr-"] {
    > .c-title {
      @include sp-stack($t-destinations-overview__title--sp-stack);
    }
  }
}

.t-destinations-overview__card__usp  {
  @include sp-stack($t-destinations-overview__card__usp--sp-stack);
}

.t-destinations-overview__card__btn:first-of-type {
  @include sp-stack($t-destinations-overview__card__btn--sp-stack);
}

@import 'fr-destinations-overview__header-img';
