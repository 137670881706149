/* -------------------------------------------------------------------------
 * SITE HEADER
 *
 */

// Selectors output
// -------------------------------------------------

.l-site-header {
  box-shadow: $l-site-header--shadow rgba($l-site-header--shadow-color, $l-site-header--shadow-opacity);

  .c-autocomplete {
    .c-autocomplete__result {
      text-align: left;
    }
  }
}

// Header top
// -------------------------------------------------

.l-site-header__top {
  position: absolute;
  left: 0;
  right: 0;
  top: -100%;
  @include sp-inset-v($l-site-header__top--sp-inset-v);
  @include t-mq($from: $l-site-header__top--visible-from) {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
  }

  .fr-header-menu-top .c-nav > .c-nav__list {
    z-index: $fr-header-menu-top__nav-list--z-index;
  }
}

.l-site-header__top-left {
  font-size: $l-site-header__top-left--font-size;
  padding-right: $l-site-header__top--sp-inline;
  white-space: nowrap;
}

.l-site-header__top-right {
  text-align: right;

  .fr-header-free-text-search {
    + .fr-header-menu-top {
      @include t-mq($until: $l-site-header__text-search-bp) {
        margin-right: $fr-header-free-text-search--width-first-bp + $l-site-header__top-live-search--gutter;
      }
      margin-right: $fr-header-free-text-search--width-second-bp + $l-site-header__top-live-search--gutter;

      .c-nav > .c-nav__list {
        padding-top: $l-site-header__top-live-search--nav-v-adjustment;
      }
    }
  }
}

// Header main
// -------------------------------------------------

.l-site-header__main {
  @include sp-inset-v($l-site-header__main--sp-inset-v);

  @include t-mq($from: $l-site-header--bp) {
    @include sp-inset-v($l-site-header__main--sp-inset-v--from-bp);
    padding-bottom: calc(#{$l-site-header__main--sp-inset-v--from-bp} - #{$l-site-header__main--offset--from-bp});
  }
}

.l-site-header__main-left {
  padding-right: $l-site-header__main--sp-inline;

  @include t-mq($from: $l-site-header--bp) {
    padding-bottom: $l-site-header__main--offset--from-bp;
    @include o-pack--mod-alignment(bottom);
  }
}

.l-site-header__main-right {
  text-align: right;

  @include t-mq($from: $l-site-header--bp) {
    text-align: left;
    @include o-pack--mod-alignment(bottom);
  }

  > .c-btn {
    @include sp-inline($l-site-header__main-icons--sp-inline);

    &:last-child {
      @include sp-inline($f-space--none);
    }
  }

  .c-nav__list {
    &[data-c-nav__level="3"],
    &[data-c-nav__level="4"] {
      text-transform: none;
    }
  }

  .c-btn__icon {
    color: $l-site-header__icons--color;
  }

  .l-site-header__mobile-icon {
    .c-btn__icon {
      &::#{$m-icons__pseudo-element} {
        font-size: $l-site-header__mobile-icons--font-size;
      }
    }
  }
}

.l-site-header__main-menu--last-to-right {
  li:last-child {
    float: right;
  }
}

// Header logo
// -------------------------------------------------

.l-site-header__logo {
  display: inline-block;
}

.l-site-header__logo-img {
  @include t-props(map_get($l-site-header__logo-img--props, "xxs"));
  @each $_breakpoint, $_props in map_remove($l-site-header__logo-img--props, "xxs") {
    @include t-mq($from: $_breakpoint) {
      @include t-props-diff(map_get($l-site-header__logo-img--props, "xxs"), $_props);
    }
  }
}

// Fragments
// -------------------------------------------------

@import 'fr-header-menu-side-drawer';
@import 'fr-header-free-text-search';
@import 'fr-header-menu-top';
@import 'fr-header-menu-main';

// Fragments: composition.
// -------------------------------------------------
.fr-header-menu-side-drawer,
.fr-header-free-text-search,
.fr-header-icon-free-text-search,
.fr-header-icon-contact-phone,
.fr-header-icon-favorite {
  display: inline;
}