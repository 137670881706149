/* -------------------------------------------------------------------------
 * CHIP
 */

.c-chip {
  display: inline-block;
  border-radius: $c-chip--radius;
  cursor: pointer;
  background-color: $c-chip--bg-color;
  color: $c-chip__text--color;
  position: relative;
  font-family: $c-chip--font-family;
  font-size: $c-chip__text--font-size;
  font-weight: $c-chip__text--font-weight;
  text-align: center;
  text-decoration: none;
  text-transform: $c-chip__text--text-transform;
  transition-property: $c-chip--transition--property;
  transition-duration: $c-chip--transition--duration;

  @include sp-inset($c-chip--spacing-inset);
  @include sp-inline($c-chip--sp-inline);

  @include t-text-crop-enable();
  @include t-text-crop($c-chip--font-family--crop, $c-chip--line-height);
  vertical-align: $c-chip__vertical-align;

  &:hover {
    background-color: $c-chip--hover--bg-color;
  }

  &.c-chip--removable, &.c-chip--linkable {
    display: inline-block;
    border-radius: $c-chip--radius;
    cursor: pointer;
    background-color: $c-chip--bg-color;
    color: $c-chip__text--color;
    position: relative;

    font-family: $c-chip--font-family;
    font-size: $c-chip__text--font-size;
    font-weight: $c-chip__text--font-weight;
    text-align: center;
    text-decoration: none;
    text-transform: $c-chip__text--text-transform;

    transition-property: $c-chip--transition--property;
    transition-duration: $c-chip--transition--duration;

    @include sp-inset($c-chip--spacing-inset);
    @include sp-inline($c-chip--sp-inline);

    @include t-text-crop-enable();
    @include t-text-crop($c-chip--font-family--crop, $c-chip--line-height);

    padding-right: calc(#{$c-chip--spacing-inset} + #{$c-chip__text--spacing-inline} + #{$c-chip__icon-font-size});
    vertical-align: $c-chip__vertical-align;

    &:hover {
      background-color: $c-chip--hover--bg-color;
    }
  }

  .c-chip__icon {
    color: $c-chip__icon-color;
    font-size: $c-chip__icon-font-size;
    vertical-align: $c-chip__vertical-align;
    padding: calc((40px - 1em)/2);
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
  }
  .c-chip__link {
    color: $c-chip__text--color;
    font-family: $c-chip--font-family;
    font-size: $c-chip__text--font-size;
    font-weight: $c-chip__text--font-weight;
    text-align: center;
    text-decoration: none;
    text-transform: $c-chip__text--text-transform;
  }

  &.c-chip--dark {
    background-color: $c-chip--bg-color--dark;
    color: $c-chip__text--color--dark;
    &:hover {
      background-color: $c-chip--hover--bg-color--dark;
    }
    .c-chip__icon {
      color: $c-chip__icon-color--dark;
    }

    .c-chip__link {
      color: $c-chip__text--color--dark;
    }
  }

  // Highlighted
  // -------------------------------------------------

  &.c-chip--highlighted {
    color: $c-chip__text--color--highlighted;
  }
  &.c-chip--mandatory {
    pointer-events: none;
  }
}

// States
// -------------------------------------------------

// Disabled
.is-disabled .c-chip,
.c-chip.is-disabled {
  opacity: $c-chip__opacity--disabled;
  pointer-events: none !important;
  cursor: default;
}
