/* -------------------------------------------------------------------------
 * RATIO
 *
 * Ratio-bound content block
 *
 */


// Object selector output
// -------------------------------------------

@if ($o-ratio--enabled) {
  .o-ratio {
    @include o-ratio();
  }

  .o-ratio__content {
    @include o-ratio__content();
  }
}


// Ratio modifiers output
// -------------------------------------------

@if ($o-ratio--enabled and $o-ratio__mod-ratios--enabled) {

  @each $_name, $_ratio in $o-ratio__mod-ratios {
    .o-ratio--#{$_name} {
      @include o-ratio--override($_ratio);
    }
  }
}


// Ratio modifiers output in breakpoints
// -------------------------------------------

@if ($o-ratio--enabled and $o-ratio__mod-ratios--enabled and $o-ratio__mod-bp--enabled) {

  @each $_bp-name, $_bp-value in $o-ratio__mod-bp {
    @each $_name, $_ratio in $o-ratio__mod-ratios {

      @include t-mq($from: $_bp-name) {
        .o-ratio--#{$_name}#{s-core-string-breakpoint($_bp-name)} {
          @include o-ratio--override($_ratio);
        }
      }
    }
  }
}
