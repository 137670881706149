// Top title variant

.c-card--top-title {
  .c-card__title {
    color: $c-card__title--top-title--color;
    background-color: $c-card__title--top-title--bg-color;
    margin: $c-card__title--top-title--space-inline;
    @include sp-stack($c-card__title--top-title--space-stack);
    @include sp-inset-squish($c-card__title--top-title--padding);
  }
  .c-card__title .c-card__title-link {
    color: $c-card__title--top-title--color;
  }

  .c-card__header + .c-card__body {
    .c-card__title {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      margin: 0;
      background-color: $c-card__title--top-title--bg-color--with-image;
    }
  }

  .c-card__body-heading {
    @include sp-stack($f-space--none);
  }
}
