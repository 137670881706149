// Collapse
@mixin destination-multiple-mbl() {
  .w-filter--destination-multiple {
    .c-floating-box__body{
      margin: unset;
    }
    .c-checkbox__children.collapse {
      display: none !important;
    }

    .w-destination-multiple-collapse {
      .c-checkbox__text-wrapper {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    .c-floating-box__header {
      display: flex;
      flex-direction: row;
      text-align: right;

      .c-textbox.w-destination-multiple-filter__autocomplete {
        flex-grow: 1;
        margin-right: $w-destination-multiple--checkbox-value-font-size;

        .c-textbox__wrapper input.c-textbox__element {
          border-top: none;
          border-left: none !important;
          border-right: none;
          border-radius: 0 !important;
          border-color: $f-color__neutral-grey--semi-light;
          cursor: text;
        }
      }

      .c-btn--flat {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .c-floating-box__footer {
      display: flex;
      flex-direction: row;
      text-align: right;

      .w-destination-multiple-filter__floating-box__mbl-btn {
        flex-grow: 1;
      }
    }

    .c-choice-list__options {
      .c-checkbox__text {
        font-weight: normal;
      }

      .c-checkbox.c-checkbox__bottom-line {
        &::after {
          content: "";
          display: block;
          width: calc(100% - 9px);
          border-top: $w-destination-multiple--checkbox-separator-border-size
            $w-destination-multiple--checkbox-separator-border-type
            $w-destination-multiple--checkbox-separator-border-color;
          height: $w-destination-multiple--checkbox-separator-height;
          margin-left: $w-destination-multiple--checkbox-separator-margin-left;
        }
      }

      .w-destination-multiple__checkbox-value {
        color: $f-color__neutral-grey;
        font-size: $w-destination-multiple--checkbox-value-font-size;
      }
    }

    .w-destination-multiple-filter__floating-box__btn {
      display: none;
    }

    .w-destination-multiple-filter__floating-box__mbl-btn {
      display: block;
    }

    .w-destination-multiple-filter__clear-btn {
      flex-grow: 1;
      font-size: $w-destination-multiple-filter__clear-btn--font-size;
      margin-left: $w-destination-multiple-filter__clear-btn--margin;
      margin-right: $w-destination-multiple-filter__clear-btn--margin;
      color: $w-destination-multiple-filter__clear-btn__color;
      text-transform: none;
      text-decoration: underline;

      &:hover {
        color: $w-destination-multiple-filter__clear-btn__color--hover;
      }

      &.hidden {
        display: none;
      }

      &.w-destination-multiple-filter__floating-box__mbl-btn {
        margin-bottom: 0;
      }
    }

    .c-choice-list__options {
      display: block;
    }
  }
}

@include destination-multiple-mbl();

.w-filter--destination-multiple {
  @include t-mq($from: $f-breakpoint--md) {
    .c-floating-box {
      min-width: $w-destination-multiple--floating-box__min-width;
      .c-textbox.w-destination-multiple-filter__autocomplete .c-textbox__wrapper {
        width: $w-destination-multiple--column-wrapper-width;
      }
    }

    .w-destination-multiple-filter__floating-box__btn {
      display: block;
    }

    .w-destination-multiple-filter__floating-box__mbl-btn {
      display: none;
    }

    .c-choice-list__options {
      display: flex;

      .c-choice-list__options--column-wrapper {
        width: $w-destination-multiple--column-wrapper-width;

        &:first-of-type {
          margin-right: $w-destination-multiple--column-wrapper-margin;
        }

        &:last-of-type {
          margin-right: $w-destination-multiple--last-column-wrapper-margin;
        }
      }
    }
  }
}
.w-search-map .w-search__filters {
  @include destination-multiple-mbl();
}
