/* -------------------------------------------------------------------------
* Autocomplete Variables
*
*/

$c-autocomplete--color:                                 $e-form-elements__field--color !default;
$c-autocomplete--bg-color:                              $e-form-elements__field--bg-color !default;
$c-autocomplete--border-radius:                         $f-radius--none !default;
$c-autocomplete--border-size:                           $e-form-elements__field--border--size !default;
$c-autocomplete--border-color:                          $f-color__neutral-grey--semi-light !default;
$c-autocomplete--bottom-distance:                       $f-space--small !default;
$c-autocomplete--z-index:                               $f-z-index--3 !default;
$c-autocomplete--sp-inset:                              $f-space--medium !default;
$c-autocomplete--shadow:                                $f-shadow--elevated !default;

$c-autocomplete__result--sp-inset-v:                    $f-space--small !default;

$c-autocomplete__caption--padding-left--destinations:   $f-space--medium + $f-space--small !default;
$c-autocomplete__caption--font-size--destinations:      $e-document__body--font-size !default;
$c-autocomplete__caption--color--destinations:          $e-document__body--color !default;
$c-autocomplete__caption--space:                        $f-space--tiny * .5 !default;

$c-autocomplete__icon--font-size--destinations:         map_get($m-icons__sizes, "medium") !default;
$c-autocomplete__icon--color--destinations:             $f-color__neutral-grey !default;

$c-autocomplete__mark--color:                           $f-color__state-info !default;
$c-autocomplete__mark--bg-color:                        transparent !default;

$c-autocomplete--selected--color:                       $f-color__state-info !default;
$c-autocomplete--selected--bg-color:                    $f-color__neutral-grey--ultra-light !default;

$c-autocomplete--selected__mark--color:                 $f-color__state-info--semi-dark !default;
$c-autocomplete--selected__mark--bg-color:              transparent !default;

$c-autocomplete--atributes--color:                      $cs-default--text--discrete !default;
$c-autocomplete--atributes--font-size:                  map-deep-get($m-body__mod-sizes, "tiny", "font-size") !default;

$c-autocomplete--result-loader--color:                  $cs-default--text--discrete !default;
$c-autocomplete--textbox-padding:                       34px !default;

