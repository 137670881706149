/* -------------------------------------------------------------------------
 * ABSOLUTE DIALOG VARS
 *
 */

$c-absolute-dialog--bp:                                 "xs" !default;

$c-absolute-dialog--sp-inset:                           $f-space--medium !default;
$c-absolute-dialog--min-height:                         4em !default;
$c-absolute-dialog--outside-border-width:               1px !default;
$c-absolute-dialog--outside-border-style:               solid !default;
$c-absolute-dialog--title-border-width:                 1px !default;
$c-absolute-dialog--title-border-style:                 solid !default;

$c-absolute-dialog__header--margin-bottom:              $f-space--small !default;

$c-absolute-dialog__title-icon--font-size:              1.2em !default;
$c-absolute-dialog__title-icon--margin-right:           $f-space--tiny !default;
$c-absolute-dialog__title-icon--position-top:           -1 * $f-space--tiny !default;

$c-absolute-dialog__title--padding-bottom:              $f-space--small + $f-space--tiny !default;

$c-absolute-dialog__content--max-height:                140px !default;
$c-absolute-dialog__content--text-align:                center !default;
$c-absolute-dialog__content--text-align--from-bp:       justify !default;

$c-absolute-dialog--dark-bg-opacity:                    .75 !default;
$c-absolute-dialog--dark-bg-color:                      rgba($f-color__neutral-black, $c-absolute-dialog--dark-bg-opacity) !default;

$c-absolute-dialog--transition-duration:                $f-duration--normal;


// Modifiers
// -----------------------------------

// Toast
$c-absolute-dialog--toast-width:                        375px !default;
$c-absolute-dialog--toast-width--from-bg:               300px !default;
$c-absolute-dialog--toast-margin:                       $f-space--small !default;
$c-absolute-dialog--toast-max-width:                    calc(100% - #{$c-absolute-dialog--toast-margin * 2}) !default;

// Closable
$c-absolute-dialog__close--padding-h:                   $f-space--small + $f-space--tiny !default;
$c-absolute-dialog__close--padding-v:                   $f-space--small !default;
$c-absolute-dialog--sp-inset-right--closable:           $f-space--large !default;
