/* -------------------------------------------------------------------------
 * MODAL
 *
 */

// Component selector output
// --------------------------------------------------

.c-modal-v2 {
  display: none;
  overflow: hidden;
  position: fixed;
  flex-direction: column;
  top: 50%;
  left: 50%;
  min-width: $c-modal-v2__min-width;
  max-height: 100%;
  width: 100%;
  background-color: $c-modal-v2--bg-color;
  z-index: $c-modal-v2--z-index;
  @include a-reveal-lift();
  transform: translate(-50%, -75%);
  border-radius: $c-modal-v2__border-radius;

  @include t-mq($until: $c-modal-v2--bp) {
    border-radius: unset;
    height: 100%;
  }

  @include t-mq($from: $c-modal-v2--bp) {
    max-width: calc(100% - $c-modal-v2--small__max-width-inset);
  }

  &.is-fix-height{
    &.in,
    &.is-opening,
    &.is-closing {
      height: 100%;
    }
  }

  &.in {
    display: flex;
    @include a-reveal-lift--in();
    transform: translate(-50%, -50%);
  }

  &.is-opening,
  &.is-closing {
    display: flex;
  }

  &[data-c-modal-v2--closable].has-preheader{
    @include t-mq($until: $f-breakpoint--xs) {
      .c-modal-v2__header-title{
        margin-right: auto;
        margin-left: auto;
        text-align: center;

        .c-modal-v2__header-text{
          padding-right: 0px;
        }
      }
    }
  }

  &.has-title:not(.has-logo){
    .c-modal-v2__header-title{
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &[data-c-modal-v2--closable]:not(.has-title):not(.has-logo){
    .c-modal-v2__header{
      padding-bottom: $c-modal-v2__header--mb;
    }
  }

  &:not(.has-title):not(.has-logo):not(.has-preheader){
    .c-modal-v2__close{

      .c-btn__icon{
        height: $c-modal-v2--sp-inset-close-btn-alone;

        &::before {
          font-size: $c-modal-v2--cross-size-close-btn-alone;
        }
      }
    }
  }

  &[data-c-modal-v2--closable]:not(.has-title) {
    .c-modal-v2__header {
      padding-bottom: 0px;
    }
  }
}

.c-modal-v2__pre-header{
  padding-right: $c-modal-v2__preheader--pr;
}

.c-modal-v2__header-title{
  width: 100%;
}

.c-modal-v2__header {
  position: relative;
  @include sp-inset($c-modal-v2--sp-inset-v, $c-modal-v2--sp-inset);
  display: flex;
  box-shadow: $c-modal-v2__header--box-shadow;
  background-color: $c-modal-v2__header--background-color;

  .c-modal-v2__header-logo-img {
    width: auto;
    margin-bottom: $c-modal-v2__header--logo-mb;
    height: $c-modal-v2__header--logo-height;
  }

  .c-btn__icon {
    &::#{$m-icons__pseudo-element} {
      font-size: $c-modal-v2__close--font-size;
      right: 0;
      left: auto;
    }
  }
}

.c-modal-v2__header-text {
  padding-right: $c-modal-v2__header--safe-text;
}

.c-modal-v2__body {
  @include sp-inset($c-modal-v2--sp-inset-v, $c-modal-v2--sp-inset);
  overflow-y: auto;
  display: block;
}

@if $m-body--enabled {
  .c-modal-v2__body {
    @include t-text-crop-disable();
  }
}

.c-modal-v2__body-content{
  display: grid;

  > *:last-child {
    margin-bottom: 0;
  }
}

.c-modal-v2__footer {
  @include sp-inset($c-modal-v2--sp-inset-v, $c-modal-v2--sp-inset);
  margin-top: auto;
  box-shadow: $c-modal-v2__footer--box-shadow;
  background-color: $c-modal-v2__footer--background-color;
  bottom: 0;
  width: 100%;
}

.c-modal-v2__close {
  margin-bottom: auto;
  margin-left: $c-modal-v2--cross-ml;
  color: $c-modal-v2--cross-color;
  @include sp-inset($f-space--none);

  &:hover {
    color: $c-modal-v2--cross-color-hover;
  }

  .c-btn__icon{
    height: $c-modal-v2--cross-height;
  }

  .c-btn__icon::before {
    font-size: $c-modal-v2--cross-size;
  }
}

// Since we have only one backdrop, when a modal is inside a side-drawer
// we need to fake it using a shadow, only when the side drawer is open
@each $_breakpoint in $c-side-drawer--breakpoints {
  @include t-mq($until: $_breakpoint) {
    .c-side-drawer[data-c-side-drawer__until="#{$_breakpoint}"] .c-modal-v2 {
      box-shadow: 0 0 $c-modal-v2--radius $c-modal-v2--radius $c-modal-v2--shadow;
    }
  }
}

// Modal size modifiers
// --------------------------------------------------

@include t-mq($from: $c-modal-v2--bp) {
  .c-modal-v2--small{
    width: $c-modal-v2--small__width;
    max-height: calc(100vh - $c-modal-v2--small__max-height-inset);
  }

  .c-modal-v2--medium{
    width: $c-modal-v2--medium__width;
    max-height: calc(100vh - $c-modal-v2--medium__max-height-inset);
  }

  .c-modal-v2--large{
    width: $c-modal-v2--large__width;
    max-height: calc(100vh - $c-modal-v2--large__max-height-inset);
  }

  .c-modal-v2--huge{
    width: $c-modal-v2--huge__width;
    max-height: calc(100vh - $c-modal-v2--huge__max-height-inset);
  }
}
