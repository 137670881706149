/* -------------------------------------------------------------------------
 * SITE FOOTER
 *
 */

.l-site-footer {
  a{
    color: $l-site-footer--link--color;

    &:hover,
    &:focus,
    &:active{
      color: $l-site-footer--link-hover--color;
    }
  }
}

.l-site-footer__main-content {
  .c-title {
    font-weight: $l-site-footer__main-title--font-weight;
  }

  .c-nav__item {
    @include sp-stack($l-site-footer__c-nav--sp-stack);
  }
}

.l-site-footer__bottom .o-swipe-btn--left {
  background: linear-gradient(to right, $l-site-footer__bottom--bg-color 40%, rgba($l-site-footer__bottom--bg-color, 0));
}

.l-site-footer__bottom .o-swipe-btn--right {
  background: linear-gradient(to left, $l-site-footer__bottom--bg-color 40%, rgba($l-site-footer__bottom--bg-color, 0));
}
