
.fr-account-booked-service-edit .w-booking-box.c-box{
  background-color: $fr-account-booked-service-edit--booking-box--bg-color;
  border: none;
  @include sp-inset($fr-account-booked-service-edit--padding-reset);
  @include t-mq($fr-account-booked-service-edit__breakpoint) {
    @include sp-inset($fr-account-booked-service-edit--booking-box--padding);
  }
  @include t-mq($until: $fr-account-booked-service-edit__breakpoint) {
    .w-booking-box__description, .w-booking-box__body {
      @include sp-inset-h($fr-account-booked-service-edit--booking-box--padding);
    }
  }
}

@include t-mq($until: $fr-account-booked-service-edit__breakpoint) {
  .fr-account-booked-service-edit{
    .l-site__wrapper--medium {
      @include sp-inset-h(0px);
    }
  }
  .w-booked-service-edit__main-right{
    @include sp-stack($w-booked-service-edit__main-right--margin-bottom)
  }
}

.fr-account-booked-service-edit{
  .c-modal {
    z-index: $w-booked-service-edit__modal--z-index;
  }
  .c-modal-v2 {
    z-index: $w-booked-service-edit__modal-v2--z-index;
  }
}

.fr-account-booked-service-edit__payment-options-side-drawer.fr-account-booked-service-edit__payment-options-side-drawer.c-side-drawer{
  transition-duration: $fr-account-booked-service-edit__payment-options-side-drawer--duration;
}

.fr-account-booked-service-edit__payment-options-side-drawer.fr-account-booked-service-edit__payment-options-side-drawer.c-side-drawer.in{
  z-index: $fr-account-booked-service-edit__layer-1;
  padding-bottom: $fr-account-booked-service-edit__payment-options-side-drawer--padding-bottom;
}

@include t-mq($until: $fr-account-booked-service-edit__breakpoint) {
  .fr-account-booked-service-edit__side-drawer--mobile{
    .fr-account-booked-service-edit__payment-buttons, .fr-account-booked-service-edit__cancel-button  {
      display: none;
    }
  }
}

.fr-account-booked-service-edit__pay-button{
  @include sp-stack($fr-account-booked-service-edit__pay-button--margin-bottom);
  &:last-child{
    @include sp-stack(0px);
  }
}

.fr-account-booked-service-edit__modal-success {
  &.w-booking-awaitable-popup__modal {
    .w-booking-awaitable-popup__modal-icon, .w-booking-awaitable-popup__modal-title{
      color: $fr-account-booked-service-edit__modal-success--color;
    }
  }
}

.fr-account-booked-service-edit__back-button{
  text-transform: inherit;
  &.has-error{
    display: none;
  }
}

.fr-account-booked-service-edit__conditions-modal {
  .w-booking-terms-conditions__conditions-container {
    margin-top: $fr-account-booked-service-edit__conditions-container--margin-top;
  }
  .w-booking-box.c-box.w-booking-terms-conditions  {
    padding: $fr-account-booked-service-edit__booking-terms-conditions--padding;
  }
}
  