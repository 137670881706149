/* -------------------------------------------------------------------------
 * LIST IN-LINE
 *
 * The list-inline object simply displays a list of items in one line.
 *
 */

// Object selector output
// --------------------------------------------------

@if ($o-list-inline--enabled) {
  .o-list-inline {
    @include o-list-inline();
  }
}
