/* -------------------------------------------------------------------------
 * PROGRESS CIRCLE VARS
 */


$c-progress-circle--bg-color:                    $f-color__neutral-grey--light !default;
$c-progress-circle--size:                        6em !default;
$c-progress-circle--border-radius:               $f-radius--rounded !default;

$c-progress-circle__border--width:               .8em !default;
$c-progress-circle__border--offset:              1px !default;
$c-progress-circle__border--color:               $f-color__state-info--semi-dark  !default;

$c-progress-circle__indicator--bg-color:         $e-document__body--bgcolor !default;
$c-progress-circle__indicator--size:             $c-progress-circle--size - ($c-progress-circle__border--width * 2) !default;
$c-progress-circle__indicator--z-index:          $f-z-index--1 !default;
$c-progress-circle__indicator--font-weight:      700 !default;

$c-progress-circle__title--stack:                $f-space--small !default;
