/* -------------------------------------------------------------------------
 * INSET SQUISH MIXINS
 *
 */

// Space as a mixin
// --------------------------------------------------

@mixin sp-inset-squish($_spacing-value) {
  padding: ($_spacing-value * .5) $_spacing-value;
}
