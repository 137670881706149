.w-booking-redeemer__booking{
  position: relative;

  .c-img{height: 100%;}
  &.is-selected{
    .w-booking-redeemer__booking__card{
      border: $w-booking-redeemer__booking__card--selected--border-width $w-booking-redeemer__booking__card--selected--border-color solid;
      .c-card__header, .c-card__body{
        background-color: $w-booking-redeemer__booking__card--selected--background-color;
        .c-img{height: 100%;}
      }
    }
  }
  .c-card-price__subtitle{
    .c-breadcrumbs{
      &:first-child {
        margin: $w-booking-redeemer__breadcrums--margin auto;
      }
      .c-breadcrumbs__link{
        color: $w-booking-redeemer__breadcrums--color;
      }
    }
  } 
  .w-booking-redeemer__booking__card{
    border-radius: $w-booking-redeemer__booking__card--border-radius;
    border: $w-booking-redeemer__booking__card--border-width $w-booking-redeemer__booking__card--border-style $w-booking-redeemer__booking__card--border-color;
    box-shadow: $w-booking-redeemer__booking__card--shadow rgba($w-booking-redeemer__booking__card--shadow-color, $w-booking-redeemer__booking__card--shadow-opacity);
    margin-left: $w-booking-redeemer__booking__card--spacing-inset;
    margin-top: $w-booking-redeemer__booking__card--spacing-inset;
    background-color: $w-booking-redeemer__booking__card--bg-color;

    @include t-mq($w-booking-redeemer--bp-medium) {
      display: grid;
      grid-template-columns: 160px auto;
      grid-template-rows: auto;
      grid-template-areas:
        "image info";
      .c-card__header{
        grid-area: image;
      }
      .c-card__body{
        grid-area: info;
      }
    }
    &.w-booking-redeemer__booking__card--without-image{
      @include t-mq($w-booking-redeemer--bp-medium) {
        display: block;
      }
      .c-card__header{
        display: none;
      }
    }
  }
}

.w-booking-redeemer__booking__card .w-booking-redeemer__booking__body{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .w-booking-redeemer__booking__body-buttons{
    align-self: flex-start;
    width: 100%;
    @include t-mq($until: $w-booking-redeemer--bp-medium) {
      .w-booking-redeemer__button-select, .w-booking-redeemer__button-revert{
        width: 100%;
      }
    }
  }
  @include t-mq($w-booking-redeemer--bp-medium) {
    flex-direction: row;
    align-items: center;

    .w-booking-redeemer__booking__body-buttons{
      width: auto;
      min-width: 140px;
      text-align: right;
    }
  }
}

.w-booking-redeemer__booking__card{
  .c-card__body{
    padding-top: $w-booking-redeemer__booking__card-body--sp-top;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: inherit;
  }
  .c-card-price__title{
    font-size: $w-booking-redeemer__booking__card__title--font-size;
  }
}

.w-booking-redeemer__button-revert{
  font-size: $w-booking-redeemer__buttons--font-size;
  color: $w-booking-redeemer__button-revert--color;
  @include sp-inset($w-booking-redeemer__button-revert--sp-inset-mobile);
  @include t-mq($w-booking-redeemer--bp-medium) {
    text-align: right;
    @include sp-inset($w-booking-redeemer__button-revert--sp-inset-v, $w-booking-redeemer__button-revert--sp-inset-h);
  }
  span{
    text-decoration: underline;
  }
}

.w-booking-redeemer__button-select{
  font-size: $w-booking-redeemer__buttons--font-size;
  @include sp-inset-v($w-booking-redeemer__button-select--sp-inset-v);
}

.w-booking-redeemer__usps {
  text-align: left;
  margin-bottom: $w-booking-redeemer__usps--margin-bottom;
  
  @include t-mq($from: $w-booking-redeemer--bp-medium) {
    display: flex;
    justify-content: center;
  }
  .c-bullet-list--inline > * {
    display: block;
    @include t-mq($w-booking-redeemer--bp-medium) {
      display: inline-flex;
    }
  }
  .c-bullet-list__icon {
    color: $w-booking-redeemer__usp--color;
  } 
  .c-bullet-list__text{
    font-size: $w-booking-redeemer__usp--font-size;
    font-weight: $w-booking-redeemer__usp--font-weight;
    color: $w-booking-redeemer__usp--color;
    @include t-mq($w-booking-redeemer--bp-medium) {
      font-size: $w-booking-redeemer__usp--font-size-desktop;
    }
  }
}

.w-booking-redeemer__booking__body-heading{
  margin-bottom: $w-booking-redeemer__booking__body-heading--margin-bottom;
 
  .c-bullet-list{
    margin-top: 0;
    margin-left: 0;

    > * {
      font-size: $w-booking-redeemer__booking__info--font-size;
      margin-top: $w-booking-redeemer__booking__bullet-list--margin-top;
      margin-left: $w-booking-redeemer__booking__bullet-list--margin-left;
      margin-bottom: $w-booking-redeemer__booking__bullet-list--margin-bottom;
      margin-right: $w-booking-redeemer__booking__bullet-list--margin-right;
      @include t-mq($until: $w-booking-redeemer--bp-medium) {
        display: block;
      }
      @include t-mq($from: $w-booking-redeemer--bp-medium) {
        .c-bullet-list__icon{
          padding-top: 0;
        }
      }
    }
  } 
  @include t-mq($w-booking-redeemer--bp-medium) {
    margin-bottom: 0;
    .c-bullet-list--inline > * {
      display: inline-flex;
    }
  }
}

.w-booking-redeemer__messages, .w-booking-redeemer__global-messages{
  margin-left: $w-booking-redeemer__booking__card--spacing-inset;
  margin-top: $w-booking-redeemer__messages--margin-top;
  .w-booking-message__box{
    @include sp-inset($w-booking-redeemer__messages--sp-inset-v, $w-booking-redeemer__messages--sp-inset-h);
    font-weight: $w-booking-redeemer__messages--font-weight;
  }
}

.w-booking-redeemer__global-messages{
  margin-top: $w-booking-redeemer__global-messages--margin-top;
}

.w-booking-redeemer__no-candidates-modal-trigger{
  cursor: pointer;
}
// HIDE
.c-btn.w-booking-redeemer__button-select, 
.c-btn.w-booking-redeemer__button-revert,
.w-booking-redeemer__messages, .w-booking-redeemer__global-messages{
  &.is-hidden{
      display: none
  }
} 
