// Flow
@mixin c-btn-flow {
  color: $c-btn-flow--color;
  background-color: $c-btn-flow--bg-color;
  border-color: $c-btn-flow--border-color;
  box-shadow: $c-btn-flow--shadow;

  .c-btn__loader {
    @include c-loader__set-color($c-btn-flow--color);
  }

  &:hover {
    color: $c-btn-flow--hover--color;
    background-color: $c-btn-flow--hover--bg-color;
    border-color: $c-btn-flow--hover--border-color;

    .c-btn__loader {
      @include c-loader__set-color($c-btn-flow--hover--color);
    }
  }

  &:active {
    color: $c-btn-flow--pressed--color;
    background-color: $c-btn-flow--pressed--bg-color;
    border-color: $c-btn-flow--pressed--border-color;
    box-shadow: $c-btn-flow--pressed--shadow;

    .c-btn__loader {
      @include c-loader__set-color($c-btn-flow--pressed--color);
    }
  }

  &:focus {
    box-shadow: $c-btn-flow--focus--shadow;
    color: $c-btn-flow--color;
  }
}

.c-btn--flow,
a.c-btn--flow {
  @include c-btn-flow()
}
