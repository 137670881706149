/* -------------------------------------------------------------------------
* Map Interactive
*
*/

.c-map-interactive {
  height: $c-map-interactive--mobile-height;

  @include t-mq($from: $c-map-interactive--breakpoint-tablet) {
    max-height: $c-map-interactive--tablet-max-height;
    height: $c-map-interactive--height;
  }

  @include t-mq($from: $c-map-interactive--breakpoint-desktop) {
    max-height: $c-map-interactive--desktop-max-height;
    height: $c-map-interactive--height;
  }
}

.c-map-interactive__container {
  height: 100%;
}

.c-map-interactive__card {
  position: absolute;
  left: 0;
  bottom: 0;
  height: $c-map-interactive__card--mobile-height;
  width: 100%;

  @include t-mq($until: $c-map-interactive--breakpoint-desktop) {
    position: fixed;
  }

  @include a-reveal-lift();
  transform: translateY(-25%);

  &.in {
    display: block;
    @include a-reveal-lift--in();
    transform: translateY(0);
  }

  &.is-opening,
  &.is-closing {
    display: block;
  }

  @include t-mq($from: $c-map-interactive--breakpoint-tablet) {
    width: $c-map-interactive__card--tablet-width;
    top: $c-map-interactive__card--offset;
    left: $c-map-interactive__card--offset;
    bottom: auto;
    height: auto;
  }

  .c-card__title {
    padding-right: $c-map-interactive__card-title--padding-right;
  }
}

.c-map-interactive__card-close {
  position: absolute;
  cursor: pointer;
  top: $c-map-interactive__card-close--mobile-offset;
  right: $c-map-interactive__card-close--mobile-offset;
  width: $c-map-interactive__card-close--size;
  height: $c-map-interactive__card-close--size;
  background: $c-map-interactive__card-close--background-color;
  border-radius: 50%;
  z-index: $c-map-interactive__card-close--z-index;
  display: none;
  justify-content: center;

  &::before {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @include t-mq($from: $c-map-interactive--breakpoint-tablet) {
    display: flex;
    top: $c-map-interactive__card-close--tablet-offset;
    right: $c-map-interactive__card-close--tablet-offset;
  }
}

.c-map-interactive__cluster {
  > div {
    outline: none;
    z-index: $c-map-interactive__cluster--z-index;
  }

  &::after {
    content: "";
    background: $c-map-interactive__cluster--hover-color;
    opacity: 0;
    display: block;
    position: absolute;
    border-radius: $c-map-interactive__cluster--border-radius;
    width: $c-map-interactive__cluster--size;
    height: $c-map-interactive__cluster--size;
    transform: scale(0.1);
    transition: transform $c-map-interactive__cluster--transition-duration $c-map-interactive__cluster--transition-easing, opacity $c-map-interactive__cluster--transition-duration $c-map-interactive__cluster--transition-easing;
  }

  &:hover {
    &::after {
      opacity: 1;
      transform: scale(1);
    }
  }
}

//important are necessary since google maps add inline styles

.c-map-interactive__custom-marker {
  font-family: $c-map-interactive__custom-marker--font-family !important;
  font-size: $c-map-interactive__custom-marker--font-size !important;
  color: $c-map-interactive__custom-marker--color !important;
  background-color: $c-map-interactive__custom-marker--bg-color;
  padding: $c-map-interactive__custom-marker--padding;
  border-radius: $c-map-interactive__custom-marker--border-radius;
  font-weight: $c-map-interactive__custom-marker--font-weight;
  box-shadow: $c-map-interactive__custom-marker--box-shadow;
  border: $c-map-interactive__custom-marker--border;

  &.c-map-interactive__custom-marker--visited {
    color: $c-map-interactive__custom-marker--color-visited !important;
    border-color: $c-map-interactive__custom-marker--visited-border-color;
    background-color: $c-map-interactive__custom-marker--bg-color-visited;
  }

  &.c-map-interactive__custom-marker--active {
    color: $c-map-interactive__custom-marker--color-active !important;
    background-color: $c-map-interactive__custom-marker--bg-color-active;
  }

  &.c-map-interactive__custom-marker--bounce {
    animation: markerBounce $c-map-interactive__custom-marker--bounce-duration ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    backface-visibility: hidden;
  }
}

.c-map-interactive__custom-marker--active,
.c-map-interactive__custom-marker.c-map-interactive__custom-marker--hover-in {
  border-color: $c-map-interactive__custom-marker--active-border-color;
  transform: $c-map-interactive__custom-marker--scale-transform;
}

@keyframes markerBounce {
  from {
    transform: translateY(0) scale(1);
  }
  to {
    transform: translateY($c-map-interactive__custom-marker--bounce-translate) $c-map-interactive__custom-marker--scale-transform;
  }
}

.c-map-interactive__unavailable-custom-marker.c-btn__icon {
  height: 32px;
  width: 32px;
  &::before {
    font-size: 1.75em;
    right: calc(50% - 0.5em);
  }
}

.c-map-interactive__destination-custom-marker {
  width: $c-map-interactive__destination-custom-marker--size;
  height: $c-map-interactive__destination-custom-marker--size;
  color: white !important;
  font-size: $c-map-interactive__destination-custom-marker--font-size !important;
  border: none;
  padding: 9px 0 0 0 !important;
  font-family: Branding Sunweb,Verdana,Geneva,sans-serif !important;

  &::after {
    content: "";
    background: $c-map-interactive__cluster--hover-color;
    opacity: 0;
    display: block;
    position: absolute;
    border-radius: $c-map-interactive__cluster--border-radius;
    width: $c-map-interactive__destination-custom-marker--size;
    height: $c-map-interactive__destination-custom-marker--size;
    top: $c-map-interactive__destination-custom-marker--top;
    z-index: $c-map-interactive__destination-custom-marker--z-index;
    transform: scale(0.1);
    transition: transform $c-map-interactive__cluster--transition-duration $c-map-interactive__cluster--transition-easing, opacity $c-map-interactive__cluster--transition-duration $c-map-interactive__cluster--transition-easing;
  }
}

.c-map-interactive__destination-custom-marker.hover {
  &::after {
    opacity: 1 !important;
    transform: scale(1) !important;
    top: $c-map-interactive__destination-custom-marker--top;
    z-index: $c-map-interactive__destination-custom-marker--z-index;
  }
}
