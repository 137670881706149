.fr-acco-back-button {
  margin-top: $t-accommodation__button-back--margin-top--mobile;
  @include t-mq($from: $l-site-main--bp-smaller) {
    margin-top: $t-accommodation__button-back--margin-top;
    display: none;
  }
  .c-btn {
    padding-left: 0;
    padding-right: 0;
    margin-left: $t-accommodation__button-back--margin-left;
  }
}

.t-accommodation__main-top .c-absolute-dialog + .t-accommodation__main-top-a .fr-acco-back-button {
  margin-top: 0;
}