/* -------------------------------------------------------------------------
 * DOWNLOAD BUTTON VARIABLES
 */

$c-download-button--color:                            $f-color__brand-primary--dark !default;
$c-download-button__title--font-family:               $f-font-family--secondary !default;
$c-download-button__title--font-weight:               600 !default;

$c-download-button--hover--shadow:                    $f-shadow--discrete !default;
$c-download-button--hover--shadow-opacity:            $f-shadow__opacity--discrete !default;
$c-download-button--hover--background-color:          $f-color__state-info--ultra-light !default;

$c-download-button__subtitle--color:                  $f-color__neutral-grey !default;

// Icons
$c-download-button__icon--color:                      $f-color__brand-primary--dark !default;

$c-download-button__download-icon--color:             $f-color__state-info !default;
$c-download-button__download-icon--hover--color:      $f-color__state-info--semi-dark !default;
