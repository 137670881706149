.c-accordion--small {
  .c-accordion__item {
    border-width: $c-accordion__item--border-width--small;
  }

  .c-accordion__item-heading {
    @include sp-inset-stretch($c-accordion--sp-inset--small);

    @include t-mq($from: $c-accordion--bp) {
      @include sp-inset($c-accordion--sp-inset--small);
    }


    &:hover {
      .c-accordion__item-trigger {
        background-color: $c-accordion__item-trigger--bg-color--small;

        .m-icon {
          color: $c-accordion__item-trigger--color--hover--small;
        }
      }
    }
  }

  .c-accordion__item-title {
    text-transform: $c-accordion__item-title--text-transform--small;
    padding-right: $c-accordion__item-title--padding-right--small;
  }

  .c-accordion__item-trigger.c-accordion__item-trigger {
    background-color: $c-accordion__item-trigger--bg-color--small;
    right: $c-accordion--sp-inset--until-bp--small;

    @include t-mq($from: $c-accordion--bp) {
      right: $c-accordion--sp-inset--small;
    }

    .m-icon {
      color: $c-accordion__item-trigger--color--small;
    }
  }

  .c-accordion__item-content {
    color: $c-accordion__content--color--small;
    padding-bottom: $c-accordion--sp-inset--small;
    @include sp-inset-h($c-accordion--sp-inset--small *.5);

    @include t-mq($from: $c-accordion--bp) {
      @include sp-inset-h($c-accordion--sp-inset--small);
    }
  }

  .c-accordion__item-title-icon {
    left: $c-accordion--sp-inset--until-bp--small;
    width: $c-accordion__item-title-icon--size--small;
    height: $c-accordion__item-title-icon--size--small;

    @include t-mq($from: $c-accordion--bp) {
      left: $c-accordion--sp-inset--small;
    }

    &.m-icon::before {
      font-size: $c-accordion__item-title-icon--size--small;
    }

    & + .c-accordion__item-title {
      padding-left: $c-accordion__item-title-icon--size--small + $c-accordion--sp-inset--until-bp--small;

      @include t-mq($from: $c-accordion--bp) {
        padding-left: $c-accordion__item-title-icon--size--small + $c-accordion--sp-inset--small;
      }
    }
  }
}
