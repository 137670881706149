
/* -------------------------------------------------------------------------
 * COLOR SCHEMES
 *
 * Color combination schemes that affects border, background, content,
 * links, headings and other colors.
 * Consider extend with your own.
 *
 */

@import 'brand-accent/main';
@import 'brand-primary/main';
@import 'brand-secondary/main';
@import 'default/main';
@import 'state-error/main';
@import 'state-info/main';
@import 'state-success/main';
@import 'state-warning/main';

.cs--transparent-bg {
  background-color: transparent;
}
