
/* -------------------------------------------------------------------------
 * HIDDEN MIXINS
 *
 */

// Utility breakpoints mixin
// --------------------------------------------------

@mixin u-hidden--bp($_direction: "from", $_breakpoint: null) {
  .u-hidden--#{$_direction}#{s-core-string-breakpoint($_breakpoint)} {

    @if ($_direction == "from") and ($_breakpoint != null) {
      @include t-mq($from: $_breakpoint) {
        display: none !important;
      }
    }

    @if ($_direction == "until") and ($_breakpoint != null) {
      @include t-mq($until: $_breakpoint) {
        display: none !important;
      }
    }
  }
}
