/* -------------------------------------------------------------------------
 * TABS
 */

$c-tabs--bp:                                                          "sm" !default;
$c-tabs--bp--smaller:                                                 "xs" !default;
$c-tabs--bp--smallest:                                                380px !default;

$c-tabs__nav--height:                                                 52px !default;
$c-tabs__nav--height--from-bp:                                        44px !default;
$c-tabs__nav--bg-color:                                               $f-color__neutral-grey--ultra-light !default;
$c-tabs__nav--shadow:                                                 $f-shadow--submersed rgba($f-shadow__color, $f-shadow__opacity--submersed) !default;

$c-tabs__nav-list--bg-color:                                          $e-document__body--bgcolor !default;

$c-tabs__nav-item--bg-color:                                          $c-tabs__nav--bg-color !default;
$c-tabs__nav-item--bg-color--open:                                    $e-document__body--bgcolor !default;
$c-tabs__nav-item--shadow:                                            $c-tabs__nav--shadow !default;
$c-tabs__nav-item--shadow--open:                                      $f-shadow--superimposed rgba($f-shadow__color, $f-shadow__opacity--superimposed) !default;
$c-tabs__nav-item--sp-inline:                                         2px !default;

$c-tabs__nav-button--font-size--until-bp:                             $f-font-size--xs !default;
$c-tabs__nav-button--font-weight:                                     700 !default;
$c-tabs__nav-button--sp-stack--until-bp:                              $f-space--tiny !default;
$c-tabs__nav-button--color:                                           $e-document__body--color !default;
$c-tabs__nav-button--color--hover:                                    $e-document__body--color !default;
$c-tabs__nav-button--bg-color--hover:                                 $f-color__neutral-grey--semi-light !default;
$c-tabs__nav-button--bg-color--open:                                  $f-color__neutral-grey !default;
$c-tabs__nav-button--color--open:                                     $e-document__body--color !default;
$c-tabs__nav-button--sp-inset-squish:                                 $f-space--small !default;
$c-tabs__nav-button--sp-inset-h:                                      $f-space--medium !default;
$c-tabs__nav-button--sp-inset-v:                                      ($f-space--medium / 3) * 2 !default;
$c-tabs__nav-button--sp-inset-h--from-bp:                             $f-space--medium !default;
$c-tabs__nav-button--sp-inset-v--from-bp:                             $f-space--small + ($f-space--tiny / 2) !default;
$c-tabs__nav-button--text-transform:                                  none !default;
$c-tabs__nav-button--color--open:                                     $f-color__brand-primary !default;
$c-tabs__nav-button--after-height--open:                              4px !default;
$c-tabs__nav-button--after-color--open:                               $f-color__brand-primary !default;
$c-tabs__nav-button--min-width--from-bp:                              150px !default;

$c-tabs__nav-button--color--featured:                                 $f-color__neutral-white !default;
$c-tabs__nav-button--bg-color--featured:                              $f-color__brand-primary !default;
$c-tabs__nav-button--bg-color--hover--featured:                       $f-color__brand-primary--semi-dark !default;
$c-tabs__nav-button--bg-color--open--featured:                        $f-color__brand-primary--dark !default;

$c-tabs__nav-button--icon-font-size--until-bp:                        18px !default;
$c-tabs__nav-button--icon-adjust--until-bp:                           4px !default;
$c-tabs__nav-button--text--until-bp:                                  4px !default;

$c-tabs__swipe-button--icon-color:                                    $f-color__neutral-grey--semi-dark !default;
$c-tabs__swipe-button--icon-vertical-align:                           -5px !default;
$c-tabs__swipe-button--icon-color--hover:                             $f-color__neutral-grey--ultra-dark !default;

$c-tabs__content--bg-color:                                           $e-document__body--bgcolor !default;
$c-tabs__content--sp-inset-stretch:                                   $f-space--large !default;
$c-tabs__content--sp-inset-stretch--nested:                           $f-space--medium !default;

$c-tabs__nav-item-width--until-bp:                                    26.5% !default;
$c-tabs__nav-item-width--until-bp--smallest:                          55% !default;
$c-tabs__nav-item-width--until-bp--smaller:                           37.5% !default;

$c-tabs__nav-list--featured-width--until-bp:                          180px !default;
$c-tabs__nav-list--featured-width--until-bp--smaller:                 40% !default;


// Variants
// ----------------------------------------

// Secondary
$c-tabs__nav--height--secondary:                                      48px !default;
$c-tabs__nav--height--from-mq--secondary:                             42px !default;
$c-tabs__nav--border-bottom-width--secondary:                         2px !default;
$c-tabs__nav--border-bottom-style--secondary:                         solid !default;
$c-tabs__nav--border-bottom-color--secondary:                         $f-color__neutral-grey--ultra-light !default;
$c-tabs__nav--bg-color--secondary:                                    $f-color__neutral-white--transparent !default;
$c-tabs__nav--shadow--secondary:                                      none !default;

$c-tabs__nav-item--bg-color--secondary:                               $f-color__neutral-white--transparent !default;

$c-tabs__nav-button--bg-color--hover--secondary:                      $f-color__neutral-white--transparent !default;
$c-tabs__nav-button--color--hover--secondary:                         $f-color__neutral-grey !default;
$c-tabs__nav-button--bg-color--open--secondary:                       $f-color__neutral-white--transparent !default;
$c-tabs__nav-button--color--open--secondary:                          $f-color__neutral-grey--semi-dark !default;
$c-tabs__nav-button--sp-inset--secondary:                             $f-space--medium / 4 !default;
$c-tabs__nav-button--height--secondary:                               40px !default;
$c-tabs__nav-button--height--secondary--until-bp:                     46px !default;

$c-tabs__nav-button--icon-sp-inline--secondary:                       $f-space--tiny !default;
$c-tabs__nav-button--icon-vertical-align--from-mq--secondary:         0 !default;

$c-tabs__nav-button--swipe-bottom--secondary:                         -1px !default;
