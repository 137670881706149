/* -------------------------------------------------------------------------
 * PAYMENT METHODS
 */

.w-payment__container {
  display: flex;
  flex-direction: column;

  .w-payment__pay-button__align-right{
    text-align: right;
  }

  &.is-hidden{
    display: none;
  }

  .c-radio-button__additional-text {
    color: $w-payment-methods__extratext-color;
    display: inline;
    padding-right: $w-payment-methods__extratext-pd;
    white-space: pre;
  }
}

.w-payment__methods {
  margin-bottom: $w-payment-methods__margin-right;
  margin-right: 0;

  .c-radio-button__text-wrapper {
    .c-radio-button__text-content{
      align-items: center;
    }

    .c-radio-button__text-wrapper--additional-text{
      display: flex;
      align-items: center;
    }
  }
}

.w-payment__payment-method {
  .c-radio-button__text {
    text-transform: none;
    &::first-letter {
      text-transform: none;
    }
  }
}

.w-payment__options{
  background-color: $w-payment-methods__options--bg-color;
  border: 1px solid $w-payment-methods__options--border-color;
  border-radius: $w-payment-methods--extra-border--border-radius;
  padding: $w-payment-methods__options--spacing;
  margin-left: 0;
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}

.w-payment__options-content {
  border-radius: $w-payment-methods__options-content--br;
  background-color: $w-payment-methods__options-content--bgcolor;
  padding: $w-payment-methods__options--spacing-top-bottom $w-payment-methods__options--spacing-left-right;
  height: 100%;

  .c-radio-button__additional-text {
    color: $w-payment-methods__options-extratext-price-color;
    font-weight: $w-payment-methods__options-extratext-price-fw;
    float: right;
    padding-right: $w-payment-methods__options-extratext-price-pd;
    font-size: $w-payment-methods__options-extratext-price-size;
  }

  input:checked + label .c-radio-button__additional-text {
    color: $w-payment-methods__options-extratext-price-color--selected;
  }

  .c-title {
    margin-bottom: $w-payment-methods__options-title--margin-bottom;
  }
}
.w-payment__payment-options-choice-list {
  @include sp-stack($w-payment-methods__options-choice-list--margin-bottom)
}

.w-payment__message-title{
  @include sp-stack($w-payment__title--sp-stack);
}

.is-unresolved {
  .w-payment__message-description, .w-payment__message-image-alert{
    @include a-unresolved();
    &::before, &::after{
      z-index: 1;
    }
  }
}

.c-box.w-payment__message {
  font-size: $w-payment__message--font-size;
  @include sp-stack($w-payment__message--sp-stack);
  padding: 0;

  .c-box__content{
    display: flex;
    .w-payment__message-image-alert{
      margin-bottom: 0px;
      overflow: hidden;
      position: relative;
      flex: 0 0 10em;
      .w-payment__message-image-alert-background{
        position: absolute;
        width: 100%;
        height: 100%;
        [class|="confetti"] {
          position: absolute;
        }
      }
      .w-payment__message-image{
        animation-name: paymentSuccess;
        animation-duration: $w-payment__message-image-alert__duration;
        animation-timing-function: $w-payment__message-image-alert__easing;
        animation-delay: $w-payment__message-image-alert__delay;
        animation-fill-mode: forwards;
        transform: translate(calc( -1 * #{$w-payment__message-image--translate-to}), $w-payment__message-image--translate-to);
        height: 100%;
        opacity: 0;
        img{
          transform: rotate($w-payment__message-image--rotate);
          position: absolute;
          width: auto;
          height: auto;
          bottom: 0;
        }
      }

    }
    .w-payment__message-content{
      @include sp-inset($w-payment__message-content--sp-inset);
    }
  }
}

@keyframes paymentSuccess {
  0% { opacity: 0; transform: translate(calc( -1 * #{$w-payment__message-image--translate-from}), $w-payment__message-image--translate-from);}
  10% { opacity: 1;}
  100% { opacity: 1; transform: translate(calc( -1 * #{$w-payment__message-image--translate-to}), $w-payment__message-image--translate-to);}
}

@for $i from 0 through 50 {
  $w: random(8);
  $l: random(100);
  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w*0.4}px;
    background-color: nth($w-payment__message-image-alert__confeti-colors, random(3));
    top: -10%;
    left: unquote($l+"%");
    opacity: random() + 0.5;
    transform: rotate(#{random()*360}deg);
    animation: drop-#{$i} unquote(1+random()+"s") unquote(random()+"s") infinite;
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l+random(15)+"%");
    }
  }
}

.w-payment__message:last-child {
  margin-bottom: 0;
}

.w-payment__alert-container + .w-payment__container {
  margin-top: $w-payment__message-only--sp-stack;
}

.w-payment__voucher-option{
  margin-bottom: $w-payment__voucher-option--margin-bottom;
}

.w-payment__voucher-widget-isolated {
  margin-bottom: $w-payment__voucher-widget-isolated--margin-bottom;
}

.w-payment__selected-title {
  margin-bottom: $w-payment__selected-title--margin-bottom;
}

//Voucher

.w-payment__voucher-selection-wrapper {
  margin-top: $w-payment__voucher-selection-wrapper--margin-top;
}

.w-payment__voucher-selection-description {
  margin-top: $w-payment__voucher-selection-description--margin-top;
  margin-bottom: $w-payment__voucher-selection-description--margin-bottom;
  font-size: $w-payment__voucher-selection-description--font-size;
}


//voucher options

.w-payment__voucher-options-expired-wrapper {
  text-align: center;
}

.w-payment__voucher-options-expired-text {
  display: flex;
  flex-direction: column;
  font-size:$w-payment__voucher-options-expired-text--font-size;
  margin-top: $w-payment__voucher-options-expired-text--margin-top;
}

.w-payment__voucher-options-booking-amount-wrapper {
  margin-bottom: $w-payment__voucher-options-booking-amount-wrapper--margin-bottom;
}

.w-payment__voucher-options-booking-amount {
  font-weight: $w-payment__voucher-options-booking-amount--font-weight;
}

.w-payment__voucher-options-balance-select-amount {
  display: flex;
  flex-direction: column;
}

.w-payment__voucher-options-choice-list-wrapper {
  .c-radio-button__text {
    text-transform: none;
  }
}

.w-payment__voucher-options-partial-redeemer-wrapper {
  display: none;
}

.w-payment__voucher-options-partial-redeemer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-top: $w-payment__voucher-options-partial-redeemer--margin-top;
  margin-right: $w-payment__voucher-options-partial-redeemer--margin-right;
  margin-bottom: $w-payment__voucher-options-partial-redeemer--margin-bottom;
}

.w-payment__voucher-options-input-label {
  margin-right: $w-payment__voucher-options-input-label--margin-right;
  max-width: $w-payment__voucher-options-input-label--min-width;
}

.w-payment__voucher-options-input {
  width: $w-payment__voucher-options-input--width;
  @include t-mq($from: $f-breakpoint--sm) {
    width: $w-payment__voucher-options-input--width--from-bp;
  }
}

.w-payment__voucher-options-currency {
  margin-left: $w-payment__voucher-options-currency--margin-left;
  font-weight: $w-payment__voucher-options-currency--font-weight;
  font-size: $w-payment__voucher-options-currency--font-size;
}

.w-payment__voucher-options-error-message {
  color: $w-payment__voucher-options-error-message--color;
  font-size: $w-payment__voucher-options-error-message--font-size;
  text-align: right;
  margin-top: $w-payment__voucher-options-error-message--margin-top;
  margin-right: $w-payment__voucher-options-error-message--margin-right;
  visibility: hidden;
}

.w-payment__voucher-options-info {
  margin-top: $w-payment__voucher-options-info--margin-top;
  font-size: $w-payment__voucher-options-info--font-size;
  color: $w-payment__voucher-options-info--color;
  margin-bottom: 0;

  &:before {
    content: "* "
  }
}

.w-payment__voucher-options-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: $w-payment__voucher-options-buttons--margin-top;

  & > * + * {
    margin-left: $w-payment__voucher-options-buttons--children-margin-left;
  }
}

.w-payment__voucher-options-expired-button {
  margin-top: $w-payment__voucher-options-expired-button--margin-top;
}


//Voucher selector
.w-payment__voucher-selector-vouchers-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: $w-payment__voucher-selector-vouchers-wrapper--margin;
}

.w-payment__voucher-widget {
  margin: $w-payment__voucher-widget--margin;
  width: 100%;
  @include t-mq($from: "sm", $until: "md") {
    width: calc(50% - (2 * #{$w-payment__voucher-widget--margin}));
  }
  @include t-mq($from: "lg") {
    width: calc(50% - (2 * #{$w-payment__voucher-widget--margin}));
  }
  @include t-mq($from: "xxl") {
    width: calc(33.333333% - (2 * #{$w-payment__voucher-widget--margin}));
  }
}

.w-payment__voucher-selector-add-link {
  display: inline-block;
  margin-top: $w-payment__voucher-selector-add-link--margin-top;
}

.w-payment__bonus-wrapper {
  margin-bottom: $w-payment__bonus-wrapper--margin-bottom;
}

.w-payment__bonus-header {
  display: flex;
  align-items: baseline;
}

.w-payment__bonus-icon {
  font-size: $w-payment__bonus-icon--font-size;
  @include sp-inline($w-payment__bonus-icon--sp-inline);
  margin-bottom: $w-payment__bonus-icon--margin-bottom;
}

.w-payment__bonus-title {
  align-self: center;
}

.w-payment__bonus-description {
  font-size: $w-payment__bonus-message--font-size;
}

//Voucher redeemer
.w-payment__voucher-redeemer-widget {
  margin-top: $w-payment__voucher-redeemer-widget--margin-top;
  .w-voucher-redeemer__form-button-section {
    justify-content: flex-end;
  }
}

.w-payment__voucher-redeemer-widget {
  .c-form__fieldset-container {
    @include c-form__fieldset-container--sp-stack-children($w-payment__voucher-redeemer-widget__space-stack-children);
  }
}

//Extra styles needed for the new implementation
.w-payment__brand-img{
  float: left;
  max-width: $w-payment-methods__brand--max-width;
  margin-left: $w-payment-methods__brand--margin-left;
}

.c-choice-list__option.w-payment__payment-method,
.c-choice-list__option.w-payment__payment-option-custompartial{
  > .c-radio-button__input:checked+.c-radio-button__label{
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.w-payment__payment-methods-choice-list .c-radio-button__label{
  align-items: center;
}
.w-payment__payment-method-loader{
  &::before {
    margin: 0 auto;
  }
  &.is-hidden{
    display: none;
  }
}

.w-payment__partial {
  hr {
    margin: 0;
    color: $w-payment-methods__options--border-color;
  }
  background-color: $w-payment-methods__options--bg-color;
  border: 2px solid $f-color__neutral-grey--semi-dark;
  border-radius: $w-payment__partial--extra-border--border-radius;
  padding: $w-payment__partial--padding;
  margin-left: 0;
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.w-payment__partial-wrapper {
  padding: $w-payment__partial-wrapper--padding-sm;
  @include t-mq($from: $f-breakpoint--sm) {
    padding: $w-payment__partial-wrapper--padding;
  }
}

.w-payment__partial-line {
  display: flex;
  justify-content: space-between;
  padding-top: $w-payment__partial-line--padding;
  padding-bottom: $w-payment__partial-line--padding;
}

.w-payment__partial-line-title {
  margin-top: $w-payment__partial-line-title--margin-top;
  margin-bottom: $w-payment__partial-line-title--margin-bottom;
  font-size: $w-payment__partial-line-title--font-size;
  font-weight: $w-payment__partial-line-title--font-weight;
}

.w-payment__partial-line-price {
  padding: $w-payment__partial-line-price--padding;
  font-size: $w-payment__partial-line-price--font-size;
  font-weight: $w-payment__partial-line-price--font-weight;
}

.w-payment__partial-input {
  width: 40%;
  min-width: $w-payment__partial-input--min-width;
  margin-left: $w-payment__partial-input--margin-left;
  @include t-mq($from: $f-breakpoint--sm) {
    width: 20%;
  }
  font-size: $w-payment__partial-line-price--font-size;
  font-weight: $w-payment__partial-input--font-weight;
}

.w-payment__partial-total {
  display: flex;
  justify-content: space-between;
  font-size: $w-payment__partial-total--font-size;
  font-weight: $w-payment__partial-total--font-weight;
  padding-top: $w-payment__partial-line--padding;
}

.w-payment__partial-total-title {
  margin-top: 0;
  margin-bottom: $w-payment__partial-total-title--margin-bottom;
}

.w-payment__partial-total-price {
  padding-right: $w-payment__partial-total-price--padding;
}

.w-payment__partial-subtotal {
  display: flex;
  justify-content: space-between;
  font-size: $w-payment__partial-subtotal--font-size;
  font-weight: $w-payment__partial-subtotal--font-weight;
  padding-bottom: $w-payment__partial-line--padding;
  margin: 0;
}

.w-payment__partial-subtotal-price {
  padding-right: $w-payment__partial-total-price--padding;
}

// Import adyen styles
@import "adyen/adyen";
