$fr-destination-exceptional-info__bp:                                                         $f-breakpoint--sm !default;

$fr-destination-exceptional-info__table-item--sp-inset:                                       $f-space--medium !default;

$fr-destination-exceptional-info__table-row-header--bg-color:                                 $f-color__state-info--semi-dark !default;
$fr-destination-exceptional-info__table-row-header--color:                                    $f-color__neutral-white !default;
$fr-destination-exceptional-info__table-row-header--font-weight:                              400 !default;
$fr-destination-exceptional-info__table-row-header--font-size:                                $f-font-size--m !default;

$fr-destination-exceptional-info__table-first-cell--font-weight:                              700 !default;

$fr-destination-exceptional-info__table-cell--color:                                          $f-color__neutral-grey--dark !default;
$fr-destination-exceptional-info__table-cell--padding-right:                                  $f-space--medium !default;

$fr-destination-exceptional-info__table-last-cell--font-size:                                 $f-font-size--s !default;
$fr-destination-exceptional-info__table-last-cell--margin-top:                                $f-space--small !default;

$fr-destination-exceptional-info__table-last-cell-before--font-size:                          $f-font-size--s !default;
$fr-destination-exceptional-info__table-last-cell-before--margin-right:                       3px !default;
$fr-destination-exceptional-info__table-last-cell-before--color:                              $f-color__state-info--semi-dark !default;
