/* -------------------------------------------------------------------------
 * BREADCRUMBS
 */

$c-breadcrumbs--text:                                                  map_get($m-body__mod-sizes, "small") !default;

$c-breadcrumbs__divider--font-family:                                  $f-font-family__primary !default;
$c-breadcrumbs__link--font-weight:                                     400 !default;
$c-breadcrumbs__link--color:                                           $f-color__neutral-grey--semi-dark !default;
$c-breadcrumbs__link--active-color:                                    $f-color__state-info !default;
$c-breadcrumbs__link__last--color:                                     $f-color__neutral-grey !default;
$c-breadcrumbs__divider--font-size:                                    $f-font-size--s !default;
$c-breadcrumbs__divider--line-height:                                  $f-font-size--xs !default;
$c-breadcrumbs__divider--sp-inset:                                     $f-space--tiny !default;

// is-unresolved
$c-breadcrumbs--unresolved-background:                                 $f-color__neutral-grey--light !default;
$c-breadcrumbs--unresolved-background--width:                          100% !default;
$c-breadcrumbs--unresolved-background--left:                           0 !default;
$c-breadcrumbs--unresolved--border-radius:                             $f-radius--medium !default;

$c-breadcrumbs__unresolved--animation-duration:                        2s !default;
$c-breadcrumbs__unresolved--animation-angle:                           220deg !default;
$c-breadcrumbs__unresolved--animation-endcolor:                        rgba($f-color__neutral-grey--ultra-light, .7) !default;
$c-breadcrumbs--unresolved--animation-z-index:                         $f-z-index--2 !default;
$c-breadcrumbs--unresolved--z-index:                                   $f-z-index--1 !default;
$c-breadcrumbs--unresolved--width:                                     5em !default;
