/* -------------------------------------------------------------------------
 * Booking preferences selector
 *
 */

.w-booking-preferences-selector__collapse {
  font-size: $w-booking-preferences-selector__collapse--font-size;
  margin-top: $w-booking-preferences-selector__collapse--margin-top;
}

.w-booking-preferences-selector
{
  .w-booking-preferences-selector__form__section {
    all: initial;
  }

  .w-booking-preferences-selector__form__section__fieldset {
    all: initial;

    .c-form__fieldset-container {
      all: initial;
      margin-left: $w-booking-preferences-selector--reset-margin;
      margin-top: $w-booking-preferences-selector--reset-margin;
      .c-choice-list{
        @include sp-stack($w-booking-preferences-selector--reset-margin);
      }
    }
  }
}

.w-booking-preferences-selector__accordion{
  .c-accordion__item{
    border-color: $w-booking-preferences-selector__accordion-item--border-color;
  }
  .c-accordion__item-title{
    font-weight: $w-booking-preferences-selector__accordion-title--font-weight;
  }
  .c-accordion__item-content{
    color: inherit;
  }
}
.c-choice-list.o-columns{
  .c-choice-list__options{
    .c-choice-list__option{
      display: inline-block;
      width: 100%;
    }
  }
}
.is-unresolved {
  .w-booking-preferences-selector__warning-messages{
    @include a-unresolved();
    &::before, &::after{
      z-index: 1;
    }
  }
}
.w-booking-preferences-selector__warning-messages{
  font-family: $w-booking-preferences-selector__warning-messages--font-family;
  @include sp-stack($w-booking-preferences-selector__warning-messages--margin-bottom)
}

.w-booking-preferences-selector__option__textbox{
  margin-left: $w-booking-preferences-selector__option__textbox--margin-left;
  margin-bottom: $w-booking-preferences-selector__option__textbox--margin-bottom;
  box-sizing: border-box;
  &.is-hidden{
    visibility: hidden;
  }
} 