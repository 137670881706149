/* -------------------------------------------------------------------------
 * BOX
 */

.c-box {
  background-color: $c-box--bg-color;
  border-radius: $c-box--border-radius;
  border: $c-box--border-width $c-box--border-style $c-box--border-color;
  box-shadow: $c-box--shadow rgba($c-box--shadow-color, $c-box--shadow-opacity);
  @include sp-inset($c-card--spacing-inset)
}

// Variants
// --------------------------------------------

@import "c-box__flat";
@import "c-box__arrow";


// Color schemes
// --------------------------------------------

@import "color-schemes";
