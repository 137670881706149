/* -------------------------------------------------------------------------
 * Voucher status
 *
 */


@import '../shared/fragments/fr-account-booking-cancelled-content/main';

.t-voucher-status {
  .fr-account-booking-cancelled-content {
    @include sp-inset-v($t-voucher-status__fr-account-booking-cancelled-content--sp-inset-v);
  }
}
