.t-destination-page {
  .l-site-main{
    .l-site-main__content>.fr-destination-page__cta-container,
    .l-site-main__content>.fr-destination-page__cta-container:last-child,
    .o-layout__item>.fr-destination-page__cta-container,
    .o-layout__item>.fr-destination-page__cta-container:last-child {
      @include t-mq($until: $fr-destination-page__description--bp) {
        margin-bottom: 0;
      }
    }
  } 
}

.fr-destination-page__cta-container {
  @include t-mq($until: $fr-destination-page__description--bp) {
    bottom: 0;
    box-shadow: $fr-destination-page__cta--shadow;
    min-height: $fr-destination-page__cta--min-height;
    left: 0;
    position: fixed;
    margin: 0;
    width: 100%;
    z-index: 2;
    @include sp-inset($fr-destination-page__cta--padding);
  }

  @include t-mq($from: $fr-destination-page__description--bp) {
    text-align: center;
    
  }
}

.o-layout__item > .fr-destination-page__cta-container {
  @include t-mq($from: $fr-destination-page__description--bp) {
    text-align: left;
  }
}


[class^="fr-"] + .fr-destination-page__cta-container {
  @include t-mq($from: $fr-destination-page__description--bp) {
    margin-top: $fr-destination-page__cta--negative-margin;
  }
}

.fr-destination-page__cta {
  @include t-mq($until: $fr-destination-page__description--bp) {
    width: 100%;

    .c-btn__icon {
      display: none;
    }
  }
}

// To make sure the chat button is not shown over the CTA
.t-destination-page .zEWidget-launcher--active {
  @include t-mq($until: $fr-destination-page__description--bp) {
    bottom: $fr-destination-page__chat-balloon-bottom !important;
    left: auto !important;
    right: 0px !important;
    margin: $fr-destination-page__chat-balloon-margin !important;
  }
}
