/* -------------------------------------------------------------------------
 * BUTTON - GROUP
 */

$c-button-group--width:                                   100% !default;
$c-button-group--flex:                                    1 !default;
$c-button-group--border-color:                            $f-color__neutral-grey--semi-light !default;
$c-button-group__text--color:                             $f-color__neutral-grey !default;

$c-button-group__btn--min-width:                          70px !default;
$c-button-group__btn--reduced-padding:                    $c-btn--spacing-inset * .5 !default;


// Hover

$c-button-group--hover--bg-color:                         $f-color__neutral-grey--ultra-light !default;

// Selected

$c-button-group--selected--bg-color:                      $f-color__neutral-white !default;
$c-button-group--selected--shadow:                        none !default;
$c-button-group--selected--border-color:                  $f-color__brand-primary !default;
$c-button-group__text--selected--color:                   $f-color__brand-primary !default;

$c-button-group__c-btn--flat__border-bottom--width:       2px;

// Variant Chips
$c-button-group--chips--gap:                              $f-space--small !default;