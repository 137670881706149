/* -------------------------------------------------------------------------
 * FAQ
 *
 */

.t-faq {
  .l-site-main__content {
    > [class^="fr-"] {
      @include sp-stack($t-faq__children--sp-stack);

      @include t-mq($from: $t-faq__main--bp) {
        @include sp-stack($t-faq__children--sp-stack--from-bp);
      }

      &:last-child {
        @include sp-stack($t-faq__children-last--sp-stack);

        @include t-mq($from: $t-faq__main--bp) {
          @include sp-stack($t-faq__children-last--sp-stack--from-bp);
        }
      }
    }
  }
}

// Main top
// ------------------

.fr-faq__main-top--content {
  padding: $fr-faq__main-top--content--padding;
}

.t-faq__search-container {
  margin: auto;
}

.t-faq__background-img--withdrawn {
  height: $t-faq__background-img--withdrawn--height;

  .fr-faq__main-top--content {
    padding: $fr-faq__main-top__background-img--withdrawn--content--padding;
  }
}

.t-faq__main-top--title {
  @include t-shared-header-img__title();

  margin-bottom: $t-faq__main-top--title--margin-bottom;
}

// Main styles
// ------------------

@import 'fr-faq-categories-grid-item';
@import 'fr-faq-contact-cards';
@import 'fr-faq-top-five';

.t-faq__search-results-title {
  color: $t-faq__search-results-title--color;
}

.t-faq__pagination {
  text-align: center;
}

.t-faq__question-answer * {
  // Override styles set using Rich text editor (Kana).
  background-color: transparent !important;
}

.t-faq__search-results,
.t-faq__subcategory {
  margin-left: -1 * $t-faq__actionable-list--margin;
  margin-right: -1 * $t-faq__actionable-list--margin;
}

// Borders + Horizontal space alignment
// with actionable list
// ------------------
.t-faq__actionable-list::before,
.t-faq__actionable-list::after,
.t-faq__search-results-no-results::before {
  display: block;
  content: ' ';
  height: $t-faq__actionable-list--border-width;
  background-color: $t-faq__actionable-list--border-color;
}

.t-faq__search-results-no-results {
  position: relative;
  padding-top: $t-faq__question-answer__sp-inset-top;
}

.t-faq__search-results-no-results::before {
  position: absolute;
  top: 0;
  left: $t-faq__actionable-list--margin;
  right: $t-faq__actionable-list--margin;
}

.t-faq__actionable-list-title,
.t-faq__search-results-no-results {
  @include sp-inset-h($t-faq__actionable-list-title--sp-inset-h);
}

.t-faq__question-answer {
  border-top: $fr-faq-categories__grid-item__list-item--border-width $fr-faq-categories__grid-item__list-item--border-style $fr-faq-categories__grid-item__list-item--border-color;
  padding-top: $t-faq__question-answer__sp-inset-top;
  @include sp-stack($t-faq__question-answer--sp-stack);
}
