/* -------------------------------------------------------------------------
* WELCOME USER
*
*/

.w-welcome-user {
  display: flex;
  align-items: center;
  padding-top: $w-welcome-user--padding-top;

  @include t-mq($from: $w-welcome-user--bp) {
    padding-top: $w-welcome-user--padding-top-from-bp;
  }
}

.w-welcome-user__text.is-unresolved,
.w-welcome-user__avatar.is-unresolved {
    @include a-unresolved();
    &::before, &::after{
      z-index: 1;
    }
}

.w-welcome-user__text {
  margin-bottom: $w-welcome-user__text--margin-bottom;
  @include m-heading(map_get($m-heading__mod-sizes, "medium"));
  @include t-text-crop-disable();

  @include t-mq($from: 'xs') {
    @include m-heading($w-welcome-user__text--heading);
  }
}

// Avatar styles

.w-welcome-user__avatar {
  @include m-heading(map_get($m-heading__mod-sizes, "medium"));
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  position: relative;
  width: $w-welcome-user__avatar-container--size;
  height: $w-welcome-user__avatar-container--size;
  background-color: $w-welcome-user__avatar-container--bg-color;
  margin-right: $w-welcome-user__avatar-container--margin-right;
  color: $w-welcome-user__avatar--color;
  border-radius: 50%;
  flex-shrink: 0;
}
