/* -------------------------------------------------------------------------
* Items Grid
*
*/

.c-items-grid__grid {
  @include sp-stack($c-items-grid--main-spacing);
  @include t-mq($until: $c-items-grid--breakpoint-tablet) {
    @include sp-stack-children($c-items-grid--main-spacing);
  }

  display: flex;
  flex-wrap: wrap;
  margin-left: -.5 * $c-items-grid__item-sp-inline;
  margin-right: -.5 * $c-items-grid__item-sp-inline;
  justify-content: flex-start;

  > * {
    @include sp-stack($c-items-grid__item-sp-stack);
    flex-grow: 0;
    margin-left: .5 * $c-items-grid__item-sp-inline;
    margin-right: .5 * $c-items-grid__item-sp-inline;
    width: 100%;
    
    @include t-mq($from: $c-items-grid--breakpoint-tablet) {
      width: calc(50% - #{$c-items-grid__item-sp-inline});
    }

    @include t-mq($from: $c-items-grid--breakpoint-desktop) {
      width: calc(33% - #{$c-items-grid__item-sp-inline});
    }
  }

  @supports (display: grid) {
    @include t-mq($from: $c-items-grid--breakpoint-tablet) {
      display: grid;
      grid-column-gap: $c-items-grid--main-spacing;
      grid-row-gap: $c-items-grid--main-spacing;
      grid-template-columns: repeat(auto-fill, minmax($c-items-grid__item--tablet-min-width, 1fr));
    }

    @include t-mq($from: $c-items-grid--breakpoint-desktop) {
      grid-template-columns: repeat(auto-fill, minmax($c-items-grid__item--desktop-min-width, 1fr));
    }

    margin-left: 0;
    margin-right: 0;

    > * {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
      min-width: initial;
      max-width: initial;

      @include t-mq($from: $c-items-grid--breakpoint-tablet) {
        width: 100%;
      }
  
      @include t-mq($from: $c-items-grid--breakpoint-desktop) {
        width: 100%;
      }
    }
  }
}

.c-items-grid__collapse-triggers {
  position: relative;
  width: 100%;
  text-align: center;
}

.c-items-grid__collapse-btn {
  &[data-c-collapse__action="open"][data-c-collapse__opened="true"],
  &[data-c-collapse__action="close"]:not([data-c-collapse__opened="true"]),
  &.is-hidden {
    display: none;
  }

  &[data-c-collapse__action="open"]::before {
    content: "";
    position: absolute;
    width: 100%;
    height: $c-items-grid__gradient--height;
    top: -($c-items-grid__gradient--height + $c-items-grid__gradient--position-fix);
    left: 0;
    background-image: linear-gradient(to top, $c-items-grid__gradient--initial-color $c-items-grid__gradient--initial-change, $c-items-grid__gradient--final-color $c-items-grid__gradient--final-change);
    z-index: $c-items-grid__gradient--z-index;
    opacity: $c-items-grid__gradient--opacity;
  }
}
