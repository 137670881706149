/* -------------------------------------------------------------------------
 * WRAPPER MIXINS
 *
 */


// Object selector output
// --------------------------------------------------

@mixin o-wrapper() {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @include t-clearfix;
}

// Size modifiers (from)
// --------------------------------------------------

@mixin o-wrapper--from($_bp-name: "xs") {
  @each $_s-bp-name, $_s-bp-size in $o-wrapper__mod-bp {

    @if ($_s-bp-size >= map-get($o-wrapper__mod-bp, $_bp-name)) {
      @include t-mq($from: $_s-bp-name) {
        max-width: s-core-px-to-rem(map-get($o-wrapper__mod-bp, $_s-bp-name));
      }
    }
  }
}


// Size modifiers (to)
// --------------------------------------------------

@mixin o-wrapper--to($_bp-name: "xs") {
  @each $_s-bp-name, $_s-bp-size in $o-wrapper__mod-bp {

    @if ($_s-bp-size >= map-get($o-wrapper__mod-bp, $_bp-name)) {
      @include t-mq($from: $_s-bp-name) {
        max-width: s-core-px-to-rem(map-get($o-wrapper__mod-bp, $_bp-name));
      }
    }
  }
}
