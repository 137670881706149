/* -------------------------------------------------------------------------
 * Account permissions
 *
 */

 .t-account-permissions {
  .l-site-main__content {
    padding-top: $t-account-permissions__l-site-main__content--no-top--padding-top;
  }
}
