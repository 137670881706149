/* -------------------------------------------------------------------------
 * PAYMENT VARS
 *
 */

// Payment methods

$w-payment-methods--border-width:                                     1px !default;
$w-payment-methods--spacing:                                          $f-space--large !default;
$w-payment-methods--mt:                                               $f-space--large !default;
$w-payment-methods--extra-border--border-radius:                      $f-radius--medium !default;
$w-payment-methods__extratext-color:                                  $f-color__neutral-grey !default;
$w-payment-methods__extratext-pd:                                     $f-space--small !default;
$w-payment-methods__grid-gap:                                         $f-space--small !default;
$w-payment-methods__margin-bottom:                                    $f-space--tiny !default;
$w-payment-methods__margin-right:                                     $f-space--tiny !default;
$w-payment-methods__brand--margin-left:                               $f-space--tiny !default;
$w-payment-methods__brand--max-width:                                 30px !default;
// Payment options

$w-payment-methods__options--bg-color:                                $f-color__state-info--ultra-light !default;
$w-payment-methods__options--border-color:                            $f-color__state-info !default;
$w-payment-methods__options--spacing:                                 $f-space--small !default;
$w-payment-methods__options--spacing-top-bottom:                      $f-space--small !default;
$w-payment-methods__options--spacing-left-right:                      $f-space--small !default;
$w-payment-methods__options-content--bgcolor:                         $f-color__neutral-white !default;
$w-payment-methods__options-content--br:                              $f-radius--medium !default;
$w-payment-methods__options-extratext-price-color:                    $f-color__neutral-grey--dark !default;
$w-payment-methods__options-extratext-price-color--selected:          $f-color__state-info !default;
$w-payment-methods__options-extratext-price-fw:                       700 !default;
$w-payment-methods__options-extratext-price-pd:                       $f-space--small !default;
$w-payment-methods__options-extratext-price-size:                     $f-font-size--m !default;
$w-payment-methods__options-title--margin-bottom:                     $f-space--small !default;

$w-payment-methods__options-choice-list--margin-bottom:               $f-space--small !default;

// Payment Messages
$w-payment__title--sp-stack:                                          $f-space--small !default;
$w-payment__message--sp-stack:                                        0 !default;
$w-payment__message-line--color:                                      $f-color__neutral-grey--semi-light !default;
$w-payment__message--font-size:                                       $f-font-size--s !default;
$w-payment__breakpoint--sm:                                           $f-breakpoint--sm !default;
$w-payment__message-only--sp-stack:                                   $f-space--medium !default;

//Voucher
$w-payment__voucher-option--margin-bottom:                            $f-space--small !default;
$w-payment__voucher-selection-wrapper--margin-top:                    $f-space--small !default;
$w-payment__voucher-selection-description--margin-top:                $f-space--small !default;
$w-payment__voucher-selection-description--margin-bottom:             $f-space--small !default;
$w-payment__voucher-selection-description--font-size:                 $f-font-size--s !default;

//voucher options
$w-payment__voucher-options-expired-text--font-size:                  $f-font-size--l !default;
$w-payment__voucher-options-expired-text--margin-top:                 $f-space--small !default;
$w-payment__voucher-options-booking-amount-wrapper--margin-bottom:    $f-space--medium !default;
$w-payment__voucher-options-booking-amount--font-weight:              700 !default;
$w-payment__voucher-options-partial-redeemer--margin-top:             $f-space--small !default;
$w-payment__voucher-options-partial-redeemer--margin-right:           $f-space--tiny !default;
$w-payment__voucher-options-partial-redeemer--margin-bottom:          -$f-space--small !default;
$w-payment__voucher-options-input-label--margin-right:                $f-space--small !default;
$w-payment__voucher-options-input-label--min-width:                   40% !default;
$w-payment__voucher-options-input--width:                             40% !default;
$w-payment__voucher-options-input--width--from-bp:                    30% !default;
$w-payment__voucher-options-currency--margin-left:                    $f-space--small !default;
$w-payment__voucher-options-currency--font-weight:                    700 !default;
$w-payment__voucher-options-currency--font-size:                      $f-font-size--m !default;
$w-payment__voucher-options-error-message--color:                     $f-color__state-danger !default;
$w-payment__voucher-options-error-message--font-size:                 $f-font-size--s !default;
$w-payment__voucher-options-error-message--margin-top:                $f-space--small !default;
$w-payment__voucher-options-error-message--margin-right:              $f-space--medium !default;
$w-payment__voucher-options-buttons--margin-top:                      $f-space--medium !default;
$w-payment__voucher-options-buttons--children-margin-left:            $f-space--medium !default;
$w-payment__voucher-options-expired-button--margin-top:               $f-space--medium !default;
$w-payment__voucher-options-info--margin-top:                         $f-space--medium !default;
$w-payment__voucher-options-info--font-size:                          $f-font-size--xs !default;
$w-payment__voucher-options-info--color:                              $f-color__neutral-grey--semi-dark !default;
$w-payment__voucher-widget-isolated--margin-bottom:                   $f-space--medium !default;
$w-payment__selected-title--margin-bottom:                            $f-space--small !default;

//Voucher selector
$w-payment__voucher-selector-vouchers-wrapper--margin:                -$f-space--tiny !default;
$w-payment__voucher-widget--margin:                                   $f-space--tiny !default;
$w-payment__voucher-selector-add-link--margin-top:                    $f-space--medium !default;
$w-payment__bonus-wrapper--margin-bottom:                             $f-space--medium !default;
$w-payment__bonus-icon--font-size:                                    map_get($m-icons__sizes, "medium") !default;
$w-payment__bonus-icon--sp-inline:                                    $f-space--small !default;
$w-payment__bonus-icon--margin-bottom:                                $f-space--small !default;
$w-payment__bonus-message--font-size:                                 $f-font-size--s !default;


//Voucher redeemer
$w-payment__voucher-redeemer-widget--margin-top:                      $f-space--small !default;
$w-payment__voucher-redeemer-widget__space-stack-children:            $f-space--small !default;


$w-payment__message-content--sp-inset:                                $f-space--medium !default;
$w-payment__message-image--rotate:                                    320deg !default;
$w-payment__message-image--translate-from:                            75% !default;
$w-payment__message-image--translate-to:                              15% !default;

// STATUS Animation
$w-payment__message-image-alert__easing:                              $f-easing--out !default;
$w-payment__message-image-alert__duration:                            1s !default;
$w-payment__message-image-alert__delay:                               1s !default;

$w-payment__message-image-alert__confeti-colors:                      (#d13447, #ffbf00, #263672) !default;

// Partial
$w-payment__partial--padding:                                         $f-space--medium !default;
$w-payment__partial--extra-border--border-radius:                     $f-radius--small !default;
$w-payment__partial--border-color:                                    $f-color__state-info !default;

$w-payment__partial-line--padding:                                     $f-space--tiny !default;

$w-payment__partial-wrapper--padding-sm:                              $f-space--medium !default;
$w-payment__partial-wrapper--padding:                                 $f-space--medium !default;

$w-payment__partial-line-title--margin-top:                           $f-space--small !default;
$w-payment__partial-line-title--margin-bottom:                        $f-space--small !default;
$w-payment__partial-line-title--font-size:                            $f-font-size--m !default;
$w-payment__partial-line-title--font-weight:                          500 !default;

$w-payment__partial-line-price--padding:                              $f-space--tiny !default;
$w-payment__partial-line-price--font-size:                            $f-font-size--m !default;
$w-payment__partial-line-price--font-weight:                          600 !default;

$w-payment__partial-input--font-weight:                               500 !default;
$w-payment__partial-input--min-width:                                 80px !default;
$w-payment__partial-input--margin-left:                               $f-space--small !default;

$w-payment__partial-total--font-size:                                 $f-font-size--l !default;
$w-payment__partial-total--font-weight:                               600 !default;
$w-payment__partial-total-title--margin-bottom:                       $f-space--tiny !default;
$w-payment__partial-total-price--padding:                             $f-space--tiny !default;

$w-payment__partial-subtotal--font-size:                              $f-font-size--s !default;
$w-payment__partial-subtotal--font-weight:                            500 !default;
