/* -------------------------------------------------------------------------
 * SLIDER VARS
 */

// Slider arrows

$c-slider__btn-arrow--bg-color:                         $f-color__neutral-white !default;
$c-slider__btn-arrow--color:                            $f-color__brand-primary--dark !default;
$c-slider__nav-item--bg-color:                          $f-color__neutral-white !default;
$c-slider__nav-item--bg-color--opacity:                 .3 !default;
$c-slider__nav-item--border:                            1px solid $f-color__neutral-white !default;

$c-slider__nav--bg-color--outer-navigation:             $f-color__state-info--semi-light !default;
$c-slider__nav--bg-color--active--outer-navigation:     $f-color__state-info--semi-dark !default;

