/* -------------------------------------------------------------------------
 * CROP MIXINS
 *
 */


// Object as a mixin
// --------------------------------------------------

@mixin o-crop() {
  position: relative;
  display: block;
  overflow: hidden;
}

@mixin o-crop__content() {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
}


// Unset as mixin
// --------------------------------------------------

@mixin o-crop--unset() {
  position: inherit;
  display: inherit;
  overflow: inherit;
}

@mixin o-crop__content--unset() {
  position: inherit;
  top: auto;
  left: auto;
  right: auto;
  max-width: inherit;
  transform: none;
}
