/* -------------------------------------------------------------------------
* TOOLTIP
*
*/
.c-tooltip.is-ie11 {
  &::before,
  &::after {
    display: none;
  }

  &::before {
    top: 50%;
    transform: translateY(-50%);
    left: $c-tooltip__icon--ie11-before--left;
    bottom: auto !important;
    width: 100%;
    min-width: $c-tooltip__icon--ie11-before--min-width;
  }

  &:hover::before,
  &:hover::after {
    display: block;
  }
}

.c-tooltip {
  cursor: pointer;
  position: relative;
  display: inline-block;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  &:before,
  &:after  {
    position: absolute;
    transition-property: transform;
    transition-timing-function: linear;
    z-index: $c-tooltip--z-index;
  }

  &:before {
    @include sp-inset-squish($c-tooltip--inset);
    content: attr(data-c-tooltip__text);
    max-width: $c-tooltip--max-width;
    color: $c-tooltip--color;
    background-color: $c-tooltip--bg-color;
    border-radius: $c-tooltip--border-radius;
    font-size: $c-tooltip--font-size;
    transition-duration: $c-tooltip--transition-duration;
    height: max-content;
    width: max-content;
    height: auto;
    line-height: $c-tooltip--line-height;

    @include t-mq($until: $c-tooltip--first-breakpoint) {
      max-width: $c-tooltip--max-width--until-first-bp;
    }
  }

  &:after {
    content: "";
    transition-duration: $c-tooltip__arrow--transition-duration;
    border-width: $c-tooltip__arrow--width;
    border-style: solid;
    transition-delay: $c-tooltip__arrow--transition-delay;
  }

  &.in:before {
    transition-delay: $c-tooltip--transition-delay;
  }

  &.in:after,
  &:before {
    transition-delay: 0s;
  }

  &.in:before,
  &.in:after {
    display: block;
  }

  .c-tooltip__icon {
    color: $c-tooltip__icon--color;
    transition-property: color;
    transition-duration: $c-tooltip__icon--transition-duration;

    &:hover {
      color: $c-tooltip__icon--color-hover;
    }
  }
}

@mixin c-tooltip($_direction, $_direction-values) {
  &:before,
  &:after {
    left: unset;
    right: unset;
    top: unset;
    bottom: unset;
    #{map-get($_direction-values, "horizontal-position-property")}: map-get($_direction-values, "horizontal-position-value");
    #{map-get($_direction-values, "vertical-position-property")}: map-get($_direction-values, "vertical-position-value");
    transform-origin: map-get($_direction-values, "transform-origin");
  }

  &:before {
    --tooltip-arrow-offset: #{$c-tooltip__arrow--offset};
    transform: map-get($_direction-values, "translate-before") scale(0) translateZ(0);
  }

  &:after {
    transform: map-get($_direction-values, "translate-after") scale(0) translateZ(0);
    border-color: map-get($_direction-values, "border-color");
  }

  &.in:before {
    transform: map-get($_direction-values, "translate-before") scale(1) translateZ(0);
  }

  &.in:after {
    transform: map-get($_direction-values, "translate-after") scale(1) translateZ(0);
  }
}

// Enable tooltip direction until certain breakpoints
// ---------------------------------------------------------

@each $_direction, $_direction-values in $c-tooltip--directions {
  .c-tooltip--#{$_direction} {
    @include c-tooltip($_direction, $_direction-values);
  }
  @each $_breakpoint in $c-tooltip--direction-breakpoints {
    @include t-mq($from: $_breakpoint) {
      .c-tooltip--#{$_direction}-from#{s-core-string-breakpoint($_breakpoint)} {
        @include c-tooltip($_direction, $_direction-values);
      }
    }
  }
}
