.fr-account-booking-acco-info{

  .fr-account-booking-acco-info__content-details {
    font-size: $fr-account-booking-acco-info__content-details--font-size;
    line-height: $fr-account-booking-acco-info__content-details--line-height;

    .fr-account-booking-acco-info__content-details-text {
      margin-bottom: $fr-account-booking-acco-info__content-details-text--margin-bottom;
    }

    .c-bullet-list {
      font-size: $f-font-size--xs;
      margin-bottom: $fr-account-booking-acco-info__content-details-usps--margin-bottom;
    }

    .o-bullet__text {
      padding-left: $fr-account-booking-acco-info__content-details-usps--padding-left;
    }

    .o-bullet__icon {
      font-size: $fr-account-booking-acco-info__content-details-usps-icon--font-size;
      padding-top: $fr-account-booking-acco-info__content-details-usps-icon--padding-top;
    }
  }
}
