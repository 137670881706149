/* -------------------------------------------------------------------------
 * PACK MIXINS
 *
 */

// Object as a mixin
// --------------------------------------------------

@mixin o-pack($_space-value: $o-pack__space) {
  width: 100%;
  margin-left: 0;
  display: table;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: s-core-px-to-rem($_space-value);
}

@mixin o-pack__item() {
  display: table-cell;
  vertical-align: top;
}

// Space modifiers
// --------------------------------------------------

@mixin o-pack--space($_space-name: "none", $_space-value: 0) {
  .o-pack--space-#{$_space-name} {
    border-spacing: s-core-px-to-rem($_space-value);
  }
}

// Alignment modifiers
// --------------------------------------------------

@mixin o-pack__mod-alignment($_vertical-alignment: top) {
  vertical-align: $_vertical-alignment;
}

// Reverse modifier
// --------------------------------------------------

@mixin o-pack--reverse() {
  direction: rtl;
}

@mixin o-pack__item--reverse() {
  direction: ltr;
}

// Auto-Width modifier
// --------------------------------------------------

@mixin o-pack--auto() {
  table-layout: auto;
}

@mixin o-pack--auto__item--wide() {
  width: 100%;
}

// Item reverse modifiers
// --------------------------------------------------

@mixin o-pack__item-mod-reverse() {
  direction: rtl;
  text-align: right;

  > * {
    direction: ltr;
    text-align: right;
  }
}

// Unset as a mixin
// --------------------------------------------------

@mixin o-pack--unset() {
  width: auto;
  margin-left: inherit;
  display: inherit;
  table-layout: inherit;
  border-collapse: inherit;
  border-spacing: inherit;
}

@mixin o-pack__item--unset() {
  display: inherit;
  vertical-align: inherit;
}

@mixin o-pack__item-reverse--unset() {
  direction: inherit;
  text-align: inherit;
  > * {
    direction: inherit;
    text-align: inherit;
  }
}
