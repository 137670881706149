/* -------------------------------------------------------------------------
 * PHOTO GALLERY VARS
 *
 */


$c-photo-gallery__background:                               $f-color__neutral-white--transparent !default;
$c-photo-gallery__content-background:                       $f-color__neutral-white !default;

$c-photo-gallery__breakpoint--mobile:                       $f-breakpoint--xs !default;
$c-photo-gallery__breakpoint--tablet:                       $f-breakpoint--sm !default;
$c-photo-gallery__breakpoint--desktop:                      $f-breakpoint--lg !default;

$c-photo-gallery__items-per-row--default:                   3 !default;
$c-photo-gallery__items-per-row--tablet:                    6 !default;
$c-photo-gallery__items-per-row--desktop:                   8 !default;

$c-photo-gallery__grid--gutter:                             $f-space--tiny !default;

$c-photo-gallery--transform-time-in:                        $f-duration--fast !default;
$c-photo-gallery--transform-time-out:                       $f-duration--normal !default;
$c-photo-gallery--hover--shadow:                            $f-shadow--elevated !default;
$c-photo-gallery--hover--shadow--color:                     $f-color__neutral-grey--ultra-dark !default;
$c-photo-gallery--hover--shadow--opacity:                   .5 !default;

$c-photo-gallery__description-size--mobile:                 $f-font-size--s !default;
$c-photo-gallery__description-size--tablet:                 $f-font-size--s !default;
$c-photo-gallery__description-sp-inset:                     $f-space--medium !default;
$c-photo-gallery__description-sp-inset-v:                   ($f-space--medium - $f-space--tiny) !default;
$c-photo-gallery__description-color:                        $f-color__neutral-white !default;

$c-photo-gallery__close-offset:                             $f-space--large !default;

$c-photo-gallery__header-vertical-padding:                  $f-space--tiny + $f-space--small !default;
$c-photo-gallery__header-horizontal-padding:                $f-space--large !default;

$c-photo-gallery__play-button-icon:                         45px!default;

$c-photo-gallery__transform-time-out:                       .3s !default;
$c-photo-gallery__thumbnail--info-icon-content-color:       $f-color__brand-secondary--semi-dark !default;
$c-photo-gallery__thumbnail--info-icon-color:               $f-color__neutral-white !default;
$c-photo-gallery__thumbnail--info-icon-content-color-hover: $f-color__brand-secondary--dark !default;
$c-photo-gallery__thumbnail--info-icon-bg-color-hover:      $f-color__neutral-grey--light !default;
