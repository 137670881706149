/* -------------------------------------------------------------------------
 * SITE HEADER
 */

$l-site-header--bp:                                                             "sml" !default;

$l-site-header--shadow:                                                         $f-shadow--elevated !default;
$l-site-header--shadow-color:                                                   $f-shadow--color !default;
$l-site-header--shadow-opacity:                                                 $f-shadow--opacity !default;

$l-site-header__top--sp-inset-v:                                                $f-space--tiny !default;
$l-site-header__top--sp-inline:                                                 $f-space--medium !default;
$l-site-header__top--visible-from:                                              'sm' !default;
$l-site-header__text-search-bp:                                                 'lg' !default;

$l-site-header__top-left--font-size:                                            $f-font-size--s !default;

$l-site-header__main--sp-inset-v:                                               $f-space--tiny !default;
$l-site-header__main--sp-inset-v--from-bp:                                      $f-space--small !default;
$l-site-header__main--offset--from-bp:                                          $f-space--tiny !default;
$l-site-header__main--sp-inline:                                                $f-space--large !default;

$l-site-header__logo-img--props: (
  "xxs": (
    "height":                                                                   auto,
    "width":                                                                    120px,
  ),
  "sm": (
    "height":                                                                   auto,
    "width":                                                                    140px,
  ),
) !default;


$fr-header-menu-top__nav-swipe-btn--margin-top:                                 .85em !default;

$fr-header-menu-top__nav-item--font-size:                                       $f-font-size--s !default;
$fr-header-menu-top__nav-item--font-weight:                                     400 !default;
$fr-header-menu-top__nav-item---link-color:                                     $f-color__neutral-grey--semi-dark !default;
$fr-header-menu-top__nav-item--background-color:                                transparent !default;
$fr-header-menu-top__nav-item--margin-left:                                     $c-nav__item--inline__sp-inline !default;
$fr-header-menu-top__favorite-collector--font-size:                             $f-font-size--xs !default;
$fr-header-menu-top__nav-list--z-index:                                         map_get($f-z-indexes--usage, "c-nav--dropdown__list") !default;

$fr-header-menu-main__nav-list--z-index:                                        $fr-header-menu-top__nav-list--z-index - 1 !default;

$l-site-header__main-icons--sp-inline:                                          $f-space--small !default;

$l-site-header__icons--color:                                                   $cs-default--text !default;

$fr-header-menu-side-drawer__menu-icon--padding-bottom:                         6px !default;
$fr-header-menu-side-drawer__menu-icon--font-size:                              9px !default;
$fr-header-menu-side-drawer__menu-icon--font-family:                            $f-font-family__secondary !default;
$fr-header-menu-side-drawer__menu-icon--margin-top:                             $fr-header-menu-side-drawer__menu-icon--padding-bottom / 2 !default;
$fr-header-menu-side-drawer__menu-icon--vertical-align:                         null !default;

$fr-header-free-text-search--small-bp:                                          "lg" !default;
$fr-header-free-text-search--smaller-bp:                                        "sm" !default;
$fr-header-free-text-search--width-first-bp:                                    250px !default;
$fr-header-free-text-search--width-second-bp:                                   300px !default;

$fr-header-free-text-search__floating-box--min-width:                           300px !default;
$fr-header-free-text-search__floating-box--z-index:                             $f-z-index--5 !default;

$fr-header-free-text-search--element-sp-inset:                                  $f-space--small !default;
$fr-header-free-text-search--element-sp-inset-squish:                           true !default;

$l-site-header__mobile-icons--font-size:                                        $f-font-size--xl !default;


$l-site-header__top-live-search--gutter:                                        $f-space--medium !default;
$l-site-header__top-live-search--nav-v-adjustment:                              4px !default;
