/* -------------------------------------------------------------------------
 * TABS FILTER
 *
 */

.w-filter--tabs {
  .c-tabs__nav-button {
    min-width: 0;
    padding-left: $c-tabs__nav-button--sp-inset--secondary * 2;
    padding-right: $c-tabs__nav-button--sp-inset--secondary * 2;
    font-size: inherit;
  }
}



// States
// -------------------------------------------------

// Disabled
.w-filter--tabs.is-disabled,
.is-disabled .w-filter--tabs {
  .c-tabs__nav-item {
    opacity: $w-tabs-filter__disabled--opacity;
    pointer-events: none;
  }
}
