/* -------------------------------------------------------------------------
 * NUMBER SCORE
 */

$c-number-score--font-family:               $f-font-family__primary !default;
$c-number-score--font-size:                 $f-font-size--m !default;
$c-number-score--font-weight:               700 !default;
$c-number-score--line-height:               $f-line-height--tiny !default;
$c-number-score--color:                     $f-color__neutral-white !default;

$c-number-score__label--font-size:          $f-font-size--xs !default;
$c-number-score__label--font-weight:        normal !default;

$c-number-score__bubble--color:             $f-color__state-info--semi-dark !default;
$c-number-score__bubble--color-soft:        $f-color__state-info !default;
$c-number-score__bubble-tail--bottom:       -10px !default;
$c-number-score__bubble-tail--left:         35% !default;
$c-number-score__bubble--min-width:         39px !default;
$c-number-score__bubble--padding:           round($f-space / 3) !default;
$c-number-score__bubble--border-radius:     $f-radius--medium !default;

$c-number-score__label--spacing:            $f-space--tiny !default;
$c-number-score__label-separator--spacing:  2px !default;

$c-number-score__arrow--height:             10px !default;
$c-number-score__arrow--width:              15px !default;

$c-number-score__separator--spacing-label-rating: $f-space--small !default;
$c-number-score__label--padding-bottom:     $f-space--tiny !default;

$c-number-score__button-info--color--hover: $f-color__state-info !default;
$c-number-score__button-info--color:        $f-color__state-info--dark !default;
$c-number-score__button-info--size:         $f-font-size--l !default;
$c-number-score__button-info--font-size:    16px !default;


// Variants
// ------------------------------

// Small Basis

$c-number-score--font-size--small-basis:    $f-font-size--s !default;


// Right arrow

$c-number-score--font-size--right-arrow:    $f-font-size--s !default;


// Squared

$c-number-score--squared-title-font-size:   15px !default;
$c-number-score--squared-label-font-size:   $f-font-size--xs !default;
$c-number-score--squared-padding:           8px !default;
$c-number-score--squared-border-radius:     4px !default;
$c-number-score--squared-color:             $c-number-score__bubble--color-soft !default;
$c-number-score--squared-min-width:         37px !default;