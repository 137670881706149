
/* -------------------------------------------------------------------------
 * FONTS
 *
 * Font families & fallback
 *
 */

// Web fonts variable. Used to preload load them
$web-fonts: ();


// Font imports

@import "foundations__font-families--librefranklin";
@import "foundations__font-families--outfit";


// Main font families

$f-font-family__primary:          $f-font-family__librefranklin, $f-font-family__librefranklin--fallback !default;
$f-font-family__secondary:        $f-font-family__outfit, $f-font-family__outfit--fallback !default;

$f-font-family__primary--crop:    $f-font-family__librefranklin--crop !default;
$f-font-family__secondary--crop:  $f-font-family__outfit--crop !default;


// FONT FAMILIES MAP

$f-font-families: (
  "primary":    $f-font-family__primary,
  "secondary":  $f-font-family__secondary,
) !default;

$f-font-families--crops: (
  "primary":    $f-font-family__primary--crop,
  "secondary":  $f-font-family__secondary--crop,
) !default;
