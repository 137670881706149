/* -------------------------------------------------------------------------
 * BOOKING STEPS VARS
 *
 */

$w-booking-steps__main-top--sp-stack:                           $f-space--medium !default;

$w-booking-steps__title--main-right--color:                     $f-color__neutral-grey--semi-dark !default;
$w-booking-steps__title--main-right--text-transform:            uppercase !default;
$w-booking-steps__title--main-right--sp-stack:                  $f-space--medium !default;

$w-booking-steps__contact-icon--font-size:                      map_get($m-icons__sizes, "medium") !default;
$w-booking-steps__contact-phone--font-weight:                   700 !default;
$w-booking-steps__contact-phone--sp-stack:                      $f-space--small !default;
