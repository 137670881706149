
/* -------------------------------------------------------------------------
 * THEME NEWEST DISCOVERIES Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-newest-discoveries--enabled {
  .cs-theme-newest-discoveries {
    @include color-scheme($cs-theme-newest-discoveries)
  }
}
