$w-account-special-luggage__modal-footer-gap:                               8px !default;

$w-account-special-luggage__measurement-title__margin-bottom:               4px !default;

$w-account-special-luggage__measurement-subtitle__margin-bottom:            $f-space--small !default;

$w-account-special-luggage__row__margin:                                    16px !default;
$w-account-special-luggage__row__margin--sm:                                8px !default;
$w-account-special-luggage__input-row__gap:                                 8px !default;
$w-account-special-luggage__input-row__gap--small-screen:                   16px !default;

$w-account-special-luggage__terms-and-conditions__margin-top:               8px !default;
