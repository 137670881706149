/* -------------------------------------------------------------------------
 * VERIFY ACCOUNT
 *
 */

.w-verify-account {
  text-align: center;
  display: flex;
  flex-direction: column;

  > .is-hidden {
    display: none;
  }

  .w-verify-account__icon,
  .w-verify-account__title {
    @include sp-stack($w-verify-account__error-message--sp-stack);
  }

  .w-verify-account__body {
    margin-bottom: $f-space--medium;
  }

  .w-verify-account__icon {
    color: $w-verify-account__icon--color;
  }

  .w-verify-account__loader {
    align-self: center;
  }
}
