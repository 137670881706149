/* -------------------------------------------------------------------------
 * BULLET
 *
 * The bullet pattern is used to align an icon with some text,
 * similar to how a bullet point has the icon and the text
 *
 */

// Object selector output
// --------------------------------------------------

@if ($o-bullet--enabled) {
  .o-bullet {
    @include o-bullet();
  }

  .o-bullet__icon {
    @include o-bullet__icon();
  }

  .o-bullet__text {
    @include o-bullet__text();
  }
}

// Block modifier
// --------------------------------------------------

@if ($o-bullet--enabled and $o-bullet__mod-block--enabled) {
  @include o-bullet__mod-block();
}


// Middle modifier
// --------------------------------------------------

@if ($o-bullet--enabled and $o-bullet__mod-middle--enabled) {
  @include o-bullet__mod-middle();
}

// Reverse modifiers
// --------------------------------------------------

@if ($o-bullet--enabled and $o-bullet__mod-reverse--enabled) {
  .o-bullet--reverse {
    @include o-bullet__mod-reverse();

    > .o-bullet__text {
      @include o-bullet-text__mod-reverse();
    }

    &.o-bullet--block {
      > .o-bullet__text {
        @include o-bullet-text__mod-reverse($_space-multiplier: $o-bullet__icon--padding-multiplier)
      }
    }
  }
}

// Icon Size modifiers
// --------------------------------------------------

@if ($o-bullet--enabled and $o-bullet__mod-iconsize--enabled) {
  @each $_iconsize-name, $_iconsize-value in $o-bullet__mod-iconsizes {
    @include o-bullet--iconsize($_iconsize-name, $_iconsize-value);
  }
}
