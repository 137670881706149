
/* -------------------------------------------------------------------------
 * HEADINGS
 *
 * Define some minimal heading styles
 *
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: $e-headings__line-height;
  margin-bottom: $e-headings__margin-bottom;
  font-weight: $e-headings__font-weight;
}


h1 {
  font-size: s-core-px-to-rem($e-headings__font-size--h1);
}

h2 {
  font-size: s-core-px-to-rem($e-headings__font-size--h2);
}

h3 {
  font-size: s-core-px-to-rem($e-headings__font-size--h3);
}

h4 {
  font-size: s-core-px-to-rem($e-headings__font-size--h4);
}

h5 {
  font-size: s-core-px-to-rem($e-headings__font-size--h5);
}

h6 {
  font-size: s-core-px-to-rem($e-headings__font-size--h6);
}
