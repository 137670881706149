.c-autocomplete--live-search_new {
  ul {
    list-style-type: none;
    margin: 0;
  }

  .w-live-search__category {
    text-align: left;
    margin-top: $f-space--small;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    &::before {
      content: "";
      display: block;
      position: relative;
      top: 0;
      left: $w-live-search--sp-inset;
      right: $w-live-search--sp-inset;
      background-color: $w-live-search--border-color;
      height: $w-live-search--border-size;
      margin-bottom: $f-space--small;
      margin-right: $f-space--large;
    }
    &:first-child {
      margin-top: 0;
      &::before {
        display: none;
      }
    }

    .w-live-search__category-header {
      margin-left: $f-space--tiny;
    }

    .w-live-search__category-icon {
      margin-left: $f-space--small;
      font-size: $w-live-search__category-icon--font-size;
    }

    .w-live-search__category-trigger {
      padding: $f-space--small $f-space--medium;
    }

    .c-autocomplete__result {
      font-size: 14px;
      font-weight: 400;
      text-transform: none;
      &::before {
        display: none;
      }
    }

    .w-live-search__result {
      > .w-live-search__result-name {
        display: inline-block;
      }

      > .w-live-search__breadcrumbs {
        display: inline-block;
        .w-live-search__breadcrumb {
          font-size: 12px;
          color: #727272;
        }
      }
    }
  }
}

.w-live-search .c-autocomplete__show-more {
  margin-right: $w-live-search__show-more--margin-right;
  &.is-hidden {
    display: none;
  }
}
