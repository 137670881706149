/* -------------------------------------------------------------------------
 * CARD VARS
 */

$c-card--alignment:                                     left !default;

$c-card--border-width:                                  1px !default;;
$c-card--border-style:                                  solid !default;;
$c-card--border-color:                                  $f-color__neutral-grey--light !default;;
$c-card--border-radius:                                 $f-radius--small !default;;

$c-card--spacing-inset:                                 $f-space--medium !default;
$c-card--spacing-stack:                                 $f-space--medium !default;

$c-card--shadow:                                        $f-shadow--elevated !default;
$c-card--shadow-color:                                  $f-shadow__color !default;
$c-card--shadow-opacity:                                $f-shadow__opacity !default;

$c-card__body--bg-color:                                $e-document__body--bgcolor !default;

$c-card__label--positioning-top:                        $f-space--small !default;
$c-card__label--positioning-left:                       $f-space--small !default;
$c-card__label--top-right--positioning-top:             $f-space--medium !default;

$c-card__title--color:                                  $e-document__body--color !default;

$c-card__title-link--font-weight:                       700 !default;

$c-card__subtitle--color:                               $f-color__neutral-grey--semi-dark !default;
$c-card__subtitle--spacing:                             $f-space--tiny !default;

$c-card__footer--bg-color:                              $e-document__body--bgcolor !default;
$c-card__footer--padding-top:                           $f-space--tiny !default;

$c-card__c-nav--list--margin-h:                         -1 * $f-space--tiny !default;

// Variants
// --------------------------------------

// Top title modifier
$c-card__title--top-title--space-inline:                -1 * $c-card--spacing-inset !default;
$c-card__title--top-title--space-stack:                 $f-space--small * 1.5 !default;
$c-card__title--top-title--padding:                     $f-space--medium !default;
$c-card__title--top-title--bg-color:                    $f-color__brand-primary !default;
$c-card__title--top-title--color:                       $f-color__neutral-white !default;
$c-card__title--top-title--bg-color--with-image:        rgba($f-color__neutral-black, .5) !default;

