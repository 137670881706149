.w-voucher__container {
  padding: 0;

  .w-voucher__balance_bonus_info_label {
    background-color: $w-voucher__balance_bonus_info_label--bg-color;
    color: $w-voucher__balance_bonus_info_label--color;
    font-size: $w-voucher__balance_bonus_info_label--font-size;
    font-family: $w-voucher__balance_bonus_info_label--font-family;
    font-weight: $w-voucher__balance_bonus_info_label--font-weight;
    @include sp-inset-squish($w-voucher__balance_bonus_info_label--sp-inset);
  }
}

a.w-voucher__inner-content {
  display: flex;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    background-color: $w-voucher--bg-color-hover;
    color: $w-voucher--color;
  }

  &:visited,
  &:link {
    color: $w-voucher--color;
  }
}

.w-voucher__inner-content {
  display: flex;
  height: 171px;
  align-items: center;
  margin-bottom: 0;
  background-color: $w-voucher--bg-color;
  color: $w-voucher--color;
}

.w-voucher__balance {
  @include sp-stack-children($f-space--medium);
  flex-grow: 1;
  text-align: center;
  font-size: $f-font-size--s;
  padding: $f-space--small;

  .w-voucher__bonus {
    @include sp-stack($f-space--small);
  }
}

.w-voucher__side {
  background-image: $w-voucher__logo--bg-image;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  height: 100%;
  width: 112px;
  display: flex;
  align-items: center;
  padding-left: $f-space--small;
}

.w-voucher__icon {
  font-size: $w-voucher__icon--font-size;
  color: $w-voucher__icon--color;
  margin-bottom: $w-voucher__icon--margin-bottom;
  margin-left: $w-voucher__icon--margin-left;
}

.w-voucher__link-icon {
  margin: $f-space--small;
}

.w-voucher__code {
  @include sp-inset-squish($f-space--medium);
  background-color: $f-color__neutral-alternate;
  font-size: $f-font-size--s;
  display: block;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: $f-color__neutral-grey--ultra-light;

    .w-voucher__code-tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}

.w-voucher__code-tooltip {
  @include sp-inset-squish($f-space--small);
  visibility: hidden;
  background-color: $w-voucher__tooltip--bg-color;
  color: $w-voucher__tooltip--color;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: 0;
  opacity: 0;
  transition: opacity .3s;
  bottom: -21px;
  transform: translateX(-50%);
}

.w-voucher__code-tooltip::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent $w-voucher__tooltip--bg-color transparent;
}

.w-voucher__code-guid {
  user-select: text;

  .m-icon {
    margin-left: $f-space--tiny;
  }
}

.w-voucher__old-balance-amount {
  font-weight: $w-voucher__old-balance-amount--font-weight;
  font-family: $w-voucher__old-balance-amount--font-family;
  font-size: $w-voucher__old-balance-amount--font-size;
  margin-right: $w-voucher__old-balance-amount--margin-right;
  text-decoration: line-through;
  display: inline-block;
}

.w-voucher .w-voucher__bonus{
  white-space: nowrap;
}

.w-voucher.w-voucher--compact {
  .w-voucher__container {
    border-radius: 4px;
    overflow: hidden;
  }

  .w-voucher__inner-content {
    height: 82px;
  }

  .w-voucher__side {
    width: 68px;
    padding-left: $f-space--tiny;
  }

  .w-voucher__icon {
    font-size: $w-voucher--compact__icon--font-size;
    margin-bottom: $w-voucher--compact__icon--margin-bottom;
  }

  .w-voucher__balance {
    @include sp-stack-children($f-space--tiny);
    text-align: left;
    font-size: $f-font-size--xs;
  }

  .w-voucher__balance-description {
    display: none;
  }
}


.w-voucher__refund-button,
.w-voucher__refund-message {
  margin: $w-voucher__refund-message-and-button--margin;
  text-align: center;
}
