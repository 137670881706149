/* -------------------------------------------------------------------------
 * PACK VARIABLES
 *
 */

// Object toggling

$o-pack--enabled:                           true !default;

// Object Params

$o-pack__space:                             $f-space--none !default;

// Modifiers Spaces

$o-pack__mod-spaces--enabled:               true !default;
$o-pack-spaces:                             map_remove($f-spaces, "large", "huge") !default;

// Modifiers Alignments

$o-pack__mod-alignments--enabled:           true !default;
$o-pack__mod-alignments:                    (top, middle, bottom, baseline) !default;

// Modifier Reverse

$o-pack__mod-reverse--enabled:              true !default;

// Modifier Auto Width

$o-pack__mod-auto--enabled:                 true !default;

// Item Reverse Modifier

$o-pack__item-mod-reverse--enabled:         true !default;
