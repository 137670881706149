/* -------------------------------------------------------------------------
 * CARD PRICE
 */

.c-card-price {
  @extend .c-card;
  background-color: $c-card-price__bg-color;
  box-shadow: unset;

  .c-price__info {
    margin-bottom: $c-card-price__price--legend-margin;
  }
}

.c-card-price__headings {
  @include sp-inline($c-card-price__headings--sp-inline);
}

.c-card-price__title {
  @extend .c-card__title;

  + .c-card-price__subtitle {
    @extend .c-card__subtitle;
  }
}

.c-card-price__info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include sp-stack($c-card-price--main-spacing);
}

.c-card-price__price {
  min-width: $c-card-price__price--min-width;

}

.c-card-price__icon-wrapper {
  background-color: $c-card-price__icon-wrapper--bg-color;
  color: $c-card-price__icon-wrapper--color;
}

.c-card-price__icon {
  font-size: $c-card-price__icon-font-size;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}

.c-card-price__text {
  @include t-mq($until: $c-card-price--breakpoint-tablet) {
    font-size: $c-card-price__text--mobile-font-size;
  }
}

/* -------------------------------------------------------------------------
 * Card price compact version
 */

.c-card-price.c-card-price--compact {
  &,
  .c-card-price__link {
    display: flex;
  }

  .c-card-price__link {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &.c-card-price--hover-effect:hover {
    transition: box-shadow $c-card-price--compact--transition-duration $f-easing--base $c-card-price--compact--transition-timing-function;
    box-shadow: $c-card-price--compact--box-shadow;
  }

  .c-card-price__title {
    line-height: $c-card-price--compact__title--line-height;

    &::after {
      margin-top: 0;
    }
  }

  .c-card-price__img,
  .c-card-price__icon-wrapper {
    height: $c-card-price--compact__img--height;
    width: $c-card-price--compact__img--width;
    flex-shrink: 0;
  }

  &:not(.c-card-price--without-price) {
    .c-card-price__img,
    .c-card-price__icon-wrapper {
      height: $c-card-price--compact__img--height-with-price;
      width: $c-card-price--compact__img--width-with-price;
    }
  }


  .c-card-price__description {
    margin-bottom: $c-card-price--compact__description--margin-bottom;
  }

  &.c-card-price--without-price .c-card-price__description {
    margin-bottom: 0;
  }

  .c-card__body {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: $c-card-price--compact__body--margin-padding;
    text-decoration: none;
    color: $c-card-price--compact__body--color;
  }

  .c-card-price__price {
    order: 1;
    margin-bottom: 0;
    text-align: left;
  }

  .c-card-price__info {
    margin-bottom: $c-card-price--compact__info--margin-bottom;
  }

  .c-card-price__description,
  .c-card__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    * {
      display: inline;
      line-height: $c-card-price--compact__description-content--line-height;
    }
  }

  .c-card__title {
    max-height: calc(#{$c-card-price--compact__title-height} * #{$c-card-price--compact__text-max-lines});
  }

  .c-card-price__description {
    max-height: calc(#{$c-card-price--compact__text-height} * #{$c-card-price--compact__text-max-lines});
  }
}
