/* -------------------------------------------------------------------------
 * REVIEWS LIST VARS
 *
 */

$w-reviews-list__items--mb:                                                     $f-space--medium !default;

$w-reviews-list__item--bq:                                                      "sml" !default;
$w-reviews-list__item--mb:                                                      $f-space--medium !default;
$w-reviews-list__item-title--mb:                                                $f-space--small + $f-space--tiny !default;
$w-reviews-list__item-title-area--mb:                                           $f-space--tiny !default;
$w-reviews-list__item-sorting-area--mb:                                         $f-space--medium + $f-space--small !default;
$w-reviews-list__item-sorting-item--ml:                                         $f-space--small + $f-space--tiny !default;
$w-reviews-list__item-sorting-switch-item--ml:                                  $f-space--medium !default;    

$w-reviews-list__item-date--mb:                                                 $f-space--small + $f-space--tiny !default;
$w-reviews-list__item-date--text-color:                                         $f-color__neutral-grey--semi-dark;

$w-reviews-list__item-subtitle--sp-inline:                                      $f-space--medium !default;
$w-reviews-list__item-subtitle-label-bg:                                        $f-color__state-info--ultra-light !default;
$w-reviews-list__item-subtitle-label-color:                                     $f-color__state-info--dark !default;
$w-reviews-list__item-subtitle-label-font-size:                                 $f-font-size--s !default;

$w-reviews-list__score--mb:                                                     $f-space--small + $f-space--tiny !default;
$w-reviews-list__item-scores--column-width:                                     50% !default;
$w-reviews-list__item-scores--row-gap:                                          $f-space--tiny !default;
$w-reviews-list__item-scores--title-font-size:                                  $f-font-size--s !default;

$w-reviews-list__main-comment--sp-inline:                                       $f-space--medium !default;

$w-reviews-list__number-score__body--min-width:                                 $f-space--medium !default;
$w-reviews-list__number-score__body--br:                                        $f-space--medium !default;
$w-reviews-list__number-score__body--pv:                                        9px !default;
$w-reviews-list__number-score__body--ph:                                        14px !default;
$w-reviews-list__number-score__body--background-color:                          $f-color__neutral-grey--ultra-light !default;
$w-reviews-list__number-score__body--text-color:                                $f-color__state-info--semi-dark !default;
$w-reviews-list__item-scores--title-font-weight:                                400 !default;

$w-reviews-list__number-score__grade--max-width:                                9px !default;

$w-reviews-list__main-title-label--padding-bottom:                              $f-space--tiny !default;

$w-reviews-list__main-score-bubble--gutter:                                     $f-space--small + $f-space--tiny !default;
$w-reviews-list__main-score-bubble--high:                                       9px !default;
$w-reviews-list__main-score-bubble--gutter-mobile:                              $f-space--small !default;
$w-reviews-list__main-score-bubble--high-mobile:                                $f-space--tiny !default;
$w-reviews-list__main-score-bubble--font-size:                                  $f-font-size--l !default;
$w-reviews-list__main-score-bubble--font-size-mobile:                           $f-font-size--m !default;
$w-reviews-list__main-score-bubble--radius:                                     $f-space--small !default;
$w-reviews-list__main-score-bubble--radius-mobile:                              10px !default;
$w-reviews-list__main-score-bubble--margin-right:                               $f-space--small + $f-space--tiny !default;

$w-reviews-list__button-info--font-size:                                        $f-space--small + $f-space--tiny !default;
$w-reviews-list__button-info--ph:                                               0 !default;
$w-reviews-list__button-info--pv:                                               $f-space--small + $f-space--tiny !default;
$w-reviews-list__button-info--color:                                            $f-color__state-success!default;
$w-reviews-list__button-info--hover-color:                                      $f-color__state-success--semi-dark!default;

$w-reviews-list__title--sp-stack:                                               $f-space--medium !default;

$w-reviews-list__comment-translated-button--color:                           $e-links__color !default;
$w-reviews-list__comment-translated-button--duration:                        $f-duration--normal !default;
$w-reviews-list__comment-translated-button--color--hover:                    $e-links__hover-color !default;
$w-reviews-list__comment-translated-button--disabled:                        $f-color__neutral-grey !default;
$w-reviews-list__comment-translated-button--padding-top:                     $f-space--small + $f-space--small !default;
$w-reviews-list__comment-translated-button--margin-right:                    $f-space--tiny !default;
$w-reviews-list__comment-translated-button--font-size:                       $f-font-size--s !default;

$w-reviews-list__comment-translate-info-google--color:                       $f-color__neutral-grey !default;

$w-reviews-list__comment-translate-text--padding:                            $f-space--small !default;
$w-reviews-list__comment-translate-text--font-size:                          $f-font-size--s !default;
$w-reviews-list__comment-translate-text--max-height:                         1000px!default;

$w-reviews-list__country-flag--margin-right:                                 $f-space--small !default;

$w-reviews-list__loader--padding:                                            $f-space--small !default;