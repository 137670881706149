
/* -------------------------------------------------------------------------
 * RESET
 *
 * A very simple reset that sits on top of Normalize.css.
 *
 */


// Reset content

body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

fieldset {
  border: 0;
}

// Remove trailing margins from nested lists.

li > {
  ol,
  ul {
    margin-bottom: 0;
  }
}


// Remove default table spacing.

table {
  border-collapse: collapse;
  border-spacing: 0;
}


// Vertical spacing

address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: s-core-px-to-rem($g-reset__spacing--vertical);
}


// Consistent indentation for lists.

dd,
ol,
ul {
  margin-left: s-core-px-to-rem($g-reset__spacing--horizontal);
}
