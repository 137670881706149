/* -------------------------------------------------------------------------
 * Contact center semaphore
 *
 */

.w-contact-center-semaphore {
  &--free > .w-contact-center-semaphore__icon {
    color: $w-contact-center-semaphore--free--color;
  }

  &--busy > .w-contact-center-semaphore__icon {
    color: $w-contact-center-semaphore--busy--color;
  }

  &--verybusy > .w-contact-center-semaphore__icon {
    color: $w-contact-center-semaphore--verybusy--color;
  }

  &__text {
    margin-left: $w-contact-center-semaphore__text--margin-left;
  }

  &__icon::before {
    font-size: 1em;
  }
}
