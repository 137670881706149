/* -------------------------------------------------------------------------
 * BOOKED SERVICE BASKET VARS
 *
 */

$w-booked-service-basket__breakpoint:                                               $f-breakpoint--md !default;
$w-booked-service-basket__breakpoint-sml:                                           $f-breakpoint--sml !default;

$w-booked-service-basket__sp-inline:                                                $f-space--medium !default;
$w-booked-service-basket__sp-inset-h:                                               $f-space--small !default;

$w-booked-service-basket--background-color:                                         $f-color__state-info--ultra-light !default;
$w-booked-service-basket--border-style:                                             solid;
$w-booked-service-basket--border-width:                                             1px !default;
$w-booked-service-basket--border-color:                                             $f-color__state-info--light !default;

$w-booked-service-basket__side-drawer-trigger--sp-inset-v:                          $f-space--tiny + $f-space--small !default;
$w-booked-service-basket__side-drawer-trigger--sp-inset-h:                          $f-space--small !default;
$w-booked-service-basket__side-drawer-trigger--width:                               100% !default;
$w-booked-service-basket__side-drawer-trigger--background-color:                    $f-color__state-info--ultra-light !default;
$w-booked-service-basket__side-drawer-trigger--z-index:                             $f-z-index--4 + 3 !default;

$w-booked-service-basket__side-drawer--top:                                         55% !default;
$w-booked-service-basket__side-drawer--height:                                      calc(100% - #{$w-booked-service-basket__side-drawer--top}) !default;
$w-booked-service-basket__side-drawer--width:                                       100% !default;

$w-booked-service-basket__side-drawer--header--padding:                             $f-space--small !default;
$w-booked-service-basket__side-drawer--header__btn-icon--color:                     $f-color__neutral-black !default;

$w-booked-service-basket__total-services--color:                                    $f-color__state-info !default;

$w-booked-service-basket__service-info--margin-bottom:                              $f-space--tiny !default;

$w-booked-service-basket__service-info--text--font-size:                            $f-font-size--s !default;
$w-booked-service-basket__service-info--price--font-size:                           $f-font-size--m !default;

$w-booked-service-basket__booking-paid-amount--color:                               $f-color__neutral-grey--dark !default;
$w-booked-service-basket__booking-total-amount--padding-v:                          $f-space--tiny !default;
$w-booked-service-basket__booking-info--padding-bottom:                             $f-space--small !default;
$w-booked-service-basket__booking-info--margin-bottom:                              $f-space--small !default;
$w-booked-service-basket__booking-info--border-color:                               $f-color__neutral-grey--semi-light !default;

$w-booked-service-basket__due-amount--color:                                        $f-color__neutral-grey--semi-dark !default;
$w-booked-service-basket__amount-information--margin-bottom:                        $f-space--tiny !default;

$w-booked-service-basket__side-drawer-btn--height:                                  $f-space--medium !default;
$w-booked-service-basket__side-drawer-btn--margin-top:                              0 !default;
$w-booked-service-basket__side-drawer-btn-in--margin-top:                           calc(-1 * #{$f-space--tiny}) !default;
$w-booked-service-basket__side-drawer-btn--margin-left:                             $f-space--small !default;
$w-booked-service-basket__side-drawer-btn__icon--color:                             $f-color__state-info !default;
$w-booked-service-basket__side-drawer-btn__icon--padding:                           $f-space--small !default;
$w-booked-service-basket__side-drawer-btn__icon--font-size:                         $f-font-size--l !default;

$w-booked-service-basket__backdrop-z-index:                                         calc(2 * #{$f-z-index--3}) !default;

$w-booked-service-basket__subitem--child--color:                                    $f-color__neutral-grey !default;
$w-booked-service-basket__subitem--child--border-size:                              1px !default;
$w-booked-service-basket__subitem--child--inset-left:                               $f-space--small !default;
$w-booked-service-basket__subitem--inset-top:                                       $f-space--tiny !default;

$w-booked-service-basket__table__item--color--total:                                3px !default;
$w-booked-service-basket__table__item--font-weight--bold:                           700 !default;
$w-booked-service-basket__table__item--font-weight--normal:                         400 !default;
$w-booked-service-basket__table__item--text-transform--total:                       uppercase !default;

$w-booked-service-basket__table__item-right--sp-right:                              $f-space--medium !default;

$w-booked-service-basket__table__item--color--subitem:                              $f-color__neutral-grey !default;
$w-booked-service-basket__table__item--material--subitem:                           map_get($m-body__mod-sizes, 'tiny') !default;

$w-booked-service-basket__table__item--margin:                                      $f-space--small !default;
