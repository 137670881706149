/* -------------------------------------------------------------------------
 * ROOM SELECTOR VARS
 *
 */

$w-room-selector--breakpoint:                                            $f-breakpoint--sm !default;
$w-room-selector--main-spacing:                                          $f-space--small !default;

$w-room-selector__room--padding-right:                                   20px !default;
$w-room-selector__room--padding-right--desktop:                          30px !default;

$w-room-selector__item-wrapper--padding-right:                           20px !default;
$w-room-selector__item-wrapper--padding-right--desktop:                  30px !default;

$w-room-selector__message-box--border-radius:                            $f-radius--medium !default;
$w-room-selector__message-lines--space:                                  $f-space--small + $f-space--tiny !default;
$w-room-selector__items-short-width:                                     45% !default;

$w-room-selector__age-profile-message-trigger-btn--margin-left:          $f-space--medium !default;
$w-room-selector__age-profile-message-content--margin-top:               18px !default;
$w-room-selector__age-profile-message-profile-title--padding-right:      18px !default;
$w-room-selector__age-profile-message-profile-cell--padding-bottom:      4px !default;