/* -------------------------------------------------------------------------
* DESTINATION VARS
*
*/

$t-destination-page__header-bottom-decoration--bp:                         "sm" !default;
$t-destination-page__header-bottom-decoration--bottom:                     15px !default;
$t-destination-page__l-site-main__top--margin-bottom:                      ($f-space--medium + $f-space--tiny) !default;
$t-destination-page__l-site-main__top--margin-bottom-bp:                   $f-space--large !default;
$t-destination-page__c-breadcrumbs--top:                                   $f-space--small !default;

// Fragments
$fr-destination-page__expert-tips-c-slider-item--margin-bottom:            20px !default;
