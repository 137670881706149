
.w-booked-service-error {
  text-align: center;
  @include sp-inset-h($w-booked-service-error--sp-inset-h);
  &.is-unresolved {
    @include a-unresolved();
    &::before, &::after{
      z-index: 1;
    }
  }
}
  
  
.w-booked-service-error__content {
  margin-left: auto;
  margin-right: auto;
  max-width: $w-booked-service-error__content--max-width;
}
  
.w-booked-service-error__icon:before {
  font-size: $w-booked-service-error__icon--font-size;
}
  