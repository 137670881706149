.fr-account-booking-modifications {
  @include o-layout__item();
  padding-top: 0;

  display: flex;
  flex-direction: column;

  @include t-mq($from: "sm", $until: "md") {
    margin: 0 auto;
    max-width: 320px;
  }
}