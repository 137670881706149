/* -------------------------------------------------------------------------
 * ICONS mixins
 *
 */


// Material mixins
// --------------------------------------------------

@mixin m-icon__styles {
	font-family: $m-icons__font-family;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

@mixin m-icon($_icon-name, $_pseudo: $m-icons__pseudo-element) {
  &::#{$_pseudo} {
    @include m-icon__styles;
    content: map_get($m-icon__glyphs, $_icon-name);
  }
}