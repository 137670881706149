/* -------------------------------------------------------------------------
 * BOOKING STEPS
 *
 */
.t-bookingsteps, .t-bookingsteps .l-site-footer {
    background-color: $t-bookingsteps--background-color;
}

.t-bookingsteps .l-site-main {
    padding-top: $t-bookingsteps--padding-top;
}

.t-bookingsteps-content__title {
    margin-bottom: $t-bookingsteps-content--margin;
}

// DON'T remove the lines below, they are specific styles used for table inside BookingSteps
.t-bookingsteps-content__table-container{
  margin-top: $t-bookingsteps-content__table-container--margin;
}

.t-bookingsteps-content__table .t-bookingsteps-content__table-column{
  margin-bottom: $t-bookingsteps-content__table-column--margin;
}

@include t-mq($until: $t-bookingsteps-content__table-container--bp-medium) {
  .t-bookingsteps-content__table {
    display: grid;
    grid-template-columns: 1fr;
  }

  .t-bookingsteps-content__table-column:not(:first-child) .t-bookingsteps-content__table-item:first-child {
    text-align: center;
    padding-bottom: $t-bookingsteps-content__table-item--sp-bottom;
  }

  .t-bookingsteps-content__table>.t-bookingsteps-content__table-column:first-child {
    display: none;
  }

  .t-bookingsteps-content__table-column .t-bookingsteps-content__table-item{
    &:not(:first-child) {
      display: grid;
      grid-template-columns: minmax(30%, 50%) auto 1fr;
      align-items: center;
      border-bottom: 1px solid $t-bookingsteps-content__table-column--border-color;
      border-left: 1px solid $t-bookingsteps-content__table-column--border-color;
      border-right: 1px solid $t-bookingsteps-content__table-column--border-color;
      padding-left: $t-bookingsteps-content__table-item--sp-inset;
    }
    &:first-child{
      border-bottom: 1px solid $t-bookingsteps-content__table-column--border-color;
    }
    .m-icon {
      padding: $t-bookingsteps-content__table-item--sp-inset;
    }
    span {
      border-right: 1px solid $t-bookingsteps-content__table-column--border-color;
      padding: $t-bookingsteps-content__table-item--sp-inset;
    }
  }
}

@include t-mq($t-bookingsteps-content__table-container--bp-medium) {
  .t-bookingsteps-content__table {
    display: grid;
    grid-template-columns: 2fr repeat(3, 1fr);
  }

  .t-bookingsteps-content__table-column {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
  }

  .t-bookingsteps-content__table-column:first-child .t-bookingsteps-content__table-item:not(:first-child) {
    border-left: 1px solid $t-bookingsteps-content__table-column--border-color;
  }

  .t-bookingsteps-content__table-column:not(:first-child) .t-bookingsteps-content__table-item:first-child {
    border-top: none;
    border-right: none;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .t-bookingsteps-content__table-item {
    span {
      display: none;
    }
  }

  .t-bookingsteps-content__table-column .t-bookingsteps-content__table-item{
    &:first-child{
      border-bottom: 1px solid $t-bookingsteps-content__table-column--border-color;
    }

    &:not(:first-child) {
      border-right: 1px solid $t-bookingsteps-content__table-column--border-color;
      border-bottom: 1px solid $t-bookingsteps-content__table-column--border-color;
      @include sp-inset($t-bookingsteps-content__table-item--sp-inset);
      display: flex;
      align-items: center;
      grid-gap: $t-bookingsteps-content__table-item--sp-inset;
    }
  }
}

.t-booking-steps__contact-text {
  margin-top: $t-booking-steps__contact-and-faq--sp;
}

.t-booking-steps__contact-title,
.t-booking-steps__faq-title {
  margin-bottom: $t-booking-steps__contact-and-faq--sp;
}
// DON'T remove the lines before, they are specific styles used for table inside BookingSteps
