/* -------------------------------------------------------------------------
 * RADIO BUTTON
 *
 */

.c-radio-button {
  font-size: $c-radio-button--font-size;
}

.c-radio-button__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.c-radio-button__label {
  cursor: pointer;
  position: relative;
  display: flex;
  @include m-body($c-radio-button__label--font);
}

.c-radio-button__element {
  position: relative;
  width: s-core-px-to-em($c-radio-button__element--size);
  height: s-core-px-to-em($c-radio-button__element--size);
  flex: 0 0 s-core-px-to-em($c-radio-button__element--size);

  // Used as radio CIRCLE
  &::before {
    content: '';
    position: relative;
    display: block;
    left: $c-radio-button__circle--size / 2;
    top: calc(50% - #{$c-radio-button__circle--size / 2});
    width: s-core-px-to-em($c-radio-button__circle--size);
    height: s-core-px-to-em($c-radio-button__circle--size);
    border: $c-radio-button__circle--border $c-radio-button__circle--border--color;
    border-radius: $c-radio-button__circle--border-radius;
    background-color: $c-radio-button__circle--bg-color;
  }
  // Used as radio BULLET
  &::after {
    content: '';
    position: absolute;
    width: s-core-px-to-em($c-radio-button__bullet--size);
    height: s-core-px-to-em($c-radio-button__bullet--size);
    background-color: $c-radio-button__bullet--color;
    top: calc(50% - #{$c-radio-button__bullet--size / 2});
    left: s-core-px-to-em($c-radio-button__bullet--size);
    border-radius: $c-radio-button__circle--border-radius;
    transition-property: $c-radio-button--transition-property;
    transition-duration: $c-radio-button--transition-duration;
    transition-timing-function: $c-radio-button--transition-easing;
    opacity: 0;
    transform: scale(0);
  }
}

.c-radio-button__text-wrapper {
  @include sp-inset-v($c-radio-button__text-wrapper--sp-inset-v);
  display: flex;
  align-items: flex-start;

  &.c-radio-button__text-wrapper--highlighted-text{
    flex-direction: column;
    align-items: start;
  }

  &.c-radio-button__text-wrapper--additional-text{
    margin-left: auto;
    align-self: flex-start;
  }

  .c-radio-button__text-content{
    display: flex;
    align-items: flex-start;
  }
}

.c-radio-button__text {
  text-transform: lowercase;
  display: inline-block;

  &::first-letter {
    text-transform: uppercase;
  }
}

.c-radio-button__image {
  max-width: $c-radio-button__image-max-width;
  margin-right: $c-radio-button__image-margin-right;
  margin-bottom: $c-radio-button__image-margin-bottom;
  margin-left: $c-radio-button__image-margin-left;
}

.c-radio-button--with-illustration {
  .c-radio-button__label {
    display: flex;
    flex-direction: column;
  }
  .c-radio-button__illustration-image {
    width: fit-content;
    align-self: center;
    margin-bottom: $c-radio-butto__illustration-image;
  }

  .c-radio-button__element-container {
    display: flex;
  }

  .c-radio-button__text-wrapper {
    align-self: start;
  }
}

.c-radio-button__count {
  color: $c-radio-button__count--color;
  font-size: $c-radio-button__count--font-size;
  padding-left: $f-space--tiny;
  &::before{content: "("}
  &::after{content: ")"}
}

.c-radio-button__additional-text {
  color: $c-radio-button__additional-text--color;
  font-size: $c-radio-button__additional-text--font-size;
  display: block;
  top: calc(50% - #{$c-radio-button__circle--size / 2});
}

.c-radio-button .c-radio-button__highlight-text {
  display: block;
  width: fit-content;
  @include sp-stack($c-radio-button__highlight-text--sp-stack)
}

// User Interaction States
// -------------------------------------------------

// Hover / Focus State
.c-radio-button__input:focus ~ .c-radio-button__label,
.c-radio-button__label:hover {
  .c-radio-button__element::before {
    background-color: rgba($c-radio-button__circle--hover--bg-color, $c-radio-button__circle--extra-border--opacity);
    border: $c-radio-button__circle--border $c-radio-button__circle--hover--border--color;
    box-shadow: 0 0 0 s-core-px-to-em($c-radio-button__circle--extra-border--size) rgba($c-radio-button__circle--hover--extra-border--color, $c-radio-button__circle--extra-border--opacity);
  }
}

// Pressed
.c-radio-button__label:active {
  .c-radio-button__element::before {
    background-color: rgba($c-radio-button__circle--pressed--bg-color, $c-radio-button__circle--extra-border--opacity);
    border: $c-radio-button__circle--border $c-radio-button__circle--pressed--border--color;
    box-shadow: 0 0 0 s-core-px-to-em($c-radio-button__circle--extra-border--size) rgba($c-radio-button__circle--pressed--extra-border--color, $c-radio-button__circle--extra-border--opacity);
  }
}

// Active / Selected / Checked State
.c-radio-button__input:checked + .c-radio-button__label {
  .c-radio-button__element::before {
    background-color: $c-radio-button__circle--selected--bg-color;
    border: $c-radio-button__circle--border $c-radio-button__circle--selected--border--color;
  }
  .c-radio-button__element::after {
    opacity: 1;
    transform: scale(1);
  }
}


// General States
// -------------------------------------------------

// Disabled / unresolved
.c-radio-button.is-disabled,
.is-disabled .c-radio-button,
.c-radio-button.is-unresolved {
  pointer-events: none;
  opacity: $c-radio-button__disabled--opacity;
}

.is-disabled .c-radio-button,
.c-radio-button.is-unresolved {
  opacity: $c-radio-button__disabled--opacity;
}

// Unresolved
.c-radio-button.is-unresolved,
.is-unresolved .c-radio-button {
  .c-radio-button__element {
    &::before {
      background-color: $c-radio-button__unresolved--bg-color;
      border: none;
    }
  }
  .c-radio-button__text-wrapper {
    @include a-unresolved(
      $_width-after: $c-radio-button__unresolved--animation-bullet--width
    );
  }
}

// Validation States
// -------------------------------------------------

.c-radio-button {
  &.has-success {
    .c-radio-button__label .c-radio-button__element::before {
      border: $c-radio-button__circle--border $c-radio-button__circle--success--border--color;
    }
  }
  &.has-warning {
    .c-radio-button__label .c-radio-button__element::before {
      border: $c-radio-button__circle--border $c-radio-button__circle--warning--border--color;
    }
  }
  &.has-error {
    .c-radio-button__label .c-radio-button__element::before {
      border: $c-radio-button__circle--border $c-radio-button__circle--error--border--color;
    }
  }
}

