/* -------------------------------------------------------------------------
 * FLIGHT/BUS SELECTOR VARS
 *
 */

$w-flight-bus-selector__spacing-main:                                                              $f-space--small !default;
$w-flight-bus-selector__spacing-secondary:                                                         $f-space--medium !default;
$w-flight-bus-selector__spacing-tertiary:                                                          $f-space--large !default;

$w-flight-bus-selector__heading--separation:                                                       $f-space--tiny !default;
$w-flight-bus-selector__heading-dates--color:                                                      $f-color__state-info--semi-dark !default;
$w-flight-bus-selector__heading-date-arrival--separation:                                          $f-space--tiny !default;

$w-flight-bus-selector__breakpoint:                                                                $f-breakpoint--sml !default;

$w-flight-bus-selector__collapse--min-height:                                                      320px !default;
$w-flight-bus-selector__collapse--min-height-small:                                                190px !default;

$w-flight-bus-selector__side-drawer--padding:                                                      $w-flight-bus-selector__spacing-secondary !default;

$w-flight-bus-selector__side-drawer-header--text-transform:                                        uppercase !default;
$w-flight-bus-selector__side-drawer-header--font-weight:                                           900 !default;
$w-flight-bus-selector__side-drawer-header--color:                                                 $f-color__neutral-white !default;
$w-flight-bus-selector__side-drawer-header--bg-color:                                              $f-color__brand-primary !default;

$w-flight-bus-selector__side-drawer-footer--border-color:                                          $f-color__neutral-grey--ultra-light !default;
$w-flight-bus-selector__side-drawer-footer--border-style:                                          solid !default;
$w-flight-bus-selector__side-drawer-footer--border-width:                                          1px !default;

$w-flight-bus-selector__gradient--height:                                                          120px !default;
$w-flight-bus-selector__gradient--position-fix:                                                    2px !default;
$w-flight-bus-selector__gradient--initial-color:                                                   $f-color__neutral-white !default;
$w-flight-bus-selector__gradient--final-color:                                                     $f-color__neutral-white--transparent !default;
$w-flight-bus-selector__gradient--z-index:                                                         $f-z-index--1 !default;
$w-flight-bus-selector__gradient--initial-change:                                                  30% !default;
$w-flight-bus-selector__gradient--final-change:                                                    100% !default;

$w-flight-bus-selector__disabled--opacity:                                                         .5 !default;

$w-flight-bus-selector_extra-info--spacing:                                                        $f-space--small !default;
$w-flight-bus-selector__extra-info--font-size:                                                     $f-font-size--xs !default;
$w-flight-bus-selector__extra-info--color:                                                         $f-color__state-info--semi-dark !default;
$w-flight-bus-selector__extra-info--margin:                                                        $f-space !default;

$w-flight-bus-selector__error-message--padding:                                                    $w-flight-bus-selector__spacing-secondary !default;
$w-flight-bus-selector__error-message--border-width:                                               1px !default;
$w-flight-bus-selector__error-message--border-style:                                               solid !default;

$w-flight-bus-selector__flexible-regular-flights-tabs--margin-top:                                 $f-space--medium !default;
$w-flight-bus-selector__flexible-regular-flights-tabs--border-bottom:                              2px solid $f-color__neutral-grey--ultra-light !default;
$w-flight-bus-selector__flexible-regular-flights-tabs-content--padding:                            $f-space--medium $f-space--medium 0 !default;
$w-flight-bus-selector__flexible-regular-flights-tabs-content--margin-bottom:                      $f-space--medium !default;
$w-flight-bus-selector__flexible-regular-flights-tabs-content--padding--from-bp:                   $f-space--small $f-space--small 0 0 !default;
$w-flight-bus-selector__flexible-regular-flights-tabs-content--margin-bottom--from-bp:             $f-space--small !default;

$w-flight-bus-selector__header-container--padding:                                                 $f-space--small 0 !default;
$w-flight-bus-selector__header-container--border-bottom:                                           2px solid $f-color__neutral-grey--ultra-light !default;
$w-flight-bus-selector__header-container--margin-bottom:                                           $f-space--medium !default;

$w-flight-bus-selector__open-jaw-switch--breakpoint:                                               $f-breakpoint--sm !default;
$w-flight-bus-selector__open-jaw-switch--margin-top:                                               $f-space--small !default;
$w-flight-bus-selector__open-jaw-switch-text--margin-right:                                        $f-space--small !default;
$w-flight-bus-selector__open-jaw-switch-subtitle--color:                                           $f-color__neutral-grey--semi-dark !default;
$w-flight-bus-selector__open-jaw-switch-subtitle--font-size:                                       $f-font-size--xs !default;
$w-flight-bus-selector__open-jaw-switch-subtitle--max-width:                                       31rem !default;
$w-flight-bus-selector__open-jaw-switch-subtitle--margin-top:                                      6px !default;
