/* -------------------------------------------------------------------------
 * BOOKING BASKET
 *
 */

.w-booking-basket__content {
  overflow: hidden;
  padding-bottom: $w-booking-basket__content-mobile--padding-bottom;
  
  @include t-mq($w-booking-basket__mobile-breakpoint) {
    @include sp-stack($w-booking-basket__content--margin-bottom);
  }
 
  @include t-mq($until: $w-booking-basket__mobile-breakpoint) {
    .w-booking-basket__services--collapse-btn {
      display: none;
    }
  } 
}

.w-booking-basket__info, .w-booking-basket__services {
  @include sp-inset-h($w-booking-basket--padding-sm);
}

.w-booking-basket__header {
  display: flex;

  @include t-mq($w-booking-basket__mobile-breakpoint) {
    display: block;
  }
}

.w-booking-basket__image {
  margin-right: $w-booking-basket__image--margin-h;
  max-width: $w-booking-basket__image--width-mq;
  min-width: $w-booking-basket__image--width-mq;

  @include sp-stack($w-booking-basket__image--sp-stack);

  @include t-mq($w-booking-basket__mobile-breakpoint) {
    margin: 0 (-1 * $w-booking-basket__image--margin-h) $w-booking-basket__image--sp-stack;
    max-width: inherit;
    min-width: inherit;
  }
}

.w-booking-basket__rating {
  display: block;
  color: $w-booking-basket__rating--color;
  @include sp-stack($w-booking-basket__rating--sp-stack);
}

.w-booking-basket__acco-name {
  @include sp-stack($w-booking-basket__acco-name--sp-stack);
  @include t-mq($until: $w-booking-basket__mobile-breakpoint) {
    font-size: $w-booking-basket__acco-name--font-size-mq;
  }
}

.w-booking-basket__breadcrumbs {
  @include sp-stack($w-booking-basket__breadcrumbs--sp-stack);

  .c-breadcrumbs__item {
    min-width: initial;
  }
}

.w-booking-basket__breadcrumbs,
.w-booking-basket__breadcrumbs .c-breadcrumbs__link {
  color: $w-booking-basket__breadcrumbs--color;
  pointer-events: none;
}

.w-booking-bakset__price-rebooking, 
.w-booking-basket__price-total-person {
  &.is-hidden{
    display: none;
  }
}

.w-booking-basket__package-block {
  @include sp-stack($w-booking-basket__package-block--sp-stack);

  @include t-mq($until: $w-booking-basket__mobile-breakpoint) {
    .c-package-block__departure {
      font-size: $w-booking-basket__package-block--departure-font-size-mq;
    }

    .c-package-block__filters {
      font-size: $w-booking-basket__package-block--filters-font-size-mq;
    }
  }
}

.w-booking-basket__price-title {
  font-size: $w-booking-basket__price-title--font-size;
  color: $w-booking-basket__price-title--color;
  margin-bottom: $w-booking-basket__price-title--margin-bottom;

  @include sp-stack($w-booking-basket__price-title--sp-stack);
}

.w-booking-basket__price-title--text {
  font-size: $w-booking-basket__price-title--text;
  margin-bottom: $w-booking-basket__price-title--margin-bottom;
}

.w-booking-basket__price-package, .w-booking-basket__price-paid {
  display: flex;
  justify-content: space-between;
  font-size: $w-booking-basket__mobile-small--font-size;
  color: $w-booking-basket__price-package--color;
}

.w-booking-basket__price-paid {
  margin-bottom:  $w-booking-basket__price-paid--margin-bottom;
}

.w-booking-basket__price-package-person {
  display: flex;
  justify-content: flex-end;  
  color: $w-booking-basket__price-package--color;
  @include sp-stack($w-booking-basket__price-package--sp-stack);
}

.w-booking-basket__price-package-person--text{
  font-size: $w-booking-basket__price-package-person--font-size;
  font-weight: $w-booking-basket__price-package-person-amount--font-weight;
}

.w-booking-basket__price-total,
.w-booking-basket__price-person {
  display: flex;
  justify-content: space-between;
  @include t-mq($until: $w-booking-basket__mobile-breakpoint) {
    margin-bottom: 0;
    justify-content: space-between;
  }
}

.w-booking-basket__price-total {
  font-size: $w-booking-basket__price-total--font-size;
  color: $w-booking-basket__price-total--color;
  @include sp-stack($w-booking-basket__price-total--sp-stack);
  
  .w-booking-basket__price-total--title, .w-booking-basket__price-total--amount{
    font-size: $w-booking-basket__price-total--font-size;
    color: $w-booking-basket__price-total--color;
  }
}

.w-booking-basket__price {
  .w-booking-basket__price-total{
    &.w-booking-basket__price-rebooking {
      margin-bottom: $w-booking-basket__price-total-rebooking-margin-bottom;
    }
    @include t-mq($until: $w-booking-basket__mobile-breakpoint) {
      &:not(.w-booking-basket__price-rebooking) {
        display: none;
      }
    }
  }
}

.w-booking-basket__price-total-person {
  display: flex;
  justify-content: space-between;  

  @include t-mq($until: $w-booking-basket__mobile-breakpoint) {
    display: none;
  }
}

.w-booking-basket__price-total--title,
.w-booking-basket__price-total--amount {
  font-size: $w-booking-basket__desktop-small--font-size;
  
  @include t-mq($from: $w-booking-basket__desktop--breakpoint) {
    font-size: inherit;
  }
}

.w-booking-basket__price-package--title,
.w-booking-basket__price-package--amount,
.w-booking-basket__price-paid--title,
.w-booking-basket__price-paid--amount {
  font-size: $w-booking-basket__mobile-small--font-size;
}

.w-booking-basket__price-total--amount {
  font-weight: $w-booking-basket__price-total-amount--font-weight;

  @include t-mq($until: $w-booking-basket__mobile-breakpoint) {
    margin-left: auto;
  }
}

.w-booking-basket__price-package--amount {
  font-weight: $w-booking-basket__price-package-amount--font-weight;

  @include t-mq($until: $w-booking-basket__mobile-breakpoint) {
    margin-left: auto;
  }
}

.w-booking-basket__price-total-person--amount {
  font-size: $w-booking-basket__price-total-person-amount--font-size;
  font-weight:  $w-booking-basket__price-total-person-amount--font-weight;
}

.w-booking-basket__price-total-person--text {
  font-size: $w-booking-basket__price-total-person--text--font-size;
  font-weight:  $w-booking-basket__price-package-amount--font-weight;
}

.w-booking-basket__message {
  margin-bottom: $w-booking-basket__message-margin-bottom;
}

.w-booking-message__box.w-booking-message__text_rebooking
{
  padding: $w-booking-message__box-message__text-padding;
}

.w-booking-basket__extra-info {
  background-color: $w-booking-basket__extra-info--background-color;
  font-size: $w-booking-basket__extra-info--font-size;
  margin: 0 $w-booking-basket__extra-info--margin 0;
  @include sp-inset($w-booking-basket__extra-info--padding, $w-booking-basket__extra-info--padding-h);
  @include sp-stack($w-booking-basket__extra-info--sp-stack);
}

.w-booking-basket__services--collapse-btn {
  float: right;
  pointer-events: none;
  height: $w-booking-basket__services--collapse-btn--height;

  .c-btn__icon {
    color: $w-booking-basket__services--collapse-btn__icon--color;
    padding-left: $w-booking-basket__services--collapse-btn__icon--padding-left;
    height: $w-booking-basket__services--collapse-btn--height;
  }
  .c-btn__icon::before {
    font-size: $w-booking-basket__services--collapse-btn__icon--font-size;
    height: $w-booking-basket__services--collapse-btn__icon--height;
    color: $w-booking-basket__services--collapse-btn__icon--color;
  }
}

.w-booking-basket__services-item {
  border-top: $w-booking-basket__services-item--border-top;
  border-top-color: $w-booking-basket__services-item--border-top-color;
  border-top-style: $w-booking-basket__services-item--border-top-style;
  padding-top: $w-booking-basket__services-item--padding-top;

  @include sp-stack($w-booking-basket__services-item--sp-stack);

  &[data-c-collapse__opened] {
    @include sp-stack($f-space--none);
    .c-btn__icon::before {
      transform: rotate(180deg);
    }
  }

  @include t-mq($until: $w-booking-basket__mobile-breakpoint) {
    pointer-events: none;
  }
}

.w-booking-basket__services-item--empty {
  display:none;
}

.w-booking-basket__services--header {
  @include sp-stack($w-booking-basket__services--header--sp-stack);
}

.w-booking-basket__services--header-icon {
  @include sp-inline($w-booking-basket__services--header-icon--sp-inline);
}

.w-booking-basket__services--header-title {
  display: inline-block;
}

.w-booking-basket__services--lines {
  padding: 0 $w-booking-basket__services--lines--padding 0;
  margin-bottom: $w-booking-basket__services--lines--margin-bottom;
  font-size: $w-booking-basket__services--lines--font-size;
}

.w-booking-basket__lines-item--title {
  font-size: $w-booking-basket__lines-item--title--font-size;
  font-weight: $w-booking-basket__lines-item--title--font-weight;
  margin-bottom: $w-booking-basket__lines-item--title--margin-bottom;
}

.w-booking-basket__lines-item--title:not(:first-child) {
  margin-top: $w-booking-basket__lines-item--title--margin-top;
}

.w-booking-basket__lines-item {
  margin-bottom: $w-booking-basket__lines-item--title--margin-bottom;
  display: flex;
}
.w-booking-basket__lines-item-text, .w-booking-basket__lines-item-price{
  margin-right: $w-booking-basket__lines-item--margin-right;
}
.w-booking-basket__lines-item-total-price{
  font-size: $w-booking-basket__lines-item-total-price--font-size;
  margin-left: auto;
}

.w-booking-basket__mobile-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: $w-booking-basket__mobile-footer--width;
  padding: $w-booking-basket__mobile-footer--margin-top-bottom $w-booking-basket__mobile-footer--margin-h;
  z-index: $w-booking-basket__mobile-footer--z-index;
  @include t-mq($w-booking-basket__mobile-breakpoint) {
    display: none;
  }
}

@include t-mq($until: $w-booking-basket__mobile-breakpoint) {
  .w-booking-basket__side-drawer {
    &.c-side-drawer--origin-bottom {
      top: $w-booking-basket__side-drawer--top;
      width: $w-booking-basket__side-drawer--width;
      height: $w-booking-basket__side-drawer--height;

      .c-side-drawer__body-content {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: $w-booking-basket__side-drawer--body_content--padding-bottom;
      }

      .c-side-drawer__close {
        top: 0;
        right: 0;
        transform: translate(0);
      }
    }
  }
}

.w-booking-basket__side-drawer--header {
  padding: $w-booking-basket__side-drawer--header--padding;
  margin-bottom: 0;
  .c-btn__icon {
    color: $w-booking-basket__side-drawer--header__btn-icon--color;
  }
}

.w-booking-basket__services--side-drawer-btn {
  float: right;
  pointer-events: none;
  height: $w-booking-basket__services--side-drawer-btn--height;
  margin-top: $w-booking-basket__services--side-drawer-btn--margin-top;
  margin-left: $w-booking-basket__services--side-drawer-btn--margin-left;

  .c-btn__icon {
    color: $w-booking-basket__services--side-drawer-btn__icon--color;
    padding-left: $w-booking-basket__services--side-drawer-btn__icon--padding;
  }
  .c-btn__icon::before {
    font-size: $w-booking-basket__services--side-drawer--font-size;
    height: $w-booking-basket__services--collapse-btn__icon--height;
    color: $w-booking-basket__services--side-drawer-btn__icon--color;
  }
}

.w-booking-basket__mobile-footer.in {
  .w-booking-basket__services--side-drawer-btn{
    .c-btn__icon::before{
      transform: rotate(180deg);
    }
  }
}

.w-booking-basket__footer-content {
  @include t-mq($until: $w-booking-basket__mobile-breakpoint) {
    margin-top: $w-booking-basket__footer-content-mobile--margin-top;
    padding-bottom: $w-booking-basket__footer-content-mobile--padding-bottom;
    @include sp-inset-h($w-booking-basket__content-mobile--padding-h);
  }
}

.is-unresolved{
  .w-booking-basket__price, .w-booking-basket__extra-info, .w-booking-basket__package-block{
    @include a-unresolved();
    &::before, &::after{
      z-index: 1;
    }
  }
}