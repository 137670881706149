/* -------------------------------------------------------------------------
 * BOOKING CHANGED NOTIFICATION
 */
.w-booking-changed-notification {
  .w-booking-changed-notification--content-notification {
    .w-booking-changed-notification__title {
      margin-bottom: $w-booking-changed-notification--sp-stack;
    }

    > :not(.w-booking-changed-notification__no-content) {
      text-align: center;
    }
  }

  .w-booking-changed-notification--content-flow {
    text-align: left;

    .w-booking-changed-notification__buttons {
      @include t-mq($until: $w-booking-changed-notification__buttons--bp) {
        @include sp-stack-children($w-booking-changed-notification__sp-between);
      }

      @include t-mq($from: $w-booking-changed-notification__buttons--bp) {
        display: flex;
        flex-direction: row-reverse;

        > * {
          width: auto;
        }

        > * + * {
          margin-right: $w-booking-changed-notification__sp-between;
        }
      }
    }
  }

  .w-booking-changed-notification__icon {
    color: $w-booking-changed-notification__icon--color;
    margin-bottom: $w-booking-changed-notification--sp-stack;
    text-align: center;
  }

  .w-booking-changed-notification--failed {
    .w-booking-changed-notification__icon {
      color: $w-booking-changed-notification--failed__icon--color;
    }
  }

  .w-booking-changed-notification--succeeded {
    .w-booking-changed-notification__title, .w-booking-changed-notification__icon {
      color: $w-booking-changed-notification--succeeded__icon--color;
    }
  }
}
