.c-photo-gallery-sidebar{
    .c-photo-gallery__item-video{
        .c-photo-gallery-video-thumbnail-info-icon{
            background-color: transparent;

            &:before{
                color: $c-photo-gallery-sidebar__play-icon-color;
            }
        }

        &:hover{
            .c-photo-gallery-video-thumbnail-info-icon{
                background-color: transparent;
                &:before{
                    color: $c-photo-gallery-sidebar__play-icon-color-hover;
                }
            }
        }
    }
}