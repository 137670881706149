/* -------------------------------------------------------------------------
 * Weather table vars
 */

$c-weather-table__value--font-family:                           $f-font-family__primary !default;
$c-weather-table--border-color:                                 $f-color__neutral-grey--ultra-light !default;
$c-weather-table--font-size--bp-medium:                         $f-font-size--m !default;
$c-weather-table__month-name--short--text-transform:            unset !default;
$c-weather-table__month-name--font-family:                      $f-font-family__secondary !default;
$c-weather-table__month-name--font-weight:                      600 !default;
$c-weather-table__legend--font-weight:                          600 !default;