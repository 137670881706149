
.w-booking-optional-ancillaries-popup {
  .c-modal-v2__body-content{
    @include sp-stack-children($w-booking-optional-ancillaries-popup__body-content--spacing);
  }
 
  .w-booking-awaitable-popup__modal-footer{
    justify-content: space-between;
    max-width: unset;
    flex-direction: row;
  }
}