/* -------------------------------------------------------------------------
 * LEGEND VARS
 *
 */

$c-legend--inner-spacing:                        $f-space--small !default;
$c-legend--outer-spacing:                        $f-space--medium !default;

$c-legend__content--font-family:                 $f-font-family__secondary !default;
$c-legend__content--font-size:                   $f-font-size--l !default;
$c-legend__content--font-weight:                 700 !default;
$c-legend__content--padding:                     $f-space--tiny !default;
$c-legend__content--font-weight:                 700 !default;
$c-legend__content--border-size:                 1px !default;
$c-legend__content--border-style:                solid !default;
$c-legend__content--border-color:                $f-color__neutral-grey !default;
$c-legend__content--border-radius:               $f-radius--small !default;
$c-legend__content--size:                        $f-space--medium + $f-space--tiny !default;

