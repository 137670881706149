/* -------------------------------------------------------------------------
 * PRICE TABLE VARS
 */

$c-price-table--bp:                                                 $f-breakpoint--md !default;
$c-price-table--padding-right--from-bp:                             9px !default;

$c-price-table__navigation-wrapper--after-color:                    $e-document__body--bgcolor !default;

$c-price-table__navigation--color:                                  $e-document__body--color !default;
$c-price-table__navigation--bg-color--hover:                        $f-color__neutral-grey--ultra-light !default;
$c-price-table__navigation--border-width:                           1px !default;
$c-price-table__navigation--border-style:                           solid !default;
$c-price-table__navigation--border-color:                           $e-document__body--color !default;
$c-price-table__navigation--border-radius:                          $f-radius--rounded !default;
$c-price-table__navigation--color:                                  $e-document__body--color !default;
$c-price-table__navigation--font-size:                              $f-font-size--s !default;
$c-price-table__navigation--size:                                   22px !default;
$c-price-table__navigation--z-index:                                $f-z-index--1 !default;
$c-price-table__navigation--vertical-adjustment:                    -1px !default;
$c-price-table__navigation--horizontal-adjustment--left:            -2px !default;
$c-price-table__navigation--duration:                               $f-duration--normal !default;

$c-price-table__row--border-width:                                  1px !default;
$c-price-table__row--border-style:                                  solid !default;
$c-price-table__row--border-color:                                  $f-color__neutral-grey--semi-light !default;
$c-price-table__row--inset-v:                                       $f-space--tiny !default;
$c-price-table__row--inset-h:                                       2px !default;

$c-price-table__cell--width:                                        100px !default;
$c-price-table__cell--compact-width:                                80px !default;
$c-price-table__cell--inset-squish:                                 $f-space--small !default;
$c-price-table__cell--text-align:                                   center !default;
$c-price-table__cell--border-width:                                 1px !default;
$c-price-table__cell--border-style:                                 solid !default;
$c-price-table__cell--border-color:                                 $f-color__neutral-grey--semi-light !default;
$c-price-table__cell--bg-color--hover:                              $f-color__state-info--ultra-light !default;
$c-price-table__cell--bg-color--active:                             $f-color__state-info--semi-light !default;
$c-price-table__cell--z-index--hover:                               $f-z-index--1 !default;
$c-price-table__cell--border-width--hover:                          2px !default;
$c-price-table__cell--border-style--hover:                          solid !default;
$c-price-table__cell--border-color--hover:                          $f-color__state-info--semi-light !default;
$c-price-table__cell--onrequest-font-size:                          $f-font-size--xxs !default;
$c-price-table__cell--onrequest-color:                              $f-color__neutral-grey--semi-dark !default;
$c-price-table__cell--onrequest-color--active:                      $f-color__neutral-grey--semi-dark !default;
$c-price-table__cell--disabled-color:                               $f-color__neutral-grey !default;

$c-price-table__cell--title--text-align:                            left !default;
$c-price-table__cell--title--inset-h--until-bp:                     $f-space--small !default;
$c-price-table__cell--title--color:                                 $f-color__state-info--semi-dark !default;
$c-price-table__cell--title--font-weight:                           700 !default;
$c-price-table__cell--title--padding-left:                          ($f-space--small - $c-price-table__row--inset-h) !default;
$c-price-table__cell--title--padding-right:                         34px !default;

$c-price-table__day-name--color:                                    $f-color__neutral-grey--semi-dark !default;
$c-price-table__day-name--text-transform:                           capitalize !default;
$c-price-table__day-name--letter-spacing:                           -1 * .5px !default;
$c-price-table__day-name--sp-stack:                                 $f-space--tiny !default;

$c-price-table__day-month--font-weight:                             700 !default;
$c-price-table__day-month--letter-spacing:                          .2px !default;
$c-price-table__day-month--color:                                   $f-color__neutral-grey--semi-dark !default;
$c-price-table__day-month--font-size:                               $f-font-size--m;

$c-price-table__title-icon--font-size:                              $f-font-size--xl !default;

$c-price-table__title-text--font-size:                              $f-font-size--l !default;
$c-price-table__title-text--margin-left:                            $f-space--tiny !default;

$c-price-table__cell--participants--text-align:                     left !default;
$c-price-table__cell--participants--inset-h--until-bp:              $f-space--small !default;

$c-price-table__cell--price--color:                                 $f-color__neutral-grey--light !default;
$c-price-table__cell--price--font-size:                             1.1em !default;
$c-price-table__cell--price--font-weight:                           400 !default;

$c-price-table__participant--sp-inline:                             $f-space--tiny !default;

$c-price-table__room-info--margin-top:                              $f-space--medium !default;
$c-price-table__room-info--margin-top--first:                       $f-space--none !default;
$c-price-table__room-info--border-width:                            1px !default;
$c-price-table__room-info--border-style:                            solid !default;
$c-price-table__room-info--bg-color:                                $f-color__neutral-grey--ultra-light !default;
$c-price-table__room-info--sp-inset:                                $f-space--small !default;

$c-price-table__room-title--sp-inline:                              $f-space--tiny !default;
$c-price-table__room-title--sp-inset:                               $f-space--small !default;
$c-price-table__room-title--padding-right:                          24px !default;
$c-price-table__room-title--padding-right--from-bp:                 124px !default;
$c-price-table__room-title--color--hover:                           $e-links__hover-color !default;

$c-price-table__room-content--sp-inset-v:                           $f-space--small !default;
$c-price-table__room-content-list--sp-stack:                        $f-space--tiny !default;

$c-price-table__room-characteristic--sp-stack:                      $f-space--tiny !default;
$c-price-table__room-characteristic--font-size:                     $f-font-size--s !default;

$c-price-table__room-icon--font-size:                               map_get($m-icons__sizes, "medium") !default;
$c-price-table__room-icon--v-align:                                 -2px !default;

$c-price-table__room-trigger--color:                                $e-document__body--color !default;
$c-price-table__room-trigger-icon-font-size:                        map_get($m-icons__sizes, "small") !default;

$c-price-table__cell-selected:                                      16px !default;

$c-price-table__discount-bullet-size:                               $f-font-size--xxs !default;

$c-price-table__cell-threshold-standard--color:                     $f-color__state-success !default;
$c-price-table__title-period-extra-info--font-size:                 12px !default;

$c-price-table__footer-extra-info--color:                           $f-color__neutral-grey !default;
$c-price-table__footer-extra-info--font-style:                      italic !default;
$c-price-table__footer-extra-info--font-weight:                     400 !default;
$c-price-table__footer-extra-info--margin-top:                      $f-space--small !default;
