/* -------------------------------------------------------------------------
 * CARD
 */

.c-card {
  position: relative;
  border-radius: $c-card--border-radius;
  border: $c-card--border-width $c-card--border-style $c-card--border-color;
  box-shadow: $c-card--shadow rgba($c-card--shadow-color, $c-card--shadow-opacity);
  @include o-block__mod-alignment($c-card--alignment);

  .c-card__header {
    position: relative;
  }

  .c-card__header-label {
    position: absolute;
    top: $c-card__label--positioning-top;
    left: $c-card__label--positioning-left;
  }

  .c-card__header-label.c-card__header-label--top-right {
    top: $c-card__label--top-right--positioning-top;
    right: 0;
    left: auto;
    transform: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.c-card__title,
.c-card__title a {
  color: $c-card__title--color;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.c-card__title {
  position: relative;

  + .c-card__subtitle {
    color: $c-card__subtitle--color;
    margin-top: $c-card__subtitle--spacing;
  }
}

.c-card__title-link {
  font-weight: $c-card__title-link--font-weight;
  word-break: break-word;
}

.c-card__body {
  background-color: $c-card__body--bg-color;

  // When there is a nav--list inside a card, the inset padding looks weird.
  // With this little fix the result is visually better
  .c-nav--list {
    margin-left: $c-card__c-nav--list--margin-h;
    margin-right: $c-card__c-nav--list--margin-h;
  }
}

.c-card__body,
.c-card__footer {
  @include sp-inset($c-card--spacing-inset)
}

.c-card__footer {
  background-color: $c-card__footer--bg-color;
  padding-top: $c-card__footer--padding-top;

  .c-btn--flat,
  a.c-btn--flat {
    padding: 0;
    display: inline-block;
    width: auto;
    float: right;
  }
}

@import "c-card--top-title";
