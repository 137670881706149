/* -------------------------------------------------------------------------
 * PHOTO GALLERY
 */

.c-photo-gallery__item {
  padding: calc(#{$c-photo-gallery__item__padding} / 2);
  padding-bottom: calc(#{$c-photo-gallery__item--padding-bottom} / 2);
  margin: calc(#{$c-photo-gallery__item--margin} / 2);
  background-color: $c-photo-gallery__item--background-color;
  border: $c-photo-gallery__item--border;
  border-radius: $c-photo-gallery__item--border-radius;
  box-shadow: $c-photo-gallery__item--box-shadow;
  width: calc(((1 / #{$c-photo-gallery__items-per-row--default}) * 100%) - ((#{$c-photo-gallery__item--margin} / 2) * 2));
  transition: $c-photo-gallery__item--transition;

  .c-photo-gallery-video-thumbnail-info-icon {
    position: absolute;
    top: calc(50% - (#{$c-photo-gallery__item--margin} * 2));
    left: 50%;
    transform: translate(-50%,-50%);
  }

  @include t-mq($c-photo-gallery__breakpoint--mobile) {
    width: calc(((1 / #{$c-photo-gallery__items-per-row--default}) * 100%) - (#{$c-photo-gallery__item--margin} * 2));
    padding: $c-photo-gallery__item__padding;
    padding-bottom: $c-photo-gallery__item--padding-bottom;
    margin: $c-photo-gallery__item--margin;
  }

  @include t-mq($c-photo-gallery__breakpoint--tablet) {
    width: calc(((1 / #{$c-photo-gallery__items-per-row--tablet}) * 100%) - (#{$c-photo-gallery__item--margin} * 2));
  }

  @include t-mq($c-photo-gallery__breakpoint--desktop) {
    width: calc(((1 / #{$c-photo-gallery__items-per-row--desktop}) * 100%) - (#{$c-photo-gallery__item--margin} * 2));

    &:nth-child(even):hover {
      transform: rotate(-$c-photo-gallery__item--transform-rotate-deg);
    }

    &:nth-child(odd):hover {
      transform: rotate($c-photo-gallery__item--transform-rotate-deg);
    }
  }

  &:hover .c-img {
    box-shadow: none;
  }
}

