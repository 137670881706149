/* -------------------------------------------------------------------------
 * Media vars
 */

$c-media--bp:                                                   "sml" !default;

$c-media__img--sp-stack:                                        $f-space--medium !default;
$c-media__img--sp-inline--from-bp:                              $f-space--medium !default;
$c-media__img--width--from-bp:                                  25% !default;

$c-media__body--sp-inline--from-bp:                             $f-space--small !default;

$c-media__heading--sp-stack:                                    $f-space--small !default;

$c-media__title--sp-stack:                                      $f-space--tiny / 2 !default;

$c-media__subtitle--color:                                      $f-color__neutral-grey--semi-dark !default;
