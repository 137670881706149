/* -------------------------------------------------------------------------
 * TRANSPORT-TICKET
 */


$c-transport-ticket__detailed-info-additional--color:                         $f-color__neutral-grey !default;

$c-transport-ticket__radio--checked-bg-color:   					          $f-color__state-info--light !default;
$c-transport-ticket__checked-bg-color:          					          $f-color__state-info--ultra-light !default;
$c-transport-ticket__price--checked-color:      					          $f-color__state-info--semi-dark !default;
$c-transport-ticket__internal-flag--bg-color:                                 $f-color__state-info--dark !default;
$c-transport-ticket__detailed-info-top--color:                                $f-color__neutral-grey--semi-dark !default;
$c-transport-ticket__detailed-info-icon-line--color:                          $f-color__neutral-grey !default;
$c-transport-ticket__detailed-info-icon--color:                               $f-color__neutral-grey !default;
$c-transport-ticket__detailed-info-remark--color:                             $f-color__state-info !default;
$c-transport-ticket__price--color:                                            $f-color__brand-primary--dark !default;
$c-transport-ticket__price--font-size:                                        $f-font-size--m !default;
$c-transport-ticket-bus__airline-info--color:                                 $f-color__neutral-grey--semi-dark !default;
$c-transport-ticket-bus__detailed-info-departure--font-weight:                600 !default;
