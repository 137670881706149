
/* -------------------------------------------------------------------------
 * EASINGS
 *
 * Define easings foundation
 * The values here are for sample proposes, consider add your owns
 *
 * Extracted from [Polaris]
 * (https://github.com/Shopify/polaris/blob/master/src/styles/foundation/easing.scss)
 *
 */

// Main easing

$f-easing:                    cubic-bezier(.64, 0, .35, 1) !default;

// Easing variants

$f-easing--base:              $f-easing !default;
$f-easing--in:                cubic-bezier(.36, 0, 1, 1) !default;
$f-easing--out:               cubic-bezier(0, 0, .42, 1) !default;
$f-easing--excite:            cubic-bezier(.18, .67, .6, 1.22) !default;
$f-easing--overshoot:         cubic-bezier(.07, .28, .32, 1.22) !default;
$f-easing--anticipate:        cubic-bezier(.38, -.4, .88, .65) !default;
$f-easing--loopable:          cubic-bezier(0.5, 0.2, 0.5, 0.8) !default;
$f-easing--linear:            linear !default;


// Easings map

$f-easings: (
  "base":                     $f-easing--base,
  "in":                       $f-easing--in,
  "out":                      $f-easing--out,
  "excite":                   $f-easing--excite,
  "overshoot":                $f-easing--overshoot,
  "anticipate":               $f-easing--anticipate,
  "linear":                   $f-easing--linear,
) !default;
