/* -------------------------------------------------------------------------
 * SEARCH
 *
 */

.w-search__filters.c-side-drawer::after {
  background: transparent;
}

.w-search__top-filters {
  @extend .o-wrapper;
  @extend .l-site__wrapper--medium;
  @include t-mq($until: $f-breakpoint--sml) {
    width: 100vw;
    border-left: none;
    border-right: none;
  }
  @include t-mq($from: $f-breakpoint--lg) {
    max-width: $w-search__top-filters-width-mq-lg !important;
  }
  @include t-mq($from: $f-breakpoint--xl) {
    max-width: $w-search__top-filters-width-mq-xl !important;
  }
  .w-search__top-filters__collapse--container {
    display: flex;
  }
}

.t-searchpage .l-site-main.w-search__top-filters__body {
  @extend .l-site-main;
} 

.w-search__header-row .w-search__header-btns {
  .w-search__map-action-btn, .w-search__filters-action-btn {
    border-color: $w-search__top-search-buttons--border-color;
    color: $w-search__top-search-buttons--color;
  }

  .w-search__sort .c-dropdown__icon {
    color: $w-search__top-search-buttons--color;
  }

  .w-search__sort .c-dropdown__element {
    border-color: $w-search__top-search-buttons--border-color;
    color: $w-search__top-search-buttons--color;

    &:hover {
      border-color: $w-search__top-search-buttons--border-color;
    }
  }
}

.w-search__main-body .w-search__results .c-search-result::after {
    border: unset;
}

.w-search__sort .c-dropdown__icon {
  background-color: transparent;
}

.w-search__top-filters {
  background-color: $w-search--background-color;
}
