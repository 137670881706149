/* -------------------------------------------------------------------------
* DOWNLOAD DOCUMENTS
*
*/

.w-download-documents {
  display: flex;
  flex-direction: column;
}

.w-download-documents__container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

.w-download-documents__container:not(:empty) {
  margin: -$w-download-documents__container--gap;
}

.w-download-documents__container .c-download-button {
  width: 100%;
  margin: $w-download-documents__container--gap;

  @include t-mq($from: $w-download-documents__container--first-bp) {
    width: $w-download-documents__download-button--width-first-bp;
  }

  @include t-mq($from: $w-download-documents__container--second-bp) {
    width: $w-download-documents__download-button--width-second-bp;
  }
}

[data-w-download-documents__error-message],
[data-w-download-documents__empty-message] {
  display: none;
}

.w-download-documents__loader {
  align-self: center;
}
