@include t-mq($until: $c-product-card-breakpoint--sm) {
  .c-product-card__footer-services {
    font-size: $c-product-card__services--font-size-sm;
    grid-row-gap: $c-product-card__footer-services--row-gap;
    .c-bullet-list__icon {
      font-size: $c-product-card__bullet-list-icon--font-size-sm;
    }
  }
}

.c-product-card__usps .c-bullet-list__icon {
  color: $c-product-card__usps-icon--color;
}
