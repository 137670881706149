/* -------------------------------------------------------------------------
 * Price variables
 *
 */

// Material toggling

$m-price--enabled:                       true !default;

$m-price--font-size:                     $f-font-size--xl !default;
$m-price--font-family:                   $f-font-family__primary !default;
$m-price--font-family--crop:             $f-font-family__primary--crop !default;
$m-price--font-weight:                   700 !default;
$m-price--letter-spacing:                1px !default;
$m-price--spacing-inline:                $f-space--tiny !default;

$m-price__currency--font-size:           $f-font-size--l !default;
$m-price__currency--font-family:         $f-font-family__primary !default;
$m-price__currency--font-weight:         $m-price--font-weight !default;



// Modifiers
// -----------------------------------------------

// Small modifier
$m-price--small--font-size:               $f-font-size--m !default;
$m-price--small--font-family:             $m-price--font-family !default;
$m-price--small--letter-spacing:          $m-price--letter-spacing / 2 !default;

// Large modifier
$m-price--large--font-size:               $f-font-size--xxxl !default;
$m-price--large--font-family:             $m-price--font-family !default;

$m-price__currency--large--font-size:     $f-font-size--xl !default;
$m-price__currency--large--font-weight:   700 !default;
