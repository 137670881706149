/* -------------------------------------------------------------------------
 * CARD AVATAR
 */

.c-card-avatar {
  @extend .c-card;
}

.c-card-avatar__headings {
  @include sp-inline($c-card-avatar__headings--sp-inline);
}

.c-card-avatar__title {
  @extend .c-card__title;

  + .c-card-avatar__subtitle {
    @extend .c-card__subtitle;
  }
}

.c-card-avatar__avatar {
  width: $c-card-avatar__avatar--size;
  height: $c-card-avatar__avatar--size;
  margin-right: $c-card-avatar__avatar--sp-inline;
  border-radius: $c-card-avatar__avatar--radius;
}

.c-card-avatar__text {
  @include t-mq($until: $c-card-avatar--breakpoint-tablet) {
    font-size: $c-card-avatar__text--mobile-font-size;
  }
}
