/* -------------------------------------------------------------------------
 * BOOKING STEPS TABS
 *
 */

.is-unresolved {
  .c-steps{
    @include a-unresolved();
    &::before, &::after{
      z-index: $w-booking-steps-tabs__item__unresolved-layer-1;
    }
  }
}
