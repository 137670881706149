/* -------------------------------------------------------------------------
 * SPLASHPAGE VARS
 *
 */

$t-splashpage__fr-quick-search--bp:                               "sm" !default;
$t-splashpage__fr-quick-search--sp-stack--from-mq:                $f-space--large !default;
$t-splashpage__fr-quick-search--sp-inset-h:                       $f-space--small !default;

$t-splashpage__fr-special-promotions--rows-stack:                 $c-collection-row-expanded__item-sp-stack !default;

$fr-inspiring-images-with-header--first-child__margin-bottom:     $f-space--small !default;
$fr-inspiring-images-with-header--first-child__margin-bottom-bq:  $f-space--medium !default;
$fr-inspiring-images-with-header--promotion-image__height:        300px !default;

//TODO: [MS-StyleGuideLegacy] Remove this variable when design system is rolled out
$t-splashpage__fr-inspiring-images--sp-inset-v:                   $f-space--large !default;


