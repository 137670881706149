/* -------------------------------------------------------------------------
 * accommodation PAGE VARS
 *
 */

// Top
$t-accommodation--bp:                                                     $l-site-header--bp !default;
$t-accommodation--bp-columns:                                             "md" !default;
$t-accommodation__children--sp-stack:                                     $f-space--large !default;
$t-accommodation__main-top--sp-stack:                                     $f-space--medium  !default;

$t-accommodation__button-back--margin-left:                               -1 * $f-space--tiny  !default;
$t-accommodation__button-back--margin-top:                                -20px !default;
$t-accommodation__button-back--margin-top--mobile:                        -1 * $f-space--tiny !default;

$t-accommodation__title--sp-stack:                                        $f-space--medium !default;

$t-accommodation__collection-label--top:                                  $f-space--small + $f-space--tiny !default;
$t-accommodation__collection-label--left:                                 $f-space--small !default;
$t-accommodation__collection-label--top--from-bp:                         $f-space--medium !default;
$t-accommodation__collection-label--left--from-bp:                        $f-space--medium !default;
$t-accommodation__collection-label--z-index:                              $f-z-index--1 !default;

// Fragments
// -----------------------------------

$fr-acco-carousel--sp-stack:                                              $f-space--medium !default;
$fr-acco-carousel--margin-h--until-bp:                                    -1 * $l-site__wrapper-medium--inset !default;

$fr-acco-basic-info__heading--sp-stack:                                   $f-space--tiny !default;
$fr-acco-basic-info__name--material:                                      map_get($m-heading__mod-sizes, "medium") !default;
$fr-acco-basic-info__name--material--from-bp:                             map_get($m-heading__mod-sizes, "large") !default;
$fr-acco-basic-info__name--sp-inline:                                     $f-space--small !default;
$fr-acco-basic-info__favourite--size:                                     1.5em;
$fr-acco-basic-info__rating--font-size:                                   $f-font-size--xs !default;
$fr-acco-basic-info__rating--color:                                       $f-color__state-warning--semi-light !default;
$fr-acco-basic-info__rating--sp-stack:                                    $f-space--tiny !default;
$fr-acco-basic-info__rating-half-star--width:                             $fr-acco-basic-info__rating--font-size / 2 !default;
$fr-acco-basic-info__margin-bottom:                                       $f-space--medium !default;
$fr-acco-basic-info__margin-bottom--bp:                                   $f-space--medium + $f-space--small !default;
$fr-acco-basic-info__inset:                                               $f-space--medium !default;  

$fr-tabs-container--margin-h--until-bp:                                   - 1 * $l-site__wrapper-medium--inset !default;
$fr-tabs-container__content--sp-inset-h--until-bp:                        $f-space--none !default;
$fr-tabs-container__content--padding-top:                                 $f-space--medium !default;

$fr-acco-description--bp:                                                 "xs" !default;
$fr-acco-description__c-number-score--sp-stack--until-bp:                 $f-space--small + $f-space--tiny !default;
$fr-acco-description__c-number-score--sp-inline--from-bp:                 $f-space--small + $f-space--tiny !default;
$fr-acco-description__label--color:                                       $f-color__state-info--semi-dark !default;
$fr-acco-description__label--sp-stack:                                    $f-space--small !default;
$fr-acco-description__collapse--after-fading-color:                       $f-color__neutral-white !default;
$fr-acco-description__collapse--margin-bottom:                            $f-space--small !default;

$fr-acco-usps--icon-color:                                                $f-color__state-info !default;

$fr-acco-room-types--sp-stack:                                            $f-space--medium !default;
$fr-acco-room-types__description--sp-stack:                               $f-space--small !default;
$fr-acco-room-types__description--font-weight:                            700 !default;
$fr-acco-room-types__list-item--font-size--until-bp:                      $f-font-size--xs !default;
$fr-acco-room-types__list-item--sp-stack:                                 $f-space--tiny !default;
$fr-acco-room-types__extra-info-title--sp-stack:                          $f-space--medium !default;
$fr-acco-room-types__extra-info--margin-top:                              $f-space--medium !default;
$fr-acco-room-types__extra-info--sp-inset:                                $f-space--small !default;
$fr-acco-room-types__extra-info--sp-inset--from-bp:                       $f-space--medium !default;
$fr-acco-room-types__photo-gallery--sp-stack:                             $f-space--medium !default;

$fr-acco-campaign-details--sp-stack:                                      $f-space--medium !default;

$fr-acco-facilities--bp:                                                  $t-accommodation--bp !default;
$fr-acco-facilities__collapse--sp-stack:                                  $f-space--medium !default;
$fr-acco-facilities__collapse--min-height:                                230px !default;
$fr-acco-facilities__collapse--gradient-initial:                          $f-color__neutral-white--transparent !default;
$fr-acco-facilities__collapse--gradient-final:                            $f-color__neutral-white !default;
$fr-acco-facilities__collapse-content--bg-color:                          $f-color__neutral-grey--ultra-light !default;
$fr-acco-facilities__collapse-content--border-radius:                     $f-radius--small !default;
$fr-acco-facilities__collapse-content--sp-inset:                          $f-space--small !default;
$fr-acco-facilities__category--sp-inset:                                  $f-space--medium !default;
$fr-acco-facilities__category--sp-inset--from-bp:                         $f-space--medium + $f-space--small !default;
$fr-acco-facilities__category-after--bottom:                              -1px !default;
$fr-acco-facilities__category-after--height:                              1px !default;
$fr-acco-facilities__category-item--sp-stack:                             $f-space--tiny !default;
$fr-acco-facilities__category--border-width:                              1px !default;
$fr-acco-facilities__category--border-style:                              solid !default;
$fr-acco-facilities__category--border-color:                              $f-color__neutral-grey--semi-light !default;
$fr-acco-facilities__category--sp-stack:                                  $f-space--medium !default;
$fr-acco-facilities__category--padding-bottom:                            $f-space--medium - $fr-acco-facilities__category-item--sp-stack !default;
$fr-acco-facilities__category--sp-stack--from-bp:                         $f-space--medium + $f-space--small !default;
$fr-acco-facilities__category--padding-bottom--from-bp:                   $f-space--medium - $fr-acco-facilities__category-item--sp-stack !default;
$fr-acco-facilities__category-name--text-transform:                       uppercase !default;
$fr-acco-facilities__category-name--font-weight:                          700 !default;
$fr-acco-facilities__category-name--sp-stack:                             $f-space--small + $f-space--tiny !default;

$fr-acco-address__icon--sp-inline:                                        $f-space--small !default;

$fr-acco-practical-info__text-container--sp-stack:                        $f-space--small !default;
$fr-acco-practical-info__text-container--preview--bp:                     "sml" !default;
$fr-acco-practical-info__text-container--preview--bp-big:                 "md" !default;

$fr-acco-destination-description__slider--width--from-bp:                 percentage(1/2) !default;
$fr-acco-destination-description__slider--width--from-bp-big:             percentage(1/3) !default;
$fr-acco-destination-description__slider--sp-inline:                      $f-space--medium !default;
$fr-acco-destination-description__slider--sp-stack:                       $fr-acco-destination-description__slider--sp-inline !default;
$fr-acco-destination-description__slider--bp:                             "sml" !default;
$fr-acco-destination-description__slider--bp-big:                         "lg" !default;

$fr-acco-destination-excursion__card-title--font-size:                    $f-font-size--l !default;

$fr-reviews-overview--bq:                                                 $f-breakpoint--lg !default;
$fr-reviews-overview__main-item--vertical-rhythm:                         $f-space--large !default;

$fr-reviews-overview__main-item--gutter:                                  $f-space--medium !default;
$fr-reviews-overview__main-item--first-child--width:                      percentage(2/3) !default;
$fr-reviews-overview__main-item--first-child--border-width:               1px !default;
$fr-reviews-overview__main-item--first-child--border-style:               solid !default;
$fr-reviews-overview__main-item--first-child--border-color:               $f-color__neutral-grey--light !default;
$fr-reviews-overview__main-item--last-child--width:                       percentage(1/3) !default;

$fr-reviews-overview__c-progress-bar--sp-stack:                           $f-space--small !default;
$fr-reviews-overview__c-progress-circle--sp-inline:                       $f-space--tiny !default;
$fr-reviews-overview__c-progress-circle--sp-stack:                        $f-space--small + $f-space--tiny !default;

$fr-acco-mealplans__description--sp-stack:                                $f-space--medium  !default;

$fr-promoted-price__sticky--padding-top:                                  $f-space--small + $f-space--tiny !default;

// Highlighted facility section

$fr-acco-facilities__category-highlight--background-color:                $f-color__neutral-white  !default;
$fr-acco-facilities__category-highlight--border-width:                    1px  !default;
$fr-acco-facilities__category-highlight--border-color:                    $f-color__state-info--semi-dark  !default;
$fr-acco-facilities__category-highlight-name--color:                      $f-color__state-info--semi-dark  !default;
$fr-acco-facilities__category-highlight-icon--left:                       -$f-space--medium  !default;

// videos on photogallery when sidebar

$c-photo-gallery-sidebar__play-icon-color:                               $f-color__neutral-white !default;
$c-photo-gallery-sidebar__play-icon-color-hover:                          $f-color__neutral-grey--light !default;

// Reviews overview

$fr-reviews-overview__c-progress-bar-title--padding-right:                  $f-space--small !default;
$fr-reviews-overview__c-progress-bar-title--padding-bottom:                 $f-space--small + $f-space--tiny  !default;
$fr-reviews-overview__c-progress-bar-color-fantastic:                       $f-color__state-success--semi-dark!default;
$fr-reviews-overview__c-progress-bar-color-good:                            $f-color__state-success--semi-light!default;                               
$fr-reviews-overview__c-progress-bar-color-normal:                          $f-color__state-info--semi-light!default;
$fr-reviews-overview__c-progress-bar-color-bad:                             $f-color__state-warning!default;
$fr-reviews-overview__c-progress-bar-color-horror:                          $f-color__state-warning--dark!default;
$fr-reviews-overview__c-progress-bar-color-bg:                              #EEEEEEEE !default;

$fr-reviews-overview__c-number-score--row-gap:                              9px !default;
$fr-reviews-overview__c-number-score--padding-right:                        $f-space--medium !default;
$fr-reviews-overview__c-number-score--radius:                               $f-space--small !default;
$fr-reviews-overview__c-number-score--font-size:                            $f-font-size--s !default;
$fr-reviews-overview__c-number-score--font-weight:                          600 !default;
$fr-reviews-overview__c-number-score--padding-top:                          3px !default;
$fr-reviews-overview__c-number-score--padding-bottom:                       $f-space--tiny !default;
$fr-reviews-overview__c-number-score--padding-sides:                        9px !default;
$fr-reviews-overview__c-number-score--column-width:                         50% !default;

$fr-reviews-overview__main-score-bubble--gutter:                            $f-space--small + $f-space--tiny !default;
$fr-reviews-overview__main-score-bubble--high:                              9px !default;
$fr-reviews-overview__main-score-bubble--gutter-mobile:                     $f-space--small !default;
$fr-reviews-overview__main-score-bubble--high-mobile:                       $f-space--tiny !default;
$fr-reviews-overview__main-score-bubble--font-size:                         $f-font-size--l !default;
$fr-reviews-overview__main-score-bubble--font-size-mobile:                  $f-font-size--m !default;
$fr-reviews-overview__main-score-bubble--radius:                            $f-space--small !default;
$fr-reviews-overview__main-score-bubble--radius-mobile:                     10px !default;
$fr-reviews-overview__main-score-bubble--margin-right:                      $f-space--medium !default;

$fr-reviews-overview__main-category-label-bg:                               $f-color__state-info--ultra-light !default;
$fr-reviews-overview__main-category-label-color:                            $f-color__state-info--dark !default;
$fr-reviews-overview__main-category-label-margin-top:                       $f-space--medium !default;
$fr-reviews-overview__main-category-label-font-size:                        $f-font-size--s !default;
$fr-reviews-overview__main-category-label-label-size:                       $f-font-size--xl !default;

$l-site-main__content--c-breadcrumbs__margin-bottom:                        $f-space--medium !default;
$l-site-main__content--c-breadcrumbs__margin-bottom--bp:                    $f-space--small !default;

// No tabs

$t-accommodation__content--aside--no-tabs--background-color:                $f-color__neutral-grey--ultra-light !default;
$t-accommodation__content--aside--no-tabs--inset-h:                         $f-space--medium !default;
$fr-destination-block__content--z-index:                                    $f-z-index--2 - 1 !default;
$t-accommodation__content--aside--no-tabs--margin-top:                      16px !default;
$t-accommodation__content--aside--no-tabs--border-radius:                   16px !default;