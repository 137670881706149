/* -------------------------------------------------------------------------
 * IMG
 *
 */

// Component selector output
// --------------------------------------------------

.c-img {
  overflow: hidden;

  &.is-resolving .c-img__resolved {
    will-change: opacity;
    transition: opacity $c-img--reveal-easing $c-img--reveal-duration;
  }

  &.is-resolved .c-img__resolved {
    opacity: 1;
  }
}

.c-img__placeholder {
  filter: blur($c-img__placeholder--blur-radius);
}

.c-img__resolved {
  opacity: 0.01;
}

.c-img--sync .c-img__resolved {
  opacity: 1;
  transition: none;
  will-change: unset;
}

.c-img.is-unresolved,
.is-unresolved .c-img {
  background-color: $f-color__neutral-grey--light;
  
  img {
    display: none;
  }
  
  &::after {
    font-size: $c-img--unresolved--width;
    color: $f-color__neutral-grey;
    content: map_get($m-icon__glyphs, 'image');;
    position: absolute;
    display: block;
    left: $c-img--unresolved--left;
    top: $c-img--unresolved--top;
    transform: translate(-50%, -50%);
    @include m-icon__styles;
  }
}