/* -------------------------------------------------------------------------
 * BOOKING STEPS NAVIGATION
 *
 */

.w-booking-steps-navigation {
  display: flex;
  justify-content: center;
  &.is-unresolved {
    .c-btn{
      @include a-unresolved($_border-radius: $w-booking-steps-navigation--unresolved-border-radius);
      &::before, &::after{
        z-index: 1;
      }
    }
  }
}

.w-booking-steps-navigation.is-loading {
  opacity: $w-booking-steps-navigation--opacity--is-loading;
  pointer-events: none;
}

.w-booking-steps-navigation.has-error {
  display: none;
}

