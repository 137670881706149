/* -------------------------------------------------------------------------
 * ACCO NAV
 *
 */

$w-acco-nav--swipe-btn__top:                                                    4px !default;

$w-acco-nav--item__inset-h:                                                     $f-space--small !default;
$w-acco-nav--item-link__padding-top:                                            $f-space--small + $f-space--tiny !default;
$w-acco-nav--item-link__padding-bottom:                                         $f-space--small + $f-space--tiny - $w-acco-nav--swipe-btn__top !default;

$w-acco-nav__min-height:                                                        53px !default;

$w-acco-nav--wrapper__min-height:                                               $w-acco-nav__min-height !default;

$w-acco-nav__mb:                                                                $f-space--small !default;

$w-acco-nav--menu__min-height:                                                  $w-acco-nav__min-height !default;
$w-acco-nav--nav-list__height:                                                  $w-acco-nav__min-height !default;
$w-acco-nav--menu__bc:                                                          $f-color__neutral-white !default;
$w-acco-nav--menu__border-bottom-color:                                         $f-color__neutral-grey--light !default;
$w-acco-nav--menu__border-bottom-height:                                        2px !default;

$w-acco-nav--item__border-bottom-height:                                        2px !default;
$w-acco-nav--item__border-bottom-color:                                         transparent !default;
$w-acco-nav--item__border-bottom-duration:                                      $f-duration--normal !default;
$w-acco-nav--item__border-bottom-easing:                                        $f-easing--linear !default;

$w-acco-nav--item__hover-color:                                                 $f-color__brand-primary !default;

$anchor-item-tool__scroll-margin-top:                                           70px !default;
