/* -------------------------------------------------------------------------
 * WRAPPER MIXINS
 *
 */

// Object selector output
// --------------------------------------------------

@if ($o-wrapper--enabled) {
  .o-wrapper {
    @include o-wrapper;
  }
}


// Size modifiers (from)
// --------------------------------------------------

@if ($o-wrapper--enabled and $o-wrapper__mod-bp-from--enabled) {
  @each $_bp-name, $_bp-size in $o-wrapper__mod-bp {
    .o-wrapper--from#{s-core-string-breakpoint($_bp-name)} {
      @include o-wrapper--from($_bp-name);
    }
  }
}


// Size modifiers (to)
// --------------------------------------------------

@if ($o-wrapper--enabled and $o-wrapper__mod-bp-to--enabled) {
  @each $_bp-name, $_bp-size in $o-wrapper__mod-bp {
    .o-wrapper--to#{s-core-string-breakpoint($_bp-name)} {
      @include o-wrapper--to($_bp-name);
    }
  }
}
