/* -------------------------------------------------------------------------
* DD Grid Variables
*
*/

$w-dd-grid--bp:                                                 $f-breakpoint--md !default;
$w-dd-grid--font-family:                                        $f-font-family__primary !default;

$w-dd-grid__panorama--padding:                                  $f-space--small + $f-space--tiny !default;

$w-dd-grid__cell--padding:                                      $f-space--tiny !default;
$w-dd-grid__cell--height:                                       $f-space--large !default;
$w-dd-grid__cell--color:                                        $f-color__neutral-grey--dark !default;
$w-dd-grid__cell--bg-color:                                     $e-document__body--bgcolor !default;
$w-dd-grid__cell--border-radius:                                $f-radius--medium !default;
$w-dd-grid__cell--border-size:                                  1px !default;
$w-dd-grid__cell--border-style:                                 solid !default;
$w-dd-grid__cell--border-color:                                 $f-color__neutral-grey--semi-light !default;
$w-dd-grid__cell--disabled-color:                               $f-color__neutral-grey !default;

$w-dd-grid__cell--color--hover:                                 $f-color__state-info--semi-dark !default;
$w-dd-grid__cell--bg-color--hover:                              $f-color__state-info--ultra-light !default;
$w-dd-grid__cell--border-color--hover:                          $f-color__state-info--semi-dark !default;
$w-dd-grid__cell--width-size--hover:                            calc(100% + #{2 * $w-dd-grid__cell--border-size}) !default;
$w-dd-grid__cell--height-size--hover:                           calc(100% + #{2 * $w-dd-grid__cell--border-size}) !default;

$w-dd-grid__cell--color--selected:                              $f-color__neutral-white !default;
$w-dd-grid__cell--bg-color--selected:                           $f-color__state-info--semi-dark !default;
$w-dd-grid__cell--border-color--selected:                       $f-color__state-info--semi-dark !default;
$w-dd-grid__cell--bg-color-header--selected:                    $f-color__state-info--ultra-light !default;
$w-dd-grid__cell--color-header--selected:                       $f-color__state-info--semi-dark !default;

$w-dd-grid__cell--color--active:                                $f-color__neutral-white !default;
$w-dd-grid__cell--bg-color--active:                             $f-color__state-info--dark !default;

$w-dd-grid__cell--header--bg-color:                             $f-color__neutral-grey--ultra-light !default;
$w-dd-grid__cell--header-corner--bg-color:                      $f-color__neutral-grey--light !default;
$w-dd-grid__cell--header-corner--font-size:                     $f-font-size--s !default;
$w-dd-grid__cell--header-corner--font-weight:                   normal !default;

$w-dd-grid__cell--header--color:                                $e-document__body--color !default;
$w-dd-grid__cell--discrete--color:                              $f-color__neutral-grey--semi-dark !default;
$w-dd-grid__cell--discrete--font-size:                          $f-font-size--xs !default;
$w-dd-grid__cell--discrete--margin-bottom:                      2px !default;

$w-dd-grid__cell-threshold-standard--color:                     $f-color__state-success !default;

$w-dd-grid__header--margin-bottom:                              $f-space--medium !default;

$w-dd-grid__navigation--color:                                  $e-document__body--color !default;
$w-dd-grid__navigation--duration:                               $f-duration--normal !default;
$w-dd-grid__navigation--size:                                   22px !default;
$w-dd-grid__navigation--border-width:                           1px !default;
$w-dd-grid__navigation--border-style:                           solid !default;
$w-dd-grid__navigation--border-color:                           $e-document__body--color !default;
$w-dd-grid__navigation--border-radius:                          $f-radius--rounded !default;
$w-dd-grid__navigation--bg-color--hover:                        $f-color__neutral-grey--ultra-light !default;
$w-dd-grid__navigation--vertical-adjustment:                    -1px !default;
$w-dd-grid__navigation--horizontal-adjustment--left:            -2px !default;
$w-dd-grid__navigation--font-size:                              $f-font-size--xs !default;
$w-dd-grid__navigation-button--sp-inset-h:                      $f-space--small !default;
$w-dd-grid__navigation-title--color:                            $f-color__neutral-grey--semi-dark !default;

$w-dd-grid__navigation--button-size:                            $f-font-size--xxl !default;

$w-dd-grid__cell--onrequest-font-size:                          $f-font-size--xxs !default;
$w-dd-grid__cell--onrequest-color:                              $f-color__neutral-grey--semi-dark !default;
$w-dd-grid__cell--onrequest-color--active:                      $f-color__neutral-grey--semi-dark !default;
$w-dd-grid__onrequest--text-transform:                          uppercase !default;

$w-dd-grid__discount-bullet-size:                               $f-font-size--xxs !default;

$w-dd-grid__footer-wrapper--margin-top:                         $f-space--tiny !default;

$w-dd-grid__footer-caption--max-width:                          440px !default;

$w-dd-grid__footer-caption-title--font-weight:                  700 !default;
$w-dd-grid__footer-caption-title--color:                        $f-color__state-success !default;
$w-dd-grid__footer-caption-title--margin-bottom:                4px !default;

$w-dd-grid__footer-caption-description--color:                  $f-color__neutral-grey !default;

$w-dd-grid__wrapper-after--background-color:                    transparent !default;
$w-dd-grid__wrapper-after--border:                              1px solid $f-color__neutral-grey--light !default;

$w-dd-grid__legend_item_description--text-transform:            uppercase !default;

$w-dd-grid__footer-extra-info--color:                           $f-color__neutral-grey !default;
$w-dd-grid__footer-extra-info--font-style:                      italic !default;
$w-dd-grid__footer-extra-info--font-weight:                     400 !default;
$w-dd-grid__footer-extra-info--margin-top:                      $f-space--small !default;
