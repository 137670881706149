/* -------------------------------------------------------------------------
 * Booking coupon code -list VARS
 *
 */

$w-booking-coupon-code__main-container--medium-width:                                                     percentage(1/2) !default;
$w-booking-coupon-code__main-container--full-width:                                                       100% !default;
$w-booking-coupon-code__main-container--top-margin:                                                       -1 * $f-space--small !default;
$w-booking-coupon-code__main-container--left-margin:                                                      -1 * $f-space--small !default;

$w-booking-coupon-code--breakpoint-tablet:                                                                $f-breakpoint--sm !default;
$w-booking-coupon-code--breakpoint-desktop:                                                               $f-breakpoint--lg !default;

// Success
$w-booking-coupon-code--success--color:                                                                   $f-color__state-success !default;

// Error
$w-booking-coupon-code--error--color:                                                                     $f-color__state-danger !default;

