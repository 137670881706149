/* -------------------------------------------------------------------------
* Product Map
*
*/

.w-product-map {
  .c-product-card__breadcrumbs::after {
    @include t-mq($from: $w-product-map--breakpoint-mobile) {
      border-bottom: none;
    }
  }
  
  .c-product-card .c-card__title-link {
    font-family: $w-product-map__card-title--font-family;
    font-size: $w-product-map__card-title--font-size;
    font-weight: $w-product-map__card-title--font-weight;
  }
}

