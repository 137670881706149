// ACCORDION VARS
// ---------------------------------

$c-accordion--bp:                                           "sml" !default;

$c-accordion--sp-inset:                                     $f-space--medium !default;
$c-accordion--sp-inset--until-bp:                           $f-space--small !default;

$c-accordion__item--border-width:                           2px !default;
$c-accordion__item--border-style:                           solid !default;
$c-accordion__item--border-color:                           $f-color__neutral-grey--ultra-light !default;

$c-accordion__item-heading--color--hover:                   $f-color__neutral-grey--semi-dark !default;
$c-accordion__item-heading--duration:                       $f-duration--normal !default;

$c-accordion__item-title--font-weight:                      inherit !default;
$c-accordion__item-title--text-transform:                   uppercase !default;
$c-accordion__item-title--padding-right:                    $f-space--medium + $f-space--small !default;

$c-accordion__item-trigger--size:                           23px !default;
$c-accordion__item-trigger--bg-color:                       $f-color__state-info !default;
$c-accordion__item-trigger--bg-color--hover:                $f-color__state-info--semi-dark !default;
$c-accordion__item-trigger--color:                          $f-color__neutral-white !default;
$c-accordion__item-trigger--border-radius:                  $f-radius--ellipsis !default;
$c-accordion__item-trigger--sp-inset:                       5px !default;
$c-accordion__item-trigger--icon-size:                      12px !default;

$c-accordion__item-title-icon--size:                        map_get($m-icons__sizes, "medium")/1em * $s-core__font-size !default;

$c-accordion__item-content--padding-top:                    $f-space--tiny !default;

// SMALL
// ---------------------------------

$c-accordion--sp-inset--small:                              $f-space--small !default;
$c-accordion--sp-inset--until-bp--small:                    $f-space--tiny !default;

$c-accordion__item--border-width--small:                    1px !default;

$c-accordion__item-title--text-transform--small:            none !default;
$c-accordion__item-title--padding-right--small:             $f-space--tiny + $f-space--small !default;

$c-accordion__item-trigger--bg-color--small:                transparent !default;
$c-accordion__item-trigger--color--small:                   $f-color__state-info !default;
$c-accordion__item-trigger--color--hover--small:            $f-color__state-info--semi-dark !default;

$c-accordion__content--color--small:                        $f-color__neutral-grey--semi-dark !default;

$c-accordion__item-title-icon--size--small:                 $s-core__font-size !default;

// SUBTITLE
// ---------------------------------

$c-accordion__item-subtitle--font-weight:                   700 !default;
$c-accordion__item-subtitle--margin-top:                    $f-space--tiny !default;