/* -------------------------------------------------------------------------
 * Z-INDEXES
 *
 * Foundation z-indexes as border-z-index
 *
 */

// Utilities output
// --------------------------------------------------

@if ($u-z-index--enabled) {
  @each $_z-index__name, $_z-index__value in $u-z-index__map {
    .u-z-index--#{$_z-index__name} {
      z-index: $_z-index__value !important;
    }
  }
}
