/* -------------------------------------------------------------------------
 * INLINE MIXINS
 *
 */

// Space as a mixin
// --------------------------------------------------

@mixin sp-inline($_spacing-value) {
  margin-right: $_spacing-value;
}
