/* -------------------------------------------------------------------------
 * BOOKING BASKET VARS
 *
 */

$w-booking-basket--max-width:                                   30% !default;

$w-booking-basket__content--margin-bottom:                      $f-space--medium !default;

$w-booking-basket--padding-sm:                                  $f-space--small + $f-space--tiny !default;

$w-booking-basket__image--sp-stack:                             $f-space--medium !default;

$w-booking-basket__rating--color:                               $f-color__state-warning--semi-light !default;
$w-booking-basket__rating--sp-stack:                            $f-space--tiny !default;

$w-booking-basket__acco-name--sp-stack:                         $f-space--tiny !default;
$w-booking-basket__acco-name--font-size-mq:                     $f-font-size--m !default;

$w-booking-basket__breadcrumbs--sp-stack:                       $f-space--medium !default;
$w-booking-basket__breadcrumbs--color:                          $f-color__neutral-grey--semi-dark !default;

$w-booking-basket__package-block--sp-stack:                     $f-space--medium !default;
$w-booking-basket__message-margin-bottom:                       $f-space--medium !default;
$w-booking-message__box-message__text-padding:                  $f-space--small !default;

$w-booking-basket__price-total--font-size:                      $f-font-size--xl !default;
$w-booking-basket__price-total--color:                          $f-color__state-info !default;
$w-booking-basket__price-total--sp-stack:                       $f-space--tiny !default;
$w-booking-basket__price-total-amount--font-weight:             900 !default;
$w-booking-basket__price-total-rebooking-margin-bottom:         $f-space--medium !default;

$w-booking-basket__price-total-person--font-size:               $f-font-size--xl !default;
$w-booking-basket__price-total-person--color:                   $f-color__state-info !default;
$w-booking-basket__price-total-person--sp-stack:                $f-space--tiny !default;

$w-booking-basket__price-title--font-size:                      $f-font-size--m !default;
$w-booking-basket__price-title--color:                          $f-color__state-info !default;
$w-booking-basket__price-title--sp-stack:                       $f-space--tiny !default;
$w-booking-basket__price-title-amount--font-weight:             700 !default;
$w-booking-basket__price-title--text:                           $f-font-size--m !default;
$w-booking-basket__price-title--margin-bottom:                  $f-space--small !default;


$w-booking-basket__price-package--color:                        $f-color__neutral-black !default;
$w-booking-basket__price-package--sp-stack:                     $f-space--small !default;
$w-booking-basket__price-package-amount--font-weight:           700 !default;
$w-booking-basket__price-package-person--font-size:             $f-font-size--xs !default;
$w-booking-basket__price-package-person-amount--font-weight:    100 !default;

$w-booking-basket__price-paid--margin-bottom:                   $f-space--medium !default;
$w-booking-basket__price-paidamount--font-size:                 $f-font-size--xl !default;
$w-booking-basket__price-paidamount--color:                     $f-color__state-info !default;
$w-booking-basket__price-paidamount--sp-stack:                  $f-space--tiny !default;

$w-booking-basket__price-total-person--text--font-size:         $f-font-size--s !default;
$w-booking-basket__price-total-person-amount--font-size:        $f-font-size--l !default;
$w-booking-basket__price-total-person-amount--font-weight:      900 !default; 

$w-booking-basket__extra-info--background-color:                $f-color__state-info--light !default;
$w-booking-basket__extra-info--font-size:                       $f-font-size--s !default;
$w-booking-basket__extra-info--margin:                          -1 * $w-booking-basket--padding-sm !default;
$w-booking-basket__extra-info--padding:                         $f-space--small !default;
$w-booking-basket__extra-info--padding-h:                         $w-booking-basket--padding-sm !default;
$w-booking-basket__extra-info--sp-stack:                        $f-space--medium !default;

$w-booking-basket__services--collapse-btn--height:              $f-space--small !default;
$w-booking-basket__services--collapse-btn__icon--color:         $f-color__neutral-black  !default;
$w-booking-basket__services--collapse-btn__icon--font-size:     $f-font-size--xs !default;
$w-booking-basket__services--collapse-btn__icon--height:        $f-space--small !default;
$w-booking-basket__services--collapse-btn__icon--padding-left:  $f-space--small !default;

$w-booking-basket__services-item--border-top:                   1px !default;
$w-booking-basket__services-item--border-top-color:             $f-color__neutral-grey--semi-light !default;
$w-booking-basket__services-item--padding-top:                  $f-space--small !default;
$w-booking-basket__services-item--sp-stack:                     $f-space--tiny !default;
$w-booking-basket__services-item--border-top-style:             solid !default;

$w-booking-basket__services--header--sp-stack:                  $f-space--small !default;
$w-booking-basket__services--header-icon--sp-inline:            $f-space--tiny !default;

$w-booking-basket__services--lines--padding:                    $f-space--medium !default;
$w-booking-basket__services--lines--margin-bottom:              $f-space--small !default;
$w-booking-basket__services--lines--font-size:                  $f-font-size--xs !default;
$w-booking-basket__services--lines__is-title--font-size:        $f-font-size--s !default;
$w-booking-basket__lines-item-total-price--font-size:           $f-font-size--s !default;  
$w-booking-basket__lines-item--margin-right:                    $f-space--tiny !default;

$w-booking-basket__mobile-breakpoint:                           $f-breakpoint--md !default;
$w-booking-basket__desktop-small--font-size:                    $f-font-size--l !default;
$w-booking-basket__mobile-small--font-size:                     $f-font-size--s !default;
$w-booking-basket__desktop--breakpoint:                         $f-breakpoint--lg !default;
$w-booking-basket__mobile-footer--margin-top-bottom:            $f-space--tiny + $f-space--small !default;
$w-booking-basket__mobile-footer--margin-h:                     $f-space--small !default;

$w-booking-basket__side-drawer--top:                            15% !default;
$w-booking-basket__side-drawer--height:                         calc(100% - 15%) !default;
$w-booking-basket__side-drawer--width:                          100% !default;
$w-booking-basket__services--side-drawer--font-size:            $f-font-size--m !default;

$w-booking-basket__content-mobile--padding-bottom:              $f-space--small !default;
$w-booking-basket__content-mobile--padding-h:                   $f-space--medium !default;
$w-booking-basket__package-block--departure-font-size-mq:       $f-font-size--xs !default;
$w-booking-basket__package-block--filters-font-size-mq:         $f-font-size--xxs !default;

$w-booking-basket__footer-content-mobile--margin-top:           $f-space--medium !default;
$w-booking-basket__footer-content-mobile--padding-bottom:       $f-space--medium !default;

$w-booking-basket__image--margin-h:                             $w-booking-basket--padding-sm !default;
$w-booking-basket__image--width-mq:                             40% !default;

$w-booking-basket__mobile-footer--width:                        100% !default;
$w-booking-basket__mobile-footer--z-index:                      map_get($f-z-indexes--usage, "w-booking-basket") !default;

$w-booking-basket__side-drawer--header--padding:                $f-space--small !default;
$w-booking-basket__side-drawer--header__btn-icon--color:        $f-color__neutral-black !default;

$w-booking-basket__side-drawer--body_content--padding-bottom:   $f-space--large !default;

$w-booking-basket__services--side-drawer-btn--height:           $f-space--small !default;
$w-booking-basket__services--side-drawer-btn--margin-top:       -1 * $f-space--tiny !default;
$w-booking-basket__services--side-drawer-btn--margin-left:      $f-space--small !default;
$w-booking-basket__services--side-drawer-btn__icon--color:      $f-color__state-info !default;
$w-booking-basket__services--side-drawer-btn__icon--padding:    $f-space--small !default;

$w-booking-basket__lines-item--title--font-size:                $f-font-size--s !default;
$w-booking-basket__lines-item--title--font-weight:              700 !default;
$w-booking-basket__lines-item--title--margin-bottom:            $f-space--tiny !default;
$w-booking-basket__lines-item--title--margin-top:               $f-space--small !default;