/* -------------------------------------------------------------------------
 * BOOKING BACK BUTTON VARS
 *
 */
 $w-booking-back-button--sp-stack:                              $f-space--tiny !default;
 $w-booking-back-button__btn--hover-color:                      $f-color__state-info !default;
 $w-booking-back-button__btn-icon--margin-top:                  0 !default;

// is-unresolved
$w-booking-back-button--unresolved-border-radius:               $f-radius--medium !default;
