/* -------------------------------------------------------------------------
* Phone input country flags
*
*/


.m-country-flag[data-m-country-flag__country-id="be"] {
  width: 18px;
}

.m-country-flag[data-m-country-flag__country-id="ch"] {
  width: 15px;
  min-width: 15px;
}

.m-country-flag[data-m-country-flag__country-id="mc"] {
  width: 19px;
}

.m-country-flag[data-m-country-flag__country-id="ne"] {
  width: 18px;
}

.m-country-flag[data-m-country-flag__country-id="np"] {
  width: 13px;
}

.m-country-flag[data-m-country-flag__country-id="va"] {
  width: 15px;
}

.m-country-flag[data-m-country-flag__country-id="ac"] {
  height: 10px;
  background-position: 0 0;
}

.m-country-flag[data-m-country-flag__country-id="ad"] {
  height: 14px;
  background-position: -22px 0;
}

.m-country-flag[data-m-country-flag__country-id="ae"] {
  height: 10px;
  background-position: -44px 0;
}

.m-country-flag[data-m-country-flag__country-id="af"] {
  height: 14px;
  background-position: -66px 0;
}

.m-country-flag[data-m-country-flag__country-id="ag"] {
  height: 14px;
  background-position: -88px 0;
}

.m-country-flag[data-m-country-flag__country-id="ai"] {
  height: 10px;
  background-position: -110px 0;
}

.m-country-flag[data-m-country-flag__country-id="al"] {
  height: 15px;
  background-position: -132px 0;
}

.m-country-flag[data-m-country-flag__country-id="am"] {
  height: 10px;
  background-position: -154px 0;
}

.m-country-flag[data-m-country-flag__country-id="ao"] {
  height: 14px;
  background-position: -176px 0;
}

.m-country-flag[data-m-country-flag__country-id="aq"] {
  height: 14px;
  background-position: -198px 0;
}

.m-country-flag[data-m-country-flag__country-id="ar"] {
  height: 13px;
  background-position: -220px 0;
}

.m-country-flag[data-m-country-flag__country-id="as"] {
  height: 10px;
  background-position: -242px 0;
}

.m-country-flag[data-m-country-flag__country-id="at"] {
  height: 14px;
  background-position: -264px 0;
}

.m-country-flag[data-m-country-flag__country-id="au"] {
  height: 10px;
  background-position: -286px 0;
}

.m-country-flag[data-m-country-flag__country-id="aw"] {
  height: 14px;
  background-position: -308px 0;
}

.m-country-flag[data-m-country-flag__country-id="ax"] {
  height: 13px;
  background-position: -330px 0;
}

.m-country-flag[data-m-country-flag__country-id="az"] {
  height: 10px;
  background-position: -352px 0;
}

.m-country-flag[data-m-country-flag__country-id="ba"] {
  height: 10px;
  background-position: -374px 0;
}

.m-country-flag[data-m-country-flag__country-id="bb"] {
  height: 14px;
  background-position: -396px 0;
}

.m-country-flag[data-m-country-flag__country-id="bd"] {
  height: 12px;
  background-position: -418px 0;
}

.m-country-flag[data-m-country-flag__country-id="be"] {
  height: 15px;
  background-position: -440px 0;
}

.m-country-flag[data-m-country-flag__country-id="bf"] {
  height: 14px;
  background-position: -460px 0;
}

.m-country-flag[data-m-country-flag__country-id="bg"] {
  height: 12px;
  background-position: -482px 0;
}

.m-country-flag[data-m-country-flag__country-id="bh"] {
  height: 12px;
  background-position: -504px 0;
}

.m-country-flag[data-m-country-flag__country-id="bi"] {
  height: 12px;
  background-position: -526px 0;
}

.m-country-flag[data-m-country-flag__country-id="bj"] {
  height: 14px;
  background-position: -548px 0;
}

.m-country-flag[data-m-country-flag__country-id="bl"] {
  height: 14px;
  background-position: -570px 0;
}

.m-country-flag[data-m-country-flag__country-id="bm"] {
  height: 10px;
  background-position: -592px 0;
}

.m-country-flag[data-m-country-flag__country-id="bn"] {
  height: 10px;
  background-position: -614px 0;
}

.m-country-flag[data-m-country-flag__country-id="bo"] {
  height: 14px;
  background-position: -636px 0;
}

.m-country-flag[data-m-country-flag__country-id="bq"] {
  height: 14px;
  background-position: -658px 0;
}

.m-country-flag[data-m-country-flag__country-id="br"] {
  height: 14px;
  background-position: -680px 0;
}

.m-country-flag[data-m-country-flag__country-id="bs"] {
  height: 10px;
  background-position: -702px 0;
}

.m-country-flag[data-m-country-flag__country-id="bt"] {
  height: 14px;
  background-position: -724px 0;
}

.m-country-flag[data-m-country-flag__country-id="bv"] {
  height: 15px;
  background-position: -746px 0;
}

.m-country-flag[data-m-country-flag__country-id="bw"] {
  height: 14px;
  background-position: -768px 0;
}

.m-country-flag[data-m-country-flag__country-id="by"] {
  height: 10px;
  background-position: -790px 0;
}

.m-country-flag[data-m-country-flag__country-id="bz"] {
  height: 14px;
  background-position: -812px 0;
}

.m-country-flag[data-m-country-flag__country-id="ca"] {
  height: 10px;
  background-position: -834px 0;
}

.m-country-flag[data-m-country-flag__country-id="cc"] {
  height: 10px;
  background-position: -856px 0;
}

.m-country-flag[data-m-country-flag__country-id="cd"] {
  height: 15px;
  background-position: -878px 0;
}

.m-country-flag[data-m-country-flag__country-id="cf"] {
  height: 14px;
  background-position: -900px 0;
}

.m-country-flag[data-m-country-flag__country-id="cg"] {
  height: 14px;
  background-position: -922px 0;
}

.m-country-flag[data-m-country-flag__country-id="ch"] {
  height: 15px;
  background-position: -944px 0;
}

.m-country-flag[data-m-country-flag__country-id="ci"] {
  height: 14px;
  background-position: -961px 0;
}

.m-country-flag[data-m-country-flag__country-id="ck"] {
  height: 10px;
  background-position: -983px 0;
}

.m-country-flag[data-m-country-flag__country-id="cl"] {
  height: 14px;
  background-position: -1005px 0;
}

.m-country-flag[data-m-country-flag__country-id="cm"] {
  height: 14px;
  background-position: -1027px 0;
}

.m-country-flag[data-m-country-flag__country-id="cn"] {
  height: 14px;
  background-position: -1049px 0;
}

.m-country-flag[data-m-country-flag__country-id="co"] {
  height: 14px;
  background-position: -1071px 0;
}

.m-country-flag[data-m-country-flag__country-id="cp"] {
  height: 14px;
  background-position: -1093px 0;
}

.m-country-flag[data-m-country-flag__country-id="cr"] {
  height: 12px;
  background-position: -1115px 0;
}

.m-country-flag[data-m-country-flag__country-id="cu"] {
  height: 10px;
  background-position: -1137px 0;
}

.m-country-flag[data-m-country-flag__country-id="cv"] {
  height: 12px;
  background-position: -1159px 0;
}

.m-country-flag[data-m-country-flag__country-id="cw"] {
  height: 14px;
  background-position: -1181px 0;
}

.m-country-flag[data-m-country-flag__country-id="cx"] {
  height: 10px;
  background-position: -1203px 0;
}

.m-country-flag[data-m-country-flag__country-id="cy"] {
  height: 13px;
  background-position: -1225px 0;
}

.m-country-flag[data-m-country-flag__country-id="cz"] {
  height: 14px;
  background-position: -1247px 0;
}

.m-country-flag[data-m-country-flag__country-id="de"] {
  height: 12px;
  background-position: -1269px 0;
}

.m-country-flag[data-m-country-flag__country-id="dg"] {
  height: 10px;
  background-position: -1291px 0;
}

.m-country-flag[data-m-country-flag__country-id="dj"] {
  height: 14px;
  background-position: -1313px 0;
}

.m-country-flag[data-m-country-flag__country-id="dk"] {
  height: 15px;
  background-position: -1335px 0;
}

.m-country-flag[data-m-country-flag__country-id="dm"] {
  height: 10px;
  background-position: -1357px 0;
}

.m-country-flag[data-m-country-flag__country-id="do"] {
  height: 13px;
  background-position: -1379px 0;
}

.m-country-flag[data-m-country-flag__country-id="dz"] {
  height: 14px;
  background-position: -1401px 0;
}

.m-country-flag[data-m-country-flag__country-id="ea"] {
  height: 14px;
  background-position: -1423px 0;
}

.m-country-flag[data-m-country-flag__country-id="ec"] {
  height: 14px;
  background-position: -1445px 0;
}

.m-country-flag[data-m-country-flag__country-id="ee"] {
  height: 13px;
  background-position: -1467px 0;
}

.m-country-flag[data-m-country-flag__country-id="eg"] {
  height: 14px;
  background-position: -1489px 0;
}

.m-country-flag[data-m-country-flag__country-id="eh"] {
  height: 10px;
  background-position: -1511px 0;
}

.m-country-flag[data-m-country-flag__country-id="er"] {
  height: 10px;
  background-position: -1533px 0;
}

.m-country-flag[data-m-country-flag__country-id="es"] {
  height: 14px;
  background-position: -1555px 0;
}

.m-country-flag[data-m-country-flag__country-id="et"] {
  height: 10px;
  background-position: -1577px 0;
}

.m-country-flag[data-m-country-flag__country-id="eu"] {
  height: 14px;
  background-position: -1599px 0;
}

.m-country-flag[data-m-country-flag__country-id="fi"] {
  height: 12px;
  background-position: -1621px 0;
}

.m-country-flag[data-m-country-flag__country-id="fj"] {
  height: 10px;
  background-position: -1643px 0;
}

.m-country-flag[data-m-country-flag__country-id="fk"] {
  height: 10px;
  background-position: -1665px 0;
}

.m-country-flag[data-m-country-flag__country-id="fm"] {
  height: 11px;
  background-position: -1687px 0;
}

.m-country-flag[data-m-country-flag__country-id="fo"] {
  height: 15px;
  background-position: -1709px 0;
}

.m-country-flag[data-m-country-flag__country-id="fr"] {
  height: 14px;
  background-position: -1731px 0;
}

.m-country-flag[data-m-country-flag__country-id="ga"] {
  height: 15px;
  background-position: -1753px 0;
}

.m-country-flag[data-m-country-flag__country-id="gb"] {
  height: 10px;
  background-position: -1775px 0;
}

.m-country-flag[data-m-country-flag__country-id="gd"] {
  height: 12px;
  background-position: -1797px 0;
}

.m-country-flag[data-m-country-flag__country-id="ge"] {
  height: 14px;
  background-position: -1819px 0;
}

.m-country-flag[data-m-country-flag__country-id="gf"] {
  height: 14px;
  background-position: -1841px 0;
}

.m-country-flag[data-m-country-flag__country-id="gg"] {
  height: 14px;
  background-position: -1863px 0;
}

.m-country-flag[data-m-country-flag__country-id="gh"] {
  height: 14px;
  background-position: -1885px 0;
}

.m-country-flag[data-m-country-flag__country-id="gi"] {
  height: 10px;
  background-position: -1907px 0;
}

.m-country-flag[data-m-country-flag__country-id="gl"] {
  height: 14px;
  background-position: -1929px 0;
}

.m-country-flag[data-m-country-flag__country-id="gm"] {
  height: 14px;
  background-position: -1951px 0;
}

.m-country-flag[data-m-country-flag__country-id="gn"] {
  height: 14px;
  background-position: -1973px 0;
}

.m-country-flag[data-m-country-flag__country-id="gp"] {
  height: 14px;
  background-position: -1995px 0;
}

.m-country-flag[data-m-country-flag__country-id="gq"] {
  height: 14px;
  background-position: -2017px 0;
}

.m-country-flag[data-m-country-flag__country-id="gr"] {
  height: 14px;
  background-position: -2039px 0;
}

.m-country-flag[data-m-country-flag__country-id="gs"] {
  height: 10px;
  background-position: -2061px 0;
}

.m-country-flag[data-m-country-flag__country-id="gt"] {
  height: 13px;
  background-position: -2083px 0;
}

.m-country-flag[data-m-country-flag__country-id="gu"] {
  height: 11px;
  background-position: -2105px 0;
}

.m-country-flag[data-m-country-flag__country-id="gw"] {
  height: 10px;
  background-position: -2127px 0;
}

.m-country-flag[data-m-country-flag__country-id="gy"] {
  height: 12px;
  background-position: -2149px 0;
}

.m-country-flag[data-m-country-flag__country-id="hk"] {
  height: 14px;
  background-position: -2171px 0;
}

.m-country-flag[data-m-country-flag__country-id="hm"] {
  height: 10px;
  background-position: -2193px 0;
}

.m-country-flag[data-m-country-flag__country-id="hn"] {
  height: 10px;
  background-position: -2215px 0;
}

.m-country-flag[data-m-country-flag__country-id="hr"] {
  height: 10px;
  background-position: -2237px 0;
}

.m-country-flag[data-m-country-flag__country-id="ht"] {
  height: 12px;
  background-position: -2259px 0;
}

.m-country-flag[data-m-country-flag__country-id="hu"] {
  height: 10px;
  background-position: -2281px 0;
}

.m-country-flag[data-m-country-flag__country-id="ic"] {
  height: 14px;
  background-position: -2303px 0;
}

.m-country-flag[data-m-country-flag__country-id="id"] {
  height: 14px;
  background-position: -2325px 0;
}

.m-country-flag[data-m-country-flag__country-id="ie"] {
  height: 10px;
  background-position: -2347px 0;
}

.m-country-flag[data-m-country-flag__country-id="il"] {
  height: 15px;
  background-position: -2369px 0;
}

.m-country-flag[data-m-country-flag__country-id="im"] {
  height: 10px;
  background-position: -2391px 0;
}

.m-country-flag[data-m-country-flag__country-id="in"] {
  height: 14px;
  background-position: -2413px 0;
}

.m-country-flag[data-m-country-flag__country-id="io"] {
  height: 10px;
  background-position: -2435px 0;
}

.m-country-flag[data-m-country-flag__country-id="iq"] {
  height: 14px;
  background-position: -2457px 0;
}

.m-country-flag[data-m-country-flag__country-id="ir"] {
  height: 12px;
  background-position: -2479px 0;
}

.m-country-flag[data-m-country-flag__country-id="is"] {
  height: 15px;
  background-position: -2501px 0;
}

.m-country-flag[data-m-country-flag__country-id="it"] {
  height: 14px;
  background-position: -2523px 0;
}

.m-country-flag[data-m-country-flag__country-id="je"] {
  height: 12px;
  background-position: -2545px 0;
}

.m-country-flag[data-m-country-flag__country-id="jm"] {
  height: 10px;
  background-position: -2567px 0;
}

.m-country-flag[data-m-country-flag__country-id="jo"] {
  height: 10px;
  background-position: -2589px 0;
}

.m-country-flag[data-m-country-flag__country-id="jp"] {
  height: 14px;
  background-position: -2611px 0;
}

.m-country-flag[data-m-country-flag__country-id="ke"] {
  height: 14px;
  background-position: -2633px 0;
}

.m-country-flag[data-m-country-flag__country-id="kg"] {
  height: 12px;
  background-position: -2655px 0;
}

.m-country-flag[data-m-country-flag__country-id="kh"] {
  height: 13px;
  background-position: -2677px 0;
}

.m-country-flag[data-m-country-flag__country-id="ki"] {
  height: 10px;
  background-position: -2699px 0;
}

.m-country-flag[data-m-country-flag__country-id="km"] {
  height: 12px;
  background-position: -2721px 0;
}

.m-country-flag[data-m-country-flag__country-id="kn"] {
  height: 14px;
  background-position: -2743px 0;
}

.m-country-flag[data-m-country-flag__country-id="kp"] {
  height: 10px;
  background-position: -2765px 0;
}

.m-country-flag[data-m-country-flag__country-id="kr"] {
  height: 14px;
  background-position: -2787px 0;
}

.m-country-flag[data-m-country-flag__country-id="kw"] {
  height: 10px;
  background-position: -2809px 0;
}

.m-country-flag[data-m-country-flag__country-id="ky"] {
  height: 10px;
  background-position: -2831px 0;
}

.m-country-flag[data-m-country-flag__country-id="kz"] {
  height: 10px;
  background-position: -2853px 0;
}

.m-country-flag[data-m-country-flag__country-id="la"] {
  height: 14px;
  background-position: -2875px 0;
}

.m-country-flag[data-m-country-flag__country-id="lb"] {
  height: 14px;
  background-position: -2897px 0;
}

.m-country-flag[data-m-country-flag__country-id="lc"] {
  height: 10px;
  background-position: -2919px 0;
}

.m-country-flag[data-m-country-flag__country-id="li"] {
  height: 12px;
  background-position: -2941px 0;
}

.m-country-flag[data-m-country-flag__country-id="lk"] {
  height: 10px;
  background-position: -2963px 0;
}

.m-country-flag[data-m-country-flag__country-id="lr"] {
  height: 11px;
  background-position: -2985px 0;
}

.m-country-flag[data-m-country-flag__country-id="ls"] {
  height: 14px;
  background-position: -3007px 0;
}

.m-country-flag[data-m-country-flag__country-id="lt"] {
  height: 12px;
  background-position: -3029px 0;
}

.m-country-flag[data-m-country-flag__country-id="lu"] {
  height: 12px;
  background-position: -3051px 0;
}

.m-country-flag[data-m-country-flag__country-id="lv"] {
  height: 10px;
  background-position: -3073px 0;
}

.m-country-flag[data-m-country-flag__country-id="ly"] {
  height: 10px;
  background-position: -3095px 0;
}

.m-country-flag[data-m-country-flag__country-id="ma"] {
  height: 14px;
  background-position: -3117px 0;
}

.m-country-flag[data-m-country-flag__country-id="mc"] {
  height: 15px;
  background-position: -3139px 0;
}

.m-country-flag[data-m-country-flag__country-id="md"] {
  height: 10px;
  background-position: -3160px 0;
}

.m-country-flag[data-m-country-flag__country-id="me"] {
  height: 10px;
  background-position: -3182px 0;
}

.m-country-flag[data-m-country-flag__country-id="mf"] {
  height: 14px;
  background-position: -3204px 0;
}

.m-country-flag[data-m-country-flag__country-id="mg"] {
  height: 14px;
  background-position: -3226px 0;
}

.m-country-flag[data-m-country-flag__country-id="mh"] {
  height: 11px;
  background-position: -3248px 0;
}

.m-country-flag[data-m-country-flag__country-id="mk"] {
  height: 10px;
  background-position: -3270px 0;
}

.m-country-flag[data-m-country-flag__country-id="ml"] {
  height: 14px;
  background-position: -3292px 0;
}

.m-country-flag[data-m-country-flag__country-id="mm"] {
  height: 14px;
  background-position: -3314px 0;
}

.m-country-flag[data-m-country-flag__country-id="mn"] {
  height: 10px;
  background-position: -3336px 0;
}

.m-country-flag[data-m-country-flag__country-id="mo"] {
  height: 14px;
  background-position: -3358px 0;
}

.m-country-flag[data-m-country-flag__country-id="mp"] {
  height: 10px;
  background-position: -3380px 0;
}

.m-country-flag[data-m-country-flag__country-id="mq"] {
  height: 14px;
  background-position: -3402px 0;
}

.m-country-flag[data-m-country-flag__country-id="mr"] {
  height: 14px;
  background-position: -3424px 0;
}

.m-country-flag[data-m-country-flag__country-id="ms"] {
  height: 10px;
  background-position: -3446px 0;
}

.m-country-flag[data-m-country-flag__country-id="mt"] {
  height: 14px;
  background-position: -3468px 0;
}

.m-country-flag[data-m-country-flag__country-id="mu"] {
  height: 14px;
  background-position: -3490px 0;
}

.m-country-flag[data-m-country-flag__country-id="mv"] {
  height: 14px;
  background-position: -3512px 0;
}

.m-country-flag[data-m-country-flag__country-id="mw"] {
  height: 14px;
  background-position: -3534px 0;
}

.m-country-flag[data-m-country-flag__country-id="mx"] {
  height: 12px;
  background-position: -3556px 0;
}

.m-country-flag[data-m-country-flag__country-id="my"] {
  height: 10px;
  background-position: -3578px 0;
}

.m-country-flag[data-m-country-flag__country-id="mz"] {
  height: 14px;
  background-position: -3600px 0;
}

.m-country-flag[data-m-country-flag__country-id="na"] {
  height: 14px;
  background-position: -3622px 0;
}

.m-country-flag[data-m-country-flag__country-id="nc"] {
  height: 10px;
  background-position: -3644px 0;
}

.m-country-flag[data-m-country-flag__country-id="ne"] {
  height: 15px;
  background-position: -3666px 0;
}

.m-country-flag[data-m-country-flag__country-id="nf"] {
  height: 10px;
  background-position: -3686px 0;
}

.m-country-flag[data-m-country-flag__country-id="ng"] {
  height: 10px;
  background-position: -3708px 0;
}

.m-country-flag[data-m-country-flag__country-id="ni"] {
  height: 12px;
  background-position: -3730px 0;
}

.m-country-flag[data-m-country-flag__country-id="nl"] {
  height: 14px;
  background-position: -3752px 0;
}

.m-country-flag[data-m-country-flag__country-id="no"] {
  height: 15px;
  background-position: -3774px 0;
}

.m-country-flag[data-m-country-flag__country-id="np"] {
  height: 15px;
  background-position: -3796px 0;
}

.m-country-flag[data-m-country-flag__country-id="nr"] {
  height: 10px;
  background-position: -3811px 0;
}

.m-country-flag[data-m-country-flag__country-id="nu"] {
  height: 10px;
  background-position: -3833px 0;
}

.m-country-flag[data-m-country-flag__country-id="nz"] {
  height: 10px;
  background-position: -3855px 0;
}

.m-country-flag[data-m-country-flag__country-id="om"] {
  height: 10px;
  background-position: -3877px 0;
}

.m-country-flag[data-m-country-flag__country-id="pa"] {
  height: 14px;
  background-position: -3899px 0;
}

.m-country-flag[data-m-country-flag__country-id="pe"] {
  height: 14px;
  background-position: -3921px 0;
}

.m-country-flag[data-m-country-flag__country-id="pf"] {
  height: 14px;
  background-position: -3943px 0;
}

.m-country-flag[data-m-country-flag__country-id="pg"] {
  height: 15px;
  background-position: -3965px 0;
}

.m-country-flag[data-m-country-flag__country-id="ph"] {
  height: 10px;
  background-position: -3987px 0;
}

.m-country-flag[data-m-country-flag__country-id="pk"] {
  height: 14px;
  background-position: -4009px 0;
}

.m-country-flag[data-m-country-flag__country-id="pl"] {
  height: 13px;
  background-position: -4031px 0;
}

.m-country-flag[data-m-country-flag__country-id="pm"] {
  height: 14px;
  background-position: -4053px 0;
}

.m-country-flag[data-m-country-flag__country-id="pn"] {
  height: 10px;
  background-position: -4075px 0;
}

.m-country-flag[data-m-country-flag__country-id="pr"] {
  height: 14px;
  background-position: -4097px 0;
}

.m-country-flag[data-m-country-flag__country-id="ps"] {
  height: 10px;
  background-position: -4119px 0;
}

.m-country-flag[data-m-country-flag__country-id="pt"] {
  height: 14px;
  background-position: -4141px 0;
}

.m-country-flag[data-m-country-flag__country-id="pw"] {
  height: 13px;
  background-position: -4163px 0;
}

.m-country-flag[data-m-country-flag__country-id="py"] {
  height: 11px;
  background-position: -4185px 0;
}

.m-country-flag[data-m-country-flag__country-id="qa"] {
  height: 8px;
  background-position: -4207px 0;
}

.m-country-flag[data-m-country-flag__country-id="re"] {
  height: 14px;
  background-position: -4229px 0;
}

.m-country-flag[data-m-country-flag__country-id="ro"] {
  height: 14px;
  background-position: -4251px 0;
}

.m-country-flag[data-m-country-flag__country-id="rs"] {
  height: 14px;
  background-position: -4273px 0;
}

.m-country-flag[data-m-country-flag__country-id="ru"] {
  height: 14px;
  background-position: -4295px 0;
}

.m-country-flag[data-m-country-flag__country-id="rw"] {
  height: 14px;
  background-position: -4317px 0;
}

.m-country-flag[data-m-country-flag__country-id="sa"] {
  height: 14px;
  background-position: -4339px 0;
}

.m-country-flag[data-m-country-flag__country-id="sb"] {
  height: 10px;
  background-position: -4361px 0;
}

.m-country-flag[data-m-country-flag__country-id="sc"] {
  height: 10px;
  background-position: -4383px 0;
}

.m-country-flag[data-m-country-flag__country-id="sd"] {
  height: 10px;
  background-position: -4405px 0;
}

.m-country-flag[data-m-country-flag__country-id="se"] {
  height: 13px;
  background-position: -4427px 0;
}

.m-country-flag[data-m-country-flag__country-id="sg"] {
  height: 14px;
  background-position: -4449px 0;
}

.m-country-flag[data-m-country-flag__country-id="sh"] {
  height: 10px;
  background-position: -4471px 0;
}

.m-country-flag[data-m-country-flag__country-id="si"] {
  height: 10px;
  background-position: -4493px 0;
}

.m-country-flag[data-m-country-flag__country-id="sj"] {
  height: 15px;
  background-position: -4515px 0;
}

.m-country-flag[data-m-country-flag__country-id="sk"] {
  height: 14px;
  background-position: -4537px 0;
}

.m-country-flag[data-m-country-flag__country-id="sl"] {
  height: 14px;
  background-position: -4559px 0;
}

.m-country-flag[data-m-country-flag__country-id="sm"] {
  height: 15px;
  background-position: -4581px 0;
}

.m-country-flag[data-m-country-flag__country-id="sn"] {
  height: 14px;
  background-position: -4603px 0;
}

.m-country-flag[data-m-country-flag__country-id="so"] {
  height: 14px;
  background-position: -4625px 0;
}

.m-country-flag[data-m-country-flag__country-id="sr"] {
  height: 14px;
  background-position: -4647px 0;
}

.m-country-flag[data-m-country-flag__country-id="ss"] {
  height: 10px;
  background-position: -4669px 0;
}

.m-country-flag[data-m-country-flag__country-id="st"] {
  height: 10px;
  background-position: -4691px 0;
}

.m-country-flag[data-m-country-flag__country-id="sv"] {
  height: 12px;
  background-position: -4713px 0;
}

.m-country-flag[data-m-country-flag__country-id="sx"] {
  height: 14px;
  background-position: -4735px 0;
}

.m-country-flag[data-m-country-flag__country-id="sy"] {
  height: 14px;
  background-position: -4757px 0;
}

.m-country-flag[data-m-country-flag__country-id="sz"] {
  height: 14px;
  background-position: -4779px 0;
}

.m-country-flag[data-m-country-flag__country-id="ta"] {
  height: 10px;
  background-position: -4801px 0;
}

.m-country-flag[data-m-country-flag__country-id="tc"] {
  height: 10px;
  background-position: -4823px 0;
}

.m-country-flag[data-m-country-flag__country-id="td"] {
  height: 14px;
  background-position: -4845px 0;
}

.m-country-flag[data-m-country-flag__country-id="tf"] {
  height: 14px;
  background-position: -4867px 0;
}

.m-country-flag[data-m-country-flag__country-id="tg"] {
  height: 13px;
  background-position: -4889px 0;
}

.m-country-flag[data-m-country-flag__country-id="th"] {
  height: 14px;
  background-position: -4911px 0;
}

.m-country-flag[data-m-country-flag__country-id="tj"] {
  height: 10px;
  background-position: -4933px 0;
}

.m-country-flag[data-m-country-flag__country-id="tk"] {
  height: 10px;
  background-position: -4955px 0;
}

.m-country-flag[data-m-country-flag__country-id="tl"] {
  height: 10px;
  background-position: -4977px 0;
}

.m-country-flag[data-m-country-flag__country-id="tm"] {
  height: 14px;
  background-position: -4999px 0;
}

.m-country-flag[data-m-country-flag__country-id="tn"] {
  height: 14px;
  background-position: -5021px 0;
}

.m-country-flag[data-m-country-flag__country-id="to"] {
  height: 10px;
  background-position: -5043px 0;
}

.m-country-flag[data-m-country-flag__country-id="tr"] {
  height: 14px;
  background-position: -5065px 0;
}

.m-country-flag[data-m-country-flag__country-id="tt"] {
  height: 12px;
  background-position: -5087px 0;
}

.m-country-flag[data-m-country-flag__country-id="tv"] {
  height: 10px;
  background-position: -5109px 0;
}

.m-country-flag[data-m-country-flag__country-id="tw"] {
  height: 14px;
  background-position: -5131px 0;
}

.m-country-flag[data-m-country-flag__country-id="tz"] {
  height: 14px;
  background-position: -5153px 0;
}

.m-country-flag[data-m-country-flag__country-id="ua"] {
  height: 14px;
  background-position: -5175px 0;
}

.m-country-flag[data-m-country-flag__country-id="ug"] {
  height: 14px;
  background-position: -5197px 0;
}

.m-country-flag[data-m-country-flag__country-id="um"] {
  height: 11px;
  background-position: -5219px 0;
}

.m-country-flag[data-m-country-flag__country-id="us"] {
  height: 11px;
  background-position: -5241px 0;
}

.m-country-flag[data-m-country-flag__country-id="uy"] {
  height: 14px;
  background-position: -5263px 0;
}

.m-country-flag[data-m-country-flag__country-id="uz"] {
  height: 10px;
  background-position: -5285px 0;
}

.m-country-flag[data-m-country-flag__country-id="va"] {
  height: 15px;
  background-position: -5307px 0;
}

.m-country-flag[data-m-country-flag__country-id="vc"] {
  height: 14px;
  background-position: -5324px 0;
}

.m-country-flag[data-m-country-flag__country-id="ve"] {
  height: 14px;
  background-position: -5346px 0;
}

.m-country-flag[data-m-country-flag__country-id="vg"] {
  height: 10px;
  background-position: -5368px 0;
}

.m-country-flag[data-m-country-flag__country-id="vi"] {
  height: 14px;
  background-position: -5390px 0;
}

.m-country-flag[data-m-country-flag__country-id="vn"] {
  height: 14px;
  background-position: -5412px 0;
}

.m-country-flag[data-m-country-flag__country-id="vu"] {
  height: 12px;
  background-position: -5434px 0;
}

.m-country-flag[data-m-country-flag__country-id="wf"] {
  height: 14px;
  background-position: -5456px 0;
}

.m-country-flag[data-m-country-flag__country-id="ws"] {
  height: 10px;
  background-position: -5478px 0;
}

.m-country-flag[data-m-country-flag__country-id="xk"] {
  height: 15px;
  background-position: -5500px 0;
}

.m-country-flag[data-m-country-flag__country-id="ye"] {
  height: 14px;
  background-position: -5522px 0;
}

.m-country-flag[data-m-country-flag__country-id="yt"] {
  height: 14px;
  background-position: -5544px 0;
}

.m-country-flag[data-m-country-flag__country-id="za"] {
  height: 14px;
  background-position: -5566px 0;
}

.m-country-flag[data-m-country-flag__country-id="zm"] {
  height: 14px;
  background-position: -5588px 0;
}

.m-country-flag[data-m-country-flag__country-id="zw"] {
  height: 10px;
  background-position: -5610px 0;
}
