/* -------------------------------------------------------------------------
* Choice List Filter
*
*/
.c-choice-list__dynamic-clear-btn {
  margin: $w-choice-list-filter__clear-btn--margin;
}
// States
// -------------------------------------------------

// Disabled
.w-filter--choice-list.is-disabled,
.is-disabled .w-filter--choice-list {
  .c-choice-list__options {
    opacity: $w-choice-list-filter__disabled--opacity;
    pointer-events: none;
  }
}
