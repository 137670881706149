@mixin search-result-extended ($isAlwaysExtended: false) {
  @if $isAlwaysExtended {
    $c-search-result--bp: 'xxs' !default;
    $c-search-result--bp--small: 'xxs' !default;
    $c-search-result--bp--big: 'xxs' !default;
  }

  // Header and its content
  .c-search-result__content {
    @include t-mq($from: $c-search-result--bp-small) {
      display: flex;
      flex-grow: 1;
      flex-basis: 0%;
    }
  }

  .c-search-result__image-wrapper {
    @include t-mq($from: $c-search-result--bp-big) {
      width: $c-search-result__image-wrapper--width--from-mq-big;
    }

    @include t-mq($from: $c-search-result--bp-small) {
      width: $c-search-result__image-wrapper--width--from-mq;
    }
  }

  .c-search-result__collection {
    @include t-mq($from: $c-search-result--bp) {
      top: $c-search-result__collection--top--from-mq;
    }
  }

  .c-search-result__action-wrapper {
    @include t-mq($from: $c-search-result--bp-small) {
      margin: 0;
    }
  }

  .c-search-result__action {
    @include t-mq($from: $c-search-result--bp-small) {
      width: $c-search-result__image-wrapper--width--from-mq;
    }
    @include t-mq($from: $c-search-result--bp-big) {
      width: $c-search-result__image-wrapper--width--from-mq-big;
    }
  }

  .c-search-result__body {
    @include t-mq($from: $c-search-result--bp-small) {
      display: flex;
      flex-direction: column;
      flex: 1;
      @include sp-inset($c-search-result__body--sp-inset--from-mq);
    }
  }

  .c-search-result__score {
    @include t-mq($from: $c-search-result--bp) {
      top: $c-search-result__score--top--from-mq;
      right: $c-search-result__score--right--from-mq;
    }
  }

  .c-search-result__warning-message {
    @include t-mq($from: $c-search-result--bp) {
      display: block;
      margin-top: $c-search-result__warning-message--margin-top;
      font-size: $c-search-result__warning-message--font-size;
      color: $c-search-result__warning-message--color;
    }
  }

  .c-search-result__price {
    @include t-mq($from: $c-search-result--bp) {
      display: block;
      @include sp-stack($c-search-result__price--sp-stack);
    }
  }

  .c-search-result__name {
    @include t-mq($from: $c-search-result--bp) {
      @include sp-inline($c-search-result__name--sp-inline--from-mq);
    }
  }

  .c-search-result__mostbooked {
    @include t-mq($from: $c-search-result--bp) {
      display: block;
      font-size: $c-search-result__mostbooked--font-size;
      color: $c-search-result__mostbooked--color;
      @include sp-stack($c-search-result__mostbooked--sp-stack);
    }
  }

  .c-search-result__urgency-messages {
    @include t-mq($from: $c-search-result--bp) {
      font-size: $c-search-result__urgency-messages--font-size--from-mq;
      line-height: $c-search-result__urgency-messages--line-height--from-mq;
    }
  }

  .c-search-result__usps {
    @include t-mq($from: $c-search-result--bp) {
      font-size: $c-search-result__usps--font-size--from-mq;
      line-height: $c-search-result__usp-item--line-height--from-mq;
    }
  }
}
