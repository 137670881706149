/* -------------------------------------------------------------------------
 * PROMOPAGE
 *
 */

 .fr-promopage-hero{
    .c-promotion-image__content--title{
        @include t-shared-header-img__title();
    }
    .c-promotion-image__content--body{
        @include t-shared-header-img__subtitle()
    }
 }