/* -------------------------------------------------------------------------
 * PARTICIPANTS SELECTOR VARS
 *
 */

$c-participants-selector__room-title--material:                               map_get($m-heading__mod-sizes, "tiny") !default;
$c-participants-selector__add-room--bg-color:                                 $f-color__state-info--ultra-light !default;
$c-participants-selector__wrapper--bg-color:                                  unset !default;
$c-participants-selector__children-dates-required--bg-color:                  $f-color__brand-secondary--ultra-light !default;
$c-participants-selector__add-room-btn--bg-color:                             $f-color__state-info--light !default;
$c-participants-selector__add-room-btn--bg-color--hover:                      $f-color__state-info--semi-light !default;
$c-participants-selector__room-wrapper--border:                               1px solid $f-color__neutral-grey--semi-light !default;
$c-participants-selector__profile--focus--border:                             unset !default;
$c-participants-selector__title--color:                                       $f-color__brand-primary--dark !default;
