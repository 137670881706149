/* -------------------------------------------------------------------------
 * UNTRUSTED HTML VARS
 *
 */

$s-untrusted-html--body-font:                                   map_get($m-body__mod-sizes, "medium") !default;
$s-untrusted-html--heading-font:                                map_get($m-heading__mod-sizes, "small") !default;
$s-untrusted-html--heading--sp-stack:                           $f-space--small !default;

$s-untrusted-html--table--head-font-weight:                     700 !default;
$s-untrusted-html--table--sp-inset:                             $f-space--small !default;
$s-untrusted-html--table--bg-color:                             $f-color__neutral-grey--light !default;