.t-accountprofile .l-site-main {
  margin-top: $t-accountprofile--margin-top;
}

.t-accountprofile__title {
  @include t-text-crop-disable;
  font-size: $t-accountprofile__title--font-size;
  margin-bottom: $t-accountprofile__title--margin-bottom;
}

.t-accountprofile__breadcrumbs.t-accountprofile__breadcrumbs.c-breadcrumbs:first-child {
  margin-top: 0;
  margin-bottom: $t-accountprofile__breadcrumbs--margin-bottom;
}

.t-accountprofile__form-container {
  margin-top: $t-accountprofile__form-container--margin-top;
  position: relative;
  flex-grow: 1;

  @include t-mq($until: $f-breakpoint--sm) {
    margin-top: $t-accountprofile__form-container--margin-top--until-bp;
  }
}

.t-account-profile .o-block__body {
  display: flex;
}

.t-accountprofile__container {
  display: flex;
  flex-direction: column;
}

.t-accountprofile .w-delete-account__open-button {
  padding-left: 0;
  border-left: 0;
  margin-top: $f-space--small;
}
