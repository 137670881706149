/* -------------------------------------------------------------------------
 * Media
 */

.c-media {
}

.c-media__img {
  @include sp-stack($c-media__img--sp-stack);

  @include t-mq($from: $c-media--bp) {
    width: $c-media__img--width--from-bp;
    @include sp-stack($f-space--none);
    @include sp-inline($c-media__img--sp-inline--from-bp);
    @include o-media__img();
  }
}

.c-media__body {
  @include t-mq($from: $c-media--bp) {
    @include o-media__body();
    @include sp-inline($c-media__body--sp-inline--from-bp);
  }
}

.c-media__heading {
  @include sp-stack($c-media__heading--sp-stack);
}

.c-media__title {
  @include sp-stack($c-media__title--sp-stack);
}

.c-media__subtitle {
  color: $c-media__subtitle--color;
}
