/* -------------------------------------------------------------------------
 * BREADCRUMBS
 *
 */

// Component selector output
// --------------------------------------------------

.c-breadcrumbs {
  @include o-list-bare;
  @include m-body($c-breadcrumbs--text);
  line-height: inherit;
  flex-wrap: wrap;
  display: flex;

  &:not(.c-breadcrumbs--unclickable) {
    .c-breadcrumbs__item:last-child .c-breadcrumbs__link, .c-breadcrumbs__link--unclickable {
      color: $c-breadcrumbs__link__last--color;
      cursor: default;
    }
  }
}

.c-breadcrumbs__item {
  display: flex;
  color: $c-breadcrumbs__link--color;
  align-items: center;

  &:not(:last-child)::after {
    content: attr(data-divider);
    font-family: $c-breadcrumbs__divider--font-family;
    font-size: $c-breadcrumbs__divider--font-size;
    @include sp-inset-h($c-breadcrumbs__divider--sp-inset);
  }
}

.c-breadcrumbs__link {
  color: $c-breadcrumbs__link--color;
  font-weight: $c-breadcrumbs__link--font-weight;
  text-decoration: none;

  &:not(c-breadcrumbs__link--unclickable):hover {
    color: $c-breadcrumbs__link--active-color;
    text-decoration: none;
  }
}

// Unclickable styles
// -----------------------------------------

.c-breadcrumbs--unclickable {
  cursor: default;
  pointer-events: none;

  .c-breadcrumbs__item:not(:last-child)::after {
    padding-left: 0;
  }
}

// Unresolved styles
// -----------------------------------------

.c-breadcrumbs.is-unresolved,
.is-unresolved .c-breadcrumbs {
  position: relative;

  &::before, &::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
  }

  &::before {
    z-index: $c-breadcrumbs--unresolved--z-index;
    left: $c-breadcrumbs--unresolved-background--left;
    width: $c-breadcrumbs--unresolved-background--width;
    background-color: $c-breadcrumbs--unresolved-background;
    border-color: $c-breadcrumbs--unresolved-background;
    border-radius: $c-breadcrumbs--unresolved--border-radius;
  }

  &::after {
    width: $c-breadcrumbs--unresolved--width;
    animation: is-unresolved-animation $c-breadcrumbs__unresolved--animation-duration infinite;
    z-index: $c-breadcrumbs--unresolved--animation-z-index;
    background: linear-gradient(
      $c-breadcrumbs__unresolved--animation-angle,
      $f-color__neutral-white--transparent,
      $c-breadcrumbs__unresolved--animation-endcolor,
      $f-color__neutral-white--transparent
    );
  }
}
