
@mixin collection-row-expanded--eliza() {
  .c-collection-row__item:not(.c-collection-row__item--collection) {
    .c-collection-row__item-content {
      background-color: rgba(
        $c-collection-row__item-content--background-color,
        0.2
      );
      height: 100%;
      text-align: center;
      transition: background-color $f-duration--normal $f-easing--base;
    }

    .c-collection-row__item-title {
      color: $c-collection-row__item-title--color;
      font-size: $c-collection-row__item-title--font-size;
      position: relative;
      text-shadow: map_get($c-collection-row__item-title--shadow, "shadow") rgba(map_get($c-collection-row__item-title--shadow, "color"), map_get($c-collection-row__item-title--shadow, "opacity"));
      top: $c-collection-row__item-title--positioning;
      -webkit-transform: translateY($c-collection-row__item-title--positioning-correction);
      -ms-transform: translateY($c-collection-row__item-title--positioning-correction);
      transform: translateY($c-collection-row__item-title--positioning-correction);
    }

    .c-collection-row__item-subtitle {
      position: relative;
      top: $c-collection-row__item-subtitle--positioning;
      color: $c-collection-row__item-title--color;
    }
  }
}