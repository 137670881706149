/* -------------------------------------------------------------------------
 * TRANSPORT SUMMARY VARS
 *
 */
$w-transport-summary__spacing-main:                                           $f-space--small !default;
$w-transport-summary__spacing-secondary:                                      $f-space--medium !default;

$w-transport-summary__heading--separation:                                    $f-space--tiny !default;
$w-transport-summary__heading-dates--color:                                   $f-color__state-info--semi-dark !default;
$w-transport-summary__heading-dates--font-family:                             $f-font-family__secondary !default;
$w-transport-summary__heading-dates--line-height:                             $e-headings__line-height !default;

$w-transport-summary__breakpoint:                                             $f-breakpoint--md !default;

// Booking flight date changed
$w-transport-summary__heading-date--old--margin-right:                        $f-space--tiny !default;
$w-transport-summary__heading-date--old--new--color:                          $f-color__brand-primary !default;

$w-transport-summary__flight-details--margin-top:                             $f-space--small !default;
$w-transport-summary__flight-details--font-size:                              $f-font-size--xs !default;
$w-transport-summary__flight-details--color:                                  $f-color__state-info--semi-dark !default;

// transport summary empty message
$w-transport-summary__transport-ticket-contents-empty-message--min-height:    65px !default;
$w-transport-summary__transport-ticket-title-empty-message--font-family:      $f-font-family__secondary !default;
$w-transport-summary__transport-ticket-title-empty-message--font-weight:      600 !default;
$w-transport-summary__transport-ticket-title-empty-message--color:            $f-color__neutral-grey !default;