/* -------------------------------------------------------------------------
 * TABLE VARS
 */

$c-table__item--border-width:                             1px !default;
$c-table__item--border-style:                             solid !default;
$c-table__item--border-color:                             $f-color__neutral-grey--semi-light !default;
$c-table__item--sp-inset-v:                               $f-space--small !default;

// Modifiers
// ------------------------------------------------

// Stripped

$c-table__item--border-color--stripped:                   $f-color__neutral-grey--ultra-light !default;
$c-table__item--bg-color--stripped:                       $f-color__neutral-grey--ultra-light !default;
$c-table__item--sp-inset--stripped:                       $f-space--small !default;
