/* -------------------------------------------------------------------------
 * MARGINS
 *
 * Margin utilities based on foundations spaces
 *
 */

// Utilities output
// --------------------------------------------------

@if ($u-margin--enabled) {
  @each $_space-name, $_space-value in $u-margin__spaces {
    @each $_class-name, $_class-properties in $u-margin__classes {
      .u-#{$_class-name}--#{$_space-name} {
        @each $_property-name in $_class-properties {
          #{$_property-name}: s-core-px-to-rem($_space-value) !important;
        }
      }
    }
  }
}

// Auto utilities output
// --------------------------------------------------

@if ($u-margin_auto--enabled) {
  .u-mh--auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
