.fr-header-menu-main {
  // Tune up for NAV

  .c-nav__list {
    z-index: $fr-header-menu-main__nav-list--z-index;

    .c-nav__item{
      line-height: normal;
      .c-nav__link{
        line-height: normal;
        display: block;
        max-height: auto;
      }
    }
  }
    
}
