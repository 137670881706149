/* -------------------------------------------------------------------------
 * REVEAL OPACITY
 *
 * Reveal from transparent to opaque
 *
 * Params:
 * DURATION ........................... Animation duration
 *
 */

// Animation selector output
// --------------------------------------------------

@if ($a-reveal-opacity--enabled) {
  .a-reveal-opacity {
    @include a-reveal-opacity();

    &.in {
      @include a-reveal-opacity--in();
    }
  }
}


// Duration modifiers output
// --------------------------------------------------

@if ($a-reveal-opacity--enabled and $a-reveal-opacity__mod-durations--enabled) {
  @each $_duration-name, $_duration-value in $a-reveal-opacity__mod-durations {
    @include a-reveal-opacity__mod-duration($_duration-name, $_duration-value);
  }
}
