/* -------------------------------------------------------------------------
* Preference Questionnaire
*
*/

.w-preference-questionnaire {
  [class^="c-title"] {
    transform: unset;
  }
}

.w-preference-questionnaire__message-title {
  margin-bottom: $w-preference-questionnaire__message-title--margin-bottom;
}
