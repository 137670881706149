/* -------------------------------------------------------------------------
 * REVEAL LIFT
 *
 * Reveal from transparent & translated to opaque & untranslated position
 *
 * Params:
 * DURATION ........................... Animation duration
 *
 */

// Animation selector output
// --------------------------------------------------

@if ($a-reveal-lift--enabled) {
  .a-reveal-lift {
    @include a-reveal-lift();

    &.in {
      @include a-reveal-lift--in();
    }
  }
}
