.is-unresolved {
  .w-booking-vouchers__container {
    @include a-unresolved($_height-before: $w-booking-vouchers__container--unresolved-border-radius);
  }
}

.w-booking-vouchers__container {
  display: flex;
  flex-wrap: wrap;
  margin: $w-booking-vouchers__container--margin;
}

.w-booking-vouchers__voucher-widget {
  flex-basis: auto;
  flex-grow: 1;
  margin: $w-booking-vouchers__voucher-widget--margin;
}
