/* -------------------------------------------------------------------------
 * Map
 */

.c-map {
  max-width: $c-map--max-width;
  position: relative;
}

.c-map__overlay {
  opacity: 0;
  position: absolute;
  width: $c-map__overlay--full-cover;
  height: $c-map__overlay--full-cover;
  top: 0;
  background-color: $c-map__overlay--bg-color;
  pointer-events: none;
  transition: opacity .5s ease;
}

.c-map__icon {
  position: absolute;
  display: none;
  display: block;
  top: $c-map__overlay--full-cover / 2;
  left: $c-map__overlay--full-cover / 2;
  color: $c-map__icon--color;
  transform: translate(-1 * ($c-map__overlay--full-cover / 2), -1 * ($c-map__overlay--full-cover / 2));
}

.c-map:hover .c-map__overlay {
  opacity: $c-map__overlay--opacity;
}

.c-map:hover .c-map__icon {
  display: block;
}
