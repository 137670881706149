/* -------------------------------------------------------------------------
 * bookings confirmation summary
 *
 */

.w-booking-confirmation-summary__title {
  @include sp-inset-h($w-booking-confirmation-summary--sp-inline);
  @include sp-stack($w-booking-confirmation-summary--sp-stack);
}

.w-booking-confirmation-summary__subtitle {
  @include sp-inset-h($w-booking-confirmation-summary--sp-inline);
  @include sp-stack($w-booking-confirmation-summary--sp-stack);
}

.w-booking-confirmation-summary__image {
  @include sp-stack($w-booking-confirmation-summary--sp-stack);
}
.w-booking-confirmation-summary__image-titles {
  position: relative;
}

.w-booking-confirmation-summary__details {
  border-bottom: $w-booking-confirmation-summary__details--border-bottom;
  border-bottom-style: $w-booking-confirmation-summary__details--border-bottom-style;
  border-bottom-color: $w-booking-confirmation-summary__details--border-bottom-color;
  @include sp-stack($w-booking-confirmation-summary--sp-stack);
}

.w-booking-confirmation-summary__details-list {
  display: flex;
}

.w-booking-confirmation-summary__detail-info{
  width: $w-booking-confirmation-summary__detail-info--width;
  padding-right: $w-booking-confirmation-summary__detail-info--sp-inset-h;
  @include sp-stack($w-booking-confirmation-summary--sp-stack);

  @include t-mq($from: $w-booking-confirmation-summary__breakpoint-sml) {
    width: $w-booking-confirmation-summary--right-column-width;
    align-self: flex-start;
  }
}

.w-booking-confirmation-summary__details-title {
  font-size: $w-booking-confirmation-summary__details-title--font-size;
  @include sp-stack($w-booking-confirmation-summary--sp-stack);
}

.w-booking-confirmation-summary__detail-name {
  font-size: $w-booking-confirmation-summary__detail-name--font-size;
  @include sp-stack($w-booking-confirmation-summary__detail-name--sp-stack);
}

.w-booking-confirmation-summary__detial-info {
  font-size: $w-booking-confirmation-summary__detail-info--font-size;
}

.w-booking-confirmation-summary__links {
  display: flex;
  flex-direction: column;
  @include t-mq($from: $w-booking-confirmation-summary__breakpoint-sml) {
    flex-direction: row;
  }
}
.w-booking-confirmation-summary__links-info {
  margin-bottom: $w-booking-confirmation-summary__links-info-margin-bottom;
  @include t-mq($from: $w-booking-confirmation-summary__breakpoint-sml) {
    margin-bottom: 0;
    @include sp-inline($w-booking-confirmation-summary--sp-inline);
    p:last-child{
      @include sp-stack($w-booking-confirmation-summary__links-info--sp-stack);
    }
  }
  flex-basis: $w-booking-confirmation-summary__links-info--flex-basis;
}

.w-booking-confirmation-summary__links-anchor {
  &:not(:last-child){
    @include sp-stack($w-booking-confirmation-summary--sp-stack);
    @include t-mq($from: $w-booking-confirmation-summary__breakpoint-sml) {
      @include sp-inline($w-booking-confirmation-summary--sp-inline);
    }
  }
  .w-booking-confirmation-summary__links-anchor__btn{
    width: 100%;
    @include t-mq($from: $w-booking-confirmation-summary__breakpoint-sml) {
      white-space: nowrap;
    }
  }
}

.w-booking-confirmation-summary.has-error {
  display: none;
}

.is-unresolved {
  .w-booking-confirmation-summary__details-title {
    width: $w-booking-confirmation-summary__details-title__unresolved--width;
  }

  .w-booking-confirmation-summary__links {
    @include a-unresolved($_border-radius: $w-booking-confirmation-summary--unresolved-border-radius);
  }


  .w-booking-confirmation-summary__image-titles {
    @include a-unresolved();
  }

  .w-booking-confirmation-summary-text {
    @include a-unresolved();
  }
}
