/* -------------------------------------------------------------------------
* COLLECTION ROW VARIABLES
*
*/
$c-collection-row__item--rounded-corners:                   $f-radius !default;
$c-collection-row__item-subtitle--material:                 $f-font-size--m !default;

$c-collection-row__item-content--background-color:          $f-color__neutral-black !default;
$c-collection-row__item-content--color:                     $f-color__brand-primary--dark !default;

$c-collection-row__item-title--material:                    map_get($m-heading__mod-sizes, "micro") !default;
$c-collection-row__item-title--font-size:                   $f-font-size--xxl !default;
$c-collection-row__item-title--color:                       $f-color__neutral-white !default;
$c-collection-row__item-title--shadow:                      map_get($f-shadows, "text") !default;
$c-collection-row__item-title--font-weight:                 700 !default;

$c-collection-row__item-title--positioning:                 50% !default;
$c-collection-row__item-subtitle--positioning:              45% !default;
$c-collection-row__item-subtitle--color:                    $f-color__neutral-white !default;

$c-collection-row__item-title--positioning-correction:      -50% !default;