
/* -------------------------------------------------------------------------
 * THEME CHILD-FRIENDLY Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-child--enabled {
  .cs-theme-child {
    @include color-scheme($cs-theme-child)
  }
}
