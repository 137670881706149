
/* -------------------------------------------------------------------------
 * PROPS DIFF
 *
 * Mixin to set only the different properties between two maps
 *
 * Params:
 * MAP-A .............................. Main map to compare with
 * MAP-B .............................. Secondary map to reduce
 *
 * Output:
 * MAP-B props & values skipping the ones repeated on MAP-A
 *
 * Example
 *
 * map-a: ("font-weight": 400, "text-decoration": underline)
 * map-b: ("font-weight": 400, "text-decoration": none, "text-transform": "uppercase")
 * .selector { @include t-props-diff(map-a, map-b); }
 *
 * .selector-output {
 *  text-decoration: none;
 *  text-transform: uppercase;
 * }
 *
 */


@mixin t-props-diff($_map-a: (), $_map-b: (), $_px-to-rem: true) {

  @include t-props(s-core-map-diff($_map-a, $_map-b));

}
