/* -------------------------------------------------------------------------
 * TABS
 *
 */

// Component output
.c-tabs__nav {
  height: $c-tabs__nav--height;
  display: flex;
  background-color: $c-tabs__nav--bg-color;
  box-shadow: $c-tabs__nav--shadow;

  @include t-mq($from: $c-tabs--bp) {
    height: $c-tabs__nav--height--from-bp;
  }

  .o-swipe-btn {
    [class^="m-icon--"] {
      color: $c-tabs__swipe-button--icon-color;
      vertical-align: $c-tabs__swipe-button--icon-vertical-align;
      // 💡 TIP: To hide the arrow, &::before {visibility: hidden;}
    }

    &:hover {
      [class^="m-icon--"] {
        color: $c-tabs__swipe-button--icon-color--hover;
      }
    }
  }

  .o-swipe-btn--left {
    background: linear-gradient(90deg, $c-tabs__nav--bg-color 40%, rgba($c-tabs__nav--bg-color, 0));
  }

  .o-swipe-btn--right {
    background: linear-gradient(270deg, $c-tabs__nav--bg-color 40%, rgba($c-tabs__nav--bg-color, 0));
  }
}

// This style fixes a heritage problem coming from the site main layout.
.l-site-main__content > .c-tabs__nav::after {
  content: '';
  width: auto;
}

.c-tabs__nav-list-wrapper {
  width: auto;

  &[data-js-swipe] > [class*=list]:first-child {
    position: relative;
  }
}

.c-tabs__nav-list {
  background-color: $c-tabs__nav-list--bg-color;
  padding-left: $c-tabs__nav-item--sp-inline;
}

.c-tabs__nav-list--swipe {
  padding-bottom: 20px;
  scrollbar-width: none;
  scrollbar-height: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.c-tabs__nav-item {
  position: relative;
  background-color: $c-tabs__nav-item--bg-color;
  box-shadow: $c-tabs__nav-item--shadow;
  @include sp-inline($c-tabs__nav-item--sp-inline);

  &.is-open {
    background-color: $c-tabs__nav-item--bg-color--open;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: $c-tabs__nav-button--after-height--open;
      background-color: $c-tabs__nav-button--after-color--open;
    }

    .c-tabs__nav-button,
    .c-tabs__nav-button:hover {
      color: $c-tabs__nav-button--color--open;
      background-color: $c-tabs__nav-item--bg-color--open;
      pointer-events: none;
    }
  }
}

.c-tabs__nav-item:not(.c-tabs__nav-item--featured) {
  @include t-mq($until: $c-tabs--bp) {
    width: $c-tabs__nav-item-width--until-bp;
  }

  @include t-mq($until: $c-tabs--bp--smaller) {
    width: $c-tabs__nav-item-width--until-bp--smaller;
  }

  @media (max-width: $c-tabs--bp--smallest) {
    width: $c-tabs__nav-item-width--until-bp--smallest;
  }
}

.c-tabs__nav-button,
a.c-tabs__nav-button {
  color: $c-tabs__nav-button--color;
  text-transform: $c-tabs__nav-button--text-transform;
  width: 100%;
  max-width: 100%;
  @include sp-inset-squish($c-tabs__nav-button--sp-inset-squish);
  
  @include t-mq($until: $c-tabs--bp) {
    font-size: $c-tabs__nav-button--font-size--until-bp;
  }

  @include t-mq($from: $c-tabs--bp) {
    @include sp-inset($c-tabs__nav-button--sp-inset-v,$c-tabs__nav-button--sp-inset-h);
  }

  &:hover {
    background-color: $c-tabs__nav-button--bg-color--hover;
    color: $c-tabs__nav-button--color--hover;
    box-shadow: none;
  }

  &:active {
    background-color: $c-tabs__nav-button--bg-color--open;
    color: $c-tabs__nav-button--color--open;
    box-shadow: none;
  }

  @include t-mq($from: $c-tabs--bp) {
    min-width: $c-tabs__nav-button--min-width--from-bp;
  }

  .c-btn__icon {
    @include t-mq($until: $c-tabs--bp) {
      font-size: $c-tabs__nav-button--icon-font-size--until-bp;
      height: 1em;
      display: block;
      padding: 0;
      margin: 0;
      margin-top: $c-tabs__nav-button--icon-adjust--until-bp;
      @include sp-stack($c-tabs__nav-button--sp-stack--until-bp);
    }

    @include t-mq($from: $c-tabs--bp) {
      display: none;
    }

    &:before {
      @include t-mq($until: $c-tabs--bp) {
        left: auto;
        right: auto;
        position: relative;
      }
    }
  }

  .c-btn__text {
    white-space: nowrap;
    font-weight: $c-tabs__nav-button--font-weight;

    @include t-mq($until: $c-tabs--bp) {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
}

.c-tabs__content {
  display: none;
  background-color: $c-tabs__content--bg-color;
  @include sp-inset-stretch($c-tabs__content--sp-inset-stretch);

  &.is-open {
    display: block;
  }

  > .c-tabs__nav {
    @include sp-stack($f-space--none);
  }

  > .c-tabs__content-wrap {
    margin-left: -1 * $c-tabs__content--sp-inset-stretch * .5;
    margin-right: -1 * $c-tabs__content--sp-inset-stretch * .5;
  }

  .c-tabs__content {
    @include sp-inset-stretch($c-tabs__content--sp-inset-stretch--nested);
  }
}

// Featured
.c-tabs__nav-list--featured {
  width: auto;

  padding-left: 0;

  @include t-mq($until: $c-tabs--bp) {
    width: $c-tabs__nav-list--featured-width--until-bp;
  }

  @include t-mq($until: $c-tabs--bp--smaller) {
    width: $c-tabs__nav-list--featured-width--until-bp--smaller;
  }
}

.c-tabs__nav-item--featured {
  display: none;

  @include t-mq($until: $c-tabs--bp) {
    width: 100%;
  }

  &:first-child {
    display: inline-block;
  }

  &.is-open {
    .c-tabs__nav-button,
    .c-tabs__nav-button:hover {
      background-color: $c-tabs__nav-item--bg-color--open;
      box-shadow: none;
    }
  }

  .c-tabs__nav-button {
    box-shadow: $c-tabs__nav-item--shadow;
    color: $c-tabs__nav-button--color--featured;
    background-color: $c-tabs__nav-button--bg-color--featured;

    &:hover {
      box-shadow: none;
      background-color: $c-tabs__nav-button--bg-color--hover--featured;
    }

    &:active {
      box-shadow: none;
      background-color: $c-tabs__nav-button--bg-color--open--featured;
    }
  }
}

// Variants
// ----------------------------------------

// Secondary
.c-tabs__nav--secondary {
  height: $c-tabs__nav--height--secondary;
  background-color: $c-tabs__nav--bg-color--secondary;
  box-shadow: $c-tabs__nav--shadow--secondary;
  border-bottom: $c-tabs__nav--border-bottom-width--secondary $c-tabs__nav--border-bottom-style--secondary $c-tabs__nav--border-bottom-color--secondary;
  @include sp-inline($c-tabs__nav-item--sp-inline);

  @include t-mq($from: $c-tabs--bp) {
    height: $c-tabs__nav--height--from-mq--secondary;
  }

  .c-tabs__nav-list-wrapper {
    width: 100%;
    &[data-js-swipe] > [class*=list]:first-child {
      position: absolute;
    }
  }

  .c-tabs__nav-item.c-tabs__nav-item {
    width: auto;
    background-color: $c-tabs__nav-item--bg-color--secondary;
    box-shadow: $c-tabs__nav--shadow--secondary;

    &.is-open {
      box-shadow: $c-tabs__nav--shadow--secondary;

      &:after {
        top: auto;
        bottom: 0;
      }
    }
  }

  .c-tabs__nav-item--featured .c-tabs__nav-button,
  .c-tabs__nav-button {
    width: auto;
    height: $c-tabs__nav-button--height--secondary;
    color: $c-tabs__nav-button--color;
    background-color: transparent;
    box-shadow: none;
    @include sp-inset($c-tabs__nav-button--sp-inset--secondary);
    @include t-mq($until: $c-tabs--bp) {
      height: $c-tabs__nav-button--height--secondary--until-bp;
    }

    &:hover {
      background-color: $c-tabs__nav-button--bg-color--hover--secondary;
      color: $c-tabs__nav-button--color--hover--secondary;
      box-shadow: none;
    }

    &:active {
      background-color: $c-tabs__nav-button--bg-color--open--secondary;
      color: $c-tabs__nav-button--color--open--secondary;
      box-shadow: none;
    }

    .c-btn__icon {
      margin: 0;
      display: inline-block;
      @include sp-inline($c-tabs__nav-button--icon-sp-inline--secondary);

      @include t-mq($from: $c-tabs--bp) {
        vertical-align: $c-tabs__nav-button--icon-vertical-align--from-mq--secondary;
      }

      &:before {
        @include t-mq($until: $c-tabs--bp) {
          left: auto;
          right: auto;
          position: relative;
        }
      }
    }

    .c-btn__text {
      display: initial;
    }
  }

  .c-tabs__nav-item--featured.is-open {
    .c-tabs__nav-button {
      color: $c-tabs__nav-button--color--open;
    }
  }

  .o-swipe-btn {
    bottom: $c-tabs__nav-button--swipe-bottom--secondary;

    [class^="m-icon--"]::before {
      @include t-mq($until: $c-tabs--bp) {
        visibility: visible;
      }
    }
  }

  .o-swipe-btn--left {
    background: linear-gradient(90deg, $e-document__body--bgcolor 40%, rgba($e-document__body--bgcolor, 0));
  }

  .o-swipe-btn--right {
    background: linear-gradient(270deg, $e-document__body--bgcolor 40%, rgba($e-document__body--bgcolor, 0));
  }
}
