/* -------------------------------------------------------------------------
 * DOWNLOAD BUTTON VARIABLES
 */
$c-download-button--border-width:                     1px !default;
$c-download-button--border-style:                     solid !default;
$c-download-button--border-color:                     $f-color__neutral-grey--semi-light !default;
$c-download-button--border-radius:                    4px !default;
$c-download-button--background-color:                 $f-color__neutral-white !default;
$c-download-button--inset:                            $f-space--small !default;
$c-download-button--sibilings--margin-left:           $f-space--tiny !default;
$c-download-button--color:                            $f-color__neutral-black !default;
$c-download-button--hover--color:                     $f-color__neutral-black !default;
$c-download-button--hover--shadow:                    $f-shadow--elevated !default;
$c-download-button--hover--shadow-color:              $f-shadow__color !default;
$c-download-button--hover--shadow-opacity:            $f-shadow__opacity--elevated !default;
$c-download-button--hover--background-color:          $f-color__neutral-grey--ultra-light !default;

// Title
$c-download-button__title--font-family:               $f-font-family--primary !default;
$c-download-button__title--font-weight:               700 !default;
$c-download-button__title--font-size:                 $f-font-size--m !default;

// Subtitle
$c-download-button__subtitle--font-family:            $f-font-family--primary !default;
$c-download-button__subtitle--font-size:              $f-font-size--xs !default;
$c-download-button__subtitle--margin-top:             $f-space--tiny !default;
$c-download-button__subtitle--color:                  $f-color__neutral-grey--semi-dark !default;
$c-download-button__subtitle--font-weight:            400 !default;

// Icons
$c-download-button__icon--color:                      currentColor !default;

$c-download-button__download-icon--color:             $f-color__neutral-grey !default;
$c-download-button__download-icon--hover--color:      $f-color__state-info !default;
