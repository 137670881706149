/* -------------------------------------------------------------------------
 * LABEL
 *
 */

.c-label {
  @include m-body--complete($c-label--material);
  @include t-text-crop-disable();
  line-height: $c-label--line-height;
}
