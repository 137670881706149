/* -------------------------------------------------------------------------
 * STACK MIXINS
 *
 */

// Space as a mixin
// --------------------------------------------------

@mixin sp-stack($_spacing-value) {
  margin-bottom: $_spacing-value;
}


@mixin sp-stack-children($_spacing-value) {
  & > * {
    margin-bottom: $_spacing-value;

    &:last-child,
    &:empty {
      margin-bottom: 0;
    }
  }
}
