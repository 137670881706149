/* -------------------------------------------------------------------------
 * Price
 */

.c-price__legend {
  font-family: $c-price__legend--font-family;
  color: $c-price__legend--color;
  font-weight: $c-price__legend--font-weight;
}

/* ---------
 * Mandatory booking related costs related styles
 */
 .c-price__label.c-price__label-grayed {
  line-height: $c-price__label-grayed--line-height;
  display: inline-block;
  margin-top: $c-price__label-grayed--margin-top;
}

@include t-mq($until: $c-price__label-grayed--breakpoint) {
  .w-acco-lister__package-item--listed {
    .c-price__label.c-price__label-grayed {
      font-size: $c-price__label-grayed--breakpoint--font-size;
    }
    .c-breadcrumbs__item,
    .c-breadcrumbs__link {
      font-size: $c-price__label-grayed--breakpoint--font-size;
    }
  }
}
/* -------- */
