
/* -------------------------------------------------------------------------
 * LINE-HEIGHTS
 *
 * Foundation line-heights
 *
 */

// Utilities output
// --------------------------------------------------

@if ($u-line-height--enabled) {
  @each $_line-height__name, $_line-height__value in $u-line-height__map {
    .u-line-height--#{$_line-height__name} {
      line-height: $_line-height__value !important;
    }
  }
}
