.fr-header-menu-top {
    // Tune ups for NAV
    .c-nav--dropdown {
        .c-nav__list[data-c-nav__level="1"] {
            .c-nav__item {
                padding: 0;
                background-color: $fr-header-menu-top__nav-item--background-color;
            }

            .c-nav__link {
                font-size: $fr-header-menu-top__nav-item--font-size;
                font-weight: $fr-header-menu-top__nav-item--font-weight;
                color: $fr-header-menu-top__nav-item---link-color;

                .c-btn {
                    font-size: $fr-header-menu-top__nav-item--font-size;
                }
            }
        }
    }
}
