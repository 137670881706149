/* -------------------------------------------------------------------------
 * COLLECTION TEASER
 *
 */

// Collection labels should be positioned
.c-collection-teaser {
  .c-label {
    position: absolute;
    left: $c-collection-teaser__label--left-position;
    top: $c-collection-teaser__label--top-position;
    z-index: $c-collection-teaser__label--z-index;
  }
}

// LARGE VARIANT
.c-collection-teaser.c-collection-teaser--large .c-collection-teaser__label {
  transform: none;
  @include t-mq($from: $c-collection-teaser--tablet-breakpoint) {
    left: $c-collection-teaser--large__label--left-position;
  }
}
