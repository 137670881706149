/* -------------------------------------------------------------------------
 * PARAGRAPHS
 *
 * Appearance & behaviour for paragraphs
 *
 */

p + p {
  margin-top: ($e-paragraphs__spacing-vertical - $e-paragraphs__space-within) * -1;
}
