/* -------------------------------------------------------------------------
 * Personal details edit
 *
 */

.w-personal-details-edit {
  &__form,
  &__warning-message {
    @include sp-stack($w-personal-details-edit__form--sp-v);
  }


  .e-form-label {
    display: inline-block;
    &:after {
      margin-top: 0;
    }

    &:before {
      margin-bottom: 0;
    }
  }


  &__warning-message {
    &.is-hidden {
      display: none;
    }
    .c-box__content {
      display: flex;
    }

    &__icon {
      margin-right: $w-personal-details-edit__warning-message__icon--margin-right;
      color: $cs-state-warning--light--heading;

      &--info {
        color: $cs-state-info--light--heading;
      }
    }

    &__text {
      font-size: $w-personal-details-edit__warning-message__text--font-size;
    }
  }

  &__buttons {
    text-align: right;
  }

  &__section.is-hidden {
    display: none;
  }
}

// Modals
.w-personal-details-edit__modal {
  text-align: center;

  .w-personal-details-edit__modal-icon,
  .w-personal-details-edit__modal-title {
    margin-bottom: $w-personal-details-edit__modal-title-icon--sp-stack;
  }

  .w-personal-details-edit__modal-icon {
    color: $w-personal-details-edit__modal-icon-default--color;
  }

  &.w-personal-details-edit__modal-success {
    .w-personal-details-edit__modal-icon {
      color: $w-personal-details-edit__modal-title-icon-success--color;
    }
  }
}

.w-personal-details-edit__cancel-modal .c-modal__footer {
  text-align: right;
}

.w-personal-details-edit__section {
  position: relative;
}

[data-w-personal-details-edit__form-container]:empty {
  min-height: $w-personal-details-edit__form-container--min-height;
  background-color: $w-personal-details-edit__form-container--background-color;
  border: $w-personal-details-edit__form-container--border;
  margin-bottom: $w-personal-details-edit__form-container--margin-bottom;
}

.w-personal-details-edit__loader-form-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: $w-personal-details-edit__loader-form-container--transform;
  z-index: 1;
}

.w-personal-details-edit__loader-form-container.is-hidden {
  display: none;
}

.w-personal-details-edit__request-manual-edition__modal {
  .c-textbox,
  .c-date-selector {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}
