/* -------------------------------------------------------------------------
 * PACKAGE ITEM VARS
 */

$c-package-item--shadow:                                                       $f-shadow--elevated !default;
$c-package-item--shadow-color:                                                 $f-shadow__color !default;
$c-package-item--shadow-color--hover:                                          $f-color__neutral-grey !default;
$c-package-item--shadow-opacity:                                               $f-shadow__opacity--elevated !default;
$c-package-item--alignment-fix:                                                -1 * $f-space--tiny !default;
$c-package-item--bg-color:                                                     $f-color__neutral-white !default;

$c-package-item__body--padding-v:                                              $f-space--medium !default;
$c-package-item__body--padding-h:                                              $f-space--medium !default;
$c-package-item__body-heading--sp-stack:                                       $f-space--small !default;

$c-package-item__title--material:                                              map_get($m-heading__mod-sizes, "small") !default;
$c-package-item__title--max-width:                                             calc(100% - (#{$c-package-item__body--padding-v / 2} + #{$c-number-score__label--spacing} + #{$c-number-score--font-size} + #{$c-number-score__bubble--padding * 2})) !default;

$c-package-item__rating--color:                                                $f-color__state-warning--semi-light !default;

$c-package-item__label--top:                                                   $f-space--small !default;
$c-package-item__label--left:                                                  $f-space--small !default;

$c-package-item__score--top:                                                   $f-space--medium !default;
$c-package-item__score--right:                                                 $c-package-item__body--padding-h !default;
$c-package-item__score--margin-right:                                          0px !default;

$c-package-item__price-color:                                                  $f-color__neutral-black !default;
$c-package-item__price-info-text--overwrite--font-size:                        $f-font-size--s !default;
$c-package-item__price-size:                                                   $f-font-size--xl !default;

$c-package-item__package-block--font-size:                                     1em !default;
$c-package-item__package-block--margin-bottom:                                 -3px !default;

$c-package-item__cta-button--margin-top:                                       $f-space--small !default; 
$c-package-item__cta-button--z-index:                                          $f-z-index--1 !default; 



// Variants
// --------------------------------

// Listed

$c-package-item__body--listed--padding:                                        $f-space--small !default;
$c-package-item__body--listed--from-mq--padding:                               16px !default;
$c-package-item__body--listed--bg-color:                                       $f-color__neutral-grey--ultra-light !default;
$c-package-item__body-title--listed--max-width:                                100% !default;

$c-package-item__img--listed--size:                                            $f-space--giant !default;

$c-package-item__score--listed--top:                                           $f-space--small !default;
$c-package-item__score--listed--right:                                         $c-package-item__body--listed--from-mq--padding !default;

$c-package-item__price-currency--listed--overwrite--font-size:                 $f-font-size--xs !default;
$c-package-item__price-value--listed--overwrite--font-size:                    $f-font-size--m !default;


// Mobile Modifications

$c-package-item--mq:                                                           'sm' !default;
$c-package-item-legend--mq:                                                    640px !default;
$c-package-item__body-title--listed--until-mq--font-size:                      $f-font-size--s !default;
$c-package-item__body-heading--sp-stack--until-bp:                             $f-space--tiny !default;
$c-package-item__body-heading--listed--height:                                 calc(#{($c-package-item__img--listed--size / 3)*2});
$c-package-item__body-footer--listed--height:                                  calc(#{($c-package-item__img--listed--size / 3)*1});

$c-package-item__label--listed--until-mq--font-size:                           $f-font-size--xs !default;

$c-package-item__img--listed--from-mq--size:                                   150px !default;

$c-package-item__rating--listed--until-mq--font-size:                          .6em !default;

$c-package-item__breadcrumbs--listed--until-mq--font-size:                     $f-font-size--xs !default;

$c-package-item__price--listed--from-mq--font-size--until-mq:                  $f-font-size--m !default;
$c-package-item__price-icon--listed--from-mq--font-size--until-mq:             $f-font-size--s !default;

$c-package-item__package-block--listed--until-mq--font-size:                   1.2em !default;

$c-package-item__package-block--listed--until-mq--separator--space:            $f-space--none !default;
