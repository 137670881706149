// Vars

$fr-account-booked-service-edit__breakpoint:                                      $f-breakpoint--md !default;

$fr-account-booked-service-edit--booking-box--padding:                            $f-space--small !default;
$fr-account-booked-service-edit--padding-reset:                                   $f-space--none !default;
$fr-account-booked-service-edit--booking-box--bg-color:                           $f-color__neutral-white !default;

$fr-account-booked-service-edit__pay-button--margin-bottom:                       $f-space--small !default;

$fr-account-booked-service-edit__layer-1:                                         $f-z-index--4 + 2 !default;

$fr-account-booked-service-edit__modal-success--color:                            $f-color__state-success !default;

$fr-account-booked-service-edit__payment-options-side-drawer--duration:           $f-duration--normal !default;
$fr-account-booked-service-edit__payment-options-side-drawer--padding-bottom:     $f-space--large + $f-space--small !default;

$fr-account-booked-service-edit__conditions-container--margin-top:                $f-space--none !default;
$fr-account-booked-service-edit__booking-terms-conditions--padding:               $f-space--none !default;

$w-booked-service-edit__modal--z-index:                                          map_get($f-z-indexes--usage, "c-modal") !default;
$w-booked-service-edit__modal-v2--z-index:                                       map_get($f-z-indexes--usage, "c-modal-v2") !default;
