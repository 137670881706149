/* -------------------------------------------------------------------------
* Textbox Variables
*
*/

$c-textarea--color:                                   $e-form-elements__field--color !default;
$c-textarea--sp-inset-h:                              $e-form-elements--sp-inset-h !default;
$c-textarea--sp-inset-v:                              $e-form-elements--sp-inset-v !default;
$c-textarea--border-radius:                           $e-form-elements__field--border-radius !default;
$c-textarea--duration:                                $f-duration--normal !default;
$c-textarea--easing:                                  $f-easing--linear !default;

$c-textarea__wrapper--bg-color:                       $e-form-elements__field--bg-color !default;

$c-textarea__element--bg-color:                       $e-form-elements__field--bg-color !default;
$c-textarea__element--placeholder-color:              $e-form-elements__placeholder--color !default;
$c-textarea__element--font-family:                    $e-form-elements__field--font-family !default;
$c-textarea__element--font-size:                      $e-form-elements__field--font-size !default;
$c-textarea__element--border-size:                    $e-form-elements__field--border--size !default;
$c-textarea__element--border-style:                   $e-form-elements__field--border--type !default;
$c-textarea__element--border-color:                   $e-form-elements__field--border-color !default;
$c-textarea__element--border-color--hover:            $e-form-elements--hover--border-color !default;
$c-textarea__element--shadow--focus:                  $f-shadow--glow $e-form-elements--focus--border-color !default;
$c-textarea__element--border--focus:                  $e-form-elements--focus--border-color !default;
$c-textarea__element--height:                         100px !default;

  // States
// -----------------------------------------

// Disabled
$c-textarea__disabled--opacity:                       $e-form-elements--disabled--opacity !default;

// Success
$c-textarea__element--success--border-color:          $e-form-elements--success--border-color !default;
$c-textarea__element--success--shadow--focus:         $f-shadow--glow $e-form-elements--success--border-color !default;


// Warning
$c-textarea__element--warning--border-color:          $e-form-elements--warning--border-color !default;
$c-textarea__element--warning--shadow--focus:         $f-shadow--glow $e-form-elements--warning--border-color !default;

// Error
$c-textarea__element--error--border-color:            $e-form-elements--error--border-color !default;
$c-textarea__element--error--shadow--focus:           $f-shadow--glow $e-form-elements--error--border-color !default;


// Modifiers
// -----------------------------------------

// Highlighted
$c-textarea__element--highlighted--color:             $e-form-elements__inner-element--color !default;

// Messages
$c-textarea__messages--font-size:                     $f-font-size--s !default;
$c-textarea__messages--margin-top:                    $f-space--tiny !default;
$c-textarea__message--error--color:                   $e-form-elements--error--border-color !default;
