
/* -------------------------------------------------------------------------
 * STATE INFO Color Scheme
 *
 */

// Color Scheme output

@if $cs-state-info--enabled {
  .cs-state-info {
    @include color-scheme($cs-state-info)
  }
}


// Color Scheme Modifiers
// -------------------------

// White modifier

@if $cs-state-info--enabled and $cs-state-info--white--enabled {
  .cs-state-info--white {
    @include color-scheme(s-core-map-diff($cs-state-info, $cs-state-info--white))
  }
}

// Light modifier

@if $cs-state-info--enabled and $cs-state-info--light--enabled {
  .cs-state-info--light {
    @include color-scheme(s-core-map-diff($cs-state-info, $cs-state-info--light))
  }
}
