// Color schemes overrides
// -----------------------------------------

.cs-brand-primary {
  .c-favorite-collector {
    color: $c-favorite-collector__bubble--color--cs-brand-primary;

    &::after {
      color: $c-favorite-collector__bubble--color--cs-brand-primary;
    }

    &:hover {
      color: $c-favorite-collector__bubble--color--hover--cs-brand-primary;

      &::after {
        color: $c-favorite-collector__bubble--color--hover--cs-brand-primary;
      }
    }
  }
}
