/* -------------------------------------------------------------------------
* Favorite Variables
*
*/

$c-favorite-collector__bubble--bg-color:                            transparent !default;
$c-favorite-collector__bubble--color:                               $f-color__brand-primary--dark !default;
$c-favorite-collector__bubble--top:                                 .15em !default;
$c-favorite-collector__bubble--left:                                2.25em !default;

$c-favorite-btn--color:                                             $f-color__brand-primary !default;
$c-favorite-btn--bg-color--hover:                                   $f-color__brand-primary--ultra-light !default;

$c-favorite-btn--shadow:                                            $f-shadow--discrete rgba($f-shadow__color, $f-shadow__opacity--discrete) !default;

// Color schemes overrides
// --------------------------------------------------

$c-favorite-collector__bubble--color--cs-brand-primary:             $cs-brand-primary--heading !default;
$c-favorite-collector__bubble--color--hover--cs-brand-primary:      $f-color__brand-primary--ultra-light !default;
