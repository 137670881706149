/* -------------------------------------------------------------------------
 * PROFILE DATA
 */

.w-profile-data {
  padding: $w-profile-data--vertical-padding 0 $w-profile-data--vertical-padding 0;
  border-bottom: $w-profile-data--border;
  border-top: $w-profile-data--border;
}

.w-profile-data__field:not(:last-child) {
  margin-bottom: $w-profile-data__field--margin-bottom;
}

.w-profile-data__field-header {
  display: flex;
  align-items: baseline;
}

.w-profile-data__open-collapse-container {
  margin-left: auto;
}

.w-profile-data__collapse-btn {
  color:  $w-profile-data__collapse-btn--color;
  font-size: $w-profile-data__collapse-btn--font-size;
  text-decoration: underline;
  margin-left: auto;
  margin-right: 0;
}

.w-profile-data__field-value {
  font-size: $w-profile-data__field-value--font-size;
  color: $w-profile-data__field-value--color;
  margin-top: $w-profile-data__field-value--margin-top;
  max-height: $w-profile-data__field-value--max-height;
  transition: max-height $w-profile-data__field-value--animation;
  overflow: hidden;
}

.w-profile-data__loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: $w-profile-data__loader--transform;
  z-index: 1;
}


.w-profile-data :not(.w-profile-data__field-value).is-hidden {
  display: none;
}

.w-profile-data__field-value.is-hidden {
  max-height: 0;
}

.w-profile-data__form {
  text-align: left;
}

.w-profile-data__form-input {
  width: 100%;
  @include t-mq($from: $f-breakpoint--sml) {
    max-width: $w-profile-data__form-input--max-width;
  }
}

.w-profile-data__form-name-description {
  width: 100%;
  color: $w-profile-data__form-name-description--color;
  font-size: $w-profile-data__form-name-description--font-size;
  margin-top: $w-profile-data__form--vertical-margin;
  margin-bottom: 0;
  @include t-mq($from: $f-breakpoint--sml) {
    max-width: $w-profile-data__form-input--max-width;
  }
}

.w-profile-data__form .c-form__fieldset-container {
  margin-top: 0;
}

.w-profile-data__messages-container {
  position: absolute;
  left: 0;
  right: 0;
  top: $w-profile-data__messages-container--top;

  @include t-mq($until: $f-breakpoint--lg) {
    bottom: 0;
    top: unset;
  }


  .c-absolute-dialog {
      padding: $w-profile-data__absolute-dialog--padding;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: $w-profile-data__absolute-dialog--min-width;
      width: 100%;
      text-align: center;
      min-height: unset;
      display: none;
      opacity: 1;
  }

  .c-absolute-dialog__title {
      padding-bottom: unset;
      border-style: unset;
  }

  .c-absolute-dialog__header {
      margin-bottom: unset;
  }

  .w-profile-data__show-msg-animation {
      display: block;
      animation: hide $f-duration--normal $f-easing--in forwards;
      animation-delay: $w-profile-data__absolute-dialog--animation-delay;
  }

  .w-profile-data__show-msg {
    display: block;
  }

  //Animations

  @keyframes hide {
    100% {
        opacity: 0;
    }
  }
}
