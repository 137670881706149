/* -------------------------------------------------------------------------
 * Personal details edit VARS
 *
 */

$w-personal-details-edit__form--sp-v:                                                 $f-space--medium !default;

$w-personal-details-edit__modal-icon-default--color:                                  $f-color__neutral-grey !default;
$w-personal-details-edit__modal-title-icon--sp-stack:                                 $f-space--small !default;
$w-personal-details-edit__modal-title-icon-success--color:                            $f-color__state-success !default;

$w-personal-details-edit__warning-message__icon--margin-right:                        $f-space--small !default;
$w-personal-details-edit__warning-message__text--font-size:                           $f-font-size--s !default;

$w-personal-details-edit__form-container--min-height:                                 550px !default;
$w-personal-details-edit__form-container--background-color:                           $f-color__neutral-grey--ultra-light !default;
$w-personal-details-edit__form-container--border-color:                               $f-color__neutral-grey--light !default;
$w-personal-details-edit__form-container--border:                                     1px solid $w-personal-details-edit__form-container--border-color !default;
$w-personal-details-edit__form-container--margin-bottom:                              $f-space--medium !default;

$w-personal-details-edit__loader-form-container--transform:                           translate(-50%, calc(-50% - 35px)) !default;

$w-personal-details-edit__form-fields-label--line-height:                             2 !default;
$w-personal-details-edit__form-fields-label--margin-bottom:                           4px !default;
