/* -------------------------------------------------------------------------
 * CAR ITEM VARS
 *
 */


$c-car-item--bp-medium:                                        $f-breakpoint--sml !default;
$c-car-item--bp-large:                                         $f-breakpoint--md !default;

$c-car-item--padding--bp-medium:                               $f-space--small !default;

$c-car-item__image--max-width:                                 180px !default;
$c-car-item__image--min-width:                                 100px !default;

$c-car-item__name--font-size:                                  $f-font-size--s !default;
$c-car-item__name--font-size-desktop:                          $f-font-size--l !default;
$c-car-item__name--font-weight:                                700 !default;

$c-car-item__type--font-weight:                                400 !default;
$c-car-item__type--color:                                      $f-color__brand-primary !default;
$c-car-item__type--sp-stack:                                   $f-space--tiny !default;
$c-car-item__type--sp-inline:                                  $f-space--tiny !default;

$c-car-item__features--icon-color:                             $f-color__neutral-grey--semi-dark !default;
$c-car-item__features--sp-inline--bp-small:                    $f-space--medium !default;
$c-car-item__features-item--sp-inline--bp-small:               $f-space--small !default;
$c-car-item__features-item--margin-top:                        $f-space--tiny !default;
$c-car-item__features--font-size-desktop:                      $f-font-size--s !default;
$c-car-item__features--font-size:                              $f-font-size--xs !default;

$c-car-item__label--sp-stack:                                  $f-space--tiny !default;
$c-car-item__label--background-color:                          $f-color__neutral-grey--light !default;
$c-car-item__label--color:                                     $f-color__neutral-grey--semi-dark !default; 
$c-car-item__label--sp-inset-v:                                $f-space--tiny - 2 !default; 
$c-car-item__label--margin-left:                               $f-space--tiny !default; 

$c-car-item__details--max-width:                               calc(100% - (#{$c-car-item__image--max-width} + #{$c-car-item--padding--bp-medium})) !default;