
.c-product-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: $c-product-card--border;
  border-radius: $c-product-card--border-raidus;
  margin-bottom: $c-product-card--f-space;
  background-color: $c-product-card--white;
  position: relative;
  box-shadow: $c-product-card--shadow;

  @include t-mq($from: $c-product-card-breakpoint--md) {
    flex-direction: row;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include t-mq($from: $c-product-card-breakpoint--sm) {
      flex-direction: row;
      border-bottom: $c-product-card--border;
    }

    @include t-mq($from: $c-product-card-breakpoint--md) {
      border-right: $c-product-card--border;
      border-bottom: none;
    }

    &-header {
      padding: $c-product-card--space;
      padding-bottom: 0;

      @include t-mq($from: $c-product-card-breakpoint--sm) {
        padding: $c-product-card__content-header--padding;
      }
      &--rating {
        display: flex;
        padding-bottom: $c-product-card__rating--padding-bottom;
        justify-content: space-between;
      }

      .c-product-card__favourite {
        @include t-mq($from: $c-product-card-breakpoint--sm) {
          display: none;
        }
      }

      .c-product-card__image-wrapper {
        position: relative;
        z-index:  $c-product-card__image-wrapper--z-index;
        @include sp-stack($c-product-card__image-wrapper--sp-stack--from-mq);
        @include t-mq($from: $c-product-card-breakpoint--sm) {
          width: $c-product-card__image-wrapper--width-sm;
        }
        @include t-mq($from: $c-product-card-breakpoint--md, $until: $c-product-card__image-wrapper--bp) {
          width: $c-product-card__image-wrapper--width-md;
        }
        @include t-mq($from: $c-product-card-breakpoint--lg) {
          width: $c-product-card__image-wrapper--width-lg;
        }
        .tns-outer {
          border-radius: $c-product-card--space-tiny;
        }

        .c-product-card__collection {
          pointer-events: none;
          position: absolute;
          left: $c-product-card__collection--left;
          top: $c-product-card__collection--top;
          z-index: $c-product-card__collection--z-index;

          @include t-mq($until: $c-product-card-breakpoint--sm) {
            font-size: $c-product-card__collection--font-size--until-mq;
          }

        }
      }
    }

    &-footer {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-bottom: 0;

      &-title {
        padding: $c-product-card__name--padding;
        padding-bottom: $c-product-card__name--padding-bottom;
        padding-top: $c-product-card__name--padding-top;
        border-bottom: $c-product-card--border;
        position: relative;

        @include t-mq($from: $c-product-card-breakpoint--sm) {
          border-bottom: none;
          padding-top: $c-product-card--f-space;
        }
        .c-product-card__name {
          padding-bottom: $c-product-card--space/4;
          @include t-mq($from: $c-product-card-breakpoint--sm) {
            font-size: $c-product-card--f-space;
          }
        }
        .c-product-card__favourite {
          position: absolute;
          top: $c-product-card--f-space--small;
          right: $c-product-card--f-space--small;
          @include t-mq($until: $c-product-card-breakpoint--sm) {
            display: none;
          }
        }

        .c-product-card__breadcrumbs .c-breadcrumbs__item {
          min-width: initial;
          line-height: $c-product-card--f-space;
          &::after {
            line-height: $c-product-card--f-space;
          }
        }

        .c-product-card__breadcrumbs,
        .c-product-card__breadcrumbs .c-breadcrumbs__link {
          color: $c-product-card__breadcrumbs--color;
          font-size: $c-product-card--f-space--small;
        }

        .c-product-card__mostbooked {
          font-size: $c-product-card_mostbooked--font-size;
          &-icon {
            margin-right: $c-product-card_mostbooked-icon--margin-right;
            font-size: $c-product-card_mostbooked-icon--font-size;
            @include sp-inline($c-product-card__mostbooked-icon--sp-inline);
          }
        }

        .c-product-card__rating {
          display: block;
          color: $c-product-card__rating--color;
          font-size: $c-product-card--f-space--small;
          height: $c-product-card--f-space--small;
          @include sp-stack($c-product-card--space);
        }
      }

      &-body {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: $c-product-card__price--padding-sm;

        @include t-mq($from: $c-product-card-breakpoint--sm) {
          padding-top: $c-product-card--space-small;
        }
        .c-product-card__usps {
          font-size: $c-product-card__usps--font-size;

          .c-bullet-list__item {
            @include sp-stack($c-product-card__usp-item--sp-stack);
          }
        }

        .c-product-card__urgency-messages {
          font-size: $c-product-card__urgency-messages--font-size;
          margin-top: $c-product-card__urgency-message-margin-top;

          .c-bullet-list__item {
            @include sp-stack($c-product-card__urgency-message-item--sp-stack);
          }

          .c-bullet-list__icon {
            color: $c-product-card__urgency-message-icon--color;
          }
        }

        .c-product-card__action-wrapper {
          margin: 0;
        }

        .c-product-card__action {
          width: 100%;
          text-align: left;
          cursor: pointer;
          display: block;
          border-width: $c-product-card__action--border-width;
          border-style: $c-product-card__action--border-style;
          position: relative;
          z-index: $c-product-card__action--index;
          font-size: $c-product-card__action--font-size;
          font-weight: $c-product-card__action--font-weight;
          transition: color $c-product-card__action--duration;
          @include sp-inset($c-product-card__action--sp-inset);
          color: $c-product-card__action--color;
          font-family: $c-product-card__action-font-family;
          padding-right: $c-product-card__action--padding-right;
          padding-left: $c-product-card__action--padding-left;
          line-height: $c-product-card__action-line-height;

          &:hover {
            text-decoration: none;
          }

        }

        .c-product-card__action-icon {
          position: absolute;
          right: $c-product-card__action--sp-inset;
          top: 50%;
          font-size: $c-product-card__action-icon--font-size;
          transform: translateY(-50%);
        }

        .c-product-card__content-footer-campaigns {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: auto;

          .c-product-card__action-wrapper{
            width: 100%;
            flex-grow: 1;
            margin-top: $c-product-card__action-wrapper--margin--top;

            @include t-mq($from: $c-product-card-breakpoint--sm) {
              width: auto;
              max-width: $c-product-card__action-max-width;
            }
            .c-product-card__action {
              border: none;
              border-radius: $c-product-card__action--border-radius;
            }
          }
          .c-product-card__yield {
            height: $c-product-card__yield--height;
            width: auto;
            display: initial;
            padding-left: $c-product-card--f-space--tiny;
            padding-top: $c-product-card--f-space--tiny;


            @include t-mq($until: $c-product-card-breakpoint--sm) {
              display: none;
            }
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;

    &.unavailable {
      flex-direction: unset;
      align-items: center;
    }

    @include t-mq($from: $c-product-card-breakpoint--sm) {
      flex-direction: row;
    }
    @include t-mq($from: $c-product-card-breakpoint--md) {
      flex-direction: column;
      width: $c-product-card__footer--width;
    }
    // TODO change this so it lists properly
    &-services {
      border-top: $c-product-card--border;
      border-bottom: $c-product-card--border;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(auto-fill, $c-product-card__services--grid-row);
      grid-auto-rows: $c-product-card__services--grid-row;
      padding: $c-product-card__services--padding;
      font-size: $c-product-card__services--font-size;
      font-weight: 400;
      flex-grow: 1;
      margin: auto;
      align-self: center;
      width: 100%;
      column-gap: $c-product-card__services--column-gap;

      @include t-mq($from: $c-product-card-breakpoint--sm) {
        border-top: 0;
        border-bottom: 0;
        margin: 0;
        padding-top: $c-product-card--f-space;
      }

      @include t-mq($from: $c-product-card-breakpoint--md) {
        margin: auto;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fill, $c-product-card__services--grid-row--md);
      }

      .c-bullet-list__item {
        margin: 0;

        &:last-of-type {
          margin-bottom: 0;
        }
        .c-bullet-list__icon {
          font-size: $c-product-card__bullet-list-icon--font-size;
          margin-right: $c-product-card--space-small;

          &::before {
            font-weight: 600;
          }
        }

        .c-bullet-list__text {
          line-height: 1;
          transform: translateY(13%);
          padding-left: 0;
        }
      }
    }

    &-price {
      padding: $c-product-card__price--padding-sm;

      @include t-mq($from: $c-product-card-breakpoint--sm) {
        padding: $c-product-card__price--padding-md;
        min-width: $c-product-card__price--mid-width;
      }

      @include t-mq($from: $c-product-card-breakpoint--md) {
        min-width: none;
      }

      .c-price {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        //Todo change this
        & > div {
          display: inline-flex;
          &.c-price__discount {
            width: 100%;
          }
          &:not(.c-price__discount):not(.c-price__info) {
            flex: 1;
          }
        }

        &__sale-info{
          display: none !important;
        }

        &__old-price.discount-ab-test {
          display: inline-block;
          color: $c-product-card--grey;
          font-size: $c-product-card--font-size-sm;
          font-weight: 700;
          margin-left: 0;
          margin-right: $c-product-card__price-old-price--margin-right;
        }

        &__discount {
          font-size: $c-product-card--font-size-s;
        }

        &__number {
          align-items: baseline;
          justify-content: end;
        }

        &__info {
          align-items: end;
          color: $c-product-card--grey;
          margin-bottom: 0;
          flex-basis: 100%;
          justify-content: end;
        }

        &__legend {
          font-size: $c-product-card--font-size-sm;
        }

        &__value {
          font-size: $c-product-card__price--font-size;
          margin-left: 0;
        }

        &__public-price-detail {
          flex-basis: 100% !important;
          justify-content: flex-end;
        }
      }
    }

    .c-product-card__cta {
      display: block;
      margin-top: $c-product-card--space;
      font-size: $c-product-card__cta--font-size;
      position: relative;
      z-index: $c-product-card__cta--z-index;

      @include sp-inset-v($c-product-card__cta--sp-inset-v);
  
      &[target="_blank"] {
        display: none;
      }
      @include t-mq($from: $c-product-card-breakpoint--sm) {
        & {
          display: none;
        }
    
        &[target="_blank"] {
          display: inline-block;
        }
      }
    }

    &-unavailable-info{
      padding: $c-product-card--space;
      border-top: $c-product-card--border;
      @include t-mq($from: $c-product-card-breakpoint--sm) {
        border-top: 0;
      }

      & > div {
        text-align: left;
        @include t-mq($from: $c-product-card-breakpoint--md) {
          text-align: center;
        }
      }

      &-title {
        font-size: $c-product-card__unavailable-title--font-size;
        font-weight: $c-product-card__unavailable-title--font-weight;
        @include sp-stack($c-product-card--space)
      }

      &-text {
        @include sp-stack($c-product-card--f-space);
        @include t-mq($until: $c-product-card-breakpoint--md) {
          max-width: 70ch;
        }
      }
      .c-product-card__unavailable-button {
        @include t-mq($until: $c-product-card-breakpoint--md) {
          max-width: $c-product-card__unavailable-button--max-width;
        }
      }
    }
  }
  &__link {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  
    &[target="_blank"] {
      display: none !important;
    }
    @include t-mq($from: $c-product-card-breakpoint--sm) {
      & {
        display: none !important;
      }
  
      &[target="_blank"] {
        display: inline-block !important;
      }
    }
  }
}

.c-product-card__favourite {
  box-shadow: $c-product-card__favourite--box-shadow;
  border: $c-product-card__favourite--border;
  color: $c-product-card__favourite--color;
  z-index: 1;
  &::after {
    font-weight: 600;
  }
}
