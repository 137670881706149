/* -------------------------------------------------------------------------
 * Booking participant summary -list VARS
 *
 */

$w-booking-participant-summary__heading-title--margin-top:                                        $f-space--small !default;
$w-booking-participant-summary__heading-title--margin-bottom:                                     $f-space--small !default;

$w-booking-participant-summary__table--border-bottom-width:                                       1px !default;
$w-booking-participant-summary__table--border-bottom-style:                                       solid !default;
$w-booking-participant-summary__table--border-bottom-color:                                       $f-color__neutral-grey--light !default;
$w-booking-participant-summary__table-last-child--margin-bottom:                                  $f-space--medium !default;
$w-booking-participant-summary__table-item-left--flex-basis:                                      150px !default;
$w-booking-participant-summary__table-item-left--flex-grow:                                       0 !default;

$w-booking-participant-summary__collapse--margin-top:                                             $f-space--small !default;
$w-booking-participant-summary__collapse--font-size:                                              16px !default;
