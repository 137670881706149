/* -------------------------------------------------------------------------
 * LAYOUT MIXINS
 *
 */

// Object as a mixin
// --------------------------------------------------

@mixin o-layout($_space-value: $o-layout__space) {
  display: block;
  margin-left: -1 * s-core-px-to-rem($_space-value);
  margin-top: -1 * s-core-px-to-rem($_space-value);
  padding: 0;
  list-style: none;
  font-size: 0;
}

@mixin o-layout__item($_space-value: $o-layout__space) {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding-left: s-core-px-to-rem($_space-value);
  padding-top: s-core-px-to-rem($_space-value);
  font-size: s-core-px-to-rem($s-core__font-size);
}

// Space modifiers
// --------------------------------------------------

@mixin o-layout__mod-space($_space-name: none, $_space-value: 0) {
  margin-left: -1 * s-core-px-to-rem($_space-value);
  margin-top: -1 * s-core-px-to-rem($_space-value);
}

@mixin o-layout__item-mod-space($_space-name: none, $_space-value: 0) {
  padding-left: s-core-px-to-rem($_space-value);
  padding-top: s-core-px-to-rem($_space-value);
}

// Vertical alignment modifiers
// --------------------------------------------------

@mixin o-layout__mod-alignment-v($_vertical-alignment: top) {
  vertical-align: $_vertical-alignment;
}

// Horizontal alignment modifiers
// --------------------------------------------------

@mixin o-layout__mod-alignment-h($_alignment: left) {
  .o-layout--#{$_alignment} {
    text-align: $_alignment;

    > .o-layout__item {
      text-align: left;
    }
  }
}

// Reverse modifier
// --------------------------------------------------

@mixin o-layout--reverse() {
  direction: rtl;
}

@mixin o-layout__item--reverse() {
  direction: ltr;
  text-align: left;
}

// Stretch modifier
// --------------------------------------------------

@mixin o-layout--stretch() {
  display: flex;
  flex-wrap: wrap;
}

@mixin o-layout__item--stretch() {
  display: flex;
}

@mixin o-layout__item--stretch-center() {
  justify-content: center;
}

@mixin o-layout__item--stretch-right() {
  justify-content: flex-end;
}

// Item Reverse modifier
// --------------------------------------------------
@mixin o-layout__item-mod--reverse() {
  direction: rtl;
  text-align: right;
  > * {
    direction: ltr;
    text-align: right;
  }
}

// Unset as mixin
// --------------------------------------------------

@mixin o-layout--unset() {
  display: inherit;
  margin: inherit;
  padding-left: inherit;
  padding-top: inherit;
  list-style: inherit;
  font-size: inherit;
}

@mixin o-layout__item--unset() {
  box-sizing: inherit;
  display: inherit;
  vertical-align: inherit;
  width: auto;
  padding-left: inherit;
  padding-top: inherit;
  font-size: inherit;
}

@mixin o-layout__item-reverse--unset() {
  direction: inherit;
  text-align: inherit;
  > * {
    direction: inherit;
    text-align: inherit;
  }
}

// Smart layout columns
// --------------------------------------------------

@import "smart";
