/* -------------------------------------------------------------------------
 * Account bookings
 *
 */

@import 'fr-account-vouchers-overview';

.t-account-bookings {
  .t-account-bookings__booking-list {
    padding: $t-account-bookings__booking-list--sp-inset-v 0;

    .t-account-bookings__booking-list__card-link {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    .c-slider__item {
      margin-bottom: $fr-account-upcoming-bookings__c-slider-item--margin-bottom;
    }

    .tns-slide-active + :not(.tns-slide-active) > .c-card {
      /* Avoid displaying the border of the next card */
      padding-left: 2px;
      box-shadow: none;
    }

    .c-card {
      height: 100%;
      transition: box-shadow $t-account-bookings__booking-list__card--shadow--transition-duration $t-account-bookings__booking-list__card--shadow--transition-timing-function;

      &:hover {
        box-shadow: $t-account-bookings__booking-list__card--hover--shadow $t-account-bookings__booking-list__card--hover--shadow-color;
      }

      &.t-account-bookings__booking-list__card--faint {
        .c-img {
          opacity: $t-account-bookings__booking-list__card-img--opacity;
        }
      }
    }

    .c-card__title {
      font-size: $t-account-bookings__booking-list__card-title--font-size;
    }

    .c-card__subtitle {
      font-size: $t-account-bookings__booking-list__card-subtitle--font-size;
    }

    .c-card__body-heading {
      @include sp-stack($t-account-bookings__booking-list__card-heading--margin-bottom);
    }

    .t-account-bookings__booking-list__card-content {
      font-size: $t-account-bookings__booking-list__card-content--font-size;

      .m-icon {
        margin-right: $t-account-bookings__booking-list__card-content-icon--margin-right;
      }
    }
  }

  .fr-account-booking-message {
    @include sp-inset-v($t-account-bookings__fr-account-booking-message--sp-inset-v);
  }

  .w-customer-discounts {
    margin-bottom: $w-customer-discount--margin-bottom;
  }
}

.t-account-bookings__info-message:not(:last-child) {
  margin-bottom: $t-account-bookings__info-message--margin-bottom;
}

.t-account-bookings__info-message+.w-customer-discounts {
  margin-top: -$t-account-bookings__info-message--margin-bottom/2;
}

.t-account-bookings__slider .c-slider__content {
  opacity: 0;

  &.tns-slider {
    opacity: 1;
  }
}

.t-account-bookings__slider  {
  &.c-slider--outer-navigation .c-slider__buttons--arrow--next {
    right: $t-account-bookings__slider-button--offset;
  }

  &.c-slider--outer-navigation .c-slider__buttons--arrow--prev {
    left: $t-account-bookings__slider-button--offset;
  }

  &.c-slider--outer-navigation .tns-outer {
    padding: 0;
  }
}

.fr-account-upcoming-bookings .fr-account-booking__add-booking-container,
.fr-account-booking-message--error-container .fr-account-booking__add-booking-container {
  text-align: center;
  margin-top: $fr-account-upcoming-bookings-add-booking--margin-top;
  margin-bottom: $fr-account-upcoming-bookings-add-booking--margin-bottom;
}

.fr-account-booking-message--error-container {
  padding-top: $f-space--large;
}

.fr-account-booking__add-booking-link {
  cursor: pointer;
}

.fr-account-booking__add-booking-form .c-form__item {
  width: 100%;
}

.fr-account-booking-add-booking__error-msg,
.fr-account-booking__add-booking-modal {
  text-align: left;
}

.fr-account-booking-message .fr-account-booking-add-booking__error-msg {
  margin-bottom: 0;
}

.t-account-bookings__title {
  @include m-heading(map_get($m-heading__mod-sizes, "medium"));
  @include t-text-crop-disable();

  @include t-mq($from: $t-account-bookings--bp) {
    @include m-heading($t-account-bookings__title-heading);
  }
}

.fr-account-bookings__title-add-booking-container .t-account-bookings__title {
  margin-bottom: 0;
}

.fr-account-bookings__title-add-booking-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fr-account-bookings__title-add-booking-container .c-btn {
  @include t-mq($until: "sml") {
    display: none;
  }
}

.fr-account-booking__add-booking-container {
  @include t-mq($from: "sml") {
    display: none;
  }
}

.t-account-bookings__title,
.fr-account-bookings__title-add-booking-container {
  margin-bottom: $f-space--medium;
}

.t-account-bookings .l-site-footer__main,
.t-account-bookings .l-site-footer__main-extra {
  background-color: $f-color__neutral-grey--ultra-light;
}

.t-account-bookings--grey-bg {
  @include l-site-main__content__wide-background-color($f-color__neutral-grey--ultra-light);
  padding-bottom: $f-space--medium;

  @include t-mq($from: "sml") {
    padding-bottom: $f-space--large;
  }
}

.t-account-bookings--grey-bg .fr-account-booking-message--error-container,
.t-account-bookings--grey-bg .fr-account-vouchers-overview,
.t-account-bookings--grey-bg .fr-account-booking-message,
.t-account-bookings--grey-bg .fr-faq-top-questions {
  margin-bottom: $f-space--large;
}

.t-account-bookings .l-site-main {
  padding-bottom: 0;
}

.fr-account-booking-message--error-container .fr-account-booking-message {
  margin-bottom: 0;
}

.fr-account-booking-message--error-container .fr-account-booking__add-booking-container {
  margin-top: $f-space--medium;
}

.t-account-bookings .fr-account-previous-bookings {
  padding-top: 0;
}

.t-account-bookings__booking-list__card-link .c-card .c-card__header-label {
  left: $t-account-bookings__booking-list-card-label--left;
  right: auto;
}

.t-account-bookings__booking-list__card-link .t-account-bookings__two-labels .c-card__header-label {
  top: $t-account-bookings__booking-list-card-two-labels--top;
}

.t-account-bookings__booking-list__card-link .t-account-bookings__three-labels .c-card__header-label {
  top: $t-account-bookings__booking-list-card-tertiary-label--top;
}

.t-account-bookings__booking-list__card-link .c-label.c-label.t-account-bookings__label--tertiary,
.t-account-bookings__booking-list__card-link .c-label.c-label.t-account-bookings__label--secondary {
  position: absolute;
  left: $t-account-bookings__booking-list-card-label--left;
}

.t-account-bookings__booking-list__card-link .c-label.c-label.t-account-bookings__label--secondary {
  top: $t-account-bookings__booking-list-card-secondary-label--top;
}

.t-account-bookings__booking-list__card-link .c-label.c-label.t-account-bookings__label--tertiary {
  top: $t-account-bookings__booking-list-card-two-labels--top;
}

.fr-account-delete {
  display: flex;
  justify-content: center;
}
