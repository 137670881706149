.fr-acco-description__label {
  font-weight: $fr-acco-description__label--weight;
}

.t-accommodation .fr-tabs-container {
  @include t-mq($until: $t-accommodation--bp) {
    box-shadow: $t-accommodation__tabs-container--box-shadows;
  }
}

.fr-social-share .c-icon-row__link {
  color: $fr-social-share__icon--color;

  &:hover,
  &:focus {
    color: $fr-social-share__icon--hover-color;
  }
}

.fr-acco-room-types {
  .fr-acco-room-types__extra-info-title {
    @include sp-stack($fr-acco-room-types__extra-info-title--sp-stack);
    color: $fr-acco-room-types__extra-info-title--color;
  }
}

.t-accommodation-reviews__reviews-overview .fr-reviews-overview {
  box-shadow: $fr-reviews-overview--shadow;

  .fr-reviews-overview__progress-bar progress {
    background: $fr-reviews-overview__progress-bar--background;
  }

  .fr-reviews-overview__progress-circle .c-progress-circle__indicator {
    background-color: $fr-reviews-overview__progress-circle--background;
  }
}

.fr-booking-gate {
  .c-choice-list__option {
    .c-radio-button__text {
      font-size: $fr-booking-gate__choice-list-option--font-size;
    }

    .c-radio-button__element {
      height: auto;
    }
  }

  .w-selected-state__price-overview .c-table__item {
    border-color: $fr-booking-gate__table__item--border-color;
  }
}

.fr-reviews-list
  .w-reviews-list
  .w-reviews-list__item
  .c-collapse.after-fading-light:not(.in):not(.is-idle):after {
  background-image: linear-gradient(
    to bottom,
    $c-collapse--after-fading-transparent,
    $fr-reviews-collapse__after-fading-color--white
  );
}

.t-accommodation .fr-promoted-price .w-promoted-price {
  @include t-mq($from: $t-accommodation__w-promoted-price--first-bp) {
    top: $t-accommodation__w-promoted-price--top-first-bp;
  }
  @include t-mq($from: $t-accommodation__w-promoted-price--second-bp) {
    top: $t-accommodation__w-promoted-price--top-second-bp;
  }
  @include t-mq($from: $t-accommodation__w-promoted-price--third-bp) {
    top: 0px;
  }
  @include t-mq($from: $t-accommodation__w-promoted-price--fourth-bp) {
    top: $t-accommodation__w-promoted-price--top-fourth-bp;
  }
}

.fr-reviews-overview-main {
  .fr-reviews-overview{
    box-shadow: unset;
  }

  .fr-reviews-overview__main-rating {
    .c-number-score__title {
      font-size: $t-accommodation__review-overview--main-score-title-font-size;
    }
  }
}

.fr-acco-description__collapse p {
  line-height: $fr-acco-description__collapse--line-height;
}

.fr-destination-exceptional-info__table .fr-destination-exceptional-info__table-row-header.c-table__item {
  text-transform: capitalize;

  .fr-destination-exceptional-info__table-cell {
    font-family: $fr-destination-exceptional-info__table-row-header--font-family;
  }
}
