/* -------------------------------------------------------------------------
 * SITE HEADER
 *
 */

// Selectors output
// -------------------------------------------------

.l-site-main {
  @include sp-inset-v($l-site-main--sp-inset-v);

  @include t-mq($from: $l-site-main--bp) {
    @include sp-inset-v($l-site-main--sp-inset-v--from-bp);
  }
}

// Main top
// -------------------------------------------------

.l-site-main__top {
  margin-top: -1 * $l-site-main__top--sp-inset-v;
  margin-bottom: $l-site-main__top--sp-inset-v;

  @include t-mq($from: $l-site-main--bp) {
    margin-top: -1 * $l-site-main__top--margin-top--from-bp;
    margin-bottom: $l-site-main__top--margin-bottom--from-bp;
  }

  @include t-mq($from: $l-site-main--bp-smaller) {
    margin-bottom: $l-site-main__top--margin-bottom--from-bp-smaller;
  }
}

.l-site-main__top--sm-margin-bottom {
  margin-bottom: $l-site-main__top--sm-margin-bottom--margin-bottom;
}


// Main content
// -------------------------------------------------

.l-site-main__content {
  .c-breadcrumbs:first-child {
    margin-bottom: $l-site-main--breadcrumbs--margin-bottom;

    @include t-mq($from: $l-site-main--bp) {
      margin-top: -1 * $l-site-main--breadcrumbs--margin-top;
    }
  }

  > [class^="fr-"]:not(.fr-booking-steps):not(.fr-search-promo-title) {
    @include sp-stack($l-site-main__children--sp-stack);

    &:last-child {
      @include sp-stack($l-site-main__children-last--sp-stack);

      @include t-mq($from: $l-site-main--bp) {
        @include sp-stack($l-site-main__children-last--sp-stack--from-bp);
      }
    }
  }

  .cs-default--light.l-site-main__content__wide-background {
    @include l-site-main__content__wide-background-color($cs-default--light--background)
  }
}
