.w-account-menu {
  display: inline-block;
  position: relative;
  z-index: $w-account-menu__z-index;
}

.w-account-menu .w-account-menu__nav-item .c-favorite-collector,
.w-account-menu a {
  font-weight: $w-account-menu__link__font-weight;
}

.w-account-menu a {
  font-size: $w-account-menu__link__font-size;
  line-height: $w-account-menu__link__line-height;
}

.w-account-menu__dropdown {
  display: none;
  width: $w-account-menu__dropdown__width;
  position: absolute;
  right: 0;
  box-shadow: $w-account-menu__dropdown__box-shadow;
  background-color: $w-account-menu__dropdown__bg-color;
  border-radius: $w-account-menu__dropdown__border-radius;
  padding-bottom: $w-account-menu__dropdown__margin-bottom;
  top: $w-account-menu__dropdown__top;
}

.w-account-menu.is-open .w-account-menu__dropdown {
  display: block;
}

.w-account-menu__login-container {
  display: flex;
  flex-direction: column;
  padding: $w-account-menu__login-container__padding 0;
  margin: 0 $w-account-menu__login-container__margin;
  border-bottom: $w-account-menu__border-width solid $w-account-menu__border-color;
  text-align: center;
}

.w-account-menu__logo {
  color: $w-account-menu__logo__color;
  font-size: $w-account-menu__logo__font-size;
  margin-bottom: $w-account-menu__logo__margin-bottom;
}

a.w-account-menu__create-account-link {
  font-weight: $w-account-menu__create-account-link__font-weight;
}

.w-account-menu__login-btn {
  margin-bottom: $w-account-menu__login-btn__margin-bottom;
}

.w-account-menu__login-btn span {
  color: $w-account-menu__login-btn__color;
  font-weight: $w-account-menu__login-btn__font-weight;
}

.w-account-menu__login-title {
  margin-bottom: $w-account-menu__login-title__margin-bottom;
}

.w-account-menu__button {
  display: none;
  padding: $w-account-menu__button__padding;
  font-weight: $w-account-menu__button__font-weight;
  border-radius: $w-account-menu__button__border-radius;
  position: relative;

  @include t-mq($from: $f-breakpoint--md) {
    display: inline-block;
  }

  .c-btn__icon {
    font-size: $w-account-menu__button-icon__font-size;
    margin-bottom: $w-account-menu__button-icon__offset;
  }

  &.is-active,
  &:hover,
  &:focus-visible,
  &:focus {
    background-color: $w-account-menu__nav-item-active--background-color;
    color: $w-account-menu__button__hover-color;
  }
}

.w-account-menu .w-account-menu__button,
.w-account-menu .w-account-menu__button--sm {
  &:before {
    content: attr(avatar-content);
    display: none;
    background-color: $w-account-menu__avatar__bg-color;
    color: $w-account-menu__avatar__color;
    height: $w-account-menu__avatar__height;
    width: $w-account-menu__avatar__width;
    position: absolute;
    top: 50%;
    left: $w-account-menu__avatar__left;
    transform: translateY(-50%);
    z-index: $w-account-menu__avatar__z-index;
    border-radius: $w-account-menu__avatar__border-radius;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    line-height: $w-account-menu__avatar__height;
    font-weight: 400;
    font-size: $f-font-size--s;
  }
}

.w-account-menu .w-account-menu__button.w-account-menu__button--show-avatar,
.w-account-menu .w-account-menu__button--sm.w-account-menu__button--show-avatar {
  &:before {
    display: flex;
  }
}

.w-account-menu__button--sm {
  display: inline-block;

  @include t-mq($from: $f-breakpoint--md) {
    display: none;
  }
}

.w-account-menu__nav-item {
  list-style: none;
}

.w-account-menu__nav-link {
  display: block;
  padding: $w-account-menu__nav-link__padding;
  text-align: left;

  &:hover {
    color: $w-account-menu__nav-link__color-hover;
    background-color: $w-account-menu__nav-item-active--background-color;
  }
}

.w-account-menu__nav-item a {
  text-decoration: none;
}

.w-account-menu .w-account-menu__third-nav,
.w-account-menu .w-account-menu__first-nav {
  margin: 0;
}

.w-account-menu .w-account-menu__first-nav {
  margin-top: $w-account-menu__first-nav__margin-top;
}

.w-account-menu__nav-link-icon {
  font-size: $w-account-menu__nav-link-icon__font-size;
}

.w-account-menu__nav-item--with-icon {
  display: flex;
  align-items: center;
  gap: $w-account-menu__nav-item--with-icon__gap;
}

.w-account-menu .c-btn.w-account-menu__login-btn {
  &:hover,
  &:focus {
    color: $w-account-menu__login-btn__hover-color;
  }
}

.w-account-menu .c-btn.w-account-menu__button {
  &:hover,
  &:focus {
    color: $w-account-menu__button__hover-color;
  }
}

[data-w-account-menu__logout-item].is-hidden,
[data-w-account-menu__login-item].is-hidden {
  display: none;
}

.w-account-menu .w-account-menu__second-nav {
  padding: $w-account-menu__second-nav__padding;
  margin: $w-account-menu__second-nav__margin;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    height: $w-account-menu__border-width;
    background-color: $w-account-menu__border-color;
    left: $w-account-menu__second-nav__pseudo-element-left;
    right: $w-account-menu__second-nav__pseudo-element-right;
    position: absolute;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
}

.w-account-menu__nav-link.c-favorite-collector:after {
  position: initial;
  top: unset;
  background-color: unset;
  color: $w-account-menu__nav-link-favorite__color;
  font-size: $w-account-menu__nav-link-favorite__font-size;
  width: unset;
  height: unset;
  line-height: $w-account-menu__nav-link-favorite__line-height;
  border-radius: unset;
  font-weight: $w-account-menu__nav-link-favorite__font-weight;
  padding: 0;
  margin-left: auto;
}

.w-account-menu__form-submit {
  border-width: 0;
  width: $w-account-menu__form-submit__width;
  color: $w-account-menu__form-submit__color;
  background-color: $w-account-menu__form-submit__background-color;
  font-size: $w-account-menu__form-submit__font-size;
  font-weight: $w-account-menu__form-submit__font-weight;
  font-family: $w-account-menu__form-submit__font-family;
  line-height: $w-account-menu__form-submit__line-height;
  cursor: pointer;
}
