/* -------------------------------------------------------------------------
 * Error 500 VARS
 *
 */

$t-error__mobile-bp:                      $f-breakpoint--sml !default;
$t-error__title--sp-stack:                $f-space--small !default;
$t-error__container--margin-top:          $f-space--medium !default;
$t-error__container--line-height:         $f-line-height--medium !default;
$t-error__container--sp-stack:            $f-space--medium !default;
$t-error__items--inset:                   $f-space--medium !default;
$t-error__items--separation:              $f-space--medium !default;
$t-error__list-item--padding-left:        $f-space--medium !default;
$t-error__item--illustration--inset-h:    $f-space--large !default;
$t-error__options--icon-size:             map_get($m-icons__sizes, "tiny") / 2 !default;
$t-error__options--font-size:             $f-font-size--s !default;
$t-error__options--icon-margin-top:       2.25em !default;
