/* -------------------------------------------------------------------------
 * WIDE TEXT PLACEHOLDER VARS
 * a global placeholder on the templates to layout the wide text with a title consistently.
 *
 */

%fr-wide-text {
  display: flex;

  @include t-mq($until: "md") {
    display: block;
  }

  .c-title {
    position: sticky;
    
    width: $fr-wide-text__title--width;
    flex-shrink: 0;
    top: $fr-wide-text__title--top;
    align-self: flex-start;
    margin-right: $fr-wide-text__title--margin-right;
    margin-bottom: 0;

    @include t-mq($until: "md") {
      position: initial;
      margin-right: 0;
      max-width: unset;
      margin-bottom: $fr-wide-text__title--margin-bottom-from-bp;
    }
  }
}