/* -------------------------------------------------------------------------
 * AIRPORT FILTER
 *
 */

.w-airport-filter {
  .c-checkbox__count {
    &::before,
    &::after {
      content: inherit;
    }
  }

  &.is-hidden {
    display: none;
  }
}
