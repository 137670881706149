/* -------------------------------------------------------------------------
 * PAGE LOADER VARS
 *
 */

$w-page-loader__loader--bp:                           $f-breakpoint--md !default;
$w-page-loader__loader--width-till-md:                100% !default;
$w-page-loader__loader--z-index:                      map_get($f-z-indexes--usage, "w-page-loader") !default;

$w-page-loader__loader-bkg--width:                    100% !default;
$w-page-loader__loader-bkg--height:                   100% !default;
$w-page-loader__loader-bkg--z-index:                  map_get($f-z-indexes--usage, "w-page-loader") - $f-z-index--1 !default;
$w-page-loader__loader-bkg--color:                    rgba($f-color__neutral-white, .5) !default;
$w-page-loader__loader--enhanced-bkg--color:          rgba($f-color__neutral-grey--ultra-dark, .8) !default;

$w-page-loader__loader--left:                         50% !default;
$w-page-loader__loader--top:                          50% !default;
$w-page-loader__loader--transform:                    translate(-50%, -50%) !default;

$w-page-loader__container--until-xs-width:            90% !default;
$w-page-loader__container--width:                     480px !default;
$w-page-loader__container--bg-color:                  $f-color__neutral-white !default;
$w-page-loader__container--padding:                   $f-space--medium !default;
$w-page-loader__container--sp-stack:                  $f-space--medium !default;

$w-page-loader__icon--mb:                             $f-space--small !default;
$w-page-loader__icon--color:                          $f-color__brand-primary !default;