/* -------------------------------------------------------------------------
 * MODAL
 *
 */

// Component selector output
// --------------------------------------------------

.c-modal {
  display: none;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: $c-modal__min-width;
  max-height: calc(100vh - #{$c-modal--sp-inset});
  max-height: calc(100svh - #{$c-modal--sp-inset});
  max-width: calc(100vw - #{$c-modal--sp-inset});
  background-color: $c-modal--bg-color;
  z-index: $c-modal--z-index;
  @include o-wrapper--from();
  @include a-reveal-lift();
  transform: translate(-50%, -75%);

  &.in {
    display: block;
    @include a-reveal-lift--in();
    transform: translate(-50%, -50%);
  }

  &.is-opening,
  &.is-closing {
    display: block;
  }
}

.c-modal__header {
  position: relative;
  @include sp-inset($c-modal__header--sp-inset-v, $c-modal--sp-inset);

  .c-btn__icon {
    &::#{$m-icons__pseudo-element} {
      font-size: $c-modal__close--font-size;
      right: 0;
      left: auto;
    }
  }
}

.c-modal__header-text {
  padding-right: $c-modal__header--safe-text;
}

.c-modal__body {
  @include sp-inset($c-modal--sp-inset);

  > *:last-child {
    margin-bottom: 0;
  }
}

.c-modal__footer {
  @include sp-inset($c-modal--sp-inset);
  padding-top: 0;
}

.c-modal__close {
  display: block;
  position: absolute;
  top: 50%;
  right: $c-modal--sp-inset;
  transform: translateY(-50%);
  font-size: $f-font-size--xxs;
  @include sp-inset($f-space--none);
}

// Since we have only one backdrop, when a modal is inside a side-drawer
// we need to fake it using a shadow, only when the side drawer is open
@each $_breakpoint in $c-side-drawer--breakpoints {
  @include t-mq($until: $_breakpoint) {
    .c-side-drawer[data-c-side-drawer__until="#{$_breakpoint}"] .c-modal {
      box-shadow: 0 0 $c-modal--radius $c-modal--radius $c-modal--shadow;
    }
  }
}

// Modal size modifiers
// --------------------------------------------------

@each $_mod-name, $_mod-wrapper-size in $c-modal--sizes {
  .c-modal--#{$_mod-name} {
    width: 100%;
    @include o-wrapper--to($_mod-wrapper-size);
  }
}


// Color schemes
// --------------------------------------------------

@import "color-schemes";
