/* -------------------------------------------------------------------------
 * FLAG VARIABLES
 *
 */

// Object toggling

$o-flag--enabled:                     true !default;

// Object Params

$o-flag__alignment:                   middle !default;

// Modifiers Alignments

$o-flag__mod-alignments--enabled:     true !default;
$o-flag__mod-alignments:              (top, middle, bottom) !default;

// Modifier Reverse

$o-flag__mod-reverse--enabled:        true !default;
