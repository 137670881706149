/* -------------------------------------------------------------------------
* DROPDOWN REDIRECT GROUP
*
*/

.w-dropdown-redirect-group{
  display: flex;
  width: 100%;
}
.w-dropdown-redirect-group__wrapper,
.w-dropdown-redirect-group__wrapper--rendered {

    &:not(.rendered) {
      .c-dropdown {
        filter: blur($w-dropdown-redirect-group-dropdown--blur);
      }
    }

    &.is-hidden {
      display: none;
    }

    display: flex;
    flex-direction: column;
    width: 100%;
  
    @include t-mq($from: $w-dropdown-redirect-group--bp) {
      flex-direction: row;
      flex: 1 0 auto;
      width: 100%;
      justify-content: center;
    } 
    .c-dropdown {
        margin-right: 0;
      
        &:not(:last-child) {
          @include sp-stack($w-dropdown-redirect-group-dropdown--margin-bottom);
        }
      
        @include t-mq($from: $w-dropdown-redirect-group--bp) {
          flex: 1 100%;
          max-width: $w-dropdown-redirect-group-dropdown--max-width;
      
          &:not(:last-child) {
            margin-right: $w-dropdown-redirect-group-dropdown--margin-right;
            margin-bottom: 0;
          }
        }
    }
}

.w-dropdown-redirect-group__wrapper--rendered:not(.rendered){
  display: none;
}
  
  