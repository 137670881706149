
.w-booking-basket__price-total--amount,
.w-booking-basket__price-person--amount,
.w-booking-basket__acco-name,
.w-booking-basket__services--header-title {
  font-family: $w-booking-basket__titles-eliza--font-family;
  font-weight: $w-booking-basket__titles-eliza--font-weight;
}

.w-booking-basket__price-total--title {
  font-family: $w-booking-basket__titles-eliza--font-family;
}

.w-booking-basket__services--header-title {
  font-size: $w-booking-basket__services--header-title--font-size;
}

.w-booking-basket__acco-name {
  @include t-mq($until: $w-booking-basket__mobile-breakpoint) {
    line-height: $w-booking-basket__acco-name--line-height-from-bp;
  }
}

.w-booking-basket__content {
  @include sp-inset($w-booking-basket__content--sp-inset);
}