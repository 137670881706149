/* -------------------------------------------------------------------------
* COLLECTION TEASER
*
*/

.c-collection-teaser {
  padding-top: $c-collection-teaser--padding-top;
  position: relative;
}

.c-collection-teaser__container {
  border-radius: $c-collection-teaser--border-radius;
  overflow: hidden;
}

.c-collection-teaser__label {
  z-index: $c-collection-teaser__label--z-index;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.c-collection-teaser__description {
  padding: $c-collection-teaser__description--vertical-padding $c-collection-teaser__description--horizontal-padding;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.c-collection-teaser__title, .c-collection-teaser__container-main {
  @include t-text-crop-disable();
}

.c-collection-teaser__title {
  @include sp-stack($c-collection-teaser__title----sp-stack);
}

.c-collection-teaser__usps {
  background-color: $c-collection-teaser__usps--background-color;
  padding: $c-collection-teaser__usps--padding;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.c-collection-teaser__usps > * {
  width: 100%;
}

.c-collection-teaser__usps .c-bullet-list__icon {
  color: $c-collection-teaser__usps-icon--color;
}

// LARGE VARIANT

.c-collection-teaser--large {

  .c-collection-teaser__container-top {
    @include t-mq($from: $c-collection-teaser--tablet-breakpoint) {
      display: flex;
    }
  }

  .c-collection-teaser__img {
    @include t-mq($from: $c-collection-teaser--tablet-breakpoint) {
      width: initial;
      min-width: $c-collection-teaser--large__img--width;
    }
  }

  .c-collection-teaser__label {
    @include t-mq($from: $c-collection-teaser--tablet-breakpoint) {
      left: $c-collection-teaser--large__label--left-position;
    }
  }

  .c-collection-teaser__description {
    @include t-mq($from: $c-collection-teaser--tablet-breakpoint) {
      padding: $c-collection-teaser__description--horizontal-padding;
    }
  }

  .c-collection-teaser__usps {
    padding: $c-collection-teaser__usps--padding - $c-collection-teaser--large__usps--padding;

    > * {
      @include t-mq($from: $c-collection-teaser--tablet-breakpoint) {
        margin-bottom: 0;
        width: $c-collection-teaser--large__usps--tablet-width;
        padding: $c-collection-teaser--large__usps--padding;
      }

      @include t-mq($from: $c-collection-teaser--desktop-breakpoint) {
        width: $c-collection-teaser--large__usps--desktop-width;
      }
    }
  }

  .c-collection-teaser__title, .c-collection-teaser__container-main {
    @include t-mq($from: $c-collection-teaser--tablet-breakpoint) {
      overflow: hidden;
      // 3 ellipsis at the end of the 4 line if the text is trimmed(not supported in ie11)
      display: -webkit-box;
      box-orient: vertical;
      -moz-box-orient: vertical;
      -webkit-box-orient: vertical;
    }
  }

  .c-collection-teaser__title {
    @include t-mq($from: $c-collection-teaser--tablet-breakpoint) {
      -webkit-line-clamp: $c-collection-teaser--large__title--line-clamp;
    }
  }

  .c-collection-teaser__container-main {
    @include t-mq($from: $c-collection-teaser--tablet-breakpoint) {
      -webkit-line-clamp: $c-collection-teaser--large__container-main--line-clamp;
    }
  }
}
