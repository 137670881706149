/* -------------------------------------------------------------------------
 * CONTENT BLOCK
 */

.c-content-block {
}

.c-content-block__header,
.c-content-block__body,
.c-content-block__footer {
  @include sp-stack($c-content-block--spacing-stack);

  &:last-child,
  &:empty {
    margin-bottom: 0;
  }
}

.c-content-block__body {
  @include sp-stack($c-content-block__body--spacing-stack);
}

.c-content-block__title {
  + .c-content-block__subtitle {
    color: $c-content-block__subtitle--color;
    margin-top: $c-content-block__subtitle--spacing;
  }
}


// Size Modifiers
// -------------------------------

// Small modifier
.c-content-block--small {
  .c-content-block__header,
  .c-content-block__body,
  .c-content-block__footer {
    @include sp-stack($c-content-block--small--spacing-stack);

    &:last-child,
    &:empty {
      margin-bottom: 0;
    }
  }
  .c-content-block__body {
    @include sp-stack($c-content-block__body--small--spacing-stack);
  }
}

// Large modifier
.c-content-block--large {
  .c-content-block__header,
  .c-content-block__body,
  .c-content-block__footer {
    @include sp-stack($c-content-block--large--spacing-stack);

    &:last-child,
    &:empty {
      margin-bottom: 0;
    }
  }
  .c-content-block__body {
    @include sp-stack($c-content-block__body--large--spacing-stack);
  }
}
