// Color schemes overrides
// --------------------------------------------------

.cs-default.cs-default--light {
  .o-swipe-btn {
    color: $o-swipe__button-color--cs-default--light;

    &:hover {
      color: $o-swipe__button-color--hover--cs-default--light;
    }
  }

  .o-swipe-btn--left {
    background: linear-gradient(to right, $o-swipe__button-bg-initial--cs-default--light 40%, rgba($o-swipe__button-bg-initial--cs-default--light, 0));
  }

  .o-swipe-btn--right {
    background: linear-gradient(to left, $o-swipe__button-bg-initial--cs-default--light 40%, rgba($o-swipe__button-bg-initial--cs-default--light, 0));
  }
}


.cs-brand-primary {
  .o-swipe-btn {
    color: $o-swipe__button-color--cs-brand-primary;

    &:hover {
      color: $o-swipe__button-color--hover--cs-brand-primary;
    }
  }

  .o-swipe-btn--left {
    background: linear-gradient(to right, $o-swipe__button-bg-initial--cs-brand-primary 40%, rgba($o-swipe__button-bg-initial--cs-brand-primary, 0));
  }

  .o-swipe-btn--right {
    background: linear-gradient(to left, $o-swipe__button-bg-initial--cs-brand-primary 40%, rgba($o-swipe__button-bg-initial--cs-brand-primary, 0));
  }
}


.cs-brand-primary.cs-brand-primary--dark {
  .o-swipe-btn {
    color: $o-swipe__button-color--cs-brand-primary--dark;

    &:hover {
      color: $o-swipe__button-color--hover--cs-brand-primary--dark;
    }
  }

  .o-swipe-btn--left {
    background: linear-gradient(to right, $o-swipe__button-bg-initial--cs-brand-primary--dark 40%, rgba($o-swipe__button-bg-initial--cs-brand-primary--dark, 0));
  }

  .o-swipe-btn--right {
    background: linear-gradient(to left, $o-swipe__button-bg-initial--cs-brand-primary--dark 40%, rgba($o-swipe__button-bg-initial--cs-brand-primary--dark, 0));
  }
}
