/* -------------------------------------------------------------------------
 * DD GRID
 *
 */

// General layout

.w-dd-grid__wrapper {
  display: flex;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: $w-dd-grid__panorama--padding;;
    left: 0;
    right: 0;
    background-color: $w-dd-grid__wrapper-after--background-color;
    border: $w-dd-grid__wrapper-after--border;
  }
}

.w-dd-grid__right-area {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  padding-bottom: $w-dd-grid__panorama--padding;
}

.w-dd-grid__panorama {
  position: absolute;
  display: flex;
  white-space: nowrap;
  max-width: 100%;
  margin-bottom: 0;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.w-dd-grid__column {
  display: inline-flex;
  flex-direction: column;
}

.w-dd-grid__left-area {
  z-index: 2;
  padding-bottom: $w-dd-grid__panorama--padding;
}

// Cell decoration
.w-dd-grid__cell,
button.w-dd-grid__cell {
  height: $w-dd-grid__cell--height;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  min-width: 75px;
  border: $w-dd-grid__cell--border-style $w-dd-grid__cell--border-size $w-dd-grid__cell--border-color;
  border-top-width: 0;
  border-left-width: 0;
  background-color: $w-dd-grid__cell--bg-color;
  color: $w-dd-grid__cell--color;
  padding: $w-dd-grid__cell--padding ($w-dd-grid__cell--padding * 2);
  margin: 0;
  font-family: $f-font-family__primary;
  line-height: 1;
  transition: background-color $f-duration--fast;
  position: relative;

  @include t-mq($from: $f-breakpoint--sm) {
    min-width: 100px;
  }

  &[disabled] {
    cursor: default;
    color: $w-dd-grid__cell--disabled-color;
  }

  &:before {
    content: '';
    pointer-events: none;
    display: block;
    width: $w-dd-grid__cell--width-size--hover;
    height: $w-dd-grid__cell--height-size--hover;
    position: absolute;
    left: -1 * ($w-dd-grid__cell--border-size);
    top: -1 * ($w-dd-grid__cell--border-size);
  }

  &:after {
    content: '';
    pointer-events: none;
    display: block;
    width: $w-dd-grid__cell--width-size--hover;
    height: $w-dd-grid__cell--height-size--hover;
    position: absolute;
    left: -1 * ($w-dd-grid__cell--border-size);
    top: -1 * ($w-dd-grid__cell--border-size);
    right: -1 * ($w-dd-grid__cell--border-size);
    bottom: -1 * ($w-dd-grid__cell--border-size);
  }

  &.is-hovered {
    background-color: $w-dd-grid__cell--bg-color--hover;
    border-color: $w-dd-grid__cell--border-color--hover;
    color: $w-dd-grid__cell--color--hover;

    &:before {
      border-left: $w-dd-grid__cell--border-size $w-dd-grid__cell--border-color--hover $w-dd-grid__cell--border-style;
      border-top: $w-dd-grid__cell--border-size $w-dd-grid__cell--border-color--hover $w-dd-grid__cell--border-style;
    }
  }

  &.on-hovered-column {
    border-right-color: $w-dd-grid__cell--border-color--hover;

    &:before {
      border-left: $w-dd-grid__cell--border-size $w-dd-grid__cell--border-color--hover $w-dd-grid__cell--border-style;
      border-right: $w-dd-grid__cell--border-size  $w-dd-grid__cell--border-color--hover $w-dd-grid__cell--border-style;
    }
  }

  &.on-hovered-row {
    &:before {
      border-top: $w-dd-grid__cell--border-size $w-dd-grid__cell--border-color--hover $w-dd-grid__cell--border-style;
      border-bottom: $w-dd-grid__cell--border-size $w-dd-grid__cell--border-color--hover $w-dd-grid__cell--border-style;
    }
  }

  &.is-selected{
    background-color: $w-dd-grid__cell--bg-color--selected;
    border-color: $w-dd-grid__cell--border-color--selected;
    color: $w-dd-grid__cell--color--selected;

    &:before {
      border-left: $w-dd-grid__cell--border-size $w-dd-grid__cell--border-color--selected $w-dd-grid__cell--border-style;
      border-top: $w-dd-grid__cell--border-size $w-dd-grid__cell--border-color--selected $w-dd-grid__cell--border-style;
    }

    &.on-hovered-row{
      &:before {
        border-top: $w-dd-grid__cell--border-size $w-dd-grid__cell--border-color--hover $w-dd-grid__cell--border-style;
        border-bottom: $w-dd-grid__cell--border-size $w-dd-grid__cell--border-color--hover $w-dd-grid__cell--border-style;
      }
    }

    .w-dd-grid__onrequest{
      color: $w-dd-grid__cell--color--selected;
    }
  }

  &.w-dd-grid__cell-threshold-standard{
    &:not([class*="is-"]){
      color: $w-dd-grid__cell-threshold-standard--color;
    }
  }

  &.w-dd-grid__cell--header{
    &.on-hovered-column {
      background-color: $w-dd-grid__cell--bg-color--hover;
      border-top-color:  $w-dd-grid__cell--border-color--hover;
      color: $w-dd-grid__cell--color--hover;
      .w-dd-grid__weekday, .w-dd-grid__date {
        color: $w-dd-grid__cell--color--hover;
      }
    }

    &.on-selected-column{
      background-color: $w-dd-grid__cell--bg-color-header--selected;
      color: $w-dd-grid__cell--color-header--selected;
      .w-dd-grid__weekday, .w-dd-grid__date {
        color: $w-dd-grid__cell--color-header--selected;
      }
    }
  }
}

button.w-dd-grid__cell{
  &:not([disabled]){
    &:active{
      color: $w-dd-grid__cell--color--active;
      background-color: $w-dd-grid__cell--bg-color--active;
    }
  }
}

.w-dd-grid__navigation-add-next-month-column {
  button{
    box-shadow: -6px 0 4px 0px rgba(0,0,0,0.2);
    min-width: 40px;
    height: 100%;
    border-radius: 0px;
    z-index: 1; //in order to put button above the cells, otherwise shadows keeps behind the previous column
    &:hover {
      background-color: $w-dd-grid__cell--bg-color--hover;
      border-color: $w-dd-grid__cell--border-color--hover;
      color: $w-dd-grid__cell--color--hover;
    }
    .c-btn__icon::before {
      left: 0;
    }
    &:disabled {
      display: none;
    }

    &.c-btn--loading{
      .c-btn__icon {
        display: none;
      }

      .c-loader {
        //c-btn__icon has padding-left:calc(2.5em - 4px),
        // both need to match so we can swap from one icon (chrevron)
        // to another (loader) and they render exactly at same position
        padding-left: calc(2em - 4px);
        &::before {
          left: 0;
        }
      }
    }
  }
}

.w-dd-grid__column:first-child {
  .w-dd-grid__cell{
    border-left: 1px solid $w-dd-grid__cell--border-color;
  }
}

.w-dd-grid__cell--header {
  border-top-width: $w-dd-grid__cell--border-size;
  background-color: $w-dd-grid__cell--header--bg-color;
  font-weight: 700;
}

.w-dd-grid__left-area .w-dd-grid__cell {
  border-left-width: $w-dd-grid__cell--border-size;
  align-items: inherit;
  text-align: right;
  background-color: $w-dd-grid__cell--header--bg-color;
  font-weight: 700;
  box-shadow: 6px 0 4px 0px rgba(0,0,0,0.2);
  border-right-width: 0;

  &.on-hovered-row {
    background-color: $w-dd-grid__cell--bg-color--hover;
    color: $w-dd-grid__cell--color--hover;
    border-left-color: $w-dd-grid__cell--border-color--hover;
  }

  &.on-selected-row {
    background-color: $w-dd-grid__cell--bg-color-header--selected;
    color: $w-dd-grid__cell--color-header--selected;
  }

  &:first-child{
    border-top-left-radius: $w-dd-grid__cell--border-radius;
  }
  &:last-child{
    border-bottom-left-radius: $w-dd-grid__cell--border-radius;
  }
}

.w-dd-grid__left-area .w-dd-grid__cell.w-dd-grid__cell--header {
  background-color: $w-dd-grid__cell--header-corner--bg-color;
  color: $w-dd-grid__cell--discrete--color;
  font-weight: $w-dd-grid__cell--header-corner--font-weight;
  font-size: $w-dd-grid__cell--header-corner--font-size;
}

.w-dd-grid__left-area .w-dd-grid__cell, .w-dd-grid__right-area .w-dd-grid__cell--header .w-dd-grid__date{
  color: $w-dd-grid__cell--header--color;
}

.w-dd-grid__weekday {
  color: $w-dd-grid__cell--discrete--color;
  font-size: $w-dd-grid__cell--discrete--font-size;
  font-weight: normal;
  margin-bottom: $w-dd-grid__cell--discrete--margin-bottom;
}

.w-dd-grid__header-wrapper {
  margin-bottom: $w-dd-grid__header--margin-bottom;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.w-dd-grid__navigation-wrapper{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include t-mq($until: $w-dd-grid--bp) {
    display: none;
  }
}

.w-dd-grid__navigation-title{
  font-size: $w-dd-grid__navigation--font-size;
  color: $w-dd-grid__navigation-title--color;
  text-transform: uppercase;
  text-align: center;
}

.w-dd-grid__navigation {
  color: $w-dd-grid__navigation--color;
  transition: background-color $w-dd-grid__navigation--duration;
  width: $w-dd-grid__navigation--size;
  height: $w-dd-grid__navigation--size;
  border: $w-dd-grid__navigation--border-width $w-dd-grid__navigation--border-style $w-dd-grid__navigation--border-color;
  border-radius: $w-dd-grid__navigation--border-radius;
  overflow: hidden;

  &[disabled] {
    pointer-events: none;
  }

  &:hover {
    @include t-mq($from: $w-dd-grid--bp) {
      background-color: $w-dd-grid__navigation--bg-color--hover;
    }
  }

  .c-btn__icon {
    margin-top: $w-dd-grid__navigation--vertical-adjustment;
    height: $w-dd-grid__navigation--size;
    padding-left: $w-dd-grid__navigation--size;

    &::before {
      font-size: $w-dd-grid__navigation--font-size;
    }
  }
}

.w-dd-grid__navigation--left {
  margin-right: $w-dd-grid__navigation-button--sp-inset-h;

  .c-btn__icon {
    margin-left: $w-dd-grid__navigation--horizontal-adjustment--left;

    &::before {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }
  }
}

.w-dd-grid__navigation--right {
  margin-left: $w-dd-grid__navigation-button--sp-inset-h;

  .c-btn__icon {
    &::before {
      left: auto;
      right: 50%;
      transform: translateX(50%);
    }
  }
}

.w-dd-grid__right-area{
  .w-dd-grid__column:last-child{
    .w-dd-grid__cell{
      &:first-child{
        border-top-right-radius: $w-dd-grid__cell--border-radius;
      }
      &:last-child{
        border-bottom-right-radius: $w-dd-grid__cell--border-radius;
      }
    }
  }
}

.w-dd-grid__onrequest {
  text-transform: $w-dd-grid__onrequest--text-transform;
  color: $w-dd-grid__cell--onrequest-color;
  font-size: $w-dd-grid__cell--onrequest-font-size;
  font-weight: 400;
  white-space: nowrap;
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  text-align: center;
}

.w-dd-grid__legend {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.w-dd-grid__discount_bullet {
  width: $w-dd-grid__discount-bullet-size;
  min-width: $w-dd-grid__discount-bullet-size;
  height: $w-dd-grid__discount-bullet-size;
  border-radius: 50%;
}

.w-dd-grid__legend_item_bullet {
  margin-left: $f-space--small;
  margin-right: $f-space--small;
}

.w-dd-grid__legend_item_description {
  font-size: $f-font-size--xs;
  margin-right: $f-space--medium;
  font-weight: 900;
  text-transform: $w-dd-grid__legend_item_description--text-transform;
  line-height: 1;
}

.w-dd-grid__discount_bullet_placement {
  position: absolute;
  bottom: 30px;
  right: 5px;
}

.w-dd-grid__footer-wrapper {
  @extend %c-price-table__footer-wrapper;
}

.w-dd-grid__footer-caption  {
  @extend %c-price-table__footer-caption;
}

.w-dd-grid__footer-caption-title {
  @extend %c-price-table__footer-caption-title;
}

.w-dd-grid__footer-caption-description {
  @extend %c-price-table__footer-caption-description;
}

.w-dd-grid__footer-extra-info {
  @extend %c-price-table__footer-extra-info;

  // HACK: Duplicate the styles as the extend does not work and it is unknown why.
  color: $w-dd-grid__footer-extra-info--color;
  font-style: $w-dd-grid__footer-extra-info--font-style;
  font-weight: $w-dd-grid__footer-extra-info--font-weight;
  margin-top: $w-dd-grid__footer-extra-info--margin-top;
}
