

/* -------------------------------------------------------------------------
 * SHADOWS
 *
 * Foundation shadows
 *
 */

// Utilities output
// --------------------------------------------------

@if ($u-shadows--enabled) {
  @each $_shadow-name, $_shadow-properties in $u-shadows__map {
    .u-shadow--#{$_shadow-name} {
      @if(map_get($_shadow-properties, "type") == "box") {
        box-shadow: map_get($_shadow-properties, "shadow") rgba(map_get($_shadow-properties, "color"), map_get($_shadow-properties, "opacity")) !important;
      }
      @else if(map_get($_shadow-properties, "type") == "text") {
        text-shadow: map_get($_shadow-properties, "shadow") rgba(map_get($_shadow-properties, "color"), map_get($_shadow-properties, "opacity")) !important;
      }
    }
  }
}
