/* -------------------------------------------------------------------------
 * SKI PRICES VARS
 *
 */

$w-ski-prices--bp:                                                        "sml" !default;
$w-ski-prices--bp-big:                                                    "md" !default;

$w-ski-prices__item-table--bg-color:                                      $f-color__neutral-grey--ultra-light !default;
$w-ski-prices__item-table--sp-inset:                                      $f-space--small !default;
$w-ski-prices__item-table--width:                                         50% !default;

$w-ski-prices__item-row--even--bg-color:                                  $e-document__body--bgcolor !default;

$w-ski-prices__item-cell--sp-inset:                                       $f-space--tiny !default;
$w-ski-prices__item-cell--sp-inset-h:                                     $f-space--small !default;
$w-ski-prices__item-cell--sp-inset-h--from-bp:                            $f-space--medium !default;
$w-ski-prices__item-cell--sp-inset-down--from-bp:                         $f-space--small !default;
