/* -------------------------------------------------------------------------
 * CARD VARS
 */

$c-card__body--bg-color:                                $f-color__neutral-white !default;
$c-card__body--color:                                   $f-color__neutral-grey !default;     

$c-card__title-link--font-weight:                       400 !default;
$c-card__title--top-title--color:                       $f-color__brand-primary--dark !default;
$c-card__title--top-title--bg-color:                    $f-color__brand-secondary--light !default;
$c-card__title--top-title--bg-color--with-image:        $f-color__brand-secondary--light !default;

$c-card__subtitle--color:                               $f-color__neutral-grey !default;       

$c-card__footer--bg-color:                              $f-color__neutral-white !default;

$c-card--shadow:                                        $f-shadow--discrete !default;
