
/* -------------------------------------------------------------------------
 * CLEARFIX
 *
 * Mixin to drop micro clearfix into a selector. Further reading:
 * http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
 *
 */


@mixin t-clearfix() {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin t-clearfix--unset() {
  &::after {
    content: initial;
    display: initial;
    clear: initial;
  }
}

@mixin t-clearfix-important() {
  &::after {
    content: "" !important;
    display: block !important;
    clear: both !important;
  }
}
