/* -------------------------------------------------------------------------
 * BUTTON
 *
 * button as a material not as a tag
 *
 */


// Material selector output
// --------------------------------------------------

@if $m-button--enabled {

  // Reset default button styles
  .m-button--clean {
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    user-select: none;
    cursor: pointer;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    &:focus {
      outline: 0;
    }
  }
}
