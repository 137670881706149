.fr-destination-exceptional-info__table {

  .c-table__item {
    @include sp-inset-squish($fr-destination-exceptional-info__table-item--sp-inset);
  }

  .c-table__item.fr-destination-exceptional-info__table-row-header {
    
    background-color: $fr-destination-exceptional-info__table-row-header--bg-color;
    text-transform: uppercase;

    .fr-destination-exceptional-info__table-cell {
      color: $fr-destination-exceptional-info__table-row-header--color;
      font-weight: $fr-destination-exceptional-info__table-row-header--font-weight;
      font-size: $fr-destination-exceptional-info__table-row-header--font-size;

      @include t-mq($until: $fr-destination-exceptional-info__bp) {
        &:last-child {
          display: none;
        } 
      }
    }
  }
}

.fr-destination-exceptional-info__table-row {
  display: flex;
  @include t-mq($until: $fr-destination-exceptional-info__bp) {
    flex-wrap: wrap;
  }
}

.fr-destination-exceptional-info__table-cell {
  flex: 1 1 0px;
  &:not(:last-child) {
    padding-right: $fr-destination-exceptional-info__table-cell--padding-right;

    @include t-mq($until: $fr-destination-exceptional-info__bp) {
      padding-right: 0;
    }
  }
  @include t-mq($until: $fr-destination-exceptional-info__bp) {
    flex-wrap: wrap;

    &:first-child {
      padding-right: $fr-destination-exceptional-info__table-cell--padding-right;
    }

    &:last-child {
      flex-basis: 100%;
      margin-top: $fr-destination-exceptional-info__table-last-cell--margin-top;

      &:before {
        content: map-get($m-icon__glyphs, "information-circle");
        font-family: "m-icons";
        margin-right: $fr-destination-exceptional-info__table-last-cell-before--margin-right;
        font-size: $fr-destination-exceptional-info__table-last-cell-before--font-size;
        color: $fr-destination-exceptional-info__table-last-cell-before--color;
      }
    }

    &:last-child:empty {
      display: none;
    }
  }
}

.fr-destination-exceptional-info__table-cell:first-child {
  font-weight: $fr-destination-exceptional-info__table-first-cell--font-weight;
}

.fr-destination-exceptional-info__table-cell:not(:first-child) {
  color: $fr-destination-exceptional-info__table-cell--color;
}

.fr-destination-exceptional-info__table-cell:last-child {
  font-size: $fr-destination-exceptional-info__table-last-cell--font-size;
  
}