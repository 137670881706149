.fr-destination-page__discover {
  [class*=c-collection-row--expanded]>*, [class*=c-collection-row--tiled]>* {
    @include t-mq($from: $fr-destination-page__discover-other--breakpoint-large) {
      width: calc(#{$fr-destination-page__discover-other--column-width-large} - #{$fr-destination-page__discover-other--tile-offset});
    }
    @include t-mq($from: $fr-destination-page__discover-other--breakpoint-larger) {
      width: calc(#{$fr-destination-page__discover-other--column-width-larger} - #{$fr-destination-page__discover-other--tile-offset});
    }
  }

  @supports (display: grid) {
    [class*=c-collection-row--expanded], [class*=c-collection-row--tiled] {
      grid-template-columns: repeat(auto-fill, minmax($fr-destination-page__discover-other--min-width, 1fr));

      >* {
        @include t-mq($from: $fr-destination-page__discover-other--breakpoint-large) {
          width: 100%;
        }
        @include t-mq($from: $fr-destination-page__discover-other--breakpoint-larger) {
          width: 100%;
        }
      }
    }
  }
}
