/* -------------------------------------------------------------------------
 * SIDE DRAWER
 *
 * --------------------------------------------------
 * IMPORTANT: this component is using inverted mediaQueries to avoid overEngineering on restore appearance
 * --------------------------------------------------
 */

// Component selector output
// --------------------------------------------------

.c-side-drawer {
  &:not([class*="c-side-drawer--until"]) {
    background-color: $c-side-drawer--bg-color;
    @include c-side-drawer();
    overflow: visible;
  }

  &:after {
    content: none;
    background: $c-side-drawer--bg-color;
    bottom: -$c-side-drawer--pseudo-element--positioning;
    height: $c-side-drawer--pseudo-element--positioning;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}

.c-side-drawer__header,
.c-side-drawer__footer {
  display: none;
  @include sp-inset($c-side-drawer--sp-inset);
}

.c-side-drawer__header {
  position: relative;
  min-height: $c-side-drawer__close--font-size;
  @include sp-inset($c-side-drawer--sp-inset);

  .c-btn__icon {
    &::#{$m-icons__pseudo-element} {
      font-size: $c-side-drawer__close--font-size;
      right: 0;
      left: 0;
    }
  }
}

.c-side-drawer__close {
  display: block;
  position: absolute;
  top: 50%;
  right: $c-side-drawer--sp-inset;
  transform: translateY(-50%);
  font-size: $f-font-size--xxs;
  @include sp-inset($f-space--none);
}


// Enable side drawer until certain breakpoints
// --------------------------------------------------

@each $_breakpoint in $c-side-drawer--breakpoints {
  @include t-mq($until: $_breakpoint) {
    .c-side-drawer--until#{s-core-string-breakpoint($_breakpoint)} {
      background-color: $c-side-drawer--bg-color;
      @include c-side-drawer();
    }
  }
}

// Color schemes overrides
// -----------------------------
@import "color-schemes";

