.fr-acco-description__title-container {
  @include sp-stack($t-accommodation__title--sp-stack);

  @include t-mq($from: $fr-acco-description--bp) {
    display: flex;
    align-items: baseline;
  }

  .c-number-score {
    @include t-mq($until: $fr-acco-description--bp) {
      @include sp-stack($fr-acco-description__c-number-score--sp-stack--until-bp);
    }

    @include t-mq($from: $fr-acco-description--bp) {
      @include sp-inline($fr-acco-description__c-number-score--sp-inline--from-bp);
    }
  }

  .c-title {
    @include t-accommodation-title;
    @include sp-stack($f-space--none);
  }
}

.fr-acco-description__collapse {
  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 20px;
  }
}

.fr-acco-description__label {
  color: $fr-acco-description__label--color;
  @include sp-stack($fr-acco-description__label--sp-stack);
}

.fr-acco-description__collapse {
  margin-bottom: $fr-acco-description__collapse--margin-bottom;

  *:last-child {
    margin-bottom: 0;
  }

  &.c-collapse.after-fading:not(.in):not(.is-idle):after {
    background-image: linear-gradient(
      to bottom,
      $c-collapse--after-fading-transparent,
      $fr-acco-description__collapse--after-fading-color
    );
  }
}
