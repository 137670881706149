/* -------------------------------------------------------------------------
 * IMG
 */

$c-img__placeholder--blur-radius:     8px !default;

$c-img--reveal-duration:              $f-duration !default;
$c-img--reveal-easing:                $f-easing--base !default;

$c-img--unresolved--width:            6em !default;
$c-img--unresolved--left:             50% !default;
$c-img--unresolved--top:              50% !default;