.fr-reviews-overview-main{
    .c-box__content{
        @include t-mq($from: $fr-reviews-overview--bq) {
            display: flex;
        }
    }
}

.fr-reviews-overview-overview__number-score{
    display: flex;

    .c-number-score__body{
        display: table;
        @include t-mq($from: $fr-reviews-overview--bq){
            @include sp-inset-h($fr-reviews-overview__main-score-bubble--gutter);
            @include sp-inset-v($fr-reviews-overview__main-score-bubble--high);
            font-size: $fr-reviews-overview__main-score-bubble--font-size;
            border-radius: $fr-reviews-overview__main-score-bubble--radius;
        }
        @include sp-inset-h($fr-reviews-overview__main-score-bubble--gutter-mobile);
        @include sp-inset-v($fr-reviews-overview__main-score-bubble--high-mobile);
        font-size: $fr-reviews-overview__main-score-bubble--font-size-mobile;
        border-radius: $fr-reviews-overview__main-score-bubble--radius-mobile;
        margin-right: $fr-reviews-overview__main-score-bubble--margin-right;
    }
    .c-number-score__title-label{
        white-space: nowrap;
    }

}

.fr-reviews-overview__main-category{
    background: $fr-reviews-overview__main-category-label-bg;
    color: $fr-reviews-overview__main-category-label-color;
    margin-top: $fr-reviews-overview__main-category-label-margin-top;
    font-size: $fr-reviews-overview__main-category-label-font-size;
    display: inline-flex;

    .c-label__icon{
        display: flex;
        font-size: $fr-reviews-overview__main-category-label-label-size;
    }
}

.fr-reviews-overview__score-bars{
    @include t-mq($from: $fr-reviews-overview--bq) {
        @include sp-inset-h($fr-reviews-overview__main-item--gutter);
        min-width: 0;
        margin-top: 0;
        margin-left: $fr-reviews-overview__main-item--gutter;
        border-left: $fr-reviews-overview__main-item--first-child--border-width $fr-reviews-overview__main-item--first-child--border-style $fr-reviews-overview__main-item--first-child--border-color;
        margin-bottom: 0;
     }
}

.fr-reviews-overview__score-bars__progress-bar{
    display: table-row;
    .c-progress-bar__title{
        display: table-cell;
        padding-right: $fr-reviews-overview__c-progress-bar-title--padding-right;
        padding-bottom: $fr-reviews-overview__c-progress-bar-title--padding-bottom;
    }
    progress{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;

        background-color: $fr-reviews-overview__c-progress-bar-color-bg;
    }

    //color bars
    &:nth-child(1) .c-progress-bar__body .c-progress-bar__graphic progress{
        &::-webkit-progress-bar {
            background-color: $fr-reviews-overview__c-progress-bar-color-bg;
        }
        &::-webkit-progress-value {
            background-color: $fr-reviews-overview__c-progress-bar-color-fantastic;
        }
        /* MOZILLA FIREFOX */
        &::-moz-progress-bar {
            background-color: $fr-reviews-overview__c-progress-bar-color-fantastic;
        }
    }
    &:nth-child(2) .c-progress-bar__body .c-progress-bar__graphic progress{
        &::-webkit-progress-bar {
            background-color: $fr-reviews-overview__c-progress-bar-color-bg;
        }
        &::-webkit-progress-value {
            background-color: $fr-reviews-overview__c-progress-bar-color-good;
        }
        /* MOZILLA FIREFOX */
        &::-moz-progress-bar {
            background-color: $fr-reviews-overview__c-progress-bar-color-good;
        }
    }
    &:nth-child(3) .c-progress-bar__body .c-progress-bar__graphic progress{
        &::-webkit-progress-bar {
            background-color: $fr-reviews-overview__c-progress-bar-color-bg;
        }
        &::-webkit-progress-value {
            background-color: $fr-reviews-overview__c-progress-bar-color-normal;
        }
        /* MOZILLA FIREFOX */
        &::-moz-progress-bar {
            background-color: $fr-reviews-overview__c-progress-bar-color-normal;
        }
    }
    &:nth-child(4) .c-progress-bar__body .c-progress-bar__graphic progress{
        &::-webkit-progress-bar {
            background-color: $fr-reviews-overview__c-progress-bar-color-bg;
        }
        &::-webkit-progress-value {
            background-color: $fr-reviews-overview__c-progress-bar-color-bad;
        }
        /* MOZILLA FIREFOX */
        &::-moz-progress-bar {
            background-color: $fr-reviews-overview__c-progress-bar-color-bad;
        }
    }
    &:nth-child(5) .c-progress-bar__body .c-progress-bar__graphic progress{
        &::-webkit-progress-bar {
            background-color: $fr-reviews-overview__c-progress-bar-color-bg;
        }
        &::-webkit-progress-value {
            background-color: $fr-reviews-overview__c-progress-bar-color-horror;
        }
        /* MOZILLA FIREFOX */
        &::-moz-progress-bar {
            background-color: $fr-reviews-overview__c-progress-bar-color-horror;
        }
    }
}

.fr-reviews-overview__score-points{
    display: grid;
    grid-template-columns: repeat(2, minmax($fr-reviews-overview__c-number-score--column-width, 1fr));
    row-gap: $fr-reviews-overview__c-number-score--row-gap;

    .c-number-score{
        display: flex;
        padding-right: $fr-reviews-overview__c-number-score--padding-right;
        align-self:baseline;

        .c-number-score__body{
            @include sp-inset-h($fr-reviews-overview__c-number-score--padding-sides);
            padding-top: $fr-reviews-overview__c-number-score--padding-top;
            padding-bottom: $fr-reviews-overview__c-number-score--padding-bottom;
            border-radius: $fr-reviews-overview__c-number-score--radius;
            display: table;

            .c-number-score__grade{
                font-size: $fr-reviews-overview__c-number-score--font-size;
                font-weight: $fr-reviews-overview__c-number-score--font-weight;
            }

            &::after{
                display: none;
            }
        }

        .c-number-score__label{
            @include t-mq($from: $fr-reviews-overview--bq) {
                white-space: nowrap;
            }
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}