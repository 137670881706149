progress[role] {
	display: inline-block;
	position: relative;
	width: $c-progress-bar__polyfill--width;
	height: $c-progress-bar__polyfill--height;
	vertical-align: -$c-progress-bar__polyfill--vertical-align;

	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

progress[role],
progress[aria-valuenow]:before {
	background-color: $c-progress-bar__value--bg-color;
}

progress[role],
progress[role]:after {
	background-repeat:repeat-x;
	background-position: 0 0;
	background-size: auto 100%;
}

/* Determinate only overrides */
progress[aria-valuenow] {
	background: $c-progress-bar__polyfill--bg-color-overwrite;
}

progress[aria-valuenow]:before {
	content: "";
	display: block;
	height: 100%;
}

/* Overlay */
progress[role]:after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
