/* -------------------------------------------------------------------------
 * LABEL
 *
 */

.c-label {
  text-align: center;
  display: inline-block;
  border-radius: $c-label--border-radius;
  @include m-heading--complete($c-label--material);
  @include t-text-crop-disable();

  &:first-letter {
    text-transform: uppercase;
  }
}

// Modifiers
// ------------------------------

.c-label--with-icon {
  text-align: left;
  display: flex;
  align-items: center;
}

.c-label__icon {
  margin-right: $c-label--margin-right--icon;
}

// Variants
// ------------------------------

//Badge

.c-label.c-label--badge {
  @include sp-inset-squish($c-label--space-inset);
  font-weight: $c-label--badge--font-weight;
}

// Sale

.c-label.c-label--sale {
  background-color: $c-label--bg-color--sale;
  color: $c-label--text-color--sale;
  font-size: $c-label--font-size--sale;
  font-family: $c-label--font-family--sale;
  @include sp-inset-squish($c-label--sp-inset--sale);
}

// Variants
// ------------------------------
@each $_size__name, $_size__value in $c-label-sizes__map {
  @if ($_size__value) {
    .c-label.c-label--#{$_size__name} {
      font-size: map_get($_size__value, 'font-size');
    }
  }
}

// State
// ------------------------------

.cs-state-success--white.c-label--success {
  color: map_get($cs-state-success, 'background');
}
.cs-state-warning--white.c-label--warning {
  color: map_get($cs-state-warning, 'background');
}
.cs-state-error--white.c-label--error {
  color: map_get($cs-state-error, 'background');
}
.cs-state-info--white.c-label--info {
  color: map_get($cs-state-info, 'background');
}

// Collections
// ------------------------------

.c-label--collection {
  @include sp-inset-squish($c-label--space-inset);
}
