/* -------------------------------------------------------------------------
* Calendar Variables
*
*/

$c-calendar--height:                                    36px !default;

$c-calendar__weekdays--color:                           $f-color__brand-primary--dark !default;

$c-calendar__month--color:                              $f-color__brand-primary--dark !default;
$c-calendar__month--font-size:                          $f-font-size--m !default;
$c-calendar__month--font-weight:                        600 !default;
$c-calendar__month--color--hover:                       $f-color__neutral-grey !default;
$c-calendar__month--font-family:                        $f-font-family__secondary !default;
$c-calendar__month--text-transform:                     lowercase !default;
$c-calendar__month--margin:                             0 1.5em 0 1.5em  !default;

$c-calendar__day--color:                                $f-color__brand-primary--dark !default;
$c-calendar__day--font-weight:                          400 !default;
$c-calendar__day--hover--bg-color:                      $f-color__state-info--ultra-light !default;
$c-calendar__day--hover--font-weight:                   400 !default;
$c-calendar__day--disabled--color:                      $f-color__neutral-grey--semi-light !default;
$c-calendar__day--selected--bg-color:                   $f-color__state-info !default;
$c-calendar__day--selected--font-weight:                400 !default;
$c-calendar__day--selected--hover--color:               $f-color__neutral-white !default;
$c-calendar__day--selected--pressed--bg-color:          $f-color__state-info--semi-dark !default;
$c-calendar__day--disabled--selected--color:            $f-color__neutral-grey !default;
$c-calendar__day--disabled--selected--bg-color:         $f-color__neutral-grey--light !default;

$c-calendar__next-prev--color:                          $f-color__neutral-grey--light !default;

$c-calendar__week-number--color:                        $f-color__state-info !default;
