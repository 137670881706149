/* -------------------------------------------------------------------------
 * PARTICIPANTS SELECTOR VARS
 *
 */

 $c-participants-selector__floating-box--min-width:                             360px !default;
 $c-participants-selector__floating-box--max-width:                             480px !default;

$c-participants-selector--main-spacing:                                         $f-space--medium !default;

$c-participants-selector__title--color:                                         $f-color__neutral-grey--dark !default;

$c-participants-selector__wrapper--bg-color:                                    $f-color__neutral-grey--ultra-light !default;
$c-participants-selector__children-dates-required--bg-color:                    $f-color__neutral-grey--ultra-light !default;

$c-participants-selector__remove-room-btn--size:                                $f-font-size--s !default;
$c-participants-selector__remove-room-btn--color:                               $f-color__neutral-grey--dark !default;

$c-participants-selector__label-description--color:                             $f-color__neutral-grey--semi-dark !default;
$c-participants-selector__label-description--font-size:                         $f-font-size--s !default;
$c-participants-selector__label-description--top-separation:                    $f-space--tiny !default;

$c-participants-selector__children-dates-pointer--alignment:                    54px !default;

$c-participants-selector__add-room-btn--border-width:                           1px !default;
$c-participants-selector__add-room-btn--border-type:                            solid !default;
$c-participants-selector__add-room-btn--border-color:                           $f-color__neutral-grey--semi-light !default;
$c-participants-selector__add-room-btn--color:                                  $f-color__neutral-grey--dark !default;
$c-participants-selector__add-room-btn--bg-color:                               $f-color__neutral-white !default;
$c-participants-selector__add-room-btn--bg-color--hover:                        $f-color__state-info--ultra-light !default;
$c-participants-selector__add-room-btn--hover-color:                            $f-color__brand-primary !default;
$c-participants-selector__add-room-btn-icon--color:                             $f-color__neutral-white !default;
$c-participants-selector__add-room-btn-icon--radius:                            $f-radius--rounded !default;
$c-participants-selector__add-room-btn-icon--size:                              $f-space--tiny + $f-space--medium !default;
$c-participants-selector__add-room-btn-icon--font-size:                         $f-font-size--m !default;
$c-participants-selector__add-room-btn-icon--alignment:                         $f-space--tiny + 1 !default;
$c-participants-selector__add-room--padding:                                    18px $f-space 16px $f-space !default;
$c-participants-selector__add-room--margin-bottom:                              $f-space !default;
$c-participants-selector__group-link--margin-bottom:                            1rem !default;

$c-participants-selector__room-title--material:                                 map_get($m-heading__mod-sizes, "small") !default;

$c-participants-selector__floating-box-footer--margin-top:                      $f-space--small !default;
$c-participants-selector__floating-box-body-content--padding-top:               1px !default;

$c-participants-selector__add-room--bg-color:                                   $f-color__state-info--light !default;
$c-participants-selector__profile--focus--border:                               1px solid $f-color__neutral-grey--semi-dark !default;
$c-participants-selector__children-dates-required--dropdown-space:              8px;
