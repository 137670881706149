/* -------------------------------------------------------------------------
 * SKI PRICES
 *
 */

.w-ski-prices__item-table {
  display: table;
  background-color: $w-ski-prices__item-table--bg-color;
  width: $w-ski-prices__item-table--width;
  @include sp-inset-squish($w-ski-prices__item-table--sp-inset);

  @include t-mq($from: $w-ski-prices--bp) {
    background-color: transparent;
    @include sp-inset($f-space--none);
  }
}

.w-ski-prices__item-row {
  display: table-row;
  @include sp-inset($f-space--none);

  &.w-ski-prices__item-row:nth-child(odd) {
    @include t-mq($from: $w-ski-prices--bp) {
      background-color: $w-ski-prices__item-row--even--bg-color;
    }
  }

  &:nth-child(even) {
    background-color: $w-ski-prices__item-row--even--bg-color;

    @include t-mq($from: $w-ski-prices--bp) {
      background-color: $w-ski-prices__item-table--bg-color;
    }
  }

  &:first-child {
    .w-ski-prices__item-cell {
      @include t-mq($from: $w-ski-prices--bp) {
        padding-bottom: $w-ski-prices__item-cell--sp-inset-down--from-bp;
      }
    }
  }
}

.w-ski-prices__item-cell {
  font-weight: 700;
  display: table-cell;
  text-transform: capitalize;
  @include sp-inset($w-ski-prices__item-cell--sp-inset);

  &:first-child {
    padding-left: $w-ski-prices__item-cell--sp-inset-h;

    @include t-mq($from: $w-ski-prices--bp) {
      @include sp-inset-h($w-ski-prices__item-cell--sp-inset-h--from-bp);
    }
  }

  &:last-child {
    padding-right: $w-ski-prices__item-cell--sp-inset-h;

    @include t-mq($from: $w-ski-prices--bp) {
      @include sp-inset-h($w-ski-prices__item-cell--sp-inset-h--from-bp);
    }
  }

  &.w-ski-prices__item-cell--body:first-child {
    font-weight: 400;
  }

  &.w-ski-prices__item-cell--body:not(:first-child) {
    white-space: nowrap;
  }
}
