.fr-account-booking-message {
  text-align: center;
  background-color: $f-color__neutral-white;
  border-radius: $f-radius--large;
}

.fr-account-booking-message__content {
  margin-left: auto;
  margin-right: auto;
  max-width: $fr-account-booking-message__content--max-width;
}

.fr-account-booking-message--rebooked {
  .fr-account-booking-message__icon:before {
    font-size: $fr-account-booking-message__icon--font-size;
  }
}

.fr-account-booking-message--error {
  .fr-account-booking-message__icon:before {
    font-size: $fr-account-booking-message--error__icon--font-size;
  }
}

