/* -------------------------------------------------------------------------
 * LINKS
 *
 * Links as a material
 *
 */


// Material selector output
// --------------------------------------------------

@if $m-link--enabled {
  .m-link {}
}

// Material discrete modifier output
// --------------------------------------------------

@if $m-link--enabled and $m-link__mod-discrete--enabled {
  .m-link--discrete {
    color: $m-link__mod-discrete--color;
    font-weight: 400;
    text-decoration: none;
    &:hover {
      color: $m-link__mod-discrete--color;
      text-decoration: none;
    }
  }
}
