.fr-account-booking-participants {
  .fr-account-booking-term {
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    margin-top: $fr-account-booking-participants__term--margin;

    @include t-mq($from: "sm") {
      width: calc(100%/2);
      padding: 0 $fr-account-booking-participants__term--padding;
    }

    @include t-mq($from: "sml") {
      width: calc(100%/3);
    }
  }
}

.fr-account-booking-participants-container {
  display: flex;
  gap: $f-space--tiny;
  flex-wrap: wrap;
  align-items: center;
}

.fr-account-booking-participants__label.c-label {
  font-size: $f-font-size--xxs;
  flex-shrink: 0;
}

.fr-account-booking-participants__content {
  @include t-mq($until: "sm") {
    @include sp-inset-h($fr-account-booking-participants__content--inset-h);
  }
}

.fr-account-booking-participants__section {
  @include t-mq($from: "sm") {
    margin-top: $fr-account-booking-participants__section--margin-top;
    &:first-child {
      margin-top: 0;
    }
  }
}

.fr-account-booking-participants__section-title {
  padding-bottom: $fr-account-booking-participants__section-title--padding;
  border-bottom: 1px solid $fr-account-booking-participants__section-title--border-color;

  .m-heading {
    display:  inline-block;
  }
  .m-link {
    float: right;
  }
}

.fr-account-booking-participants__section-content {
  display: flex;
  flex-wrap: wrap;
}

.fr-account-booking-participants .c-accordion > div:not(:last-child) .c-accordion__item {
  border-bottom: none;
}
