/* -------------------------------------------------------------------------
 * CAR ITEM VARS
 */

$c-car-item__name--font-weight:                                400 !default;
$c-car-item__name--font-size-desktop:                          $f-font-size--m !default;

$c-car-item__type--font-weight:                                600 !default;
$c-car-item__type--font-size-desktop:                          $f-font-size--l !default;

$c-car-item__type--font-family:                                $f-font-family__secondary !default;

$c-car-item__features--icon-color:                             $f-color__neutral-grey !default;

$c-car-item__type--sp-stack:                                   $f-space--small !default;
$c-car-item__features-item--margin-top:                        $f-space--small !default;
$c-car-item__features--sp-stack:                               $f-space--tiny !default;


