/* -------------------------------------------------------------------------
 * PRICE TABLE VARS
 */

$c-price-table__room-name--font-weight:                             700 !default;

$c-price-table__title-period--font-size:                            $f-font-size--m !default;

$c-price-table__cell--border-color--hover:                          $f-color__state-info !default;
$c-price-table__cell--border-offset:                                -1px !default;
$c-price-table__cell--bg-color--hover:                              $f-color__state-info--ultra-light !default;
$c-price-table__cell--bg-color--active:                             $f-color__brand-primary !default;
$c-price-table__cell-price-active--color:                           $f-color__neutral-white !default;
$c-price-table__cell-price--right:                                  0 !default;
$c-price-table__cell--right:                                        0 !default;
$c-price-table__cell--onrequest-color--active:                      $f-color__neutral-grey--light !default;

$c-price-table__navigation--color:                                  $f-color__state-info !default;

$c-price-table__day-month--color:                                   $f-color__brand-primary--dark !default;
$c-price-table__day-name--color:                                    $f-color__brand-primary--dark !default;

$c-price-table__room-title--sp-inline:                              $f-space--small !default;
$c-price-table__cell--title--color:                                 $f-color--brand-primary--dark !default;

$c-price-table__cell--price--font-size:                             16px !default;

$c-price-table__room-characteristic--color:                         $f-color__neutral-grey--semi-dark !default;

$c-price-table__room-trigger--font-size:                            $f-font-size--xs !default;
$c-price-table__room-trigger-icon--font-size:                       0.75em !default;
$c-price-table__room-trigger-icon--top:                             -3px !default;
