// --------------------------------------------------
// Color schemes overrides
// --------------------------------------------------

.cs-default--light {

  .c-icon-row__link {
    color: $c-icon-row__link--color--cs-default--light;

    &:hover {
      color: $c-icon-row__link--color-hover--cs-default--light;
    }
  }

}
