.fr-account-vouchers-overview {
  @include sp-stack-children($f-space--medium);
}

.fr-account-vouchers-overview__header {
  display: flex;
  align-items: center;
}

.fr-account-vouchers-overview__header-title {
  margin-left: $f-space--small;
  text-transform: $fr-account-vouchers-overview__header-title--text-transform;
}

.fr-account-vouchers-overview__vouchers {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1 * $fr-account-vouchers-overview__voucher--margin;
  margin-left: -1 * $fr-account-vouchers-overview__voucher--margin;

  .w-voucher {
    margin-top: $fr-account-vouchers-overview__voucher--margin;
    margin-left: $fr-account-vouchers-overview__voucher--margin;
    width: 100%;

    @include t-mq($from: "sm") {
      width: calc(50% - #{$fr-account-vouchers-overview__voucher--margin});
    }

    @include t-mq($from: "md") {
      width: calc(33.333333% - #{$fr-account-vouchers-overview__voucher--margin});
    }
  }
}
