.fr-account-booking-details__content {
  @include sp-inset-h($fr-account-booking-details__content--inset-h);
}

.fr-account-booking-details {
  @include o-layout__item();

  .fr-account-booking-section-header {
    @include t-mq($from: "md") {
      display: none;
    }
  }
}

.fr-account-booking-details__section {
  &:not(:first-child) {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: -$fr-account-booking-details__term--margin;
  }

  &:not(:last-child){
    .fr-account-booking-details__extras-add-wrapper{
      @include sp-stack($fr-account-booking-details__extras-add--margin)
    }
  }

  &:last-child .fr-account-booking-details__extras-title {
    padding-bottom: 0;
  }
}

.fr-account-booking-details__section-content, .fr-account-booking-details__extras-terms-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .fr-account-booking-term {
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    margin-top: $fr-account-booking-details__term--margin;

    @include t-mq($from: "sm") {
      width: calc(100%/2);
      padding: 0 $fr-account-booking-details__term--padding;
    }

    @include t-mq($from: "sml") {
      width: calc(100%/3);
    }

    @include t-mq($from: "md") {
      width: 100%;
      padding: 0;
    }
  }
}

.fr-account-booking-details__extras-terms-wrapper {
  flex-grow: 1;
  margin-bottom: $fr-account-booking-details__term--margin/2;

  .fr-account-booking-term {
    margin: $fr-account-booking-details__term--margin/2 0;
  }
}
.fr-account-booking-details__extras-add-wrapper{
  flex-grow: 1;
}
.fr-account-booking-details__extras-section {
  border-top: $fr-account-booking-details__extras-section--border;
}

.fr-account-booking-details__extras-title {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: $fr-account-booking-details__term--padding 0;

  & > .m-heading {
    flex-grow: 1;
  }

  &:hover {
    .m-heading, .m-icon {
      color: $fr-account-booking-details__extras-title--hover-color;
    }
  }
}

.fr-account-booking-details__extras-opener {
  margin-right: 0;

  .m-icon {
    font-size: $fr-account-booking-details__extras-opener--icon-size;
  }
}


.fr-account-booking-details__preferences {
  word-break: break-word;
  font-size: $fr-account-booking-term__value--font-size;
  margin-top: $fr-account-booking-details__preferences-list--stack-spacing;

  .fr-account-booking-details__preferences-list {
    @include sp-stack-children($fr-account-booking-details__preferences-list--spacing);
    margin-left: $fr-account-booking-details__preferences-list--margin-left;
    margin-bottom: $fr-account-booking-details__preferences-list--stack-spacing;
  }

  .fr-account-booking-details__preferences-remark {
    color: $fr-account-booking-details__preferences-remark--color;
    font-size: $fr-account-booking-details__preferences-remark--font-size;
  }
}

.fr-account-booking-details__contact-section-content {
  flex-grow: 1;

  .fr-account-booking-term:not(.fr-account-booking-term--small):not(:first-child) {
    margin-top: $fr-account-booking-details-contact__term--margin-top;
  }

  .fr-account-booking-term--small {
    margin-top: $fr-account-booking-details-contact__small-term--margin-top;
  }

  .fr-account-booking-details__contact-section-empty-message {
    font-size: $fr-account-booking-details-contact__empty-message--font-size;
    margin-top: $fr-account-booking-details-contact__empty-message--margin-top;
  }

  .fr-account-booking-term__content {
    flex: 1;
    }
}

