/* -------------------------------------------------------------------------
 * BLOCK MIXINS
 *
 */


// Object as a mixin
// --------------------------------------------------

@mixin o-block($_alignment-direction: $o-block__alignment) {
  display: block;
  text-align: $_alignment-direction;
}

@mixin o-block__header() {
  display: block;
}

@mixin o-block__body() {
  display: block;
}

@mixin o-block__footer() {
  display: block;
}

// Alignment modifiers
// --------------------------------------------------

@mixin o-block__mod-alignment($_alignment-direction: left) {
  .o-block--#{$_alignment-direction} {
    text-align: $_alignment-direction;
  }
}

// Stretch modifier output
// --------------------------------------------------

@mixin o-block--stretch() {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@mixin o-block__body--stretch() {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
}

@mixin o-block__mod-stretch() {
  .o-block--stretch {
    @include o-block--stretch();

    > .o-block__body {
      @include o-block__body--stretch();

      + .o-block__body {
        flex-grow: 0;
        padding-top: 0;
      }
    }
  }
}

// Unset as mixin
// --------------------------------------------------

@mixin o-block--unset() {
  display: inherit;
  text-align: inherit;
}

@mixin o-block__header--unset() {
  display: inherit;
}

@mixin o-block__body--unset() {
  display: inherit;
}

@mixin o-block__footer--unset() {
  display: inherit;
}
