/* -------------------------------------------------------------------------
 * PRICE TABLE
 */

.c-price-table__room-name,
.c-price-table__title-period {
  font-weight: $c-price-table__room-name--font-weight;
}

.c-price-table__title-period {
  font-size: $c-price-table__title-period--font-size;
}

.c-price-table__room-info {
  background-color: $c-price-table__room-info--bg-color;
}

.c-price-table__row,
.c-price-table__row.is-highlighted {
  .c-price-table__cell--price.is-active,
  .c-price-table__cell--price:active {
    right: $c-price-table__cell-price--right;
    &:before {
      border: none;
      left: 0;
      right: 0;
    }
    .c-price__value {
      color: $c-price-table__cell-price-active--color;
    }
  }
}

.c-price-table__navigation,
.c-price-table__navigation:hover {
  @include t-mq($from: $f-breakpoint--md) {
    border: none;
    color: $c-price-table__navigation--color;
    background-color: transparent;
  }
}

.c-price-table__navigation-wrapper::after {
  content: none;
}

.c-price-table__cell::before {
  @include t-mq($from: $c-price-table--bp) {
    top: 0;
    right: $c-price-table__cell--border-offset;
    bottom: 0;
    left: $c-price-table__cell--border-offset;
  }
}

.c-price-table__row {
  padding-left: 0px;
  padding-right: 0px;
}

.c-price-table__cell--participants-coarse-detail {
  font-size: $c-price-table__cell--price--font-size;
}

.c-price-table__room-characteristic {
  color: $c-price-table__room-characteristic--color;
}

.c-price-table__legend_item_description {
  text-transform: unset;
}

.c-price-table__room-trigger.c-collapse__trigger {
  font-size: $c-price-table__room-trigger--font-size;

  .c-collapse__trigger-icon {
    font-size: $c-price-table__room-trigger-icon--font-size;
    position: relative;
    top: $c-price-table__room-trigger-icon--top;
  }
}
