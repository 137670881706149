/* -------------------------------------------------------------------------
 * BACKDROP
 */


$c-backdrop--color:                                  $f-color__neutral-black !default;
$c-backdrop--opacity:                                .8 !default;
$c-backdrop--z-index:                                map_get($f-z-indexes--usage, "c-backdrop") !default;

$c-backdrop__in--easing:                             $f-easing--in !default;
$c-backdrop__in--duration:                           $f-duration !default;

$c-backdrop__out--easing:                            $f-easing--out !default;
$c-backdrop__out--duration:                          $f-duration !default;

$c-backdrop__loader--size:                           24px !default;
$c-backdrop__loader--border-width:                   .2em !default;
$c-backdrop__loader--border-type:                    solid !default;
$c-backdrop__loader--border-color:                   $f-color__neutral-white !default;
$c-backdrop__loader--border-opacity:                 .2 !default;
$c-backdrop__loader--animation-duration:             $f-duration--sluggish !default;
$c-backdrop__loader--animation-easing:               $f-easing--linear !default;
$c-backdrop__loader--animation-repeat:               infinite !default;
$c-backdrop__loader--space:                          2.25em !default;
