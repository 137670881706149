/* -------------------------------------------------------------------------
* EXCURSIONS VARS
*
*/

$w-excursions__container--first-bp:                             'sml' !default;
$w-excursions__container--second-bp:                            'lg' !default;

$w-excursions__container--gap:                                  $f-space--tiny !default;

$w-excursions__card--width-first-bp:                            calc((100% / 2) - (#{$w-excursions__container--gap} * 2)) !default;
$w-excursions__card--width-second-bp:                           calc((100% / 3) - (#{$w-excursions__container--gap} * 2)) !default;

$w-excursions__all-excursions-link--margin-top:                 16px !default;

$w-excursions__card-title--heading:                             map_get($m-heading__mod-sizes, "small") !default;
$w-excursions__card-title--line-height:                         $f-line-height--medium !default;
