/* -------------------------------------------------------------------------
 * CAR RENTAL VARS
 *
 */

$w-car-rental--bp:                                                        "sml" !default;
$w-car-rental--bp-big:                                                    "md" !default;

$w-car-rental__item--border-width:                                        1px !default;
$w-car-rental__item--border-style:                                        solid !default;
$w-car-rental__item--border-color:                                        $f-color__neutral-grey--light !default;
$w-car-rental__item--border-radius:                                       $f-radius--small !default;
$w-car-rental__item--shadow--focus:                                       $f-shadow--elegant rgba($f-shadow--color, $f-shadow__opacity--elegant) !default;
$w-car-rental__item--sp-inset--from-bp:                                   $f-space--medium !default;
$w-car-rental__item--col-width--from-bp:                                  100% !default;

$w-car-rental__item-content--sp-inset:                                    $f-space--small !default;
$w-car-rental__item-content--sp-stack--from-bp:                           $f-space--medium !default;
$w-car-rental__item-content--sp-inline--from-bp-big:                      $f-space--medium !default;
$w-car-rental__item-content--max-width--from-bp-big:                      55%;

$w-car-rental__item-header--sp-stack:                                     $f-space--small + $f-space--tiny !default;
$w-car-rental__item-header--width--from-bp:                               300px !default;
$w-car-rental__item-header--max-width--from-bp:                           $w-car-rental__item-header--width--from-bp !default;
$w-car-rental__item-header--width--from-bp-big:                           percentage(1/2) !default;
$w-car-rental__item-header--sp-inline--from-bp:                           $f-space--large !default;
$w-car-rental__item-header--sp-inline--from-bp-big:                       $f-space--medium !default;

$w-car-rental__item-image--max-width:                                     120px !default;
$w-car-rental__item-image--width--from-bp:                                175px !default;
$w-car-rental__item-image--width--from-bp-big:                            250px !default;
$w-car-rental__item-image--sp-inset:                                      $f-space--small !default;

$w-car-rental__item-title--sp-stack--from-bp:                             $f-space--small !default;

$w-car-rental__item-name--material:                                       map_get($m-heading__mod-sizes, "tiny") !default;
$w-car-rental__item-name--material--from-bp:                              map_get($m-heading__mod-sizes, "small") !default;
$w-car-rental__item-name--sp-stack:                                       $f-space--small !default;

$w-car-rental__item-category--color:                                      $f-color__brand-primary !default;

$w-car-rental__item-characteristics--sp-stack:                            $f-space--tiny !default;
$w-car-rental__item-characteristics--sp-inset-top:                        $f-space--medium !default;

$w-car-rental__item-characteristic--sp-inline:                            $f-space--small !default;
$w-car-rental__item-characteristic--sp-stack:                             $f-space--tiny !default;

$w-car-rental__item-icon--color:                                          $f-color--neutral_grey--semi-dark !default;
$w-car-rental__item-icon--font-size:                                      map_get($m-icons__sizes, "medium") !default;
$w-car-rental__item-icon--sp-inline--from-bp:                             $f-space--tiny !default;

$w-car-rental__item-trigger--color:                                       $f-color__state-info !default;

$w-car-rental__item-table--bg-color:                                      $f-color__neutral-grey--ultra-light !default;
$w-car-rental__item-table--sp-inset:                                      $f-space--small !default;

$w-car-rental__item-row--even--bg-color:                                  $f-color__neutral-white !default;

$w-car-rental__item-cell--sp-inset:                                       $f-space--tiny !default;
$w-car-rental__item-cell--sp-inset-h:                                     $f-space--small !default;
$w-car-rental__item-cell--sp-inset-h--from-bp:                            $f-space--medium !default;
$w-car-rental__item-cell--sp-inset-down--from-bp:                         $f-space--small !default;

$w-car-rental__notification--color:                                       $f-color__state-info !default;

$w-car-rental__notification-icon--sp-inline:                              $f-space--small !default;
