/* -------------------------------------------------------------------------
 * BOOKING BACK BUTTON VARS
 *
 */

.w-booking-back-button {
  @include t-mq($from: $w-booking-back-button--bp) {
    margin-top: $w-booking-back-button--margin-button;
  }

  .w-booking-back-button__btn:hover,
  a.w-booking-back-button__btn:hover  {
    color: inherit;
    text-decoration: none;
  }
}