
/* -------------------------------------------------------------------------
 * THEME SUSTAINABLE Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-sustainable--enabled {
  .cs-theme-sustainable {
    @include color-scheme($cs-theme-sustainable)
  }
}
