
/* -------------------------------------------------------------------------
 * TEXT-CROP
 *
 * Mixin to remove the space above and below a block of text.
 * Based on http://text-crop.eightshapes.com/
 *
 * Example
 *
 * .selector {
 *   @include t-text-crop-enable();
 *   @include t-text-crop($my_props);
 * }
 *
 */

// TEXT-CROP main mixin
// --------------------------------------------------

@function t-text-crop-values($crop-properties: (), $line-height: 1) {
  $crop-font-size: s-core-strip-units(map_get($crop-properties, "font-size"));
  $crop-line-height: map_get($crop-properties, "line-height");
  $top-crop: map_get($crop-properties, "top");
  $bottom-crop: map_get($crop-properties, "bottom");

  // Apply values to calculate em-based margins that work with any font size
  $dynamic-top-crop: max(($top-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;
  $dynamic-bottom-crop: max(($bottom-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;

  @return (
    'top': $dynamic-top-crop,
    'bottom': $dynamic-bottom-crop
  );
}

// To enable or disable the pseudo elements on text crop, use the t-text-crop-enable() or t-text-crop-disable() mixins

@mixin t-text-crop($crop-properties: (), $line-height: 1, $top-adjustment: 0px, $bottom-adjustment: 0px) {

  line-height: $line-height;
  $t-text-crop-values: t-text-crop-values($crop-properties, $line-height);
  &::before {
    margin-bottom: calc(-#{map_get($t-text-crop-values, 'top')}em + #{$top-adjustment});
  }
  &::after {
    margin-top: calc(-#{map_get($t-text-crop-values, 'bottom')}em + #{$bottom-adjustment});
  }
}


// Enable the pseudo elements necessary for text-crop
@mixin t-text-crop-enable() {
  &::before,
  &::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
  }
}

// Disable the pseudo elements necessary for text-crop
@mixin t-text-crop-disable() {
  &::before,
  &::after {
    display: none;
  }
}
