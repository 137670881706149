.fr-acco-destination-description {
  @include t-clearfix;
}
.fr-acco-destination-description__slider {
  width: 100%;
  @include sp-stack($fr-acco-destination-description__slider--sp-stack);
  @include o-media__img();
  @include t-mq($from: $fr-acco-destination-description__slider--bp) {
    width: $fr-acco-destination-description__slider--width--from-bp;
    @include sp-inline($fr-acco-destination-description__slider--sp-inline);
  }
  @include t-mq($from: $fr-acco-destination-description__slider--bp-big) {
    width: $fr-acco-destination-description__slider--width--from-bp-big;
  }
}