/* -------------------------------------------------------------------------
 * SPLASHPAGE
 *
 */

.t-splashpage {
  .l-site-main__content > [class^="fr-"] {
    > .c-title {
      text-align: center;
      @include sp-stack($t-homepage__c-title--sp-stack);
    }
  }

  .fr-special-promotions {
    .c-collection-row {
      margin-bottom: $t-splashpage__fr-special-promotions--rows-stack;

      &:last-child,
      &:empty {
        margin-bottom: 0;
      }
    }
  }

  //TODO: [MS-StyleGuideLegacy] Remove this rule when design system is rolled out
  .l-site-main__top > .fr-inspiring-images {
    @include sp-inset-v($t-splashpage__fr-inspiring-images--sp-inset-v);
    @extend .l-site__wrapper--medium !optional;
    margin: 0 auto;
  }

  @import 'fr-inspiring-images--header';
  @import 'fr-product-ads--splashpage';
}
