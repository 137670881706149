/* -------------------------------------------------------------------------
 * HEADING variables
 *
 */


// Material toggling

$m-heading--enabled: true !default;


// Material default properties

$m-heading__properties: (
  "font-family"         : $f-font-family__secondary,
  "font-family-crop"    : $f-font-family__secondary--crop,
  "font-weight"         : 600,
  "font-size"           : $f-font-size--xxl,
  "line-height"         : $f-line-height--tiny
) !default;


// Material Size Modifiers

$m-heading__mod-sizes--enabled : true !default;
$m-heading__mod-sizes   : (
  "micro": (
    "font-family"       : $f-font-family__secondary,
    "font-family-crop"  : $f-font-family__secondary--crop,
    "font-weight"       : 600,
    "font-size"         : $f-font-size--m,
    "line-height"       : $f-line-height--small
  ),
  "tiny": (
    "font-family"       : $f-font-family__secondary,
    "font-family-crop"  : $f-font-family__secondary--crop,
    "font-weight"       : 600,
    "font-size"         : $f-font-size--l,
    "line-height"       : $f-line-height--small
  ),
  "small": (
    "font-family"       : $f-font-family__secondary,
    "font-family-crop"  : $f-font-family__secondary--crop,
    "font-weight"       : 600,
    "font-size"         : $f-font-size--xl,
    "line-height"       : $f-line-height--tiny
  ),
  "medium"              : $m-heading__properties,
  "large": (
    "font-family"       : $f-font-family__secondary,
    "font-family-crop"  : $f-font-family__secondary--crop,
    "font-weight"       : 600,
    "font-size"         : ($f-font-size--xxs + $f-font-size--xl),
    "line-height"       : $f-line-height--tiny
  ),
  "huge": (
    "font-family"       : $f-font-family__secondary,
    "font-family-crop"  : $f-font-family__secondary--crop,
    "font-weight"       : 600,
    "font-size"         : ($f-font-size--xxs + $f-font-size--xxxl),
    "line-height"       : $f-line-height--tiny
  )
) !default;
