/* -------------------------------------------------------------------------
 * CONTENT USPS VARS
 *
 */

$w-content-usps--bullet-icon-secondary-color:            $f-color__state-success !default;
$w-content-usps--bp:                                     $f-breakpoint--sml !default;


/* -------------------------------------------------------------------------
 * USP BLOCK VARS
 */

$w-content-usps__usp-block-item--spacing:                $f-space--small !default;
$w-content-usps__usp-block-item--max-width:              300px !default;

$w-content-usps__usp-block-title--color:                 $f-color__neutral-grey--dark !default;
$w-content-usps__usp-block-title--fontweight:            700 !default;
$w-content-usps__usp-block-title--fontsize:              $f-font-size--l !default;

$w-content-usps__usp-block-subtitle--padding:            $f-space--small !default;
$w-content-usps__usp-block-subtitle--color:              $f-color__brand-secondary--semi-dark !default;
$w-content-usps__usp-block-subtitle--fontweight:         400 !default;

/* -------------------------------------------------------------------------
 * USP BLOCK AS SLIDER VARS
 */

 $w-content-usps__usp-list-slider--item-mb:                       $f-space--small !default;
 $w-content-usps__usp-list-slider--item-mt__sml:                  $f-space--small !default;
 $w-content-usps__usp-list-slider--item-ml__sml:                  $f-space--medium !default;
 $w-content-usps__usp-list-slider--item-mb__sml:                  $f-space--none !default;