/* -------------------------------------------------------------------------
* Product Map
*
*/

.w-product-map {
  .c-modal {
    background: transparent;

    @include t-mq($until: $w-product-map--breakpoint-mobile) {
      max-height: none;
      top: 0;
      left: 0;
      transform: none;
    }

    @include t-mq($until: $w-product-map--breakpoint-desktop) {
      max-width: none;
    }
  }
  .c-modal__header {
    background: transparent;

    @include t-mq($until: $w-product-map--breakpoint-mobile) {
      position: absolute;
      z-index: $w-product-map__modal-header--z-index;
      width: 100%;
    }
  }
  .c-modal__body {
    padding: 0;

    @include t-mq($from: $w-product-map--breakpoint-desktop) {
      padding: $w-product-map__modal-body--padding;
    }

    &::before {
      margin-bottom: 0;
    }

    &::after {
      margin-top: 0;
    }
  }

  .c-modal__close {
    @include t-mq($until: $w-product-map--breakpoint-mobile) {
      right: $w-product-map__modal-close--offset;
      top: $w-product-map__modal-close--offset;
      transform: none;
      height: $w-product-map__modal-close--size;
      width: $w-product-map__modal-close--size;
      background: $w-product-map__modal-close--background-color;
      border-radius: 50%;
      z-index: $w-product-map__modal-close--z-index;
      display: flex;
      justify-content: center;

      .c-btn__icon {
        color: $w-product-map__modal-close--icon-color;
        width: $w-product-map__modal-close--size;
        height: $w-product-map__modal-close--size;
      }

      .c-btn__icon::before {
        left: 0;
      }
    }
  }

  .c-modal__close .c-btn__icon::before {
    font-size: $w-product-map__modal-close--icon-font-size;
  }

  .c-product-card {
    &:not(.slider-item) {
      @include t-mq($w-product-map--breakpoint-mobile) {
        width: $w-product-map__card--width;
      }
    }

    @include t-mq($until: $w-product-map--breakpoint-mobile) {
      display: flex;
      flex-direction: row;
    }

    .c-card__title {
      font-size: $w-product-map__card-title--font-size-mobile;

      @include t-mq($w-product-map--breakpoint-mobile) {
        font-size: $w-product-map__card-title--font-size;
      }
    }

    .c-card__title::before {
      margin-bottom: 0;
    }

    .c-card__header {
      @include t-mq($until: $w-product-map--breakpoint-mobile) {
        width: $w-product-map__card--mobile-header-size;
        background: $w-product-map__card--mobile-header-background;
      }
    }

    .c-card__body,
    .c-card__footer {
      padding: $w-product-map__card--padding;
    }

    .c-card__body {
      position: relative;
      display: flex;
      flex-direction: column;
      @include t-mq($until: $w-product-map--breakpoint-mobile) {
        flex: 1;
      }
    }
  }

  .c-product-card.slider-item .c-card__body  {
    padding-bottom: $w-product-map__card-body--padding-bottom;
  }

  .c-product-card__body-cta {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include t-mq($until: $w-product-map--breakpoint-mobile) {
      position: absolute;
      bottom: $w-product-map__card--bottom;
      right: $w-product-map__card--padding;
      left: $w-product-map__card--padding;
    }
  }

  .c-product-card__price {
    font-family: $w-product-map__card-price--font-family;
    font-weight: $w-product-map__card-price--font-weight;
  }

  .c-product-card__btn--mobile {
    margin-left: auto;
    @include t-mq($from: $w-product-map--breakpoint-mobile) {
      display: none;
    }
  }

  .c-product-card__score {
    position: absolute;
    right: $w-product-map__card-score--right;
    top: $w-product-map__card-score--top;

    .c-number-score__label {
      @include t-mq($w-product-map--breakpoint-mobile) {
        display: none;
      }
    }

    .c-number-score__body {
      font-family: $w-product-map__card-score--font-family;
      font-weight: $w-product-map__card-score--font-weight;
      font-size: $w-product-map__card-score--font-size-mobile;

      @include t-mq($w-product-map--breakpoint-mobile) {
        font-size: $w-product-map__card-score--font-size;
      }

      &::after {
        bottom: $w-product-map__card-score-arrow--offset;
        border-top: $w-product-map__card-score-arrow--height solid $c-number-score__bubble--color;
        border-right: $w-product-map__card-score-arrow--width solid transparent;
      }
    }
  }

  .c-product-card__rating {
    display: block;
    color: $w-product-map__card-rating--color;
    @include sp-stack($w-product-map__card-rating--sp-stack);
  }

  .c-product-card__btn--desktop {
    display: none;
    @include t-mq($from: $w-product-map--breakpoint-mobile) {
      display: block;
    }
  }

  // Needs to be so specific to override .l-site-main__content .c-breadcrumbs:first-child
  .c-product-card .c-product-card__breadcrumbs .c-breadcrumbs {
    margin: 0;
  }

  .c-product-card__breadcrumbs {
    flex-grow: 2;
  }

  .c-slider {
    left: $w-product-map__slider--left;

    @include t-mq($until: $w-product-map--breakpoint-mobile) {
      bottom: $w-product-map__slider-mobile--bottom;
      left: 0;
      margin: $w-product-map__slider-mobile--margin;
    }

    .c-slider__item.tns-item  {
      padding-bottom: $w-product-map__slider-item--padding-bottom;
      padding-right: $w-product-map__slider-item--padding-right;
      display: inline-flex;
    }

    .c-slider__content {
      display: flex;
    }

    .tns-inner {
      margin: $w-product-map__slider-tns-inner--margin;
    }

    .tns-nav {
      bottom: $w-product-map__slider-tns-nav--bottom;
      button[data-nav] {
        background-color: $c-slider__nav--bg-color--outer-navigation;
        border: none;

        &.tns-nav-active {
          background-color: $c-slider__nav--bg-color--active--outer-navigation;
        }
      }
    }

    .c-slider__buttons--arrow {
      top: $w-product-map__slider-buttons-arrow--top;
    }

    .c-slider__buttons--arrow.c-slider__buttons--arrow--prev {
      left: $w-product-map__slider-buttons-arrow--left;
    }

    .c-slider__buttons--arrow.c-slider__buttons--arrow--next {
      right: $w-product-map__slider-buttons-arrow--right;
    }
  }

  .c-map-interactive__card {
    height: auto;
  }

  .c-map-interactive__card-close {
    top: $w-product-map__card-close--top;
    right: $w-product-map__card-close--right;
  }

}
