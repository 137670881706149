/* -------------------------------------------------------------------------
* Site-main mixins
*/

@mixin l-site-main__content__wide-background-color($background-color) {
  background-color: $background-color;
  box-shadow: -$l-site__wrapper-medium--inset 0px 0px 0px $background-color, $l-site__wrapper-medium--inset 0px 0px 0px $background-color;

  @include t-mq($from: $l-site__wrapper-bp) {
    box-shadow: -$l-site__wrapper-medium--inset--from-bp 0px 0px 0px $background-color, $l-site__wrapper-medium--inset--from-bp 0px 0px 0px $background-color;
  }

  @include t-mq($from: $l-site__wrapper-medium--from) {
    box-shadow: -1200px 0px 0px 0px $background-color, 1200px 0px 0px 0px $background-color;
  }
}
