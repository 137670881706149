/* -------------------------------------------------------------------------
 * REVIEWS TEASER
 *
 */
 
 $w-reviews-teaser__stack:                                                                  $f-space--small + $f-space--tiny !default;
 $w-reviews-teaser__bq:                                                                     "sml" !default;

 $w-reviews-teaser--wrapper__blur-radius:                                                   $f-space--tiny !default;

 $w-reviews-teaser--reviews-items__gap:                                                     $f-space--medium !default;

 $w-reviews-teaser--item__inset-v:                                                          $f-space--medium + $f-space--small !default;
 $w-reviews-teaser--item__inset-h:                                                          $f-space--medium !default;
 $w-reviews-teaser--item__shadow-box:                                                       $f-shadow--glow rgba($f-shadow__color, $f-shadow__opacity)!default;
 $w-reviews-teaser--item-rating__stack:                                                     $f-space--medium + $f-space--small !default;
 $w-reviews-teaser--item__width:                                                            50% !default;
 $w-reviews-teaser--item__width-bq:                                                         100% !default;

 $w-reviews-teaser--review-content--collapse-btn__font-size:                                $f-font-size--s !default;

 $w-reviews-teaser--item--translate-btn__color:                                             $e-links__color !default;
 $w-reviews-teaser--item--translate-btn__color-hover:                                       $e-links__hover-color!default;
 $w-reviews-teaser--item--translate-btn__font-size:                                         $f-font-size--s !default;
 $w-reviews-teaser--item--translate-btn__padding-top:                                       $f-space--small + $f-space--tiny !default;
 $w-reviews-teaser--item--translate-btn__transition-duration:                               $f-duration !default;

 $w-reviews-list__loader__margin-left:                                                      $f-space--small + $f-space--tiny !default;
 $w-reviews-list__modal--box-shadow:                                                        $f-shadow rgba($f-shadow__color, $f-shadow__opacity)!default;

 $w-reviews-teaser__modal--body-content--item-gap:                                          $f-space--medium !default;
 
 $w-reviews-teaser__teaser-loader__margin-left:                                             $f-space--tiny !default;