// Neutral
.c-btn--neutral,
a.c-btn--neutral {
  color: $c-btn-neutral--color;
  background-color: $c-btn-neutral--bg-color;
  border-color: $c-btn-neutral--border-color;

  .c-btn__loader {
    @include c-loader__set-color($c-btn-neutral--color);
  }

  &:hover {
    color: $c-btn-neutral--hover--color;
    background-color: $c-btn-neutral--hover--bg-color;
    border-color: $c-btn-neutral--hover--border-color;

    .c-btn__loader {
      @include c-loader__set-color($c-btn-neutral--hover--color);
    }
  }

  &:active {
    color: $c-btn-neutral--pressed--color;
    background-color: $c-btn-neutral--pressed--bg-color;
    border-color: $c-btn-neutral--pressed--border-color;

    .c-btn__loader {
      @include c-loader__set-color($c-btn-neutral--pressed--color);
    }
  }

  &:focus {
    box-shadow: $c-btn-neutral--focus--shadow;
  }

}
