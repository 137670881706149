/* -------------------------------------------------------------------------
 * bookings price overview
 *
 */

.w-booking-price-overview {
  .w-booking-price-overview {
    @include sp-stack($w-booking-price-overview__sp-inline);
  }

  .w-booking-price-overview__heading-extra-content-title {
    @include sp-stack($w-booking-price-overview__heading-extra-content-title__sp-stack);
  }

  .w-booking-price-overview--warning-box {
    @include sp-stack($w-booking-price-overview--warning-box__sp-stack);
  }

  
  @include t-mq($from: $w-booking-price-overview__breakpoint-sml) {
    .w-booking-price-overview .c-table__item {
      @include sp-inset-h($w-booking-price-overview__sp-inset-h);
    }
  }

  .c-breakdown-table__item-content--discount {
    border-top-color: $w-booking-price-overview__discount--border-color;
    border-bottom: 1px solid $w-booking-price-overview__discount--border-color;
    background-color: $w-booking-price-overview__discount--background-color;
    
    .c-breakdown-table__item-content:not(.c-breakdown-table__item-content--subitem) {
      font-weight: 700;
    }

    + .c-table__item {
      border-top: 0px;
    }
  }
}

.is-unresolved .w-booking-price-overview__heading-extra-content-text {
  display: none;
}

.w-booking-price-overview{
  .c-breakdown-table__item-content--subitem:not(.w-booking-price-overview__subitem--child){
    margin-top: $w-booking-price-overview__subitem--inset-top;
  }
  @include t-mq($until: $w-booking-price-overview__breakpoint-sml) {
    .c-breakdown-table__item-content, .c-breakdown-table__item-content--subitem{
      margin-left: 0;
      margin-right: 0;
    }
  }
}
.w-booking-price-overview__subitem--indented{
  > .c-breakdown-table__item-content.c-breakdown-table__item-content--subitem.w-booking-price-overview__subitem--child:last-child{
    border-left: $w-booking-price-overview__subitem--child--border-size solid transparent;
  }
  .c-breakdown-table__item-content--subitem.w-booking-price-overview__subitem--child{
    border-left: $w-booking-price-overview__subitem--child--border-size solid $w-booking-price-overview__subitem--child--color;
    margin-left: $w-booking-price-overview__subitem--child--inset-left;
    @include t-mq($from: $w-booking-price-overview__breakpoint-sml) {
      margin-left: 2 * $w-booking-price-overview__subitem--child--inset-left;
    }
    .c-breakdown-table__item-left{
      padding-left: $w-booking-price-overview__subitem--child--inset-left;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: $w-booking-price-overview__subitem--child--border-size * -1;
        bottom: 50%;
        width: 10px;
        border: $w-booking-price-overview__subitem--child--border-size solid $w-booking-price-overview__subitem--child--color;
        border-top: 0 none transparent;
        border-right: 0 none transparent;
      }
    }
  }
} 
