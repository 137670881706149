/* -------------------------------------------------------------------------
 * REVEAL SLIDE
 *
 */

// Helper functions
// --------------------------------------------------

@function a-reveal-slide__direction-to-transform($_direction: "left") {
  $_directions-transforms: (
    "left":     translate(100%, 0),
    "right":    translate(-100%, 0),
    "top":      translate(0, -100%),
    "bottom":   translate(0, 100%),
  );

  @if not map_has_key($_directions-transforms, $_direction) {
    @error "a-reveal-slide__direction-to-transform: `#{$_direction}` is not one of the allowed directions.";
  }

  @return map_get($_directions-transforms, $_direction);

}


// Animation as a mixin
// --------------------------------------------------

@mixin a-reveal-slide($_duration: $a-reveal-slide__duration, $_direction: $a-reveal-slide__direction) {
  transform: a-reveal-slide__direction-to-transform($_direction);
  transition-property: transform;
  transition-duration: $_duration;
  transition-timing-function: $a-reveal-slide__easing-out;
}

@mixin a-reveal-slide--in($_duration: $a-reveal-slide__duration) {
  transform: translate(0, 0);
  transition-property: transform;
  transition-duration: $_duration;
  transition-timing-function: $a-reveal-slide__easing-in;
}

// Duration modifiers output
// --------------------------------------------------

@mixin a-reveal-slide__mod-duration($_duration-name: "normal", $_duration-value: $f-duration--normal) {
  .a-reveal-slide--duration-#{$_duration-name} {
    transition-duration: $_duration-value;
  }
}

// Direction modifiers output
// --------------------------------------------------

@mixin a-reveal-slide__mod-direction($_direction-name: "right") {
  .a-reveal-slide--direction-#{$_direction-name} {
    transform: a-reveal-slide__direction-to-transform($_direction-name);
  }
}

// Unset as mixin
// --------------------------------------------------

@mixin a-reveal-slide--unset() {
  transform: initial;
  transition-property: initial;
  transition-duration: initial;
  transition-timing-function: initial;
}
