/* -------------------------------------------------------------------------
 * TABLE
 *
 * A simple object for manipulating the structure of HTML `table`s.
 *
 * Params:
 * SPACE .............................. Horizontal separation between cells
 *
 */


// Object selector output
// --------------------------------------------------

@if ($o-table--enabled) {
  .o-table {
    @include o-table();

    th,
    td {
      @include o-table__cell();
    }
  }

}


// Space modifiers
// --------------------------------------------------

@if ($o-table--enabled and $o-table_mod-spaces--enabled) {
  @each $_space-name, $_space-value in $o-table__mod-spaces {
    @include o-table__mod-space($_space-name, $_space-value);
  }
}


// Fixed modifier
// --------------------------------------------------

@if ($o-table--enabled and $o-table__mod-fixed--enabled) {
  .o-table--fixed {
    @include o-table--fixed;
  }
}
