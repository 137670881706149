/* -------------------------------------------------------------------------
* Map Interactive
*
*/

.c-map-interactive__card-close {
  font-size: $c-map-interactive__card-close--font-size;
  color: $c-map-interactive__card-close--color;
}

.c-map-interactive__custom-marker.c-map-interactive__custom-marker--hover-in {
  background-color: $c-map-interactive__custom-marker--bg-color--hover;
}

.c-map-interactive__custom-marker.c-map-interactive__custom-marker--active {
  border-color: $c-map-interactive__custom-marker--border-color--active;
}
