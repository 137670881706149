
.w-booking-awaitable-popup__modal.w-booking-awaitable-popup__modal {
  .w-booking-awaitable-popup__modal-icon, .w-booking-awaitable-popup__modal-title{
    margin-bottom: $w-booking-awaitable-popup__modal--margin;
    text-align: center;
    + .w-booking-awaitable-popup__modal-body{
      text-align: center
    }
  }
  .c-modal-v2__body{
    .m-body--small{
      @include sp-stack-children($w-booking-awaitable-popup__modal--margin);
    }
  }
}

.w-booking-awaitable-popup__modal-footer{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: fit-content;
}

