/* -------------------------------------------------------------------------
 * IMG-BOX
 *
 */

// Component selector output
// --------------------------------------------------

.c-img-box {
  @include c-img-box();
  position: fixed;
  width: 75%;
  top: 50%;
  left: 50%;
  @include t-mq($until: $f-breakpoint--md) {
    width: 100%;
  }

  &.in {
    transform: translate(-50%, -50%);
  }
}

.c-img-box__image {
  overflow: hidden;

  &.zoom2x img {
    width: $c-img-box__image--zoom2x;
    height: auto;
  }

  &.zoom4x img {
    width: $c-img-box__image--zoom4x;
    height: auto;
  }

  &.zoom8x img {
    width: $c-img-box__image--zoom8x;
    height: auto;
  }
}

// Zoom buttons

.c-img-box__buttons {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  margin: $c-img-box__buttons--margin;
  bottom: 0;
  right: 0;
}

.c-img-box-close-button {
  position: absolute;
  margin-right: $c-img-box__buttons--margin-right--close-button;
  margin-top: $c-img-box__buttons--margin-top--close-button;
  right: 0;
}

.c-img-box__buttons--close {
  position: relative;
  opacity: 1;
  transition: opacity $f-duration--normal ease;
  z-index: $f-z-index--1;

  .c-btn__icon {
    color: $c-img-box__btn-zoom--color;
    width: $c-img-box__btn-zoom--size;
    height: $c-img-box__btn-zoom--size;

    &::before {
      font-size: $c-img-box__btn-zoom--font-size;
      left: $c-img-box__btn-zoom_icon--left-fix;
    }
  }
}

.c-img-box__buttons--zoom--in {
  margin-bottom: $c-img-box__buttons--zoom--in--margin-bottom;
}

.c-img-box__buttons--zoom {
  position: relative;
  opacity: 1;
  transition: opacity $f-duration--normal ease;
  z-index: $f-z-index--1;

  .c-btn__icon {
    color: $c-img-box__btn-zoom--color;
    background-color: $c-img-box__btn-zoom--bg-color;
    width: $c-img-box__btn-zoom--size;
    height: $c-img-box__btn-zoom--size;
    border-radius: $c-img-box__btn-zoom--border-radius;
    transition: $c-img-box__btn-zoom--transition-property $c-img-box__btn-zoom--transition-duration $c-img-box__btn-zoom--transition-easing;

    &::before {
      font-size: $c-img-box__btn-zoom--font-size;
      left: $c-img-box__btn-zoom_icon--left-fix;
      color: $c-img-box__btn-zoom_icon--color;
    }
  }

  &:hover {
    .c-btn__icon {
      color: $c-img-box__btn-zoom--hover--color;

      &::before {
        color: $c-img-box__btn-zoom_icon--hover--color;
      }
    }
  }

  &:active {
    .c-btn__icon {
      background-color: $c-img-box__btn-zoom--active-bg-color;
      color: $c-img-box__btn-zoom--hover--color;

      &::before {
        color: $c-img-box__btn-zoom_icon--active--color;
      }
    }
  }

  &.disabled {
    .c-btn__icon {
      background-color: $c-img-box__btn-zoom--disabled-bg-color;
      color: $c-img-box__btn-zoom--disabled-color;
      &::before {
        color: $c-img-box__btn-zoom_icon--disabled--color;
      }
    }
  }
}
