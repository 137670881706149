/* -------------------------------------------------------------------------
 * Form
 *
 */

.c-form {
  text-align: right;
  @include sp-stack-children($c-form--sp-stack);
}

.c-form__section {
  text-align: left;
}

.c-form__section-title {
  border-bottom: $c-form__section-title-border-size;
  border-bottom-style: $c-form__section-title-border-style;
  border-bottom-color: $c-form__section-title-border-color;
  @include sp-stack($c-form__section-title--sp-stack);
}

.c-form-section__title {
  @include sp-stack($c-form__section-title-border-margin);
}

.c-form__fieldset {
  @include sp-stack($c-form__fieldset--sp-stack);

  &:last-child {
    @include sp-stack(0);
  }

  .c-form__fieldset-title-container {
    @include sp-stack($c-form__fieldset-title-container--sp-stack);
  }

  .c-title {
    @include sp-stack($c-form__fieldset-title--sp-stack);
  }

  .c-form__fieldset-description {
    @include sp-stack($c-form__fieldset-description--sp-stack);
    font-size: $c-form__fieldset-description--font-size;
    color: $c-form__fieldset-description--color; 
  }
}

.c-form__item {
  padding-left: $c-form__item--padding-left;
  margin-top: $c-form__item--sp-stack;
}

.c-form__fieldset-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: $c-form__fieldset-container--margin-left;

  @include c-form__fieldset-container--sp-stack-children($c-form__item--sp-stack);

  .c-form__item--micro {
    @include c-form__item--size($c-form__item--half-width, $c-form__item--third-width, $c-form__item--sixth-width);
  }

  .c-form__item--tiny {
    @include c-form__item--size($c-form__item--full-width, $c-form__item--half-width, $c-form__item--fourth-width, $c-form__item--third-width);
  }

  .c-form__item--small {
    @include c-form__item--size($c-form__item--full-width, $c-form__item--third-width, $c-form__item--third-width);
  }

  .c-form__item--medium {
    @include c-form__item--size($c-form__item--full-width, $c-form__item--half-width, $c-form__item--half-width);
  }

  .c-form__item--large {
    @include c-form__item--size($c-form__item--full-width, $c-form__item--full-width, $c-form__item--full-width);
  }
}

// Specifics for field type
// ------------------------------------------------
.c-form__fieldset-container > .c-choice-list {
  margin-bottom: $c-form__fieldset--choice-list-margin-bottom;
}

.c-form__fieldset-container > .c-choice-list:not(.c-choice-list--boxed) .c-choice-list__label {
  margin-bottom: 0;
}


// Specifics for collapse
// ------------------------------------------------
.c-form__collapse-trigger {
  cursor: pointer;
  .c-box__content{
    display: flex;
    align-items: center;  
    width: 100%;
  }

  .c-form__section-title{
    border: none;
    margin-bottom: 0;
  }
  .c-form-section__title{
    @include sp-stack(0);
  }
  .c-form__section-heading-icon{
    margin-right: $c-form__section-heading-icon--margin-right;
    color: $c-form__section-heading-icon--color;
  }
  .c-form__section-collapse-icon{
    justify-self: end;
    margin-left: auto;
  }
  .c-form__section-collapse-icon--shown{
    display: none;
  }
  &[data-c-collapse__opened="true"]{
    .c-form__section-collapse-icon--shown{
      display: block;
    }
    .c-form__section-collapse-icon--hided{
      display: none;
    }
  }
}

.c-form__section--collapse{
  .c-form__fieldset:nth-of-type(1) {
    padding-top: $c-form__section--collapse-fieldset-sp-top;
  }
}

