/* -------------------------------------------------------------------------
 * BOOKING CHOICE LIST VARS
 *
 */

 $w-booking-choice-list__additional-text--font-size:                    $f-font-size--m !default;
 $w-booking-choice-list__additional-text--font-color:                   $f-color__neutral-grey !default;
 $w-booking-choice-list__additional-text--font-color--selected:         $f-color__state-info !default;
 $w-booking-choice-list__additional-text--font-weight--selected:        700 !default;

 $w-booking-choice-list__additional-text--sp-inline:                    $f-space--small !default;
 
 $w-booking-choice-list__text--sp-inline:                               $f-space--small !default;
 
 $w-booking-choice-list__item-text:                                     $f-font-size--s !default;
 
 $w-booking-choice-list__messages-top--margin-bottom:                   $f-space--medium !default;
 $w-booking-choice-list__applies-to-all-participants--margin-h:          -1 * $f-space--tiny !default;
 
 $w-booking-choice-list__additional-text-legend--font-weight:           500 !default;
 $w-booking-choice-list__additional-text-legend--font-size:             $f-font-size--xs !default;