/* -------------------------------------------------------------------------
* Phone input
*
*/

.c-phone-input.is-open {
  .c-phone-input__chevron {
    transform: rotate(180deg);
  }
}

.c-phone-input__wrapper {
  position: relative;
}

.c-phone-input__country-flag {
  @include sp-inline($c-phone-input__country-flag--sp-inline);
}

.c-phone-input__country-selector {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: $c-phone-input__country-selector--z-index;
  @include sp-inset($c-phone-input__country-selector--sp-inset);
}

.c-phone-input__country-selector--hidden{
  .c-phone-input__country-selector {
    display: none;
  }
  .c-phone-input__number .c-textbox__element{
    text-indent: $c-phone-input__number--reset-text-indent;
  }
}

.c-phone-input__chevron {
  pointer-events: none;
  will-change: transform;
  display: block;
  backface-visibility: visible;
  transform: rotateX(0deg);
  transition-property: transform;
  transition-duration: $c-phone-input__chevron--duration;
  transition-timing-function: $c-phone-input__chevron--easing;
}

.c-phone-input__number .c-textbox__element {
  text-indent: $c-phone-input__number--text-indent;
}

.c-phone-input__country-list {
  @include sp-inset($c-phone-input__country-list--sp-inset);
}

.c-phone-input__country-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  @include sp-inset-squish($c-phone-input__country-item--sp-squish);

  &:hover {
    background-color: $c-phone-input__country-item--bg-color--hover;
  }

  &.is-active {
    pointer-events: none;
  }

  .c-phone-input__country-flag {
    display: block;
    @include sp-inline($c-phone-input__country-flag--sp-inline--country-item);
  }

  .c-phone-input__country-flag[data-m-country-flag__country-id="ch"] {
    margin-right: $c-phone-input__country-flag--sp-inline--country-item-ch;
  }
}

.c-phone-input__country-item.is-active {
  .c-phone-input__country-name {
    font-weight: 700;
  }
}

.c-phone-input__country-name {
  @include sp-inline($c-phone-input__country-name--sp-inline);
}

.c-phone-input__country-dial {
  color: $c-phone-input__country-dial--color
}

.c-phone-input__messages  {
  font-size: $c-phone-input__messages--font-size;
  display: flex;
}

.c-phone-input__message:first-child {
  margin-top: $c-phone-input__messages--margin-top;
}

// States
// -----------------------------------------

// Disabled

.c-phone-input.is-disabled,
.is-disabled .c-phone-input {
  .c-phone-input__country-selector {
    pointer-events: none;
  }
}

.c-phone-input.is-disabled {
  .c-phone-input__country-flag {
    opacity: $c-phone-input__disabled--opacity;
  }

  .c-phone-input__chevron {
    color: $c-phone-input__chevron--disabled--color;
  }

  .c-textbox {
    .c-textbox__wrapper {
      opacity: 1;
    }
  }
}

.is-disabled .c-phone-input {
  .c-phone-input__country-selector {
    opacity: $c-phone-input__disabled--opacity;
  }
}

// Error
.c-phone-input.has-error,
.has-error .c-phone-input {
  .c-phone-input__message {
    color: $c-phone-input__message--error--color
  }
}


// Modifiers
// -----------------------------------------

// Highlighted
.c-phone-input--highlighted {
  .c-phone-input__prefix {
    .c-textbox__element[disabled] {
      color: $c-phone-input__element--highlighted--color;
    }
  }
}

.c-phone-input{
  &.is-required:not(.c-textbox--without-required-symbol) {
    .c-phone-input__label:after {
      content: ' *';
      display: inline;
    }
  }
}
