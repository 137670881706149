/* -------------------------------------------------------------------------
 * TRANSFER SUMMARY VARS
 *
 */

$w-transfer-summary__items-container--gap:                                          $f-space--small !default;

$w-transfer-summary__heading-container--margin-bottom:                              $f-space--small !default;

$w-transfer-summary__heading--font-family:                                          $f-font-family__secondary !default;
$w-transfer-summary__heading-before--margin-right:                                  4px !default;

$w-transfer-summary__info-text--color:                                              $f-color__state-info--semi-dark !default;
$w-transfer-summary__info-text--margin-top:                                         16px !default;
