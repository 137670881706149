%fr-excursions {
    .m-price__currency,
    .m-price__value {
        color: $fr-destination-page__excursion-card-price--color;
    }

    .c-card-price__info {
        align-items: center;
    }

    .c-card-price__title {
        font-size: $fr-destination-page__excursion-card-price--title-font-size;
    }

    .c-price__info {
        color: $fr-destination-page__excursion-card-price--legend-color;
    }
}

.fr-excursions {
    //excursions from destination page and the acco page should have the same design and we use one placeholder as a source to accomplish it
    @extend %fr-excursions;
}
