/* -------------------------------------------------------------------------
 * DD-GRID
 */


$w-dd-grid__cell--bg-color:                                     $f-color__neutral-white !default;
$w-dd-grid__cell--header-corner--font-weight:                   700 !default;

$w-dd-grid__cell--color--hover:                                 $f-color__state-info--semi-dark !default;
$w-dd-grid__cell--bg-color--hover:                              $f-color__state-info--ultra-light !default;
$w-dd-grid__cell--border-color--hover:                          $f-color__state-info--semi-dark !default;

$w-dd-grid__cell--bg-color--selected:                           $f-color__brand-primary !default;
$w-dd-grid__cell--bg-color-header--selected:                    $f-color__brand-primary--ultra-light !default;
$w-dd-grid__cell--border-color--selected:                       $f-color__brand-primary !default;
$w-dd-grid__cell--color-header--selected:                       $f-color__brand-primary--semi-dark !default;

$w-dd-grid__cell--bg-color--active:                             $f-color__brand-primary--dark !default;

$w-dd-grid__cell--onrequest-color--active:                      $f-color__neutral-grey--light !default;
$w-dd-grid__onrequest--text-transform:                          unset !default;

$w-dd-grid__legend_item_description--text-transform:            unset !default;

$w-dd-grid__navigation-add-next-month-column:                   $f-color__neutral-grey--light !default;
