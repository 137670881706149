/* -------------------------------------------------------------------------
 * NAV
 */

 $c-nav--sp-stack:                                                    $f-space--tiny * 1.5 !default;
 $c-nav__item--has-child--font-size:                                  $f-font-size--xs !default;
 $c-nav__link--hover--color:                                          $f-color__brand-primary !default;
 $c-nav--sp-inset-left:                                               $f-space--small !default;
 $c-nav--font-family:                                                 $f-font-family__secondary !default;
 $c-nav--font-family--crop:                                           $f-font-family__secondary--crop !default;
 $c-nav--font-weight:                                                 600 !default;

 $c-nav__item--has-child--color:                                      $f-color__brand-primary--dark !default;

 // Variants
 // ---------------------

 // Inline

 $c-nav__button--inline--color--hover:                                $f-color__state-info--semi-dark !default;

 // Dropdown

 $c-nav--dropdown--sp-inline:                                         $f-space--medium !default;
 $c-nav--dropdown--sp-inset-v:                                        $f-space--small + $f-space--tiny !default;
 $c-nav--dropdown--sp-inset-h:                                        $f-space--small + $f-space--tiny !default;

 $c-nav__list--dropdown-min-width:                                    300px !default;
 $c-nav__list--dropdown-max-width:                                    300px !default;

 $c-nav__link--dropdown--pressed--bg-color:                           $f-color__brand-secondary--light !default;
 $c-nav__link--dropdown--active--bg-color:                            $f-color__brand-secondary--light !default;
 $c-nav__link--dropdown--4th--padding-left:                           $f-space--small + $f-space--medium !default;
 $c-nav__item--active--dropdown--border-color:                        $f-color__brand-secondary--light !default;

 $c-nav__list--dropdown-border-width:                                 $f-space--none !default;
 $c-nav__list--dropdown--level-2--margin-top:                         $f-space--small !default;

 $c-nav__link--color:                                                 $f-color__brand-primary--dark !default;
 $c-nav--dropdown__border-width:                                      0px !default;
 $c-nav__link--dropdown--hover--bg-color:                             $f-color__brand-secondary--light !default;

 $c-nav-item--shadow:                                                 $f-shadow rgba($f-shadow__color, $f-shadow__opacity) !default;

 // Block

 $c-nav--block--sp-inset-h:                                           $f-space--small * 1.5 !default;
 $c-nav--block--sp-inset-v:                                           $f-space--small !default;

 $c-nav__link--block--pressed--color:                                 $f-color__brand-secondary--light !default;
 $c-nav__link--block--active--bg-color:                               $f-color__brand-secondary--light !default;
 $c-nav__link--block--hover--bg-color:                                $f-color__brand-secondary--light !default;
 $c-nav__link--block--pressed--color:                                 $f-color__brand-primary--dark !default;
 $c-nav__back--block--color:                                          $f-color__brand-primary--dark !default;
 $c-nav__item--active--block--border-color:                           $f-color__brand-secondary--light !default;
 $c-nav__link--block--color:                                          $f-color__brand-primary--dark !default;

 $c-nav__link--block--children--padding-left:                         $c-nav--sp-inset-left * 3 !default;

 $c-nav__back--sp-inset-h:                                            $f-space--small !default;
 $c-nav__back--block--text-transform:                                 unset !default;
 $c-nav__back--block--font-size:                                      $f-font-size--m !default;

 // List

 $c-nav--list--sp-inset-h:                                           $f-space--small * 1.5 !default;
 $c-nav--list--sp-inset-v:                                           $f-space--tiny !default;

 $c-nav__item--list--sp-stack:                                       $f-space--none !default;

 $c-nav__link--list--bg-color:                                       $f-color__neutral-white--transparent !default;
 $c-nav__link--list--pressed--color:                                 $f-color__brand-secondary--light !default;
 $c-nav__link--list--hover--bg-color:                                $f-color__brand-secondary--light !default;

 $c-nav__back--list--color:                                          $f-color__brand-primary--semi-dark !default;
 $c-nav__link--list--color:                                          $f-color__brand-primary--dark !default;


 // Collapsible

 $c-nav__item--collapsible--has-child--color:                        $f-color__brand-primary--dark !default;
 $c-nav__item--collapsible--has-child--font-weight:                  normal !default;
 $c-nav__item--collapsible--has-child--hover--color:                 $f-color__brand-primary--semi-dark !default;
 $c-nav__item--collapsible--has-child--hover--font-weight:           normal !default;
 $c-nav__item--collapsible--has-child--active--color:                $f-color__brand-primary !default;
 $c-nav__item--collapsible--has-child--active--font-weight:          700 !default;

 $c-nav__item--collapsible--color:                                   $f-color__neutral-grey--dark !default;
 $c-nav__link--collapsible--hover--color:                            $f-color__brand-primary--semi-dark !default;

