.fr-acco-facilities__collapse {
  position: relative;
  min-height: $fr-acco-facilities__collapse--min-height;
  @include sp-stack($fr-acco-facilities__collapse--sp-stack);
  
  &:not(.is-idle):after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    background-image: linear-gradient(to bottom, $fr-acco-facilities__collapse--gradient-initial, $fr-acco-facilities__collapse--gradient-final);
  }

  &.in {
    &:after {
      display: none;
    }
  }
}

.fr-acco-facilities__collapse-content {
  background-color: $fr-acco-facilities__collapse-content--bg-color;
  border-radius: $fr-acco-facilities__collapse-content--border-radius;
  @include sp-inset($fr-acco-facilities__collapse-content--sp-inset);
}

.fr-acco-facilities__category {
  position: relative;
  @include sp-inset($fr-acco-facilities__category--sp-inset);
  @include t-mq($from: $fr-acco-facilities--bp) {
    @include sp-inset($fr-acco-facilities__category--sp-inset--from-bp);
  }

  &:not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    left: $fr-acco-facilities__category--sp-inset;
    right: $fr-acco-facilities__category--sp-inset;
    bottom: $fr-acco-facilities__category-after--bottom;
    height: $fr-acco-facilities__category-after--height;
    background-color: $fr-acco-facilities__category--border-color; 
    @include t-mq($from: $fr-acco-facilities--bp) {
      left: $fr-acco-facilities__category--sp-inset--from-bp;
      right: $fr-acco-facilities__category--sp-inset--from-bp;
    }
  }
}

.fr-acco-facilities__category-name {
  text-transform: $fr-acco-facilities__category-name--text-transform;
  font-weight: $fr-acco-facilities__category-name--font-weight;
  @include sp-stack($fr-acco-facilities__category-name--sp-stack);
}

.fr-acco-facilities__category-list {
  @include sp-stack($f-space--none);
}

.fr-acco-facilities__category-item {
  break-inside: avoid;

  &:not(:last-child) {
    margin-bottom: $fr-acco-facilities__category-item--sp-stack;
  }
}

.fr-acco-facilities__trigger-wrapper {
  [data-c-collapse__action="open"][data-c-collapse__opened="true"],
  [data-c-collapse__action="close"] {
    display: none;
  }

  [data-c-collapse__opened="true"][data-c-collapse__action="close"] {
    display: inline-block;
  }

  .c-btn.is-hidden {
    display: none;
  }
}

// Highlighted facility section

.fr-acco-facilities__category.fr-acco-facilities__category-highlight {
  background-color: $fr-acco-facilities__category-highlight--background-color;
  border: $fr-acco-facilities__category-highlight--border-width solid $fr-acco-facilities__category-highlight--border-color;
  &:after {
    display: none; 
  }

  .fr-acco-facilities__category-item {
    list-style: none;
    position: relative;
    
  }

  .fr-acco-facilities__category-name {
    color: $fr-acco-facilities__category-highlight-name--color;
  }

  .fr-acco-facilities__category-item-icon {
    position: absolute;
    left: $fr-acco-facilities__category-highlight-icon--left;
  }
}