/* -------------------------------------------------------------------------
 * COLLAPSE
 *
 */

// Component selector output
// --------------------------------------------------

.c-collapse {
  &:not([class*="c-collapse--from"]) {
    @include c-collapse();
  }
}

.c-collapse__trigger {
  color: $c-collapse__trigger--color;
  margin-right: 1rem;
  transition: color $c-collapse__trigger--duration;
  @include m-body($c-collapse__trigger--text-props);

  &:hover:not([disabled]) {
    color: $c-collapse__trigger--color--hover;
  }

  &[disabled] {
    color: $c-collapse__trigger--disabled;
    pointer-events: none;
  }

  // Hide and show according action and opened attribute
  &[#{$c-collapse__attr__action}].is-hidden,
  &[#{$c-collapse__attr__action}="open"][#{$c-collapse__attr__opened}],
  &[#{$c-collapse__attr__action}="close"]:not([#{$c-collapse__attr__opened}]) {
    &:not(.c-collapse__trigger--smooth) {
      display: none;
    }
    &.c-collapse__trigger--smooth {
      opacity: 0;
      height: 0;
    }
  }

  &[#{$c-collapse__attr__action}="toggle"],
  &[#{$c-collapse__attr__action}="close"][#{$c-collapse__attr__opened}],
  &[#{$c-collapse__attr__action}="open"]:not([#{$c-collapse__attr__opened}]) {
    opacity: 1;
    height: $c-collapse__trigger--font-size * $c-collapse__trigger--line-height;
  }

  // Hide open trigger smoothly
  &.c-collapse__trigger--smooth {
    will-change: opacity, height;
    transition-property: opacity, height;
    transition-duration: $c-collapse__duration;
    transition-timing-function: $c-collapse__easing;
  }

  // Trigger icon (closed)
  .c-collapse__trigger-icon {
    will-change: transform;
    display: block;
    backface-visibility: visible;
    transform: rotateX( 0deg );
    transform-origin: $c-collapse__trigger-icon--origin;
    transition-property: transform;
    transition-duration: $c-collapse__duration;
    transition-timing-function: $c-collapse__easing;
  }
  // Trigger icon (opened)
  &[#{$c-collapse__attr__opened}] .c-collapse__trigger-icon {
    transform: rotateX( 180deg );
  }

}


// Enable collapse from certain breakpoints
// --------------------------------------------------

@each $_breakpoint in $c-collapse--breakpoints {
  @include t-mq($from: $_breakpoint) {
    .c-collapse.c-collapse--from#{s-core-string-breakpoint($_breakpoint)} {
      @include c-collapse();
    }
  }
}
