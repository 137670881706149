/* -------------------------------------------------------------------------
 * TITLE
 *
 */

.c-title {
  display: block;
}

.c-title.is-unresolved,
.is-unresolved .c-title {
  position: relative;
    
  &::before {
    content: '';
    position: absolute;
    top: $c-title--unresolved-background--top;
    left: $c-title--unresolved-background--left;
    height: $c-title--unresolved-background--height;
    width: $c-title--unresolved-background--width;
    display: block;
    background-color: $c-title--unresolved-background;
    border-color: $c-title--unresolved-background;
    border-radius: $c-title--unresolved--border-radius;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: $c-title__unresolved--width;
    display: block;
    animation: is-unresolved-animation $c-title__unresolved--animation-duration infinite;
    background: linear-gradient(
        $c-title__unresolved--animation-angle,
        $f-color__neutral-white--transparent,
        $c-title__unresolved--animation-endcolor,
        $f-color__neutral-white--transparent
    );
  } 
}