/* -------------------------------------------------------------------------
 * Bullet list
 */

$c-bullet-list__sp-stack:                             $f-space--small !default;
$c-bullet-list__font-size:                            $f-font-size--m !default;
$c-bullet-list__font-weight:                          700 !default;
$c-bullet-list__icon--position-fix:                   2px !default;

// Display modifiers

$c-bullet-list__sp-inline:                            $f-space--medium !default;


// Color schemes overrides
// -----------------------------

$c-bullet-list__icon--color--state-info--light:       $f-color__state-info !default;

