$w-booking-car-rental--bp-medium:                                       $f-breakpoint--sml !default;

$w-booking-car-rental__list-option-rich-item--margin-top:               $f-space--small !default; 

$w-booking-car-rental__car-information--padding-bottom:                 $f-space--tiny !default;

$w-booking-car-rental__item--label-on-request--background-color:        $f-color__neutral-grey--light !default;
$w-booking-car-rental__item--label-on-request--color:                   $f-color__neutral-grey--semi-dark !default; 
$w-booking-car-rental__item--label--sp-stack:                           $f-space--tiny !default; 
$w-booking-car-rental__item--label-on-request--sp-inset-v:              $f-space--tiny - 2 !default; 

$w-booking-car-rental__item-age-warning--sp-stack--in:                  $f-space--medium !default;
$w-booking-car-rental__item-age-warning--sp-inset:                      $f-space--small !default;

$w-booking-car-rental__selected-font-size--bp-small:                    $f-font-size--xs !default;
$w-booking-car-rental__selected-font-size--bp-medium:                   $f-font-size--s !default;
$w-booking-car-rental__selected-breakpoint--md:                         $f-breakpoint--sml !default;
$w-booking-car-rental__selected-info-section--sp-inset:                 $f-space--small !default;
$w-booking-car-rental__driver-info--color:                              $f-color__state-info !default;
$w-booking-car-rental__driver-info--margin-right:                       $f-space--small !default;

$w-booking-car-rental__item-price--margin-right:                        $f-space--small !default;
$w-booking-car-rental__item-price--margin-bottom:                       $f-space--tiny !default;
$w-booking-car-rental__item-price--width:                               calc(100% - #{$f-space--small}) !default;
$w-booking-car-rental__edit-details--sp-stack:                          $f-space--small !default;
$w-booking-car-rental__checkout-form--sp-stack:                         $f-space--small !default;

$w-booking-car-rental__edit-details__box--background-color:             $f-color__neutral-grey--ultra-light !default;

$w-booking-car-rental__equipment--sp-inset:                             $f-space--tiny !default;
$w-booking-car-rental__equipments--margin:                              -1 * $w-booking-car-rental__equipment--sp-inset !default;
$w-booking-car-rental__equipments--height:                              $f-space--medium + $f-space--small !default;
$w-booking-car-rental__equipments--background-color:                    $f-color__neutral-white !default;
$w-booking-car-rental__selected-button-actions--margin-top:             $f-space--tiny !default;
$w-booking-car-rental__selected-button--margin-left:                    $f-space--medium !default;
$w-booking-car-rental__selected-button--margin-bottom:                  $f-space--small !default;

$w-booking-car-rental__usp-icon--color:                                 $f-color__state-success--semi-light !default;
$w-booking-car-rental__usp-icon--font-size:                             map_get($m-icons__sizes, "medium") !default;
$w-booking-car-rental-edit__conditions-btn--color:                      $f-color__state-info !default;

$w-booking-car-rental_separator--color:                                 $f-color__neutral-grey--semi-light !default;
$w-booking-car-rental_separator--sp-top:                                $f-space--medium !default;

$w-booking-car-rental__add-extra-cars-title--sm-bottom:                 $f-space--small !default;
$w-booking-car-rental__edit-details--sm-top:                            $f-space--small !default;

$w-booking-car-rental--with-slider--bp-small:                           "sm" !default;
