/* -------------------------------------------------------------------------
 * BOOKED SERVICES LIST
 *
 */
.w-booked-services-list{
  position: relative;
  &.has-error{
    display: none;
  }
}
@include t-mq($w-booked-services-list__breakpoint) {
  .is-unresolved {
    .w-booked-services-list__side-drawer {
      @include a-unresolved();
      &::before, &::after{
        z-index: 1;
      }
    }
  }
}

.w-booked-services-list__trigger{
  position: sticky;
  top: 0;
  width: 100%;
  left: 0;
  @include sp-inset($w-booked-services-list__side-drawer-trigger--sp-v, $w-booked-services-list__side-drawer-trigger--sp-h);
  font-weight: $w-booked-services-list--font-weight;
  font-size: $w-booked-services-list--font-size;
  border: $w-booked-services-list__side-drawer-trigger--border-size $w-booked-services-list__side-drawer-trigger--border-type $w-booked-services-list__side-drawer-trigger--border-color;
  box-shadow: $w-booked-services-list__side-drawer-trigger--shadow rgba($w-booked-services-list__side-drawer-trigger--shadow-color, $w-booked-services-list__side-drawer-trigger--shadow-opacity);
  background-color: $w-booked-services-list--background-color;
  transition: $f-easing--in $f-duration;
  color: $w-booked-services-list__side-drawer-trigger--color-selected;
  min-height: $w-booked-services-list__side-drawer-trigger--min-height;
  align-items: center;
  justify-content: space-between;
  display: flex;

  @include t-mq($w-booked-services-list__breakpoint) {
    display: none;
  } 
  
  .c-btn__icon {
    color: $w-booked-services-list__trigger-button__icon--color;
    padding-left: $w-booked-services-list__trigger-button__icon--padding;
    height: $w-booked-services-list__trigger-button--height;
  }
  .c-btn__icon::before {
    height: $w-booked-services-list__trigger-button--height;
    color: $w-booked-services-list__trigger-button__icon--color;
    font-size: $w-booked-services-list__trigger-button__icon--font-size;
  }

  .w-booked-services-list__trigger__text{
    display: flex;
    align-items: center;
    color: $w-booked-services-list__trigger-button__icon--color;
    .c-actionable-list__item-extended__icon{
      color: $w-booked-services-list__trigger-button__icon--color;
    }
  }

}

.w-booked-services-list__trigger.in {
  .w-booked-services-list__trigger__button{
    margin-top: $w-booked-services-list__trigger-button--margin-top;
    .c-btn__icon::before{
      transform: rotate(180deg);
    }
  }
}


.w-booked-services-list__side-drawer{
  border-bottom-left-radius: $w-booked-services-list__side-drawer--border-radius;
  border-bottom-right-radius: $w-booked-services-list__side-drawer--border-radius;
}

.w-booked-services-list__side-drawer {
  &.c-side-drawer--origin-top {
    width: $w-booked-services-list__side-drawer--width;
    margin-bottom: 0;
    .c-btn__icon {
      display: none;
    }
  }
  @include t-mq($until: $w-booked-services-list__breakpoint) {
    .c-side-drawer__body-content{
      padding: 0;
      bottom: auto;
    }
  }
}

.w-booked-services-list__side-drawer__close-button{
  @include sp-inset($w-booked-services-list__side-drawer__close-button--sp-v, $w-booked-services-list__side-drawer__close-button--sp-h);
  float: right;

  @include t-mq($w-booked-services-list__breakpoint) {
    display: none;
  }
}

.w-booked-services-list__item-icon-right{
  display: inline-block;
  margin-left:auto;
}

.w-booked-services-list__content{
  border-bottom: $w-booked-services-list__content--border-size $w-booked-services-list__content--border-type $w-booked-services-list__content--border-color;

  .c-actionable-list__item{
    @include sp-inset($w-booked-services-list__item--sp-v, $w-booked-services-list__item-button--sp-h);
    &.is-hidden{
      display: none;
    }
    &.w-booked-services-list-service-not-booked{
      background: $w-booked-services-list-service-not-booked--bg-color;
    }
    &.w-booked-services-list-service-booked{
      background: $w-booked-services-list--background-color;
    }
    &.w-booked-services-list-service-booked, &.w-booked-services-list-service-not-booked{
      &.is-selected{
        background: $w-booked-services-list__item-selected--bg-color;
      }
    }
    &.w-booked-services-list-service-booked, &.w-booked-services-list-service-not-booked.is-selected{
      .w-booked-services-list__item-icon-right{
        color: $w-booked-services-list-service-booked__icon--color;
      }
    }    
  }  
}

.w-booked-services-list__no-service-selected{
  background-color: $w-booked-services-list__no-service-selected--bg-color;
  @include sp-inset($w-booked-services-list__no-service-selected--padding);
  position: absolute;
  top: 0px;
  left: 0px;
  width: $w-booked-services-list__no-service-selected--width;
  color: $w-booked-services-list__no-service-selected--color;
  border-radius: $w-booked-services-list__no-service-selected--border-radius;
  box-shadow: $w-booked-services-list__no-service-selected--shadow rgba($w-booked-services-list__no-service-selected--shadow-color, $w-booked-services-list__no-service-selected--shadow-opacity);
  @include t-mq($until: $w-booked-services-list__breakpoint) {
    display: none;
  }
  &.is-hidden{
    visibility: hidden;
  }
  &::before{
    content: "";
    height: $w-booked-services-list__no-service-selected--arrow-size;
    width: $w-booked-services-list__no-service-selected--arrow-size;
    position: absolute;
    background-color: $w-booked-services-list__no-service-selected--bg-color;
    top: $w-booked-services-list__no-service-selected--arrow-top;
    left: $w-booked-services-list__no-service-selected--arrow-left;
    transform: rotate(45deg);
    z-index: -1;
    box-shadow: $w-booked-services-list__no-service-selected--shadow rgba($w-booked-services-list__no-service-selected--shadow-color, $w-booked-services-list__no-service-selected--shadow-opacity);
  }
}

.w-booked-services-list__side-drawer--header{
  @include sp-stack(0px);
  &.is-hidden{
    display: none;
  }
}
.w-booked-services-list__backdrop.w-booked-services-list__backdrop{
  z-index: $w-booked-services-list__backdrop-z-index;
}