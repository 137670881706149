/* -------------------------------------------------------------------------
 * CHIP VARIABLES
 */

$c-chip--font-family:                                 $f-font-family__primary !default;
$c-chip--font-family--crop:                           $f-font-family__primary--crop !default;
$c-chip--bg-color:                                    $f-color__state-info--ultra-light !default;
$c-chip--bg-color--dark:                              $f-color__state-info--dark !default;
$c-chip--hover--bg-color:                             $f-color__state-info--light !default;
$c-chip--hover--bg-color--dark:                       $f-color__state-info--semi-dark !default;
$c-chip--spacing-inset:                               ($f-space--tiny * 1.5) !default;
$c-chip--radius:                                      $f-radius--rounded !default;
$c-chip--vertical-align:                              1px !default;
$c-chip--sp-inline:                                   $f-space--tiny !default;
$c-chip--line-height:                                 $f-line-height--tiny !default;

$c-chip--transition--property:                         background-color !default;
$c-chip--transition--duration:                         $f-duration--normal !default;

$c-chip__text--color:                                 $f-color__neutral-grey--dark !default;
$c-chip__text--color--dark:                           $f-color__neutral-grey--ultra-light !default;
$c-chip__text--color--highlighted:                    $f-color__state-info !default;
$c-chip__text--spacing-inline:                        $f-space--small !default;
$c-chip__text--font-size:                             $f-font-size--s !default;
$c-chip__text--font-weight:                           400 !default;
$c-chip__text--text-transform:                        none !default;

$c-chip__icon-color:                                  $f-color__state-info--semi-dark !default;
$c-chip__icon-color--dark:                            $f-color__state-info--ultra-light !default;
$c-chip__icon-font-size:                              map_get($m-icons__sizes, "small") !default;

// States
// -----------------------------------------

// Disabled
$c-chip--opacity--disabled:                           .5 !default;
