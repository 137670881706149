
/* -------------------------------------------------------------------------
 * STATE ERROR Color Scheme
 *
 */

// Color Scheme output

@if $cs-state-error--enabled {
  .cs-state-error {
    @include color-scheme($cs-state-error)
  }
}


// Color Scheme Modifiers
// -------------------------

// White modifier

@if $cs-state-error--enabled and $cs-state-error--white--enabled {
  .cs-state-error--white {
    @include color-scheme(s-core-map-diff($cs-state-error, $cs-state-error--white))
  }
}

// Light modifier

@if $cs-state-error--enabled and $cs-state-error--light--enabled {
  .cs-state-error--light {
    @include color-scheme(s-core-map-diff($cs-state-error, $cs-state-error--light))
  }
}
