// ACCORDION VARS
// ---------------------------------

$c-accordion__item-title--text-transform:                                 unset !default;
$c-accordion__item-title--font-weight:                                    700 !default;
$c-accordion__item-heading--color--hover:                                 $f-color__brand-primary !default;

$c-accordion__item-trigger--bg-color:                                     $f-color__brand-primary !default;
$c-accordion__item-trigger--bg-color--hover:                              $f-color__brand-primary--semi-dark !default;

$c-accordion__item-subtitle--font-weight:                                 400 !default;

$c-accordion__item--border-color:                                         $f-color__neutral-grey--light !default;

$c-accordion__item-trigger--color--small:                                 $f-color__brand-primary--dark !default;
$c-accordion__item-trigger--color--hover--small:                          $f-color__brand-primary !default;

$c-accordion__item-trigger--font-size:                                    $f-font-size--xs !default;
