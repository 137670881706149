/* -------------------------------------------------------------------------
 * BOOKED SERVICE EDIT
 *
 */
.w-booked-service-edit{
  position: relative;
}

.w-booked-service-edit__main-top {
  position: relative;
  @include sp-stack($w-booked-service-edit__main-top--sp-stack)
}

.w-booked-service-edit__main{
  position: relative;
}
@include t-mq($until: $w-booked-services-list__breakpoint) {
  .w-booked-service-edit__main-left{
    position: sticky;
    top: 0;
    z-index: $w-booked-service-edit__backdrop-z-index;
    width: 100%;
    left: 0;
  }
}
@include t-mq($w-booked-services-list__breakpoint) {
  .w-booked-service-edit__main{
    padding-right: $w-booked-service-edit__main--padding-right;
    .w-booked-service-edit__main-left, .w-booked-service-edit__main-content{
      float: left;
    }
    .w-booked-service-edit__main-left{
      padding-left: $w-booked-service-edit__main--padding-left;
    }
    .w-booked-service-edit__main-right{
      float: right;
      padding-left: $w-booked-service-edit__main--padding-left;
    }
  }

  .w-booked-service-edit .l-site__wrapper--medium.l-site__wrapper--medium{
    @include sp-inset-h($w-booked-service-edit--reset-padding);
  }

  .w-booked-service-edit__main-content{
    .w-booking-item:not(.has-error){
      padding: $w-booked-service-edit__service--padding;
      &.service-is-selected{
        background-color: $w-booked-service-edit__service-selected--bg--color;
      }
    }
  }
  .w-booked-service-edit__main{
    @supports (display: grid) {
      display: grid;
      grid-template-columns: minmax($w-booked-service-edit__sidebars--width, 1fr) minmax($w-booked-service-edit__main-content--width, 2fr) minmax($w-booked-service-edit__sidebars--width, 1fr);
      grid-template-rows: minmax(0, auto);
      grid-template-areas:
        "messages messages right"
        "left center right";
      .w-booked-service-edit__warning-messages{
        grid-area: messages;
        padding-left: $w-booked-service-edit__main--padding-left;
        &.has-error{
          display: none;
        }
      }
      .w-booked-service-edit__main-left{
        grid-area: left;
      }
      .w-booked-service-edit__main-content{
        grid-area: center;
      }
      .w-booked-service-edit__main-right{
        grid-area: right;
      }
    }  
  }
}
.w-booked-service-edit__warning-messages{
  &.has-error{
    display: none;
  }
  &.is-unresolved {
    @include a-unresolved();
    &::before, &::after{
      z-index: 1;
    }
  }
}

@include t-mq($until: $w-booked-services-list__breakpoint) {
  .w-booked-service-edit__warning-messages{
    @include sp-inset-h($w-booked-service-edit__warning-messages--padding-h);
    @include sp-stack($w-booked-service-edit__warning-messages--margin-bottom);
  }
  .w-booked-service-edit__main-right{
    @include sp-inset-h($w-booked-service-edit__main-right--padding-h);
    @include sp-stack($w-booked-service-edit__main-right--margin-bottom);  
  }
  .w-booked-service-edit__main-content{
    .w-booking-item{
      .w-booking-box__heading{
        display: none;
      }
    }
  }
}

.w-booked-service-edit__buttons-action, .w-booked-service-edit__go-to-payment-element, .w-booked-service-edit__continue-editting-button, .w-booked-service-edit__cancel-button{
  @include t-mq($until: $w-booked-services-list__breakpoint) {
    @include sp-inset-h($w-booked-service-edit__buttons-action--padding-h);
  }
  &.is-hidden{
    display: none;
  }
}

@include t-mq($w-booked-services-list__breakpoint) {
  .w-booked-service-edit__go-to-payment-element{
    display: none;
  }
}

.w-booked-service-edit__payment-options-button{
  @include sp-stack($w-booked-service-edit__pay-button--margin-bottom)   
}
.w-booked-service-edit__cancel-button.w-booked-service-edit__cancel-button{
  @include sp-stack($w-booked-service-edit__cancel-button--margin-bottom)   
}


.w-booked-service-edit__modal {
  text-align: center;

  .w-booked-service-edit__modal-icon, .w-booked-service-edit__modal-title{
    margin-bottom: $f-space--small;
  }

  .w-booked-service-edit__modal-icon--cancel {
    color: $w-booked-service-edit__modal-icon-cancel--color;
  }

  .w-booked-service-edit__modal-icon--error{
    color: $w-booked-service-edit__modal-icon-error--color;
  }
}

.w-booked-service-edit__modal-footer{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: fit-content;
}

.w-booked-service-edit ~ .c-backdrop:not([class*="u-hidden--from@md"]):last-child {
  z-index: $w-booked-service-edit__layer-3
}

.is-hidden[data-w-booked-service-edit__hide-if-has-error] {
  display: none;
}

.w-booked-service-edit__cancel--button.w-booked-service-edit__cancel--button{
  @include sp-stack($f-space--none)
}

.w-booked-service-edit__booking-item-modal-button{
  cursor: pointer;
  display: none;
  @include t-mq($until: $w-booked-services-list__breakpoint) {
    display: inline-block;
  }
}