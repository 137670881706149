/* -------------------------------------------------------------------------
* DOWNLOAD DOCUMENTS VARS
*
*/

$w-download-documents__container--first-bp:                             'sm' !default;
$w-download-documents__container--second-bp:                            'md' !default;

$w-download-documents__container--gap:                                  $f-space--tiny !default;

$w-download-documents__download-button--width-first-bp:                 calc((100% / 2) - (#{$w-download-documents__container--gap} * 2)) !default;
$w-download-documents__download-button--width-second-bp:                calc((100% / 3) - (#{$w-download-documents__container--gap} * 2)) !default;
