/* -------------------------------------------------------------------------
 * BOX
 */

@mixin c-box-arrow-background($_bg-color) {
  &.c-box--arrow::after {
    border-bottom-color: $_bg-color;
  }
  &.c-box--arrow-bottom::after {
    border-bottom-color: transparent;
    border-top-color: $_bg-color;
  }
  &.c-box--arrow-left::after {
    border-bottom-color: transparent;
    border-right-color: $_bg-color;
  }
}

.c-box {
  @include c-box-arrow-background($c-box--bg-color--cs-default);
}

.cs-default.c-box {
  background-color: $c-box--bg-color--cs-default;
  border-color: $c-box--border-color--cs-default;
  @include c-box-arrow-background($c-box--bg-color--cs-default);
}

.cs-default--light.c-box {
  background-color: $c-box--bg-color--cs-default--light;
  border-color: $c-box--border-color--cs-default--light;
  @include c-box-arrow-background($c-box--bg-color--cs-default--light);
}

.cs-default--dark.c-box {
  background-color: $c-box--bg-color--cs-default--dark;
  border-color: $c-box--border-color--cs-default--dark;
  @include c-box-arrow-background($c-box--border-color--cs-default--dark);

}

.cs-state-info--light.c-box {
  background-color: $c-box--bg-color--cs-state-info--light;
  border-color: $c-box--border-color--cs-state-info--light;
  @include c-box-arrow-background($c-box--bg-color--cs-state-info--light);
}

.cs-state-warning--light.c-box {
  background-color: $c-box--bg-color--cs-state-warning--light;
  border-color: $c-box--border-color--cs-state-warning--light;
  @include c-box-arrow-background($c-box--bg-color--cs-state-warning--light);
}

.cs-state-error--light.c-box {
  background-color: $c-box--bg-color--cs-state-error--light;
  border-color: $c-box--border-color--cs-state-error--light;
  @include c-box-arrow-background($c-box--bg-color--cs-state-error--light);
}

.cs-state-success--light.c-box {
  background-color: $c-box--bg-color--cs-state-success--light;
  border-color: $c-box--border-color--cs-state-success--light;
  @include c-box-arrow-background($c-box--bg-color--cs-state-success--light);
}
