/* -------------------------------------------------------------------------
 * SWITCH COMPONENT
 *
 *
 *
 */

$c-switch--width:                                       ($f-space--medium * 2) - $f-space--tiny !default;
$c-switch--height:                                      $f-space--medium !default;
$c-switch--inner-padding:                               2px !default;
$c-switch--circle-width:                                $c-switch--height - ($c-switch--inner-padding * 2) !default;
$c-switch--radius:                                      $c-switch--circle-width !default;
$c-switch--translate:                                   ($c-switch--width/2) - ($c-switch--inner-padding * 2) !default;

$c-switch--transition:                                  $f-easing--in;
$c-switch--transition-duration:                         $f-duration--normal;

$c-switch--color:                                       $f-color__state-info !default;
$c-switch--background-color:                            $f-color__neutral-grey--semi-light !default;
$c-switch--disabled-opacity:                            $e-form-elements--disabled--opacity !default;
$c-switch--circle-background-color:                     $f-color__neutral-white !default;

$c-switch__slider--sp-inline:                           $f-space--small !default;
$c-switch__circle--shadow:                              map_get($f-shadows, "elevated") !default;

// Variant boxed
$c-switch--boxed--background-color:                     $f-color__neutral-grey--light !default;
$c-switch--boxed__slider--sp-inset:                     $f-space--small !default;

// Variant with modal

$c-switch__icon--color:                                 $f-color__state-info !default;
$c-switch__icon--color-hover:                           $f-color__state-info--dark !default;
$c-switch__icon--height:                                20px!default;
$c-switch__icon--padding-left:                          16px !default;
$c-switch__icon--font-size:                             $f-font-size--m !default;
