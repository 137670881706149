/* -------------------------------------------------------------------------
 * BOOKING GATE VARS
 *
 */
$w-booking-gate--spacing-x:                                             $f-space * .5 !default;
$w-booking-gate--spacing-y:                                             $f-space  !default;

$w-booking-gate--shadow:                                                $f-shadow--elevated !default;
$w-booking-gate--shadow-color:                                          $f-shadow__color !default;
$w-booking-gate--shadow-opacity:                                        $f-shadow__opacity !default;

$w-booking-gate__item-wrapper--box-shadow-length:                       320px !default;
$w-booking-gate__item-wrapper--box-shadow-color:                        $f-color__neutral-grey--ultra-light !default;
$w-booking-gate__item-wrapper--background-color:                        $f-color__neutral-grey--ultra-light !default;

$w-booking-gate__item-wrapper-main--shadow:                             $f-shadow--elevated !default;
$w-booking-gate__item-wrapper-main--shadow-color:                       $f-color__neutral-black !default;
$w-booking-gate__item-wrapper-main--shadow-opacity:                     $f-shadow__opacity--elevated !default;

$w-booking-gate__price-table--min-height:                               320px !default;

$w-booking-gate__price-table-dd-grid--max-width:                        13 * 100px !default; // N of cols multiplied by the width of a cell

$w-booking-gate__alternatives-item--margin-bottom:                      $f-space--small !default;

$w-booking-gate__alternatives-chips--margin-bottom:                     $f-space--tiny !default;

$w-booking-gate__filter--width:                                         250px !default;