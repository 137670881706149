/* -------------------------------------------------------------------------
 * booking terms conditions VARS
 *
 */

$w-booking-terms-conditions-container__margin-bottom:                                 $f-space--medium !default;

$w-booking-terms-conditions__documents-container__margin-bottom:                      $f-space--small !default;
$w-booking-terms-conditions__documents-container__padding:                            $f-space--small !default;
$w-booking-terms-conditions__documents-container__color:                              $f-color__neutral-grey--light !default;

$w-booking-terms-conditions__collapse--last-child:                                    $f-space--small !default;
$w-booking-terms-conditions__collapse--first-child:                                   $f-space--small + $f-space--tiny !default;
$w-booking-terms-conditions__nav-link-color--hover:                                   $f-color__neutral-black !default;

$c-collapse__trigger-icon--color:                                                     $f-color__neutral-black !default;
