/* -------------------------------------------------------------------------
 * FLAG
 *
 */


// Object as a mixin
// --------------------------------------------------

@mixin o-flag() {
  display: table;
  width: 100%;
}

@mixin o-flag__img($_vertical-alignment: $o-flag__alignment) {
  display: table-cell;
  vertical-align: $_vertical-alignment;
  width: 1px;

  > img {
    width: auto;
  }
}

@mixin o-flag__body($_vertical-alignment: $o-flag__alignment) {
  display: table-cell;
  vertical-align: $_vertical-alignment;
  width: auto;
}


// Alignment modifiers
// --------------------------------------------------

@mixin o-flag__mod-alignment($_vertical-alignment: middle) {
  .o-flag--#{$_vertical-alignment} {
    > .o-flag__img,
    > .o-flag__body {
      vertical-align: $_vertical-alignment;
    }
  }
}

// Reverse modifier
// --------------------------------------------------

@mixin o-flag--reverse() {
  direction: rtl;
}

@mixin o-flag__body--reverse() {
  direction: ltr;
}

@mixin o-flag__img--reverse() {
  direction: ltr;
}

@mixin o-flag__mod-reverse() {
  .o-flag--reverse {
    @include o-flag--reverse();

    > .o-flag__body {
      @include o-flag__body--reverse;
    }

    > .o-flag__img {
      @include o-flag__img--reverse;
    }
  }
}

// Unset as mixin
// --------------------------------------------------

@mixin o-flag--unset() {
  display: inherit;
  width: inherit;
}

@mixin o-flag__img--unset() {
  display: inherit;
  vertical-align: inherit;
  width: auto;

  > img {
    max-width: inherit;
  }
}

@mixin o-flag__body--unset() {
  display: inherit;
  vertical-align: inherit;
  width: inherit;
}
