/* -------------------------------------------------------------------------
* QuickSearch Variables
*
*/

$w-cookie-emergency-dialog__cookie-settings--margin-top:    $f-space--small !default;
$w-cookie-emergency-dialog__content-footer--margin-left:    $f-space--large !default;
$w-cookie-emergency-dialog--max-height:                     100vh !default;
$w-cookie-emergency-dialog__post-form--font-size:           $f-font-size--s !default;
$w-cookie-emergency-dialog--z-index:                        $f-z-index--4 !default;

$w-cookie-emergency-dialog__close--position-x:              $f-space--small + $f-space--tiny !default;
$w-cookie-emergency-dialog__close--position-y:              $f-space--small !default;
