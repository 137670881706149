/* -------------------------------------------------------------------------
 * BOOKING LUGGAGE -LIST VARS
 *
 */

 $w-booking-luggage__default--sp-stack:                 $f-space--medium !default;
 $w-booking-luggage__default--sp-inline:                $f-space--medium !default;
 $w-booking-luggage__participant-name--sp-stack:        $f-space--small !default;
 $w-booking-luggage__title--sp-stack:                   $f-space--medium !default;
 $w-booking-luggage__options--sp-stack:                 $f-space--medium !default;

 $w-booking-luggage__message-padding:                   $f-space--small + $f-space--tiny !default;
 $w-booking-luggage__message--sp-stack:                 $f-space--medium + $f-space--small !default;
 $w-booking-luggage__message-items--sp-stack:           $f-space--small !default;
 $w-booking-luggage__message-line--color:               $f-color__neutral-grey--semi-light !default;
 $w-booking-luggage__message--font-size:                $f-font-size--s !default;

 $w-booking-luggage__options-price--padding:            $f-space--small !default;
 $w-booking-luggage__options-price--text-color:         $f-color__state-info !default;
 $w-booking-luggage__options-price--margin-top:         -1 * $f-space--small !default;

 $w-booking-luggage__column-width:                      15% !default;
 $w-booking-luggage__breakpoint--sm:                    $f-breakpoint--sm !default;
 $w-booking-luggage__breakpoint--md:                    $f-breakpoint--md !default;
 $w-booking-luggage__font-weight:                       700 !default;

 // oneClickLuggage, custom vars override for ABTest and newFoundations
$w-booking-luggage__one-click-options--gap:                   4px !default;
$w-booking-luggage__one-click-option--padding:                24px 24px 32px 24px !default;
$w-booking-luggage__one-click-option--gap:                    24px !default;
$w-booking-luggage__one-click-option--border-radius:          8px !default;
$w-booking-luggage__one-click-options--pb:                    8px !default;          
$w-booking-luggage__one-click-option--bg:                     $f-color__neutral-white !default;
$w-booking-luggage__one-click-options--mt:                    16px !default;
$w-booking-luggage__one-click-options--height:                calc(100% - 22px) !default;
$w-booking-luggage__one-click-options--shadow:                0px 2px 6px 0px !default;
$w-booking-luggage__one-click-options--shadow-color:          $f-color__neutral-black !default;
$w-booking-luggage__one-click-options--shadow-opacity:        0.08 !default;
$w-booking-luggage__one-click-title--font-weight:             600 !default;
$w-booking-luggage__one-click--accent-color:                #494141 !default;
$w-booking-luggage__one-click-price-ml:                       16px !default;
$w-booking-luggage__one-click-option--remove-button-color:  #21B788 !default;
$w-booking-luggage__one-click--remove-button-color:         #362F2F !default;
$w-booking-luggage__one-click-option--border:                 1px !default;
$w-booking-luggage__one-click-option--border-color:         #EDEBE7 !default;
$w-booking-luggage__one-click-option--selected-border-color:#63533D !default;
$w-booking-luggage__one-click-option--selected-border:        2px !default;
$w-booking-luggage__one-click-option-label-left:              20px !default;
$w-booking-luggage__one-click-option-label-top:               -15px !default;
$w-booking-luggage__special-luggage-extra-text-switch-ml:     $c-switch--width + $c-switch__slider--sp-inline !default;
$w-booking-luggage__special-luggage-extra-text-switch-mt:     8px !default;
$w-booking-luggage__one-click-options-slider--margin:         -18px !default;
$w-booking-luggage__one-click-options-slider--padding:        24px !default;