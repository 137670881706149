// Flow button has a different font family
.c-btn--flow,
a.c-btn--flow {
  &[disabled] {
    opacity: $c-btn-flow--disabled--opacity;
  }

  &:active {
    color: $c-btn-flow--pressed--color;
  }
}

// Color schemes overrides
// -----------------------------------------

.cs-brand-secondary--dark .c-btn__icon {
  color: $c-btn-icon-only--color--cs-secondary;
}

.c-btn--delete:hover{
  color: $c-btn-delete--hover--color;
}

// Trust variant

.c-btn--trust,
a.c-btn--trust {
  box-shadow: unset;

  &:active,
  &:focus {
    box-shadow: $c-btn-trust--shadow;
  }
}
