/* -------------------------------------------------------------------------
 * UN RESOLVED
 *
 * Un resolved Animation
 *
 */

// Animation selector output
// --------------------------------------------------

@if ($a-unresolved--enabled) {
  .a-unresolved.is-unresolved,
  .is-unresolved .a-unresolved{
      @include a-unresolved();
  }
}
