/* -------------------------------------------------------------------------
 * BOOKING FILTERABLE SERVICES
 *
 */

.w-booking-filterable-services__room-name {
  @include sp-stack($w-booking-filterable-services__room-name--padding);
}

.w-booking-filterable-services__participant{
  @include sp-stack($w-booking-filterable-services__participant--padding);
}
.w-booking-filterable-services__room:last-child {
  .w-booking-filterable-services__participant:last-child{
    @include sp-stack($w-booking-filterable-services__participant--reset-padding);
  }
}
.w-booking-filterable-services__participant-name {
  @include sp-stack($w-booking-filterable-services__participant-name--padding);
}

@include t-mq($from: $w-booking-filterable-services--bp) {
  .w-booking-filterable-services-collapse__service__content{
    &.in,
    &.is-opening,
    &.is-closing {
      @include sp-stack($w-booking-filterable-services-collapse__service__content--padding);
      .w-booking-filterable-services__body {
        margin-top: $w-booking-filterable-services__body--margin-top;
      }
    }
  }
}

.w-booking-filterable-services--collapse__header .w-booking-filterable-services__service-title, 
.w-booking-filterable-services--side__drawer__header .w-booking-filterable-services__service-title{
  font-weight: $w-booking-filterable-services__service-title--font-weight;
}

.w-booking-filterable-services__service-title--side-drawer{
  margin-bottom: $w-booking-filterable-services__service-title--side-drawer--margin;
  .c-side-drawer__close{
    color: $w-booking-filterable-services__service-title--side-drawer--close--color;
  }
}
.w-booking-filterable-services-side-drawer__service__content{
  background: $w-booking-filterable-services__body--background-color;
}

.w-booking-filterable-services__participant__service-title--side-drawer{
  padding: $w-booking-filterable-services__participant__service-title--side-drawer--space 0;
  border-top: 1px solid $w-booking-filterable-services__participant__service-title--border-color;
}

@include t-mq($from: $w-booking-filterable-services--bp) {
  .w-booking-filterable-services__participant__service-title--side-drawer{
    display: none;
  }
}
.w-booking-filterable-services__header {
  padding: $w-booking-filterable-services__header--padding;
  background-color: $w-booking-filterable-services__header--background-color;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  font-size: $w-booking-filterable-services__header--font-size;
  margin-top: $w-booking-filterable-services__header--margin-top;
  .w-booking-filterable-services__header-right {
    display: flex;
    align-items: center;
  }
  
  .w-booking-filterable-services__header-info {
    font-size: $w-booking-filterable-services__header-info--font-size;
    margin-right: $w-booking-filterable-services__header-info--margin;
    white-space: nowrap;
  }
 
  .w-booking-filterable-services__header-trigger{
    margin-right: $w-booking-filterable-services__header-trigger--margin-right;
    .c-collapse__trigger-icon::before {
      color: $w-booking-filterable-services__header--color;        
      font-size: $w-booking-filterable-services__header-trigger--font-size;
    } 
  }

  @include t-mq($until: $w-booking-filterable-services--bp) {
    .w-booking-filterable-services__header-trigger {
      display: none;
    }
  }

  &:hover{
    cursor: pointer;
  }
  
  &:not(.active) {
    .w-booking-filterable-services__header-right,
    .w-booking-filterable-services__header-trigger .c-collapse__trigger-icon::before {
      color: $w-booking-filterable-services__header-inactive--color
    }
    .c-switch {
      pointer-events: none;
    }
  }
  
  &.active {
    background-color: $w-booking-filterable-services__header-active--background-color;
    .w-booking-filterable-services__header-right,
    .w-booking-filterable-services__header-trigger .c-collapse__trigger-icon::before {
      color: $w-booking-filterable-services__header-active--color;
      font-weight: $w-booking-filterable-services__header-active--font-weight;
    }

    .c-switch.is-disabled{
      opacity: 1;
      .c-switch__slider {
        opacity: .5;
      }
    }
    
    
    
    
  }
}

@include t-mq($from: $w-booking-filterable-services--bp) {
  .w-booking-filterable-services__body {
    position: relative;
    padding: $w-booking-filterable-services__body--padding; 
    border: 1px solid $w-booking-filterable-services__body--border-color;
    background: $w-booking-filterable-services__body--background-color;
    &:after {
      content: "";
      height: 20px;
      width: 20px;
      position: absolute;
      background-color: $w-booking-filterable-services__body--background-color;
      top: -12px;
      left: 50px;
      border-top: $w-booking-filterable-services__body--border-color solid 1px;
      border-left: $w-booking-filterable-services__body--border-color solid 1px;
      transform: rotate(45deg);
    }
  }
}

.w-booking-filterable-services__body__filter {
   display: flex;
   flex-direction: column;
   position: relative;
}

.w-booking-filterable-services__filter {
  @include sp-stack($w-booking-filterable-services__filter--padding);
  &.w-booking-filterable-services__filter-info {
    text-align: right;
    min-width: 100px;
  }
  @include t-mq($from: $w-booking-filterable-services--bp) {
    &:first-child {
      max-width: 50%;
    }
    &.w-booking-filterable-services__filter-info {
      padding-top: $w-booking-filterable-services__filter-info--padding;
      max-width: calc(50% - #{$w-booking-filterable-services__body--padding});
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .c-number-stepper__label, .c-choice-list__label, .c-dropdown__label{
    font-size: $w-booking-filterable-services__labels--font-size;
    font-weight: $w-booking-filterable-services__labels--font-weight;
  }
  .c-dropdown__label{
    display: inline-block;
  }
}

.w-booking-filterable-services__tabs__content{
  .c-tabs__content{
    @include sp-inset($w-booking-filterable-services__body--padding, 0)
  }
}

@include t-mq($until: $w-booking-filterable-services--bp) {
  .w-booking-filterable-services__tabs__nav{
    .c-tabs__nav-item .c-tabs__nav-button{
      min-height: $w-booking-filterable-services__tabs__nav-button--height;
    }
  }
}

.w-booking-filterable-services__filter-info__price {
  font-family: $w-booking-filterable-services__filter-info__price--font-family;
  font-size: $w-booking-filterable-services__filter-info__price--font-size;
  font-weight: $w-booking-filterable-services__filter-info__price--font-weight;
  color: $w-booking-filterable-services__filter-info__price--color;
  margin-bottom: $w-booking-filterable-services__filter-info__price--margin;
}

.w-booking-filterable-services__filter-info__price-description {
  font-family: $w-booking-filterable-services__filter-info__price-description--font-family;
  font-size: $w-booking-filterable-services__filter-info__price-description--font-size;
  font-weight: $w-booking-filterable-services__filter-info__price-description--font-weight;
  padding-left: $w-booking-filterable-services__filter-info__price-description--padding-left;
  span {
    font-weight: $w-booking-filterable-services__filter-info__price-description__span--font-weight;
  }
}

.w-booking-filterable-services__filter__content{
  &.is-hidden{
    display: none;
  }
  @include t-mq($from: $w-booking-filterable-services--bp) {
    &.w-booking-filterable-services__participants-as-options:first-child  {
      margin-top: $w-booking-filterable-services__participants-as-options--margin-top;
    }
  }
}


.w-booking-filterable-services__service-participants-choice-list{
  .c-checkbox__text-wrapper{
    flex-direction: row;
    justify-content: space-between;
    .c-checkbox__additional-text.is-hidden{
      display: none;
    }
  }
}

.w-booking-filterable-services__body__buttons {
  display: flex;
  flex-direction: column-reverse;
  @include sp-stack($w-booking-filterable-services__body__buttons--sp-stack);

  .w-booking-filterable-services__cancel-btn {
    font-size: $w-booking-filterable-services__cancel-btn--font-size;
  }

  @include t-mq($from: $w-booking-filterable-services--bp) {
    flex-direction: row;
    justify-content: flex-end;
    @include sp-stack(0px);
    .w-booking-filterable-services__cancel-btn {
      @include sp-inline($w-booking-filterable-services__cancel-btn--margin);
    }
  }
}

.w-booking-filterable-services-label{
  @include sp-stack($w-booking-filterable-services__btn-group-label--margin-bottom);
  font-family: $w-booking-filterable-services__btn-group-label--font-family;
}

.w-booking-filterable-services__empty-message, .w-booking-filterable-services__validation-messages, .w-booking-filterable-services__time-messages {
  @include sp-stack($w-booking-filterable-services__empty-message--sp-stack);
  .w-booking-message__box {
    padding: $w-booking-filterable-services__empty-message--padding-v $w-booking-filterable-services__empty-message--padding-h;
  }
}
.w-booking-filterable-services__validation-messages .w-booking-message__box{
  margin-top: $w-booking-filterable-services__empty-message--sp-stack
}

.w-booking-filterable-services__accept-btn.is-empty{
  opacity: $w-booking-filterable-services__accept-btn--is-empty;
  pointer-events: none;
}

.w-booking-filterable-services__filter-info,
.w-booking-filterable-services__service-options-choice-list,
.w-booking-filterable-services__empty-message {
  &.is-hidden {
    display: none;
  }
}

//////////////////////////////////
/////// Overwritten colors ///////
//////////////////////////////////
.c-button-group:not(.c-button-group--chips){
  .w-booking-filterable-services-btn{
    box-shadow: $w-booking-filterable-services__btn--shadow;
    &.is-active, &:active{
      border-color: $w-booking-filterable-services__btn--active--border-color;
      color: $w-booking-filterable-services__btn--active--color;
      background-color: $w-booking-filterable-services__btn--active--bg-color;
      box-shadow: $w-booking-filterable-services__btn--active--shadow;
    }
    &:not(:last-child) {
      &:active + .c-btn,
      &.is-active + .c-btn {
        border-left-color: $w-booking-filterable-services__btn--active--border-color;
      }
    }
  }
}

.w-booking-filterable-services__tabs__nav{ 
  @include sp-stack($w-booking-filterable-services__tabs__nav--sp-stack);
  
  .c-tabs__nav-list-wrapper {
    width: 100%;
  }

  .c-tabs__nav-item {
    &.is-open {
      .c-tabs__nav-button{
        color: $w-booking-filterable-services__tabs__nav-button--color--open;
      }
      &:after {
        background-color: $w-booking-filterable-services__tabs__nav-button--color--open;
      }
    }
  }
}


.c-button-group.c-button-group--chips{
  flex-wrap: wrap;
  gap: $w-booking-filterable-services-button-group--chips--gap-mobile;
  
  @include t-mq($from: $w-booking-filterable-services--bp) {
    gap: $w-booking-filterable-services-button-group--chips--gap-v $w-booking-filterable-services-button-group--chips--gap-h;
  }
  .w-booking-filterable-services-btn-chip{
    padding: $w-booking-filterable-services-btn-chip--padding;
    flex-grow: 0;
    text-transform: lowercase;
    font-weight: $w-booking-filterable-services-btn-chip--font-weight;
    font-size: $w-booking-filterable-services-btn-chip--font-size;
    font-family: $w-booking-filterable-services-btn-chip--font-family;
    border-color: $w-booking-filterable-services__btn-chip--border-color;
    &.is-active, &:active{
      border-color: $w-booking-filterable-services__btn--active--border-color;
      color: $w-booking-filterable-services__btn--active--color;
      background-color: $w-booking-filterable-services__btn--active--bg-color;
    }
    
    .c-btn__text{
      white-space: nowrap;
    }
  }
}

//////////////////////////////////
/////// Specific for Golf ////////
//////////////////////////////////
.w-booking-filterable-services-golf{
  .w-booking-filterable-services__body{
    .w-booking-filterable-services__body__filter{
      display: grid;
      grid-template-columns: $w-booking-filterable-services-golf__body__filter--columns-size;
      grid-template-rows: repeat(10, $w-booking-filterable-services-golf__body__filter--rows-size);
      align-items: flex-end;
      grid-template-areas: 
        "location" 
        "holes"
        "date"
        "time"
        "buggy"
        "participants"
        "options"
        "messages"
        "time-messages"
        "total";
      @include t-mq($from: $w-booking-filterable-services--bp) {
        grid-template-columns: repeat(3, $w-booking-filterable-services-golf__body__filter--columns-size);
        grid-template-rows: repeat(7, $w-booking-filterable-services-golf__body__filter--rows-size);
        grid-template-areas: 
          "location location holes"
          "date time buggy"
          "participants participants participants"
          "options options options"
          "messages messages messages"
          "time-messages time-messages time-messages"
          "total total total";
          gap: 0px $w-booking-filterable-services-golf__body__filter--grid-gap;
      }
    }
    .w-booking-filterable-services__filter{
      &:first-child{
        max-width: 100%;
      }
      &.w-booking-filterable-services__filter-info{
        position: relative;
        grid-area: total;
        justify-self: end;
        max-width: 100%;
      }
    }

    .w-booking-filterable-services__filter-location{
      grid-area: location;
    }
    .w-booking-filterable-services__filter-holes{
      grid-area: holes;
    }
    .w-booking-filterable-services__filter-date{
      grid-area: date;
    }
    .w-booking-filterable-services__filter-time{
      grid-area: time;
    }
    .w-booking-filterable-services__filter-buggy{
      grid-area: buggy;
    }
    .w-booking-filterable-services__participants-as-options{
      grid-area: participants;
    }
    .w-booking-filterable-services__options{
      grid-area: options;
    }
    .w-booking-filterable-services__validation-messages{
      grid-area: messages;
    }
    .w-booking-filterable-services__time-messages{
      grid-area: time-messages;
    }
  }
}