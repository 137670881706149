/* -------------------------------------------------------------------------
 * STEP INDICATOR VARS
 */

$c-steps---padding-bottom:                   $f-space--medium !default;

$c-steps__link--bg-color:                    $f-color__neutral-grey--light !default;
$c-steps__link--color:                       $f-color__neutral-grey--semi-dark !default;
$c-steps__link--font-size:                   $f-font-size--xs !default;
$c-steps__link--font-size--from-bp:          $f-font-size--m !default;
$c-steps__link--spacing:                     $f-space--small !default;
$c-steps__link--radius:                      $f-radius--small !default;
$c-steps__link--font-weight:                 700 !default;
$c-steps__link--bg-hover:                    $f-color__neutral-grey--semi-light !default;
$c-steps__link--text-align:                  center !default;

$c-steps__link--from-bp:                     "sml" !default;
$c-steps__text--from-bp:                     "sml" !default;

// Active
$c-steps__item---bg-color--active:           $f-color__state-info--dark !default;
$c-steps__item--box-shadow--active:          1px 1px 5px 0 rgba($c-steps__item---bg-color--active, 0.14) !default;
$c-steps__item---color--active:              $f-color__neutral-white !default;
$c-steps__item---spacing--active:            $f-space--tiny + $f-space--small !default;
$c-steps__item---z-index--active:            $f-z-index--2 !default;
$c-steps__item---transform--active:          rotate(45deg) translate(-50%) !default;
$c-steps__item---transform-origin--active:   0 50% !default;
$c-steps__item---size--active:               $f-font-size--l !default;
$c-steps__item---bottom--active:             ($f-font-size--m * .5) * -1 !default;


// Locked
$c-steps__item---bg--locked:                 $f-color__neutral-grey--semi-dark !default;
$c-steps__item---opacity--locked:            .6 !default;


// Variants
// Progress
$c-steps__progress--bp:                                "sml" !default;

$c-steps--progress-sp-v:                               $f-space--tiny !default;
$c-steps--progress__link-color:                        $f-color__neutral-grey--dark !default;
$c-steps--progress__link-color--active:                $f-color__neutral-black !default;
$c-steps--progress__link-weight--active:               700 !default;
$c-steps--progress__link-weight:                       400 !default;
$c-steps--progress__link-min-height:                   60px !default;
$c-steps--progress__link-height:                       auto !default;

$c-steps--progress__link-font-size:                    $f-font-size--xs !default;
$c-steps--progress__link-font-size--from-bp:           $f-font-size--s !default;

$c-steps--progress__bullet-size:                       $f-space--medium !default;
$c-steps--progress__bullet-bg-color--active:           $f-color__brand-primary !default;
$c-steps--progress__bullet-shadow--active:             $f-color__brand-primary--light !default;
$c-steps--progress__bullet-shadow-opacity:             0.6 !default;
$c-steps--progress__bullet-bg-color--disabled:         $f-color__neutral-grey !default;
$c-steps--progress__bullet-weight:                     700 !default;
$c-steps--progress__bullet-color:                      $f-color__neutral-white !default;
$c-steps--progress__bullet-margin-bottom:              $f-space--small !default;
$c-steps--progress__bullet--z-index:                   $f-z-index--1 !default;

$c-steps--progress__bullet-line-border-width:          2px !default;
$c-steps--progress__bullet-line-width:                 calc(100% - #{$c-steps--progress__bullet-size}) !default;
$c-steps--progress__bullet-line-left:                  calc(-50% + (#{$c-steps--progress__bullet-size}/2) ) !default;
$c-steps--progress__bullet-line-top:                   $c-steps--progress__bullet-size/2 !default;
$c-steps--progress__bullet-before--reset-padding:      $f-space--none !default;

// Vertical
$c-steps--vertical__item--sp-inset-v:                  $f-space--small !default;
$c-steps--vertical__item--sp-inset-h:                  $f-space--none !default;
$c-steps--vertical__bullet--sp-stack:                  $f-space--none !default;
$c-steps--vertical__bullet--size:                      calc(#{$c-steps--progress__bullet-size}/2) !default;
$c-steps--vertical__bullet--margin-right:              $c-steps--vertical__bullet--size !default;
$c-steps--vertical__border--size:                      $f-space--medium + $f-space--tiny !default;
$c-steps--vertical__border--position-top:              calc(-1 * (#{$c-steps--vertical__border--size}/2)) !default;
