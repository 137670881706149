/* -------------------------------------------------------------------------
* Dropdown Multiple
*
*/

.c-dropdown-multiple {
  position: relative;
  color: $c-dropdown-multiple--color;

  .c-dropdown__element {
    padding-top: $c-dropdown-multiple--padding-top;
    padding-bottom: $c-dropdown-multiple--padding-bottom;
  }

  .c-dropdown__placeholder {
    position: absolute;
    top: $c-dropdown-multiple__placeholder--top;
    left: 0;
    transform: translateY(-50%);
    white-space: nowrap;
    color: $c-dropdown-multiple__placeholder--color;
  }

}



// Floating box
// -----------------------------------------

.c-dropdown-multiple {
  .c-floating-box {
  }
  .c-floating-box__footer {
    padding: $f-space--small 0 0 0;
    @include t-clearfix;
  }
  [data-c-dropdown__action="cancel"] {
    float: left;
  }
  [data-c-dropdown__action="submit"] {
    float: right;
  }
}

// States
// -------------------------------------------------

// Disabled
.c-dropdown-multiple.is-disabled,
.is-disabled .c-dropdown-multiple {
  .c-dropdown__element {
    pointer-events: none;
  }
}

.c-dropdown-multiple.is-disabled {
  .c-dropdown__icon,
  .c-dropdown__element {
    background-color: $c-dropdown__disabled--bg-color;
  }

  .c-dropdown__arrow {
    color: $c-dropdown__arrow--disabled--color;
  }
}

.is-disabled .c-dropdown-multiple {
  .c-dropdown__wrapper {
    opacity: $c-dropdown__disabled--opacity;
  }
}

// Swipe
// -----------------------------------------
[data-js-swipe].c-dropdown__swipe {
  min-height: $c-dropdown-multiple__swipe--min-height;
  pointer-events: none;

  .c-dropdown__selected-item-list {
    top: $c-dropdown-multiple__swipe--top;
    bottom: $c-dropdown-multiple__swipe--bottom;

    &:not(:empty) ~ .c-dropdown__placeholder {
      display: none;
    }
  }

  .c-chip {
    display: inline-block;
    pointer-events: auto;
  }

  .o-swipe-btn {
    pointer-events: auto;
  }
}

// Chevrone
// -----------------------------------------
.c-dropdown-multiple {
  .c-dropdown__arrow {
    pointer-events: auto;
    cursor: pointer;
  }
}
