/* -------------------------------------------------------------------------
* Collection row
*
*/
// Shared Styles
.c-collection-row {
  display: flex;

  .c-collection-row__item {
    position: relative;
    box-shadow: $c-collection-row--shadow $c-collection-row--shadow-color;
    transition: box-shadow $f-duration--normal $f-easing--base;
    text-decoration: none;

    &:hover {
      transition: box-shadow $f-duration--fast $f-easing--base;
      box-shadow: $c-collection-row--shadow $c-collection-row--shadow-color--hover;
      text-decoration: none;
    }
  }

  .c-collection-row__item-image {
    position: relative;
  }

  .c-collection-row__item-label {
    position: absolute;
  }

  .c-collection-row__item-content {
    @include sp-inset-squish($c-collection-row__item-content--sp-inset);
  }

  .c-collection-row__item-title {
    @include m-heading($c-collection-row__item-title--material);
    margin-bottom: 0;
    color: currentColor;
    + .c-collection-row__item-subtitle {
      margin-top: $c-collection-row__item-title--sp-stack;
    }
  }

  .c-collection-row__item-subtitle {
    @include m-body($c-collection-row__item-subtitle--material);
    font-weight: $c-collection-row__item-subtitle--font-weight;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .c-collection-row__item-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @include sp-inset($c-collection-row__item-icon--sp-inset);
  }
}

// Condensed Styles

.c-collection-row:not([class*="c-collection-row--expanded"]) {
  @include collection-row-condensed();
}

.c-collection-row:not([class*="c-collection-row--expanded"]):not([class*="c-collection-row--tiled"]) {
  @include sp-stack-children($c-collection-row__item-sp-stack);
}

// Tiled styles
// Keep collection row condensed
// ---------------------------------------------------------

[class*="c-collection-row--tiled"] {
  @include collection-row-tiled();

  &.c-collection-row:not([class*="c-collection-row--expanded"]) {
    flex-direction: row;
  }
}
@each $_breakpoint in $c-collection-row--tiled-breakpoints {
  @if $_breakpoint != "xxs" {
    @include t-mq($until: $_breakpoint) {
      .c-collection-row--tiled-from#{s-core-string-breakpoint($_breakpoint)} {
        @include collection-row-expanded-to-condensed();
        @include sp-stack-children($c-collection-row__item-sp-stack);
        @include collection-row-condensed();
      }
    }
  }
}

// Expanded styles
// Enable collection row condensed until certain breakpoints
// ---------------------------------------------------------

[class*="c-collection-row--expanded"] {
  @include collection-row-tiled();
  @include collection-row-expanded();
}
@each $_breakpoint in $c-collection-row--expanded-breakpoints {
  @if $_breakpoint != "xxs" {
    @include t-mq($until: $_breakpoint) {
      .c-collection-row--expanded-from#{s-core-string-breakpoint($_breakpoint)} {
        @include collection-row-expanded-to-condensed();
        @include sp-stack-children($c-collection-row__item-sp-stack);
        @include collection-row-condensed();
      }
    }
  }
}
