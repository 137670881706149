/* -------------------------------------------------------------------------
 * ROOM OCCUPANCY
 *
 */

.w-room-occupancy {
  &:last-child {
    margin-bottom: 0
  }
}

.w-room-occupancy__room-title-row {
  padding-left: $w-room-occupancy__room-title-row--padding-left;
  color: $w-room-occupancy__room-title--color;
  margin-bottom: $w-room-occupancy__room-title-row--margin-bottom;
  display: flex;
  align-items: center;
}

.w-room-occupancy__room-title-text {
  text-transform: $w-room-occupancy__room-title--text-transform;
  color: $w-room-occupancy__room-title--color;
  font-size: $w-room-occupancy__room-title--font-size;
  font-weight: $w-room-occupancy__room-title--font-weight;
  margin-bottom: 0;
}

.w-room-occupancy__room-title-icon {
  color: $w-room-occupancy__room-title--color;
  font-size: $w-room-occupancy__room-title-icon--font-size;
  margin-right: $w-room-occupancy__room-title-icon--margin-right;
}

.w-room-occupancy__details-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: $f-space--small;
  align-items: center;
}

.w-room-occupancy__price {
  font-weight: $w-room-occupancy__price--font-weight;
  color: $w-room-occupancy__price--color;
  text-align: right;
  flex-shrink: 0;
  padding-left: $f-space--small;
  max-width: $w-room-occupancy__price--max-width;
  .c-price {
    font-size: $w-room-occupancy__price--font-size;
    font-weight: $w-room-occupancy__price_info--font-weight;
    display: inline;
    word-wrap: break-word;
    .c-price__info {
      font-size: $w-room-occupancy__price_info--font-size;
      padding-bottom: $w-room-occupancy__price_info--padding-bottom;
    }
    .c-price__number {
      line-height: $w-room-occupancy__price-number--line-height;
      .c-price__suffix {
        white-space: nowrap;
      }
    }
  }
}

.w-room-occupancy__info-message {
  color: $w-room-occupancy__info-message--color;
  font-size: $w-room-occupancy__info-message--font-size;
  &:empty {
    display: none;
  }
}

.w-room-occupancy__error-message {
  color: $w-room-occupancy__error-message--font-color;
  font-size: $w-room-occupancy__error-message--font-size;
  font-weight: $w-room-occupancy__error-message--font-weight;
  margin-top: $w-room-occupancy__error-message--margin;
  &:not(:last-child) {
    margin-bottom: $w-room-occupancy__error-message--margin;
  }
  &:empty {
    display: none;
  }
}

.w-room-occupancy__collapsible-buttons {
  text-align: right;
  .c-collapse__trigger[data-c-collapse__action=close]:not([data-c-collapse__opened]).c-collapse__trigger--smooth {
    display: none;
  }

  .c-collapse__trigger[data-c-collapse__action=open][data-c-collapse__opened].c-collapse__trigger--smooth {
    display: none;
  }
}

.w-room-occupancy__description {
  font-size: $w-room-occupancy__description--font-size;
}

.w-room-occupancy__details-wrapper {
  position: relative;
}

.w-room-occupancy__details {
  border: $w-room-occupancy--border-size $w-room-occupancy--border-type $w-room-occupancy--border-color;
  border-radius: $w-room-occupancy--border-radius;
  @include sp-inset($w-room-occupancy--main-spacing);
  @include t-mq($until:$w-room-occupancy--breakpoint) {
    @include sp-stack($w-room-occupancy__details--mobile-stack);
  }

  &.w-room-occupancy__details--invalid {
    border-color: $w-room-occupancy_status-invalid--border-color;
  }
}

.w-room-occupancy__title,
.w-room-occupancy__name-text {
  display: inline-block;
  @include sp-inline($w-room-occupancy--main-spacing);
}

.w-room-occupancy__onrequest.c-label--sale.c-label {
  font-family: $w-room-occupancy__onrequest--font-family;
  font-size: $w-room-occupancy__onrequest--font-size;
  font-weight: $w-room-occupancy__onrequest--font-weight;
  border: $w-room-occupancy__onrequest--border-size $w-room-occupancy__onrequest--border-type $w-room-occupancy__onrequest--border-color;
  color: $w-room-occupancy__onrequest-color;
  margin-bottom: $w-room-occupancy__onrequest--margin-bottom;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: $w-room-occupancy__onrequest--bg-color;
}

.w-room-occupancy__availability.c-label--sale.c-label {
  border: $w-room-occupancy__availability--border-size $w-room-occupancy__availability--border-type $w-room-occupancy__availability--border-color;
  color: $w-room-occupancy__availability--color;
  background-color: $w-room-occupancy__availability--bg-color;
  margin-bottom: $w-room-occupancy__availability--margin-bottom;
}

.w-room-occupancy__bt-close,
.w-room-occupancy__remove-button {
  color: $w-room-occupancy--icon-color;
  &:hover {
    color: $w-room-occupancy--icon-hover-color;
  }

  &.c-btn--icon-only .c-btn__icon {
    padding-left: $w-room-occupancy--main-spacing;
    @include t-mq($from:$w-room-occupancy--breakpoint) {
      padding-left: $w-room-occupancy__wrapper-spacing;
    }
    &::before {
      font-size: $w-room-occupancy--icon-font-size;
      @include t-mq($from:$w-room-occupancy--breakpoint) {
        font-size: $w-room-occupancy--icon-desktop-font-size;
      }
    }
  }
}

.w-room-occupancy__remove-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: $w-room-occupancy__remove-button--right;
  @include t-mq($from:$w-room-occupancy--breakpoint) {
    right: $w-room-occupancy__remove-button--desktop--right;
  }
}

.w-room-occupancy__occupancy-selector {
  margin-top: $w-room-occupancy__occupancy-selector--margin-top;
  .c-number-stepper {
    display: flex;
    align-items: flex-start;
    padding: $w-room-occupancy__occupancy-selector__number-stepper--padding 0;
    border-top: $w-room-occupancy__occupancy-selector__number-stepper--border-size $w-room-occupancy__occupancy-selector__number-stepper--border-type $w-room-occupancy__occupancy-selector__number-stepper--border-color;
    align-items: center;
    &:last-child {
      padding-bottom: 0;
    }
  }
  .c-number-stepper__label {
    flex-grow: 2;
    margin-right: $w-room-occupancy__occupancy-selector__number-stepper-label--margin-right;
    font-size: $w-room-occupancy__occupancy-selector__number-stepper-label--font-size;
  }

  @include t-mq($w-room-occupancy__occupancy-selector--breakpoint) {
    display: flex;
    justify-content: space-around;
    border-top: $w-room-occupancy__occupancy-selector__number-stepper--border-size $w-room-occupancy__occupancy-selector__number-stepper--border-type $w-room-occupancy__occupancy-selector__number-stepper--border-color;
    padding-top: $w-room-occupancy__occupancy-selector__number-stepper--padding;
    .c-number-stepper {
      display: flex;
      border-top: none;
      padding: 0;
      flex: 1;
      max-width: $w-room-occupancy__occupancy-selector__number-stepper--max-width;

      &:not(:last-child) {
        margin-right: $w-room-occupancy__occupancy-selector__number-stepper--margin-right;
      }
    }
  }

  &:not(:last-child) {
    border-bottom: $w-room-occupancy__occupancy-selector__number-stepper--border-size $w-room-occupancy__occupancy-selector__number-stepper--border-type $w-room-occupancy__occupancy-selector__number-stepper--border-color;;
    padding-bottom: $w-room-occupancy__occupancy-selector__number-stepper--padding;
  }

  .e-form-label {
    font-weight: 700;
    margin-bottom: 0;
  }

  .e-form-label__description {
    display: block;
    font-weight: 400;
    color: $w-room-occupancy__occupancy-selector__number-stepper-label-description--color;
    font-size: $w-room-occupancy__occupancy-selector__number-stepper-label-description--font-size;
    padding-top: $w-room-occupancy__occupancy-selector__number-stepper-label-description--top-separation;
  }
}

.w-room-occupancy__characteristics-trigger {
  margin-top: $w-room-occupancy__characteristics-trigger--margin-top;
}

.w-room-occupancy__characteristics-trigger-btn {
  display: flex;
  align-items: center;
}

.w-room-occupancy__characteristics-trigger-btn .c-collapse__trigger-text {
  margin-right: $w-room-occupancy__characteristics-trigger-btn-text--margin-right;
}

.w-room-occupancy__characteristics-trigger-btn .c-collapse__trigger-icon {
  color: currentColor;
  display: inline-block;
}

.w-room-occupancy__characteristics-content {
  margin-top: $w-room-occupancy__characteristics-content--margin-top;
  margin-bottom: $w-room-occupancy__characteristics-content--margin-bottom;
}
