// List modifier

.c-nav--list {

  .c-nav__list:not([data-c-nav__level="1"]) {
    display: none;
  }

  .c-nav__link {
    color: $c-nav__link--list--color;
    background-color: $c-nav__link--list--bg-color;
    cursor: pointer;
    display: block;
    position: relative;
    @include sp-inset-h($c-nav--list--sp-inset-h);
    @include sp-inset-v($c-nav--list--sp-inset-v);
    @include m-icon("chevron-right", "after");

    &:after {
      position: absolute;
      right: $c-nav__icon--list--right;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      background-color: $c-nav__link--list--hover--bg-color;
      color: $c-nav__link--list--color;
    }

    &:active {
      background-color: $c-nav__link--list--pressed--color;
    }
  }

  .c-nav__item {
    @include t-text-crop-disable();
    @include sp-stack($c-nav__item--list--sp-stack);

    &.is-active {
      > .c-nav__link {
        font-weight: 400;
      }
    }

    &.has-child {
      > .c-nav__link {
        color: inherit;
        font-size: inherit;
        pointer-events: inherit;
        padding-right: $c-nav--list--sp-inset-h * 2;
      }
    }

    &:last-child {
      @include sp-stack($f-space--none);
    }
  }

  .c-nav__link-icon {
    display: block;
    position: absolute;
    left: $c-nav--list--sp-inset-h;
  }

  .c-nav__link-icon + .c-nav__link-text {
    padding-left: $c-nav__link-icon--list--padding-left;
  }
}
