/* -------------------------------------------------------------------------
 * BOOKING FILTERABLE SERVICES
 *
 */

$w-booking-filterable-services__room-name--padding:                                     $f-space--medium !default;
$w-booking-filterable-services__participant--padding:                                   $f-space--medium !default;
$w-booking-filterable-services__participant--reset-padding:                             $f-space--none !default;
$w-booking-filterable-services__participant-name--padding:                              $f-space--small !default;

$w-booking-filterable-services__header--color:                                          $f-color__neutral-grey--semi-dark !default;
$w-booking-filterable-services__header--padding:                                        $f-space--small !default;
$w-booking-filterable-services__header--margin-top:                                     $f-space--tiny !default;
$w-booking-filterable-services__header--background-color:                               $f-color__neutral-grey--light !default;
$w-booking-filterable-services__header--font-size:                                      $f-font-size--s !default;
$w-booking-filterable-services__header-info--margin:                                    $f-space--small !default;
$w-booking-filterable-services__header-info--font-size:                                 $f-font-size--m !default;
$w-booking-filterable-services__header-trigger--font-size:                              $f-font-size--s !default;
$w-booking-filterable-services__header-trigger--margin-right:                           $f-space--none !default;
$w-booking-filterable-services__header-active--color:                                   $f-color__state-info !default;
$w-booking-filterable-services__header-inactive--color:                                 $f-color__neutral-grey !default;
$w-booking-filterable-services__header-active--background-color:                        $f-color__state-info--ultra-light !default;
$w-booking-filterable-services__header-active--font-weight:                             600 !default;

$w-booking-filterable-services__body__filter--padding:                                  $f-space--medium !default;
$w-booking-filterable-services__body__buttons--sp-stack:                                $f-space--large + $f-space--small !default;

$w-booking-filterable-services__body--padding:                                          $f-space !default;
$w-booking-filterable-services__body--border-color:                                     $f-color__neutral-grey--light !default;
$w-booking-filterable-services__body--background-color:                                 $f-color__neutral-white !default;
$w-booking-filterable-services__body--margin-top:                                       $f-space--medium !default;

$w-booking-filterable-services__filter--padding:                                        $f-space--medium !default;
$w-booking-filterable-services__filter-info--padding:                                   $f-space--small !default;

$w-booking-filterable-services__filter-info__price--font-family:                        $f-font-family__secondary !default;
$w-booking-filterable-services__filter-info__price--font-size:                          $f-font-size--m !default;
$w-booking-filterable-services__filter-info__price--font-weight:                        600 !default;
$w-booking-filterable-services__filter-info__price--color:                              $f-color__state-info !default;
$w-booking-filterable-services__filter-info__price--margin:                             $f-space--tiny !default;

$w-booking-filterable-services__filter-info__price-description--font-family:            $f-font-family__primary !default;
$w-booking-filterable-services__filter-info__price-description--font-size:              $f-font-size--xs !default;
$w-booking-filterable-services__filter-info__price-description--font-weight:            600 !default;
$w-booking-filterable-services__filter-info__price-description--padding-left:           $f-space--medium !default;
$w-booking-filterable-services__filter-info__price-description__span--font-weight:      500 !default;

$w-booking-filterable-services__participants-as-options--margin-top:                    $f-space--large + $f-space--small !default;
$w-booking-filterable-services__cancel-btn--font-size:                                  $f-font-size--s !default;
$w-booking-filterable-services__cancel-btn--margin:                                     $f-space--small !default;

$w-booking-filterable-services--bp:                                                     "md" !default;

$w-booking-filterable-services-collapse__service__content--padding:                     $f-space--medium !default;
$w-booking-filterable-services__service-title--font-weight:                             600 !default;

$w-booking-filterable-services__service-title--side-drawer--margin:                     $f-space--none !default;
$w-booking-filterable-services__service-title--side-drawer--close--color:               $f-color__neutral-grey--dark !default;
$w-booking-filterable-services__participant__service-title--border-color:               $f-color__neutral-grey--dark !default;
$w-booking-filterable-services__participant__service-title--side-drawer--space:         $f-space--medium !default;

$w-booking-filterable-services__tabs__nav-button--height:                               52px !default;
$w-booking-filterable-services__tabs__nav--sp-stack:                                    $f-space--medium !default;

$w-booking-filterable-services__btn-group-label--font-family:                           $f-font-family__primary !default;
$w-booking-filterable-services__btn-group-label--margin-bottom:                         $f-space--small !default;
$w-booking-filterable-services__btn--bg-color:                                          $f-color__neutral-white !default;
$w-booking-filterable-services__btn--shadow:                                            $f-shadow--elevated rgba($f-shadow__color, $f-shadow__opacity--elevated)!default;

$w-booking-filterable-services__btn--active--bg-color:                                  $f-color__state-info !default;
$w-booking-filterable-services__btn--active--color:                                     $f-color__neutral-white !default;
$w-booking-filterable-services__btn--active--border-color:                              $f-color__neutral-white--transparent !default;
$w-booking-filterable-services__btn--active--shadow:                                    $f-shadow--sunken rgba($f-shadow__color, $f-shadow__opacity--elevated)!default;

$w-booking-filterable-services__tabs__nav-button--color--open:                          $f-color__state-info !default;

$w-booking-filterable-services__labels--font-size:                                      $f-font-size--s !default;
$w-booking-filterable-services__labels--font-weight:                                    600 !default;

$w-booking-filterable-services__accept-btn--is-empty:                                   .4 !default;
$w-booking-filterable-services__empty-message--sp-stack:                                $f-space--medium !default;
$w-booking-filterable-services__empty-message--padding-v:                               $f-space--small !default;
$w-booking-filterable-services__empty-message--padding-h:                               $f-space--large !default;

$w-booking-filterable-services-golf__body__filter--columns-size:                        1fr !default;
$w-booking-filterable-services-golf__body__filter--rows-size:                           auto !default;
$w-booking-filterable-services-golf__body__filter--grid-gap:                            $f-space--medium !default;

$w-booking-filterable-services-button-group--chips--gap-mobile:                         $f-space--small !default;
$w-booking-filterable-services-button-group--chips--gap-v:                              $f-space--small !default;
$w-booking-filterable-services-button-group--chips--gap-h:                              $f-space--tiny !default;

$w-booking-filterable-services-btn-chip--padding:                                       $f-space--tiny !default;
$w-booking-filterable-services-btn-chip--font-size:                                     $f-font-size--s !default;
$w-booking-filterable-services-btn-chip--font-weight:                                   400 !default;
$w-booking-filterable-services-btn-chip--font-family:                                   $f-font-family--primary !default;
$w-booking-filterable-services__btn-chip--border-color:                                 $f-color__neutral-grey--light !default;