
/* -------------------------------------------------------------------------
 * FONT-FAMILIES
 *
 * Foundation font-families
 *
 */

// Utilities output
// --------------------------------------------------

@if ($u-font-families--enabled) {

  @each $_font-family__name, $_font-family__value in $u-font-families__map {

    .u-font-family--#{$_font-family__name} {
      font-family: $_font-family__value !important;
    }

  }

}


// Save utility class names to $brand__class-names
// --------------------------------------------------

@if variable-exists(class-names) and $u-font-families--enabled {

  @each $_font-family__name, $_font-family__value in $u-font-families__map {

    $u-font-families__class-names: append($u-font-families__class-names, (
      'name': '#{$_font-family__name}',
      'class': 'u-font-family--#{$_font-family__name}',
      'value': $_font-family__value
    ));

  }

  $class-names: map-merge($class-names, (
    'u-font-families': $u-font-families__class-names
  ));

}
