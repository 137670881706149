/* -------------------------------------------------------------------------
 * CHOICE LIST
 *
 */
.c-choice-list{
  &.is-required:not(.c-choice-list--without-required-symbol) {
    .c-choice-list__label:after {
      content: ' *';
      display: inline;
    }
  }
}

.c-choice-list--boxed:not(.c-choice-list--rich-options) {
  .c-choice-list__option:not(:last-child) {
    @include sp-stack($c-choice-list__element--boxed--sp-stack);
  }

  .c-checkbox__label,
  .c-radio-button__label {
    border: 1px solid $c-choice-list--boxed--border-color;
    border-radius: $c-choice-list--boxed--border-radius;
    @include sp-inset-v($c-choice-list__label--boxed--sp-inset)
  }
  .c-checkbox__element,
  .c-radio-button__element{
    height: $c-choice-list__element--boxed--height;
  }
  .c-radio-button__text-wrapper, .c-checkbox__text-wrapper{
    line-height: $c-choice-list__element--boxed--height;
    @include sp-inset-v($c-choice-list__text-wrapper--boxed--sp-inset)
  }
  // States
  // -------------------------------------------------
  // Hover / Focus / Pressed State
  .c-checkbox__input:focus + .c-checkbox__label,
  .c-radio-button__input:focus ~ .c-radio-button__label,
  .c-checkbox__label:hover,
  .c-radio-button__label:hover,
  .c-checkbox__label:active,
  .c-radio-button__label:active {
    background-color: $c-choice-list--boxed--hover--bg-color;
    .c-checkbox__element,
    .c-radio-button__element {
      background-color: $c-choice-list--boxed--hover--bg-color;
    }
  }

  // Active / Selected / Checked State + Highlight
  .c-checkbox__input:checked + .c-checkbox__label,
  .c-radio-button__input:checked + .c-radio-button__label {
    background-color: $c-choice-list--boxed--selected--bg-color;
    border: 1px solid $c-choice-list--boxed--selected--border-color;
    .c-checkbox__element,
    .c-radio-button__element {
      background-color: $c-choice-list--boxed--selected--bg-color;
    }
    .c-radio-button__element:before{
      border: 1px solid $c-choice-list--boxed--selected--border-color;
    }
  }
}

.c-choice-list__label:empty {
  display: none;
}

// States
// -------------------------------------------------

// Error
.c-choice-list.has-error,
.has-error .c-choice-list {
  .c-choice-list__message {
    color: $c-choice-list__message--error--color
  }
}

// Modifiers
// -----------------------------------------

// Highlighted
.c-choice-list--highlighted {
  .c-checkbox__input:checked + .c-checkbox__label > .c-checkbox__text,
  .c-radio-button__input:checked + .c-radio-button__label > .c-radio-button__text {
    color: $c-choice-list--highlighted--selected-color;
  }
}

.c-choice-list__messages {
  font-size: $c-choice-list__messages--font-size;
  display: flex;
}

.c-choice-list__message:first-child {
  margin-top: $c-choice-list__messages--margin-top;
}

// Inline
.c-choice-list--inline {
  .c-choice-list__option {
    display: inline-block;
    margin-right: $c-choice-list__option--inline--margin-right;
  }

  .c-choice-list__options {
    display: flex;
    flex-wrap: wrap;
  }
}


// Rich
.c-choice-list--rich-options{
  &.c-choice-list--boxed{
    .c-choice-list__option-rich{
      border: 1px solid $c-choice-list--boxed--border-color;
      border-radius: $c-choice-list--boxed--border-radius;
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      align-items: center;

      &:not(:last-child) {
        @include sp-stack($c-choice-list__element--boxed--sp-stack);
      }
      &:hover{
        background-color: $c-choice-list--boxed--hover--bg-color;
      }
      .c-choice-list__option-rich-item__content > * {
        margin-bottom: 0;
      }

      .c-choice-list-rich__label {
        cursor: pointer;
        flex: 1;
        border-top-right-radius: $c-choice-list--boxed--border-radius;
        border-bottom-right-radius: $c-choice-list--boxed--border-radius;
        @include sp-inset-v($c-choice-list__label--boxed--sp-inset);
        max-width: $c-choice-list-rich__label--max-width;
      }

      .c-checkbox__element,
      .c-radio-button__element{
        height: $c-choice-list__element--boxed--height;
      }
      .c-radio-button__text-wrapper, .c-checkbox__text-wrapper{
        line-height: $c-choice-list__element--boxed--height;
        @include sp-inset-v($c-choice-list__text-wrapper--boxed--sp-inset)
      }

      // States
      // -------------------------------------------------
      // Hover / Focus / Pressed State
      .c-checkbox__input:focus + .c-checkbox__label,
      .c-radio-button__input:focus ~ .c-radio-button__label,
      .c-checkbox__label:hover,
      .c-radio-button__label:hover,
      .c-choice-list-rich__label:hover,
      .c-checkbox__label:active,
      .c-radio-button__label:active,
      .c-choice-list-rich__label:active {
        background-color: $c-choice-list--boxed--hover--bg-color;
        .c-checkbox__element,
        .c-radio-button__element {
          background-color: $c-choice-list--boxed--hover--bg-color;
        }
      }

      // Active / Selected / Checked State + Highlight
      &.is-checked{
        background-color: $c-choice-list--boxed--selected--bg-color;
        border: 1px solid $c-choice-list--boxed--selected--border-color;
        .c-checkbox__element,
        .c-radio-button__element {
          background-color: $c-choice-list--boxed--selected--bg-color;
        }
        .c-radio-button__element:before{
          border: 1px solid $c-choice-list--boxed--selected--border-color;
        }

        &:hover{
          background-color: $c-choice-list--boxed--selected--bg-color;
          .c-checkbox__label, .c-radio-button__label, .c-choice-list-rich__label {
            background-color: $c-choice-list--boxed--selected--bg-color;
          }
        }
        .c-checkbox__input:focus + .c-checkbox__label,
        .c-radio-button__input:focus ~ .c-radio-button__label{
          background-color: $c-choice-list--boxed--selected--bg-color;
        }
      }

      // Validation states
      &.has-error {
        border-color: $c-choice-list--boxed--error--border-color;
      }
    }
  }
}

.c-choice-list--boxed-slider{
  max-width: 100%;
  .c-slider__content{
    display: flex;
    .c-choice-list--rich-options{
      &.c-choice-list--boxed{
        .c-choice-list__option-rich, .c-choice-list-rich__label, .c-choice-list__option-rich-item__content{
          height: 100%;
        }
      }
    }
  }
}
