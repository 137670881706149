.w-faq-top-questions {
  max-width: $w-faq-top-questions--max-width;
  margin: 0 auto;
  @include sp-stack-children($w-faq-top-questions--sp-stack-children);

  &__title {
    text-align: center;
  }

  &.is-loading,
  &.no-results {
    display: none;
  }
}
