$w-booking-basket__rating--sp-stack:                            $f-space--small !default;

$w-booking-basket__acco-name--line-height-from-bp:              $f-line-height--small !default;

$w-booking-basket__services--header-title--font-size:           $f-font-size--m !default;
$w-booking-basket__services--collapse-btn__icon--color:         $f-color__neutral-grey--dark !default;

$w-booking-basket__titles-eliza--font-weight:                   700 !default;
$w-booking-basket__titles-eliza--font-family:                   $f-font-family__secondary !default;

$w-booking-basket__content--sp-inset:                           $f-space--none !default;

$w-booking-basket__extra-info--background-color:                $f-color--state-info--ultra-light !default;

$w-booking-basket__side-drawer--header--padding:                18px !default;
