/* -------------------------------------------------------------------------
* Calendar
*
*/

.c-calendar {
  .flatpickr-calendar {
    background: $f-color__neutral-white;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    padding: 0;
    animation: none;
    direction: ltr;
    border: 0;
    font-size: $c-calendar--font-size;
    line-height: 24px;
    border-radius: 5px;
    position: absolute;
    width: $c-calendar--width;
    margin: 0 auto;
    touch-action: manipulation;
    @include t-mq($until: $c-calendar__week-number--visible-from) {
      width: auto !important;
      min-width: $c-calendar--width;
    }

    &.open,
    &.inline {
      opacity: 1;
      visibility: visible;
      overflow: visible;
      max-height: 640px;
    }

    &.open {
      display: inline-block;
      z-index: 99999;
    }

    &.animate.open {
      animation: fpFadeInDown $c-calendar--duration $c-calendar--easing;
    }

    &.inline {
      display: block;
      position: relative;

      &::before {
        display: none;
      }

      .flatpickr-innerContainer {
        border-bottom: 0;
      }
    }

    &.static {
      position: absolute;
      top: calc(100% + 2px);
    }

    &.open {
      z-index: 999;
      display: block;
    }

    &.hasWeeks {
      width: $c-calendar__week-number--width !important;
    }

    .hasWeeks, .hasTime {
      .dayContainer {
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .hasWeeks .dayContainer {
      border-left: 0;
    }

    &.showTimeInput.hasTime {
      .flatpickr-time {
        height: $c-calendar--height-time;
        border-top: 1px solid $f-color__neutral-white--transparent;
      }

      .flatpickr-innerContainer {
        border-bottom: 0;
      }
    }

    &.noCalendar.hasTime {
      .flatpickr-time {
        height: auto;
      }
    }

    &:before,
    &:after {
      position: absolute;
      display: block;
      pointer-events: none;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      left: 22px;
    }

    &.rightMost {
      &:before,
      &:after {
        left: auto;
        right: 22px
      }
    }

    &:before {
      border-width: 5px;
      margin: 0 -5px;
    }

    &:after {
      border-width: 4px;
      margin: 0 -4px;
    }

    &.arrowTop {
      &:before,
      &:after {
        bottom: 100%;
      }

      &:before {
        border-bottom-color: $f-color__neutral-white--transparent;
      }

      &:after {
        border-bottom-color: $f-color__neutral-white--transparent;
      }
    }

    &.arrowBottom {
      &:before,
      &:after {
        top: 100%;
      }

      &:before {
        border-top-color: $f-color__neutral-white--transparent;
      }
      &:after {
        border-top-color: $f-color__neutral-white--transparent;
      }

    }

    &:focus {
      outline: 0;
    }
  }

  .flatpickr-wrapper {
    position: relative;
    display: inline-block;
  }

  .flatpickr-month {
    border-radius: 0;
    background: $f-color__neutral-white--transparent;
    font-weight: $c-calendar__month--font-weight;
    font-size: $c-calendar__month--font-size;
    font-family: $c-calendar__month--font-family;
    color: $c-calendar__month--color;
    fill: $c-calendar__month--color;
    height: $c-calendar--height;
    margin: 0 1.5em 0 .5em;
    line-height: 1;
    text-align: center;
    position: relative;
    user-select: none;
    overflow: hidden;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    text-decoration: none;
    cursor: pointer;
    color: $c-calendar__month--color;
    position: absolute;
    top: 0;
    line-height: 16px;
    height: $c-calendar--height;
    padding: 0;
    z-index: 3;
    transform: translateY(3px);

    i {
      position: relative
    }

    svg {
      width: $c-calendar--font-size;

      path {
        transition: fill 0.1s;
        fill: $c-calendar__month--color;
      }
    }

    &.flatpickr-prev-month {
      left: 0;
    }

    &.flatpickr-next-month {
      right: 0;
    }

    &:hover {
      @if ($c-calendar--today) {
        color: $c-calendar--today;
      }

      svg {
        width: $c-calendar--font-size;

        path {
          transition: fill 0.1s;
          fill: $c-calendar__month--color--hover;
        }
      }
    }
  }

  .numInputWrapper {
    position: relative;
    height: auto;
    width: 4ch;

    input,
    span {
      display: inline-block;
    }

    input {
      width: 100%;
      pointer-events: none;
      background-color: transparent;
    }

    span {
      position: absolute;
      right: 0;
      width: 14px;
      padding: 0 4px 0 2px;
      height: 50%;
      line-height: 50%;
      opacity: 0;
      cursor: pointer;
      border: 1px solid rgba($c-calendar__day--color, 0.05);
      display: none;
      pointer-events: none;

      &:hover {
        background: $f-color__neutral-white--transparent;
      }

      &:active {
        background: $f-color__neutral-white--transparent;
      }

      &:after {
        display: block;
        content: "";
        position: absolute;
        top: 33%;
      }

      &.arrowUp {
        top: 0;
        border-bottom: 0;

        &:after {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 4px solid rgba($c-calendar__day--color, 0.6);
        }
      }

      &.arrowDown {
        top: 50%;

        &:after {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid rgba($c-calendar__day--color, 0.6);
        }

      }
    }

    &:hover {
      span {
        opacity: 1
      }
    }
  }

  .flatpickr-current-month {
    color: inherit;
    position: absolute;
    width: 75%;
    left: 12.5%;
    height: $c-calendar--height;
    display: inline-block;
    text-align: center;
    transform: translateX(.75ch) translate3d(0, 0, 0);

    &.slideLeft {
      transform: translate3d(-100%, 0px, 0px);
      animation: fpFadeOut $c-calendar--duration $c-calendar--easing, fpSlideleft $c-calendar--duration $c-calendar--easing;
    }

    &.slideLeftNew {
      transform: translate3d(100%, 0px, 0px);
      animation: fpFadeIn $c-calendar--duration $c-calendar--easing, fpSlideLeftNew $c-calendar--duration $c-calendar--easing;
    }

    &.slideRight {
      transform: translate3d(100%, 0px, 0px);
      animation: fpFadeOut $c-calendar--duration $c-calendar--easing, fpSlideright $c-calendar--duration $c-calendar--easing;
    }

    &.slideRightNew {
      transform: translate3d(0, 0, 0px);
      animation: fpFadeIn $c-calendar--duration $c-calendar--easing, fpSlideRightNew $c-calendar--duration $c-calendar--easing;
    }

    span.cur-month {
      margin-right: .5ch;
      font-weight: $c-calendar__month--font-weight;
      color: $c-calendar__month--color;
      text-transform: $c-calendar__month--text-transform;
      display: inline-block;
      padding: 0;
    }

    .numInputWrapper {
      width: 4.5ch;
      display: inline-block;
    }

    input.cur-year {
      color: inherit;
      cursor: default;
      margin: 0;
      display: inline-block;
      font-size: $c-calendar__month--font-size;
      font-family: $c-calendar__month--font-family;
      font-weight: $c-calendar__month--font-weight;
      height: initial;
      border: 0;
      padding: 0;
      box-shadow: none;
      border-radius: 0;
      vertical-align: initial;
      outline: 0;
      pointer-events: none;

      &[disabled],
      &[disabled]:hover {
        opacity: 1;
        pointer-events: none;
      }
    }
  }

  .flatpickr-weekdays {
    background: $f-color__neutral-white--transparent;
    text-align: center;
    overflow: hidden;
    width: $c-calendar--width;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $c-calendar--height;
  }

  span.flatpickr-weekday {
    cursor: default;
    font-size: $c-calendar--font-size;
    font-weight: $c-calendar__weekdays--font-weight;
    background: $f-color__neutral-white--transparent;
    color: $c-calendar__weekdays--color;
    line-height: 1;
    margin: 0;
    margin-left: $c-calendar--day-margin;
    margin-right: $c-calendar--day-margin;
    text-align: center;
    display: block;
    flex: 1;
    width: $c-calendar--day-size;
    max-width: $c-calendar--day-size;
    min-width: $c-calendar--day-size;
    text-transform: capitalize;
  }

  .dayContainer,
  .flatpickr-weeks {
    padding: 1px 0 0 0;
  }

  .flatpickr-days {

    &:focus {
      outline: 0;
      border: none;
    }
  }

  .dayContainer {
    padding: 0;
    outline: 0;
    text-align: left;
    width: $c-calendar--width;
    min-width: $c-calendar--width;
    max-width: $c-calendar--width;
    display: inline-block;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  .flatpickr-calendar.animate .dayContainer {
    &.slideLeft {
      animation: fpFadeOut $c-calendar--duration $c-calendar--easing, fpSlideLeft $c-calendar--duration $c-calendar--easing
    }

    &.slideLeft,
    &.slideLeftNew {
      transform: translate3d(-100%, 0, 0);
    }

    &.slideLeftNew {
      animation: fpFadeIn $c-calendar--duration $c-calendar--easing, fpSlideLeft $c-calendar--duration $c-calendar--easing;
    }

    &.slideRight {
      animation: fpFadeOut $c-calendar--duration $c-calendar--easing, fpSlideRight $c-calendar--duration $c-calendar--easing;
      transform: translate3d(100%, 0, 0);
    }

    &.slideRightNew {
      animation: fpFadeIn $c-calendar--duration $c-calendar--easing, fpSlideRightNew $c-calendar--duration $c-calendar--easing;
    }
  }

  .flatpickr-day {
    background: none;
    border: 0 solid transparent;
    border-radius: 150px;
    color: $c-calendar__day--color;
    font-weight: $c-calendar__day--font-weight;
    cursor: pointer;
    width: $c-calendar--day-size;
    max-width: $c-calendar--day-size;
    height: $c-calendar--day-size;
    line-height: $c-calendar--day-size;
    margin: $c-calendar--day-margin;

    display: inline-block;
    position: relative;
    justify-content: center;
    text-align: center;

    &.prevMonthDay,
    &.nextMonthDay,
    &.prevMonthDay.is-disabled,
    &.nextMonthDay.is-disabled,
    &.prevMonthDay.disabled,
    &.nextMonthDay.disabled {
      color: $c-calendar__next-prev--color;
      font-weight: $c-calendar__next-prev--font-weight;
      pointer-events: none;

      &:hover,
      &:focus {
        color: $c-calendar__day--color;
      }
    }

    &,
    &.prevMonthDay,
    &.nextMonthDay {
      &.inRange,
      &.today.inRange,
      &:hover,
      &:focus {
        cursor: pointer;
        outline: 0;
        background: $c-calendar__day--hover--bg-color;
        border-color: $c-calendar__day--hover--bg-color;
        font-weight: $c-calendar__day--hover--font-weight;
      }
    }

    @if $c-calendar--today != false {
      &.today {
        border-color: $c-calendar--today;

        &:hover,
        &:focus {
          border-color: $c-calendar--today;
          background: $c-calendar--today;
        }
      }
    }

    &.selected,
    &.startRange,
    &.endRange,
    &.is-selection-offset {
      &,
      &.inRange,
      &.is-disabled,
      &:focus,
      &:hover,
      &.prevMonthDay,
      &.nextMonthDay {
        background: $c-calendar__day--selected--bg-color;
        box-shadow: none;
        border-color: $c-calendar__day--selected--bg-color;
        color: $c-calendar__day--selected--color;
        font-size: $c-calendar__day--selected--font-size;
        font-weight: $c-calendar__day--selected--font-weight;
        transition: all $c-calendar--duration $c-calendar--easing;

        &:hover {
          color: $c-calendar__day--selected--hover--color;
        }

        &:active {
          background-color: $c-calendar__day--selected--pressed--bg-color;
          color: $c-calendar__day--selected--color;
        }
      }

      &.startRange {
        border-radius: 50px 0 0 50px;
      }

      &.endRange {
        border-radius: 0 50px 50px 0;
      }

      &.startRange + .endRange {
        box-shadow: (-5*$c-calendar--day-margin) 0 0 $c-calendar__day--selected--bg-color;
      }

      &.startRange.endRange {
        border-radius: 50px;
      }
    }

    &.inRange {
      border-radius: 0;
      box-shadow: (-2.5*$c-calendar--day-margin) 0 0 $c-calendar__day--hover--bg-color, (2.5*$c-calendar--day-margin) 0 0 $c-calendar__day--hover--bg-color;
    }

    &.disabled,
    &.disabled:hover,
    &.is-disabled,
    &.is-disabled:hover {
      pointer-events: none;
      color: $c-calendar__day--disabled--color;
      font-size: $c-calendar__day--disabled--selected--font-size;
      font-weight: $c-calendar__day--disabled--selected--font-weight;
      text-decoration: line-through;
      
      &.is-selection-offset {
        background: $c-calendar__day--disabled--selected--bg-color;
        color: $c-calendar__day--disabled--selected--color;
        font-weight: $c-calendar__day--disabled--selected--font-weight;
        font-size: $c-calendar__day--disabled--selected--font-size;
      }
    }

    &.disabled,
    &.disabled:hover,
    &.is-disabled,
    &.is-disabled:hover,
    &.prevMonthDay,
    &.nextMonthDay,
    &.notAllowed,
    &.notAllowed.prevMonthDay,
    &.notAllowed.nextMonthDay {
      cursor: default;
      background: transparent;
      &.inRange {
        background: $c-calendar__day--hover--bg-color;
        border-color: $c-calendar__day--hover--bg-color;
      }
    }

    &.week.selected {
      border-radius: 0;
      box-shadow: (-2.5*$c-calendar--day-margin) 0 0 $c-calendar__day--selected--bg-color, (2.5*$c-calendar--day-margin) 0 0 $c-calendar__day--selected--bg-color;
    }
  }

  .rangeMode .flatpickr-day {
    margin-top: 1px
  }

  .flatpickr-weekwrapper {
    display: none;
    float: left;

    @include t-mq($from: $c-calendar__week-number--visible-from) {
      display: inline-block;
    }

    .flatpickr-weeks {
      padding: 0;
      display: inline-block;

      .flatpickr-day {
        height: $c-calendar--day-size + $c-calendar--day-margin;
      }
    }

    .flatpickr-weekday {
      float: none;
      width: 100%;
      line-height: $c-calendar--height;
    }

    span.flatpickr-day {
      display: block;
      width: 100%;
      max-width: none;
      transform: translateY(1px);
      color: $c-calendar__week-number--color;
      font-weight: $c-calendar__week-number--font-weight;
      font-size: $c-calendar__week-number--font-size;

      &.disabled {
        color: $c-calendar__week-number--color;
      }
    }
  }

  .flatpickr-innerContainer {
    display: block;
    display: flex;
    overflow: hidden;
    margin: 0 auto;
    background: $c-calendar--bg-color;
    border-bottom: 1px solid $f-color__neutral-white--transparent;
  }

  .flatpickr-rContainer {
    display: inline-block;
    padding: 0;
    margin: 0 auto;
    @include t-mq($from: $c-calendar__week-number--visible-from) {
      margin: 0;
    }
  }

  .flatpickr-time {
    text-align: center;
    outline: 0;
    display: block;
    height: 0;
    line-height: $c-calendar--height-time;
    max-height: $c-calendar--height-time;
    overflow: hidden;
    display: flex;

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    .numInputWrapper {
      flex: 1;
      width: 40%;
      height: $c-calendar--height-time;
      float: left;

      span.arrowUp:after {
        border-bottom-color: $c-calendar__day--color;
      }

      span.arrowDown:after {
        border-top-color: $c-calendar__day--color;
      }
    }

    &.hasSeconds .numInputWrapper {
      width: 26%;
    }

    &.time24hr .numInputWrapper {
      width: 49%;
    }

    input {
      background: transparent;
      box-shadow: none;
      border: 0;
      border-radius: 0;
      text-align: center;
      margin: 0;
      padding: 0;
      height: inherit;
      line-height: inherit;
      cursor: pointer;
      color: $c-calendar__day--color;
      font-size: $c-calendar--font-size;
      position: relative;

      &.flatpickr-hour {
        font-weight: 700;
      }

      &.flatpickr-minute,
      &.flatpickr-second {
        font-weight: 400;
      }

      &:focus {
        outline: 0;
        border: 0;
      }
    }

    .flatpickr-time-separator,
    .flatpickr-am-pm {
      height: inherit;
      display: inline-block;
      float: left;
      line-height: inherit;
      color: $c-calendar__day--color;
      font-weight: bold;
      width: 2%;
      user-select: none;
      align-self: center;
    }

    .flatpickr-am-pm {
      outline: 0;
      width: 18%;
      cursor: pointer;
      text-align: center;
      font-weight: 400;

      &:hover,
      &:focus {
        background: lighten($c-calendar__day--hover--bg-color, 4)
      }
    }
  }

  .flatpickr-input[readonly] {
    cursor: pointer;
  }

  @keyframes fpFadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -20, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0)
    }
  }

  @keyframes fpSlideLeft {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(- 100%, 0, 0);
    }
  }

  @keyframes fpSlideLeftNew {
    from {
      transform: translate3d(100%, 0, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fpSlideRight {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(100%, 0, 0);
    }
  }

  @keyframes fpSlideRightNew {
    from {
      transform: translate3d(-100%, 0, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fpFadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes fpFadeIn {
    from {
      opacity: 0
    }

    to {
      opacity: 1
    }
  }
}

.c-calendar__input {
  display: none;
}
