/* -------------------------------------------------------------------------
 * DESTINATION AREA FACTS
 *
 */

.w-destination-area-facts {
  .c-tabs__content {
    @include t-mq($until: $w-destination-area-facts--bp-large) {
      padding-top: $c-tabs__content__padding-top;
    }
  }
}

.w-destination-area-facts-columns__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: stretch;
}

.w-destination-area-facts-columns__item {
  flex-direction: row;
  flex: auto;
  flex-grow: 1;
  min-height: $w-destination-area-facts-columns__item__min-height;
  padding-bottom: $w-destination-area-facts-columns__item__padding-bottom;
  margin: 0;
  padding-left: $w-destination-area-facts-columns__item__padding-bottom;
  padding-right: $w-destination-area-facts-columns__item__padding-bottom;
  @include t-mq($from: $w-destination-area-facts--bp-large) {
    padding-bottom: $w-destination-area-facts-columns__item__padding-bottom--from-mq;
  }

  &:first-child {
    @include t-mq($until: $w-destination-area-facts--bp-medium) {
      min-width: 100%;
    }
  }
  &:not(:first-child) {
    border-left: 1px solid $w-destination-area-facts-columns__item__border-color;
    @include t-mq($until: $w-destination-area-facts--bp-medium) {
      border-left: 0;
    }
  }

  &:not(.w-destination-area-facts__map) {
    flex-basis: 45%;
    @include t-mq($from: $w-destination-area-facts--bp-medium) {
      flex-basis: 0;
    }
  }

  .c-slider__item{
    position: relative;
  }

  &.w-destination-area-facts__map{
    flex: auto;
    order: -1;
    flex-basis: 100%;
    border-left: 0;
    @include t-mq($from: $w-destination-area-facts--bp-large) {
      flex-grow: 2;
      flex-basis: 0;
      order:0;
    }


  }

  .w-destination-area-facts__map {
    &.w-destination-area-facts__overlay {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($w-destination-area-facts__map__overlay__color, $w-destination-area-facts__map__overlay__opacity);
      transition: opacity .4s;
      opacity: 0;
      cursor: pointer;

      &:hover {
        opacity: 1;

        .m-icon {
          opacity: 1;
        }

      }

      .m-icon {
        color: $w-destination-area-facts__map__overlay__icon-color;
        position: absolute;
        z-index: $w-destination-area-facts__map__overlay__z-index--1;
        top: 50%;
        left: 50%;
        font-size: $w-destination-area-facts__map__overlay__icon-font-size;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity .4s;
      }
    }
  }
}

.w-destination-area-facts__item-icon {
  padding-bottom: $w-destination-area-facts__item-icon__padding-bottom;
  .w-destination-area-facts__item-icon-disabled {
    color: $w-destination-area-facts__item-icon-disabled__color;
  }
  .w-destination-area-facts__item-icon-size-large:before {
    font-size: $w-destination-area-facts__item-icon__size-large;
  }
  .w-destination-area-facts__item-icon-size-small:before {
    font-size: $w-destination-area-facts__item-icon__size-small;
  }
}

.w-destination-area-facts_ski-slope {
  padding-bottom: $w-destination-area-facts_ski-slope_padding-bottom;
  min-height: $w-destination-area-facts__ski-fact__min-height;

  .w-destination-area-facts_ski-slope-black{
    @include progress-color($w-destination-area-facts_ski-slope-black__color, transparent);
  }
  .w-destination-area-facts_ski-slope-red{
    @include progress-color($w-destination-area-facts_ski-slope-red__color, transparent);
  }
  .w-destination-area-facts_ski-slope-blue{
    @include progress-color($w-destination-area-facts_ski-slope-blue__color, transparent);
  }
  .w-destination-area-facts_ski-slope-green{
    @include progress-color($w-destination-area-facts_ski-slope-green__color, transparent);
  }

  .c-progress-bar__body.o-pack {
    table-layout: auto;
    width: 80%;
    margin-left: 10%;
  }

  .c-progress-bar {
    padding-bottom: $c-progress-bar__padding-bottom;
  }

  .c-progress-bar__title {
    margin-left: 10%;
  }

  .w-destination-area-facts_ski-slope__total-title {
    text-align: left;
    padding: 0;
  }
}

.w-destination-area-facts__ski-fact {
  &:not(:last-child) {
    min-height: $w-destination-area-facts__ski-fact__min-height;
    padding-bottom: $w-destination-area-facts__ski-fact__padding-bottom;
  }
}

.w-destination-area-facts__ski-fact-title, .w-destination-area-facts_ski-slope-title {
  padding-bottom: $w-destination-area-facts__ski-fact-title__padding-bottom;
}

.w-destination-area-facts__ski-fact__container{
  display:flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;
  text-align: center;

  &.w-destination-area-facts__container-many{
    @include t-mq($until: $w-destination-area-facts--bp-medium) {
      width: 60%;
      margin-left: 20%;
    }
  }
}

.c-progress-bar__value.o-pack__item.m-heading.m-heading--small{
  font-size: $c-progress-bar_overwrite-font-size;
  font-weight: initial;
  font-family: $c-progress-bar_overwrite-font-family;
}

.w-destination-area-facts_ski-slope_total-slope {
  padding-top: $w-destination-area-facts_ski-slope_total-slope__padding-top;
  border-top: 1px solid $w-destination-area-facts-columns__item__border-color;
}
