/* -------------------------------------------------------------------------
 * Customer Discounts
 *
 */

.w-customer-discounts {
  &__message {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &__text{
      margin-bottom: $w-customer-discounts__message__text--margin-bottom;
    }

    @include t-mq($from: "sm") {
      flex-direction: row;
      &__text{
        margin-right: $w-customer-discounts__message__text--margin-right;
        margin-bottom: 0;
      }
    }
  }

  &.is-hidden {
    display: none;
  }
}
