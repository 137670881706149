/* -------------------------------------------------------------------------
 * Booking generic summary
 *
 */

.w-booking-generic-summary  {
}

.w-booking-generic-summary__list-item {
  @include sp-stack($w-booking-generic-summary__sp-stack);

  &:last-child {
    @include sp-stack($f-space--none);
  }
}
