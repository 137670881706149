.fr-tabs-container {
  .c-tabs__nav {
    overflow: hidden;

    @include t-mq($until: $t-accommodation--bp) {
      margin-left: $fr-tabs-container--margin-h--until-bp;
      margin-right: $fr-tabs-container--margin-h--until-bp;
    }
  }

  .c-tabs__content {
    @include t-mq($until: $t-accommodation--bp) {
      @include sp-inset-h($fr-tabs-container__content--sp-inset-h--until-bp);
    }
  }
}
