/* -------------------------------------------------------------------------
 * BREAKDOWN TABLE VARS
 */

$c-breakdown-table__item--color--total:                   3px !default;
$c-breakdown-table__item--font-weight--bold:              700 !default;
$c-breakdown-table__item--font-weight--normal:            400 !default;
$c-breakdown-table__item--text-transform--total:          uppercase !default;

$c-breakdown-table__item-right--sp-right:                 $f-space--medium !default;

$c-breakdown-table__item--color--subitem:                 $f-color__neutral-grey !default;
$c-breakdown-table__item--color--subitem--info:           $f-color__state-success--dark !default;
$c-breakdown-table__item-content--color--discount:        $f-color__state-success !default;
$c-breakdown-table__item--material--subitem:              map_get($m-body__mod-sizes, "small") !default;

$c-breakdown-table__item--color--preitem:                 $f-color__neutral-grey !default;
$c-breakdown-table__item--material--preitem:              map_get($m-body__mod-sizes, "small") !default;

$c-breakdown-table__item--margin:                         $f-space--small !default;
