/* -------------------------------------------------------------------------
 * BULLET LIST
 *
 */

.c-bullet-list {
  font-size: $c-bullet-list__font-size;
}

.c-bullet-list__item {
  display: flex;
}

.c-bullet-list__item--bold {
  font-weight: $c-bullet-list__font-weight;
}

.c-bullet-list__icon {
  vertical-align: top;
  padding-top: $c-bullet-list__icon--position-fix;
}

.c-bullet-list__icon--hidden {
  opacity: 0;
}

.c-bullet-list__text {
  white-space: normal;
  
  p {
    margin-bottom: 0;
  }
}

.c-bullet-list__link {
  font-weight: 400;
}

// Display modifiers
// -----------------------------------------------------------------------

.c-bullet-list--inline {
  @include o-list-inline;
  margin-top: -1 * $c-bullet-list__sp-stack;
  margin-left: -1 * $c-bullet-list__sp-inline;

  .c-bullet-list__item {
    margin-bottom: 0;
    margin-top: $c-bullet-list__sp-stack;
    margin-left: $c-bullet-list__sp-inline;
  }
}


// Color schemes overrides
// -----------------------------
@import "color-schemes";
