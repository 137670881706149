/* -------------------------------------------------------------------------
 * QuickSearch
 *
 */

.w-quick-search {
  display: block;
  margin-bottom: 0;

  .c-dropdown__element:focus,
  .c-dropdown__element.is-focused,
  .c-textbox__element:focus,
  .c-textbox__element.is-focused {
    box-shadow: none;
    border-color: $c-dropdown__element--border-color !important;
  }

  .c-dropdown__element,
  .c-textbox__element {
    min-height: $w-quick-search__filter--min-height;
    cursor: pointer;
  }

  .c-dropdown {
    .c-dropdown__element {
      padding-bottom: $w-quick-search__filter-duration--p;
      padding-top: $w-quick-search__filter-duration--p;
    }
  }

  .e-form-label:not(.c-number-stepper__label) {
    @include e-form-label--strong();

    @include t-mq($until: $w-quick-search--bp) {
      display: none;
    }
  }

  .w-filter--participants {
    @include t-mq($from: $f-breakpoint--xs) {
      .c-floating-box {
        max-height: calc(100vh - 85px);
      }
      .c-floating-box__body {
        overflow-x: visible;
        overflow-y: auto;
        flex-shrink: 1;
      }
    }
  }
}

.w-quick-search__publications {
  flex-shrink: 0;
  display: flex;
  margin-bottom: $w-quick-search__publications--margin-bottom;

  &:not(.on-top) {
    margin-bottom: 0;
  }

  .c-button-group {
    width: auto;

    .c-btn {
      flex-grow: 1;
    }
  }
}

.w-quick-search__filters {
  .w-filter {
    margin-bottom: $w-quick-search__filter--mb;
    padding:0;
  }

  > *.w-filter:not(.is-active) {
    display: none;
  }
}

.w-quick-search__submit {
  margin-top: $f-space--small;
  text-align: right;
  .c-btn {
    display: block;
    width: 100%;
  }
}

@include t-mq($from: $f-breakpoint--sm) {
  .w-quick-search__submit {
    .c-btn {
      display: inline-block;
      width: auto;
      position: relative;
      // Reposition the loader in front of the text when submit-btn is loading and lower the text's opacity to give more feedback to the user
      &.c-btn--loading .c-btn__text {
        opacity: 0.4;
      }

      .c-loader {
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
      }
    }
  }
}

@include t-mq($until: $f-breakpoint--md) {
  .w-quick-search__filters {
    display: flex;
    flex-wrap: wrap;

    > * {
      flex-grow: 1;
      width: 50%;
    }
  }
}
// Better layout on larger devices
@include t-mq($from: $f-breakpoint--md) {
  .w-quick-search__publications {
    .c-btn {
      flex-grow: 0;
    }
  }

  .w-quick-search__filters {
    padding-left: 0;
    display: flex;
    margin-left: $w-quick-search__filters--sp-inline * -1;

    > * {
      flex: 1;
      margin-bottom: 0;
    }
    > :first-child {
      margin-left: $w-quick-search__filters--sp-inline;
    }
    .w-filter {
      margin-bottom: 0;
    }
  }

  .w-quick-search__submit {
    margin-top: $f-space--medium;
  }
}

@include t-mq($from: $f-breakpoint--lg) {
  .w-quick-search {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .w-quick-search__publications:not(.on-top) + .w-quick-search__wrapper {
    padding-left: $w-quick-search--sp-inline;
  }

  .w-quick-search__submit {
    margin-top: 0;
    flex-shrink: 0;
    padding-left: $w-quick-search--sp-inline / 2;

    .c-btn {
      display: block;
    }
  }
}

.w-quick-search__wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-bottom: 0;

  &.is-disabled {
    pointer-events: none;
  }
}

.c-background-img {
  .c-textbox__arrow.m-icon {
    color: $w-filter-on-top-c-background-img__dark-img--contents--color;
  }
  .c-button-group {
    .c-btn.c-btn--flat {
      &.is-active,
      &:active {
        background-color: transparent;
        color: $w-filter-on-top-c-background-img__dark-img--contents--color;
        border-bottom: $c-button-group__c-btn--flat__border-bottom--width solid
          $w-filter-on-top-c-background-img__dark-img--contents--color;

        .c-btn__icon {
          color: $w-filter-on-top-c-background-img__dark-img--contents--color;
        }
      }

      &:hover {
        background-color: transparent !important;
        color: $w-filter-on-top-c-background-img__dark-img--contents--color !important;
      }
    }
  }
}
/*
  Roundness of elements depending on position
 */
@mixin w-quick-search-target-dropdown-like-filters() {
  &.w-filter--dropdown,
  &.w-filter--destination,
  &.w-filter--dropdown-multiple,
  &.w-filter--airport {
    @content;
  }
}

@mixin w-quick-search-target-textbox-like-filters() {
  &.w-filter--departure-date,
  &.w-filter--participants,
  &.w-filter--destination {
    @content;
  }
}

.w-quick-search__filters {
  @include t-mq($from: $f-breakpoint--md) {
    // Desktop roundness
    fieldset.w-filter {
      @include w-quick-search-target-dropdown-like-filters {
        div.c-dropdown__wrapper,
        .c-dropdown__element {
          border-radius: 0;
          border-left: 0;
        }
      }
      @include w-quick-search-target-textbox-like-filters {
        .c-textbox__element {
          border-radius: 0;
          border-left: 0;
        }
      }
      &:first-of-type {
        @include w-quick-search-target-dropdown-like-filters {
          .c-dropdown__element {
            border-radius: $w-quick-search--border-radius 0 0 $w-quick-search--border-radius;
            border-left: $c-dropdown__element--border-size $c-dropdown__element--border-style
              $c-dropdown__element--border-color;
          }
        }

        @include w-quick-search-target-textbox-like-filters {
          .c-textbox__element {
            border-radius: $w-quick-search--border-radius 0 0 $w-quick-search--border-radius;
            border-left: $c-dropdown__element--border-size $c-dropdown__element--border-style
              $c-dropdown__element--border-color;
          }
        }
      }
      &:last-of-type {
        @include w-quick-search-target-dropdown-like-filters {
          .c-dropdown__element {
            border-radius: 0 $w-quick-search--border-radius $w-quick-search--border-radius 0;
            border-left: 0;
          }
        }

        @include w-quick-search-target-textbox-like-filters {
          .c-textbox__element {
            border-radius: 0 $w-quick-search--border-radius $w-quick-search--border-radius 0;
            border-left: 0;
          }
        }
        .c-floating-box {
          left: auto;
          right: 0;
        }
      }
    }
  }

  .w-filter--dropdown,
  .w-filter--dropdown-multiple,
  .w-filter--destination,
  .w-filter--airport {
    // - Hide arrow
    .c-dropdown-multiple,
    .c-dropdown {
      .c-dropdown__arrow {
        display: none;
      }
    }
  }

  @include t-mq($until: $f-breakpoint--md) {
    fieldset.w-filter:last-of-type:not(:nth-of-type(even)) {
      @include w-quick-search-target-dropdown-like-filters {
        .c-dropdown__element {
          border-radius: $w-quick-search--border-radius !important;
        }
      }

      @include w-quick-search-target-textbox-like-filters {
        .c-textbox__element {
          border-radius: $w-quick-search--border-radius !important;
        }
      }
    }

    fieldset.w-filter:nth-of-type(odd):not(:last-of-type) {
      @include w-quick-search-target-dropdown-like-filters {
        .c-dropdown__element {
          padding-right: $f-space--medium;
          border-radius: $w-quick-search--border-radius 0 0 $w-quick-search--border-radius !important;
        }
      }

      @include w-quick-search-target-textbox-like-filters {
        .c-textbox__element {
          border-radius: $w-quick-search--border-radius 0 0 $w-quick-search--border-radius !important;
        }
      }
    }

    fieldset.w-filter:nth-of-type(even) {
      @include w-quick-search-target-dropdown-like-filters {
        .c-dropdown__element {
          padding-right: $f-space--medium;
          border-radius: 0 $w-quick-search--border-radius $w-quick-search--border-radius 0 !important;
          border-left: 0;
        }
      }

      @include w-quick-search-target-textbox-like-filters {
        .c-textbox__element {
          border-radius: 0 $w-quick-search--border-radius $w-quick-search--border-radius 0 !important;
          border-left: 0;
        }
      }
    }

    @include w-quick-search-target-dropdown-like-filters {
      .c-dropdown__label {
        padding-left: $f-space--small;
      }
      // - Make sure that the clear background doesn't overlay dropdown box
      .c-dropdown__clear {
        border-top-right-radius: $w-quick-search--border-radius;
        border-bottom-right-radius: $w-quick-search--border-radius;
        // - As we hide the arrow we can reset clear button to the right
        right: 0;
      }
    }
    // Cosmetic improvements on textbox:
    // - Adjust border radius
    // - Padding on labels (Aligned with content)
    @include w-quick-search-target-textbox-like-filters {
      .c-textbox__label {
        padding-left: $f-space--small;
      }
      // - Make sure that the clear background doesn't overlay textbox box
      .c-textbox__clear {
        border-top-right-radius: $w-quick-search--border-radius;
        border-bottom-right-radius: $w-quick-search--border-radius;
      }
    }
  }

  fieldset.w-filter:nth-of-type(even) .c-floating-box {
    @include t-mq($from: $f-breakpoint--xs, $until: $f-breakpoint--md) {
      left: auto;
      right: 0;
    }
  }
}
