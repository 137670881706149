/* -------------------------------------------------------------------------
 * ROOM LIST
 *
 */

.w-room-list {
  position: relative;
}

.w-room-list__room-title,
.w-room-list__number-stepper {
  display: flex;
  align-items: center;
}

.w-room-list__header {
  @include sp-stack($w-room-list__header--margin-bottom);
  flex-direction: column;
  display: flex;
  align-items: flex-start;
}


.w-room-list__room-title-text {
  text-transform: uppercase;
  @include sp-stack(0);
  display: inline-block;
  color: $w-room-list__room-title--color;
  font-size: $w-room-list__room-title--font-size;
  font-weight: $w-room-list__room-title--font-weight;
}

.w-room-list__room-title-icon {
  color: $w-room-list__room-title--color;
  display: inline-block;
  font-size: $w-room-list__room-title-icon--font-size;
  @include sp-inline($w-room-list--main-spacing);
}

.w-room-list__occupancy-selector {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  margin-top: $w-room-list__occupancy-selector--margin-top;
  padding-top:  $w-room-list__occupancy-selector--padding-top;
  border-top: $w-room-list__occupancy-selector--border-top;

  @include t-mq($from:$w-room-list--second-bp) {
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: space-around;
  }
}

.w-room-list__number-stepper {
  flex: 1;
  @include sp-inset-v($w-room-list__number-stepper--sp-v);

  &:not(:last-child) {
    border-bottom: $w-room-list__number-stepper--border-bottom;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }


  @include t-mq($from:$w-room-list--second-bp) {
    max-width: $w-room-list__number-stepper--max-width;
    @include sp-inset(0);
    border-top: none;

    &:not(:last-child) {
      margin-right: $w-room-list__number-stepper--margin-right;
      border-bottom: none;
    }
  }

}

.w-room-list__number-stepper .c-number-stepper__label {
  margin-right: $w-room-list-item__number-stepper-label--margin-right;
  font-size: $w-room-list-item__number-stepper-label--font-size;
  font-weight: $w-room-list-item__number-stepper-label--font-weight;
  @include sp-stack(0);
  flex-grow: 1;
}

.w-room-list__number-stepper .e-form-label__description {
  display: block;
  font-weight: $w-room-list-item__number-stepper-label-description--font-weight;
  font-size: $w-room-list-item__number-stepper-label-description--font-size;
  color: $w-room-list-item__number-stepper-label-description--color;
  margin-top: $w-room-list-item__number-stepper-label-description--margin-top;
}

.w-room-list__number-stepper .c-number-stepper__input {
  background-color: transparent;
}

.w-room-list__room-title-occupancy {
  font-size: $w-room-list__room-title-occupancy--font-size;
  font-weight: $w-room-list__room-title-occupancy--font-weight;
  margin-left: $w-room-list__room-title-occupancy--margin-left;
}

.w-room-list__container {
  box-sizing: border-box;
  border-radius: $w-room-list__container--border-radius;
  border: $w-room-list__container--border;
  background-color: $w-room-list__container--background-color;
  @include sp-inset($w-room-list__container--padding);
}

.w-room-list-item {
  background-color: $w-room-list-item--background-color;
  @include sp-inset($w-room-list-item--padding);
  border-radius: $w-room-list-item--border-radius;
  border: $w-room-list-item--border;
  &:not(:last-child) {
    @include sp-stack($w-room-list-item--margin-bottom);
  }
}

.w-room-list-item__section-header {
  display: flex;
  justify-content: space-between;
  @include sp-stack($f-space--small);
  align-items: center;
}

.w-room-list-item__onrequest.c-label--sale.c-label {
  font-family: $w-room-list-item__onrequest--font-family;
  font-size: $w-room-list-item__onrequest--font-size;
  font-weight: $w-room-list-item__onrequest--font-weight;
  border: $w-room-list-item__onrequest--border-size $w-room-list-item__onrequest--border-type $w-room-list-item__onrequest--border-color;
  color: $w-room-list-item__onrequest-color;
  @include sp-stack($w-room-list-item__onrequest--margin-bottom);
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: $w-room-list-item__onrequest--bg-color;
}

.w-room-list-item__availability.c-label--sale.c-label {
  border: $w-room-list-item__availability--border-size $w-room-list-item__availability--border-type $w-room-list-item__availability--border-color;
  color: $w-room-list-item__availability--color;
  background-color: $w-room-list-item__availability--bg-color;
  @include sp-stack($w-room-list-item__availability--margin-bottom);
}

.w-room-list-item__name-text {
  display: inline-block;
  @include sp-inline($w-room-list-item--main-spacing);

  @include t-mq($until:$w-room-list--second-bp) {
    font-size: $w-room-list-item__name-text--font-size--mobile;
  }
}

.w-room-list-item__description {
  font-size: $w-room-list-item__description--font-size;
}

.w-room-list-item__price {
  font-weight: $w-room-list-item__price--font-weight;
  text-align: right;
  flex-shrink: 0;
  padding-left: $w-room-list-item__price--padding-left;
  max-width: $w-room-list-item__price--max-width;
  .c-price {
    font-size: $w-room-list-item__price--font-size;
    font-weight: $w-room-list-item__price-info--font-weight;
    display: inline;
    word-wrap: break-word;
    .c-price__info {
      font-size: $w-room-list-item__price-info--font-size;
    }
    .c-price__number {
      .c-price__suffix {
        white-space: nowrap;
      }
    }
  }
}

.w-room-list-item__section-upper-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include t-mq($until: $w-room-list--first-bp) {
    align-items: flex-start;
    flex-direction: column;
  }
}

%w-room-selector__trigger-btn-styles {
  display: flex;
  align-items: center;
  text-align: left;

  .c-collapse__trigger-text {
    margin-right: $w-room-list-item__characteristics-trigger-btn-text--margin-right;
  }

  .c-collapse__trigger-icon {
    color: currentColor;
    display: inline-block;
  }
}

.w-room-list-item__characteristics-trigger-btn {
  @extend %w-room-selector__trigger-btn-styles;
}

.w-room-list-item__characteristics-content {
  margin-top: $w-room-list-item__characteristics-content--margin-top;
  margin-bottom: $w-room-list-item__characteristics-content--margin-bottom;
}

.w-room-list-item__room-selection {
  flex-shrink: 0;
  margin-left: auto;

  @include t-mq($until: $w-room-list--first-bp) {
    margin-left: 0;
    margin-bottom: $w-room-list-item__room-selection--margin-bottom--until-bp;
    order: -1;
  }

  .w-room-list-item__room-selection--button {
    @include t-mq($until:$w-room-list--second-bp) {
      @include sp-inset-h($w-room-list-item__room-selection--button--sp-inset--mobile);
    }
  }
}

.w-room-list__empty-filtered-rooms-message {
  color: $w-room-list__empty-filtered-rooms-message--color;
  background-color: $w-room-list-item--background-color;
  @include sp-inset($w-room-list-item--padding);
  border-radius: $w-room-list-item--border-radius;
  border: $w-room-list-item--border;
  &:not(:last-child) {
    @include sp-stack($w-room-list__empty-filtered-rooms-message--margin-bottom);
  }
}

.w-room-list__filtered-out-header{
  color: $w-room-list__filtered-out-header--background-color;
  @include sp-stack($w-room-list__filtered-out-header--margin-bottom);
}

.w-room-list-item__filtered-out-container {
  opacity: $w-room-list-item__filtered-out--opacity;
  background-color: $w-room-list-item--background-color;
  @include sp-inset($w-room-list-item--padding);
  border-radius: $w-room-list-item--border-radius;
  border: $w-room-list-item--border;

  .w-room-list-item__filtered-out {
    border-radius: 0;
    border: 0;
    @include sp-inset(0);

    &:not(:last-child) {
      border-bottom: $w-room-list-item__filtered-out--border-size $w-room-list-item__filtered-out--border-type $w-room-list-item__filtered-out--border-color;
      margin-bottom: 0;
      padding-bottom: $w-room-list-item__filtered-out--padding-bottom;
    }

    &:not(:first-child) {
      margin-top: 0;
      padding-top: $w-room-list-item__filtered-out--padding-top;
    }

    .w-room-list-item__section-header {
      margin-bottom: 0;
    }

  }

}

.w-room-list-item {
  .c-price {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    @include t-mq($until: $w-room-list--first-bp) {
      flex-direction: column;
      align-items: flex-end;
    }

    .c-price__info {
      margin-right: $w-room-list-item__price-info--margin-right;
      margin-bottom: 0;
      padding-bottom: 0;

      @include t-mq($until: $w-room-list--first-bp) {
        margin-right: 0;
        margin-bottom: $w-room-list-item__price-info--margin-bottom--until-bp;
      }
    }
  }
}

.w-room-list--selected-room-type-title {
  font-weight: $w-room-list--selected-room-type-title--font-weight;
  font-size: $w-room-list--selected-room-type-title--font-size;
  margin-bottom: $w-room-list--selected-room-type-title--margin-bottom;
  line-height: $w-room-list--selected-room-type-title--line-height;
}
.w-room-list-separator {
  margin-top: $w-room-list-separator--margin;
  margin-bottom: $w-room-list-separator--margin;
  border-top: $w-room-list-separator--border-top;
}