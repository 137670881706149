.fr-text-block {
  position: relative;
}

.fr-text-block__button {
  position: relative;
  z-index: 1;
  width: 100%;
}

.fr-text-block__link {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}