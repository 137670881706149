/* -------------------------------------------------------------------------
 * MODAL
 */

 $c-modal-v2--bp:                                                              $f-breakpoint--sm !default;

 $c-modal-v2--z-index:                                                         map_get($f-z-indexes--usage, "c-modal-v2") !default;
 $c-modal-v2--bg-color:                                                        $f-color__neutral-grey--ultra-light !default;
 $c-modal-v2--font:                                                            map_get($m-body__mod-sizes, "small") !default;
 $c-modal-v2__header--background-color:                                        $f-color__neutral-white!default;
 $c-modal-v2__footer--background-color:                                        $f-color__neutral-white!default;
 $c-modal-v2--small__max-width-inset:                                          $f-space--medium * 2 !default;
 
 $c-modal-v2--min-width:                                                       260px !default;
 $c-modal-v2--sp-inset:                                                        $f-space--medium !default;
 $c-modal-v2--sp-inset-close-btn-alone:                                        $f-space--medium !default;
 $c-modal-v2--top-close-btn-alone:                                             $f-space--small + $f-space--tiny !default;
 
 $c-modal-v2--sp-inset-v:                                                      $f-space--small + $f-space--tiny !default;
 $c-modal-v2__header--mb:                                                      $f-space--tiny + $f-space--small !default;
 $c-modal-v2__header--safe-text:                                               $f-space--medium !default;
 $c-modal-v2__header--box-shadow:                                              0px 2px 6px rgba($f-shadow__color, $f-shadow__opacity)!default;
 $c-modal-v2__footer--box-shadow:                                              0px -2px 6px rgba($f-shadow__color, $f-shadow__opacity)!default;
 
 $c-modal-v2__close--font-size:                                                $f-font-size--s !default;
 
 $c-modal-v2--shadow:                                                          rgba($f-color__neutral-black, .5) !default;
 $c-modal-v2--radius:                                                          $f-radius--rounded !default;
 
 $c-modal-v2__header--logo-height:                                             $f-space--medium !default;
 $c-modal-v2__header--logo-mb:                                                 $f-space--medium !default;
 $c-modal-v2__border-radius:                                                   $f-space--tiny !default;
 $c-modal-v2--top:                                                             $f-space--medium + $f-space--small !default;
 $c-modal-v2--cross-height:                                                    $f-space--medium !default;
 $c-modal-v2--cross-size:                                                      $f-font-size--xl !default;
 $c-modal-v2--cross-size-close-btn-alone:                                      $f-font-size--xl !default;
 $c-modal-v2--cross-color-hover:                                               $f-color__neutral-black !default;
 $c-modal-v2--cross-color:                                                     $f-color__brand-primary !default;
 $c-modal-v2--cross-ml:                                                        $f-space--tiny + $f-space--small !default;

 $c-modal-v2__preheader--pr:                                                   $f-space--tiny + $f-space--small !default;
 
 // Modal sizes modifiers
 // -----------------------

 $c-modal-v2--small__width:                                                    416px !default;
 $c-modal-v2--small__max-height-inset:                                         256px !default;

 $c-modal-v2--medium__width:                                                   640px !default;
 $c-modal-v2--medium__max-height-inset:                                        160px !default;

 $c-modal-v2--large__width:                                                    864px !default;
 $c-modal-v2--large__max-height-inset:                                         128px !default;

 $c-modal-v2--huge__width:                                                     1088px !default;
 $c-modal-v2--huge__max-height-inset:                                          128px !default;
 
 
 // Color schemes
 // --------------------------------------------------
 
 $c-modal-v2__header-text--color--cs-brand-primary:                            $cs-brand-primary--heading !default;
 
 $c-modal-v2__close--color--cs-default:                                        $f-color__neutral-black !default;
 $c-modal-v2__close--hover-color--cs-default:                                  $f-color__neutral-grey !default;