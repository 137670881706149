/* -------------------------------------------------------------------------
 * MODAL
 */


$c-modal--z-index:                                  map_get($f-z-indexes--usage, "c-modal") !default;
$c-modal--bg-color:                                 $e-document__body--bgcolor !default;
$c-modal--font:                                     map_get($m-body__mod-sizes, "small") !default;

$c-modal--min-width:                                260px !default;
$c-modal--sp-inset:                                 $f-space--medium !default;

$c-modal__header--sp-inset-v:                       ($f-space--medium - $f-space--tiny) !default;
$c-modal__header--safe-text:                        $f-space--medium !default;

$c-modal__close--font-size:                         $f-font-size--s !default;

$c-modal--shadow:                                   rgba($f-color__neutral-black, .5) !default;
$c-modal--radius:                                   $f-radius--rounded !default;


// Modal sizes modifiers
// -----------------------
$c-modal--sizes: (
  "small":  "xs",
  "medium":  "sm",
  "large":  "sml",
  "huge":  "xl"
) !default;


// Color schemes
// --------------------------------------------------

$c-modal__header-text--color--cs-brand-primary:    $cs-brand-primary--heading !default;

$c-modal__close--color--cs-default:                         $f-color__neutral-black !default;
$c-modal__close--hover-color--cs-default:                   $f-color__neutral-grey !default;