/* -------------------------------------------------------------------------
* Date Selector
*
*/

.c-date-selector__label {
  @include sp-stack($c-date-selector--spacing);
}

.c-date-selector {
  .c-dropdown {
    @include o-pack__item();
  }

  &.is-required:not(.c-date-selector--without-required-symbol) {
    .c-date-selector__label:after {
      content: ' *';
      display: inline;
    }
  }

  &:not(.c-date-selector--splitted) {
    .c-date-selector__body {
      @include o-pack--auto();

      .c-dropdown {
        &:not(:first-child) {
          .c-dropdown__element {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }
        }

        &:not(:last-child) {
          .c-dropdown__wrapper {
            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: -1px;
              height: 100%;
              width: 0px;
              z-index: 1;
            }

            &:hover:not(:focus-within) {
              &::after {
                border-right: 1px solid $c-date-selector--dropdown__element--border-color--hover;
              }
            }

            &:focus-within {
              &::after {
                border-right: 1px solid $c-date-selector--dropdown__element--border--focus;
              }
            }
          }

          .c-dropdown__element {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            border-right: none;

            &:active, &:focus {
              z-index: 1;
            }
          }

          &.is-disabled .c-dropdown__wrapper {
            &:hover {
              &::after {
                border-right: 1px solid $c-date-selector--dropdown__element--border;
              }
            }
          }
        }
      }
    }
  }

  .c-date-selector__body {
    @include t-mq($until: $c-date-selector--bp) {
      .c-dropdown__element {
        padding: $c-date-selector--dropdown__element--padding;
      }

      .c-dropdown__arrow {
        display: none;
      }
    }
  }
}

.c-date-selector__messages {
  font-size: $c-date-selector__messages--font-size;
  display: flex;
}

.c-date-selector__message:first-child {
  margin-top: $c-date-selector__messages--margin-top;
}

// Modificator Block
.c-date-selector--block {
  .c-date-selector__body {
    display: flex;
    align-items: flex-start;

    .c-dropdown {
      flex-grow: 2;
      margin-bottom: 0;
    }
  }
}

// Variant Splitted

.c-date-selector--splitted {
  .c-date-selector__label {
    @include sp-stack($c-date-selector__label--stack-bottom);
  }

  .c-date-selector__body {
    @include o-pack($c-date-selector--splitted--separation);
  }
}


// States
// -------------------------------------------------

// Error
.c-date-selector.has-error,
.has-error .c-date-selector {
  .c-date-selector__message {
    color: $c-date-selector__message--error--color;
  }
  &:not(.c-date-selector--splitted) {
    .c-date-selector__body {
      .c-dropdown {
        &:not(:last-child) {
          .c-dropdown__wrapper{
            &:hover, &:focus-within {
              &::after{
                border-right: 1px solid $c-date-selector__message--error--color;
              }
            }
          }
        }
      }
    }
  }
}
