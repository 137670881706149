.c-steps{
  &.c-steps--progress{
    .c-steps__item{

      .c-steps__text{
        font-family: $c-steps__text--font-family;
      }

      &.is-disabled{
        .c-steps__link{
          color: $c-steps__link--is-disabled--color
        }
    }
    &.is-active{
        .c-steps__link{
          @include m-heading--complete($c-steps__link--is-active--font-family);
          @include t-text-crop-disable()
        }
      }
    }
  }
}
