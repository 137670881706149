/* -------------------------------------------------------------------------
 * TITLE
 */

// is-unresolved
$c-title--unresolved-background:                                 $f-color__neutral-grey--light !default;
$c-title--unresolved-background--width:                          100% !default;
$c-title--unresolved-background--height:                         100% !default;
$c-title--unresolved-background--top:                            0 !default;
$c-title--unresolved-background--left:                           0 !default;
$c-title--unresolved--border-radius:                             $f-radius--medium !default;
$c-title__unresolved--width:                                     5em !default;

$c-title__unresolved--animation-duration:                        2s !default;
$c-title__unresolved--animation-angle:                           220deg !default;
$c-title__unresolved--animation-startposition:                   -10px !default;
$c-title__unresolved--animation-startcolor:                      $f-color__neutral-white--transparent !default;
$c-title__unresolved--animation-endcolor:                        rgba($f-color__neutral-grey--ultra-light, .7) !default;