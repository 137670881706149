/* -------------------------------------------------------------------------
 * HOMEPAGE
 *
 */

.t-homepage .l-site-main__top {
  margin-bottom: $t-homepage__l-site-main__top--margin-bottom; 
} 

.fr-link-list .c-card__title,
.fr-image-link-list .c-card__title {
  font-family: $fr-link-list__c-card__title--font-family;
  font-weight: $fr-link-list__c-card__title--font-weight;
}

.t-homepage .fr-content-usps {
  margin-top: $t-homepage__content-usps--margin-top;
}

.fr-content-usps .w-content-usps--usp-block {
  margin-top: $fr-content-usps__content-usps--margin-top;
}

.t-homepage .l-site-main__content > .fr-acco-lister > .c-title, 
.t-homepage .l-site-main__content > .fr-collection-blocks > .c-title {
  margin-bottom: $fr-acco-lister__c-title--margin-bottom; 
}

.fr-acco-lister {
  padding-bottom: $fr-acco-lister--padding-bottom;
}
