/* -------------------------------------------------------------------------
* Map Interactive
*
*/

$c-map-interactive--breakpoint-tablet:                                                        $f-breakpoint--sm !default;
$c-map-interactive--breakpoint-desktop:                                                       $f-breakpoint--md !default;

$c-map-interactive--height:                                                                   85vh !default;
$c-map-interactive--mobile-height:                                                            100vh !default;
$c-map-interactive--tablet-max-height:                                                        390px !default;
$c-map-interactive--desktop-max-height:                                                       650px !default;

$c-map-interactive__card--mobile-height:                                                      102px !default;
$c-map-interactive__card--tablet-width:                                                       328px !default;
$c-map-interactive__card--offset:                                                             $f-space--medium !default;

$c-map-interactive__card-title--padding-right:                                                40px !default;

$c-map-interactive__card-close--size:                                                         $f-space--medium !default;
$c-map-interactive__card-close--mobile-offset:                                                $f-space--tiny !default;
$c-map-interactive__card-close--tablet-offset:                                                $f-space--small !default;
$c-map-interactive__card-close--background-color:                                             $f-color__neutral-white !default;
$c-map-interactive__card-close--z-index:                                                      $f-z-index--1 !default;

$c-map-interactive__cluster--z-index:                                                         $f-z-index--1 !default;
$c-map-interactive__cluster--hover-color:                                                     $f-color__neutral-black !default;
$c-map-interactive__cluster--border-radius:                                                   50% !default;
$c-map-interactive__cluster--size:                                                            100% !default;
$c-map-interactive__cluster--transition-duration:                                             $f-duration--normal !default;
$c-map-interactive__cluster--transition-easing:                                               $f-easing--base !default;

$c-map-interactive__custom-marker--color:                                                     $f-color__neutral-grey--dark !default;
$c-map-interactive__custom-marker--bg-color:                                                  $f-color__neutral-white !default;
$c-map-interactive__custom-marker--bg-color-active:                                           $f-color__neutral-grey--dark !default;
$c-map-interactive__custom-marker--bg-color-visited:                                          $f-color__neutral-grey--light !default;
$c-map-interactive__custom-marker--color-active:                                              $f-color__neutral-white !default;
$c-map-interactive__custom-marker--color-visited:                                             $f-color__neutral-black !default;
$c-map-interactive__custom-marker--padding:                                                   4px $f-space--small !default;
$c-map-interactive__custom-marker--border-radius:                                             16px !default;
$c-map-interactive__custom-marker--font-weight:                                               700 !default;
$c-map-interactive__custom-marker--font-family:                                               $f-font-family__secondary !default;
$c-map-interactive__custom-marker--font-size:                                                 15px !default;
$c-map-interactive__custom-marker--box-shadow:                                                2px 2px 10px rgba(0, 0 ,0 , 0.10) !default;
$c-map-interactive__custom-marker--border-color:                                              $f-color__neutral-grey--semi-light !default;
$c-map-interactive__custom-marker--border:                                                    2px solid $c-map-interactive__custom-marker--border-color !default;
$c-map-interactive__custom-marker--active-border-color:                                       $f-color__neutral-grey--dark !default;
$c-map-interactive__custom-marker--visited-border-color:                                      $f-color__neutral-grey !default;

$c-map-interactive__custom-marker--scale-transform:                                           scale(1.1) !default;
$c-map-interactive__custom-marker--bounce-translate:                                          -10px !default;
$c-map-interactive__custom-marker--bounce-duration:                                           0.30s !default;

$c-map-interactive__destination-custom-marker--size:                                          36px;
$c-map-interactive__destination-custom-marker--font-size:                                     14px;
$c-map-interactive__destination-custom-marker--top:                                           32px;
$c-map-interactive__destination-custom-marker--z-index:                                       -$f-z-index--5;
