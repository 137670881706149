
/* -------------------------------------------------------------------------
 * CONFIG
 *
 * Main configuration, settings, foundations and tools. No CSS output from
 * them. Should be modified with caution. Values should be expressed in PX,
 * if you plan to convert them into relative units, please, do so on usage.
 *
 * settings ................... Main configuration, settings and core functions
 * foundations ................ Visual language expressed into SASS vars
 * tools ...................... Helper mixins for mq, quantities, ...
 *
 */

$s-core__font-size:           16px !default;
$s-config__path--fonts:       "../assets/fonts" !default;
$s-config__path--img:         "../assets/img" !default;

@import "../../../shared/modules/settings/settings__core";
@import "../../../shared/modules/settings/settings__config";
@import "../../../shared/modules/settings/settings__core-functions";


@import "../modules/foundations/foundations";
@import "../../../shared/modules/foundations/foundations";
@import "../../../shared/modules/tools/tools";
