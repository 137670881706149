/* -------------------------------------------------------------------------
 * FORM MIXINS
 *
 */

@mixin c-form__item--size($width-default, $width-tablet, $width-desktop, $width-tablet-desktop: null) {
  min-width: $width-default;
  flex-basis: $width-default;

  @include t-mq($from: $c-form--breakpoint-tablet) {
    min-width: $width-tablet;
    flex-basis: $width-tablet;
  }

  @if $width-tablet-desktop != null {
    @include t-mq($from: $c-form--breakpoint-tablet-desktop) {
      min-width: $width-tablet-desktop;
      flex-basis: $width-tablet-desktop;
    }
  }

  @include t-mq($from: $c-form--breakpoint-desktop) {
    min-width: $width-desktop;
    flex-basis: $width-desktop;
  }
}

@mixin c-form__fieldset-container--sp-stack-children($_spacing-value) {
  margin-top: -$_spacing-value;

  & .c-form__item {
    margin-top: $_spacing-value;
  }
}
