/* -------------------------------------------------------------------------
 * LABEL VARIABLES
 */

$c-label--material:                         map_get($m-body__mod-sizes, "medium") !default;
$c-label--line-height:                      $f-line-height--tiny !default;

$c-label-sizes__map:                        $m-body__mod-sizes !default;

// Variants
// ------------------------------

// Sale

$c-label--font-family--sale:                $f-font-family__primary !default;
$c-label--bg-color--sale:                   $f-color__state-danger !default;
