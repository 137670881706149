/* -------------------------------------------------------------------------
 * COLLECTION
 *
 */

.t-collection {
  .l-site-main__content {
    > [class^="fr-"] {
      @include sp-stack($f-space--large);

      @include t-mq($from: $t-homepage--bp) {
        @include sp-stack($f-space--huge);
      }

      > .c-title {
        text-align: center;
        @include sp-stack($t-homepage__c-title--sp-stack);
      }
    }
  }
}

// Vertical margins for items with shadows inside other collections slider
.fr-other-collections {
  .c-slider__content {
    display: flex;
  }
  .c-slider__item {
    display: inline-flex;
    margin-top: $fr-other-collections__c-slider-item--margin-top;
    margin-bottom: $fr-other-collections__c-slider-item--margin-bottom;
  }
}
