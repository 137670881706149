/* -------------------------------------------------------------------------
 * NAV
 */

$c-nav--font-size:                                  $f-font-size--m !default;
$c-nav--font-family:                                $f-font-family__primary !default;
$c-nav--font-family--crop:                          $f-font-family__primary--crop !default;
$c-nav--line-height:                                $f-line-height--medium !default;
$c-nav--sp-inset-left:                              $f-space--small !default;
$c-nav--sp-stack:                                   $f-space--tiny !default;

$c-nav__list--margin-top:                           $c-nav--sp-stack * .5 !default;

$c-nav__item--has-child--font-size:                 $f-font-size--s !default;
$c-nav__item--has-child--color:                     $f-color__neutral-grey--semi-dark !default;
$c-nav__item--active--font-weight:                  700 !default;

$c-nav__link--text-transform:                       none !default;
$c-nav__link--font-weight:                          400 !default;
$c-nav__link--color:                                $f-color__brand-primary !default;
$c-nav__link--duration:                             $f-duration--normal !default;
$c-nav__link--easing:                               $f-easing--linear !default;
$c-nav__link--hover--color:                         $f-color__brand-primary--dark !default;


// Variants
// ---------------------

// Inline
$c-nav--inline--height:                             $c-nav--font-size * $c-nav--line-height !default;

$c-nav__item--inline__sp-inline:                    $f-space--small !default;

$c-nav__button--inline--width:                      3em !default;
$c-nav__button--inline--bg-color:                   $f-color__neutral-white !default;
$c-nav__button--inline--font-size:                  $f-font-size--xs !default;
$c-nav__button--inline--color:                      $e-links__color !default;
$c-nav__button--inline--color--hover:               $f-color__brand-secondary !default;
$c-nav__button--inline--duration-opacity:           $f-duration--normal !default;
$c-nav__button--inline--duration-width:             $f-duration--slow !default;

// Dropdown
$c-nav--dropdown--sp-inline:                        $f-space--small !default;
$c-nav--dropdown--sp-inset-v:                       $f-space--tiny !default;
$c-nav--dropdown--sp-inset-h:                       $f-space--small !default;
$c-nav--dropdown--border-width:                     1px !default;
$c-nav--dropdown--border-color:                     $f-color__neutral-grey--light !default;
$c-nav--dropdown--padding-left:                     .25em !default;
$c-nav--dropdown--padding-right:                    2em !default;


$c-nav__link--dropdown--font-weight--level-1:       400 !default;
$c-nav__link--dropdown--hover--bg-color:            $f-color__brand-primary--ultra-light !default;
$c-nav__link--dropdown--hover--bg-color:            $f-color__brand-primary--ultra-light !default;
$c-nav__link--dropdown--pressed--bg-color:          $f-color__brand-primary--light !default;
$c-nav__link--dropdown--active--bg-color:           $f-color__brand-primary--light !default;
$c-nav__link--dropdown--active--font-weight:        400 !default;
$c-nav__link--dropdown--4th--padding-left:          $f-space--medium !default;
$c-nav__link--dropdown--padding-left:               $f-space--medium !default;

$c-nav__list--dropdown-min-width:                   210px !default;
$c-nav__list--dropdown-max-width:                   210px !default;
$c-nav__list--dropdown-border-color:                $c-nav__link--color !default;
$c-nav__list--dropdown-border-width:                2px !default;
$c-nav__list--dropdown-border-style:                solid !default;
$c-nav__list--dropdown-bg-color:                    $e-document__body--bgcolor !default;
$c-nav__list--dropdown-shadow:                      0 2px 10px 0 rgba($f-color__neutral-black, .16) !default;
$c-nav__list--dropdown-z-index:                     $f-z-index--1 !default;
$c-nav__list--dropdown--level-2--margin-top:        0 !default;


$c-nav__item--active--dropdown--border-width:       4px !default;
$c-nav__item--active--dropdown--border-style:       solid !default;
$c-nav__item--active--dropdown--border-color:       $f-color__brand-primary !default;


// Block
$c-nav--block--sp-inset-h:                          $f-space--small !default;
$c-nav--block--sp-inset-v:                          $f-space--tiny * .75 !default;

$c-nav__list--block--border-width:                  $f-duration--normal !default;

$c-nav__item--block--border-width:                  1px !default;
$c-nav__item--block--border-style:                  solid !default;
$c-nav__item--block--border-color:                  $f-color__neutral-grey--light !default;

$c-nav__item--back--height:                          22px !default;

$c-nav__item--active--block--border-width:          4px !default;
$c-nav__item--active--block--border-style:          solid !default;
$c-nav__item--active--block--border-color:          $f-color__brand-primary !default;

$c-nav__back--sp-inset-h:                           $f-space--none !default;
$c-nav__back--block--color:                         $f-color__brand-primary--dark !default;
$c-nav__back--block--font-size:                     $f-font-size--s !default;
$c-nav__back--block--vertical-align:                -1px !default;
$c-nav__back--block--text-transform:                uppercase !default;

$c-nav__icon--block--right:                         $f-space--tiny !default;

$c-nav__link--block--color:                         $f-color__brand-primary !default;
$c-nav__link--block--hover--bg-color:               $f-color__brand-primary--ultra-light !default;
$c-nav__link--block--pressed--color:                $f-color__brand-primary--light !default;
$c-nav__link--block--active--bg-color:              $f-color__brand-primary--light !default;
$c-nav__link--block--active--font-weight:           400 !default;
$c-nav__link--block--children--padding-left:        $c-nav--sp-inset-left * 2.5 !default;


// List

$c-nav--list--sp-inset-h:                            $f-space--small !default;
$c-nav--list--sp-inset-v:                            $f-space--tiny * .75 !default;

$c-nav__icon--list--right:                           $f-space--tiny !default;
$c-nav__item--list--sp-stack:                        $f-space--tiny !default;

$c-nav__link--list--color:                           $f-color__brand-primary !default;
$c-nav__link--list--bg-color:                        $f-color__brand-primary--ultra-light !default;
$c-nav__link--list--hover--bg-color:                 $f-color__brand-primary--light !default;
$c-nav__link--list--pressed--color:                  $f-color__brand-primary--semi-light !default;

$c-nav__link-icon--list--padding-left:               $f-space--medium !default;


// Collapsible

$c-nav--collapsible--line-height:                     $f-line-height--small !default;
$c-nav--collapsible--sp-stack:                        $f-space--small !default;
$c-nav--collapsible--sp-inset-left:                   $f-space--medium !default;

$c-nav__list--collapsible--margin-top:                $c-nav--collapsible--sp-stack !default;

$c-nav__item--collapsible--has-child--font-size:      $c-nav--font-size !default;
$c-nav__item--collapsible--has-child--color:          $f-color__neutral-grey--dark !default;
$c-nav__item--collapsible--has-child--font-weight:    700 !default;
$c-nav__item--collapsible--has-child--hover--color:   $f-color__neutral-black !default;
$c-nav__item--collapsible--has-child--hover--font-weight: 700 !default;
$c-nav__item--collapsible--has-child--active--color:  $f-color__neutral-black !default;
$c-nav__item--collapsible--has-child--active--font-weight: 700 !default;

$c-nav__item--active--collapsible--font-weight:       700 !default;
$c-nav__item--active--collapsible--border-width:      4px !default;
$c-nav__item--active--collapsible--sp-inline-left:    6px !default;
$c-nav__item--active--collapsible--border-color:      $f-color__brand-primary !default;
$c-nav__item--active--collapsible--color:             $f-color__brand-primary !default;

$c-nav__item--collapsible--color:                     $c-nav__link--color !default;
$c-nav__link--collapsible--hover--color:              $f-color__brand-primary !default;

