/* -------------------------------------------------------------------------
 * SITE FOOTER
 */

$l-site-footer__bottom--bg-color:                         $f-color__brand-secondary--light !default;

$l-site-footer__main-title--font-weight:                  700 !default;

$l-site-footer__c-nav--sp-stack:                          $f-space--tiny !default;

$l-site-footer--link-hover--color:                        $f-color__brand-primary !default;
$l-site-footer--link--color:                              $f-color__brand-primary--dark !default;
$l-site-footer__main-title--text-transform:               unset !default;

$l-site-footer__item--boxed--icon-row--bg-color:          unset !default;
$l-site-footer__item--boxed--icon-row--sp-inset:          0px !default;
$l-site-footer__item--boxed--icon-row--item-sp-stack:     0px !default;