.fr-account-booking-edit-link {

  &:hover {
    text-decoration: none;

    .fr-account-booking-edit-link__text {
      text-decoration: underline;
    }
  }

  &__text {
    display: none;

    @include t-mq($from: "sm") {
      display: inline;
    }
  }
}
