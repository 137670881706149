/* -------------------------------------------------------------------------
* Video
*
*/
$c-video__breakpoint--tablet:                               $f-breakpoint--sm !default;
$c-video--transform-time-out:                               .3s !default;

// Title
$c-video--title-aligment:                                   center !default;
$c-video--title-padding-top:                                $f-space--large !default;
$c-video--title-padding-bottom:                             $f-space--medium !default;

// Subtitle
$c-video--subtitle-up-padding-bottom:                       $f-space--medium !default;
$c-video--subtitle-down-padding-top:                        $f-space--medium !default;
$c-video--subtitle-down-padding-bottom:                     $f-space--large !default;

// Thumbnail
$c-video--thumbnail-information-background-color:           rgba(255, 255, 255, 0.9) !default;
$c-video--thumbnail-info-name-padding-right:                $f-space--medium !default;
$c-video--thumbnail-info-name-padding-right-tablet:         $f-space--small !default;
$c-video--thumbnail--info-icon-wrapper-padding-left:        $f-space--medium !default;
$c-video--thumbnail--info-icon-wrapper-padding-right:       $f-space--medium !default;
$c-video--thumbnail--info-icon-wrapper-padding-right-tablet:$f-space--small !default;
$c-video--thumbnail--info-icon-size:                        18px  !default;
$c-video--thumbnail--info-icon-size-tablet:                 $f-space--medium !default;
$c-video--thumbnail--info-icon-margin-top:                  -30px !default;
$c-video--thumbnail--info-icon-margin-top-tablet:           -30px !default;
$c-video--thumbnail--info-icon-margin-bottom-tablet:        8px !default;
$c-video--thumbnail--info-icon-margin-bottom:               3px !default;
$c-video--thumbnail--info-icon-content-size:                57px !default;
$c-video--thumbnail--info-icon-content-size-tablet:         60px !default;
$c-video--thumbnail--info-icon-content-color:               $f-color__brand-secondary--semi-dark !default;
$c-video--thumbnail--info-icon-color:                       $f-color__neutral-white !default;
$c-video--thumbnail--info-icon-color-hover:                 $f-color__brand-secondary--dark !default;
$c-video--thumbnail--info-icon-center-left:                 50% !default;
$c-video--thumbnail--info-icon-center-top:                  50% !default;
