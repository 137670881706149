/* -------------------------------------------------------------------------
 * SIDE DRAWER MIXINS
 *
 */

@mixin promotion-image__gradient($direction, $gradient-colors) {
  background-image: linear-gradient(to $direction, $gradient-colors);
}

@mixin promotion-image__content-left($from-breakpoint, $from-lg-enabled) {
  @include t-mq($from: $from-breakpoint) {
    max-width: $c-promotion-image__contents--max-width--desktop;
    text-align: left;
    padding: 0 0 0 $c-promotion-image--padding--tablet;
  }

  @if($from-lg-enabled) {
    @include t-mq($from: "lg") {
      padding: 0 0 0 $c-promotion-image--padding--desktop;
    }
  }
}

@mixin promotion-image__content-right($from-breakpoint, $from-lg-enabled) {
  @include t-mq($from: $from-breakpoint) {
    max-width: $c-promotion-image__contents--max-width--desktop;
    text-align: right;
    padding: 0 $c-promotion-image--padding--tablet 0 0;
    right: 0;
    left: initial;
  }

  @if($from-lg-enabled) {
    @include t-mq($from: "lg") {
      padding: 0 $c-promotion-image--padding--desktop 0 0;
    }
  }
}

@mixin promotion-image__content-center($from-breakpoint) {
  @include t-mq($from: $from-breakpoint) {
    max-width: $c-promotion-image__contents-center--max-width--desktop;
    text-align: center;
    right: 0;
    left: 0;
    padding: 0;
    margin: 0 auto;
  }
}
