/* -------------------------------------------------------------------------
 * LOADER MIXINS
 *
 */

@mixin c-pagination__set-visible-pages($pages: $c-pagination__visible-pages) {
  $left : '';
  @for $i from -($pages+1) through 0 {
    $left : '#{$left}:not([data-index="#{$i}"])';
  }

  $center : '';
  @for $i from -$pages through $pages {
    $center : '#{$center}&[data-index="#{$i}"],';
  }

  $right : '';
  @for $i from 0 through ($pages+1) {
    $right : '#{$right}:not([data-index="#{$i}"])';
  }

  &:first-child,
  &:last-child,
  #{$center} {
    display: inherit
  }

  &:first-child#{$left},
  &:last-child#{$right} {
    &:before {
      content: '...';
      pointer-events: none;
      position: relative;
      top: $c-pagination__button-truncation--top;
    }
  }

  &:first-child#{$left} {
    margin-right: $c-pagination__button-truncation--margin-x;
    &:before {
      text-indent: ($c-pagination__button-truncation--space-left + $c-pagination__button-truncation--margin-x);
    }
  }

  &:last-child#{$right} {
    margin-left: $c-pagination__button-truncation--margin-x;
    &:before {
      text-indent: -($c-pagination__button-truncation--space-right + $c-pagination__button-truncation--margin-x);
    }
  }
}
