/* -------------------------------------------------------------------------
 * SWITCH COMPONENT
 *
 *
 *
 */

.c-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.c-switch__slider {
  position: relative;
  display: inline-block;
  min-width: $c-switch--width;
  width: $c-switch--width;
  height: $c-switch--height;
  top: 0;

  @include sp-inline($c-switch__slider--sp-inline);
}

.c-switch__checkbox {
  display: none;
}

.c-switch__circle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $c-switch--background-color;
  transition: all $c-switch--transition-duration $c-switch--transition;
  border-radius: $c-switch--radius;
  overflow: hidden;
}

.c-switch__circle:before {
  position: absolute;
  border-radius: 50%;
  content: "";
  height: $c-switch--circle-width;
  width: $c-switch--circle-width;
  top: $c-switch--inner-padding;
  left: $c-switch--inner-padding;
  background-color: $c-switch--circle-background-color;
  transition: all $c-switch--transition-duration $c-switch--transition;
  box-shadow: map_get($c-switch__circle--shadow, "shadow") rgba(map_get($c-switch__circle--shadow, "color"), map_get($c-switch__circle--shadow, "opacity"));
}

.c-switch__checkbox:checked + .c-switch__circle {
  background-color: $c-switch--color;
}

.c-switch__checkbox:checked + .c-switch__circle:before {
  transform: translateX($c-switch--translate);
}

/* States */
//is-disabled

.is-disabled.c-switch {
  opacity: $c-switch--disabled-opacity;
  pointer-events: none;
}

/* Variants */
//boxed

.c-switch.c-switch--boxed {
  background-color: $c-switch--boxed--background-color;
  @include sp-inset($c-switch--boxed__slider--sp-inset);
}

//full-width

.c-switch.c-switch--full-width,
.c-switch.c-switch--with-modal {
  width: 100%;
  justify-content: space-between;

  .c-switch__slider,
  .c-switch__container {
    margin-right: 0;
    margin-left: $c-switch__slider--sp-inline;
    order: 1;
  }

}

//with modal

.c-switch__container {
  display: flex;
  align-items: center;
}

.c-switch__icon {
  color: $c-switch__icon--color;

  &:hover {
    color: $c-switch__icon--color-hover;
  }
}

.c-switch__icon .c-btn__icon {
  height: $c-switch__icon--height;
  padding-left: $c-switch__icon--padding-left;

  &:before {
    font-size: $c-switch__icon--font-size;
  }
}
