/* -------------------------------------------------------------------------
* Favorite
*
*/

.c-favorite-collector {
  position: relative;
  padding-right: $c-favorite-collector__bubble--left;
  display: inline-block;

  &:hover {
    text-decoration: none;
  }

  &::after {
    content: attr(data-c-favorite-collector__count);
    position: absolute;
    background-color: $c-favorite-collector__bubble--bg-color;
    color: $c-favorite-collector__bubble--color;
    top: $c-favorite-collector__bubble--top;
    min-width: 1.3em;
    min-height: 1.3em;
    padding: 0 0.3em;
    line-height: 1.3;
    text-align: center;
    font-weight: $c-favorite-collector__bubble--font-weight;
    font-size: $c-favorite-collector__bubble--font-size;
    border-radius: 10em;
    will-change: transform;

    @include t-mq($from: $c-favorite-collector--bp) {
      left: calc(100% - #{$c-favorite-collector__bubble--left});
    }
  }

  &.is-incrementing {
    &::after {
      animation: like $f-duration--fast $f-easing--excite;
    }
  }

  &.is-decrementing {
    &::after {
      animation: unlike $f-duration--fast $f-easing--excite;
    }
  }
}

.c-favorite-collector__icon {
  font-size: 1.4em;
  line-height: 1;
}

.c-favorite-btn {
  position: relative;
  min-width: $c-favorite-btn--size;
  min-height: $c-favorite-btn--size;
  background-color: $c-favorite-btn--bg-color;
  color: $c-favorite-btn--color;
  text-align: center;
  border-radius: $c-favorite-btn--border-radius;
  box-shadow: $c-favorite-btn--shadow;
  transition: background-color $c-favorite-btn--duration;
  will-change: transform;
  animation: unlike $f-duration--fast $f-easing--excite;
  @include m-icon("heart");

  &::#{$m-icons__pseudo-element} {
    position: absolute;
    top: calc(50% - #{$c-favorite-btn__heart--font-size * 0.5});
    left: calc(50% - #{$c-favorite-btn__heart--font-size * 0.5});
    width: $c-favorite-btn__heart--font-size;
    height: $c-favorite-btn__heart--font-size;
    font-size: $c-favorite-btn__heart--font-size;
    will-change: transform;
    animation: none;
  }

  &:hover {
    background-color: $c-favorite-btn--bg-color--hover;
  }

  &.is-active {
    animation: like $f-duration--fast $f-easing--excite;
    &::#{$m-icons__pseudo-element} {
      content: map_get($m-icon__glyphs, "heart-fill");
      animation: like $f-duration--fast $f-easing--excite ($f-duration--fast * 1.25);
    }
  }
}

@keyframes like {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes unlike {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

/*----
* Modal
*
*/

.c-modal__favorites,
.w-search__favorites {
  .c-modal__favorites-footer-container,
  .w-search__favorites-footer-container {
    .c-modal__favorites-login-btn {
      width: 100%;
    }
    .c-modal__favorites-footer,
    .w-search__favorites-footer {
      display: flex;
      justify-content: center;
      align-items: baseline;
      flex-wrap: wrap;
      &::before{
        content: '';
        border: $c-modal__favorites-footer__before--border;
        margin-top: $c-modal__favorites-footer__before--margin;
        margin-bottom: $c-modal__favorites-footer__before--margin-bottom;   
        flex-basis: 100%;
      }
      .c-modal__favorites-footer-text,
      .w-search__favorites-footer-text {
        margin-bottom: 0;
        font-weight: 600;
      } 
      .c-modal__favorites-signup-btn,
      .w-search__favorites-signup-btn {
        color:$c-modal__favorites-signup-btn--color;
        text-decoration: underline;
        text-transform: none;
      }
    }
  }
}

.w-search__favorites{
  max-width: $w-search__favorites--max-width;
  display: block;
  margin: auto;
  text-align: center;
  .w-search__favorites__header{
    margin-bottom: $w-search__favorites__header--margin-bottom;
  }
  .w-search__favorites__body{
    margin-bottom: $w-search__favorites__body--margin-bottom;
  }
  .w-search__favorites-login-btn{
    width: $w-search__favorites-login-btn--width;
  }
}
