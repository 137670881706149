/* -------------------------------------------------------------------------
* BOOKING PARTICIPANTS BADGE VARIABLES
*
*/

$w-booking-participants-badge__email-section--marging-bottom:                       $f-space--medium !default;

$w-booking-participants-badge__email-field-row--margin-bottom:                      $f-space--tiny !default;
$w-booking-participants-badge__email-field-row--mq:                                 'xs' !default;

$w-booking-participants-badge__email-input--margin-right:                           $f-space--small !default;
$w-booking-participants-badge__email-input--sp-stack:                               $f-space--small !default;
$w-booking-participants-badge__email-input--label-overflow--mq:                     'md' !default;
$w-booking-participants-badge__email-input--mq:                                     'xs' !default;
$w-booking-participants-badge__email-input--width:                                  400px !default;

$w-booking-participants-badge__email-success-text--padding:                         $f-space--small !default;
$w-booking-participants-badge__email-success-text--mq:                              'xs' !default;

$w-booking-participants-badge__email-information-text--color:                       $f-color__neutral-grey--semi-dark !default;

$w-booking-participants-badge__participants-room-section--margin-bottom:            $f-space--medium;
$w-booking-participants-badge__participants-room-section-title--font-weight:        100;

$w-booking-participants-badge--email-save-button--padding-top:                      $e-form-elements__label--space-stack + ($e-form-elements__label--line-height * $e-form-elements__label--font-size);

$w-booking-participants-badge--collapse-btn--height:                                $f-space--small !default;
$w-booking-participants-badge--collapse-btn--margin-top:                            -1 * ($f-space--tiny + $f-space--small)/2 !default;
$w-booking-participants-badge--collapse-btn__icon--color:                           $f-color__neutral-black !default;
$w-booking-participants-badge--collapse-btn__icon--font-size:                       $f-font-size--xs !default;
$w-booking-participants-badge--collapse-btn__icon--height:                          $f-space--small !default;
$w-booking-participants-badge--collapse-btn__icon--padding-left:                    $f-space--small !default;

$w-booking-participants-badge--participants-header--bottom-width:                   1px !default;
$w-booking-participants-badge--participants-header--padding-bottom:                 $f-space--small !default;

$w-booking-participants-badge--collapse-section--margin-top:                        $f-space--medium !default;

$w-booking-participants-badge__participant-row--mq:                                 'md' !default;
