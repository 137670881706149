/* -------------------------------------------------------------------------
 * SIDE DRAWER
 */


$c-side-drawer--z-index:                                    map_get($f-z-indexes--usage, "c-side-drawer") !default;
$c-side-drawer--bg-color:                                   $e-document__body--bgcolor !default;
$c-side-drawer--direction:                                  "right" !default;

$c-side-drawer--breakpoints:                                ("sm", "sml", "md") !default;

$c-side-drawer--width:                                      100% !default;
$c-side-drawer--height:                                     $c-side-drawer--width;
$c-side-drawer--width--xs:                                  400px !default;

$c-side-drawer--sp-inset:                                   $f-space--medium !default;

$c-side-drawer__close--font-size:                           $f-font-size--l !default;


// Color schemes overrides
// -----------------------------

$c-side-drawer--bg-color--cs-brand-primary:                 $cs-brand-primary--background !default;
$c-side-drawer--pseudo-element--positioning:                20%;
