.fr-acco-practical-info__text-container {
  margin-bottom: $fr-acco-practical-info__text-container--sp-stack;
}

.fr-acco-practical-info__text-container--preview { 
  > * {
    display: none;
  }

  > *:nth-child(-n+4) {
    display: inherit;
  }

  > *:nth-child(4) {

    @include t-mq($from: $fr-acco-practical-info__text-container--preview--bp) {
      display: none;
    }

    @include t-mq($from: $fr-acco-practical-info__text-container--preview--bp-big) {
      display: inherit;
    }
  }
}


