/* -------------------------------------------------------------------------
* EXCURSIONS
*
*/

.w-excursions {
  display: flex;
  flex-direction: column;
}

.w-excursions__container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

.w-excursions__container:not(:empty) {
  margin: -$w-excursions__container--gap;
}

.w-excursions__container .c-card-price {
  width: 100%;
  margin: $w-excursions__container--gap;

  @include t-mq($from: $w-excursions__container--first-bp) {
    width: $w-excursions__card--width-first-bp;
  }

  @include t-mq($from: $w-excursions__container--second-bp) {
    width: $w-excursions__card--width-second-bp;
  }
}

[data-w-excursions__all-excursions-link] {
  margin-top: $w-excursions__all-excursions-link--margin-top;
  display: inline-block;
  align-self: start;
}

[data-w-excursions__error-message],
[data-w-excursions__empty-message],
[data-w-excursions__all-excursions-link] {
  display: none;
}

.w-excursions__loader {
  align-self: center;
}

.w-excursions .c-card-price__img img {
  width: auto;
  left: 50%;
  transform: translateX(-50%);
}

.w-excursions .c-card-price {
  .c-card-price__description {
    display: none;
  }

  .c-card-price__info {
    margin-bottom: 0;
  }

  .c-card-price__title {
    @include m-heading($w-excursions__card-title--heading);
    line-height: $w-excursions__card-title--line-height;
  }
}
