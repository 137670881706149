/* -------------------------------------------------------------------------
* COLLECTION TEASER VARIABLES
*
*/

$c-collection-teaser__usps-icon--color:                               $f-color__state-success !default;

$c-collection-teaser--padding-top:                                    15px !default;
$c-collection-teaser__label--offset-position:                         12px !default;
$c-collection-teaser__label--left-position:                           $c-collection-teaser__label--offset-position !default;
$c-collection-teaser__label--top-position:                            $c-collection-teaser--padding-top + $c-collection-teaser__label--offset-position !default;
$c-collection-teaser__label--z-index:                                 $f-z-index--1 !default;

$c-collection-teaser--large__label--left-position:                    $f-space--small !default;

