// Smart columns mixin
// --------------------------------------------------

@if ($o-layout--enabled and $o-layout__smart--enabled) {

  @each $_size_name, $_columns_map in $o-layout__smart-columns {

    .o-layout--smart-#{$_size_name} {

      @each $_bp-name, $_columns_number in $_columns_map {
        @if ($_bp-name != "xxs") {

          @include t-mq($from: $_bp-name) {

            @if ($_columns_number != 1) {

              .o-layout__item {
                width: (1 / $_columns_number) * 100% !important;
              }

            }
          }
        }
      }
    }
  }
}
