/* -------------------------------------------------------------------------
 * MAIN MENU
 *
 */

$w-main-menu--bq:                                                                   $f-breakpoint--md !default;
$w-main-menu--bq--xl:                                                               $f-breakpoint--xl !default;
$w-main-menu--bq--lg:                                                               $f-breakpoint--lg !default;

$w-main-menu--z-index:                                                              $f-z-index--4 !default;
$w-main-menu--border-style:                                                         solid !default;
$w-main-menu--border-width:                                                         1px !default;
$w-main-menu--border-color:                                                         $f-color__neutral-grey--semi-light !default;
$w-main-menu--background-color:                                                     $e-document__body--bgcolor !default;
$w-main-menu--transition-duration:                                                  $f-duration--normal !default;
$w-main-menu--mobile--box-shadow:                                                   $f-shadow--discrete !default;

$w-main-menu__logo-desktop--inset-v:                                                16px !default;
$w-main-menu__logo-desktop--width:                                                  120px !default;
$w-main-menu__logo-mobile--width:                                                   118px !default;

$w-main-menu__items-wrapper--padding-left:                                          $f-space--small !default;
$w-main-menu__items-wrapper--padding-side-h:                                        6px !default;
$w-main-menu__items-wrapper--padding-side-v:                                        $f-space--small !default;
$w-main-menu__items-wrapper--font-family:                                           $f-font-family__secondary !default;

$w-main-menu--icon-color:                                                           $f-color__neutral-black !default;

$w-main-menu__favourites-icon--size:                                                6px !default;
$w-main-menu__favourites-icon--adjustment:                                          6px !default;
$w-main-menu__favourites-icon--line-height:                                         $f-space--medium !default;

$w-main-menu__mobile-list--padding-top:                                             $f-space--medium !default;
$w-main-menu__mobile-list--margin-bottom:                                           $f-space--medium !default;
$w-main-menu__mobile-item--is-active--background-color:                             unset !default;
$w-main-menu__mobile-item-has-child--is-active--color:                              $e-document__body--color !default;
$w-main-menu__mobile-item--is-active--color:                                        $e-links__hover-color !default;
$w-main-menu__mobile--extra-items--border-color:                                    $w-main-menu--border-color !default;
$w-main-menu__mobile--extra-items--border-width:                                    2px !default;
$w-main-menu__mobile--go-back--padding-left:                                        $f-space--small + $f-space--tiny  !default;
$w-main-menu__mobile--go-back--line-height:                                         $f-line-height--large !default;

$w-main-menu__item-toggler--spacing:                                                -5px;

$w-main-menu__font-weight--bold:                                                    600 !default;
$w-main-menu__font-size:                                                            $f-font-size--m !default;
$w-main-menu__item--link--font-weight:                                              500 !default;

$w-main-menu__arrow-icon--font-size:                                                $f-font-size--xl !default;
$w-main-menu__arrow-icon--padding-right:                                            16px !default;

$w-main-menu__desktop--inside--easing:                                              $f-easing--base !default;
$w-main-menu__desktop--height--bq:                                                  48px !default;
$w-main-menu__desktop--inside--left-area--inset-v:                                  $f-space--medium !default;
$w-main-menu__desktop--inside--background-color:                                    $f-color__neutral-grey--light !default;
$w-main-menu__desktop--inside--background-adjustment:                               550px !default;
$w-main-menu__desktop--inside--background-z-index:                                  $f-z-index--negative !default;

$w-main-menu__item--margin-height:                                                  -3px;
$w-main-menu__item--is-active--background-color:                                    $f-color__neutral-grey !default;
$w-main-menu__item--is-active--color:                                               $e-document__body--color !default;
$w-main-menu__item--border-radius:                                                  $f-radius--rounded !default;
$w-main-menu__item--has-child--icon--padding-right:                                 $f-space--tiny !default;
$w-main-menu__item--has-child--chevron-adjustment:                                  $f-space--tiny + $f-space--small !default;
$w-main-menu__item--has-child--chevron-font-size:                                   $f-font-size--xs !default;

$w-main-menu__item-mobile--inset-v:                                                 $f-space--tiny !default;
$w-main-menu__item-mobile--line-height:                                             $f-line-height--large !default;
$w-main-menu__item-mobile--font-family:                                             $f-font-family__secondary !default;
$w-main-menu__item-mobile--border:                                                  2px solid transparent !default;

$w-main-menu__desktop--inside--max-height:                                          90vw !default;
$w-main-menu__desktop--inside--max-height--bq:                                      90vw !default;

$w-main-menu__side-drawer-background-color:                                         $e-document__body--bgcolor !default;
$w-main-menu__side-drawer--inset-h:                                                 $f-space--medium !default;
$w-main-menu__side-drawer-box-shadow-color:                                         $f-shadow__color !default;
$w-main-menu__side-drawer-box-shadow-opacity:                                       $f-shadow__opacity !default;
$w-main-menu__side-drawer-box-shadow:                                               inset 0 10px 8px -7px rgba($w-main-menu__side-drawer-box-shadow-color, $w-main-menu__side-drawer-box-shadow-opacity) !default;

$w-main-menu__action-item--favourites--color:                                       $e-document__body--color !default;
$w-main-menu__action-item--favourites--font-size:                                   $f-font-size--s !default;
$w-main-menu__action-item--favourites--top:                                         3px !default;
$w-main-menu__action-item--favourites--color-hover:                                 $e-links__hover-color !default;

$w-main-menu__desktop--inside--left-area--item--border-radius:                      $f-radius--rounded !default;
$w-main-menu__desktop--inside--left-area--item--inset-v:                            $f-space--small !default;
$w-main-menu__desktop--inside--left-area--item--inset-h:                            $f-space--small + $f-space--tiny !default;
$w-main-menu__desktop--inside--left-area--margin-top:                               $f-space--tiny !default;

$w-main-menu__desktop--inside--title--inset-v:                                      $f-space--small !default;

$w-main-menu__desktop--inside--item--background-color:                              $f-color__neutral-grey--semi-light !default;
$w-main-menu__desktop--middle-column--item--icon--adjustment:                       2px !default;

//12 column grid adjustments
$w-main-menu__12-column-grid--column-gap:                                           2rem !default;

