//Colors
$c-product-card--grey:                                                       $f-color__neutral-grey--semi-dark !default;
$c-product-card--light-grey:                                                #EBEBEB !default;
$c-product-card--white:                                                      $f-color__neutral-white !default;
$c-product-card--black:                                                      $f-color__neutral-black !default;

//Breakpoints
$c-product-card-breakpoint--sm:                                               $f-breakpoint--sm !default;
$c-product-card-breakpoint--md:                                               $f-breakpoint--md !default;
$c-product-card-breakpoint--lg:                                               $f-breakpoint--lg !default;
//Space
$c-product-card--space:                                                       16px;
$c-product-card--space-tiny:                                                  $c-product-card--space / 4 !default;
$c-product-card--space-small:                                                 $c-product-card--space / 2 !default;
$c-product-card--space-large:                                                 $c-product-card--space * 2 !default;

$c-product-card--f-space:                                                     $f-space !default;
$c-product-card--f-space--small:                                              $f-space--small !default;
$c-product-card--f-space--tiny:                                               $f-space--tiny !default;


$c-product-card--max-width-sm:                                                385px !default;
$c-product-card--max-width-md:                                                790px !default;
$c-product-card--border:                                                      1px solid $c-product-card--light-grey !default;
$c-product-card--border-raidus:                                               $c-product-card--space-small !default;
$c-product-card--font-size-sm:                                                12px !default;
$c-product-card--font-size-s:                                                 $f-font-size--s !default;
$c-product-card--shadow:                                                      0px 4px 16px 0px rgba(0, 0, 0, 0.07) !default;

$c-product-card__content-header--padding:                                     $c-product-card--f-space $c-product-card--space-small $c-product-card--space $c-product-card--f-space;

$c-product-card__services--padding:                                           $c-product-card--space 21px !default;
$c-product-card__services--grid-row:                                          30px !default;
$c-product-card__services--grid-row--md:                                      32px !default;
$c-product-card__services--font-size:                                         $f-font-size--s !default;
$c-product-card__services--column-gap:                                        4px !default;

$c-product-card__bullet-list-icon--font-size:                                 20px !default;

$c-product-card__price--padding-sm:                                           $c-product-card--space 21px !default;
$c-product-card__price--padding-md:                                           $c-product-card--space 21px $c-product-card--space 12px !default;
$c-product-card__price--mid-width:                                            260px !default;
$c-product-card__price--font-size:                                            $f-font-size--xxl !default;
$c-product-card__price-old-price--margin-right:                               $c-product-card--f-space--tiny !default;

$c-product-card__cta--font-size:                                              $f-font-size--s !default;
$c-product-card__cta--sp-inset-v:                                             $f-space--small !default;
$c-product-card__cta--sp-inline:                                              -1 * $f-space--tiny !default;
$c-product-card__cta--z-index:                                                $f-z-index--1 !default;

$c-product-card__usps--font-size:                                             $f-font-size--s !default;
$c-product-card__usps--sp-stack:                                              $f-space--tiny !default;
$c-product-card__usp-item--sp-stack:                                          $f-space--tiny !default;

$c-product-card__urgency-messages--font-size:                                 $f-font-size--xs !default;
$c-product-card__urgency-message--sp-stack:                                   $c-product-card--space-small !default;
$c-product-card__urgency-message-item--sp-stack:                              $f-space--tiny !default;
$c-product-card__urgency-message-icon--color:                                 $f-color__brand-primary !default;
$c-product-card__urgency-message-margin-top:                                  $f-space--tiny !default;

$c-product-card__mostbooked-icon--sp-inline:                                  $f-space--tiny !default;

$c-product-card__rating--color:                                               $f-color__state-warning--semi-light !default;
$c-product-card__rating--sp-stack:                                            $f-space--small !default;
$c-product-card__rating--padding-bottom:                                      10px !default;

$c-product-card__name--sp-inline:                                             60px !default;
$c-product-card__name--padding:                                               $c-product-card--space !default;
$c-product-card__name--padding-bottom:                                        $c-product-card--space-small !default;
$c-product-card__name--padding-top:                                           $c-product-card--space-small !default;

$c-product-card__breadcrumbs--color:                                          $f-color__neutral-grey--semi-dark !default;
$c-product-card__breadcrumbs--sp-stack:                                       $f-space--small !default;

$c-product-card__action-wrapper--margin:                                      $f-space--small $f-space--small 0 $f-space--small;
$c-product-card__action--border-width:                                        1px !default;
$c-product-card__action--border-style:                                        solid !default;
$c-product-card__action--font-size:                                           $f-space--small !default;
$c-product-card__action--font-weight:                                         700 !default;
$c-product-card__action--sp-inset:                                            $f-space--tiny !default;
$c-product-card__action--padding-right:                                       $f-space--medium !default;
$c-product-card__action--padding-left:                                        $f-space--small !default;
$c-product-card__action--duration:                                            $f-duration--normal !default;
$c-product-card__action--index:                                               $f-z-index--1 !default;
$c-product-card__action--color:                                               $f-color__state-info !default;
$c-product-card__action-font-family:                                          $f-font-family__primary !default;
$c-product-card__action-line-height:                                          10px !default;
$c-product-card__action-max-width:                                            250px !default;
$c-product-card__action--border-radius:                                       4px !default;

$c-product-card__action-icon--font-size:                                      $f-font-size--m !default;
$c-product-card__yield--height:                                               $c-product-card--space-large !default;

$c-product-card__collection--left:                                            $f-space--small !default;
$c-product-card__collection--top:                                             $f-space--tiny !default;
$c-product-card__collection--z-index:                                         $f-z-index--1 !default;
$c-product-card__collection--top--from-mq:                                    $f-space--small !default;
$c-product-card__collection--top--from-mq:                                    $f-space--small !default;
$c-product-card__collection--font-size--until-mq:                             $f-font-size--s !default;

$c-product-card__image-wrapper--sp-stack--from-mq:                            $f-space--tiny !default;
$c-product-card__image-wrapper--z-index:                                      $f-z-index--2 !default;
$c-product-card__image-wrapper--width-sm:                                     260px !default;
$c-product-card__image-wrapper--width-md:                                     200px !default;
$c-product-card__image-wrapper--width-lg:                                     300px !default;
$c-product-card__image-wrapper--bp:                                           1100px !default;

$c-product-card__footer--width:                                               265px !default;

$c-product-card__favourite--box-shadow:                                       0px 2px 6px rgba(0, 0, 0, 0.08) !default;
$c-product-card__favourite--border:                                           1px solid $f-color__neutral-grey--light !default;
$c-product-card__favourite--color:                                            $f-color__state-danger !default;

$c-product-card__unavailable-title--font-size:                                $f-font-size--l !default;
$c-product-card__unavailable-title--font-weight:                              700 !default;
$c-product-card__unavailable-text--max-width:                                 70ch !default;
$c-product-card__unavailable-button--max-width:                               200px !default;

$c-product-card_mostbooked--font-size:                                        $f-font-size--s !default;
$c-product-card_mostbooked-icon--margin-right:                                $c-product-card--space / 4 !default;
$c-product-card_mostbooked-icon--font-size:                                   $f-font-size--xs !default;

$c-product-card__action-wrapper--margin--top:                                 $c-product-card--f-space--tiny !default;
