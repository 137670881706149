/* -------------------------------------------------------------------------
 * SEARCH
 *
 */

.w-search {
  @include sp-stack($f-space--large);
  text-align: center;
}

// Left and its content
.w-search__left {
  text-align: left;

  @include t-mq($until: $w-search--mq) {
    padding-top: 0;
  }
}

.w-search__box {
  position: relative;
  @include sp-stack($w-search__box--sp-stack);
  padding-top: $w-search__box--sp-inset-v;
  padding-bottom: 0;

  @include t-mq($until: $w-search--mq-tiny) {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0;
  }

  &.is-empty {
    display: none;
  }
}

.w-search__box-content {
  > *:first-child:not(.c-collapse) {
    padding-top: $w-search__box-content--padding-top;
  }
  .c-checkbox.c-choice-list__option {
    margin-left: $w-search__c-checkbox--margin-left;
  }
  .e-form-label {
    @include e-form-label--strong();
    line-height: $w-search__box-content-label--line-height;
  }
  .c-checkbox__text {
    font-size: $w-search__c-checkbox-text--font-size;
  }
  .filter-description {
    margin-bottom: $w-search__filter-description--margin-bottom;
  }
  .c-floating-box__body-content > .c-choice-list > .c-choice-list__options > .c-checkbox.c-choice-list__option {
    margin-left: 0px;
  }
}

.w-search__box-title {
  font-weight: $w-search__box-title--font-weight;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: color $w-search__c-collapse-trigger--duration;
  @include sp-stack($f-space--none);
  @include t-text-crop-disable();
}

.w-search__box-collapse-trigger {
  position: absolute;
  color: $w-search__collapse-trigger--color;
  top: $w-search__box--sp-inset-v;
  left: $w-search__box--sp-inset-h;
  right: $w-search__box--sp-inset-h;
  width: calc(100% - #{($w-search__box--sp-inset-h * 2)});
  margin: $f-space--none;
  text-align: right;

  &:hover {
    color: $w-search__collapse-trigger--color;
  }
}

.w-search__filters {
  &.is-disabled {
    .c-chip,
    .c-dropdown__arrow,
    .o-swipe-btn {
      pointer-events: none;
    }
  }

  .c-side-drawer__header {
    @include sp-stack($f-space--none);
  }

  .c-side-drawer__close {
    @include t-mq($until: $w-search--mq-tiny) {
      right: $w-search__c-side-drawer__close--right;
    }
  }

  .w-participants-filter,
  .w-participants-filter .w-search__box,
  .c-side-drawer__body-content.c-side-drawer__body-content {
    @include t-mq($until: $w-search--mq-tiny) {
      @include sp-inset-h($f-space--none);
    }
  }
  // Hide empty filters
  .w-filter.is-empty {
    display: none;
  }

  .c-dropdown__element:focus,
  .c-dropdown__element.is-focused,
  .c-textbox__element:focus,
  .c-textbox__element.is-focused {
    box-shadow: none;
    border-color: $e-form-elements__field--border-color !important;
  }

  .c-dropdown__element,
  .c-textbox__element {
    cursor: pointer;
  }
}

.w-search__box-collapse,
.w-search__filter-collapse {
  margin-left: -1 * $w-search__box--sp-inset-h;
  margin-right: -1 * $w-search__box--sp-inset-h;
  @include sp-inset-h($w-search__box--sp-inset-h);

  > *:first-child {
    padding-top: $w-search__box-content--padding-top;
  }
}

.w-search__box-collapse {
  > .w-search__filter-collapse:not(:last-child):not(.has-empty-child) {
    margin-bottom: $w-search__filter-collapse--mb;
  }
  > .w-search__filter-collapse {
    > .w-filter {
      padding-top: 0;
    }
  }
}
.w-search__box-collapse:not(.in) {
  margin-bottom: $w-search__box--sp-stack;
}

.w-search__filter-collapse {
  > .w-filter {
    margin-top: 2px;
    margin-bottom: 0;
  }

  + .w-search__filter-collapse-trigger,
  + .w-search__filter-collapse-trigger + .w-search__filter-collapse-trigger {
    position: relative;
    margin-bottom: 0;
    top: $w-search__filter-collapse-trigger--top;
  }
}
.w-filter + .w-search__filter-collapse > .w-filter {
  padding-top: 0;
}

.w-search__box-content > .w-filter:last-child {
  margin-bottom: $w-search__filter-collapse--mb;
}
.w-search__box-content:first-child {
  padding-top: $w-search__box-content--padding-top;
}
// Main Header and its content

.w-search__header {
  display: flex;
  flex-direction: column;
}

.w-search__header-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: $f-space;

  &.w-search__tooltip-row{
    justify-content: end;
  }
  .w-search__popularity-tooltip {
    display: none;
  }

  @include t-mq($from: $f-breakpoint--md, $until: $w-search--mq-tablet) {
    flex-direction: column;
    align-items: center;
    .w-search__header-btns {
      margin: 0 auto;
    }
    .w-search__header-results-filters-wrapper {
      align-items: center;
    }
  }
  @include t-mq($from: $w-search--mq) {
    justify-content: space-between;
    flex-direction: row;
    align-items: baseline;
    .w-search__popularity-tooltip {
      display: flex;
      align-items: center;
    }
  }

  .w-search__header-results-title {
    margin: 0;
    align-self: flex-end;
    font-family: $f-font-family__secondary;
  }

  .w-search__header-btns {
    display: flex;
    align-items: flex-end;

    @include t-mq($until: $w-search--mq-xs) {
      flex-wrap: wrap;
      gap: $w-search__filters-action--gap;
      .w-search__map-action-btn {
        margin-right: 0;
      }
    }
    .c-btn {
      text-transform: capitalize;
    }

    .w-search__map-action-btn, .w-search__filters-action-btn {
      flex-basis: 100%;
      border-color: $f-color__neutral-black;
      color: $f-color__neutral-black;
    }

    .w-search__sort .c-dropdown__icon {
      color: $f-color__neutral-black;
    }

    .w-search__map-action-btn {
      margin-right: 1rem;
    }

    .w-search__sort {
      .c-dropdown__element{
        @extend .c-btn;
        @extend .c-btn--neutral;
        border-color: $f-color__neutral-black;
        padding-top: $w-search__sort--dropdown-element-padding;
        padding-bottom: $w-search__sort--dropdown-element-padding;

        &:focus {
          box-shadow: none;
        }

        padding-left: $f-space--large;
        padding-right: $f-space--large;
      }

    .c-dropdown__icon.m-icon--order {
      left: $f-space;
    }

    .c-dropdown__arrow {
      right: $f-space;
    }
  }

    .w-search__filters-action-btn {
      @include t-mq($from: $w-search--mq) {
        display: none;
      }
    }
  }
}

.w-search__header-item {
  @include t-mq($from: $w-search--mq) {
    flex-grow: 1;
  }
}

.w-search__header-item:first-child {
  flex: 1 0 50%;
}

.w-search__collection-teaser {
  text-align: left;
  margin-top: $w-search__collection-teaser--margin-top;
  @include sp-stack($w-search__collection-teaser--sp-stack);
}

.w-search__header-results-filters-wrapper {
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: column;
  gap: $f-space / 4;

  @include t-mq($from: $w-search--mq) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .w-search__available-results {
      margin-left: 0;
    }
  }
  .w-search__header-results-title {
    font-weight: 600;
    font-size: 28px;
    align-self: flex-end;
  }
}

.w-search__results-number {
  justify-content: flex-start;
  display: flex;
  align-items: baseline;

  .m-body {
    font-weight: $w-search__results-number--font-weight;
  }

  @include t-mq($until: $w-search--mq) {
    align-self: center;
    flex-direction: column;
    align-items: center;
  }
}

.w-search__available-results {
  font-size: $w-search__available-results__font-size;
  opacity: 0.5;

  @include t-mq($until: $w-search--mq) {
    margin-top: 4px;
  }

  @include t-mq($from: $w-search--mq) {
    margin-left: $w-search__available-results__margin-left;
  }
}

.w-search__clear-wrapper {
  display: inline-block;
}

.w-search__clear {
  margin-top: $w-search__clear--margin;
  margin-left: $w-search__clear--margin;
}

.w-search__clear,
.c-choice-list__dynamic-clear-btn {
  .c-btn__text {
    font-size: $w-search__clear--font-size;
  }
}

.w-search__sort {
  @include t-mq($from: $w-search--mq) {
    max-width: $w-search__sort--max-width--from-mq;
    display: inline-block;
    margin-left: auto;
    flex: 0 0 auto;
    align-self: flex-end;
  }

  @include t-mq($until: $w-search--mq) {
    display: none;
  }

  .c-dropdown__element:focus,
  .c-dropdown__element.is-focused {
    box-shadow: none;
    border-color: $e-form-elements__field--border-color !important;
  }
}

.w-search__box .w-search__sort {
  @include t-mq($from: $w-search--mq) {
    max-width: initial;
    display: block;
  }

  @include t-mq($until: $w-search--mq) {
    display: block;
  }

  margin-bottom: $f-space;
}

.w-search__sort-group {
  @include t-mq($from: $w-search--mq) {
    display: none;
  }
}

.w-search__clear,
.c-choice-list__dynamic-clear-btn {
  border: 0;
  color: $w-search__clear__color;
  text-transform: none;
  line-height: inherit;
  flex: 0 0 auto;
  @include sp-inset($f-space--none);

  @include t-mq($from: $w-search--mq) {
    display: block;
  }

  &:hover {
    color: $w-search__clear__color--hover;
  }

  &.hide {
    display: none;
  }
}

.w-search__selected-filters {
  display: flex;
  align-items: center;
  margin-top: 8px;

  @include t-mq($from: $w-search--mq) {
    margin-right: $w-search__selected-filters--margin-right;
  }

  &.is-empty {
    display: none;
  }
}

.w-search__selected-chips {
  text-align: left;

  .c-chip {
    text-transform: none;
    margin-top: $w-search__selected-chips--margin-top;
  }

  .c-chip.c-chip--removable {
    margin-right: $w-search__selected-chips--margin-right;
  }
  display: inline-block;
}

// Main Body and its content
.w-search__main > .w-search__main {
  padding-left: 0;
}

.w-search__main.o-layout__item {
  padding-top: $w-search__main-layout--padding-top;
}

.w-search__right.o-layout__item {
  padding-top: $w-search__right-layout--padding-top;
}

.w-search__main-body {
  position: relative;
  height: auto;

  .c-collection-teaser,
  .c-search-result {
    @include sp-stack($w-search__result--sp-stack);

    @include t-mq($from: $w-search--mq-small) {
      @include sp-stack($w-search__result--sp-stack--from-mq-small);
      .c-search-result__content {
        width: 75%;
      }
      .c-search-result__footer {
        width: 25%;
      }
    }

    &:after {
      @include t-mq($from: $w-search--mq-small) {
        content: "";
        position: absolute;
        display: block;
        height: 0;
        left: 0;
        right: 0;
        border-top: $w-search__result--border-width--from-mq-small $w-search__result--border-style--from-mq-small
          $w-search__result--border-color--from-mq-small;
        bottom: $w-search__result--border-bottom--from-mq-small;
      }
    }
  }

  .c-collection-teaser {
    display: block;
  }
}

.w-search__results-loader {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $w-search__results-loader--z-index;
  background-color: rgba($w-search__results-loader--bgcolor, $w-search__results-loader--opacity);
}

.w-search__results > h2 {
  text-align: center;
  font-size: $w-search__results-loader-text--font-size;
  line-height: $w-search__results-loader-text--line-height;
}

.w-search__loader {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 35vh;
}

.w-search__loader.c-brand-loader {
  margin: auto;
}

// Main footer and its content
.w-search__main-footer {
  text-align: center;
  .c-pagination {
    &:not(.is-loaded) {
      display: none;
    }
  }
}
// No favorites message
// -----------------------------
.w-search__no-favorites {
  text-align: center;
}

.w-search__no-favorites-title {
  font-weight: $w-search__no-favorites-title--font-weight;
  @include sp-stack($w-search__no-favorites-title--sp-stack);
}

.w-search__no-favorites-text {
  @include sp-stack($w-search__no-favorites-text--sp-stack);
}

.w-search[data-favorite] .c-search-result__footer,
.w-search[data-favorite] .c-product-card__footer{
  display: none !important;
}

.w-search__results-message {
  text-align: center;

  .c-btn {
    width: auto;
    white-space: nowrap;
  }
}

.w-search__results-message__title {
  font-weight: $w-search__results-message__title--font-weight;
  @include sp-stack($w-search__results-message__title--sp-stack);
}

.w-search__results-message__subtitle {
  @include sp-stack($w-search__results-message__subtitle--sp-stack);
}

.w-search__results-message,
.w-search__results-message .btn {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

// Results
// -----------------------------
.w-search__results {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

// Only Results
// -----------------------------
.w-search[data-w-search__results-only] {
  &.is-loading {
    .w-search__pagination {
      visibility: hidden;

      &.is-loaded {
        visibility: visible;
      }
    }
  }

  .w-search__main-body {
    min-height: $w-search__result--w-search__main-body--only-results--min-height;
  }

  .w-search__loader {
    top: 50%;
  }
}
// Loading State
// -----------------------------
.w-search.is-loading {
  .w-search__results-number,
  .w-search__sort,
  .w-search__pagination,
  .w-search__selected-filters,
  .w-search__popularity-tooltip  {
    opacity: 0.5;
    pointer-events: none;
  }

  .w-search__results-loader {
    display: block;
  }
}

//Acco tips
//-----------------------------
.w-search__results > .w-search-acco-tips {
  @include sp-stack($w-search__results-acco_tips--sp-stack);

  @include t-mq($from: $w-search--mq-small) {
    &:after {
      content: "";
      position: relative;
      display: block;
      height: 0;
      left: 0;
      right: 0;
      border-top: $w-search__result--border-width--from-mq-small $w-search__result--border-style--from-mq-small
        $w-search__result--border-color--from-mq-small;
      bottom: $w-search__results-acco_tips--border-bottom--from-mq-small;
    }

    @include sp-stack($w-search__results-acco_tips--sp-stack--from-mq-small);
  }

  .acco_tips-header {
    text-align: left;
    font-size: $w-search__results-acco_tips-title--font-size;
    line-height: $w-search__results-acco_tips-title--line-height;
    color: $w-search__results-acco_tips-title--color;
    font-weight: $w-search__results-acco_tips-title--font-weight;
    margin-bottom: $w-search__results-acco_tips-title--margin-bottom;
  }

  .c-breadcrumbs--unclickable {
    .c-breadcrumbs__link {
      color: $w-search__results-acco_tips__c-breadcrumbs__link--color;
    }
  }

  .c-slider__content {
    padding-top: $w-search__acco-tips-slider--padding-top;
  }
}

.w-search__banner:empty {
  margin: 0;
}

.w-search__top-filters {
  background-color: $w-search__top-filters-color;
  padding: 12px 0px 12px 0px;
  position: sticky;
  top: 0;
  z-index: 98;
  border: 1px solid $w-search__top-filters-border-color;

  @include t-mq($until: $f-breakpoint--md) {
    padding-bottom: 0;
  }

  .w-quick-search__filters-top {
    text-align: left;
  }

  .c-textbox__label.e-form-label {
    display: none;
  }

  .w-participants-filter-on-top {
    .c-textbox__element,
    .c-textbox__icon,
    .c-textbox__arrow {
      color: $w-search__top-filters-text-color !important;
    }

    .c-textbox__arrow {
      font-size: 16px;
    }
    .c-textbox__icon {
      font-size: 22px;
      top: 2px;
    }
  }

  @include t-mq($from: $f-breakpoint--xs) {
    .c-floating-box {
      max-height: calc(100vh - 85px);
    }
    .c-floating-box__body {
      overflow-x: visible;
      overflow-y: auto;
      flex-shrink: 1;
    }
  }
}

.w-search__top-filters.w-quick-search {
  margin-bottom: $f-space;
  justify-content: center;
  display: block;
  padding-bottom: $f-space/2;
  .w-search__top-filters__wrapper {
    margin: auto;
    .w-search__top-filters__collapse {
      @include t-mq($from: $f-breakpoint--md) {
        display: none;
      }
      display: flex;
      justify-content: flex-end;
      color: $w-search__top-filters-text-color;
      margin-bottom: $f-space/2;
      .w-search__top-filters__collapse--text {
        @include t-mq($until: $f-breakpoint--md) {
          display: none;
        }
      }
      .w-search__top-filters__collapse--icon {
        margin-left: $f-space/4;
      }

      .w-search__top-filters__collapse--container {
        cursor: pointer;
      }
    }
    .w-search__top-filters__collapse.close {
      pointer-events: none;
    }

    & fieldset {
      @include t-mq($from: $w-search--mq) {
        max-width: $w-search__top-filter--max-width;
      }
    }
  }
  .w-search__top-filters__wrapper.close {
    display: none;
  }

  .w-search__top-filters__collapsed--container {
    .w-search__top-filter__minimal {
      display: inline-block;
      padding-right: $f-space/2;
      .w-search__top-filter__minimal--icon {
        position: static;
        background-color: transparent;
        color: $w-search__top-filters-text-color;
        font-size: 20px;
        padding-right: 3px;
        line-height: 1.1;
        transform: none;
      }
      .w-search__top-filter__minimal--text {
        color: $w-search__top-filters-text-color;
        vertical-align: text-top;
        line-height: 1.1;
      }
    }
  }

  .w-filter.is-empty {
    display: none;
  }
}

.w-search {
  .c-floating-box {
    z-index: 97;
  }
}

.filters_on_top__collapse {
  display: none;
}

.w-search__top-filters__wrapper.collapsed-top-filters {
  display: flex;
  justify-content: space-between;
  .w-search__top-filters__collapse {
    @include t-mq($from: $f-breakpoint--md) {
      display: flex !important;
    }
    order: 3;
    margin-bottom: 2px !important;
    align-self: flex-end;
    .w-search__top-filters__collapse--container {
      flex-wrap: nowrap;
      display: flex;
      .w-search__top-filters__collapse--text {
        display: flex;
        margin-right: 6px;
      }
      .w-search__top-filters__collapse--icon {
        display: flex;
      }
    }
  }
  .w-search__top-filters__collapsed--container {
    display: flex;
    flex-wrap: wrap;
    order: 1;
  }
}
.w-search__top-filters__overlay {
  display: none;
}
.w-search__top-filters__overlay.open {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}

.t-searchpage {
  .l-site-main {
    max-width: 100vw;
  }
  .l-site-main.w-search__top-filters__body {
    padding-top: 0px;
  }
}

.w-search__top-filters__loader {
  margin: auto;
  display: none;
}
.w-search__top-filters__loader.open {
  display: block;
}

// Selected filters
.w-search__selected-filters-container.hide {
  display: none;
}
.w-search__top-filters-summary {
  padding: $w-search__top-filters-summary--padding;
  margin-bottom: $w-search__top-filters-summary--margin-small;
  display: flex;
  order: 1;
  flex-wrap: wrap;

  .w-search__top-filter__minimal {
    margin-right: $w-search__top-filters-summary--margin-small;
    display: inline-block;
  }

  .w-search__top-filter__minimal--icon {
    position: relative;
    transform: none;
    top: 0;
    left: 0;
    font-size: $w-search__top-filters-summary--icon-font-size;
  }
  .w-search__top-filter__minimal--text {
    font-size: $w-search__top-filters-summary--text-font-size;
  }

  &:empty {
    display: none;
  }
}

.w-search__selected-filters-container .w-search__selected-filters:not(.is-empty) {
  padding: $w-search__selected-filters-container--margin-top;
  padding-top: 0;
  padding-bottom: 0;
}

.w-search__selected-filters-container .w-search__selected-filters {
  margin-top: 0;
  margin-right: 0;
}

.w-search__selected-filters-container .w-search__clear {
  padding: $w-search__selected-filters-container-clear-button--margin-top;
}

.w-search__selected-filters-container.is-empty {
  display: none;
}

.w-search__filters .w-search__selected-filters-container {
  margin-bottom: $f-space--small;
}

.w-search__selected-filters-container .w-search__selected-filters {
  flex-wrap: wrap;
}

.w-search .w-search__selected-filters-container {
  display: block;
  @include t-mq($from: $f-breakpoint--md) {
    display: none;
  }
}

.w-search-map__search-filters .w-search__selected-filters-container {
  @include t-mq($from: $f-breakpoint--md) {
    display: block;
  }
}
//usps

.w-search .fr-content-usps {
  margin-top: $w-search__map-action-btn--margin-top;
  margin-bottom: $w-search__map-action-btn--margin-bottom;
}

// Search map

.w-search__map-action .c-btn {
  width: $w-search__map-action-btn--width;
  margin-bottom: $w-search__map-action-btn--margin-bottom;
}

.w-filter--choice-list[data-is-dynamic="true"] {
  .c-radio-button__label {
    font-size: 14px;
    .c-radio-button__element {
      flex: 0 0 27px;
      &:before {
        left: 0px;
      }
      &:after {
        left: $c-radio-button__label-after--left;
      }
    }
  }
}

.w-search__header-message-wrapper {
  padding-top: $w-search__main-layout--padding-top;
  .c-box.c-search-message__box {
    padding: $f-space--small;

    &.cs-state-info--light {
    .c-search-message__text b{
      color: $f-color__state-info--semi-dark;
    }
  }
  }
}

.w-search__popularity-tooltip {
  @include t-mq($until: $f-breakpoint--md) {
    top: $w-search__popularity-tooltip__until-md--top;
    margin: 0;
  }
  margin-top: $w-search__selected-filters--margin-top;
  display: flex;
  align-items: center;
  .c-tooltip__icon{
    margin-left: $w-search__selected-filters--margin-top;
    font-size: $w-search__popularity-tooltip__icon--size;
    line-height: $w-search__popularity-tooltip__icon--size;
  }
  .w-search__popularity-tooltip__label{
    font-size: $w-search__popularity-tooltip__label--size;
    font-weight: $w-search__popularity-tooltip__label--weight;
  }
  .w-search__popularity-tooltip__label,
  .c-tooltip__icon{
    color: $f-color__neutral-grey;
    &:hover{
      color:$f-color__neutral-grey--semi-dark;
    }
  }
  &:after,
  &:before{
    bottom: $w-search__popularity-tooltip--bottom !important;
  }
  &:before{
    line-height: $w-search__popularity-tooltip__line-height;
    z-index: $w-search__popularity-tooltip__before--z-index;
  }
}

