/* -------------------------------------------------------------------------
* Permission settings
*
*/

.w-permission-settings__title {
  display: flex;
  align-items: center;
  margin-bottom: $w-permission-settings__title--margin-bottom;

  .m-icon {
    margin-bottom: $w-permission-settings__title-icon--margin-bottom;
    @include t-mq($from: $w-permission-settings--bp) {
      margin-right: $w-permission-settings__title-icon--margin-right;
      margin-bottom: 0;
    }
  }

  @include t-mq($until: $w-permission-settings--bp) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.w-permission-settings__intro {
  margin-bottom: $w-permission-settings__intro--margin-bottom;
}

.w-permission-settings .c-form__fieldset-title-container {
  margin-bottom: $w-permission-settings__form-fieldset-title--margin-bottom;
}

.w-permission-settings .m-heading {
  line-height: $w-permission-settings__heading--line-height;
  @include t-text-crop-disable()
}

.w-permission-settings {
  .c-form__fieldset-container .c-form__item:not(:last-child) {
    padding-bottom: $w-permission-settings__form-item--padding-bottom;
    border-bottom: $w-permission-settings__form-item--border-bottom;
  }

  .c-form__fieldset-container > .w-permission-settings__form-field--choice-list {
    margin-bottom: 0;
  }

  .c-form__item {
    padding-left: 0;
    margin-left: $w-permission-settings__form-field-items--margin-left;
  }

  .c-form__item.c-textbox {
    padding-right: $w-permission-settings__form-field-items--margin-left;
  }

  .c-form__fieldset:not(:last-child) {
    margin-bottom: $w-permission-settings__form-fieldset--margin-bottom;
  }
}

.w-permission-settings__form-field-extra-info,
.w-permission-settings__form-field {
  margin-left: $w-permission-settings__form-field-items--margin-left;
}

.w-permission-settings__form-field {
  margin-top: $w-permission-settings__form-field-items--v-margin;
}

.w-permission-settings__form-field {
  width: 100%;
}

.w-permission-settings__form-field--checkbox {
  margin-left: $w-permission-settings__form-field-checkbox--margin-left;
  width: unset;
}

.w-permission-settings__form-field--choice-list .c-checkbox {
  margin-left: $w-permission-settings__form-field-choice-list-checkbox--margin-left;
}

.w-permission-settings__form-field--checkbox:not(:first-child) {
  margin-top: 0;
}

.w-permission-settings__form-field-extra-info {
  margin-bottom: $w-permission-settings__form-field-extra-info--margin-bottom;
  margin-top: $w-permission-settings__form-field-extra-info--margin-top;
}

.w-permission-settings__extra-info {
  text-align: left;
  margin-top: $w-permission-settings__extra-info--margin-top;
  margin-bottom: $w-permission-settings__extra-info--margin-bottom;
  color: $w-permission-settings__extra-info--color;
  font-size: $w-permission-settings__extra-info--font-size;
}
