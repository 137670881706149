/* -------------------------------------------------------------------------
 * DROPDOWN
 *
 */

//  
// --------------------------------------------------

// Fix the issue with choice-list hover effect inside a dropdown content
.c-dropdown-multiple {
  .c-floating-box__body-content {
    left: -$c-dropdown-multiple__floating-box-body-content--spacing;
    padding-left: $c-dropdown-multiple__floating-box-body-content--spacing;
    margin-right: -$c-dropdown-multiple__floating-box-body-content--spacing;
  }
  @each $_breakpoint in $c-floating-box--breakpoints {
    @include t-mq($from: $_breakpoint) {
      .c-floating-box__body-content {
        left: -$c-dropdown-multiple__floating-box-body-content--spacing;
        padding-left: $c-dropdown-multiple__floating-box-body-content--spacing;
        margin-right: -$c-dropdown-multiple__floating-box-body-content--spacing;
      }
    }
  }
}