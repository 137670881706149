/* -------------------------------------------------------------------------
* Actionable list Variables
*
*/

$c-actionable-list--breakpoint-tablet:                            $f-breakpoint--sm !default;
$c-actionable-list__item--border-size:                            $e-form-elements__field--border--size !default;
$c-actionable-list__item--border-color:                           $f-color__neutral-grey--semi-light !default;
$c-actionable-list__item--font-weight:                            400 !default;
$c-actionable-list__item--color:                                  $f-color__neutral-black !default;

$c-actionable-list__item--selected--color:                        $f-color__state-info !default;
$c-actionable-list__item--selected--bg-color:                     $f-color__neutral-grey--ultra-light !default;

$c-actionable-list__item--selected__mark--color:                  $f-color__state-info--semi-dark !default;
$c-actionable-list__item--selected__mark--bg-color:               transparent !default;

$c-actionable-list__item--secondary-color:                        $cs-default--text--discrete !default;
$c-actionable-list__item--secondary-font-size:                    map-deep-get($m-body__mod-sizes, "tiny", "font-size") !default;

$c-actionable-list__item__room-onrequest--font-size:              $f-font-size--xxs !default;
$c-actionable-list__item__room-onrequest--font-family:            $f-font-family__secondary !default;
$c-actionable-list__item__room-onrequest--color:                  $f-color__neutral-grey--semi-dark !default;
$c-actionable-list__item__room-onrequest--border-color:           $f-color__neutral-grey--semi-dark !default;

$c-actionable-list__item__room-availability--border-color:        $f-color__state-warning--dark !default;
$c-actionable-list__item__room-availability--color:               $f-color__state-warning--dark !default;
$c-actionable-list__item__room-availability--font-size:           $f-font-size--xxs !default;
$c-actionable-list__item__room-availability--font-weight:         700 !default;

$c-actionable-list__item__room-stock--border-type:                solid !default;
$c-actionable-list__item__room-stock--border-size:                1px !default;
$c-actionable-list__item__room-stock--border-radius:              $f-radius--medium !default;
$c-actionable-list__item__room-stock--padding-sides:              $f-space--small !default;

$c-actionable-list__item__room-description--font-size:            $f-font-size--s !default;
$c-actionable-list__item__room-price-legend--color:               $f-color__neutral-grey !default;
$c-actionable-list__item__room-price-currency--font-size:         14px !default;
$c-actionable-list__item__room-price-value--font-size:            18px !default;

$c-actionable-list__item-link--sp-inset:                          $f-space--small !default;