.fr-destination-page__description-with-slider {
  @include t-clearfix;
}
.fr-destination-page__description-with-slider__slider {
  width: 100%;
  @include sp-stack($fr-destination-page__description-with-slider__slider--sp-stack);
  @include o-media__img();
  @include t-mq($from: $fr-destination-page__description-with-slider__slider--bp) {
    width: $fr-destination-page__description-with-slider__slider--width--from-bp;
    @include sp-inline($fr-destination-page__description-with-slider__slider--sp-inline);
  }
  @include t-mq($from: $fr-destination-page__description-with-slider__slider--bp-big) {
    width: $fr-destination-page__description-with-slider__slider--width--from-bp-big;
  }
}