/* -------------------------------------------------------------------------
 * HOMEPAGE
 *
 */

.t-homepage {
  .l-site-main__content {
    & > [class^="fr-"] {
      > .c-title {
        text-align: center;
        @include sp-stack($t-homepage__c-title--sp-stack);
      }

      > .c-breadcrumbs {
        text-align: center;
        display: block;
      }
    }
  }
}

@import 'fr-content-usps--homepage';
@import 'fr-highlighted-video--homepage';
@import 'fr-inspiring-images--header';
@import 'fr-product-ads--homepage';