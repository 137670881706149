/* -------------------------------------------------------------------------
 * INSET STRETCH MIXINS
 *
 */

// Space as a mixin
// --------------------------------------------------

@mixin sp-inset-stretch($_spacing-value) {
  padding: $_spacing-value ($_spacing-value * .5);
}
