/* -------------------------------------------------------------------------
 * SEARCH RESULT VARS
 */

// General
$c-search-result--bg-color:                                 $f-color__neutral-white !default;
$c-search-result--border-radius:                            $f-radius--none !default;
$c-search-result--border-color:                             $f-color__neutral-grey--semi-light !default;

// Header and its content
$c-search-result__image--sp-stack--from-mq:                 $f-space--none !default;
$c-search-result__header--padding:                          $f-space--small 0 $f-space--small $f-space--small !default;

// Body and its content
$c-search-result__body--sp-stack:                           $f-space !default;
$c-search-result__body--sp-stack--small:                    $f-space--small !default;
$c-search-result__rating--color:                            $f-color__brand-secondary !default;
$c-search-result__rating--sp-stack:                         $f-space--small !default;
$c-search-result__name--font-weight:                        700 !default;
$c-search-result__breadcrumbs--sp-stack:                    3px !default;
$c-search-result__mostbooked--sp-stack:                     0 !default;
$c-search-result__score--top--from-mq:                      $f-space--small !default;
$c-search-result__mostbooked--color:                        $f-color__state-success !default;

//Image
$c-search-result__image--cover-offset:                      50% !default;

// Footer and its content
$c-search-result__footer--max-width--from-mq:               230px !default;
$c-search-result__footer--bg-color:                         $f-color__neutral-white !default;
$c-search-result__footer--border-color:                     $f-color__neutral-grey--semi-light !default;
$c-search-result__footer--border-left:                      solid 1px $f-color__neutral-grey--semi-light !default;
$c-search-result__footer--shadow--from-mq:                  unset !default;

//usps
$c-search-result__bullet-list-icon--color:                  $f-color__state-success !default;
$c-search-result__usps--sp-stack:                           $f-space--tiny + $f-space--small !default;
$c-search-result__usps--sp-stack--small:                    $f-space--small !default;


$c-search-result--shadow-size--from-mq:                     $f-shadow--discrete !default;
$c-search-result--shadow-color--from-mq:                    rgba($f-shadow__color, $f-shadow__opacity--discrete) !default;
$c-search-result--shadow-color--hover:                      $f-color__neutral-grey !default;
$c-search-result--shadow-transition:                        box-shadow $f-duration--normal $f-easing--base !default;
