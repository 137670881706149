/* -------------------------------------------------------------------------
 * ICON-ROW
 */

$c-icon-row__line-height:                             $f-line-height--tiny !default;
$c-icon-row__padding:                                 $f-space--tiny !default;
$c-icon-row__padding--inline:                         $f-space--tiny * .5 !default;

// Icon modifier
$c-icon-row__icon-size--icon:                         map_get($m-icons__sizes, "medium") !default;


// Color schemes overrides
// -----------------------

$c-icon-row__link--color--cs-default--light:          $cs-default--text !default;
$c-icon-row__link--color-hover--cs-default--light:    $f-color__brand-primary--semi-dark !default;

//links

$c-icon-row__link-hover--color:                       $e-links__color !default;
