// Arrow variant
.c-box--arrow {
  position: relative;
  box-shadow: $c-box--arrow--shadow;
  margin-top: $c-box--arrow--arrow-size;

  &::before,
  &::after {
    bottom: 100%;
    left: $c-box--arrow--arrow-left-position;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::before {
    border-bottom-color: inherit;
    border-width: $c-box--arrow--arrow-size + $c-box--border-width;
    margin-left: 0;
  }

  &::after {
    border-width: $c-box--arrow--arrow-size;
    margin-left: $c-box--border-width;
  }
}

.c-box--arrow-title {
  margin-bottom: $c-box--arrow-title--margin-bottom;
}

.c-box--arrow-bottom {
  &::before,
  &::after {
    bottom: inherit;
    top: 100%;
  }
  &::before {
    border-bottom-color: transparent;
    border-top-color: inherit;
  }
  &::after {
    border-bottom-color: transparent;
  }
}

.c-box--arrow-left {
  &::before,
  &::after {
    bottom: inherit;
    top: $c-box--arrow--arrow-left-position;
    right: 100%;
    left: inherit;
  }
  &::before {
    border-bottom-color: transparent;
    border-right-color: inherit;
  }
  &::after {
    border-bottom-color: transparent;
    margin-left: 0;
    margin-top: $c-box--border-width;
  }
}
