/* -------------------------------------------------------------------------
 * NAV
 *
 */

 // Component output
// ------------------------------
.c-nav {
  font-weight: $c-nav--font-weight;
}
