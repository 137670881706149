/* -------------------------------------------------------------------------
 * SEARCH MAP
 *
 */

.w-search-map__modal {
  width: $w-search-map__modal--width;
  height: $w-search-map__modal--height;

  @include t-mq($until: $w-search-map--first-bp) {
    min-width: $w-search-map__modal--min-width--from-bp;
    min-height: $w-search-map__modal--min-height--from-bp;
  }
}

.w-search-map__modal-container {
  display: flex;
  align-items: stretch;
  height: 100%;
}

.w-search-map__modal .c-modal__body {
  padding: 0;
  height: 100%;

  &::before {
    margin-bottom: 0;
  }

  &::after {
    margin-top: 0;
  }
}

.w-search-map__modal .c-modal__body {
  padding: 0;
}

.w-search-map__left-wrapper {
  width: $w-search-map__search-results--width;
}

.w-search-map__search-results,
.w-search-map__search-filters {
  position: relative;
  height:100%;

  @include t-mq($until: $w-search-map--second-bp) {
    display: none;
  }
}

.w-search-map__search-filters.in {
  display: block;
}

.w-search-map__search-results__showing-destinations {
    display: none;
}

.w-search-map .c-side-drawer__body .c-side-drawer__body-content {
  padding-left: 0;
  padding-right: 0;
}

.w-search-map .c-side-drawer__header,
.w-search-map .c-side-drawer__footer {
  @include t-mq($until: $w-search-map--second-bp) {
    padding: $w-search-map__side-drawer-footer-header--padding;
  }
}

.w-search-map .w-search__filters {
  .c-floating-box {
    right: 0;
    min-width: unset;
    padding-left: $w-search-map__search-results-container--sp;
    padding-right: $w-search-map__search-results-container--sp;
  }

  .w-search__box {
    padding-left: $w-search-map__search-results-container--sp;
    padding-right: $w-search-map__search-results-container--sp;
    margin-bottom: $w-search-map__search-results-container--sp;
  }

  .w-search__box-collapse,
  .w-search__filter-collapse {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.w-search__filters-hide {
  display: none;
  height: 0px;
}

.w-search__results-hide {
  display: none;
  height: 0px;
}

.w-search-map__search-filters-container{
    height: 100%;
    overflow: scroll;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .w-search__sort-group{
      display: none;
    }
}

.w-search-map__search-results-container {
  @include sp-inset($w-search-map__search-results-container--sp);
  padding-top: 0px;
  position: absolute;
  overflow: scroll;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &:empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $w-search-map__search-results-container-empty--padding;
    text-align: center;

    &:before,
    &:after {
      transform: translateY($w-search-map__search-results-container--offset);
    }

    &:before {
      @extend .m-heading--small;
      content: attr(data-no-results-title);
      display: block;
      font-family: $w-search-map__search-results-container-no-results-title--font-family;
      font-size: $w-search-map__search-results-container-no-results-title--font-size;
      font-weight: $w-search-map__search-results-container-no-results-title--font-weight;
      margin-bottom: $w-search-map__search-results-container-no-results-title--margin-bottom;
    }

    &.w-search-map__search-results-container-no-accommodation-results{
      &:after {
        content: attr(data-no-results-text);
        display: block;
      }
    }

    &:not(.w-search-map__search-results-container-no-accommodation-results){
      &:after {
        content: attr(data-no-results-destination-text);
        display: block;
      }
    }
  }
}

.w-search-map__search-result:not(:last-child) {
  margin-bottom: $w-search-map__search-result--margin-bottom;
}

.w-search-map__search-result {
  &:hover:not(.c-search-result--condensed-tiny),
  &:hover:not(.c-search-result--condensed-tiny) .c-search-result__footer,
  &--highlighted,
  &--highlighted .c-search-result__footer {
    background-color: $w-search-map__search-result--bg-color--hover;
  }

}

.w-search-map__search-result:hover:not(.c-search-result--condensed-tiny),
.w-search-map__search-result--highlighted {
  border: $w-search-map__search-result--border--hover;
}

.w-search-map__interactive-map {
  flex-grow: 1;
}

.w-search-map__interactive-map.c-map-interactive {
  max-height: unset;
  height: 100%;
  &[disabled] {
    opacity: .6;
    pointer-events: none;
  }
}

.w-search-map__results-loader {
  position: absolute;
  bottom: $w-search-map__results-loader--bottom;
  right: $w-search-map__results-loader--right;
  transform: $w-search-map__results-loader--transform;
}

.w-search-map__loader-container {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &.active {
    display: block;
  }
}

.w-search-map__loader {
  position: absolute;
  transform: $w-search-map__loader-translate;
  left: 50%;
  top: 50%;

  @include t-mq($until: $w-search-map--second-bp) {
    transform: $w-search-map__loader-translate--until-bp;
  }
}

.w-search-map__info-window-container {
  background-color: $w-search-map__info-window-container--color;
  position: absolute;
  bottom: $w-search-map__info-window-container--bottom;
  left: $w-search-map__info-window-container--left;
  right: $w-search-map__info-window-container--right;

  @include t-mq($from: $w-search-map--first-bp) {
    left: $w-search-map__info-window-container--left--from-bp;
    transform: translateX(-50%);
    right: unset;
    width: $w-search-map__info-window-container--width--from-bp;
  }
}
// Google Info Window
.gm-style-iw.gm-style-iw-c {
  transform: translate(20px, 40px)
}

.gm-style-iw-d {
  display: table;
}

.gm-style-iw.gm-style-iw-c,
.gm-style-iw-d {
  padding: 0;
}

.gm-style-iw-a {
  .c-price__info-icon{
      display: none;
  }
}


.gm-style .gm-style-iw-t::after {
  background: none;
  box-shadow: none;
}

.w-search-map__modal {
  .c-modal__header {
    background-color: transparent;
    position: absolute;
    right: $w-search-map__modal-header--right;
    top: $w-search-map__modal-header--top;
    z-index: $w-search-map__modal-header--z-index;
    padding: 0;
  }

  .c-modal__close {
    position: initial;
    top: initial;
    right: initial;
    transform: initial;
  }

  .c-modal__header .c-btn {
    background-color: $w-search-map__modal-header-btn--bg-color;
    border: $w-search-map__modal-header-btn--border;
    border-radius: $w-search-map__modal-header-btn--border-radius;
    height: $w-search-map__modal-header-btn--measure;
    width: $w-search-map__modal-header-btn--measure;
    position: absolute;
    top: 0;
    right: 0;

    @include t-mq($until: $w-search-map--first-bp) {
      width: $w-search-map--modal-header-btn--measure--until-first-bp;
      height: $w-search-map--modal-header-btn--measure--until-first-bp;
    }

    &:hover {
      border-color: $w-search-map__btn--border-color--hover;
    }
  }

  .c-modal__header .c-btn__icon {
    color: $w-search-map__btn--color;
    height: initial;
    padding-left: initial;


    &:before {
      position: initial;
      top: initial;
      font-size: $w-search-map__modal-header-btn-icon--font-size;
      @include t-mq($until: $w-search-map--first-bp) {
        font-size: $w-search-map__modal-header-btn-icon--font-size--until-first-bp;
      }
    }
  }
}

.w-search-map__modal.c-modal {
  max-width: $w-search-map__modal--max-width;

  @include t-mq($until: $w-search-map--first-bp) {
    min-width: $w-search-map__modal--min-width--from-bp;
  }
}

.w-search-map__modal .c-modal__header-text {
  padding-right: 0;
}

.w-search__modal-on-open-map{
    z-index:1001;
}

.w-search__modal__backdrop-on-open-map{
    z-index:1000;
}

.w-search-map__search-result.c-search-result--condensed-tiny {
  box-shadow: $w-search-map__search-result-condensed-tiny--box-shadow;

  .c-breadcrumbs__item:not(:last-child){
    display: none;
  }
}

//google map

.w-search-map__modal {
  .gmnoprint {
    @include t-mq($until: $w-search-map--second-bp) {
      display: none;
    }
  }

  .gm-ui-hover-effect {
    top: 0px !important;
    right: 0px !important;
  }
}

.w-search-map .c-search-result--condensed-tiny .c-search-result__content {
  display: flex;
}

.w-search-map__mobile-filters-btn {
  position: absolute;
  left: 12px;
  border: $w-search-map__modal-header-btn--border;
  border-color: $w-search-map__mobile-filters-btn--bg-color;
  border-radius: $w-search-map__modal-header-btn--border-radius;
  height: $w-search-map__modal-header-btn--measure;
  width: $w-search-map__modal-header-btn--measure;
  z-index: 1;
  margin-top: 12px;

  @include t-mq ($from: $w-search-map--second-bp) {
    display: none;
  }

  @include t-mq($until: $w-search-map--first-bp) {
    width: $w-search-map--modal-header-btn--measure--until-first-bp;
    height: $w-search-map--modal-header-btn--measure--until-first-bp;
  }

  &.c-btn--icon-only .c-btn__icon {
    padding-left: unset;
    height: auto;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &.c-btn--icon-only .c-btn__icon:before {
    left: unset;
    font-size: $w-search-map__filters-btn--font-size;
    @include t-mq($until: $w-search-map--first-bp) {
      font-size: $w-search-map__filters-btn--font-size--until-first-bp;
    }
  }

  &,
  &[disabled],
  &:focus {
    background-color: $w-search-map__mobile-filters-btn--bg-color;
  }

  &:hover {
    background-color: $w-search-map__mobile-filters-btn--bg-color-hover;
  }

  &:active {
    background-color: $w-search-map__mobile-filters-btn--bg-color-active;
  }

}

.w-search-map__refresh-btn {
  position: fixed;
  left: 50%;
  z-index: 1;
  transform: translateX(calc(-50% + #{$w-search-map__search-results--width / 2}));
  display: none;
  width: auto;
  margin-top: $w-search-map__refresh-btn--margin-top;
  // Reposition the loader in front of the text when submit-btn is loading and lower the text's opacity to give more feedback to the user

  @include t-mq($until: $w-search-map--second-bp) {
    margin-top: $w-search-map__refresh-btn--margin-top--from-bp;
  }

  .c-btn__icon {
    @include t-mq($until: $w-search-map--first-bp) {
      display: none;
    }
  }

  @include t-mq($until: $w-search-map--first-bp) {
    font-size: $w-search-map__refresh-btn--font-size--until-first-bp;
    padding: $w-search-map__refresh-btn--padding--until-first-bp;
    min-width: $w-search-map__refresh-btn--min-width--until-first-bp;
  }

  &.c-btn--loading .c-btn__text {
    opacity: 0.4;
  }

  .c-loader {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    height: unset;

    &:before {
      position: absolute;
      left: 50%;
      animation-name: c-loader-refresh-button;
      transform-origin: center;
    }
  }

  @include t-mq($until: $w-search-map--second-bp) {
    transform: translateX(-50%);
  }

  &.w-search-map__refresh-btn__showing-destinations {
    left: 50%;
  }
}

.w-search-map__left-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  @include t-mq($until: $w-search-map--second-bp) {
    display: none;

    &.in{
      display: flex;
    }
  }
}

.w-search-map__left-wrapper .c-button-group {
  padding: $w-search-map__search-results-container--sp;
  display: none;
}

@keyframes c-loader-refresh-button {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}

.w-search-map__filters-backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.5;
  z-index: 2;
  @include t-mq($from: $w-search-map--second-bp) {
    display: none;
  }
}
