/* -------------------------------------------------------------------------
 * Booking coupon code
 *
 */

.w-booking-coupon-code__main-container {
  @include t-mq($from: $w-booking-coupon-code--breakpoint-tablet) {
    width: $w-booking-coupon-code__main-container--full-width;

  }
  @include t-mq($from: $w-booking-coupon-code--breakpoint-desktop) {
    width: $w-booking-coupon-code__main-container--medium-width;
  }

  margin-left: $w-booking-coupon-code__main-container--left-margin;
  margin-top: $w-booking-coupon-code__main-container--top-margin;
}

.w-booking-coupon-code{
  .c-textbox.has-success .c-textbox__icon {
    color: $w-booking-coupon-code--success--color;
  }
}

.w-booking-coupon-code__message {
  font-size: $f-font-size--s;
}

.w-booking-coupon-code__message--is-success {
  color: $w-booking-coupon-code--success--color;
  display: none;
}

.w-booking-coupon-code__message--is-error {
  color: $w-booking-coupon-code--error--color;
  display: none;
}

.w-booking-coupon-code__state-accepted {
  .w-booking-coupon-code__message--is-success {
    display: inherit;
  }
}

.w-booking-coupon-code__state-rejected {
  .w-booking-coupon-code__message--is-error {
    display: inherit;
  }
}
