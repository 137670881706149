/* -------------------------------------------------------------------------
 * COLUMNS MIXINS
 *
 */

// Utility mixin
// --------------------------------------------------

@mixin u-columns($_columns, $_bp-suffix: null) {

  @each $_denominator in $_columns {

    .u-columns--#{$_denominator}#{$_bp-suffix} {
      column-count: $_denominator !important;
    }
  }
}
