/* -------------------------------------------------------------------------
 * BULLET LIST COLOR SCHEMES
 *
 */

.cs-brand-primary {
  .c-side-drawer {
    &:not([class*="c-side-drawer--until"]) {
      background-color: $c-side-drawer--bg-color--cs-brand-primary;
    }
  }
}
