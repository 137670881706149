/* -------------------------------------------------------------------------
 * LOGIN FORM
 */

.w-login-form {
  max-width: $w-login-form--max-width;
  margin-left: auto;
  margin-right: auto;
}

.w-login-form__content {
  text-align: center;
}

.w-login-form__logo.w-login-form__logo {
  @include sp-stack($w-login-form__logo-img--margin-bottom);
}

[data-w-login-form__view="password-forgoten"] {
  .w-login-form__logo.m-icon {
    color: $f-color__neutral-grey;
  }
}


[data-w-login-form__view="login"],
[data-w-login-form__view="register"] {
  .w-login-form__logo.m-icon {
    color: $w-login-form__logo--color;
  }
}

.w-login-form__title {
  @include sp-stack($w-login-form__title--margin-bottom);
}

.w-login-form__body {
  > *:last-child {
    margin-bottom: 0;
  }
}

.w-login-form__form-validation-message {
  color: $f-color__state-danger;
  font-family: $w-login-form__form-validation-message--font-family;
  font-size: $w-login-form__form-validation-message--font-size;
  text-align: left;
}

@if($c-form--sp-stack > $w-login-form__form-validation-message--margin-top) {
  .c-form__section + .w-login-form__form-validation-message {
    margin-top: -($c-form--sp-stack - $w-login-form__form-validation-message--margin-top);
  }
}

.w-login-form {
  .c-form__fieldset-container {
    @include c-form__fieldset-container--sp-stack-children($w-login-form__space-stack-children);
  }
}

.w-login-form__switcher--bottom {
  margin-left: auto;
}

.w-login-form__switcher--top {
  text-align: left;

  .c-btn {
    padding-left: 0;
    margin-left: $w-login-form__switcher--top--margin-left;
  }
}

.w-login-form__privacy-statement {
  color: $w-login-form__privacy-statement--color;
  margin-top: $w-login-form__privacy-statement--margin-top;
  font-size: $w-login-form__privacy-statement--font-size;
}

[data-w-login-form__view] {
  display: none;
}

[data-w-login-form__current-view="login"] {
  [data-w-login-form__view="login"] {
    display: block;
  }
}

[data-w-login-form__current-view="password-forgoten"] {
  [data-w-login-form__view="password-forgoten"] {
    display: block;
  }
}

[data-w-login-form__current-view="register"] {
  [data-w-login-form__view="register"] {
    display: block;
  }
}

.w-login-form__messages .c-absolute-dialog {
  margin-bottom: $w-login-form__messages--margin-bottom;
}

.w-login-form__checkbox .c-checkbox__label {
  border: 2px solid $f-color__neutral-grey--light;
  border-radius: $f-radius--large;
}

.w-login-form__checkbox .c-checkbox__text-wrapper {
  padding: $w-login-form__checkboxes-form-item--padding;
  padding-left: 0;
}

.w-login-form__checkboxes-container {
  display: none;
}

.w-login-form__checkboxes-container,
.w-login-form__checkbox-container {
  flex-basis: 100%;
}

.w-login-form__checkboxes-container .c-form__item {
  padding-left: $w-login-form__checkboxes-form-item--padding-left;
}

.c-form__fieldset-container .w-login-form__checkboxes-container .c-form__item {
  margin-top: $w-login-form__checkboxes-form-item--margin-top;
}
