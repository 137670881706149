/* -------------------------------------------------------------------------
 * SIMPLE FORM
 *
 */

.t-simple-form {
  // Position of form in top of image,
  // except in small devices, where we hide image.
  // --------------------------------------------------
  .l-site-main__top {
    position: relative;

    @include t-mq($from: $t-simple-form__background-image--bp) {
      &:first-child {
        min-height: $t-simple-form__background-image--min-height;
      }
    }
  }

  .fr-simple-form-background-image {
    display: none;

    @include t-mq($from: $t-simple-form__background-image--bp) {
      display: block;
    }

    .fr-simple-form-background-image--content {
      @include sp-inset-stretch($t-simple-form__background-image-content--padding);
    }
  }

  .fr-simple-form {
    margin-bottom: $fr-simple-form__margin-bottom;

    @include t-mq($from: $t-simple-form__background-image--bp) {
      margin-top: $t-simple-form__margin-top--medium;
    }

    @include t-mq($from: $t-simple-form__background-image--bp-sml) {
      margin-top: $t-simple-form__margin-top--large;
      margin-bottom: $fr-simple-form__margin-bottom--from-bp-sml;
    }

    > .t-simple-form__form-container {
      max-width: $t-simple-form__form-container--max-width;
      margin-left: auto;
      margin-right: auto;
      z-index: $t-simple-form__form-container--z-index;

      @include t-mq($until: $t-simple-form__background-image--bp) {
        @include c-box--disable();
        @include sp-inset-h($t-simple-form__form-container--inset--until-bp);
        margin-top: $t-simple-form__form-container--sp-inset-top;
      }

      @include t-mq($from: $t-simple-form__background-image--bp) {
        position: relative;
        top: $t-simple-form__form-container--sp-inset-top--from-bp;
      }
    }
  }

  // Style actual form content
  // --------------------------------------------------
  .t-simple-form__form-container {
    .t-simple-form__title {
      margin-bottom: $t-simple-form__title--margin-bottom;
    }

    .t-simple-form__icon, .t-simple-form__title {
      text-align: center;
    }

    .t-simple-form__icon {
      @include sp-stack($t-simple-form__icon--sp-inset-bottom);
    }

    .t-simple-form__body {
      > *:last-child {
        margin-bottom: 0;
      }

      .t-simple-form__body-intro {
        @include sp-stack($t-simple-form__body-intro-sp-inset-bottom);
        @include sp-stack-children($t-simple-form__body-intro-sp-stack);
      }

      .t-simple-form__email {
        font-weight: $t-simple-form__email--font-weight;
      }
    }

    .t-simple-form__form-validation-message {
      color: $f-color__state-danger;
      font-family: $t-simple-form__form-validation-message--font-family;
      font-size: $t-simple-form__form-validation-message--font-size;
      margin-top: $t-simple-form__form-validation-message--margin-top;
      text-align: left;
    }

    .c-form__fieldset-container {
      @include c-form__fieldset-container--sp-stack-children($w-login-form__space-stack-children);
    }
  }

  // Variants depending on form status
  // --------------------------------------------------
  .t-simple-form__form-container {
    .t-simple-form__icon {
      color: $t-simple-form__default-icon--color;
    }
  }

  .t-simple-form__form-container--failed {
    .t-simple-form__icon {
      color: $t-simple-form__failed-icon--color;
    }
  }

  .t-simple-form__form-container--succeeded .t-simple-form__icon {
    color: $t-simple-form__succeeded-icon--color;
  }
}
