/* -------------------------------------------------------------------------
 * Booking generic summary
 *
 */

.w-booking-participant-summary__table {
  border-bottom-width: $w-booking-participant-summary__table--border-bottom-width;
  border-bottom-style: $w-booking-participant-summary__table--border-bottom-style;
  border-bottom-color: $w-booking-participant-summary__table--border-bottom-color;

  .w-booking-participant-summary__table-item {
    &:last-child {
      margin-bottom: $w-booking-participant-summary__table-last-child--margin-bottom;
    }
  }

  .c-breakdown-table__item-left {
    flex-grow: $w-booking-participant-summary__table-item-left--flex-grow;
    flex-basis: $w-booking-participant-summary__table-item-left--flex-basis;
  }
}

.w-booking-participant-summary__heading-extra-content-title {
  margin-top: $w-booking-participant-summary__heading-title--margin-top;
}

.w-booking-participant-summary__heading-title {
  margin-bottom: $w-booking-participant-summary__heading-title--margin-bottom;
}

.w-booking-participant-summary{
  .w-booking-participant-summary__collapse {
    font-size: $w-booking-participant-summary__collapse--font-size;
    margin-top: $w-booking-participant-summary__collapse--margin-top;
    &.c-collapse__trigger{
      height: auto;
    }
  }
}
