/* -------------------------------------------------------------------------
* ChoiceList Variables
*
*/

$c-choice-list--boxed--border-color:                                 $f-color__neutral-grey !default;
$c-choice-list--boxed--border-radius:                                $f-radius--medium !default;

$c-choice-list--boxed--hover--bg-color:                              $f-color__neutral-grey--ultra-light !default;
$c-choice-list--boxed--selected--bg-color:                           $f-color__state-info--ultra-light !default;
$c-choice-list--boxed--selected--border-color:                       $f-color__state-info !default;

$c-choice-list__element--boxed--height:                              $f-space--small + $f-space--tiny !default;
$c-choice-list__element--boxed--sp-stack:                            $f-space--small !default;
$c-choice-list__label--boxed--sp-inset:                              $f-space--small !default;

$c-choice-list__text-wrapper--boxed--sp-inset:                       $f-space--none !default;
$c-choice-list__messages--font-size:                                 $f-font-size--s !default;
$c-choice-list__messages--margin-top:                                $f-space--tiny !default;
$c-choice-list__message--error--color:                               $e-form-elements--error--border-color !default;

$c-choice-list__option--inline--margin-right:                        $f-space--small !default;

// Modifiers
// -----------------------------------------

// Highlighted
$c-choice-list--highlighted--selected-color:                         $e-form-elements__inner-element--color !default;

$c-choice-list-rich__label--max-width:                               calc(100% - (#{$f-space--large + $f-space--small})) !default;

$c-choice-list--boxed--error--border-color:                          $e-form-elements--error--border-color !default;

// Slider
$c-choice-list--with-slider__option-margin:                          $f-space--small !default;
