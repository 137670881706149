/* -------------------------------------------------------------------------
* DESTINATION VARS
*
*/

$t-destination-page--bp:                                                   $l-site-main--bp !default;
$t-destination-page__top--sp-stack:                                        $f-space--large !default;
$t-destination-page__main--sp-stack:                                       $f-space--large !default;
$t-destination-page__title--sp-stack:                                      $f-space--medium !default;


// FRAGMENTS
// -----------------------------------

$fr-destination-page__description--bp:                                     $f-breakpoint--sm !default;
$fr-destination-page__description-content--sp-stack:                       $f-space--small !default;

$fr-layout-container__spacing:                                              $f-space !default;
$fr-layout-container__padding:                                              s-core-px-to-rem($fr-layout-container__spacing) !default;

$fr-destination-page__practical-info-text-container--sp-stack:             $f-space--small !default;
$fr-destination-page__practical-info-text-container--preview--bp:          "sml" !default;
$fr-destination-page__practical-info-text-container--preview--bp-big:      "md" !default;

$fr-destination-page__header-img--bp:                                      $f-breakpoint--sm !default;

$fr-destination-page__header-img-container--sp-inset-from-bp:              $f-space--huge !default;
$fr-destination-page__header-img-container--sp-inset-h:                    $f-space--large !default;
$fr-destination-page__header-img-container--sp-inset-v:                    $f-space--small !default;

$fr-destination-page__header-img-title--sp-stack:                          $f-space--medium + $f-space--small !default;

$fr-destination-page__header-img-dropdown--min-width:                      250px !default;
$fr-destination-page__header-img-dropdown--max-width:                      250px !default;
$fr-destination-page__header-img-dropdown--margin-right:                   $f-space--medium !default;
$fr-destination-page__header-img-dropdown--margin-bottom:                  $f-space--small !default;

$fr-destination-page__cta--negative-margin:                                -$f-space--medium !default;
$fr-destination-page__cta--padding:                                        $f-space--small !default;
$fr-destination-page__cta--shadow:                                         $f-shadow--superimposed rgba($f-shadow__color, $f-shadow__opacity--superimposed) !default;
$fr-destination-page__cta--min-height:                                     70px !default;
$fr-destination-page__discover-other--breakpoints-tablet:                  $f-breakpoint--sml !default;
$fr-destination-page__discover-other--breakpoints-desktop:                 $f-breakpoint--md !default;

$fr-destination-page__discover-other--sp-stack:                            $f-space--medium !default;
$fr-destination-page__discover-other--sp-inline:                           $fr-destination-page__discover-other--sp-stack / 2 !default;

$fr-destination-page__discover-other--min-width:                           250px !default;
$fr-destination-page__discover-other--breakpoint-large:                    $f-breakpoint--lg;
$fr-destination-page__discover-other--breakpoint-larger:                   $f-breakpoint--xl;
$fr-destination-page__discover-other--tile-offset:                         12px;
$fr-destination-page__discover-other--column-width-large:                  25%;
$fr-destination-page__discover-other--column-width-larger:                 20%;

$fr-destination-page__expert-tips--margin-offset:                          -5px !default;
$fr-destination-page__expert-tips--padding-offset:                         5px !default;
$fr-destination-page__expert-tips-c-slider-item--margin-bottom:            $f-space--tiny !default;

$fr-destination-page__party-group-statistics--margin-right:                $f-space--medium !default;
$fr-destination-page__party-group-statistics--margin-bottom:               $f-space--small !default;

$fr-destination-page__chat-balloon-margin:                                 $f-space--small !default;
$fr-destination-page__chat-balloon-bottom:                                 68px !default;

$t-destination-page__breadcrumbs--margin-top:                              $f-space--small !default;
$t-destination-page__breadcrumbs--margin-bottom--until-bp:                 $f-space--medium !default;

$fr-destination-page__excursion-card-price--color:                         $f-color__brand-primary !default;
$fr-destination-page__excursion-card-price--legend-color:                  $f-color__neutral-grey--semi-dark !default;
$fr-destination-page__excursion-card-price--title-font-size:               $f-font-size--l !default;

//Highlighted video

$fr-destination-page__video--breakpoint--tablet:                           $f-breakpoint--sm !default;
$fr-destination-page__video--max-width:                                    75% !default;

// slider with text


$fr-destination-page__description-with-slider__slider--width--from-bp:                 percentage(1/2) !default;
$fr-destination-page__description-with-slider__slider--width--from-bp-big:             percentage(1/3) !default;
$fr-destination-page__description-with-slider__slider--sp-inline:                      $f-space--medium !default;
$fr-destination-page__description-with-slider__slider--sp-stack:                       $fr-acco-destination-description__slider--sp-inline !default;
$fr-destination-page__description-with-slider__slider--bp:                             "sml" !default;
$fr-destination-page__description-with-slider__slider--bp-big:                         "lg" !default;
