.fr-acco-basic-info {
  .fr-acco-basic-info__heading {
    display: flex;
    align-items: center;
    @include sp-stack($fr-acco-basic-info__heading--sp-stack);

    @include t-mq($from: $t-accommodation--bp) {
      align-items: flex-start;
    }
  }

  .fr-acco-basic-info__name {
    @include sp-inline($fr-acco-basic-info__name--sp-inline);
    @include m-heading--complete($fr-acco-basic-info__name--material);

    @include t-mq($from: $t-accommodation--bp) {
      @include m-heading--update($fr-acco-basic-info__name--material--from-bp, $fr-acco-basic-info__name--material)
    }
  }

  .c-rating {
    display: block;
    font-size: $fr-acco-basic-info__rating--font-size;
    color: $fr-acco-basic-info__rating--color;
    @include sp-stack($fr-acco-basic-info__rating--sp-stack);

  &[data-rating$=".5"]::after {
    width: $fr-acco-basic-info__rating-half-star--width;
  }
    
  }

  .c-favorite-btn {
    min-width: $fr-acco-basic-info__favourite--size;
    min-height: $fr-acco-basic-info__favourite--size;
  }
}
