$c-steps__link--bg-color :                           $f-color__neutral-grey--semi-light !default;
$c-steps__link--bg-hover:                            $f-color__neutral-grey--light !default;
$c-steps__link--radius:                              $f-radius--medium !default;
$c-steps__link--font-weight:                         400 !default;
$c-steps__item---bg-color--active:                   $f-color__state-info--dark !default;
$c-steps--progress__link-color:                      $f-color__brand-primary--dark !default;
$c-steps--progress__link-color--active:              $f-color__brand-primary--dark !default;
$c-steps--progress__link-weight--active:             600 !default;
$c-steps__link--is-active--font-family:              map_get($m-heading__mod-sizes, "micro") !default;
$c-steps__text--font-family:                         $f-font-family__secondary !default;
$c-steps__link--is-disabled--color:                  $f-color__neutral-grey !default;

$c-steps--progress__bullet-before--reset-padding:    1px !default;