/* -------------------------------------------------------------------------
 * LEGEND
 *
 */

.c-legend__item {
  display: inline-block;
  @include sp-inline($c-legend--outer-spacing);
}

.c-legend__content {
  @include sp-inline($c-legend--inner-spacing);
  @include sp-inset-squish($c-legend__content--padding);
  font-family: $c-legend__content--font-family;
  font-size: $c-legend__content--font-size;
  font-weight: $c-legend__content--font-weight;
  text-align: center;
  border: $c-legend__content--border-size $c-legend__content--border-style $c-legend__content--border-color;
  border-radius: $c-legend__content--border-radius;
  min-width: $c-legend__content--size;
  min-height: $c-legend__content--size;
  vertical-align: middle;
}

.c-legend__content,
.c-legend__description {
  display: inline-block;
}
