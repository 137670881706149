/* -------------------------------------------------------------------------
 * accommodation PAGE
 *
 * [1] - Promoted price sometimes overlaps accommodation top, some adjustments are needed
 * [2] - Promoted price positions relatively to acco-top and then to acco-top-b
 *
 */

.t-accommodation {
  [class^="fr-"] {
    > .c-title {
      @include t-accommodation-title;
    }
  }

  .t-accommodation__main-top {
    position: relative; // [2]

    @include sp-stack($t-accommodation__main-top--sp-stack);
    @include t-mq($from: $t-accommodation--bp) {
      @include sp-stack($t-accommodation__main-top--sp-stack);
    }
  }

  .t-accommodation__main-top-a {
    @include sp-stack($t-accommodation__main-top--sp-stack);
    @include t-mq($from: 'sm') {
      max-width: calc(100% - 270px); // [1]
    }
    @include t-mq($from: 'sml') {
      max-width: calc(100% - 280px); // [1]
    }
    @include t-mq($from: 'md') {
      max-width: 100%; // [1]
    }

    //Accommodation page have the only breadcrumb with specific styles, for this reason this styles are applied in the template, if in the future we need this styles in another part of the web we should consider applying it directly into the component.
    .c-breadcrumbs__item:not(:last-child)::after {
      content: ',';
      padding-left: 0;
    }

    .c-breadcrumbs:not(.c-breadcrumbs--unclickable) {
      display: flex;
    }
  }

  .t-accommodation__main-top-b {
    position: relative; // [2]
  }

  .t-accommodation__collection-label {
    position: absolute;
    top: $t-accommodation__collection-label--top;
    left: $t-accommodation__collection-label--left;
    z-index: $t-accommodation__collection-label--z-index;

    @include t-mq($from: $t-accommodation--bp) {
      top: $t-accommodation__collection-label--top--from-bp;
      left: $t-accommodation__collection-label--left--from-bp;
    }
  }

  .t-accommodation__content--aside {
    @include t-mq($until: $t-accommodation--bp-columns) {
      padding-top: $t-accommodation__children--sp-stack;
    }
  }

  .fr-acco-practical-info,
  .fr-acco-flight,
  .fr-acco-bus-info {
    @extend %fr-wide-text;
  }

  .l-site-main__content.l-site-main__content--accommodation-no-tabs {
    .fr-acco-basic-info {
      @include t-mq($from: $f-breakpoint--sml) {
        margin-bottom: $fr-acco-basic-info__margin-bottom;
      }
      @include t-mq($until: $f-breakpoint--sml) {
        margin-bottom: $fr-acco-basic-info__margin-bottom--bp;
      }
    }
    .c-breadcrumbs:first-child {
      margin-top: unset;
      margin-bottom: $l-site-main__content--c-breadcrumbs__margin-bottom;
      @include t-mq($until: $f-breakpoint--sm) {
        margin-top: $l-site-main__content--c-breadcrumbs__margin-bottom--bp;
      }
    }
    .fr-tabs-container {
      & > .c-tabs__nav {
        display: none;
      }
      & > .c-tabs__content-wrapper > .c-tabs__content {
        padding-top: unset;
      }
    }

    .fr-tabs-container
      .c-tabs__content-wrapper
      .c-tabs__content:first-child
      .o-layout {
      display: flex;
      align-items: stretch;
      @include t-mq($until: $f-breakpoint--md) {
        flex-direction: column-reverse;
      }

      .t-accommodation__content--main {
        @include t-mq($from: $f-breakpoint--md) {
          padding-right: $t-accommodation__content--aside--no-tabs--inset-h;
        }
      }

      .t-accommodation__content--aside {
        @include t-mq($until: $f-breakpoint--md) {
          padding-top: unset;
        }
        @include t-mq($from: $f-breakpoint--md) {
          background-color: $t-accommodation__content--aside--no-tabs--background-color;
          padding-right: $t-accommodation__content--aside--no-tabs--inset-h;
          padding-top: unset;
          margin-top: $t-accommodation__content--aside--no-tabs--margin-top;
          border-radius: $t-accommodation__content--aside--no-tabs--border-radius;
        }
      }

      .fr-promoted-price {
        @include t-mq($from: $f-breakpoint--md) {
          position: relative;
          top: 0;
          height: 100%;
        }

        .w-promoted-price {
          position: sticky;
          transform: unset;
          top: 0;
          right: 0;
          width: 100%;

          @include t-mq($from: $f-breakpoint--md) {
            padding-top: $fr-promoted-price__sticky--padding-top;
            padding-bottom: $fr-promoted-price__sticky--padding-top;
          }

          .w-promoted-price__main {
            width: 100%;
          }
        }
      }
    }
  }

  @import 'fr-acco-campaign-details';
  @import 'fr-acco-back-button';
  @import 'fr-acco-basic-info';
  @import 'fr-acco-carousel';
  @import 'fr-tabs-container';
  @import 'fr-promoted-price';
  @import 'fr-acco-description';
  @import 'fr-acco-reviews';  
  @import 'fr-acco-reviews-overview';
  @import 'fr-acco-destination-description';
  @import 'fr-acco-destination-block';
  @import 'fr-acco-excursions';
  @import 'fr-acco-usps';
  @import 'fr-acco-room-types';
  @import 'fr-acco-facilities';
  @import 'fr-acco-practical-info';
  @import 'fr-acco-photo-gallery-sidebar';
  @import 'fr-address--acco-page';
  @import 'fr-reviews-list';
  @import 'fr-acco-mealplans';
  @import 'fr-acco-highlighted-video--acco-page';
}
