/* -------------------------------------------------------------------------
 * INSET
 *
 * To give space between container and content.
 *
 * TIP:
 * You can modify the amount of generated spaces by overriding the $sp-inset__spaces variable.
 *
 */

// Space selector output
// --------------------------------------------------

@if ($sp-inset__enabled) {
  @each $_spacing-name, $_spacing-value in $sp-inset__spaces {
    .sp-inset--#{$_spacing-name} {
      @include sp-inset($_spacing-value);
    }
  }
}
