/* -------------------------------------------------------------------------
 * CONTENT BLOCK VARIABLES
 */

 $c-content-block__subtitle--color:              $f-color__neutral-grey--semi-dark !default;
 $c-content-block__subtitle--spacing:            $f-space--small !default;

// Size Modifiers
// -------------------------------

// Large modifier
$c-content-block--large--spacing-stack:          $f-space--medium + $f-space--small !default;
