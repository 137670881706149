/* -------------------------------------------------------------------------
 * SITE GENERAL
 *
 */

// Layout sections
// -------------------------------------------------
@import "site-footer/main";
@import "site-header/main";
@import "site-main/main";

// Layout wrappers
// -------------------------------------------------

.l-site__wrapper--large {
  @include o-wrapper--to($l-site__wrapper-large--to);
}

.l-site__wrapper--medium {
  @include o-wrapper--from($l-site__wrapper-medium--from);
  @include o-wrapper--to($l-site__wrapper-medium--to);
  @include sp-inset-h($l-site__wrapper-medium--inset);

  @include t-mq($from: $l-site__wrapper-bp) {
    @include sp-inset-h($l-site__wrapper-medium--inset--from-bp);
  }
}

.l-site__wrapper--small {
  @include o-wrapper--from($l-site__wrapper-small--from);
  @include o-wrapper--to($l-site__wrapper-small--to);
}

// Context spacings
// -------------------------------------------------

.l-site {
  min-height: 100vh;
}
