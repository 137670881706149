/* -------------------------------------------------------------------------
 * ACCO LISTER
 *
 */

.w-acco-lister {
  .tns-nav {
    margin-bottom: $w-acco-lister__c-slider--package-item__item-listed--sp-stack;
  }
}

.w-acco-lister__slider {
  .c-slider__content {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
  }

  .c-slider__item {
    @include t-mq($until: $f-breakpoint--sm) {
      margin: $w-acco-lister__c-slider__item--mobile-offset;
    }
  }
}

.w-acco-lister__package-item {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.w-acco-lister__package-item--listed {
  @include sp-stack($w-acco-lister__c-slider--package-item__item-listed--sp-stack);
  flex-direction: row;
  height: auto;
}

.w-acco-lister__loader {
  display: none;
  margin: 0 auto;
}

.w-acco-lister.is-loading {
  .w-acco-lister__loader {
    display: flex;
  }
}

// Vertical margins for items with shadows inside default acco slider variant
.w-acco-lister[data-w-acco-lister__variant="default"] .c-slider__item {
  margin-top: $w-acco-lister__c-slider-item--margin-top;
  margin-bottom: $w-acco-lister__c-slider-item--margin-bottom;
  @include t-mq($until: $f-breakpoint--sm) {
    margin: $w-acco-lister__c-slider__item--mobile-offset;
  }
}

.w-acco-lister__package-item {
  .c-package-item__package-row {
    margin-bottom: -12px;
    .c-package-block {
      flex-direction: column;
      .c-package-block__flight-routes-container {
        flex-direction: column;
        margin-top: 8px;
      }
    }
  }
}

.w-acco-lister__package-item--listed {
  @include t-mq($until: $f-breakpoint--xs) {
    .c-package-item__body-heading{
      .c-breadcrumbs {
        display: none;
      }
    }
  }
  .c-package-block {

    @include t-mq($until: $f-breakpoint--sm) {
      .c-package-block__included.o-list-bare{
        margin-bottom: -8px;
      }
    }

    flex-direction: column;

    .c-package-block__flight-routes-container {
      bottom: -12px;
      position: relative;
      
      @include t-mq($until: $f-breakpoint--xs) {
        flex-wrap: wrap;
        
        .c-package-block__flight-routes--out-arrival,
        .c-package-block__flight-routes--in {
          display: none;
        }
        
      }
    }
  }
}
